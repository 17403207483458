import React, { Component } from "react";
import { GET_LIST, Title } from "react-admin";
import { restClient } from "../App";
import { stateMapper } from "../customReducers/connectFunctions";
import { filter, get } from "lodash";
import Card from "@material-ui/core/Card";
import PowerConsumptionGraph from "./PowerConsumptionGraph";
import PowerConsumptionScatterGraph from "./PowerConsumptionScatterGraph";
import {
  // raConsumptionsAndTemperaturesForScatterplot,
  addTemperaturesToConsumptionData,
  // mapYearsForScatterplot
} from "../utils/raDataToHighcharts";
import Spinner from "../components/layout/Spinner";
import EmptyData from "../components/layout/EmptyData";

class PowerConsumptionGraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectname: this.props.projectname,
      BuildingId: this.props.BuildingId,
      consumptions: null,
      temperatures: null,
      consumptionsWithTemperatures: null,
      blocks: null,
      powerLoading: false,
      tempLoading: false,
      twinsLoading: false,
      consTempLoading: false,
      fetchedOnce: false,
    };
  }

  // componentDidUpdate is used due to buiding not being set on mount if page is refreshed here, component will fetch things when a Building is selected from sessionStorage
  async componentDidUpdate() {
    if (!this.state.fetchedOnce && this.props.BuildingId) {
      this.setState({ fetchedOnce: true });
      try {
        let consumptions = [];
        if (
          !this.props.powerConsumption ||
          this.props.powerConsumption.length === 0
        ) {
          this.setState({ powerLoading: true });
          const consumptionfetch = await restClient(
            GET_LIST,
            "powerconsumptionlists",
            {
              filter: {
                BuildingId: this.props.BuildingId,
              },
              sort: { field: "Time", order: "ASC" },
              pagination: {},
            }
          );
          consumptions = await consumptionfetch.data;
          this.setState({ powerLoading: false });
        } else {
          consumptions = [...this.props.powerConsumption];
        }

        let temperatures = [];
        if (!this.props.temperatures || this.props.temperatures.length === 0) {
          this.setState({ tempLoading: true });
          const temperaturesfetch = await restClient(GET_LIST, "reportdata", {
            filter: {
              pointIds: this.props.BuildingId,
              buildingId: this.props.BuildingId,
              pointSource: "building_temp",
            },
            sort: { field: "valueTime", order: "ASC" },
            pagination: {},
          });
          temperatures = await temperaturesfetch.data;
          this.setState({ tempLoading: false });
        } else {
          temperatures = [...this.props.temperatures];
        }

        let consumptionsWithTemperatures = [];
        if (!this.props.consTemps || this.props.consTemps.length === 0) {
          this.setState({ consTempLoading: true });
          consumptionsWithTemperatures = await addTemperaturesToConsumptionData(
            consumptions,
            temperatures
          );
          this.setState({ consTempLoading: false });
        } else {
          consumptionsWithTemperatures = this.props.consTemps;
        }

        // get all the blocks related to this building
        let blocklist = [];
        if (!this.props.allTwins || this.props.allTwins.length === 0) {
          this.setState({ twinsLoading: true });
          const blockFetch = await restClient(GET_LIST, "blocks", {
            filter: {
              BuildingId: this.props.BuildingId,
            },
            sort: {},
            pagination: {},
          });
          blocklist = blockFetch.data;
          this.setState({ twinsLoading: false });
        } else {
          blocklist = [...this.props.allTwins];
        }
        await this.setState({
          consumptions,
          temperatures,
          consumptionsWithTemperatures,
          blocks: blocklist,
        });
      } catch (error) {}
    }
  }

  async componentDidMount() {
    if (this.state.BuildingId) {
      this.setState({ fetchedOnce: true });
      try {
        let consumptions = [];
        if (
          !this.props.powerConsumption ||
          this.props.powerConsumption.length === 0
        ) {
          this.setState({ powerLoading: true });
          const consumptionfetch = await restClient(
            GET_LIST,
            "powerconsumptionlists",
            {
              filter: {
                BuildingId: this.state.BuildingId,
              },
              sort: { field: "Time", order: "ASC" },
              pagination: {},
            }
          );
          consumptions = await consumptionfetch.data;
          this.setState({ powerLoading: false });
        } else {
          consumptions = [...this.props.powerConsumption];
        }

        let temperatures = [];
        if (!this.props.temperatures || this.props.temperatures.length === 0) {
          this.setState({ tempLoading: true });
          const temperaturesfetch = await restClient(GET_LIST, "reportdata", {
            filter: {
              pointIds: this.state.BuildingId,
              buildingId: this.state.BuildingId,
              pointSource: "building_temp",
            },
            sort: { field: "valueTime", order: "ASC" },
            pagination: {},
          });
          temperatures = await temperaturesfetch.data;
          this.setState({ tempLoading: false });
        } else {
          temperatures = [...this.props.temperatures];
        }

        let consumptionsWithTemperatures = [];
        if (!this.props.consTemps || this.props.consTemps.length === 0) {
          this.setState({ consTempLoading: true });
          consumptionsWithTemperatures = await addTemperaturesToConsumptionData(
            consumptions,
            temperatures
          );
          this.setState({ consTempLoading: false });
        } else {
          consumptionsWithTemperatures = this.props.consTemps;
        }

        // get all the blocks related to this building
        let blocklist = [];
        if (!this.props.allTwins || this.props.allTwins.length === 0) {
          this.setState({ twinsLoading: true });
          const blockFetch = await restClient(GET_LIST, "blocks", {
            filter: {
              BuildingId: this.state.BuildingId,
            },
            sort: {},
            pagination: {},
          });
          blocklist = blockFetch.data;
          this.setState({ twinsLoading: false });
        } else {
          blocklist = [...this.props.allTwins];
        }
        await this.setState({
          consumptions,
          temperatures,
          consumptionsWithTemperatures,
          blocks: blocklist,
        });
      } catch (error) {}
    }
  }

  render() {
    let title = this.props.noTitle ? " " : this.props.projectname;

    const graphLoading =
      this.props.twinsLoading ||
      this.props.powerLoading ||
      this.state.powerLoading ||
      this.state.tempLoading ||
      this.state.consTempLoading ||
      this.state.twinsLoading;

    const scatterLoading =
      this.props.powerLoading ||
      this.state.powerLoading ||
      this.state.tempLoading ||
      this.state.consTempLoading;

    // Only pass values that have temperature and power to the graph
    const filteredConsTemps = filter(
      this.state.consumptionsWithTemperatures,
      (c) => c.Temperature !== null && c.Power !== null
    );

    let showData = false;

    if (
      this.state.temperatures !== null &&
      this.state.temperatures.length > 0 &&
      this.state.consumptions !== null &&
      this.state.consumptions.length > 0 &&
      this.state.consumptionsWithTemperatures !== null &&
      this.state.consumptionsWithTemperatures.length > 0
    ) {
      showData = true;
    }

    if (this.props.showGraph && this.props.hideGraph) {
      if (showData && !graphLoading && !scatterLoading) {
        this.props.showGraph("power");
      } else if (!showData && !graphLoading && !scatterLoading) {
        this.props.hideGraph("power");
      }
    }

    if (showData)
      return (
        <div>
          <Title title={title} />
          <Card style={{ width: "100%", display: "inline", float: "left" }}>
            {!scatterLoading ? (
              <PowerConsumptionScatterGraph
                consumptions={this.state.consumptionsWithTemperatures}
                projectname={this.state.projectname}
              />
            ) : (
              <Spinner style={{ height: "20rem" }} />
            )}
          </Card>
          <Card style={{ width: "100%", display: "inline", float: "left" }}>
            {!graphLoading ? (
              <PowerConsumptionGraph
                timezone={get(this.props, "building.TimeZoneIANA")}
                consumptions={this.state.consumptions}
                temperatures={this.state.temperatures}
                projectname={this.state.projectname}
                BuildingId={this.state.BuildingId}
                blocks={this.state.blocks}
                allTwinsData={this.props.allTwinsData}
                consTemps={filteredConsTemps}
              />
            ) : (
              <Spinner style={{ height: "20rem" }} />
            )}
          </Card>
        </div>
      );
    else if (graphLoading || scatterLoading) {
      return <Spinner style={{ height: "20rem" }} />;
    } else return <EmptyData style={{ height: "28rem" }} />;
  }
}

export default stateMapper(PowerConsumptionGraphs);
