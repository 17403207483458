import { isNil, groupBy } from "lodash";

const setStackColor = (_series) => {
  // Years are the same color, set the different datas on the same stack as different color
  if (_series && _series.length > 0) {
    let series = [];
    series = [..._series];
    const grouped = groupBy(series, "stack");
    const keys = Object.keys(grouped);

    let newSeries = [];
    keys.forEach((k) => {
      if (k !== "undefined" && grouped[k] && grouped[k].length > 0) {
        let color = undefined;
        let opacity = undefined;
        // First find the year indicator and get the color of that
        grouped[k].forEach((y) => {
          // Get the color of the year indicator
          if (parseInt(y.name) === parseInt(k)) {
            color = y.color;
          }
        });
        // Then cycle through the rest of the series
        let index = 0;
        grouped[k].forEach((s) => {
          if (!isNil(s) && parseInt(s.name) !== parseInt(k)) {
            // If one of the "main" stacks, keep color same as year indicator
            // Other ones on the same stack are the same color with reduced opacity. Lowest opacity on top
            let length = grouped[k].length;
            if (
              s.name.includes("Other") ||
              s.name.includes("Total") ||
              s.name.includes("District") ||
              s.name.includes("Utility") ||
              s.opacity === 1
            ) {
              opacity = 1;
            } else {
              index++;
              opacity = 1 - 0.075 * (length - index);
            }
            newSeries.push({ ...s, color: color, opacity: opacity });
          } else {
            newSeries.push(s);
          }
        });
      } else {
        newSeries.push(...grouped[k]);
      }
    });
    return newSeries;
  } else return _series;
};

export default setStackColor;
