import React from "react";
import { useState, useEffect } from "react";
import { Toolbar } from "react-admin";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Save, Delete } from "@material-ui/icons";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import PageTitle from "../components/layout/PageTitle";
import { useSelector } from "react-redux";

const measurementTargets = [
  { id: "Heating", target: "heating", unit: "MWh" },
  {
    id: "Electricity",
    target: "electricity",
    unit: "kWh",
  },
  {
    id: "Water",
    target: "water",
    unit: "m3",
  },
];

const useStyles = makeStyles(() => ({
  standardPadding: {
    padding: 20,
  },
  createBox: {
    padding: 20,
    margingBottom: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  saveButton: {
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
}));

const CreateSubmeterDeviceDialog = ({ handleClose }) => {
  const classes = useStyles();
  const customFetch = useCustomFetch();
  const BuildingId = useSelector((state) => state.buildingFilter.id);

  const [submeters, setSubmeters] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [measurementTarget, setMeasurementTarget] = useState({
    id: "",
    target: "",
    unit: "",
  });

  useEffect(() => {
    customFetch({
      url: `manualsubmeters?BuildingId=${BuildingId}`,
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!data) return;
      setSubmeters(data);
    });
  }, [customFetch, BuildingId]);

  const handleCreate = () => {
    customFetch({
      url: "manualsubmeters",
      method: "POST",
      body: JSON.stringify({
        BuildingId,
        id: 0,
        DeviceName: deviceName,
        MeasurementTarget: measurementTarget.target,
        Unit: measurementTarget.unit,
      }),
      headers: [
        { name: "Accept", value: "application/json" },
        { name: "Content-Type", value: "application/json" },
      ],
    });
    handleClose();
  };

  //const handleDelete = () => {} Will this be implemented later?

  return (
    <>
      <form onSubmit={handleCreate}>
        <Box className={classes.standardPadding}>
          <PageTitle title="Configure Submeter Devices" />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Existing Devices</TableCell>
                <TableCell>Measurement Target</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {submeters?.map((submeter) => (
                <TableRow>
                  <TableCell>{submeter.DeviceName}</TableCell>
                  <TableCell>{submeter.MeasurementTarget}</TableCell>
                  <TableCell>{submeter.Unit}</TableCell>
                  <TableCell>
                    <Button disabled>
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            className={classes.createBox}
            component="form"
            onSubmit={handleCreate}
          >
            <Typography variant="h6" gutterBottom>
              Create new submeter
            </Typography>
            <TextField
              className={classes.marginBottom}
              fullWidth
              variant="filled"
              label="Submeter device name"
              onChange={({ target }) => setDeviceName(target.value)}
            />
            <FormControl variant="filled" fullWidth>
              <InputLabel>Measurement Target</InputLabel>
              <Select
                className={classes.marginBottom}
                id="physical-quantity"
                value={measurementTarget}
                onChange={({ target }) => setMeasurementTarget(target.value)}
              >
                {measurementTargets?.map((mTarget) => (
                  <MenuItem value={mTarget}>{mTarget.id}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <TextField
                variant="filled"
                label="Unit"
                value={measurementTarget.unit}
              />
            </Box>
          </Box>
        </Box>
        <Toolbar>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="filled"
            startIcon={<Save />}
          >
            Save
          </Button>
        </Toolbar>
      </form>
    </>
  );
};

export default CreateSubmeterDeviceDialog;
