import React from "react";
import { Edit, FormDataConsumer, NumberInput, SimpleForm } from "react-admin";
import { setPowerConsumptionUnitText } from "../utils/unitTextParsers";
import DateTimeWithTimezoneInput from "../components/TimeInputs";
import PageTitle from "../components/layout/PageTitle";

export const PowerConsumptionListsEdit = (props) => {
  // Delete unused/unnecessary props
  let editProps = Object.assign({}, props);
  delete editProps.buildingTwinId;
  delete editProps.buildingSelected;
  delete editProps.dispatch;
  return (
    <>
      <PageTitle title="Edit Power Consumption" />
      <Edit {...editProps}>
        <SimpleForm submitOnEnter={false}>
          <NumberInput
            source="Power"
            label={setPowerConsumptionUnitText("Power")}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <NumberInput
                source="WaterAmount"
                label={setPowerConsumptionUnitText("WaterAmount")}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <NumberInput
                source="WIncoming"
                label={setPowerConsumptionUnitText("WIncoming")}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <NumberInput
                source="WOutgoing"
                label={setPowerConsumptionUnitText("WOutgoing")}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.WaterAmount &&
              !formData.WIncoming &&
              !formData.WOutgoing && (
                <NumberInput
                  source="Cooling"
                  label={setPowerConsumptionUnitText("Cooling")}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <DateTimeWithTimezoneInput source="Time" label="Time" />
        </SimpleForm>
      </Edit>
    </>
  );
};
