import React from "react";
import PropTypes from "prop-types";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useTranslate } from "ra-core";

import CustomMenuLink from "./CustomMenuLink";

const CustomMenuItem = ({ locale, onClick, ...props }) => {
  const translate = useTranslate();
  return (
    <CustomMenuLink
      style={{ padding: "14px 14px" }}
      onClick={onClick}
      to="/dashboard"
      primaryText={translate("ra.page.dashboard")}
      leftIcon={<DashboardIcon />}
      exact
      {...props}
    />
  );
};

CustomMenuItem.propTypes = {
  classes: PropTypes.object,
  locale: PropTypes.string,
  onClick: PropTypes.func,
};

export default CustomMenuItem;
