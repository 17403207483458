import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { setBuildingTemp } from "../customReducers/actions";

function useBuildingTemp(id) {
  const [buildingId, setBuildingId] = useState(null);
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const notify = useNotify();

  // Set some datas to null when building is changed
  useEffect(() => {
    if (id !== buildingId) {
      setBuildingId(id);
      dispatch(setBuildingTemp(null));
      dataProvider
        .getList("reportdata", {
          filter: {
            pointIds: id,
            buildingId: id,
            pointSource: "building_temp",
            v2: true,
          },
          sort: { field: "valueTime", order: "ASC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data: d } = res;
            if (d) {
              const { values: data } = d;
              if (data && data.length > 0) {
                dispatch(setBuildingTemp(data));
              } else {
                dispatch(setBuildingTemp([]));
              }
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
          dispatch(setBuildingTemp([]));
        });
    }
  }, [id, buildingId, dispatch, notify, dataProvider]);

  return null;
}

export default useBuildingTemp;
