import React from "react";
import { useLogout } from "react-admin";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const StyledMenu = withStyles({
  paper: {
    marginTop: "1em",
    width: "12.5rem",
    border: "1px solid #d3d4d5",
    background: "#2F2347",
    boxShadow: "0px 3px 10px #0000001A",
    color: "#FFF",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //   backgroundColor: theme.palette.primary.main,
      background: "transparent",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: "12.5rem",
    height: "2.125rem",
    padding: "0 0",
    textTransform: "none",
    backgroundColor: "transparent",
    color: "#282331",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  label: {
    position: "absolute",
    height: "100%",
    fontSize: "1rem",
  },
  startIcon: {
    boxSizing: "border-box",
    height: "100%",
    marginRight: "1rem",
    opacity: 0.6,
  },
  endIcon: {},
}));

export default function ProfileMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const logout = useLogout();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logout();
  };

  return (
    <div style={{ marginLeft: "1rem" }}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        size="large"
        onClick={handleClick}
        startIcon={<AccountCircleIcon style={{ fontSize: "2.125rem" }} />}
        endIcon={<ExpandMoreIcon style={{ color: "#A4AFB7" }} />}
        classes={{
          root: classes.root,
          label: classes.label,
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
        }}
      >
        {props.userName ? props.userName : "User Name"}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleLogOut}>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
