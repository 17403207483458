import React from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { IconButton } from "@material-ui/core";

const FavoriteBuildingField = props => {
  const { record } = props;

  const checkFavoriteStatus = id => {
    // Check if building id exists in favorites
    let retVal = false;
    retVal = props.favorites.some(f => f.SourceId === id);
    return retVal;
  };

  const onClickHandler = (id, event) => {
    event.preventDefault();
    // Check if building already favorited
    if (checkFavoriteStatus(id)) {
      props.favoriteDeleted(id);
    } else {
      props.favoriteAdded(id);
    }
  };

  let retVal = <StarBorderIcon />;
  if (checkFavoriteStatus(record.id)) {
    retVal = <StarIcon />;
  }

  return (
    <IconButton onClick={event => onClickHandler(record.id, event)}>
      {retVal}
    </IconButton>
  );
};

FavoriteBuildingField.defaultProps = {
  addLabel: true,
  label: "Favorite"
};

export default FavoriteBuildingField;
