import React, { useEffect, useState } from "react";
import Spinner from "../../components/layout/Spinner";
import { makeStyles } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import EmptyData from "../../components/layout/EmptyData";
import graphOptionsHeat from "../datamappers/produced/graphOptionsHeat";
import graphOptionsElec from "../datamappers/produced/graphOptionsElec";
import { useSelector } from "react-redux";
import setLegendItemHover from "../datamappers/setLegendItemHover";
import graphOptionsCooling from "../datamappers/produced/graphOptionsCooling";
import checkGraphData from "../../utils/checkGraphData";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    height: "28rem",
    display: "flex",
    alignItems: "center",
  },
}));

const ProducedGraph = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const temperatureData = useSelector(
    (state) => state.buildingFilter.buildingTemp
  );
  const hpData = useSelector((state) => state.buildingFilter.hpData);
  const solarData = useSelector((state) => state.buildingFilter.solarData);
  const buildingTrends = useSelector(
    (state) => state.buildingFilter.buildingTrends
  );

  useEffect(() => {
    setLoading(true);
    let options = null;
    if (props.type === "heating") {
      options = graphOptionsHeat(buildingTrends, hpData, temperatureData);
    } else if (props.type === "electricity") {
      options = graphOptionsElec(buildingTrends, solarData);
    } else if (props.type === "cooling") {
      options = graphOptionsCooling(buildingTrends, hpData);
    }
    setGraphData(options);
  }, [temperatureData, hpData, buildingTrends, solarData, props.type]);

  // Additional useEffect to set loading to false when graphData changes
  useEffect(() => {
    if (graphData) {
      setLoading(false);
    }
  }, [graphData]);

  if (loading || props.loading) {
    return <Spinner style={{ height: "25rem" }} />;
  }

  if (checkGraphData({ data: graphData })) {
    if (props.showGraph) {
      props.showGraph(props.type);
    }
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={graphData}
        callback={setLegendItemHover}
      />
    );
  } else {
    if (props.hideGraph) {
      props.hideGraph(props.type);
    }
    return (
      <div className={classes.emptyContainer}>
        <EmptyData />
      </div>
    );
  }
};

export default ProducedGraph;
