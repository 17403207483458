import React, { Component } from "react";
import { SelectArrayInput } from "react-admin";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";

class WritePointInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      points: [],
    };
  }

  componentDidMount() {
    restClient(GET_LIST, "busadapterpoints", {
      filter: {
        BusAdapterId: 11,
        PointType: "WRITE",
      },
      sort: { field: "PointName", order: "ASC" },
      pagination: {},
    }).then((r) => {
      this.setState({
        points: r.data,
      });
    });
  }

  render() {
    return (
      <SelectArrayInput
        {...this.props}
        optionValue="PointName"
        optionText="Description"
        choices={this.state.points}
      />
    );
  }
}

WritePointInput.propTypes = SelectArrayInput.propTypes;
WritePointInput.defaultProps = SelectArrayInput.defaultProps;

export default WritePointInput;
