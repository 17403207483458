import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";

import { MeterDailySumGraph } from "./graphs/MeterDailySumGraph";
import { MeterMonthlySumGraph } from "./graphs/MeterMonthlySumGraph";
import CardWithTitle from "../components/layout/CardWithTitle";
import OperationLogs from "../dashboard/logs/OperationLogs";
import useUserRole from "../utils/hooks/useUserRole";
import {
  coolingColors,
  defaultColors,
  electricityColors,
  heatingColors,
  waterColors,
} from "../utils/graphColor";

const MeterShow = ({ formData }) => {
  const [meterData, setMeterData] = useState(null);
  const [trends, setTrends] = useState(null);
  const [hiddenContainers, setHiddenContainers] = useState([
    "logs",
    "monthly",
    "daily",
  ]);
  const { isInternalAdmin } = useUserRole();

  useEffect(() => {
    if (formData.Meter && !meterData) {
      setMeterData(formData.Meter);
    }
    if (formData.Trends && formData.Trends.length > 0 && !trends) {
      setTrends(formData.Trends);
    }
  }, [formData.Meter, meterData, formData.Trends, trends]);

  const handleContainerHide = useCallback(
    (container) => {
      if (!hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if (hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers]
  );

  if (
    formData.BuildingId &&
    (formData.Meter || meterData) &&
    ((formData.Trends && formData.Trends.length > 0) || trends) &&
    formData.BlockType === "Meter"
  ) {
    let meter = formData.Meter ? formData.Meter : meterData;
    let tr =
      formData.Trends && formData.Trends.length > 0 ? formData.Trends : trends;
    let _formData = { ...formData, Meter: meter, Trends: tr };

    let graphColors = defaultColors;
    const meterType =
      _formData &&
      _formData.Meter &&
      typeof _formData.Meter.Category === "string"
        ? _formData.Meter.Category.toLowerCase()
        : "";

    switch (meterType) {
      case "heating":
        graphColors = heatingColors;
        break;
      case "electricity":
        graphColors = electricityColors;
        break;
      case "water":
        graphColors = waterColors;
        break;
      case "cooling":
        graphColors = coolingColors;
        break;

      default:
        graphColors = defaultColors;
        break;
    }

    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={5}
          style={{
            display:
              hiddenContainers.includes("logs") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs
              buildingId={_formData.BuildingId}
              twinId={_formData.id}
              showContainer={handleContainerShow}
              hideContainer={handleContainerHide}
            />
          </CardWithTitle>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("monthly") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <MeterMonthlySumGraph
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
            graphColors={graphColors}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("daily") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <MeterDailySumGraph
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
            graphColors={graphColors}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default MeterShow;
