import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import { map, cloneDeep, last, find } from "lodash";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import Boost from "highcharts/modules/boost";
import {
  createFeedbackSeries,
  updateFeedbackIcons,
  tooltipFormatter,
} from "../utils/raDataToHighcharts";
Boost(Highcharts);

const boostLimit = 5000;

export class ZoneBlockHumidityMeasurements extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);

    this.state = {
      record: this.props,
      optionsDay: this.graphOptions(),
      optionsMonth: this.graphOptions(true),
      resolution: "day",
      fetchDone: false,
    };
  }

  graphOptions(monthlyData = false) {
    return {
      chart: {
        height: "800px",
        //renderTo: "container",
        alignTicks: true,
        zoomType: "x",
        events: {
          load: function () {
            let chart = this;
            // Update point icons
            updateFeedbackIcons(chart);
          },
        },
      },
      legend: {
        enabled: true,
      },
      time: {
        timezone: this.props.timezone,
      },
      rangeSelector: {
        inputEnabled: false,
        allButtonsEnabled: true,
        buttonTheme: {
          width: "auto",
        },
        buttons: monthlyData
          ? [
              {
                type: "month",
                count: 3,
                text: "3m",
              },
              {
                type: "month",
                count: 12,
                text: "12m",
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ]
          : [
              {
                type: "day",
                count: 0,
                text: "day",
              },
              {
                type: "month",
                count: 0,
                text: "month",
              },
              {
                type: "all",
                text: "All",
              },
            ],
      },
      tooltip: {
        valueDecimals: 2,
        formatter: function (tooltip) {
          return tooltipFormatter(this, Highcharts, tooltip);
        },
      },
      title: {
        text: "",
      },
      scrollbar: {
        liveRedraw: false,
        adaptToUpdatedData: false,
      },
      navigator: {
        adaptToUpdatedData: false,
        enabled: true,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
      yAxis: [
        {
          title: {
            text: "Humidity",
          },
          height: "85%",
          lineWidth: 2,
        },
        {
          title: {
            text: "Events",
          },
          top: "90%",
          height: "10%",
          offset: 0,
          lineWidth: 2,
          min: 0,
          max: 1.5,
          labels: {
            enabled: false,
          },
        },
      ],
      xAxis: {
        events: {
          afterSetExtremes: this.afterSetExtremes.bind(this),
        },
        minRange: monthlyData ? 1 : 24 * 3600 * 1000, // one day
      },
      series: [],
    };
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
  }

  componentDidMount() {
    if (this.internalChart) {
      this.internalChart.showLoading("Loading data from server...");

      const getMeasurements = restClient(GET_LIST, "zonecondition", {
        filter: {
          blockId: this.state.record.id,
          dataBegin: null,
          dataEnd: null,
          conditionType: "HUMI",
        },
        sort: { field: "UpdateTime", order: "ASC" },
        pagination: {},
      });

      Promise.all([getMeasurements]).then(([responseMeas]) => {
        if (responseMeas && responseMeas.data && responseMeas.data.length > 0) {
          // Callback to pass the latest value to parent
          this.props.latestHumidity(last(responseMeas.data));
        }
        const measSeriesDay = this.constructSeries(responseMeas.data);
        const measSeriesMonth = this.constructSeries(responseMeas.data, true);

        this.setState((prevState) => ({
          optionsDay: {
            ...prevState.optionsDay,
            series: measSeriesDay,
          },
          fetchDone: true,
        }));
        this.setState((prevState) => ({
          optionsMonth: {
            ...prevState.optionsMonth,
            series: measSeriesMonth,
          },
        }));

        this.internalChart.hideLoading();
      });
    }
  }

  afterSetExtremes(e) {
    // Check for e.trigger to only fetch new data after user action
    if (e.trigger && this.state.resolution === "day") {
      if (
        (!e.min && !e.max) ||
        isNaN(e.min) ||
        isNaN(e.max) ||
        (e.max === 43200000 && e.min === -43200000)
      ) {
        return;
      }
      const chart = this.internalChart;

      const startISO = new Date(e.min).toISOString();
      const endISO = new Date(e.max).toISOString();

      chart.showLoading("Loading data from server...");
      const getMeasurements = restClient(GET_LIST, "zonecondition", {
        filter: {
          blockId: this.state.record.id,
          dataBegin: startISO,
          dataEnd: endISO,
          conditionType: "HUMI",
        },
        sort: { field: "UpdateTime", order: "ASC" },
        pagination: {},
      });

      Promise.all([getMeasurements]).then(([responseMeas]) => {
        const measSeries = this.constructSeries(responseMeas.data);

        let allSeries = measSeries;
        // also update the feedback series
        let feedbackSeries = null;
        if (this.props.feedbackSeries) {
          let fbData = createFeedbackSeries({
            referenceSeriesName: "Humidity avg",
            allSeries: allSeries,
            feedbackSeries: this.props.feedbackSeries,
            chart: this.internalChart,
          });
          if (fbData && fbData.length > 0) {
            feedbackSeries = { ...this.props.feedbackSeries, data: fbData };
          }
        }

        // Series data is updated, instead of setting the state again to avoid using some not-wanted initial settins, such as min/max on xAxis and rangeselector selections
        if (feedbackSeries) {
          allSeries = allSeries.concat(feedbackSeries);
        }
        try {
          this.internalChart.update({ series: allSeries }, true, true, false);
          updateFeedbackIcons(this.internalChart);
        } catch (error) {}
        if (!this.state.fetchDone) {
          this.setState({ fetchDone: true });
        }

        this.internalChart.hideLoading();
      });
    }
  }

  constructSeries = (data, monthlyData = false) => {
    const series = [];

    let sData = null;

    sData = map(data, (d) => [d.measured * 1000, d.humis[0]]);
    if (sData && sData.length > 0) {
      series.unshift({
        type: "line",
        name: "Humidity avg",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "average",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    sData = map(data, (d) => [d.measured * 1000, d.humis[1]]);
    if (sData && sData.length > 0) {
      series.push({
        type: "line",
        name: "Humidity min",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "low",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    sData = map(data, (d) => [d.measured * 1000, d.humis[2]]);
    if (sData && sData.length > 0) {
      series.push({
        type: "line",
        name: "Humidity max",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "high",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    return series;
  };

  render() {
    let options = null;
    if (this.state.resolution === "day") {
      options = cloneDeep(this.state.optionsDay);
      // Try to find the feedback series, only add if not found
      let fbSer = find(options.series, (s) => s.name === "Feedbacks");
      if (
        !fbSer &&
        this.props.feedbackSeries &&
        Array.isArray(options.series)
      ) {
        let fbData = createFeedbackSeries({
          referenceSeriesName: "Humidity avg",
          allSeries: options.series,
          feedbackSeries: this.props.feedbackSeries,
          chart: this.internalChart,
        });
        if (fbData && fbData.length > 0) {
          options.series.push({ ...this.props.feedbackSeries, data: fbData });
        }
      }
    } else if (this.state.resolution === "month") {
      options = cloneDeep(this.state.optionsMonth);
    }

    const handleResolutionChange = (e) => {
      this.setState({ resolution: e.target.value });
    };

    const ResolutionInput = (
      <div
        key={"resInputH"}
        style={{
          marginLeft: 15,
          width: 80,
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        <InputLabel>Resolution</InputLabel>
        <Select value={this.state.resolution} onChange={handleResolutionChange}>
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"month"}>Month</MenuItem>
        </Select>
      </div>
    );

    let showGraph = false;
    if (this.state.record.Zone.Humidities.length > 0) {
      showGraph = true;
    }

    if (
      !this.state.record.Zone.Humidities.length > 0 ||
      (this.state.fetchDone &&
        (!options || !options.series || !options.series.length > 0))
    ) {
      showGraph = false;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.fetchDone
    ) {
      if (showGraph) {
        this.props.showContainer("humidity");
      } else {
        this.props.hideContainer("humidity");
      }
    }

    return (
      <CardWithTitle title="Humidity">
        {this.state.record.Zone.Humidities.length > 0 ? (
          [
            ResolutionInput,
            <HighchartsReact
              key={"chrtH"}
              immutable={true}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={cloneDeep(options)}
              callback={this.afterChartCreated}
            />,
          ]
        ) : (
          <div style={{ height: "20rem" }}>
            <EmptyData />
          </div>
        )}
      </CardWithTitle>
    );
  }
}
