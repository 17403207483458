import React from "react";
import { isNil } from "lodash";
import { makeStyles, Grid, Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import alertIcon from "../../../assets/icons/icon_check.svg";

const useStyles = makeStyles({
  root: {
    height: 30,
    width: 120,
    position: "relative",
    backgroundColor: (props) => (props.isRunning ? "#282331" : "transparent"),
    border: "1px solid",
    borderColor: (props) =>
      props.isRunning ? "rgba(40, 35, 49, 0.6)" : "#282331",
    borderRadius: 4,
    margin: "8px 0",
    display: "flex",
    alignItems: "center",
    paddingRight: 24,
    boxShadow: "0px 3px 10px #28233133",
  },
  icon: {
    marginLeft: 5,
    color: (props) => (props.isRunning ? "#fff" : "rgba(40, 35, 49, 0.6)"),
  },
  text: {
    margin: "auto",
    fontSize: 12,
    color: (props) => (props.isRunning ? "#fff" : "rgba(40, 35, 49, 0.6)"),
  },
  alertIcon: {
    color: "#D62B1A",
    height: "100%",
  },
  alertIconContainer: {
    backgroundColor: "transparent",
    height: "1rem",
    width: "1rem",
    zIndex: 10,
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translate(0,-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Pump = ({ pump }) => {
  const isRunning = !isNil(pump) && !isNil(pump.running) && pump.running.value;
  const isRunningTime =
    !isNil(pump) && !isNil(pump.running) && pump.running.time;

  const isAlert = !isNil(pump) && !isNil(pump.alert) && pump.alert.value;
  const isAlertTime = !isNil(pump) && !isNil(pump.alert) && pump.alert.time;

  const classes = useStyles({ isRunning });

  const alertIndicator = (
    <Tooltip
      placement="top"
      title={isAlertTime ? `Alert - ${isAlertTime}` : ""}
    >
      <div className={classes.alertIconContainer}>
        <img className={classes.alertIcon} src={alertIcon} alt="alert" />
      </div>
    </Tooltip>
  );

  return (
    <Tooltip
      title={
        isRunningTime
          ? `${isRunning ? "Running" : "Stopped"} - ${isRunningTime}`
          : ""
      }
    >
      <Grid item className={classes.root}>
        {isRunning ? (
          <CheckIcon className={classes.icon} />
        ) : (
          <ClearIcon className={classes.icon} />
        )}
        {isAlert ? alertIndicator : null}
        <span className={classes.text}>PUMP {pump.pumpNumber}</span>
      </Grid>
    </Tooltip>
  );
};

export default Pump;
