import { BlockType, BuildingBlock } from "../types/leasegreen-core-api-exports";

const twinForExternal = (twin: BuildingBlock): boolean => {
  const twinTypeNormalized: BlockType = twin?.BlockType;
  // Filter out X types of twins, and Building twin and non-active twins
  return twin?.Active &&
    (twinTypeNormalized === "ZONE" ||
      twinTypeNormalized === "SOLAR" ||
      twinTypeNormalized === "HP" ||
      twinTypeNormalized === "Meter" ||
      twinTypeNormalized === "AHU")
};

export default twinForExternal;
