import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Select,
  MenuItem,
  Input,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { filter, first, has, last, isNil, groupBy, round, find } from "lodash";
import HelpIcon from "@material-ui/icons/Help";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import notEnabledIcon from "../../assets/icons/meter_not_enabled.svg";
import useTrendsDataMapper from "../../analytics/datamappers/consumed/useTrendsDataMapper";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

// Create custom input for Select component to allow changing of the underline color
const CustomInput = withStyles({
  underline: {
    "&:after": {
      borderBottomColor: "green",
    },
  },
})(Input);

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#7E7B84",
    fontSize: "0.75rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  targetContainer: {
    width: "100%",
    minHeight: "5.25rem",
    boxSizing: "border-box",
    padding: "1rem",
  },
  value: {
    fontWeight: 600,
    fontSize: "1.125rem",
    color: "#282331",
    marginRight: "0.25rem",
  },
  consumptionContainer: {
    width: "100%",
    minHeight: "5.25rem",
    boxSizing: "border-box",
    borderTop: "1px solid rgba(164, 175, 183, 0.5)",
    display: "flex",
    padding: "1rem",
  },
  select: {
    marginLeft: "auto",
    alignSelf: "center",
  },
  underline: (props) => ({
    "&:after": {
      borderBottomColor: props.color,
    },
  }),
  chartContainer: {
    marginTop: "auto",
    // height: 250,
    width: "100%",
    position: "relative",
  },
  textContainer: {},
  targetText: {
    fontSize: "0.875rem",
    fontWeight: 600,
    textAlign: "center",
  },
  onOffTarget: {
    fontSize: "0.75rem",
    fontWeight: 300,
    textAlign: "center",
  },
  notEnabledContainer: {
    background: "#D5D5D5 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 10px #00000029",
    borderRadius: 2,
    textAlign: "center",
    padding: "21px 25px",
    margin: 21,
    marginTop: "auto",
  },
  notEnabledIcon: {},
  notEnabledText: {
    color: "#000000",
    fontSize: "0.75rem",
  },
}));

const NotEnabled = () => {
  const classes = useStyles();
  return (
    <div className={classes.notEnabledContainer}>
      <img
        className={classes.notEnabledIcon}
        src={notEnabledIcon}
        alt="Metering not enabled"
      />
      <p className={classes.notEnabledText} style={{ marginBottom: 0 }}>
        Metering not enabled!
      </p>
      <p className={classes.notEnabledText} style={{ marginTop: 0 }}>
        Please contact our customer support to enable metering.
      </p>
    </div>
  );
};

const TargetGraph = (props) => {
  const classes = useStyles(props);
  const [timeSpan, setTimeSpan] = useState("3month");
  const [additionalConsumption, setAdditionalConsumption] = useState(null);
  const [, filteredTrends] = useTrendsDataMapper(props.type);

  let showEmpty = false;

  let series = [];
  let seriesType = "area";
  let seriesData = [];
  let consumptionSum = 0;

  let timeSpanData = null;
  let target = null;
  let realized = null;
  let targetPerc = null;
  // let fromTargetAbs = null;
  let baseline = null;
  let actualSavingsAbs = null;

  let valueDivider = 1;
  if (props.type === "electricity") {
    valueDivider = 1000;
  }

  const consumptionSeriesData = [];

  useEffect(() => {
    if (
      filteredTrends &&
      (props.type === "electricity" || props.type === "cooling")
    ) {
      setAdditionalConsumption(filteredTrends);
    }
  }, [props.type, filteredTrends]);

  if (
    props &&
    props.buildingTwin &&
    props.buildingTwin.Energy &&
    props.buildingTwin.Energy.EnergySavings
  ) {
    timeSpanData = filter(props.buildingTwin.Energy.EnergySavings, (s) => {
      return (
        s.Type === props.type &&
        s.Period === timeSpan &&
        s.Unit !== "percentage"
      );
    });

    realized = first(
      filter(props.buildingTwin.Energy.EnergySavings, (s) => {
        return (
          s.Type === props.type &&
          s.Description === "realized" &&
          s.Period === timeSpan &&
          s.Unit === "percentage"
        );
      })
    );
    targetPerc = first(
      filter(props.buildingTwin.Energy.EnergySavings, (s) => {
        return (
          s.Type === props.type &&
          s.Description === "target" &&
          s.Period === timeSpan &&
          s.Unit === "percentage"
        );
      })
    );

    // fromTargetAbs = null;
    // const t = find(props.buildingTwin.Energy.EnergySavings, (s) => {
    //   return (
    //     s.Type === props.type &&
    //     s.Description === "target" &&
    //     s.Period === timeSpan &&
    //     s.Unit !== "percentage"
    //   );
    // });
    const r = find(props.buildingTwin.Energy.EnergySavings, (s) => {
      return (
        s.Type === props.type &&
        s.Description === "realized" &&
        s.Period === timeSpan &&
        s.Unit !== "percentage"
      );
    });

    // if (r && r.Value && t && t.Value) {
    //   fromTargetAbs = round(r.Value - t.Value, 2);
    // }

    const targetEnergy = first(
      filter(timeSpanData, (s) => {
        return s.Description === "target" && s.Value !== null;
      })
    );

    // Calculate baseline from targetPerc and targetEnergy
    if (
      targetEnergy &&
      targetEnergy.Value !== null &&
      targetPerc &&
      targetPerc.Value !== null
    ) {
      baseline = targetEnergy.Value / (1 - targetPerc.Value / 100);
    }

    // ELECTRICITY IS SCALED TO MWh
    if (targetEnergy) {
      target = {
        Value: targetEnergy.Value / valueDivider
      };
    }

    // Calculate actual amount of savings
    // ELECTRICITY IS SCALED TO MWh
    if (baseline !== null && r && r.Value !== null) {
      actualSavingsAbs = round(r.Value / valueDivider, 2);
    }
  }
  // Form data series
  // Check if baseline data exists
  let baselineData = null;
  if (has(props, "buildingTwin.MonthlyBaselines")) {
    baselineData = filter(props.buildingTwin.MonthlyBaselines, (b) => {
      if (b.Desc.toLowerCase().includes(props.type.toLowerCase().slice(0, 4))) {
        return true;
      } else return false;
    });
  }
  // Get starting month and year from the latest data
  const filteredCons = filter(
    props.consumptionData,
    (c) =>
      c.Type.toLowerCase() === props.type.toLowerCase() &&
      (c.Source.toLowerCase() === "manual" ||
        c.Source.toLowerCase() === "metry" ||
        c.Source.toLowerCase() === "manual & hp total" ||
        c.Source.toLowerCase() === "metry & hp total" ||
        c.Source.toLowerCase() === "hp total")
  );
  const lastData = last(filteredCons);
  let curMonth = new Date().getMonth();
  let curYear = new Date().getFullYear();
  if (lastData) {
    curMonth = new Date(lastData.Time).getMonth();
    curYear = new Date(lastData.Time).getFullYear();
  }

  // Always use the last day of the "newest" month and calculate back from that
  let oldestMonth = curMonth - 12;
  let startDate = new Date(curYear, curMonth, 0);
  let endDate = new Date(curYear, oldestMonth, 1);

  let timespanText = "";
  const y = new Date().getFullYear();
  const m = new Date().getMonth();

  switch (timeSpan) {
    case "3month":
      oldestMonth = curMonth - 3;
      startDate = new Date(curYear, curMonth + 1, 0);
      endDate = new Date(curYear, oldestMonth + 1, 1);
      timespanText = "3 months";
      break;

    case "12month":
      oldestMonth = curMonth - 12;
      startDate = new Date(curYear, curMonth + 1, 0);
      endDate = new Date(curYear, oldestMonth + 1, 1);
      timespanText = "12 months";
      break;

    case "last year":
      startDate = new Date(y, 0, 0);
      endDate = new Date(y - 1, 0, 1);
      timespanText = "Last year";
      break;

    case "this year":
      startDate = new Date(y, m + 1, 0);
      endDate = new Date(y, 0, 1);
      timespanText = "This year";
      break;

    case "all":
      startDate = new Date();
      endDate = new Date(0, 0, 0);
      timespanText = "All time";
      break;

    default:
      oldestMonth = curMonth - 12;
      startDate = new Date(curYear, curMonth + 1, 0);
      endDate = new Date(curYear, oldestMonth, 1);
      break;
  }

  // After the start and end dates have been gotten, create the consumption series and then the other ones based on that

  let consData = [];
  // Additional consumption data
  if (additionalConsumption && additionalConsumption.length > 0) {
    // Check that the timespan is correct
    for (let i = 0; i < additionalConsumption.length; i++) {
      const date = new Date(
        additionalConsumption[i].year,
        additionalConsumption[i].month - 1,
        1
      );
      // Custom divider
      let divider = 1000;

      if (date <= startDate && date >= endDate) {
        consData.push({
          year: additionalConsumption[i].year,
          value: additionalConsumption[i].value / divider,
          month: additionalConsumption[i].month,
        });
      }
    }
  }

  if (props && props.consumptionData) {
    let consumptionData = filter(props.consumptionData, (c) => {
      return c.Type.toLowerCase() === props.type.toLowerCase();
    });

    consumptionData = consumptionData.reverse();

    // Only get the data relevant to the timeSpan
    if (consumptionData && consumptionData.length > 0) {
      for (let i = 0; i < consumptionData.length; i++) {
        const date = new Date(consumptionData[i].Time);

        if (date <= startDate && date >= endDate) {
          // CUSTOM DIVIDER FOR SOME CONSUMPTION TO GET CORRECT UNIT
          let divider = 1;
          if (props.type === "electricity") {
            divider = 1000;
          }
          // Heating uses normalized consumption data
          consData.push({
            date: consumptionData[i].Time,
            value:
              props.type === "heating"
                ? consumptionData[i].ConsNorm
                : consumptionData[i].Consumption / divider,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            source: consumptionData[i].Source,
          });
        }
      }
    }

    if (consData && consData.length > 0) {
      consData = consData.reverse();
      const grouped = groupBy(consData, (c) => c.year);
      const gKeys = Object.keys(grouped);
      gKeys.forEach((y) => {
        const months = groupBy(grouped[y], (m) => m.month);
        const mKeys = Object.keys(months);
        mKeys.forEach((m) => {
          let total = 0;
          months[m].forEach((mv) => {
            // Heating graph uses values based on the source
            if (props.type === "heating") {
              let hpDataExists = false;
              months[m].forEach((d) => {
                if (d.source.toLowerCase().includes("hp total")) {
                  hpDataExists = true;
                }
              });
              if (hpDataExists) {
                if (
                  mv.source.toLowerCase() === "manual & hp total" ||
                  mv.source.toLowerCase() === "metry & hp total" ||
                  mv.source.toLowerCase() === "hp total"
                ) {
                  total += mv.value;
                }
              } else {
                if (
                  mv.source.toLowerCase() === "manual" ||
                  mv.source.toLowerCase() === "metry"
                ) {
                  total += mv.value;
                }
              }
            } else {
              total += mv.value;
            }
          });
          consumptionSeriesData.push({
            value: round(total, 2),
            year: first(months[m]).year,
            month: first(months[m]).month,
          });
        });
      });

      // Get count of datapoints, if only 2, make the series a column instead of area, also get the total consumption sum
      seriesData = [];
      if (consumptionSeriesData && consumptionSeriesData.length > 0) {
        let count = 0;
        consumptionSum = 0;
        consumptionSeriesData.forEach((d) => {
          if (d !== null) {
            let val = isNil(d.value) ? null : d.value;
            if (val) {
              count++;
            }
            let current = new Date();
            let date = new Date(d.year, d.month - 1, 2);

            consumptionSum += val;
            seriesData.push([
              new Date(
                date.getTime() - current.getTimezoneOffset() * 60000
              ).getTime(),
              val,
              new Date(
                date.getTime() - current.getTimezoneOffset() * 60000
              ).getMonth() + 1,
            ]);
          }
        });
        seriesType = count >= 2 ? "area" : "column";
      }
    }

    const dataSeries = {
      type: seriesType,
      data: seriesData,
      showInLegend: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
      name: props.type.charAt(0).toUpperCase() + props.type.substring(1),
      // Capitalize first letter
    };

    // Form the target series
    let targetSeries = null;
    if (
      props &&
      props.buildingTwin &&
      props.buildingTwin.MonthlyTargets &&
      props.buildingTwin.MonthlyTargets.length > 0
    ) {
      targetSeries = targetMapping(
        props.buildingTwin.MonthlyTargets,
        props.type,
        seriesData
      );
    }

    // Form the baseline data
    let blData = [];
    // Loop through the data and create target series according to that
    if (seriesData && seriesData.length > 0) {
      if (baselineData && baselineData.length > 0) {
        seriesData.forEach((d) => {
          let mBl = find(baselineData, (b) => b.Month === d[2]);
          const target = mBl && mBl.Target ? mBl.Target : null;
          blData.push([d[0], target / valueDivider]);
        });
      }
    }

    const blSeries = {
      showInLegend: false,
      name: "Baseline",
      data: blData,
      type: "line",
      color: "#282331",
      dashStyle: "dash",
      lineWidth: 1,
    };

    // Push consumption data & baseline data
    series.push(dataSeries);
    series.push(blSeries);
    if (targetSeries) {
      series.push(targetSeries);
    }
    // Do not show graph if no dataSeries
    if (!dataSeries) {
      showEmpty = true;
    } else if (dataSeries && dataSeries.data.length === 0) {
      showEmpty = true;
    }
    if (showEmpty) {
      props.hideResource(props.type);
    } else {
      props.showResource(props.type);
    }
  }

  // Change series colors depending on props
  let g1 = "#ffeed6";
  let g2 = "#fffdfa";
  let c = props.color;
  if (props.type === "heating") {
    g1 = "#ffeed6";
    g2 = "#fffdfa";
    c = props.color;
  } else if (props.type === "water") {
    g1 = "#d4e2f7";
    g2 = "#edf3fc";
    c = props.color;
  } else if (props.type === "electricity") {
    g1 = "#cfebe0";
    g2 = "#f5fbf9";
    c = props.color;
  } else if (props.type === "cooling") {
    g1 = "#d3f4f7";
    g2 = "#ecfafb";
    c = props.color;
  }

  const options = {
    chart: {
      type: "area",
      margin: [15, -5, 0, -5],
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: "",
    },
    series: series,
    yAxis: {
      visible: false,
      title: {
        text: "",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "downloadPNG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
    tooltip: {
      shared: true,
      xDateFormat: "%b '%y",
      pointFormatter: function () {
        let symbol = "";

        function setSymbol(symbolName) {
          switch (symbolName) {
            case "circle":
              symbol = "●";
              break;
            case "diamond":
              symbol = "♦";
              break;
            case "square":
              symbol = "■";
              break;
            case "triangle":
              symbol = "▲";
              break;
            case "triangle-down":
              symbol = "▼";
              break;
            default:
              symbol = "●";
              break;
          }
        }

        if (this.graphic && this.graphic.symbolName) {
          // when marker is enabled
          setSymbol(this.graphic.symbolName);
        } else if (this.marker && this.marker.symbol) {
          var url = this.marker.symbol.replace(/^url\(|\)$/g, "");
          symbol = '<img src="' + url + '" alt="Marker" />';
        } else if (this.series.symbol) {
          // when marker is disabled
          setSymbol(this.series.symbol);
        }

        return (
          '<span style="color:' +
          this.series.color +
          '">' +
          symbol +
          "</span>" +
          " " +
          this.series.name +
          ": " +
          round(this.y, 2) +
          "<br/>"
        );
      },
      useHTML: true,
    },
    plotOptions: {
      series: {
        pointPlacement: seriesType === "area" ? "on" : undefined,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, g1],
            [1, g2],
          ],
        },
        color:
          seriesType === "area"
            ? c
            : {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, c],
                [1, g2],
              ],
            },
        marker: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      rangeSelector: {
        selected: 1,
      },
      tickmarkPlacement: seriesType === "area" ? null : "between",
      type: "datetime",
    },
  };

  const handleChange = (event) => {
    setTimeSpan(event.target.value);
  };

  let unit = "MWh";
  if (props.type === "water") {
    unit = "m³";
  }

  let typeText = "Energy ";
  if (props.type === "water") {
    typeText = "Water ";
  } else if (props.type === "electricity") {
    typeText = "Electricity";
  }
  let targetText = null;
  // let targetTextAbs = null;
  let actualSavingsAbsText = null;
  let onOffText = "Actual savings";
  let targetColor = "#282331";
  let onOffColor = "#7D7A83";
  // let onTarget = true;
  if (realized && realized.Value) {
    targetText = round(realized.Value, 1) + " %";
    // if (fromTargetAbs) {
    //   targetTextAbs = round(fromTargetAbs, 2) + " " + unit;
    // }
    if (actualSavingsAbs !== null) {
      actualSavingsAbsText = round(actualSavingsAbs, 2) + " " + unit;
    }
    if (realized && targetPerc) {
      if (realized.Value >= targetPerc.Value) {
        targetColor = "#282331";
      } else if (realized.Value < targetPerc.Value && realized.Value !== null) {
        // onTarget = false;
        targetColor = "#D62B1A";
        onOffColor = "#D62B1A";
      }
    }
  }

  // if (showEmpty) {
  //   return (
  //     <div className={classes.root}>
  //       <div className={classes.targetContainer}></div>
  //       <div className={classes.consumptionContainer}></div>
  //       <div className={classes.chartContainer}></div>
  //     </div>
  //   );
  // }
  return (
    <div className={classes.root}>
      <div className={classes.targetContainer}>
        <Typography>{typeText} reduction target</Typography>
        <Typography>
          <span
            className={classes.value}
          // style={{ color: !onTarget && "#D62B1A" }}
          >
            {target && target.Value ? round(target.Value, 2) : "--"}
          </span>
          <span style={{ marginRight: 5 }}>{unit + " / "}</span>
          <span
            className={classes.value}
          // style={{ color: !onTarget && "#D62B1A" }}
          >
            {targetPerc && targetPerc.Value ? round(targetPerc.Value, 1) : "--"}
          </span>
          <span>{"% "}</span>
          {" / " + timespanText}
        </Typography>
      </div>
      <div className={classes.consumptionContainer}>
        <div>
          <Typography>
            {typeText} consumed
            {props.type === "heating" ||
              props.type === "electricity" ||
              props.type === "cooling" ? (
              <span style={{ marginLeft: 5 }}>
                <Tooltip
                  title="Includes purchased & produced energy"
                  placement="top"
                >
                  <span>
                    <HelpIcon fontSize="small" />
                  </span>
                </Tooltip>
              </span>
            ) : null}
          </Typography>
          <Typography>
            <span className={classes.value}>
              {consumptionSum ? consumptionSum.toFixed(2) : "--"}
            </span>
            {unit}
          </Typography>
        </div>
        <div className={classes.select}>
          <Select
            value={timeSpan}
            onChange={handleChange}
            input={<CustomInput classes={{ underline: classes.underline }} />}
          >
            <MenuItem value={"3month"}>3 months</MenuItem>
            <MenuItem value={"12month"}>12 months</MenuItem>
            <MenuItem value={"last year"}>Last year</MenuItem>
            <MenuItem value={"this year"}>This year</MenuItem>
            <MenuItem value={"all"}>All</MenuItem>
          </Select>
        </div>
      </div>
      {targetText ? (
        <div className={classes.textContainer}>
          <Typography
            className={classes.targetText}
            style={{ color: targetColor }}
          >
            {actualSavingsAbsText ? actualSavingsAbsText + " /" : null}{" "}
            {targetText}
          </Typography>
          <Typography
            className={classes.onOffTarget}
            style={{ color: onOffColor }}
          >
            {onOffText}
          </Typography>
        </div>
      ) : null}
      <div className={classes.chartContainer}>
        {options && !showEmpty ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: 250 } }}
          />
        ) : (
          <NotEnabled />
        )}
      </div>
    </div>
  );
};

export default TargetGraph;

const targetMapping = (targets, type, seriesData) => {
  // Try to find a suitable target description
  let targetType = "";
  if (type === "heating") {
    targetType = "BUILDING_HEAT_TARGET";
  } else {
    targetType = "BUILDING_" + type.toUpperCase() + "_TARGET";
  }

  // Factor for some types is different than 1
  let targetDivider = 1;
  // Electricity target is changed to MWh
  if (type === "electricity") {
    targetDivider = 1000;
  }

  // Filter targets
  const filteredTargets = filter(targets, (t) => {
    return t.Desc === targetType;
  });

  if (filteredTargets && filteredTargets.length > 0) {
    let targetData = [];

    // Map the consumptiondata according to the used months
    seriesData.forEach((m) => {
      // Get all consumption with the same month
      const mTar = filter(filteredTargets, (f) => {
        return f.Month === m[2];
      });
      // Sum the consumptions for that month
      if (mTar && mTar.length > 0) {
        let total = 0;
        mTar.forEach((mt) => {
          total += mt.Target;
        });
        targetData.push([m[0], round(total / targetDivider, 2)]);
      } else {
        targetData.push(null);
      }
    });

    return {
      name: "Target",
      data: targetData,
      type: "line",
      zIndex: 10,
      color: "#282331",
      lineWidth: 1,
      showInLegend: false,
    };
  } else {
    return null;
  }
};
