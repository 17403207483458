import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  Show,
  SimpleShowLayout,
  Pagination,
} from "react-admin";
import { useSelector } from "react-redux";

import { ipToHttpLink } from "../utils/ipToHttpParser";
import { SourceBuilding } from "../alerts/AlertShow";
import PageTitle from "../components/layout/PageTitle";

const RemoteConnectionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by building/project" source="q" alwaysOn />
  </Filter>
);

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 1000]} {...props} />
);

const RemoteConnectionExpand = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField source="Interface" />
      <TextField source="ConnectionType" />
      <DateField source="InstallationDate" />
      <DateField source="ReturnedDate" />
      <TextField source="PCUsername" label="PC username" />
      <TextField source="PCPassword" label="PC password" />
      <TextField source="BMSUsername" label="BMS username" />
      <TextField source="BMSPassword" label="BMS password" />
      <TextField source="TosiboxLANIP" label="Tosibox LAN IP" />
      <TextField source="DHCP" label="DHCP" />
      <TextField source="AdditionalInformation" />
      <TextField source="BMSAlterPolicy" label="BMS alter policy" />
      <TextField source="TosiboxLocation" />
    </SimpleShowLayout>
  </Show>
);

export const RemoteConnectionList = (props) => {
  const selectedBuilding = useSelector(
    (state) => state.buildingFilter.ProjectName
  );
  return (
    <>
      <PageTitle title="Remote Connections" />
      <List
        {...props}
        perPage={1000}
        pagination={<ValuesPagination />}
        bulkActionButtons={false}
        filters={<RemoteConnectionFilter />}
        filterDefaultValues={{
          q: selectedBuilding,
        }}
        sort={{ field: "ProjectName", order: "ASC" }}
      >
        <Datagrid expand={<RemoteConnectionExpand />}>
          <TextField source="BuildingName" label="Building name" />
          <SourceBuilding sortBy={"BuildingId"} label="Project" />
          <TextField source="SIMCardNumber" label="SIM card number" />
          <FunctionField
            sortBy="NATIPSort"
            render={(record) => ipToHttpLink(record.TosiboxNATIP)}
            label="Tosibox NAT IP"
          />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};
