import React from "react";
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  // ReferenceInput,
  // SelectInput,
  EditButton,
  Filter,
  TextInput,
  NumberField,
  Pagination,
} from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const FunctionFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      {/*
    <ReferenceInput
      label="Building"
      source="BuildingId"
      reference="buildings"
      sort={{ field: "ProjectName", order: "ASC" }}
      filter={{ RegisterListsVisible: true }}
      alwaysOn
    >
      <SelectInput optionText="ProjectName" optionValue="id" />
    </ReferenceInput>
    */}
      <TextInput label="Search" source="q" className={classes.helperText} />
    </Filter>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const RegisterListsList = ({
  BuildingId,
  projectname,
  ...props
}) => {
  const { isAdminOrUser } = useUserRole();
  return (
    <>
      <PageTitle title="Registers" />
      <List
        {...props}
        perPage={25}
        pagination={<ValuesPagination />}
        filter={{ BuildingId }}
        filters={<FunctionFilter />}
        bulkActionButtons={false}
      >
        <Datagrid>
          <NumberField source="id" options={{ useGrouping: false }} />
          <NumberField
            source="RegisterNumber"
            options={{ useGrouping: false }}
          />
          <TextField source="PointId" />
          <TextField source="Description" />
          <FunctionField
            render={(record) => {
              return (
                <Link to={`/busadapters/${record.BusAdapterId}`}>
                  {record.BusAdapterId}
                </Link>
              );
            }}
            label="Bus adapter"
          />
          <TextField source="Unit" />
          {isAdminOrUser && (
            <EditButton style={{ padding: 0 }} />
          )}
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(RegisterListsList);
