import React, { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberField,
  Pagination,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import classnames from "classnames";

import { stateMapper } from "../customReducers/connectFunctions";
import { Link } from "react-router-dom";
import DateFieldTz from "../utils/DateFieldTz";
import DateTimeWithTimezoneInput from "../components/TimeInputs";
import PageTitle from "../components/layout/PageTitle";

const useStyles = makeStyles(() => ({
  toolBar: {
    "& .MuiToolbar-root form": {
      transition: "margin-bottom .5s ease",
    },
  },
  toolBarMargin: {
    "& .MuiToolbar-root form": {
      marginBottom: 60,
    },
  },
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const ValueStorageFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <DateTimeWithTimezoneInput
        source="ValuesFrom"
        label="Values from"
        alwaysOn
      />
      <DateTimeWithTimezoneInput source="ValuesTo" label="Values to" alwaysOn />
      <SelectInput
        source="DataType"
        choices={[
          { id: "FUNCTION", name: "FUNCTION" },
          { id: "ILFORECAST", name: "ILFORECAST" },
          { id: "ILWEATHER", name: "ILWEATHER" },
          { id: "OVAPORT", name: "OVAPORT" },
          { id: "RAU", name: "RAU" },
        ]}
        allowEmpty
        className={classes.helperText}
      />
      <TextInput label="Search" source="q" />
    </Filter>
  );
};

const ValueStorageListsTitle = (projectname, BuildingId) => {
  var title = "";
  projectname === "none"
    ? (title = " No building selected")
    : (title = (
        <Link
          to={`/buildings/${BuildingId}/show`}
          style={{ textDecoration: "none", color: "white" }}
        >
          Valuestorage list for {projectname}
        </Link>
      ));
  return title;
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const startDate = () => {
  const start = new Date();
  start.setMinutes(start.getMinutes() - 15);
  return start.toISOString();
};

const ValueStorageList = ({ BuildingId, projectname, ...props }) => {
  const classes = useStyles();
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  let selectedIds = [];

  const tz = get(listProps, "building.TimeZoneIANA", null);

  const ListGrid = (props) => {
    // Only allow multiselection of unique variable names
    let selectedVariables = [];
    if (props && props.data && selectedIds && selectedIds.length > 0) {
      const keys = Object.keys(props.data);
      keys.forEach((k) => {
        if (
          selectedIds.includes(parseInt(k)) &&
          !selectedVariables.includes(props.data[k].VariableName)
        ) {
          selectedVariables.push(props.data[k].VariableName);
        }
      });
    }
    return (
      <Fragment>
        <Datagrid
          {...props}
          isRowSelectable={(record) => false}
        >
          <DateFieldTz
            source="UpdateTime"
            label={tz ? "Update time" : "Update time (UTC)"}
            showTime
            timeZone={tz}
          />
          <TextField source="VariableName" />
          <TextField source="Description" />
          <NumberField source="AggregatedValue" label="Value" options={{ useGrouping: false }} />
          <TextField source="DataType" />
        </Datagrid>
      </Fragment>
    );
  };
  return (
    <>
      <PageTitle title="Valuestorage" />
      <List
        className={classnames(
          classes.toolBar
        )}
        {...listProps}
        perPage={50}
        pagination={<ValuesPagination />}
        filterDefaultValues={{
          ValuesFrom: startDate(),
          ValuesTo: new Date().toISOString(),
          DataType: "RAU",
        }}
        sort={{ field: "UpdateTime", order: "DESC" }}
        filter={{ BuildingId }}
        filters={<ValueStorageFilter />}
        title={ValueStorageListsTitle(projectname, BuildingId)}
      >
        <ListGrid />
      </List>
    </>
  );
};

export default stateMapper(ValueStorageList);
