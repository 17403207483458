import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import { setReminderCount, setReminders } from "../customReducers/actions";
import { isValidReminder, isWarningReminder } from "../reminders/Reminders";
import { restClient } from "../App";

function useReminders(id = null) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [viewVersion, setViewVersion] = useState(null);
  const curViewVersion = useSelector((state) => state.admin.ui.viewVersion);

  // Fetch new data when refresh button clicked/new data is put)
  useEffect(() => {
    if (viewVersion !== curViewVersion && curViewVersion !== 1) {
      setViewVersion(curViewVersion);
      dataProvider
        .getList("reminders", {
          filter: { BuildingId: id },
          sort: { field: "id", order: "ASC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.length > 0) {
              const validReminders = [];
              data.forEach((d) => {
                if (isValidReminder(d) && isWarningReminder(d)) {
                  validReminders.push(d);
                  // Auto-pin functionality
                  if (isWarningReminder(d) && !d.Pinned) {
                    const params = {
                      id: d.id + "/pin",
                      data: {},
                    };
                    restClient("UPDATE", "operationlog", params)
                      .then(() => {})
                      .catch((e) => {
                        notify(e.message, "warning");
                      });
                  }
                }
              });
              dispatch(setReminderCount(validReminders.length));
              dispatch(setReminders(validReminders));
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        });
    }
  }, [dispatch, notify, curViewVersion, viewVersion, dataProvider, id]);

  return null;
}

export default useReminders;
