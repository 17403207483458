import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  SelectInput,
  TextField,
  TextInput,
  Pagination,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const FunctionFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput
        label="Search name/description"
        source="q"
        className={classes.helperText}
      />
      <SelectInput
        label="Type"
        source="BlockType"
        choices={[
          { id: "AHU", name: "AHU" },
          { id: "HP", name: "HP" },
          { id: "HX", name: "HX" },
          { id: "Meter", name: "METER" },
          { id: "SOLAR", name: "SOLAR" },
          { id: "ZONE", name: "ZONE" },
        ]}
        className={classes.helperText}
        alwaysOn
      />
    </Filter>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const BlockList = ({ BuildingId, projectname, ...props }) => {
  const { isAdminOrUser } = useUserRole();
  // Remove unused props
  const listProps = Object.assign({}, props);
  delete listProps.dispatch;
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;

  return (
    <>
      <PageTitle title="Digital Twins" />
      <List
        {...listProps}
        pagination={<ValuesPagination />}
        filter={{ BuildingId }}
        filters={<FunctionFilter />}
        bulkActionButtons={false}
      >
        <Datagrid
          rowClick={(id, basePath, record) =>
            record.BlockType === "BUILDING" ? "/dashboard" : "show"
          }
        >
          <TextField source="id" />
          <TextField label="Twin name" source="BlockName" />
          <TextField label="Twin description" source="BlockDescription" />
          <TextField label="Twin type" source="BlockType" />
          <DateField source="Updated" />
          <BooleanField source="Active" />

          {isAdminOrUser && <EditButton />}
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(BlockList);
