import React from "react";
import emptyGraph from "../../assets/icons/vector_empty_graph.svg";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    color: "#282331",
    opacity: "30%",
    fontStyle: "italic",
    fontSize: "1.5rem",
    fontWeight: 600
  }
}));

const EmptyData = props => {
  const classes = useStyles();

  return (
    <div style={props.style} className={classes.root}>
      <img src={emptyGraph} style={props.imgStyle} alt="No Data" />
      <Typography style={props.textStyle} className={classes.text}>
        Not available
      </Typography>
    </div>
  );
};

export default EmptyData;
