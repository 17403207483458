import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useRefresh, fetchStart, fetchEnd, Button } from "react-admin";
import useCustomFetch from "../utils/hooks/useCustomFetch";

const UncheckFeedbackbutton = ({ record, ids }) => {
  const [loading, setLoading] = useState(false);

  const refresh = useRefresh();
  const customFetch = useCustomFetch();
  const dispatch = useDispatch();

  const handleClick = async () => {
    try {
      setLoading(true);
      dispatch(fetchStart());
      await customFetch({
        url: "blockfeedbacks/check",
        method: "POST",
        body: JSON.stringify({
          feedbackIds: ids || [record.id],
          checked: false,
        }),
        headers: [
          { name: "Accept", value: "application/json" },
          { name: "Content-Type", value: "application/json" },
        ],
      });
    } catch (error) {
    } finally {
      setLoading(false);
      dispatch(fetchEnd());
      refresh();
    }
  };

  return (
    <Button
      variant="outlined"
      label="Uncheck"
      onClick={handleClick}
      disabled={loading}
    />
  );
};

export default UncheckFeedbackbutton;
