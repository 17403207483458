import React from "react";
import { withRouter } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { mapper } from "../../customReducers/connectFunctions";
import { restClient } from "../../App";
import { GET_LIST } from "react-admin";
import { first, filter } from "lodash";

let buildings = [];

const styles = {
  theme: {
    container: {
      width: "90%",
      maxWidth: "35rem",
      position: "relative",
      marginRight: 15,
    },
    input: {
      boxSizing: "border-box",
      width: "100%",
      height: "2.125rem",
      padding: "8px 0px 10px 19px",
      border: "1px solid #a4afb7",
      borderRadius: "2px",
      background: "#F0F0F7 0% 0% no-repeat padding-box",
      marginLeft: 10,
      fontFamily: [
        '"Segoe UI"',
        "Tahoma",
        "Geneva",
        "Verdana",
        "sans-serif",
      ].join(","),
      fontSize: "1rem",
      fontWeight: 600,
      boxShadow: "0px 3px 10px #00000029",
    },
    suggestionsContainerOpen: {
      boxSizing: "border-box",
      marginLeft: 10,
      display: "block",
      position: "absolute",
      // padding: 10,
      // right: 0,
      top: 40,
      width: "100%",
      maxWidth: "35rem",
      border: "1px solid #a4afb7",
      backgroundColor: "#f0f0f7",
      fontWeight: 300,
      fontSize: 16,
      color: "#000",
      maxHeight: 300,
      overflowY: "auto",
      zIndex: 2,
    },
    suggestion: {
      cursor: "pointer",
      padding: 20,
      borderTop: "1px solid #a4afb7",
      marginLeft: 15,
      marginRight: 15,
      // backgroundColor: "#FFF",
      // color: "#010101"
    },
    suggestionFirst: {
      borderTop: "none",
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
    suggestionHighlighted: {
      color: "#1ad689",
      // backgroundColor: "#ddd",
      fontWeight: "700",
    },
  },
};

const getSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength !== 0
    ? buildings.filter((building) =>
        building.ProjectName !== null
          ? building.ProjectName.toLowerCase().includes(inputValue)
          : null
      )
    : buildings;
};

const getSuggestionValue = (suggestion) => {
  return suggestion.ProjectName;
};

const renderSuggestion = (suggestion) => {
  return <span>{suggestion.ProjectName}</span>;
};

class BuildingSelect extends React.Component {
  constructor(props) {
    super(props);
    this.changebuilding = props.changebuilding.bind(this);
    this.setBuildings = props.setBuildings.bind(this);
    this.prevSelected = false;
    this.prevProjectName = props.projectname;
    this.state = {
      value: "",
      suggestions: [],
    };
  }

  componentDidMount() {
    restClient(GET_LIST, "2.0/buildings", {
      sort: { field: "ProjectName", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
      filter: { ShowBAUI: true },
    })
      .then((response) => response.data)
      .then((response) => {
        // CHANGE TO API 2.0
        // In the new API, the property ProjectName has been renamed to
        // projectName, the old property is created manually
        // because of the amount of usage
        const editedRes =
          response && response.length > 0
            ? response.map((r) => ({ ...r, ProjectName: r.projectName }))
            : [];
        this.setBuildings(editedRes);
        // Check if a building is set at sessionStorage
        let buildingId = sessionStorage.getItem("BuildingId");
        let selectedBuilding = null;
        if (buildingId) {
          // Add a building as payload that has the same id as sessionStorage
          selectedBuilding = filter(editedRes, function (b) {
            return b.id === parseInt(buildingId);
          });
          if (selectedBuilding && selectedBuilding[0]) {
            this.setState({ value: selectedBuilding[0].ProjectName });
            setBuilding(
              selectedBuilding[0],
              false,
              this.changebuilding,
              this.props.location.pathname
            );
          } else {
            sessionStorage.setItem("BuildingId", null);
            sessionStorage.setItem("BuildingTwinId", null);
          }
        }
      });
  }

  componentDidUpdate() {
    if (
      this.prevSelected !== this.props.buildingSelected ||
      this.prevProjectName !== this.props.projectname
    ) {
      this.prevSelected = this.props.buildingSelected;
      this.prevProjectName = this.props.projectname;
      if (!this.props.buildingSelected && this.state.value !== "") {
        this.setState({ value: "" });
      } else if (this.props.buildingSelected && this.props.projectname !== "") {
        this.setState({ value: this.props.projectname });
      }
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      userInput: true,
    });
  };

  onBlur = () => {
    // On blur, set the state as the currently selected building
    if (this.props.projectname) {
      this.setState({
        value: this.props.projectname,
      });
    } else {
      this.setState({
        value: "",
      });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    setBuilding(suggestion, true, this.changebuilding);
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search buildings",
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };
    buildings = this.props.buildings;
    if (value !== "") {
      styles.theme.input.backgroundColor =
        styles.theme.suggestionsContainerOpen.backgroundColor;
    } else {
      styles.theme.input.backgroundColor = null;
    }
    return (
      <Autosuggest
        style={{ width: "35%" }}
        theme={styles.theme}
        className={styles.theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
      />
    );
  }
}

const enhance = compose(mapper, withRouter, withStyles(styles));

export default enhance(BuildingSelect);

export const setBuilding = (building, redirect, setter, curPath = "/") => {
  restClient(GET_LIST, "blocks", {
    filter: {
      BuildingId: building.id,
      BlockType: "BUILDING",
    },
    sort: {},
    pagination: {},
  })
    .then((response) => response.data)
    .then((blocks) => {
      let buildingBlock = first(blocks);
      let redirectTo;
      let buildingTwinId = null;
      if (buildingBlock) {
        buildingTwinId = buildingBlock.id;
        if (redirect) {
          redirectTo = "/dashboard";
        } else {
          redirectTo = curPath;
        }
      } else if (building.id) {
        if (redirect) {
          redirectTo = "/dashboard";
        } else {
          redirectTo = this.props.location.pathname;
        }
      }
      setter(building, redirectTo, buildingTwinId);
    });
};
