import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  SimpleForm,
  useDataProvider,
  AutocompleteArrayInput,
  useNotify,
  fetchStart,
  fetchEnd,
  useQueryWithStore,
  TextField,
} from "react-admin";
import { Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../components/layout/PageTitle";
import { useMsal } from '@azure/msal-react';

const GuestUserEdit = (props) => {
  const { instance: msalInstance } = useMsal();
  const [userBuildings, setUserBuildings] = useState([]);
  const buildings = useSelector((state) => state.buildingFilter.buildings);
  const guestUserStore = useSelector(
    (state) => state.admin.resources["guestusers"]
  );
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const history = useHistory();

  const guestUsers = guestUserStore ? guestUserStore.data : null;
  let selectedUser = null;

  if (guestUsers) {
    for (const key in guestUsers) {
      if (guestUsers.hasOwnProperty(key)) {
        if (key === props.id) {
          selectedUser = guestUsers[key];
        }
      }
    }
  }

  // Get user data
  let { loaded } = useQueryWithStore({
    type: "getList",
    resource: "guestusers",
    payload: {
      filter: {},
      sort: {},
      pagination: {},
    },
  });

  // Create the building selections for the select input
  const buildingChoices = [];
  if (buildings) {
    buildings.forEach((b) => {
      buildingChoices.push({
        id: b.id,
        name: b.ProjectName,
      });
    });
  }

  const getUserBuildings = useCallback(async () => {
    const res = await dataProvider.getOne("guestUserEdit", {
      id: props.id,
    });
    setUserBuildings(res.data);
  }, [props.id, dataProvider]);

  useEffect(() => {
    getUserBuildings();
  }, [dataProvider, props.id, getUserBuildings]);

  const handleSave = async (formData) => {
    const token = await msalInstance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
    })
      .catch((error) => {
        msalInstance.loginRedirect();
      });
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token.accessToken);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    dispatch(fetchStart());
    const updatedRecord = formData;
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(updatedRecord),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/user/${props.id}/buildings`,
      requestOptions
    )
      .then(() => {
        notify("Buildings changed for user");
        history.push("/guestusers");
      })
      .catch(() => {
        notify("Failed to change buildings", "warning");
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  if (!selectedUser && loaded) {
    selectedUser = {
      displayName: "Unknown user",
      id: "Unknown user",
      mail: "Unknown user",
      userPrincipalName: "Unknown user",
    };
  }

  const formProps = Object.assign(
    {},
    {
      ...props,
      record: {
        BuildingIds: userBuildings,
        user: selectedUser,
      },
    }
  );
  delete formProps.hasList;
  delete formProps.hasEdit;
  delete formProps.hasCreate;
  delete formProps.hasShow;
  return (
    <>
      <PageTitle title="Edit Guest User" />
      <Paper>
        <SimpleForm {...formProps} save={(formState) => handleSave(formState)}>
          <TextField source="user.id" label="Id" />
          <TextField source="user.displayName" label="Display name" />
          <TextField
            source="user.userPrincipalName"
            label="User principal name"
          />
          <TextField source="user.mail" label="Mail" />
          <AutocompleteArrayInput
            source="BuildingIds"
            choices={buildingChoices}
            translateChoice={false}
            fullWidth
          />
        </SimpleForm>
      </Paper>
    </>
  );
};

export default GuestUserEdit;
