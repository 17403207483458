import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { refreshView } from "react-admin";
import { MsalContext } from '@azure/msal-react';

class UpdatePWAExportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: this.props.record,
    };
  }
  handleClick = (event) => {
    event.preventDefault();
    this.updateBuildingstats(this.state.record);
  };

  render() {
    return (
      <Button variant="contained" onClick={this.handleClick}>
        Update buildingstats
      </Button>
    );
  }

  static contextType = MsalContext;

  async updateBuildingstats(record) {
    const token = await this.context.instance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
    })
      .catch((error) => {
        this.context.instance.loginRedirect();
      });
    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + token.accessToken,
    });

    const request = new Request(
      `${process.env.REACT_APP_API_URL}/buildings/${record.id}/UpdateBuildingStats`,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(record),
      }
    );

    fetch(request).then((r) => refreshView());
  }
}

export default connect(null, {
  refreshView,
})(UpdatePWAExportButton);
