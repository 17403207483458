import React from "react";
import { useSelector } from "react-redux";

import BlockShow from "./BlockShow";

const BlockShowWrapper = (props) => {
  const buildingSelected = useSelector(
    (state) => state.buildingFilter.buildingSelected
  );
  const buildingIdFromLs = sessionStorage.getItem("BuildingId");

  if (buildingSelected || !buildingIdFromLs || isNaN(buildingIdFromLs)) {
    return <BlockShow {...props} />;
  } else return null;
};

export default BlockShowWrapper;
