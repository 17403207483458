import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import { SelectInput, SelectArrayInput } from "react-admin";
import { groupBy } from "lodash";

class SelectValueStorageVariablesInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choices: [],
    };
  }

  componentDidMount() {
    // this.fetchData(this.props);
    const now = new Date();
    restClient(GET_LIST, "valuestorage", {
      filter: {
        BuildingId: this.props.buildingId,
        ValuesFrom: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours(),
          now.getMinutes() - 120,
          now.getSeconds()
        ).toISOString(),
        ValuesTo: now.toISOString(),
      },
      sort: { field: "UpdateTime", order: "DESC" },
      pagination: {},
    }).then((response) => {
      const grouped = groupBy(response.data, (v) => v.VariableName);
      const choices = Object.keys(grouped).map((key) => {
        return {
          id: key,
          name: key,
        };
      });
      this.setState({
        choices: choices,
      });
    });
  }

  render() {
    const check = this.props.singleSelection;
    // Remove unused props to get rid of errors
    let newProps = Object.assign({}, this.props);
    delete newProps.buildingId;

    const Input = !check ? (
      <SelectArrayInput
        {...newProps}
        optionText="name"
        optionValue="name"
        choices={this.state.choices}
        allowEmpty={true}
        options={{
          fullWidth: true,
        }}
      />
    ) : (
      <SelectInput
        {...newProps}
        optionText="name"
        optionValue="name"
        choices={this.state.choices}
        allowEmpty={true}
        options={{
          fullWidth: true,
        }}
      />
    );
    return Input;
  }
}

SelectValueStorageVariablesInput.propTypes = SelectArrayInput.propTypes;
SelectValueStorageVariablesInput.defaultProps = SelectArrayInput.defaultProps;

export default SelectValueStorageVariablesInput;
