import { useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { setBuildingData } from "../customReducers/actions";

function useBuildingData(_id) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getOne("2.0/buildings", {
        id: _id,
      })
      .then((res) => {
        if (res) {
          const { data } = res;
          if (data) {
            dispatch(setBuildingData(data));
          }
        }
      })
      .catch((error) => {
        notify(error.message, "warning");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify, _id, dispatch]);

  return null;
}

export default useBuildingData;
