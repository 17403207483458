import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import targetMapping from "./targetMapping";

const useTargetDataMapper = (type, value, useTwins = false) => {
  const bTwin = useSelector((state) => state.buildingFilter.buildingTwin);
  const [targetSeries, setTargetSeries] = useState(null);
  const hpData = useSelector((state) => state.buildingFilter.hpData);
  const solarData = useSelector((state) => state.buildingFilter.solarData);

  let monthlyTargets = null;
  if (bTwin && bTwin.MonthlyTargets) {
    monthlyTargets = bTwin.MonthlyTargets;
  }

  useEffect(() => {
    let twinTargets = null;
    let hpTargets = [];
    let solarTargets = [];
    if (hpData && type === "heating" && useTwins) {
      const keys = Object.keys(hpData);
      keys.forEach((k) => {
        const h = hpData[k];
        if (h && h.Hp && h.Hp.MonthlyTargets) {
          hpTargets.push(h.Hp.MonthlyTargets);
        }
      });
      twinTargets = hpTargets;
    }
    if (solarData && type === "electricity" && useTwins) {
      const keys = Object.keys(solarData);
      keys.forEach((k) => {
        const s = solarData[k];
        if (s && s.Solar && s.Solar.MonthlyTargets) {
          solarTargets.push(s.Solar.MonthlyTargets);
        }
      });
      twinTargets = solarTargets;
    }

    setTargetSeries(
      targetMapping(monthlyTargets, useTwins ? twinTargets : null, type, value)
    );
  }, [monthlyTargets, useTwins, type, value, hpData, solarData]);
  return targetSeries;
};

export default useTargetDataMapper;
