import { connect } from "react-redux";
import { changebuilding, setBuildings } from "./actions";
import { showNotification } from "react-admin";

const mapStateToProps = state => ({
  buildings: state.buildingFilter.buildings,
  BuildingId: state.buildingFilter.id,
  buildingTwinId: state.buildingFilter.buildingTwinId,
  projectname: state.buildingFilter.ProjectName,
  building: state.buildingFilter.Building,
  buildingSelected: state.buildingFilter.buildingSelected
});

const mapDispatchToProps = {
  showNotification,
  changebuilding,
  setBuildings
};
const mapDispatch = { setBuildings };

export const mapper = connect(mapStateToProps, mapDispatchToProps);

export const setBuildingsMapper = connect(mapStateToProps, mapDispatch);

export const stateMapper = connect(mapStateToProps, null);
