import React from "react";
import { useHistory } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Wrapper from "../../hoc/Wrapper";

import { IconButton, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginBottom: "0.5rem",
    alignItems: "center",
  },
  separator: {
    margin: "0px 5px",
  },
  text: {
    fontSize: "1rem",
  },
  button: {
    padding: "0.3rem",
    color: "inherit",
  },
});

const CurrentPath = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (history) {
      history.goBack();
    }
  };

  const separator = <span className={classes.separator}>/</span>;

  const icon = (
    <IconButton onClick={handleClick} classes={{ root: classes.button }}>
      <ArrowBackIcon />
    </IconButton>
  );

  return (
    <div className={classes.container}>
      {props.path &&
        props.path.map((p, index) => {
          return (
            <Wrapper key={index}>
              {index === 0 && icon}
              <Typography>
                {p}
                {separator}
              </Typography>
            </Wrapper>
          );
        })}
    </div>
  );
};

export default CurrentPath;
