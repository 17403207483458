import useAllAlerts from "./useAllAlerts";
import useBlockFeedbacks from "./useBlockFeedbacks";
import useReminders from "./useReminders";

const GetAllAlerts = () => {
  useAllAlerts();
  useReminders();
  useBlockFeedbacks();
  return null;
};

export default GetAllAlerts;
