import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import seriesMapping from "./seriesMapping";
import { filter } from "lodash";

const usePurchasedDataMapper = (type, value, allCons = false) => {
  const consumption = useSelector((state) => state.buildingFilter.consumption);
  const [consumptionSeries, setConsumptionSeries] = useState([]);

  // If !allCons, the raw consumption does not include HP data
  let consumptionRaw = allCons
    ? consumption
    : filter(consumption, (c) => {
        return (
          c.Source.toLowerCase() === "manual" ||
          c.Source.toLowerCase() === "metry"
        );
      });

  useEffect(() => {
    if (!consumption) return;
    setConsumptionSeries(seriesMapping(consumption, type, value, allCons));
  }, [consumption, type, value, allCons]);

  return [consumptionSeries, consumptionRaw];
};

export default usePurchasedDataMapper;
