import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";

// import HpTwinInfo from "./HpTwinInfo";
import { BlockDailyCopGraph } from "./BlockDailyCopGraph";
import { BlockDailyEnergyGraph } from "./BlockDailyEnergyGraph";
import { HpTemperatureGraph } from "./HpTemperatureGraph";
import CardWithTitle from "../components/layout/CardWithTitle";
import OperationLogs from "../dashboard/logs/OperationLogs";
import useUserRole from "../utils/hooks/useUserRole";
import HpHeatingGraph from "./hp/HpHeatingGraph";
import HpCoolingGraph from "./hp/HpCoolingGraph";
import HpRegenerationGraph from "./hp/HpRegenerationGraph";
import HpConsumptionGraph from "./hp/HpConsumptionGraph";
import HpInputImage from "./hp/Inputs/HpInputImage";

const HasTrends = (trends) => {
  if (!trends || trends === undefined || trends.length === 0) {
    return false;
  }
  return true;
};

const HpShow = ({ formData }) => {
  const [hpData, setHpData] = useState(null);
  const [trends, setTrends] = useState(null);
  const [blockVersion, setBlockVersion] = useState(null);
  // const [latestCop, setLatestCop] = useState(null);
  const [hiddenContainers, setHiddenContainers] = useState([
    "logs",
    "cop",
    "energy",
    "heating",
    "cooling",
    "regeneration",
    "consumption",
    "temperature",
  ]);
  const { isInternalAdmin } = useUserRole();

  useEffect(() => {
    if (formData.Hp && !hpData) {
      setHpData(formData.Hp);
    }
    if (formData.Trends && formData.Trends.length > 0 && !trends) {
      setTrends(formData.Trends);
    }
    if (formData.BlockVersion && formData.BlockVersion !== blockVersion) {
      setBlockVersion(formData.BlockVersion);
    }
  }, [
    formData.Trends,
    formData.Hp,
    hpData,
    trends,
    formData.BlockVersion,
    blockVersion,
  ]);

  const handleContainerHide = useCallback(
    (container) => {
      if (!hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if (hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers]
  );

  // const handleLatestCop = useCallback((cop) => {
  //   setLatestCop(cop);
  // }, []);

  if (
    formData.BuildingId &&
    formData.id &&
    (formData.Hp || hpData) &&
    (HasTrends(formData.Trends) || HasTrends(trends)) &&
    formData.BlockType === "HP"
  ) {
    const hp = formData.Hp ? formData.Hp : hpData;
    const tr = HasTrends(formData.Trends) ? formData.Trends : trends;
    const bv = formData.BlockVersion ? formData.BlockVersion : blockVersion;
    const _formData = { ...formData, Hp: hp, Trends: tr, BlockVersion: bv };
    return (
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <HpTwinInfo {..._formData} cop={latestCop} />
        </Grid> */}
        <Grid item xs={12}>
          <HpInputImage {..._formData} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          style={{
            display:
              hiddenContainers.includes("logs") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs
              buildingId={_formData.BuildingId}
              twinId={_formData.id}
              showContainer={handleContainerShow}
              hideContainer={handleContainerHide}
            />
          </CardWithTitle>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("cop") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <BlockDailyCopGraph
            // handleLatestCop={handleLatestCop}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
            {..._formData}
          />
        </Grid>
        {_formData && _formData.BlockVersion === 1 && (
          <Grid
            item
            xs={12}
            style={{
              display:
                hiddenContainers.includes("energy") && !isInternalAdmin
                  ? "none"
                  : undefined,
            }}
          >
            <BlockDailyEnergyGraph
              showContainer={handleContainerShow}
              hideContainer={handleContainerHide}
              {..._formData}
            />
          </Grid>
        )}
        {_formData && _formData.BlockVersion === 2 && (
          <>
            <Grid
              item
              xs={12}
              style={{
                display:
                  hiddenContainers.includes("heating") && !isInternalAdmin
                    ? "none"
                    : undefined,
              }}
            >
              <HpHeatingGraph
                showContainer={handleContainerShow}
                hideContainer={handleContainerHide}
                {..._formData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display:
                  hiddenContainers.includes("cooling") && !isInternalAdmin
                    ? "none"
                    : undefined,
              }}
            >
              <HpCoolingGraph
                showContainer={handleContainerShow}
                hideContainer={handleContainerHide}
                {..._formData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display:
                  hiddenContainers.includes("consumption") && !isInternalAdmin
                    ? "none"
                    : undefined,
              }}
            >
              <HpConsumptionGraph
                showContainer={handleContainerShow}
                hideContainer={handleContainerHide}
                {..._formData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display:
                  hiddenContainers.includes("regeneration") &&
                    !isInternalAdmin
                    ? "none"
                    : undefined,
              }}
            >
              <HpRegenerationGraph
                showContainer={handleContainerShow}
                hideContainer={handleContainerHide}
                {..._formData}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("temperature") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <HpTemperatureGraph
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
            {..._formData}
          />
        </Grid>
      </Grid>
    );
  } else if (
    formData.BuildingId &&
    formData.id &&
    (formData.Hp || hpData) &&
    !(HasTrends(formData.Trends) && !HasTrends(trends)) &&
    formData.BlockType === "HP"
  ) {
    let hp = formData.Hp ? formData.Hp : hpData;
    let _formData = { ...formData, Hp: hp };
    // return <HpTwinInfo {..._formData} />;
    return <HpInputImage {..._formData} />;
  }
  return null;
};

export default HpShow;
