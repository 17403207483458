import React from "react";
import { ReactBingmaps } from "react-bingmaps";
import { has } from "lodash";
import {
  makeStyles,
  Grid,
  CardContent,
  CardActions,
  Collapse,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import TeamMembers from "./TeamMembers";
import BuildingInfo from "./BuildingInfo";
import useUserRole from "../../utils/hooks/useUserRole";
import Spinner from "../../components/layout/Spinner";

const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    color: "#5125AA",
  },
  mapContainer: {
    width: "23rem",
    height: "18rem",
    maxWidth: "100%",
    maxHeight: "50vh",
  },
  container: {
    flexWrap: "nowrap",
    overflowX: "auto",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
    },
  },
}));

const BuildingStats = (props) => {
  const classes = useStyles();
  const { isExternalUser } = useUserRole();
  const buildingStats = useSelector(
    (state) => state.buildingFilter.buildingStats
  );

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let map = "No Location Data Available";

  if (
    has(props, "building.coordinates.Latitude") &&
    has(props, "building.coordinates.Longitude")
  ) {
    map = (
      <ReactBingmaps
        bingmapKey="AkCQKS2bjq-cbIWQsBcGA4bTf-bDIQik84wEwSWFa5JQR1f1Zilq6kh0hehvCOlB"
        center={[
          props.building.coordinates.Latitude,
          props.building.coordinates.Longitude,
        ]}
        zoom={15}
        navigationBarMode={"compact"}
        mapTypeId={"aerial"}
        pushPins={[
          {
            location: [
              props.building.coordinates.Latitude,
              props.building.coordinates.Longitude,
            ],
            option: { color: "red" },
          },
        ]}
      />
    );
  }

  return (
    <div>
      <CardContent>
        <Grid className={classes.container} container spacing={2}>
          <Grid item container xs={12} md={6}>
            <Grid
              item
              xs={12}
              style={{ height: isExternalUser ? "25rem" : "20rem" }}
            >
              {map}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {has(props, "building.teamMembers") ? (
              <TeamMembers members={props.building.teamMembers} />
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          variant="text"
          classes={{ label: classes.buttonLabel }}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {expanded ? "SHOW LESS" : "SHOW MORE"}
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {buildingStats && buildingStats.loading && (
            <Spinner style={{ height: "5rem" }} />
          )}
          {buildingStats &&
            buildingStats.loaded &&
            !buildingStats.loading &&
            buildingStats.data && (
              <BuildingInfo
              isExternal={isExternalUser}
                buildingTwin={props.buildingTwin}
                buildingStats={buildingStats.data}
              />
            )}
        </CardContent>
      </Collapse>
    </div>
  );
};

export default BuildingStats;
