import { useEffect, useState } from "react";
import { useNotify, useDataProvider } from "react-admin";
import { useDispatch } from "react-redux";
import { setBuildingTwin } from "../customReducers/actions";

function useBuildingTwin(_id) {
  const dispatch = useDispatch();
  const [buildingTwin, setBuildingTwinData] = useState(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    async function getBuildingTwin(buildingTwinId) {
      try {
        const response = await dataProvider.getOne("blocks", {
          id: buildingTwinId
        });
        if (response && response.data) {
          setBuildingTwinData(response.data);
        } else {
          setBuildingTwinData([]);
        }
      } catch (error) {
        notify(error.message, "warning");
        setBuildingTwinData(null);
      }
    }

    if (_id) {
      getBuildingTwin(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, notify]);

  if (buildingTwin) {
    dispatch(setBuildingTwin(buildingTwin));
  }
  return buildingTwin;
}

export default useBuildingTwin;
