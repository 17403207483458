import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { FormDataConsumer } from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";
import { minValue, maxValue } from "../validationHelper";
import SelectValueStorageVariablesInput from "../components/SelectValueStorageVariablesInput";
import { includes, isEmpty, some } from "lodash";
import PageTitle from "../components/layout/PageTitle";

const CompensationFunctionCreate = ({ BuildingId, ...props }) => {
  // Remove unused props to get rid of errors
  let createProps = Object.assign({}, props);
  delete createProps.buildingTwinId;
  delete createProps.buildingSelected;
  delete createProps.dispatch;
  return (
    <>
      <PageTitle title="Create Function" />
      <Create {...createProps} redirect="edit">
        <SimpleForm>
          <TextInput disabled source="FunctionVersion" defaultValue="V2" />
          <BooleanInput label="Active" source="Active" defaultValue="true" />
          <ReferenceInput
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ RegisterListsVisible: true, showBAUI: true }}
            defaultValue={BuildingId}
            validate={required()}
          >
            <SelectInput optionText="ProjectName" optionValue="id" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.BuildingId &&
              formData.FunctionType !== "SPOT" && (
                <SelectValueStorageVariablesInput
                  buildingId={formData.BuildingId}
                  source="ValueStorageVariables"
                  label="input variables"
                  options={{
                    fullWidth: true,
                  }}
                  validate={required()}
                  style={{ width: "100%" }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionVersion && (
                <SelectInput
                  source="FunctionType"
                  choices={[
                    {
                      id: "ALERT",
                      name: "ALERT",
                    },
                    {
                      id: "AVG",
                      name: "AVERAGE",
                    },
                    {
                      id: "CONSUMPTION",
                      name: "RUNNING SUM DELTA (old CONSUMPTION)",
                    },
                    {
                      id: "FORMULA",
                      name: "FORMULA",
                    },
                    {
                      id: "INTEGRAL",
                      name: "INTEGRAL",
                    },
                    {
                      id: "LINEAR",
                      name: "LINEAR",
                    },
                    {
                      id: "PASSTHROUGH",
                      name: "PASSTHROUGH",
                    },
                    {
                      id: "PID",
                      name: "PID",
                    },
                    {
                      id: "SPOT",
                      name: "SPOT",
                    },
                  ]}
                  allowEmpty
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "AVG" && (
                <React.Fragment>
                  <NumberInput
                    label="Avg interval"
                    source="TimeInterval"
                    validate={validateInterval}
                    step={1}
                  />
                  <span>
                    Interval withing 1-24 hours (1=1h etc... from last
                    measurement)
                  </span>
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "PID" && (
                <div>
                  <NumberInput label="f_pid_gain" source="PidParams.PidGain" />
                  <NumberInput label="f_pid_min" source="PidParams.PidMin" />
                  <NumberInput label="f_pid_max" source="PidParams.PidMax" />
                  <NumberInput
                    label="f_pid_ti (h)"
                    source="PidParams.PidTi"
                    format={(v) => Math.round(v / 60 / 60)}
                    parse={(v) => Math.round(v * 60 * 60)}
                  />
                  <NumberInput
                    label="f_pid_target"
                    source="PidParams.PidTarget"
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "LINEAR" && (
                <TextInput
                  multiline
                  label="Graph name"
                  source="LinearParams.Graph.Name"
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "LINEAR" && (
                <ArrayInput source="LinearParams.Graph.Coordinates">
                  <SimpleFormIterator>
                    <TextInput label="input" source="X" />
                    <TextInput label="output" source="Y" />
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "ALERT" && (
                <React.Fragment>
                  <ArrayInput
                    source="AlertFormulas"
                    validate={validateAlertFormulas}
                  >
                    <SimpleFormIterator>
                      <TextInput
                        multiline
                        label="Alert formula"
                        source="Formula"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <ArrayInput source="AlertExcludeFormulas">
                    <SimpleFormIterator>
                      <TextInput
                        multiline
                        label="Alert exclude formula"
                        source="Formula"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <NumberInput
                    label="Alert delay minutes"
                    source="AlertDelayMinutes"
                    validate={required()}
                  />
                  <SelectInput
                    label="Alert mode"
                    source="AlertMode"
                    choices={[
                      {
                        id: "MIN",
                        name: "MIN",
                      },
                      {
                        id: "MAX",
                        name: "MAX",
                      },
                      {
                        id: "AVG",
                        name: "AVG",
                      },
                    ]}
                    validate={required()}
                  />
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "FORMULA" && (
                <React.Fragment>
                  <TextInput multiline source="Formula" validate={required()} />
                  <SelectInput
                    source="FormulaEvalType"
                    choices={[
                      {
                        id: "BOOL",
                        name: "BOOL",
                      },
                      {
                        id: "DOUBLE",
                        name: "DOUBLE",
                      },
                    ]}
                    validate={required()}
                  />
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "CONSUMPTION" && (
                <TextInput
                  source="RunInterval"
                  defaultValue="1h"
                  validate={required()}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "SPOT" && (
                <React.Fragment>
                  <NumberInput
                    label="start hour"
                    source="SpotWindowStartHour"
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 24,
                    }}
                  />
                  <span> - </span>
                  <NumberInput
                    label="end hour"
                    source="SpotWindowEndHour"
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 24,
                    }}
                  />
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "SPOT" && (
                <NumberInput
                  source="SpotWindowHours"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 24,
                  }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType !== "CONSUMPTION" &&
              formData.FunctionType !== "INTEGRAL" && (
                <TextInput
                  label="Output variable name"
                  source="Name"
                  style={{ width: "20em" }}
                  validate={[required(), validateOutputVariable]}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "CONSUMPTION" && (
                <React.Fragment>
                  <TextInput
                    multiline
                    label="Function name"
                    source="Name"
                    defaultValue="RUNNING_SUM_DELTA"
                  />
                  <TextInput
                    disabled
                    label="Output variable prefix"
                    source="OutputVariablePrefix"
                    defaultValue="CONSUMPTION_"
                    style={{ display: "block" }}
                  />
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "INTEGRAL" && (
                <React.Fragment>
                  <TextInput
                    disabled
                    label="Function name"
                    source="Name"
                    defaultValue="INTEGRAL_"
                    style={{ display: "block" }}
                  />
                  <span>
                    Output variable(s) will be:{" "}
                    {inputVariablesList(formData.ValueStorageVariables)}
                  </span>
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <TextInput
            multiline
            source="ValueStorageDescription"
            validate={required()}
          />
          <TextInput label="Output SI unit" source="OutputUnit" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "FORMULA" && (
                <BooleanInput
                  label="Output is usable as block input"
                  source="OutputIsUsableAsBlockInput"
                />
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </>
  );
};

const formulaValidation = (value, allValues) => {
  if (isEmpty(value)) {
    return "At least one alert formula is required";
  } else {
    let hasErrors = some(value, (v) => isEmpty(v.Formula));
    // let allFormulas = value.map(v => v.Formula);
    if (hasErrors) {
      return "Alert formula cannot be empty";
    }
    /*let hasNonUsedInputs = !some(
      allValues.ValueStorageVariables,
      inputVariable => includes(allFormulas, inputVariable)
    );
    if (hasNonUsedInputs) {
      return "Some input variables are not used in formulas";
    }*/
  }
  return undefined;
};
const validateAlertFormulas = [formulaValidation];

export const validateOutputVariable = (value, allValues) => {
  let checkOutput = includes(allValues.ValueStorageVariables, value);
  if (checkOutput) {
    return "output variable name cannot be same as any input variable";
  }

  if (startsWithNumber(value)) {
    return "function output variable cannot start with number";
  }

  if (containsWhiteSpace(value)) {
    return "function output variable cannot contain spaces";
  }

  if (containsInvalidCharacters(value)) {
    return "function output variable contains invalid characters allowed: [A-Z0-9_]";
  }

  return undefined;
};

const startsWithNumber = (value) => {
  if (/^\d/.test(value)) {
    return true;
  }
  return false;
};

const containsWhiteSpace = (value) => /\s/.test(value);

// const validateFunctionName = (value, allValues) => {
//   if (containsWhiteSpace(value)) {
//     return "function name cannot contain spaces";
//   }

//   if (startsWithNumber(value)) {
//     return "function name cannot start with number";
//   }

//   if (containsInvalidCharacters(value)) {
//     return "function name contains invalid characters allowed: [a-zA-Z0-9_]";
//   }

//   return undefined;
// };

const containsInvalidCharacters = (value) => {
  var allowed = /^[A-Z0-9_]+$/;
  if (allowed.test(value)) {
    return false;
  }
  return true;
};

const inputVariablesList = (value) => {
  if (isEmpty(value)) return "";
  return value.join();
};

const validateInterval = [required(), minValue(1), maxValue(24)];

export default stateMapper(CompensationFunctionCreate);
