import React from "react";
import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
import { BuildingBlock, BuildingPoint } from "../types/leasegreen-core-api-exports";

const optionRenderer = (choice: BuildingPoint) => {
  if (!choice) return "";
  else if (
    choice &&
    choice.PointId === null &&
    choice.Description === undefined
  )
    return "(Empty)";
  let PointId = "";
  if (choice.PointId) {
    PointId = choice.PointId;
  }
  return `${choice.Description} ${PointId} (${choice.PointSource})`;
};

const zoneInputDefaultValue: BuildingPoint[] = [];

export const ZoneInputs = (formData: BuildingBlock) => {
  return <div style={{ width: "100%" }}>
    <ReferenceArrayInput
      label="Temperatures"
      source="Zone.Temperatures"
      reference="buildingpoints"
      filter={{ BuildingId: formData.BuildingId, Unit: "°C" }}
      //perPage={10}
      defaultValue={zoneInputDefaultValue}
    >
      <AutocompleteArrayInput
        optionText={optionRenderer}
        optionValue="id"
        options={{ InputProps: { fullWidth: true } }}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="Humidities"
      source="Zone.Humidities"
      reference="buildingpoints"
      filter={{ BuildingId: formData.BuildingId, Unit: "%" }}
      //perPage={10}
      defaultValue={[]}
    >
      <AutocompleteArrayInput
        optionText={optionRenderer}
        optionValue="id"
        options={{ InputProps: { fullWidth: true } }}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="CarbonDioxides"
      source="Zone.CarbonDioxides"
      reference="buildingpoints"
      filter={{ BuildingId: formData.BuildingId, Unit: "ppm", ValidBlockInput: true }}
      //perPage={10}
      defaultValue={[]}
    >
      <AutocompleteArrayInput
        optionText={optionRenderer}
        optionValue="id"
        options={{ InputProps: { fullWidth: true } }}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="Pressures"
      source="Zone.Pressures"
      reference="buildingpoints"
      filter={{ BuildingId: formData.BuildingId, Unit: "Pa" }}
      //perPage={10}
      defaultValue={[]}
    >
      <AutocompleteArrayInput
        optionText={optionRenderer}
        optionValue="id"
        options={{ InputProps: { fullWidth: true } }}
      />
    </ReferenceArrayInput>
  </div>
};
