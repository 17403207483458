import { AhuBlock, BuildingBlock } from "../types/leasegreen-core-api-exports";
import {
  CHANGE_BUILDING,
  SET_BUILDINGS,
  SET_ALERTS,
  SET_ACTIVE_ALERT_COUNT,
  SET_ALL_ALERTS,
  SET_ALL_ACTIVE_ALERT_COUNT,
  SET_BLOCK_FEEDBACK_COUNT,
  SET_REMINDER_COUNT,
  SET_REMINDERS,
  SET_BUILDINGDATA,
  SET_BUILDINGSTATS,
  SET_BUILDINGTWIN,
  SET_CONSUMPTION,
  SET_CONSUMPTIONBYTYPES,
  SET_BUILDINGTEMP,
  SET_BUILDINGTRENDS,
  SET_ALLTWINS,
  SET_AHUDATA,
  SET_SOLARDATA,
  SET_HPDATA,
} from "./actions";

export interface IBuildingFilter {
  id: any;
  buildingTwinId: any;
  ProjectName: any;
  Building: any;
  buildings: any;
  buildingSelected: any;
  alerts: any;
  activeAlertCount: any;
  allAlerts: any;
  allActiveAlertCount: any;
  blockFeedbackCount: any;
  reminderCount: any;
  reminders: any;
  buildingData: any;
  buildingStats: {
    loading: any;
    loaded: any;
    data: any;
  };
  buildingTwin: any;
  consumption: any;
  consumptionByType: any;
  buildingTemp: any;
  buildingTrends: any;
  allTwins?: BuildingBlock[];
  ahuData: AhuBlock;
  solarData: any;
  hpData: any;
}

export default (
  previousState: IBuildingFilter = {
    id: null,
    buildingTwinId: null,
    ProjectName: "",
    Building: null,
    buildings: [],
    buildingSelected: false,
    alerts: null,
    activeAlertCount: 0,
    allAlerts: null,
    allActiveAlertCount: 0,
    blockFeedbackCount: 0,
    reminderCount: 0,
    reminders: null,
    buildingData: null,
    buildingStats: {
      loading: false,
      loaded: false,
      data: null,
    },
    buildingTwin: null,
    consumption: null,
    consumptionByType: null,
    buildingTemp: null,
    buildingTrends: null,
    allTwins: null,
    ahuData: null,
    solarData: null,
    hpData: null,
  },
  { type, payload, buildingTwinId }: any
): IBuildingFilter => {
  switch (type) {
    case CHANGE_BUILDING:
      // Add the latest selected building to sessionStorage, check BuildingSelect.js for usage
      if (payload) {
        sessionStorage.setItem("BuildingId", payload.id);
        sessionStorage.setItem("BuildingTwinId", buildingTwinId);
        sessionStorage.setItem("BuildingTimezone", payload.TimeZoneIANA);
        return {
          ...previousState,
          id: parseInt(payload.id),
          buildingTwinId: buildingTwinId,
          ProjectName: payload.ProjectName,
          Building: payload,
          buildingSelected: true,
          alerts: null,
          activeAlertCount: 0,
          allAlerts: null,
          allActiveAlertCount: 0,
          blockFeedbackCount: 0,
          reminderCount: 0,
          reminders: null,
          buildingData: null,
          buildingStats: {
            loading: false,
            loaded: false,
            data: null,
          },
          buildingTwin: null,
          consumption: null,
          consumptionByType: null,
          buildingTemp: null,
          buildingTrends: null,
          allTwins: null,
          ahuData: null,
          solarData: null,
          hpData: null,
        };
      } else {
        sessionStorage.setItem("BuildingId", "null");
        sessionStorage.setItem("BuildingTwinId", "null");
        sessionStorage.removeItem("BuildingTimezone");
        return {
          ...previousState,
          Building: null,
          buildingSelected: false,
          id: null,
          alerts: null,
          activeAlertCount: 0,
          allAlerts: null,
          allActiveAlertCount: 0,
          blockFeedbackCount: 0,
          reminderCount: 0,
          reminders: null,
          buildingData: null,
          buildingStats: {
            loading: false,
            loaded: false,
            data: null,
          },
          buildingTwin: null,
          consumption: null,
          buildingTemp: null,
          buildingTrends: null,
          allTwins: null,
          ahuData: null,
          solarData: null,
          hpData: null,
        };
      }

    case SET_BUILDINGS:
      return {
        ...previousState,
        buildings: payload,
      };

    case SET_ALERTS:
      return { ...previousState, alerts: payload };

    case SET_ACTIVE_ALERT_COUNT:
      return { ...previousState, activeAlertCount: payload };

    case SET_BLOCK_FEEDBACK_COUNT:
      return { ...previousState, blockFeedbackCount: payload };

    case SET_ALL_ALERTS:
      return { ...previousState, allAlerts: payload };

    case SET_ALL_ACTIVE_ALERT_COUNT:
      return { ...previousState, allActiveAlertCount: payload };
    case SET_REMINDER_COUNT:
      return { ...previousState, reminderCount: payload };
    case SET_REMINDERS:
      return { ...previousState, reminders: payload };

    case SET_BUILDINGDATA:
      return { ...previousState, buildingData: payload };

    case SET_BUILDINGSTATS:
      return { ...previousState, buildingStats: payload };

    case SET_BUILDINGTWIN:
      return { ...previousState, buildingTwin: payload };

    case SET_CONSUMPTION:
      return { ...previousState, consumption: payload };

    case SET_CONSUMPTIONBYTYPES:
      return { ...previousState, consumptionByType: payload };

    case SET_BUILDINGTEMP:
      return { ...previousState, buildingTemp: payload };
    case SET_BUILDINGTRENDS:
      return { ...previousState, buildingTrends: payload };
    case SET_ALLTWINS:
      return { ...previousState, allTwins: payload };
    case SET_AHUDATA:
      return { ...previousState, ahuData: payload };
    case SET_SOLARDATA:
      return { ...previousState, solarData: payload };
    case SET_HPDATA:
      return { ...previousState, hpData: payload };

    default:
      return previousState;
  }
};
