import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Tab, Tabs, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";

import ProducedGraph from "./ProducedGraph";
import CardWithTitle from "../../components/layout/CardWithTitle";
import useUserRole from "../../utils/hooks/useUserRole";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2.5rem",
    // width: "100%",
    // display: "inline",
    // float: "left",
    // height: "auto"
  },
  container: {
    minHeight: "28rem",
  },
  indicator: {
    backgroundColor: "#1AD689",
  },
  label: {
    textTransform: "none",
    fontSize: "1.125rem",
    color: "#282331",
    fontWeight: 400,
    height: "3.25rem",
    width: "12.1875rem",
    lineHeight: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, className, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={className} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ProducedGraphs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState("heating");
  const [hiddenGraphs, setHiddenGraphs] = useState([]);
  const { isExternalUser, isReader } = useUserRole();
  const { hideContainer, showContainer } = props;

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const handleGraphHide = useCallback(
    (graph) => {
      if ((isExternalUser || isReader) && !hiddenGraphs.includes(graph)) {
        setHiddenGraphs([...hiddenGraphs, graph]);
      }
    },
    [hiddenGraphs, isExternalUser, isReader]
  );

  const handleGraphShow = useCallback(
    (graph) => {
      if ((isExternalUser || isReader) && hiddenGraphs.includes(graph)) {
        setHiddenGraphs(hiddenGraphs.filter((r) => r !== graph));
      }
    },
    [hiddenGraphs, isExternalUser, isReader]
  );

  // Each time hiddenResources changes, change the selected resource if necessary
  useEffect(() => {
    const possibleGraphIds = ["heating", "electricity", "cooling"];
    // Set current selection as the "top" one
    for (let i = 0; i < possibleGraphIds.length; i++) {
      const p = possibleGraphIds[i];
      if (!hiddenGraphs.includes(p)) {
        setValue(p);
        break;
      }
    }
  }, [hiddenGraphs]);

  useEffect(() => {
    if (hiddenGraphs.length >= 3 && hideContainer) {
      hideContainer("produced");
    }
    if (hiddenGraphs.length < 3 && showContainer) {
      showContainer("produced");
    }
  }, [hiddenGraphs, hideContainer, showContainer]);

  return (
    <div className={classes.root}>
      <CardWithTitle title={"Produced"} spacerColor="#2E2247">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          classes={{ indicator: classes.indicator }}
          value={value}
          onChange={handleChange}
        >
          {!hiddenGraphs.includes("heating") && (
            <Tab
              classes={{ root: classes.label }}
              label="Heating"
              value="heating"
            />
          )}
          {!hiddenGraphs.includes("electricity") && (
            <Tab
              classes={{ root: classes.label }}
              label="Electricity"
              value="electricity"
            />
          )}
          {!hiddenGraphs.includes("cooling") && (
            <Tab
              classes={{ root: classes.label }}
              label="Cooling"
              value="cooling"
            />
          )}
        </Tabs>
        {/* Just a dummy, used to check if the graphs have any potential data */}
        <div style={{ display: "none" }}>
          <ProducedGraph
            type="heating"
            loading={props.loading}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
          <ProducedGraph
            type="electricity"
            loading={props.loading}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
          <ProducedGraph
            type="cooling"
            loading={props.loading}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
        </div>
        <TabPanel className={classes.container} value={value} index={"heating"}>
          <ProducedGraph type="heating" loading={props.loading} />
        </TabPanel>
        <TabPanel
          className={classes.container}
          value={value}
          index={"electricity"}
        >
          <ProducedGraph type="electricity" loading={props.loading} />
        </TabPanel>
        <TabPanel className={classes.container} value={value} index={"cooling"}>
          <ProducedGraph type="cooling" loading={props.loading} />
        </TabPanel>
      </CardWithTitle>
    </div>
  );
};

export default ProducedGraphs;
