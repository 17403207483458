const setLegendItemHover = (chart) => {
  if (chart && chart.series) {
    chart.series.forEach((s) => {
      if (s && s.legendItem) {
        s.legendItem.on("mouseover", () => {
          if (s && s.markerGroup && s.markerGroup.visibility !== "hidden") {
            chart.series.forEach((ser) => {
              if (
                (typeof ser.name === "string" && ser.name.includes(s.name)) ||
                (typeof ser.name === "string" &&
                  ser.name.includes("Temperature ") &&
                  s.name === "Outside Temperatures") ||
                (typeof ser.name === "string" &&
                  (ser.name.includes("Building produced total energy ") ||
                    ser.name.includes(
                      "Building heat pump total heating energy "
                    ) ||
                    ser.name.includes(
                      "Building heat pump total water energy "
                    )) &&
                  s.name === "Produced energy") ||
                (typeof ser.name === "string" &&
                  ser.name.includes("Total building COP ") &&
                  s.name === "Building COP") ||
                (typeof ser.name === "string" &&
                  ser.name.includes("Sum of produced energy ") &&
                  s.name === "Produced energy") ||
                (typeof ser.name === "string" &&
                  ser.name.includes(
                    "Solar panel energy production efficiency "
                  ) &&
                  s.name === "Efficiency")
              ) {
                ser.setState("hover");
              } else {
                ser.setState("inactive");
              }
            });
          }
        });

        s.legendItem.on("mouseout", () => {
          chart.series.forEach((ser) => {
            ser.setState("");
          });
        });
      }
    });
  }
};

export default setLegendItemHover;
