import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import CardWithTitle from "../../components/layout/CardWithTitle";

import { find, groupBy, map, round, sortBy } from "lodash";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import Boost from "highcharts/modules/boost";
Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (dataFromReactAdmin, divider) => {
  var current = new Date();
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = null;
    if (v.date) {
      date = new Date(v.date);
    } else if (v.year && v.month) {
      date = new Date(v.year, v.month - 1, 2);
    }
    return [
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getTime(),
      round(v.value / divider, 2),
    ];
  });
  return highChartsData;
};

export class MeterDailySumGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: { title: { text: "" } },
      monthlyOptions: { title: { text: "" } },
      dataFetched: false,
      monthlyFetched: false,
      regFetched: false,
      data: null,
      monthlyData: null,
      reg: null,
      chartDrawn: false,
      record: this.props,
      title: "",
      resolution: "day",
    };
  }

  componentDidMount() {
    // Daily data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props.Trends, "DAILY_SUM"),
        resolution: "day",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        let unit = "";
        if (this.props.Meter && this.props.Meter.Category) {
          switch (this.props.Meter.Category) {
            case "Heating":
              unit = "MWh";
              break;
            case "Cooling":
              unit = "MWh";
              break;
            case "Electricity":
              unit = "KWh";
              break;
            case "Water":
              unit = "m³";
              break;
            default:
              break;
          }
        }
        this.setState({
          dataFetched: true,
          data: response.values,
          reg: { Unit: unit },
          regFetched: true,
        });
      });

    // Monthly data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props.Trends, "MONTHLY_SUM"),
        resolution: "month",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        let unit = "";
        if (this.props.Meter && this.props.Meter.Category) {
          switch (this.props.Meter.Category) {
            case "Heating":
              unit = "MWh";
              break;
            case "Cooling":
              unit = "MWh";
              break;
            case "Electricity":
              unit = "KWh";
              break;
            case "Water":
              unit = "m³";
              break;
            default:
              break;
          }
        }
        this.setState({
          monthlyFetched: true,
          monthlyData: response.values,
          reg: { Unit: unit },
          regFetched: true,
        });
      });
  }

  componentDidUpdate() {
    if (
      !this.state.chartDrawn &&
      this.state.dataFetched &&
      this.state.monthlyFetched &&
      this.state.data &&
      this.state.regFetched &&
      this.state.reg
    ) {
      this.setState({
        chartDrawn: true,
        options: this.graphOptions(this.state.data, this.state.reg),
        monthlyOptions: this.graphOptions(
          this.state.monthlyData,
          this.state.reg,
          true
        ),
      });
    }
  }

  graphOptions = (data, register, monthlyData = false) => {
    const grouped = groupBy(data, "metaId");
    let trend = find(
      this.state.record.Trends,
      (y) => y.TrendId === "DAILY_SUM"
    );
    const keys = Object.keys(grouped);

    const series = map(keys, (k) => {
      let trendValue = find(
        trend.Values,
        (v) => v.ReportingPointId === Number(k)
      );

      return {
        name: trendValue.Legend,
        data: sortBy(
          valuesToHighchartsData(
            grouped[k],
            register.Unit === "MWh" ? 1000 : 1
          ),
          0
        ),
      };
    });
    if (this.state.title === "") {
      this.setState({
        title: trend.Title,
      });
    }

    return {
      chart: {
        type: "column",
      },
      legend: {
        enabled: true,
      },
      colors: this.props.graphColors,
      rangeSelector: {
        selected: 2,
        // allButtonsEnabled: true,
        buttonTheme: {
          width: "auto",
        },
        buttons: monthlyData
          ? [
              {
                type: "month",
                count: 3,
                text: "3 months",
              },
              {
                type: "month",
                count: 12,
                text: "12 months",
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ]
          : [
              {
                type: "month",
                count: 1,
                text: "1m",
              },
              {
                type: "month",
                count: 3,
                text: "3m",
              },
              {
                type: "month",
                count: 6,
                text: "6m",
              },
              {
                type: "ytd",
                text: "YTD",
              },
              {
                type: "year",
                count: 1,
                text: "1y",
              },
              {
                type: "all",
                text: "All",
              },
            ],
      },
      title: {
        text: "",
      },
      yAxis: {
        min: 0,
        title: {
          text: trend.Title + ` (${register.Unit})`,
        },
      },
      tooltip: {
        xDateFormat: !monthlyData ? "%A, %b %e %Y, %H:%M" : "%B, %Y",
      },
      series: series,
    };
  };

  handleResolutionChange = (e) => {
    this.setState({ resolution: e.target.value });
  };

  render() {
    let showData = false;

    const ResolutionInput = () => (
      <div
        key="resInput"
        style={{
          marginLeft: 15,
          width: 80,
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        <InputLabel>Resolution</InputLabel>
        <Select
          value={this.state.resolution}
          onChange={this.handleResolutionChange}
        >
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"month"}>Month</MenuItem>
        </Select>
      </div>
    );

    const selectedOptions =
      this.state.resolution === "day"
        ? this.state.options
        : this.state.monthlyOptions;

    if (this.state && selectedOptions && selectedOptions.series) {
      showData = true;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.dataFetched
    ) {
      if (showData) {
        this.props.showContainer("daily");
      } else {
        this.props.hideContainer("daily");
      }
    }

    return this.state && selectedOptions && selectedOptions.series ? (
      <CardWithTitle title="History">
        <ResolutionInput />
        <HighchartsReact
          immutable
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={selectedOptions}
        />
      </CardWithTitle>
    ) : null;
  }
}
