import React from "react";
import { NullableBooleanInput } from "react-admin";
import { get } from "lodash";

function isInputSelected(formData, inputId) {
  const input = get(formData.Meter, inputId, undefined);

  if (!input) {
    return false;
  }

  if (Array.isArray(input) && input.length > 0) {
    return true;
  } else {
    const busTagName = get(input, inputId + ".BusTagName", undefined);

    if (busTagName) {
      return true;
    }
    return false;
  }
}

export const MeterOperations = formData => {
  if (formData.Meter && formData.Meter.Operations) {
    if (isInputSelected(formData, "InputPoints")) {
      formData.Meter.Operations.CalculateRunningSum.Active = isInputSelected(
        formData,
        "InputPoints"
      );
      formData.Meter.Operations.CalculateTotalSum.Active = isInputSelected(
        formData,
        "InputPoints"
      );
    } else {
      formData.Meter.Operations.CalculateTotalSum = { Active: false };
      formData.Meter.Operations.CalculateRunningSum = { Active: false };
    }

    if (isInputSelected(formData, "InputPowerPoints")) {
      formData.Meter.Operations.CalculateTotalPower.Active = isInputSelected(
        formData,
        "InputPowerPoints"
      );
    } else {
      formData.Meter.Operations.CalculateTotalPower = { Active: false };
    }
  }

  return (
    <div style={{ display: "grid" }}>
      <NullableBooleanInput
        label="Calculate running sum"
        source="Meter.Operations.CalculateRunningSum.Active"
        defaultValue={isInputSelected(formData, "InputPoints")}
        disabled={true}
        style={{ width: "300px" }}
      />
      <NullableBooleanInput
        label="Calculate total sum of inputs"
        source="Meter.Operations.CalculateTotalSum.Active"
        defaultValue={isInputSelected(formData, "InputPoints")}
        disabled={true}
        style={{ width: "300px" }}
      />
      <NullableBooleanInput
        label="Calculate total power sum"
        source="Meter.Operations.CalculateTotalPower.Active"
        defaultValue={isInputSelected(formData, "InputPowerPoints")}
        disabled={true}
        style={{ width: "300px" }}
      />
    </div>
  );
};
