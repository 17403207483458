import useAlerts from "./useAlerts";
import useConsumption from "./useConsumption";
import useConsumptionByType from "./useConsumptionByType";
import useBuildingTemp from "./useBuildingTemp";
import useAllTwins from "./useAllTwins";
import useBuildingData from "./useBuildingData";
import useReminders from "./useReminders";
import useBuildingStats from "./useBuildingStats";
import useBlockFeedbacks from "./useBlockFeedbacks";

const GetBuildingData = (props) => {
  useAlerts(props.id);
  useConsumption(props.id);
  useConsumptionByType(props.id);
  useBuildingTemp(props.id);
  useAllTwins(props.id);
  useBuildingData(props.id);
  useBuildingStats(props.id);
  useReminders(props.id);
  useBlockFeedbacks(props.id);
  return null;
};

export default GetBuildingData;
