import graphColor from "../../../utils/graphColor";

const targetMapping = (monthlyTargets, twinTargets, type, value) => {
  if (monthlyTargets && monthlyTargets.length > 0) {
    // Try to find a suitable target description
    let targetType = "";
    if (type === "heating") {
      targetType = "BUILDING_HEAT_TARGET";
    } else {
      targetType = "BUILDING_" + type.toUpperCase() + "_TARGET";
    }

    // Factor for some types is different than 1
    let typeFactor = 1;
    let targetDivider = 1000;
    if (type === "electricity") {
      typeFactor = 1;
      targetDivider = 1;
    }

    let targetIndex = null;
    // Check if target data exists
    let targetFound = false;
    for (let i = 0; i < monthlyTargets.length; i++) {
      if (monthlyTargets[i].Desc === targetType) {
        targetFound = true;
        targetIndex = i;
        break;
      }
    }

    if (!targetFound) {
      return null;
    } else {
      let targetData = [];

      for (let index = targetIndex; index < targetIndex + 12; index++) {
        targetData[monthlyTargets[index].Month - 1] =
          monthlyTargets[index].Target * typeFactor;
      }
      // Subtract HP production targets for heating graphs and SOLAR targets for electricity graphs
      // First calculate the HP/Solar targets for each twin and month
      let twinTargetData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (twinTargets && twinTargets.length > 0) {
        twinTargets.forEach((tar) => {
          tar.forEach((t) => {
            const index = t.Month - 1;
            if (t.ProducedTarget) {
              twinTargetData[index] += t.ProducedTarget;
            }
            if (t.AdditionalTarget) {
              twinTargetData[index] += t.AdditionalTarget;
            }
            // This is for solar, above are HP
            if (t.Target) {
              twinTargetData[index] += t.Target;
            }
          });
        });
      }
      for (let i = 0; i < targetData.length; i++) {
        targetData[i] -= twinTargetData[i] / targetDivider;
        targetData[i] = parseFloat(targetData[i].toFixed(2));
      }
      return {
        name: "Target",
        data: targetData,
        type: "line",
        zIndex: 10,
        color: graphColor({
          index: 0,
          seriesLength: 1,
          lineSeries: true,
          type,
        }),
      };
    }
  }
};

export default targetMapping;
