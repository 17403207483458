import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { checkAlert, unCheckAlert } from "./AlertActions";
import compose from "recompose/compose";

const styles = {
  primary: { background: "white", color: "black", width: 150 }
};

class CheckButton extends Component {
  handleCheck = e => {
    e.stopPropagation();
    const { checkAlert, record, basePath } = this.props;
    checkAlert(record.id, record, basePath);
  };

  handleUnCheck = e => {
    e.stopPropagation();
    const { unCheckAlert, record, basePath } = this.props;
    unCheckAlert(record.id, record, basePath);
  };

  // Renders the buttons multiple times after clicking a button - slows down a bit.
  render() {
    const { record, classes } = this.props;
    return record.Checked === null ? (
      <Button
        variant="contained"
        className={classes.primary}
        onClick={this.handleCheck}
      >
        Check
      </Button>
    ) : (
      <Button
        variant="contained"
        className={classes.primary}
        onClick={this.handleUnCheck}
      >
        UnCheck
      </Button>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    {
      checkAlert,
      unCheckAlert
    }
  )
);

export default enhance(CheckButton);
