import React from "react";
import {
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { mapper } from "../customReducers/connectFunctions";

const BuildingsWithAlerts = ({ buildings, alerts, changebuilding }) => (
  <Card>
    <CardHeader title="Buildings that have active or unchecked alerts" />
    <List>
      {buildings.map((building) => {
        const buildingAlerts = alerts.filter(
          (alert) => alert.BuildingId === building.id
        );
        const count = alertCount(buildingAlerts);
        return count.buildingHasActiveOrUncheckedAlerts ? (
          <ListItem
            key={building.id}
            button
            component={Link}
            to={`/alerts`}
            onClick={() => changebuilding(building)}
          >
            <ListItemText primary={building.ProjectName} />
            <ListItemSecondaryAction>
              <ListItemText
                primary={count.active + " active alerts"}
                secondary={count.unchecked + " unchecked alerts"}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ) : null;
      })}
    </List>
  </Card>
);

export const alertCount = (alerts) => {
  const alertcounts = {
    active: 0,
    unchecked: 0,
    activeUnchecked: 0,
    buildingHasActiveOrUncheckedAlerts: false,
  };

  alertcounts.active = activealertcounter(alerts);
  alertcounts.unchecked = uncheckedalertcounter(alerts);
  alertcounts.activeUnchecked = ativeUncheckedAlertCounter(alerts);

  alertcounts.buildingHasActiveOrUncheckedAlerts =
    alertcounts.active === 0 && alertcounts.unchecked === 0 ? false : true;
  return alertcounts;
};

export const activealertcounter = (alerts) => {
  const counter = alerts.filter((alert) => !alert.AlertEndTime);
  return counter.length;
};
export const uncheckedalertcounter = (alerts) => {
  const counter = alerts.filter((alert) => !alert.Checked);
  return counter.length;
};
export const ativeUncheckedAlertCounter = (alerts) => {
  const counter = alerts.filter(
    (alert) => !alert.Checked && !alert.AlertEndTime
  );
  return counter.length;
};

export default mapper(BuildingsWithAlerts);
