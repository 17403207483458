export const CHANGE_BUILDING = "CHANGE_BUILDING";

export const changebuilding = (
  selectedBuilding,
  redirectTo = undefined,
  buildingTwinId
) => {
  return {
    type: CHANGE_BUILDING,
    payload: selectedBuilding,
    buildingTwinId: buildingTwinId,
    meta: {
      refresh: true,
      redirectTo: redirectTo ? redirectTo : "/",
    },
  };
};

export const SET_BUILDINGS = "SET_BUILDINGS";

export const setBuildings = (buildings) => ({
  type: SET_BUILDINGS,
  payload: buildings,
});

export const SET_BUILDINGDATA = "SET_BUILDINGDATA";

export const setBuildingData = (buildingData) => ({
  type: SET_BUILDINGDATA,
  payload: buildingData,
});

export const SET_BUILDINGSTATS = "SET_BUILDINGSTATS";

export const setBuildingStats = (buildingStats) => ({
  type: SET_BUILDINGSTATS,
  payload: buildingStats,
});

export const SET_ALERTS = "SET_ALERTS";

export const setAlerts = (alerts) => ({
  type: SET_ALERTS,
  payload: alerts,
});

export const SET_ACTIVE_ALERT_COUNT = "SET_ACTIVE_ALERT_COUNT";

export const setActiveAlertCount = (count) => ({
  type: SET_ACTIVE_ALERT_COUNT,
  payload: count,
});

export const SET_ALL_ALERTS = "SET_ALL_ALERTS";

export const setAllAlerts = (alerts) => ({
  type: SET_ALL_ALERTS,
  payload: alerts,
});

export const SET_ALL_ACTIVE_ALERT_COUNT = "SET_ALL_ACTIVE_ALERT_COUNT";

export const setAllActiveAlertCount = (count) => ({
  type: SET_ALL_ACTIVE_ALERT_COUNT,
  payload: count,
});

export const SET_BLOCK_FEEDBACK_COUNT = "SET_BLOCK_FEEDBACK_COUNT";

export const setBlockFeedbackCount = (count) => ({
  type: SET_BLOCK_FEEDBACK_COUNT,
  payload: count,
});

export const SET_REMINDER_COUNT = "SET_REMINDER_COUNT";

export const setReminderCount = (count) => ({
  type: SET_REMINDER_COUNT,
  payload: count,
});

export const SET_REMINDERS = "SET_REMINDERS";

export const setReminders = (reminders) => ({
  type: SET_REMINDERS,
  payload: reminders,
});

export const SET_BUILDINGTWIN = "SET_BUILDINGTWIN";

export const setBuildingTwin = (buildingTwin) => ({
  type: SET_BUILDINGTWIN,
  payload: buildingTwin,
});

export const SET_CONSUMPTION = "SET_CONSUMPTION";

export const setConsumption = (consumption) => ({
  type: SET_CONSUMPTION,
  payload: consumption,
});

//17.04.2023
export const SET_CONSUMPTIONBYTYPES = "SET_CONSUMPTIONBYTYPES";
export const setConsumptionByEnergyType = (consumptionByTypes) => ({
  type: SET_CONSUMPTIONBYTYPES,
  payload: consumptionByTypes,
});

export const SET_BUILDINGTEMP = "SET_BUILDINGTEMP";

export const setBuildingTemp = (buildingTemp) => ({
  type: SET_BUILDINGTEMP,
  payload: buildingTemp,
});

export const SET_BUILDINGTRENDS = "SET_BUILDINGTRENDS";

export const setBuildingTrends = (buildingTrends) => ({
  type: SET_BUILDINGTRENDS,
  payload: buildingTrends,
});

export const SET_ALLTWINS = "SET_ALLTWINS";

export const setAllTwins = (allTwins) => ({
  type: SET_ALLTWINS,
  payload: allTwins,
});

export const SET_AHUDATA = "SET_AHUDATA";

export const setAhuData = (ahuData) => ({
  type: SET_AHUDATA,
  payload: ahuData,
});

export const SET_SOLARDATA = "SET_SOLARDATA";

export const setSolarData = (solarData) => ({
  type: SET_SOLARDATA,
  payload: solarData,
});

export const SET_HPDATA = "SET_HPDATA";

export const setHpData = (hpData) => ({
  type: SET_HPDATA,
  payload: hpData,
});
