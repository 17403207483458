import React, { useState, useEffect } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import graphColor from "../utils/graphColor";

import { find, groupBy, map, round, sortBy, cloneDeep } from "lodash";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import useIsMounted from "../utils/hooks/useIsMounted";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

export const valuesToHighchartsData = (dataFromReactAdmin) => {
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = v.date ? new Date(v.date) : new Date(v.valueTime);
    return [date.getTime(), round(v.value, 2)];
    //return [Date.parse(v.Date), round(v.Value, 2)];
  });
  return highChartsData;
};

export const AhuWeeklyFlowIntegralGraph = (props) => {
  // Some init options etc.
  const [optionsDay, setOptionsDay] = useState({
    title: {
      text: "",
    },
  });
  const [optionsMonth, setOptionsMonth] = useState({
    title: {
      text: "",
    },
  });
  const [optionsHour, setOptionsHour] = useState({
    title: {
      text: "",
    },
  });
  const [resolution, setResolution] = useState("day");
  const [fetchDone, setFetchDone] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    const findMetaIds = (trends, id) => {
      let y = find(trends, (y) => y.TrendId === id);
      if (y) {
        let vals = map(y.Values, (v) => v.ReportingPointId);
        if (vals) {
          return vals;
        }
      }
      return [];
    };

    const graphOptions = (data, monthlyData = false, hourlyData = false) => {
      try {
        const grouped = groupBy(data, "metaId");
        let trend = find(
          props.Trends,
          (y) => y.TrendId === "AHU_FLOW_INTEGRAL"
        );
        const keys = Object.keys(grouped);

        const flowTargetSeries = map(keys, (k) => {
          if (!hourlyData) {
            const targetData = map(grouped[k], (v) => {
              const currentDate = new Date(v.date);
              const dayOfWeek = currentDate.getDay();
              return [
                currentDate.getTime(),
                props.Ahu.CalculatedWeeklyAirFlowTargets[dayOfWeek],
              ];
            });
            return {
              type: "line",
              name: monthlyData
                ? "Monthly air flow target"
                : "Daily air flow target",
              data: sortBy(targetData, 0),
              dataGrouping: {
                approximation: "sum",
                enabled: monthlyData || hourlyData,
                units: hourlyData ? [["hour", [1]]] : [["month", [1]]],
                forced: monthlyData || hourlyData,
              },
              color: graphColor({
                index: 0,
                seriesLength: 1,
                lineSeries: true,
              }),
            };
          }
        });
        const series = map(keys, (k) => {
          let trendValue = find(
            trend.Values,
            (v) => v.ReportingPointId === Number(k)
          );

          return {
            name: trendValue.Legend,
            data: sortBy(valuesToHighchartsData(grouped[k]), 0),
            dataGrouping: {
              approximation: "sum",
              enabled: monthlyData,
              units: [["month", [1]]],
              forced: monthlyData,
            },
          };
        });

        let allSeries = series;
        if (!hourlyData) {
          allSeries = series.concat(flowTargetSeries);
        }

        // Calculate date offsets for the previous year button
        // Get the time of the latest value
        let newestData = 0;
        allSeries.forEach((s) => {
          if (s && s.data && s.data.length > 0) {
            s.data.forEach((d) => {
              if (d[0] > newestData) {
                newestData = d[0];
              }
            });
          }
        });

        const curEnd = new Date(newestData);
        const curStart = new Date(curEnd.getUTCFullYear(), 0, 1);
        const prevStart = new Date(curEnd.getUTCFullYear() - 1, 0, 1);
        const prevEnd = new Date(curEnd.getUTCFullYear(), 0, 0);
        const diffStart = curStart.getTime() - prevStart.getTime();
        const diffEnd = curEnd.getTime() - prevEnd.getTime();

        return {
          chart: {
            type: "column",
            zoomType: "x",
            animation: false,
          },
          legend: {
            enabled: true,
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
          },
          tooltip: {
            valueDecimals: 2,
          },
          rangeSelector: {
            // selected: 1,
            allButtonsEnabled: true,
            buttonTheme: {
              width: "auto",
            },
            buttons: [
              {
                type: "month",
                count: 2,
                text: "3m",
              },
              {
                type: "month",
                count: 12,
                text: "12m",
              },
              {
                type: "ytd",
                text: "Last year",
                offsetMin: -diffStart,
                offsetMax: -diffEnd,
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ],
          },
          time: {
            timezone: props.timezone,
          },
          series: allSeries,
        };
      } catch (error) {
        return {};
      }
    };

    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: props.BuildingId,
        metaIds: findMetaIds(props.Trends, "AHU_FLOW_INTEGRAL"),
        trend: "AHU_FLOW_INTEGRAL",
        resolution: "day",
      },
      sort: { field: "Date", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        if (isMounted) {
          setOptionsDay(graphOptions(response.values));
          setOptionsMonth(graphOptions(response.values, true));
          setFetchDone(true);
        }
      });

    // Hourly data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: props.BuildingId,
        metaIds: findMetaIds(props.Trends, "AHU_FLOW_INTEGRAL"),
        trend: "AHU_FLOW_INTEGRAL_HOUR",
        resolution: "hour",
      },
      sort: { field: "Date", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        if (isMounted) {
          let options = null;
          if (response && response.values && response.values.length > 0) {
            options = graphOptions(response.values, false, true);
          }
          if (options && options !== {}) {
            setOptionsHour(options);
          }
          setFetchDone(true);
        }
      });
  }, [
    props.BuildingId,
    props.Trends,
    props.Ahu.CalculatedWeeklyAirFlowTargets,
    isMounted,
    props.timezone,
  ]);

  let options = null;
  if (resolution === "day") {
    options = cloneDeep(optionsDay);
  } else if (resolution === "month") {
    options = cloneDeep(optionsMonth);
  } else if (resolution === "hour") {
    options = cloneDeep(optionsHour);
  }

  const handleResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  const ResolutionInput = (
    <div
      key={"resInput"}
      style={{
        marginLeft: 15,
        width: 80,
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <InputLabel>Resolution</InputLabel>
      <Select value={resolution} onChange={handleResolutionChange}>
        <MenuItem value={"hour"}>Hour</MenuItem>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
      </Select>
    </div>
  );

  let showData = true;
  if (
    !find(props.Trends, (y) => y.TrendId === "AHU_FLOW_INTEGRAL") ||
    !options ||
    !options.series ||
    options.series.length === 0
  ) {
    showData = false;
  }

  if (props.showContainer && props.hideContainer && fetchDone) {
    if (showData) {
      props.showContainer("integral");
    } else {
      props.hideContainer("integral");
    }
  }

  return (
    <CardWithTitle title="Airflow integral">
      {showData ? (
        [
          ResolutionInput,
          <HighchartsReact
            key="ahuIntChart"
            highcharts={Highcharts}
            options={options}
            constructorType={"stockChart"}
          />,
        ]
      ) : (
        <div style={{ height: "20rem" }}>
          <EmptyData />
        </div>
      )}
    </CardWithTitle>
  );
};
