import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import { sortBy } from "lodash";
import moment from "moment";

const ZoneNPSGraph = (props) => {
  const [options, setOptions] = useState({
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "NPS",
        },
      },
    ],
    navigator: {
      enabled: true,
    },
    series: [],
  });
  const [chartCreated, setChartCreated] = useState(false);

  // Create series
  useEffect(() => {
    const createSeriesData = (data) => {
      let seriesData = [];
      if (data && data.length > 0) {
        data.forEach((d) => {
          // Set date to start of month to show it as Month - Year in chart
          const date = moment.utc(new Date(d.year, d.month - 1, 10));
          date.startOf("month");
          seriesData.push([date.valueOf(), d.nps]);
        });
      }
      // Sort by date
      seriesData = sortBy(seriesData, (s) => s[0]);
      return seriesData;
    };
    if (props.monthlyNps && props.monthlyNps.length > 0) {
      setOptions({
        series: [
          {
            name: "NPS",
            id: "NPS",
            data: createSeriesData(props.monthlyNps),
          },
        ],
      });
    }
  }, [props.monthlyNps]);

  if (!props.monthlyNps || !props.monthlyNps.length > 0) {
    if (props.hideContainer) {
      props.hideContainer("nps");
    }
  } else if (props.showContainer) {
    props.showContainer("nps");
  }

  return (
    <CardWithTitle title="Zone NPS">
      <div style={{ height: 450 }}>
        {options.series.length > 0 || !chartCreated ? (
          <HighchartsReact
            options={options}
            highcharts={Highcharts}
            constructorType="stockChart"
            callback={() => setChartCreated(true)}
          />
        ) : (
          <EmptyData />
        )}
      </div>
    </CardWithTitle>
  );
};

export default ZoneNPSGraph;
