import { useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { setBuildingStats } from "../customReducers/actions";

function useBuildingStats(_id) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dispatch(
      setBuildingStats({
        loading: true,
        loaded: false,
        data: null,
      })
    );
    dataProvider
      .getOne("2.0/buildingstats", {
        id: _id,
      })
      .then((res) => {
        if (res) {
          const { data } = res;
          if (data) {
            dispatch(
              setBuildingStats({
                loading: false,
                loaded: true,
                data: data,
              })
            );
          }
        }
      })
      .catch((error) => {
        notify(error.message, "warning");
        dispatch(
          setBuildingStats({
            loading: false,
            loaded: true,
            data: null,
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify, _id, dispatch]);

  return null;
}

export default useBuildingStats;
