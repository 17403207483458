import React from "react";
import { makeStyles, Typography, Tooltip } from "@material-ui/core";
import { isNil, round } from "lodash";

const useStyles = makeStyles({
  container: {
    backgroundColor: (props) => props.backgroundColor,
    height: (props) => props.height,
    width: (props) => props.height / 0.75,
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 8,
    padding: "0 4px",
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: "#fff",
  },
});

const InputBox = (props) => {
  const classes = useStyles(props);
  const { data, unit, divider = 1 } = props;

  return (
    <div className={classes.container} style={props.style}>
      <Typography
        align="center"
        className={classes.text}
        noWrap
        title={!isNil(data) && !isNil(data.value) ? data.value : ""}
      >
        {!isNil(data) && !isNil(data.value) ? (
          <Tooltip title="Last 24h">
            <span>{round(data.value / divider, 2)}</span>
          </Tooltip>
        ) : (
          "--"
        )}
      </Typography>
      <Typography align="center" className={classes.text} noWrap>
        {unit}
      </Typography>
    </div>
  );
};

export default InputBox;
