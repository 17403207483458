import React from "react";
import CustomAppBar from "./CustomAppBar";
import { withStyles } from "@material-ui/core/styles";
import BuildingSelect from "./BuildingSelect";

const styles = {
  toolbar: {
    // background: "#532e92"
  }
};

const CustomAppBarWrapper = props => (
  <CustomAppBar {...props} userMenu={<BuildingSelect />} />
);

export default withStyles(styles)(CustomAppBarWrapper);
