import { useRef } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";

function useBuildingTimezone() {
  const tz = useRef(null);
  const buildingData = useSelector(
    (state) => state.buildingFilter.buildingData
  );

  // Get tz either from buildingdata/redux or from sessionStorage
  tz.current =
    get(buildingData, "timeZoneIANA", null) ||
    sessionStorage.getItem("BuildingTimezone");

  return tz.current;
}

export default useBuildingTimezone;
