const handleLegendClick = (
  series,
  groupNames = ["Outside Temperatures"],
  seriesNames = ["Temperature "]
) => {
  const name = series.name;
  // Create a boolean visibility variable for each seriesname, init as false.
  let seriesVisibilities = [];
  groupNames.forEach(() => seriesVisibilities.push(false));

  // Find the temperature group
  for (let i = 0; i < series.chart.series.length; i++) {
    for (let j = 0; j < groupNames.length; j++) {
      if (series.chart.series[i].name === groupNames[j]) {
        seriesVisibilities[j] = series.chart.series[i].visible;
      }
    }
  }
  // If group series is hidden, do not hide/show the related temperature group
  if (series.visible) {
    series.hide();
    //Try to find a matching series
    for (let i = 0; i < series.chart.series.length; i++) {
      for (let j = 0; j < seriesNames.length; j++) {
        if (
          series.chart.series[i].name === seriesNames[j] + name &&
          seriesVisibilities[j]
        ) {
          series.chart.series[i].hide();
        }
      }
    }
    return;
  } else {
    series.show();
    for (let i = 0; i < series.chart.series.length; i++) {
      for (let j = 0; j < seriesNames.length; j++) {
        if (
          series.chart.series[i].name === seriesNames[j] + name &&
          seriesVisibilities[j]
        ) {
          series.chart.series[i].show();
        }
      }
    }
    return;
  }
};

export default handleLegendClick;
