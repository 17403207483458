/* This is a React component that renders a Highcharts graph based on data passed in as props. It
imports various dependencies such as Highcharts, HighchartsReact, and modules for exporting and
exporting data. It also imports several custom data mappers and a utility function for checking
graph data. The component uses state and effect hooks to manage loading and updating the graph data.
It also conditionally renders a spinner or an empty data component based on the loading state and
the availability of graph data. Finally, it exports the component for use in other parts of the
application. */
import React, { useEffect, useState, useRef } from "react";
import Spinner from "../../components/layout/Spinner";
import { makeStyles } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import EmptyData from "../../components/layout/EmptyData";
import usePurchasedDataMapper from "../datamappers/purchased/usePurchasedDataMapper";
import useTargetDataMapper from "../datamappers/purchased/useTargetDataMapper";
import graphOptions from "../datamappers/consumed/graphOptions";
import useTemperatureDataMapper from "../datamappers/purchased/useTemperatureDataMapper";
import useTrendsDataMapper from "../datamappers/consumed/useTrendsDataMapper";
import { useSelector } from "react-redux";
import setLegendItemHover from "../datamappers/setLegendItemHover";
import checkGraphData from "../../utils/checkGraphData";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    height: "28rem",
    display: "flex",
    alignItems: "center",
  },
}));

const ConsumedGraph = (props) => {
  const classes = useStyles();
  const chart = useRef(null);
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null); //graphData is the final data to be send to Highcharts
  const [consumptionData, consumptionRaw] = usePurchasedDataMapper(
    props.resource,
    props.resource === "heating" && props.norm ? "ConsNorm" : "Consumption",
    props.norm ? true : false
  );
  const targetData = useTargetDataMapper(props.resource, "Consumption");
  const temperatureData = useTemperatureDataMapper({
    consumption: consumptionRaw,
    type: props.resource,
  });
  const ahuData = useSelector((state) => state.buildingFilter.ahuData);
  const hpData = useSelector((state) => state.buildingFilter.hpData);
  const [trendData] = useTrendsDataMapper(props.resource, props.type);

  /* This `useEffect` hook is responsible for updating the `graphData` state whenever there is a change
in the dependencies passed to it. It sets the `loading` state to `true`, then creates a new `name`
variable based on the `props.resource` and `props.norm` values. It then creates a new
`editedConsumption` array by iterating over the `consumptionData` array and pushing a new object for
each item with modified properties. Finally, it creates a new `options` object using the
`graphOptions` function and sets the `graphData` state to this new object. */
  useEffect(() => {
    setLoading(true);
    let name = props.resource === "heating" ? "District Heating " : "Utility ";
    name = props.resource === "heating" && props.norm ? "Total Heating " : name;
    let editedConsumption = [];
    if (consumptionData && consumptionData.length > 0) {
      if (consumptionData && consumptionData.length > 0) {
        consumptionData.forEach((c) => {
          editedConsumption.push({
            ...c,
            stack: c.name,
            linkedTo: c.name.toString(),
            showInLegend: false,
            name: name + c.name,
            event: null,
          });
        });
      }
    }

    // Create year groups
    let trends = [];
    if (trendData) {
      trends = trendData;
    }
    let options = graphOptions(
      editedConsumption,
      trends,
      ahuData,
      hpData,
      props.target ? targetData : null,
      props.temperature ? temperatureData : null,
      props.resource,
      props.type,
      true,
      props.norm
    );
    setGraphData(options);
  }, [
    consumptionData,
    targetData,
    ahuData,
    hpData,
    temperatureData,
    props.resource,
    props.target,
    props.temperature,
    props.type,
    trendData,
    props.norm,
  ]);
  // Additional useEffect to set loading to false when graphData changes
  useEffect(() => {
    if (graphData) {
      setLoading(false);
    }
  }, [graphData]);

  if (loading || props.loading) {
    return <Spinner style={{ height: "25rem" }} />;
  }
  if (checkGraphData({ data: graphData })) {
    if (props.showGraph && props.name) {
      props.showGraph(props.name);
    }
    return (
      <HighchartsReact
        ref={chart}
        highcharts={Highcharts}
        options={graphData}
        callback={setLegendItemHover}
      />
    );
  } else {
    if (props.hideGraph && props.name) {
      props.hideGraph(props.name);
    }
    return (
      <div className={classes.emptyContainer}>
        <EmptyData />
      </div>
    );
  }
};

export default ConsumedGraph;
