import React, { useState } from "react";
import { useQueryWithStore, useNotify } from "react-admin";
import {
  Box,
  Button,
  CardActions,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import OperationLog from "./OperationLog";
import useUserRole from "../../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    height: 420,
    width: "100%",
    color: "#282331",
  },
  logs: {
    height: 352.97,
  },
  noLogs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: "1.875rem",
    fontStyle: "italic",
    opacity: "38%",
  },
  moreLogs: {
    fontSize: "0.75rem",
    fontWeight: 300,
    color: "#282331",
  },
  logLink: {
    textDecoration: "none",
  },
  buttonLabel: {
    color: "#5125AA",
  },
  buttonRoot: {
    paddingLeft: "0",
  },
  buttonContainer: {
    height: "4em",
    padding: "0 1.5em",
  },
}));

const OperationLogs = (props) => {
  const { isAdminOrUser } = useUserRole();
  const classes = useStyles();
  const notify = useNotify();
  const [logs, setLogs] = useState([]);
  const [morePinned, setMorePinned] = useState(false);
  const MAX_COUNT = 5;

  const isPermissions = isAdminOrUser;

  const { error } = useQueryWithStore(
    {
      type: "getList",
      resource: "operationlog",
      payload: {
        filter: {
          BuildingId: props && props.buildingId ? props.buildingId : null,
        },
        sort: { field: "id", order: "DESC" },
        pagination: {},
      },
    },
    {
      onSuccess: ({ data }) => {
        if (data && data.length > 0) {
          // if twin id is given, filter out logs that do not have the twinId
          if (props.twinId) {
            data = data.filter((d) => d.OperationBlocks.includes(props.twinId));
          }
          // First filter out all the pinned logs
          const pinned = data.filter((d) => d.Pinned);
          if (pinned.length > MAX_COUNT) {
            setMorePinned(true);
          } else {
            setMorePinned(false);
          }
          const notPinned = data.filter((d) => !d.Pinned);
          const allLogs = [...pinned, ...notPinned];
          // Only get the X latest logs
          let logs = [];
          for (let i = 0; i < MAX_COUNT; i++) {
            if (typeof allLogs[i] !== "undefined") {
              logs.push(allLogs[i]);
            }
          }
          setLogs(logs);
          if (props.logsCb) {
            props.logsCb(logs);
          }
          if (props.showContainer && props.hideContainer) {
            if (logs && logs.length > 0) {
              props.showContainer("logs");
            } else {
              props.hideContainer("logs");
            }
          }
        }
      },
    }
  );

  if (error) {
    notify(error.message, "warning");
  }

  return (
    <Box className={classes.root}>
      <div className={classes.logs}>
        {logs.length > 0 ? (
          logs.map((l) => {
            const active = l.State === "1. Active";
            return (
              <OperationLog
                key={l.id}
                id={l.id}
                subCategory={isPermissions ? l.meta.Name : ""}
                title={l.Title}
                date={l.CreatedAt}
                user={l.CreatedBy}
                active={active}
                pinned={l.Pinned}
                isPermissions={isPermissions}
              />
            );
          })
        ) : (
          <div className={classes.noLogs}>No logs</div>
        )}
      </div>
      <CardActions className={classes.buttonContainer} disableSpacing>
        <RouterLink className={classes.logLink} to={"/operationlog"}>
          {morePinned && (
            <Typography className={classes.moreLogs}>
              More pinned logs found!
            </Typography>
          )}
          <Button
            variant="text"
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          >
            OPEN LOG
          </Button>
        </RouterLink>
      </CardActions>
    </Box>
  );
};

export default OperationLogs;
