import React from "react";
import {
  AutocompleteInput,
  ArrayInput,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
  FormDataConsumer,
  BooleanInput,
  minValue,
  maxValue,
  number
} from "react-admin";

import optionRenderer from "../utils/optionRenderer";
import { BuildingBlock } from '../types/leasegreen-core-api-exports';

export const HpDrInputs = (formData: BuildingBlock) => {
  var showStatus = formData?.Hp?.DemandResponseParameters?.DEMAND_RESPONSE_ACTIVE ? formData?.Hp?.DemandResponseParameters?.DEMAND_RESPONSE_ACTIVE : false;
  var boilerStatus = formData?.Hp?.DemandResponseParameters?.DEMAND_RESPONSE_ACTIVE && formData?.Hp?.DemandResponseParameters?.DR_BOILERS ?
    formData?.Hp?.DemandResponseParameters?.DR_BOILERS : false;

  // Input validation  
  const drActiveValidation = (value: string | number, allValues: BuildingBlock) => {
    if (allValues?.Hp?.DemandResponseParameters?.DEMAND_RESPONSE_ACTIVE && value === undefined) {
      return 'Required value';
    }
    return undefined;
  };

  const drFieldValidation = [drActiveValidation, number(), minValue(0), maxValue(100)]

  return (
    <div>
      <BooleanInput label="Demand Response active" source="Hp.DemandResponseParameters.DEMAND_RESPONSE_ACTIVE" defaultValue={false} />
      <div style={{ display: showStatus ? "inline-flex" : "none", width: "100%" }}>
        <ArrayInput source="Hp.DemandResponseVariables" fullWidth>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <ReferenceInput
                    fullWidth
                    label={!scopedFormData?.VariableName ? `Power output measuring point` : scopedFormData?.VariableName}
                    source={getSource('VariableValue')}
                    reference="registerlists"
                    filter={{ buildingId: formData.BuildingId }}
                    allowEmpty={true}
                  >
                    <AutocompleteInput
                      optionText={optionRenderer}
                      optionValue="PointId"
                      fullWidth={true}
                    />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </div>

      <div style={{ display: showStatus ? "inline-flex" : "none", width: "100%" }}>
        <BooleanInput label="Machine learning model created" source="Hp.DemandResponseParameters.DR_ML_MODEL_CREATED" defaultValue={false} disabled />
      </div>
      <div style={{ display: showStatus ? "inline-flex" : "none", width: "100%" }}>
        <BooleanInput label="Demand Response includes boilers" source="Hp.DemandResponseParameters.DR_BOILERS" defaultValue={false} />
      </div>
      <div style={{ display: showStatus ? "inline-flex" : "none", width: "100%" }}>
        <TextInput style={{ width: "33.3%" }} label="Compressor amount" source="Hp.DemandResponseParameters.COMPRESSOR_AMOUNT" fullWidth validate={drFieldValidation} />
        <TextInput style={{ width: "33.3%" }} label="Tank amount" source="Hp.DemandResponseParameters.TANK_AMOUNT" fullWidth validate={drFieldValidation} />
        <TextInput style={{ display: showStatus && !boilerStatus ? "inline-flex" : "none", width: "33.3%" }} label="Relay amount"
          source="Hp.DemandResponseParameters.RELAY_AMOUNT" fullWidth validate={drFieldValidation} />
      </div>
      <div style={{ display: showStatus ? "inline-flex" : "none", width: "100%" }}>
        <TextInput label="Tank Setpoint down" source="Hp.DemandResponseParameters.DR_TANK_SETPOINT_DOWN" fullWidth validate={drFieldValidation} />
        <TextInput label="Tank Setpoint up" source="Hp.DemandResponseParameters.DR_TANK_SETPOINT_UP" fullWidth validate={drFieldValidation} />
        <TextInput label="Tank Setpoint stop" source="Hp.DemandResponseParameters.DR_TANK_SETPOINT_STOP" fullWidth validate={drFieldValidation} />
      </div>
      <div style={{ display: showStatus && boilerStatus ? "inline-flex" : "none", width: "100%" }}>
        <TextInput label="Heat boiler Setpoint down" source="Hp.DemandResponseParameters.DR_HEAT_BOILER_CTRL_DOWN" fullWidth validate={drFieldValidation} />
        <TextInput label="Heat boiler Setpoint up" source="Hp.DemandResponseParameters.DR_HEAT_BOILER_CTRL_UP" fullWidth validate={drFieldValidation} />
        <TextInput label="Heat boiler Setpoint stop" source="Hp.DemandResponseParameters.DR_HEAT_BOILER_CTRL_STOP" fullWidth validate={drFieldValidation} />
      </div>
      <div style={{ display: showStatus && boilerStatus ? "inline-flex" : "none", width: "100%" }}>
        <TextInput label="Water boiler Setpoint down" source="Hp.DemandResponseParameters.DR_WATER_BOILER_CTRL_DOWN" fullWidth validate={drFieldValidation} />
        <TextInput label="Water boiler Setpoint up" source="Hp.DemandResponseParameters.DR_WATER_BOILER_CTRL_UP" fullWidth validate={drFieldValidation} />
        <TextInput label="Water boiler Setpoint stop" source="Hp.DemandResponseParameters.DR_WATER_BOILER_CTRL_STOP" fullWidth validate={drFieldValidation} />
      </div>
      <div style={{ display: showStatus ? "inline-flex" : "none", width: "100%" }}>
        <TextInput label="Domestic hot water Setpoint down" source="Hp.DemandResponseParameters.DR_DHW_SETPOINT_DOWN" fullWidth validate={drFieldValidation} />
        <TextInput label="Domestic hot water Setpoint up" source="Hp.DemandResponseParameters.DR_DHW_SETPOINT_UP" fullWidth validate={drFieldValidation} />
        <TextInput label="Domestic hot water Setpoint stop" source="Hp.DemandResponseParameters.DR_DHW_SETPOINT_STOP" fullWidth validate={drFieldValidation} />
      </div>
    </div>
  );
}
