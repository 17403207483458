import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDataProvider, useNotify } from "react-admin";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

import { round, groupBy } from "lodash";
import useBuildingTimezone from "../../utils/hooks/useBuildingTimezone";
import { defaultColors, defaultLineColors } from "../../utils/graphColor";
import EmptyData from "../../components/layout/EmptyData";

const findMetaIds = (trends = [], id, values = []) => {
  const trend = trends.find((t) => t.TrendId === id);
  if (trend && trend.Values && trend.Values.length > 0) {
    const vals = [];
    values.forEach((v) => {
      trend.Values.forEach((tv) => {
        if (tv.VariableName.includes(v) && !vals.includes(tv)) {
          vals.push(tv);
        }
      });
    });
    if (vals) {
      return vals;
    }
  }
  return [];
};

const HpTrendsGraph = ({
  Trends,
  trendId,
  trendValues,
  name,
  resolution,
  targets,
  valueDivider = 1,
  unit,
  buildingId,
  naviMetaId,
  showGraph,
  hideGraph,
  graphType = "column",
  graphColors = defaultColors,
  targetColor = defaultLineColors[0],
  stack = false,
}) => {
  const [fetchDone, setFetchDone] = useState(false);
  const notify = useNotify();
  const tz = useBuildingTimezone();
  const [metaIds, setMetaIds] = useState();
  const [options, setOptions] = useState();
  const [graphDates, setGraphDates] = useState({
    min: moment.utc().subtract(3, "months").format(),
    max: moment.utc().format(),
  });

  const dataProvider = useDataProvider();
  const chartRef = useRef();

  const defaultChartOptions = {
    chart: {
      zoomType: "x",
      type: graphType,
    },
    colors: graphColors,
    legend: {
      enabled: true,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: ` ${unit}`,
      xDateFormat: resolution !== "month" ? "%A, %b %e %Y, %H:%M" : "%B, %Y",
    },
    xAxis: {
      events: {
        afterSetExtremes: (event) => {
          if (
            event.trigger &&
            event.min &&
            event.max &&
            !isNaN(event.min) &&
            !isNaN(event.max)
          ) {
            setGraphDates({
              min: moment(event.min).format(),
              max: moment(event.max).format(),
            });
          }
        },
      },
    },
    rangeSelector: {
      inputEnabled: false,
      selected: 5,
      buttons: [
        {
          type: "day",
          count: 1,
          text: "1d",
        },
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "month",
          count: 3,
          text: "3m",
        },
        {
          type: "month",
          count: 6,
          text: "6m",
        },
        {
          type: "all",
          text: "All",
        },
      ],
    },
    title: {
      text: "",
    },
    series: [],
    scrollbar: {
      liveRedraw: false,
      adaptToUpdatedData: false,
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: true,
    },
  };

  const graphOptions = useCallback(
    ({ data, naviData, metaIds, targetData, resolution }) => {
      // Function to map values to highcharts format
      const valuesToHighchartsData = (dataFromReactAdmin) => {
        const highChartsData = dataFromReactAdmin.map((v) => {
          const date = v.date
            ? new Date(v.date)
            : new Date(Date.UTC(v.year, v.month - 1, 1));
          // Heating is converted to MWh from KWh
          return [date.getTime(), round(v.value / valueDivider, 2)];
        });
        return highChartsData;
      };

      // Create series based on selected data, one series for each variable/measurement
      let series = [];

      const grouped = groupBy(data, "metaId");
      const keys = Object.keys(grouped);
      // Sort by the original metaIds
      const originalMetaIds = metaIds.map((m) => m.ReportingPointId);
      keys.sort((a, b) => {
        return originalMetaIds.indexOf(+a) - originalMetaIds.indexOf(+b);
      });
      keys.forEach((k) => {
        if (grouped[k] && grouped[k][0] && metaIds && metaIds.length > 0) {
          // Find correct legends
          let trend = null;
          trend = metaIds.find(
            (m) => m.ReportingPointId === grouped[k][0].metaId
          );
          if (trend && trend.Legend) {
            series.push({
              id: trend.Legend,
              name: trend.Legend,
              data: valuesToHighchartsData(grouped[k], targetData),
              dataGrouping: {
                enabled: false,
              },
              ...(stack && { stack: "stack" }),
            });
          }
        }
      });

      // Target series
      // First get all years and then add the monthly target data for those years
      if (resolution === "month" && targetData && targetData.length > 0) {
        const targetDataYears = [];
        let newestTime = 0;
        let oldestTime;

        // Check all series data and find all the data years and the newest timestamp
        series.forEach((s) => {
          if (s && s.data && s.data.length > 0) {
            s.data.forEach((d) => {
              const year = new Date(d[0]).getUTCFullYear();
              newestTime = d[0] > newestTime ? d[0] : newestTime;
              oldestTime = !oldestTime ? d[0] : oldestTime;
              oldestTime = d[0] <= oldestTime ? d[0] : oldestTime;
              if (!targetDataYears.includes(year)) {
                targetDataYears.push(year);
              }
            });
          }
        });

        const newestDate = new Date(newestTime);
        const oldestDate = new Date(oldestTime);
        const targetSeriesData = [];
        targetDataYears.forEach((y) => {
          targetData.forEach((t) => {
            if (
              (y < newestDate.getFullYear() &&
                t.month >= oldestDate.getUTCMonth() + 1) ||
              (y === newestDate.getFullYear() &&
                t.month <= newestDate.getUTCMonth() + 1)
            ) {
              targetSeriesData.push([
                new Date(Date.UTC(y, t.month - 1, 1)).getTime(),
                t.value,
              ]);
            }
          });
        });

        series.push({
          id: "target",
          name: "Target",
          color: targetColor,
          data: targetSeriesData,
          type: "line",
        });
      }
      return {
        time: {
          timezone: tz,
        },
        plotOptions: {
          column: {
            minPointLength: 3
          },
          series: {
            stacking: stack ? "normal" : undefined,
          },
        },
        yAxis: {
          reversedStacks: false,
        },
        series: series,
        navigator: {
          adaptToUpdatedData: false,
          ...(naviData &&
            naviData.length > 0 && {
              series: { data: valuesToHighchartsData(naviData) },
            }),
          enabled: true,
        },
      };
    },
    [tz, stack, valueDivider, targetColor]
  );

  useEffect(() => {
    // Get the correct metaId's
    if (Trends && Trends.length > 0) {
      setMetaIds(findMetaIds(Trends, trendId, trendValues));
    }
  }, [Trends, trendId, trendValues, name]);

  // useEffect for the new data fetching
  useEffect(() => {
    const naviId =
      metaIds && metaIds.find((m) => m.VariableName.includes(naviMetaId))
        ? metaIds.find((m) => m.VariableName.includes(naviMetaId))
        : null;
    if (
      metaIds &&
      metaIds.length > 0 &&
      buildingId &&
      graphDates &&
      graphDates.min &&
      graphDates.max
    ) {
      if (chartRef && chartRef.current) {
        chartRef.current.chart.showLoading("Loading data from server...");
      }
      const fetchIds = metaIds.map((m) => m.ReportingPointId);
      dataProvider
        .getList("reportdata", {
          filter: {
            v2: true,
            naviSeries: naviId ? naviId.ReportingPointId : null,
            buildingId: buildingId,
            metaIds: fetchIds,
            resolution: resolution,
            trend: naviId ? naviId.VariableName : name,
            dataBegin: graphDates.min,
            dataEnd: graphDates.max,
          },
          sort: {},
          pagination: {},
        })
        .then(({ data }) => {
          if (chartRef && chartRef.current && data && data.values) {
            const opt = graphOptions({
              data: data.values,
              naviData: data.naviSeries ? data.naviSeries.Values : [],
              metaIds,
              targetData: targets,
              resolution,
            });
            setOptions(opt);
            chartRef.current.chart.update(opt, true, true, true);
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        })
        .finally(() => {
          setFetchDone(true);
          if (chartRef && chartRef.current) {
            chartRef.current.chart.hideLoading();
          }
        });
    } else if (metaIds && metaIds.length === 0) {
      setFetchDone(true);
    }
  }, [
    dataProvider,
    graphOptions,
    metaIds,
    naviMetaId,
    buildingId,
    graphDates,
    notify,
    resolution,
    targets,
    name,
  ]);

  let showData = true;

  if (
    fetchDone &&
    (!options || !options.series || options.series.length === 0)
  ) {
    showData = false;
  }

  if (showGraph && hideGraph && fetchDone) {
    if (showData) {
      showGraph(name);
    } else {
      hideGraph(name);
    }
  }

  return showData ? (
    <HighchartsReact
      allowChartUpdate={false}
      highcharts={Highcharts}
      constructorType="stockChart"
      options={defaultChartOptions}
      ref={chartRef}
    />
  ) : (
    <EmptyData style={{ height: "20rem" }} />
  );
};

export default HpTrendsGraph;
