import React from "react";
import DigitalTwinsContainer from "../dashboard/digitaltwins/DigitalTwinsContainer";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {},
  item: {},
});

const ZoneAhus = (props) => {
  const classes = useStyles();

  return props.ahus && props.ahus.length > 0 ? (
    <DigitalTwinsContainer
      className={classes.container}
      ahuMap={props.zoneMap}
      zoneMap={props.zoneMap}
      twinsToRight
      twins={props.ahus}
    />
  ) : null;
};

export default ZoneAhus;
