import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  text: {
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const FavoriteReminders = ({ id }) => {
  const classes = useStyles();
  const reminders = useSelector((state) => state.buildingFilter.reminders);
  const reminderCount =
    reminders && reminders.filter((r) => r.BuildingId === id).length;
  return (
    <Typography
      style={{
        color:
          reminderCount !== null && reminderCount > 0 ? "#D62B1A" : "#282331",
      }}
      className={classes.text}
    >
      {reminderCount !== null ? reminderCount : ""}
    </Typography>
  );
};

export default FavoriteReminders;
