import * as React from "react";
import CheckFeedbackbutton from "./CheckFeedbackButton";
import UncheckFeedbackbutton from "./UncheckFeedbackButton";

const Feedbackbuttons = ({ record }) => {
  return record.checked ? (
    <UncheckFeedbackbutton record={record} />
  ) : (
    <CheckFeedbackbutton record={record} />
  );
};

export default Feedbackbuttons;
