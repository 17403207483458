import React from "react";
import {
  AutocompleteInput,
  DateInput,
  Edit,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";

const useStyles = makeStyles(() => ({
  formInputs: {
    width: "70%",
  },
}));

const RemoteConnectionEdit = ({ BuildingId, ...props }) => {
  const classes = useStyles();
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  return (
    <>
      <PageTitle title="Edit Remote Connection" />
      <Edit {...listProps}>
        <SimpleForm redirect={false}>
          <TextInput source="BuildingName" className={classes.formInputs} />
          <ReferenceInput
            label="Project name"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ showBAUI: true }}
            defaultValue={BuildingId}
            perPage={1000}
            validate={required()}
            className={classes.formInputs}
          >
            <AutocompleteInput
              optionText="ProjectName"
              className={classes.formInputs}
            />
          </ReferenceInput>
          <TextInput source="Interface" className={classes.formInputs} />
          <TextInput source="ConnectionType" className={classes.formInputs} />
          <TextInput
            source="SIMCardNumber"
            label="SIM card number"
            className={classes.formInputs}
          />
          <DateInput source="InstallationDate" className={classes.formInputs} />
          <DateInput source="ReturnedDate" className={classes.formInputs} />

          <TextInput
            multiline
            source="PCUsername"
            label="PC username"
            className={classes.formInputs}
          />
          <TextInput
            multiline
            source="PCPassword"
            label="PC password"
            className={classes.formInputs}
          />

          <TextInput
            multiline
            source="BMSUsername"
            label="BMS username"
            className={classes.formInputs}
          />
          <TextInput
            multiline
            source="BMSPassword"
            label="BMS password"
            className={classes.formInputs}
          />

          <TextInput
            source="TosiboxNATIP"
            label="Tosibox NAT IP"
            validate={required()}
            className={classes.formInputs}
          />
          <TextInput
            source="TosiboxLANIP"
            label="Tosibox LAN IP"
            className={classes.formInputs}
          />

          <TextInput
            source="DHCP"
            label="DHCP"
            className={classes.formInputs}
          />
          <TextInput
            multiline
            source="AdditionalInformation"
            className={classes.formInputs}
          />
          <TextInput
            multiline
            source="BMSAlterPolicy"
            label="BMS alter policy"
            className={classes.formInputs}
          />
          <TextInput source="TosiboxLocation" className={classes.formInputs} />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default stateMapper(RemoteConnectionEdit);
