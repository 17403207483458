import React from "react";
import PropTypes from "prop-types";
import OperationPinButton from "./OperationPinButton";

const OperationLogPinnedField = ({ record = {}, isPermissions }) => {
  return (
    <span>
      <OperationPinButton
        id={record.id}
        pinned={record.Pinned}
        isPermissions={isPermissions}
      />
    </span>
  );
};

OperationLogPinnedField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

export default OperationLogPinnedField;
