import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { setAlerts, setActiveAlertCount } from "../customReducers/actions";
import { alertCount } from "../home/BuildingsWithAlerts";

function useAlerts(id) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [buildingId, setBuildingId] = useState(null);
  const [viewVersion, setViewVersion] = useState(null);
  const curViewVersion = useSelector((state) => state.admin.ui.viewVersion);

  // Fetch new data when building is changed or refresh button clicked/new data is put)
  useEffect(() => {
    if (
      id !== buildingId ||
      (viewVersion !== curViewVersion && curViewVersion !== 1)
    ) {
      setBuildingId(id);
      setViewVersion(curViewVersion);

      dataProvider
        .getList("alerts", {
          filter: { BuildingId: id, active: true, check: false },
          sort: { field: "AlertId", order: "DESC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.length > 0) {
              const aCount = alertCount(data);
              dispatch(setAlerts(data));
              dispatch(setActiveAlertCount(aCount.activeUnchecked));
            } else {
              dispatch(setAlerts([]));
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        });
    }
  }, [
    dispatch,
    notify,
    dataProvider,
    curViewVersion,
    viewVersion,
    buildingId,
    id,
  ]);

  return null;
}

export default useAlerts;
