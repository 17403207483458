import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { setConsumptionByEnergyType } from "../customReducers/actions";

function useConsumption(id) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [buildingId, setBuildingId] = useState(null);
  const [viewVersion, setViewVersion] = useState(null);
  const curViewVersion = useSelector((state) => state.admin.ui.viewVersion);

  // Fetch new data when building is changed or refresh button clicked/new data is put)
  useEffect(() => {
    if (
      id !== buildingId ||
      (viewVersion !== curViewVersion && curViewVersion !== 1)
    ) {
      setBuildingId(id);
      setViewVersion(curViewVersion);

      dataProvider
        .getList("energytypelist", {
          filter: { BuildingId: id },
          sort: { field: "Time", order: "ASC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.length > 0) {
              dispatch(setConsumptionByEnergyType(data));
            } else {
              dispatch(setConsumptionByEnergyType([]));
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        });
    }
  }, [
    id,
    buildingId,
    dispatch,
    notify,
    dataProvider,
    curViewVersion,
    viewVersion,
  ]);

  return null;
}

export default useConsumption;
