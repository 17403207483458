import { setConsumptionUnitText } from "../../../utils/unitTextParsers";
import { sum } from "lodash";
import setVisibleYears from "./setVisibleYears";

const graphOptions = (
  graphData,
  targetData,
  temperatureSeries,
  type,
  value,
  ignoreZero = false
) => {
  let series = [];
  // Go through the graphData and only add non-empty years/series
  if (ignoreZero) {
    let nonZeroSeries = [];
    if (graphData && graphData.length > 0) {
      graphData.forEach(g => {
        if (sum(g.data) > 0) {
          nonZeroSeries.push(g);
        }
      });
      series = nonZeroSeries;
    }
  } else {
    series = graphData;
  }
  // Set visible years
  let visibleSeries = setVisibleYears(series);

  // Check if targetData exists
  if (targetData) {
    visibleSeries.push(targetData);
  }
  // Check chart y-axis
  let yAxis = [
    {
      // Primary axis
      min: 0,
      title: {
        text: setConsumptionUnitText(type)
      }
    }
  ];
  // Check if temperatureData exists
  if (temperatureSeries && temperatureSeries.length > 0) {
    temperatureSeries.map(s => visibleSeries.push(s));
    //series.push(temperatureSeries);
    // Also create secondary y-axis for outside temperature
    yAxis.push({
      min: -30,
      max: 30,
      title: {
        text: "Temperature (°C)"
      },
      opposite: true
    });
  }
  // Check chart title based on type
  // Custom titles for heating charts, others are type with 1st letter in uppercase
  let title = "";
  if (value === "ConsNorm") {
    title = "Heating - Weather Corrected";
  } else if (type === "heating") {
    title = "Heating - Absolute";
  } else {
    title = type[0].toUpperCase() + type.slice(1);
  }
  const finalSeries = visibleSeries;
  return {
    chart: {
      type: "column"
      // height: 600,
      // width: 1200
    },
    legend: {
      enabled: true
    },
    series: finalSeries,
    title: {
      text: title
    },
    tooltip: {
      shared: true,
      valueDecimals: 2
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      crosshair: true
    },
    yAxis: yAxis
  };
};

export default graphOptions;
