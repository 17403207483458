import {
  find,
  groupBy,
  map,
  round,
  some,
  get,
  first,
  filter,
  sum,
} from "lodash";
import { buildingTempToChart } from "../../../utils/raDataToHighcharts";
import createYearGroups from "../consumed/createYearGroups";
import setStackColor from "../consumed/setStackColor";
import handleTemperatureClick from "../purchased/handleTemperatureClick";
import raReportingDataToHighchartsData from "../consumed/raReportingDataToHighChartsData";
import graphColor from "../../../utils/graphColor";

const graphOptionsHeat = (buildingTrends, hpData, temperatureData) => {
  let data = [];
  if (hpData) {
    let k = Object.keys(hpData);
    k.forEach((k) => {
      if (k !== "trendData") {
        data.push(hpData[k]);
      }
    });
  }

  let producedExists = false;
  let copExists = false;

  let targetData = get(first(data), "Hp.MonthlyTargets", null);

  let filteredTrends = [];
  filteredTrends = filter(
    buildingTrends,
    (t) => t.trendId === "BUILDING_HEAT_PUMP_ENERGY"
  );

  // Check if HP version 2 to determine which trend to use for produced heating
  let producedSeriesName = "BUILDING_TOTAL_PRODUCED_ENERGY";
  let producedSeriesLegend = "";
  const producedWaterSeriesName = "BUILDING_HEAT_PUMP_TOTAL_WATER_ENERGY";

  filteredTrends.forEach((t) => {
    if (
      t.variableName === "BUILDING_HEAT_PUMP_TOTAL_HEATING_ENERGY" ||
      t.variableName === "BUILDING_HEAT_PUMP_TOTAL_COOLING_ENERGY" ||
      t.variableName === "BUILDING_HEAT_PUMP_TOTAL_WATER_ENERGY" ||
      t.variableName ===
        "BUILDING_HEAT_PUMP_TOTAL_WATER_BOILER_RESISTOR_ENERGY" ||
      t.variableName ===
        "BUILDING_HEAT_PUMP_TOTAL_HEATING_BOILER_RESISTOR_ENERGY"
    ) {
      producedSeriesName = "BUILDING_HEAT_PUMP_TOTAL_HEATING_ENERGY";
    }
  });

  const grouped = groupBy(filteredTrends, "metaId");
  let keys = Object.keys(grouped);
  // Reverse order to force the water trend on top
  keys = keys.reverse();
  let series = [];

  let years = null;
  keys.forEach((k) => {
    const groupedByYear = groupBy(grouped[k], "year");
    years = Object.keys(groupedByYear);
    years.forEach((y) => {
      let trendValue = find(filteredTrends, (v) => v.metaId === Number(k));
      // Only add COP and produced, not consumed/additional
      // For version2, also add produced water heating
      if (
        trendValue &&
        trendValue.legend &&
        (trendValue.legend.includes("COP") ||
          trendValue.variableName === producedSeriesName ||
          trendValue.variableName === producedWaterSeriesName)
      ) {
        // If the series is COP, add it to the second yaxis and make the series a line series
        const cop = trendValue.legend.includes("COP");
        if (cop) {
          copExists = true;
        } else if (trendValue.variableName === producedSeriesName) {
          producedSeriesLegend = trendValue.legend + " ";
          producedExists = true;
        }

        const seriesData = raReportingDataToHighchartsData(
          groupedByYear[y],
          cop ? "cop" : null
        );

        if (sum(seriesData) > 0) {
          series.push({
            name: trendValue.legend + " " + y,
            id:
              trendValue.variableName === producedSeriesName
                ? trendValue.legend + " " + y
                : undefined,
            linkedTo:
              trendValue.variableName === producedWaterSeriesName
                ? producedSeriesLegend + y
                : undefined,
            opacity:
              trendValue.variableName === producedSeriesName ? 1 : undefined,
            stack: parseInt(y),
            showInLegend: false,
            data: seriesData,
            yAxis: cop ? 1 : 0,
            type: cop ? "line" : "column",
          });
        }
      }
    });
  });

  // Calculate COP target and add to graph
  const copTargets = map(targetData, (t) =>
    round(t.ProducedTarget / t.ConsumedTarget, 2)
  );
  if (some(copTargets, (t) => !isNaN(t))) {
    series.push({
      name: "COP target",
      data: copTargets,
      type: "line",
      yAxis: 1,
      color: graphColor({
        index: 0,
        seriesLength: 1,
        lineSeries: true,
        type: "heating",
      }),
    });
  }

  // Create master groups for produced series and cop series
  if (producedExists) {
    series.push({
      name: "Produced energy",
      id: "Produced energy",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      // Area type has a circle as legend symbol but does not affect the size of other column series
      type: "area",
      showInLegend: false,
      visible: true,
      events: {
        legendItemClick: (event) => {
          event.preventDefault();
          handleTemperatureClick(event.target, producedSeriesLegend);
          return false;
        },
      },
    });
  }
  if (copExists) {
    series.push({
      name: "Building COP",
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      type: "line",
      showInLegend: true,
      visible: true,
      events: {
        legendItemClick: (event) => {
          event.preventDefault();
          handleTemperatureClick(event.target, "Total building COP ");
          return false;
        },
      },
    });
  }

  let tempSeries = [];
  let tempExists = false;
  if (years) {
    // Add outside temperature to graph
    let temperatureArr = [];
    // Get monthly averages if temperature data exists
    // Get the years of the first record and the last
    let dataBegin = first(years);
    let dataEnd = years[years.length - 1];
    if (temperatureData && temperatureData.length > 0) {
      temperatureArr = buildingTempToChart(
        temperatureData,
        new Date(dataBegin, 0, 1),
        new Date(dataEnd, 11, 31)
      );
      tempExists = true;

      // Create a group to control the visibility of temperatures
      tempSeries.push({
        name: "Outside Temperatures",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        type: "line",
        showInLegend: true,
        visible: true,
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(event.target);
            return false;
          },
        },
      });

      // Add temperatures for each year
      for (let i = 0; i < temperatureArr.length; i++) {
        const dataArr = [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ];
        temperatureArr[i].forEach((t) => {
          dataArr[parseInt(t.month) - 1] = round(t.val, 2);
        });
        let yearText = "";
        temperatureArr[i].forEach((t) => {
          if (t && t.year) {
            yearText = t.year;
          }
        });
        tempSeries.push({
          name: "Temperature " + yearText,
          data: dataArr,
          type: "line",
          zIndex: 9,
          yAxis: 2,
          visible: true,
          showInLegend: false,
          color: graphColor({
            index: i,
            seriesLength: temperatureArr.length,
            lineSeries: true,
            offset: 1,
            type: "heating",
          }),
        });
      }
    }
  }

  if (tempSeries && tempSeries.length > 0) {
    tempSeries.forEach((s) => {
      series.push(s);
    });
  }

  // Create year groups for the energy columns
  const yearGroups = createYearGroups({
    data: [...series],
    groupNames: ["Produced energy", "Building COP", "Outside Temperatures"],
    seriesNames: [producedSeriesLegend, "Total building COP ", "Temperature "],
    type: "heating",
  });
  if (yearGroups && yearGroups.length > 0) {
    series.push(...yearGroups);
  }

  // Set the colors
  series = setStackColor(series);

  // Set the stack property for every series to undefined, also set opacity to 1
  if (series && series.length > 0) {
    series.forEach((s) => {
      if (s.stack && s.type === "line") {
        s.stack = undefined;
      }
      if (s.opacity && s.type === "line") {
        s.opacity = 1.0;
      }
    });
  }

  // Create the yAxis
  let yAxis = [];
  yAxis.push({
    min: 0,
    title: {
      text: "Energy monthly (MWh)",
    },
    height: "60%",
    opposite: false,
  });
  yAxis.push({
    min: 0,
    title: {
      text: "COP monthly",
    },
    height: "60%",
    opposite: true,
  });
  if (tempExists) {
    yAxis.push({
      min: -30,
      max: 30,
      labels: {
        align: "left",
      },
      title: {
        text: "Temperature (°C)",
        align: "middle",
      },
      height: "30%",
      offset: 0,
      top: "70%",
      opposite: true,
    });
  }

  if (!series || series.length === 0) {
    return [];
  }

  return {
    chart: {
      type: "column",
    },
    legend: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: "Heat pumps monthly energy production",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: series,
    yAxis: yAxis,
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
  };
};

export default graphOptionsHeat;
