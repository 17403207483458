import React from "react";

import Arrow from "./Arrow";
import InputBox from "./InputBox";
import Tank from "./Tank";

const HeatingTank = ({
  arrowHeight,
  arrowOffset,
  extraPumps,
  inputData,
  energyPoints,
  existing = [],
}) => {
  const heatingSupply =
    inputData && inputData.find((d) => d.name === "heatingSupply");

  const heatingReturn =
    inputData && inputData.find((d) => d.name === "heatingReturn");

  const heatingTank =
    inputData && inputData.find((d) => d.name === "heatingTank");

  const heatingNetworkSupply =
    inputData && inputData.find((d) => d.name === "heatingNetworkSupply");

  const heatingNetworkReturn =
    inputData && inputData.find((d) => d.name === "heatingNetworkReturn");

  const heatingEnergy =
    energyPoints && energyPoints.find((d) => d.name === "heatingEnergy");

  const heatingResistor =
    energyPoints && energyPoints.find((d) => d.name === "heatingResistor");

  const center =
    existing &&
    !existing.includes("waterEnergy") &&
    !existing.includes("waterResistor");

  return (
    <>
      <Tank
        height={arrowHeight * 4}
        data={heatingTank}
        heating
        text="Heating tank"
        style={{
          left: `calc(100% + ${243}px)`,
          top: `${center ? 50 : 15 + extraPumps * 1}%`,
          transform: `translate(0,-${50}%)`,
        }}
      >
        {existing && existing.includes("heatingEnergy") && (
          <Arrow
            height={arrowHeight}
            width={243}
            top={`${38 + arrowOffset}%`}
            right="100%"
            translateX="0"
            translateY="-50%"
            text="Heating supply"
            data={heatingSupply}
            textLeft={42}
          >
            <InputBox
              style={{
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
              height={arrowHeight + 6}
              backgroundColor="#E9941E"
              data={heatingEnergy}
              divider={1000}
              unit="MWh"
            />
          </Arrow>
        )}
        {existing && existing.includes("heatingResistor") && (
          <Arrow
            height={arrowHeight}
            width={224}
            top={`-${19 - arrowOffset * 2}%`}
            right="50%"
            translateX={`${arrowHeight / 2}px`}
            translateY="-50%"
            cornerRadius="20%"
            toBottom
            text="Boiler resistor"
            textLeft={42}
          >
            <InputBox
              style={{
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
              height={arrowHeight + 6}
              backgroundColor="#01BD6D"
              data={heatingResistor}
              unit="kWh"
            />
          </Arrow>
        )}
        <Arrow
          height={arrowHeight}
          width={243}
          toLeft
          top={`${70 + arrowOffset / 4}%`}
          right="100%"
          translateX="0"
          translateY="-50%"
          text="Heating return"
          data={heatingReturn}
        />
        <Arrow
          height={arrowHeight}
          width={243}
          top={`${38 + arrowOffset}%`}
          left="100%"
          translateX="0"
          translateY="-50%"
          text="Heating network supply"
          data={heatingNetworkSupply}
        />
        <Arrow
          height={arrowHeight}
          width={243}
          toLeft
          top={`${70 + arrowOffset / 4}%`}
          left="100%"
          translateX="0"
          translateY="-50%"
          text="Heating network return"
          data={heatingNetworkReturn}
        />
      </Tank>
    </>
  );
};

export default HeatingTank;
