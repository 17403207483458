import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { get } from "lodash";

import Info from "./Info";

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: "center",
  },
}));

const BuildingInfo = (props) => {
  const classes = useStyles();
  const { isExternal } = props;

  // Form the info array from props
  let info = [];

  // Updated
  const BAUpdated = get(props, "buildingStats.bAUpdated", null);
  if (BAUpdated !== null && !isExternal) {
    info.push({
      key: "BAUpdated",
      title: "Updated",
      text: new Date(BAUpdated).toLocaleDateString(),
    });
  }

  // Baseline year
  const BaselineYear = get(props, "buildingStats.baselineYear", null);
  if (BaselineYear !== null) {
    info.push({
      key: "BaselineYear",
      title: "Baseline year",
      text: new Date(BaselineYear).getFullYear(),
    });
  }

  // Building area
  const area = get(props, "buildingStats.buildingArea", null);
  if (area !== null) {
    info.push({
      key: "area",
      title: "Building area",
      text: area + " m²",
    });
  }

  // Heating type
  const heatingType = get(props, "buildingStats.buildingHeatingType", null);
  if (heatingType !== null) {
    info.push({
      key: "heating",
      title: "Heating type",
      text: heatingType,
    });
  }

  // Building volume
  const volume = get(props, "buildingStats.buildingVolume", null);
  if (volume !== null) {
    info.push({
      key: "volume",
      title: "Building volume",
      text: volume + " m³",
    });
  }

  // Building year
  const BuildingYear = get(props, "buildingStats.buildingYear", null);
  if (BuildingYear !== null) {
    info.push({
      key: "BuildingYear",
      title: "Building year",
      text: BuildingYear,
    });
  }

  // Comparator
  const Comparator = get(props, "buildingStats.comparator", null);
  if (Comparator !== null) {
    info.push({
      key: "Comparator",
      title: "Comparator",
      text: Comparator,
    });
  }

  // ContractSigned
  const ContractSigned = get(props, "buildingStats.contractSigned", null);
  if (ContractSigned !== null && !isExternal) {
    info.push({
      key: "ContractSigned",
      title: "Contract signed",
      text: new Date(ContractSigned).toLocaleDateString(),
    });
  }

  // CoolingPrice
  const CoolingPrice = get(props, "buildingStats.coolingPrice", null);
  if (CoolingPrice !== null && !isExternal) {
    info.push({
      key: "CoolingPrice",
      title: "Cooling price",
      text: CoolingPrice + " €/MWh",
    });
  }

  // CoolingSavings
  const CoolingSavings = get(props, "buildingStats.coolingSavings", null);
  if (CoolingSavings !== null) {
    info.push({
      key: "CoolingSavings",
      title: "Cooling savings",
      text: CoolingSavings + " MWh/year",
    });
  }

  // Customer
  const Customer = get(props, "buildingStats.customer", null);
  if (Customer !== null) {
    info.push({
      key: "Customer",
      title: "Customer",
      text: `Type: ${Customer.Type}, Name: ${Customer.Name}`,
    });
  }

  // DHeatFlow
  const DHeatFlow = get(props, "buildingStats.dHeatFlow", null);
  if (DHeatFlow !== null && !isExternal) {
    info.push({
      key: "DHeatFlow",
      title: "District heating flow",
      text: DHeatFlow + "  m³/h",
    });
  }

  // DHeatPower
  const DHeatPower = get(props, "buildingStats.dHeatPower", null);
  if (DHeatPower !== null) {
    info.push({
      key: "DHeatPower",
      title: "District heating power",
      text: DHeatPower + " MWh",
    });
  }

  // DHeatPrice
  const DHeatPrice = get(props, "buildingStats.dHeatPrice", null);
  if (DHeatPrice !== null && !isExternal) {
    info.push({
      key: "DHeatPrice",
      title: "District heating price",
      text: DHeatPrice + " €/MWh",
    });
  }

  // ElectricityPower
  const ElectricityPower = get(props, "buildingStats.electricityPower", null);
  if (ElectricityPower !== null) {
    info.push({
      key: "ElectricityPower",
      title: "Electricity power",
      text: ElectricityPower + " MVA",
    });
  }

  // ElectricityPrice
  const ElectricityPrice = get(props, "buildingStats.electricityPrice", null);
  if (ElectricityPrice !== null && !isExternal) {
    info.push({
      key: "ElectricityPrice",
      title: "Electricity price",
      text: ElectricityPrice + " €/MWh",
    });
  }

  // ElectricitySavings
  const ElectricitySavings = get(
    props,
    "buildingStats.electricitySavings",
    null
  );
  if (ElectricitySavings !== null) {
    info.push({
      key: "ElectricitySavings",
      title: "Electricity savings",
      text: ElectricitySavings + " MWh/year",
    });
  }

  // EndingDateOfServiceInvoicing
  const EndingDateOfServiceInvoicing = get(
    props,
    "buildingStats.endingDateOfServiceInvoicing",
    null
  );
  if (EndingDateOfServiceInvoicing !== null && !isExternal) {
    info.push({
      key: "EndingDateOfServiceInvoicing",
      title: "Service invoice ending",
      text: new Date(EndingDateOfServiceInvoicing).toLocaleDateString(),
    });
  }

  // EstimatedHandoverDate
  const EstimatedHandoverDate = get(
    props,
    "buildingStats.estimatedHandoverDate",
    null
  );
  if (EstimatedHandoverDate !== null && !isExternal) {
    info.push({
      key: "EstimatedHandoverDate",
      title: "Estimated handover",
      text: new Date(EstimatedHandoverDate).toLocaleDateString(),
    });
  }

  // FinalDeliveryDate
  const FinalDeliveryDate = get(props, "buildingStats.finalDeliveryDate", null);
  if (FinalDeliveryDate !== null && !isExternal) {
    info.push({
      key: "FinalDeliveryDate",
      title: "Final delivery",
      text: new Date(FinalDeliveryDate).toLocaleDateString(),
    });
  }

  // Handover date
  const handoverDate = get(props, "buildingTwin.Energy.HandoverDate", null);
  if (handoverDate !== null) {
    info.push({
      key: "handover",
      title: "Handover date",
      text: new Date(handoverDate).toLocaleDateString(),
    });
  }

  // HeatingSavings
  const HeatingSavings = get(props, "buildingStats.heatingSavings", null);
  if (HeatingSavings !== null) {
    info.push({
      key: "HeatingSavings",
      title: "Heating savings",
      text: HeatingSavings + " MWh/year",
    });
  }

  // LifeCycleServiceStatus
  const LifeCycleServiceStatus = get(
    props,
    "buildingStats.lifeCycleServiceStatus",
    null
  );
  if (LifeCycleServiceStatus !== null && !isExternal) {
    info.push({
      key: "LifeCycleServiceStatus",
      title: "Life cycle service status",
      text: LifeCycleServiceStatus,
    });
  }

  // ListUpdated
  const ListUpdated = get(props, "buildingStats.listUpdated", null);
  if (ListUpdated !== null) {
    info.push({
      key: "ListUpdated",
      title: "List updated",
      text: ListUpdated === true ? "Yes" : "No",
    });
  }

  // MonthlyServicePayment
  const MonthlyServicePayment = get(
    props,
    "buildingStats.monthlyServicePayment",
    null
  );
  if (MonthlyServicePayment !== null && !isExternal) {
    info.push({
      key: "MonthlyServicePayment",
      title: "Monthly service payment",
      text: MonthlyServicePayment + " €",
    });
  }

  // Normalized
  const Normalized = get(props, "buildingStats.normalized", null);
  if (Normalized !== null && !isExternal) {
    info.push({
      key: "Normalized",
      title: "Normalized",
      text: Normalized === true ? "Yes" : "No",
    });
  }

  // OneYearVarrantyInspection
  const OneYearVarrantyInspection = get(
    props,
    "buildingStats.oneYearVarrantyInspection",
    null
  );
  if (OneYearVarrantyInspection !== null && !isExternal) {
    info.push({
      key: "OneYearVarrantyInspection",
      title: "One year warranty inspection",
      text: OneYearVarrantyInspection === true ? "Yes" : "No",
    });
  }

  // PercentageOfCompletion
  const PercentageOfCompletion = get(
    props,
    "buildingStats.percentageOfCompletion",
    null
  );
  if (PercentageOfCompletion !== null && !isExternal) {
    info.push({
      key: "PercentageOfCompletion",
      title: "Completion",
      text: PercentageOfCompletion + " %",
    });
  }

  // ProjOnlineUpdated
  const ProjOnlineUpdated = get(props, "buildingStats.projOnlineUpdated", null);
  if (ProjOnlineUpdated !== null && !isExternal) {
    info.push({
      key: "ProjOnlineUpdated",
      title: "Project Online updated",
      text: new Date(ProjOnlineUpdated).toLocaleDateString(),
    });
  }

  // ProjectContractType
  const ProjectContractType = get(
    props,
    "buildingStats.projectContractType",
    null
  );
  if (ProjectContractType !== null && !isExternal) {
    info.push({
      key: "ProjectContractType",
      title: "Project contract type",
      text: ProjectContractType,
    });
  }

  // ProjectPriority
  const ProjectPriority = get(props, "buildingStats.projectPriority", null);
  if (ProjectPriority !== null && !isExternal) {
    info.push({
      key: "ProjectPriority",
      title: "Project priority",
      text: ProjectPriority,
    });
  }

  // ProjectStage
  const ProjectStage = get(props, "buildingStats.projectStage", null);
  if (ProjectStage !== null && !isExternal) {
    info.push({
      key: "ProjectStage",
      title: "Project stage",
      text: ProjectStage,
    });
  }

  // ProjectStatus
  const ProjectStatus = get(props, "buildingStats.projectStatus", null);
  if (ProjectStatus !== null && !isExternal) {
    info.push({
      key: "ProjectStatus",
      title: "Project status",
      text: ProjectStatus,
    });
  }

  // RenovationYear
  const RenovationYear = get(props, "buildingStats.renovationYear", null);
  if (RenovationYear !== null) {
    info.push({
      key: "RenovationYear",
      title: "Renovation year",
      text: RenovationYear,
    });
  }

  // StartingDateOfServiceInvoicing
  const StartingDateOfServiceInvoicing = get(
    props,
    "buildingStats.startingDateOfServiceInvoicing",
    null
  );
  if (StartingDateOfServiceInvoicing !== null && !isExternal) {
    info.push({
      key: "StartingDateOfServiceInvoicing",
      title: "Service invoicing started",
      text: new Date(StartingDateOfServiceInvoicing).toLocaleDateString(),
    });
  }

  // TechnicalScope
  const TechnicalScope = get(props, "buildingStats.technicalScope", null);
  if (TechnicalScope !== null) {
    info.push({
      key: "TechnicalScope",
      title: "Technical scope",
      text: TechnicalScope,
    });
  }

  // TwoYearVarrantyInspection
  const TwoYearVarrantyInspection = get(
    props,
    "buildingStats.twoYearVarrantyInspection",
    null
  );
  if (TwoYearVarrantyInspection !== null && !isExternal) {
    info.push({
      key: "TwoYearVarrantyInspection",
      title: "Two year warranty inspection",
      text: TwoYearVarrantyInspection === true ? "Yes" : "No",
    });
  }

  // UseDefaultWaterCons
  const UseDefaultWaterCons = get(
    props,
    "buildingStats.useDefaultWaterCons",
    null
  );
  if (UseDefaultWaterCons !== null && !isExternal) {
    info.push({
      key: "UseDefaultWaterCons",
      title: "Default water consumption used",
      text: UseDefaultWaterCons === true ? "Yes" : "No",
    });
  }

  // UseWarmWater
  const UseWarmWater = get(props, "buildingStats.useWarmWater", null);
  if (UseWarmWater !== null && !isExternal) {
    info.push({
      key: "UseWarmWater",
      title: "Warm water used",
      text: UseWarmWater === true ? "Yes" : "No",
    });
  }

  // WarmWaterPerc
  const WarmWaterPerc = get(props, "buildingStats.warmWaterPerc", null);
  if (WarmWaterPerc !== null && !isExternal) {
    info.push({
      key: "WarmWaterPerc",
      title: "Warm water",
      text: WarmWaterPerc + " %",
    });
  }

  // WarrantyDate
  const WarrantyDate = get(props, "buildingStats.warrantyDate", null);
  if (WarrantyDate !== null) {
    info.push({
      key: "WarrantyDate",
      title: "Warranty date",
      text: new Date(WarrantyDate).toLocaleDateString(),
    });
  }

  // WaterConsDefaultVal
  const WaterConsDefaultVal = get(
    props,
    "buildingStats.waterConsDefaultVal",
    null
  );
  if (WaterConsDefaultVal !== null && !isExternal) {
    info.push({
      key: "WaterConsDefaultVal",
      title: "Water consumption default value",
      text: WaterConsDefaultVal + " m³",
    });
  }

  // WaterPrice
  const WaterPrice = get(props, "buildingStats.waterPrice", null);
  if (WaterPrice !== null && !isExternal) {
    info.push({
      key: "WaterPrice",
      title: "Water price",
      text: WaterPrice + " €/m³",
    });
  }

  // WaterSavings
  const WaterSavings = get(props, "buildingStats.waterSavings", null);
  if (WaterSavings !== null) {
    info.push({
      key: "WaterSavings",
      title: "Water savings",
      text: WaterSavings + " m³/year",
    });
  }

  // Service description is the last one due to larger size
  // ServiceDescription
  const ServiceDescription = get(
    props,
    "buildingStats.serviceDescription",
    null
  );
  if (ServiceDescription !== null && !isExternal) {
    info.push({
      key: "ServiceDescription",
      title: "Service description",
      text: ServiceDescription,
    });
  }

  return (
    <Grid className={classes.root} container spacing={4}>
      {info
        ? info.map((i) => {
            if (i.key === "ServiceDescription") {
              return (
                <Grid key={i.key} item xs={12}>
                  <Info title={i.title} text={i.text} desc />
                </Grid>
              );
            }
            return (
              <Grid key={i.key} item md={4} sm={8} xs={12}>
                <Info title={i.title} text={i.text} />
              </Grid>
            );
          })
        : "No additional info"}
    </Grid>
  );
};

export default BuildingInfo;
