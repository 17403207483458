import { createMuiTheme } from "@material-ui/core/styles";

// Global themes for material ui etc

const myTheme = createMuiTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "inherit",
      },
    },
    RaLogout: {
      menuItem: {
        color: "#9590a0",
        fontSize: "1em",
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    RaAutocompleteSuggestionList: {
      suggestionsPaper: {
        maxHeight: "30vh",
      },
    },
    RaDatagrid: {
      headerCell: {
        top: 60,
      },
    },
  },
  typography: {
    fontFamily: [
      '"Segoe UI"',
      "Tahoma",
      "Geneva",
      "Verdana",
      "sans-serif",
    ].join(","),
  },
});

export default myTheme;
