import React from "react";
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";

const ValueStorageCreate = ({ BuildingId, ...props }) => {
  // Delete unnecessary props
  let createProps = Object.assign({}, props);
  delete createProps.buildingTwinId;
  delete createProps.buildingSelected;
  delete createProps.dispatch;
  return (
    <>
      <PageTitle title="Create Valuestorage Item" />
      <Create {...createProps}>
        <SimpleForm>
          <ReferenceInput
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ RegisterListsVisible: true, showBAUI: true }}
            allowEmpty
            defaultValue={BuildingId}
          >
            <AutocompleteInput optionValue="id" optionText="ProjectName" />
          </ReferenceInput>

          <TextInput source="VariableName" validate={required()} />
          <TextInput multiline source="Description" validate={required()} />
          <NumberInput source="Value" validate={required()} />
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(ValueStorageCreate);
