import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import trendMapping from "./trendMapping";

const useTrendsDataMapper = (resource, type) => {
  const buildingTwin = useSelector(
    (state) => state.buildingFilter.buildingTwin
  );
  const buildingTrends = useSelector(
    (state) => state.buildingFilter.buildingTrends
  );
  const [trendSeries, setTrendSeries] = useState(null);
  const [filteredTrends, setFilteredTrends] = useState([]);

  useEffect(() => {
    if (buildingTwin && buildingTrends) {
      const [series, trends] = trendMapping(buildingTrends, resource);
      setTrendSeries(series);
      setFilteredTrends(trends);
    }
  }, [buildingTwin, buildingTrends, resource]);
  return [trendSeries, filteredTrends];
};

export default useTrendsDataMapper;
