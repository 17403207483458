import React from "react";
import buildingsIcon from "../assets/icons/vector_buildings.svg";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    color: "#282331",
    fontSize: "1.875rem",
    fontWeight: 600,
    marginTop: "3rem"
  },
  text: {
    color: "#282331",
    opacity: "50%",
    fontStyle: "italic",
    fontSize: "1.125rem",
    fontWeight: 600
  }
}));

const EmptyData = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Hello there!</Typography>
      <Typography className={classes.text}>
        Your favourite buildings will be shown here
      </Typography>
      <img src={buildingsIcon} alt="empty buildings" />
    </div>
  );
};

export default EmptyData;
