import React from "react";
import { TopToolbar, CreateButton, ExportButton } from "react-admin";
import useUserRole from "../utils/hooks/useUserRole";
import ImportConsumptions from "./ImportConsumptions";

export const ConsumptionActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => {
  const { isAdminOrUser } = useUserRole();
  return <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    {isAdminOrUser && (
      <CreateButton basePath={basePath} />
    )}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <ImportConsumptions />
  </TopToolbar>;
}