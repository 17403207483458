import React from "react";
import CustomShowButton from "../components/CustomShowButton";
import { useSelector } from "react-redux";
import { find, get } from "lodash";

const BuildingShowButton = (props) => {
  const buildingSelected = useSelector(
    (state) => state.buildingFilter.buildingSelected
  );
  const buildings = useSelector((state) => state.buildingFilter.buildings);

  // If a building is already selected, the links should just be the basic ones without query parameters
  let link = "/dashboard";
  const bId = get(props, "record.id", null);

  if (!buildingSelected && bId) {
    let building = find(buildings, (b) => b.id === bId);
    if (building && building.id && building.ProjectName) {
      link = "/dashboard?id=" + building.id + "&name=" + building.ProjectName;
    }
  }

  return <CustomShowButton link={link} />;
};

export default BuildingShowButton;
