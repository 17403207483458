import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomLayout from "./CustomLayout";
import CustomAppBarWrapper from "./appbar/CustomAppBarWrapper";
import CustomSideBar from "./sidebar/CustomSideBar";
import CustomMenu from "./menu/CustomMenu";
import { setBuildingTwin, setBuildingData } from "../customReducers/actions";

// Component for setting some common data to redux
import GetBuildingData from "../buildingdata/GetBuildingData";
import GetBuildingTwinData from "../buildingdata/GetBuildingTwinData";
import GetOtherData from "../buildingdata/GetOtherData";
import Wrapper from "../hoc/Wrapper";
import GetAllAlerts from "../buildingdata/GetAllAlerts";

const CustomLayoutWrapper = (props) => {
  const dispatch = useDispatch();
  // Get data for a specific building
  let id = useSelector((state) => state.buildingFilter.id);
  let bTwinId = useSelector((state) => state.buildingFilter.buildingTwinId);
  let bTwin = useSelector((state) => state.buildingFilter.buildingTwin);
  const buildingSelected = useSelector(
    (state) => state.buildingFilter.buildingSelected
  );

  if (!bTwinId) {
    const lsId = sessionStorage.getItem("BuildingTwinId");
    if (lsId) {
      bTwinId = lsId;
    }
  }

  if (!id) {
    const lsId = sessionStorage.getItem("BuildingId");
    if (lsId) {
      id = lsId;
    }
  }

  id = parseInt(id);
  bTwinId = parseInt(bTwinId);

  useEffect(() => {
    if (buildingSelected && (!bTwinId || isNaN(bTwinId))) {
      dispatch(setBuildingTwin([]));
    }
    if (buildingSelected && (!id || isNaN(id))) {
      dispatch(setBuildingData([]));
    }
  }, [dispatch, buildingSelected, bTwinId, id]);

  return (
    <Wrapper>
      {id && buildingSelected && !isNaN(id) ? (
        <GetBuildingData id={id} />
      ) : (
        <GetAllAlerts />
      )}
      {id && buildingSelected && bTwinId && !isNaN(bTwinId) ? (
        <GetBuildingTwinData id={bTwinId} />
      ) : null}
      {id && buildingSelected && bTwin && bTwin !== [] ? (
        <GetOtherData id={id} bTwin={bTwin} />
      ) : null}
      <CustomLayout
        {...props}
        sidebar={CustomSideBar}
        menu={CustomMenu}
        appBar={CustomAppBarWrapper}
      />
    </Wrapper>
  );
};

export default CustomLayoutWrapper;
