import handleTemperatureClick from "./handleTemperatureClick";
import { buildingTempToChart } from "../../../utils/raDataToHighcharts";
import graphColor from "../../../utils/graphColor";
import { isNil, round } from "lodash";

const temperatureMapping = ({ temperatureData, consumptionData, type }) => {
  if (!isNil(consumptionData) && consumptionData.length > 0) {
    // Get the times of the first consumption record and the last
    let dataBegin = consumptionData[0].Time;
    dataBegin = dataBegin.slice(0, 7);
    let dataEnd = consumptionData[consumptionData.length - 1].Time;
    dataEnd = dataEnd.slice(0, 7);
    const startDate = new Date(dataBegin);
    // Get the newest temperatures from the entire year
    const endDate = new Date(new Date(dataEnd).getFullYear(), 11, 31);
    // Get monthly averages of every year in the received record if temperature data exists
    let tempSeries = [];
    if (temperatureData && temperatureData.length > 0) {
      let temperatureArr = buildingTempToChart(
        temperatureData,
        startDate,
        endDate
      );

      tempSeries.push({
        name: "Outside Temperatures",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        type: "line",
        showInLegend: true,
        visible: false,
        id: "group",
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(event.target);
            return false;
          },
        },
      });

      for (let i = 0; i < temperatureArr.length; i++) {
        if (temperatureArr[i] && temperatureArr[i].length > 0) {
          const dataArr = [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ];
          temperatureArr[i].forEach((t) => {
            dataArr[parseInt(t.month) - 1] = round(t.val, 2);
          });
          // Color (index) is offset by one to make the target series the first color and temperature series are the next ones
          tempSeries.push({
            name: "Temperature " + temperatureArr[i][0].year,
            data: dataArr,
            type: "line",
            zIndex: 9,
            yAxis: 1,
            visible: false,
            showInLegend: false,
            color: graphColor({
              index: i,
              seriesLength: temperatureArr.length,
              lineSeries: true,
              offset: 1,
              type,
            }),
          });
        }
      }
    }
    return tempSeries;
  } else return null;
};

export default temperatureMapping;
