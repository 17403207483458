import React, { Component } from "react";
import { restClient } from "../App";
import sortArrayObjects from "../utils/sortArrayObjects";
import PropTypes from "prop-types";
import { alertCount } from "./BuildingsWithAlerts";
import compose from "recompose/compose";
import { mapper } from "../customReducers/connectFunctions";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Typography,
  Link as MuiLink,
  Button,
  SvgIcon,
  CardContent,
  Grid,
  CardActions,
} from "@material-ui/core";

import { filter, get, groupBy, isUndefined, some, sum } from "lodash";

import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";
import { GET_LIST, GET_ONE } from "react-admin";
import TwinButton from "../components/layout/TwinButton";
import Spacer from "../components/layout/Spacer";
import Spinner from "../components/layout/Spinner";
import FavoriteReminders from "./FavoriteReminders";
import twinForExternal from "../utils/twinForExternal";
import CardWithTitle from "../components/layout/CardWithTitle";
import NoFavorites from "./NoFavorites";

const styles = (theme) => ({
  row: {
    maxWidth: "250px",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    fontSize: "1rem",
    padding: "4px 5px 4px 0",
    textDecoration: "none",
  },
  favCell: {
    width: "10px",
    fontSize: "1rem",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
  },
  alertCell: {
    width: "150px",
    fontSize: "1rem",
    padding: "4px 10px 4px 24px",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  alertHeader: {
    fontSize: "1.1rem",
    fontWeight: 300,
    padding: "4px 10px 4px 42px",
    textDecoration: "none",
    textAlign: "center",
  },
  buildingHeader: {
    fontSize: "1.1rem",
    fontWeight: 300,
    padding: "4px 5px 4px 2rem",
    textDecoration: "none",
  },
  twinHeader: {
    fontSize: "1.1rem",
    fontWeight: 300,
    padding: "4px 5px 4px 24px",
    textDecoration: "none",
  },
  energyHeader: {
    padding: "4px 5px 4px 5px",
    fontSize: "1.1rem",
    fontWeight: 300,
    textDecoration: "none",
    textAlign: "center",
  },
  onlineHeader: {
    fontSize: "1.1rem",
    fontWeight: 300,
    textDecoration: "none",
    textAlign: "center",
  },
  favHeader: {
    fontSize: "1.1rem",
    fontWeight: 300,
    textDecoration: "none",
    textAlign: "center",
  },
  twinCell: {
    fontSize: "1rem",
    padding: "4px 10px 4px 24px",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    textDecoration: "none",
  },
  noTwins: {
    fontSize: "0.875rem",
    fontStyle: "italic",
    opacity: "30%",
  },
  energyCell: {
    padding: "4px 5px 4px 5px",
    fontSize: "1rem",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    textDecoration: "none",
    textAlign: "center",
  },
  emptyCell: {
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    fontSize: "1rem",
    padding: "4px 10px 4px 24px",
    textDecoration: "none",
  },
  twinStatus: {
    display: "flex",
    alignItems: "center",
  },
  twinName: {
    marginRight: "5px",
  },
  buildingName: {
    fontSize: "1.1rem",
    fontWeight: 600,
    marginLeft: "2em",
  },
  energyButton: {
    minWidth: 140,
    width: "90%",
    maxWidth: "30rem",
    height: "1.75rem",
    boxSizing: "border-box",
    padding: 0,
    boxShadow: "0px 0px 2px #000000B3",
    borderRadius: "6px",
    margin: "8px 0",
  },
  energyLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  heatIcon: {
    opacity: "60%",
    fontSize: "0.75em",
  },
  electricityIcon: {
    opacity: "60%",
    fontSize: "0.75em",
  },
});

class FavoriteBuildings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allTwins: [],
      favoriteData: [],
      alerts: [],
      allTwinsGroup: [],
      zoneConditions: [],
      allEnergy: [],
      sort: "asc",
      sortBy: "name",
    };
  }

  async componentDidMount() {
    const { showNotification } = this.props;
    let allTwins = [];
    let allTwinsGroup = [];
    let twinGroups = [];
    let allEnergy = [];
    // Get the twin data for every favorite
    // let buildingTwins = [];
    const promises = this.props.favorites.map(async (favorite) => {
      try {
        const ret = await restClient(GET_LIST, "blocks", {
          filter: {
            BuildingId: favorite.SourceId,
          },
          sort: {},
          pagination: {},
        });

        // Add building twins to array and also the BuildingId
        let oneBuildingTwins = { id: null, twins: [] };
        if (ret.data.length > 0) {
          // Fetch building twin from reply and push it to a list
          const bTwin = filter(ret.data, (t) => t.BlockType === "BUILDING");
          const ret2 = await restClient(GET_ONE, "blocks", {
            id: bTwin[0].id,
          });
          if (ret2 && ret2.data && ret2.data.Energy) {
            let energyData = ret2.data.Energy;
            energyData.id = ret2.data.BuildingId;
            allEnergy.push(energyData);
          }

          oneBuildingTwins = { id: ret.data[0].BuildingId, twins: ret.data };
          allTwins.push(oneBuildingTwins);
          // Building ID is always on the first node
          twinGroups.push(ret.data[0].BuildingId);
          twinGroups.push(groupBy(ret.data, (t) => t.BlockType));
          allTwinsGroup.push(twinGroups);
          twinGroups = [];
        }
      } catch (error) {
        showNotification(error.message, "warning");
      }
    });

    Promise.all(promises).then(() => {
      const zoneConditions = null;
      this.setState({
        allTwins: allTwins,
        allTwinsGroup: allTwinsGroup,
        zoneConditions: zoneConditions,
        allEnergy: allEnergy,
        loading: false,
      });
    });
  }

  // Map favoritedata to display
  mapFavoriteData(allTwins) {
    let favoritesCopy = [...this.props.favorites];
    let unSortedFavorites = [];
    let favoriteObject = {
      name: "",
      id: null,
      SourceId: null,
      bTwinId: null,
      twins: [],
      alertData: null,
      twinGroups: [],
      energy: {},
      threeMonthEnergy: [],
      alerts: null,
    };
    // Sort all favorites alphabetically and assign the necessary data
    for (let i = 0; i < favoritesCopy.length; i++) {
      //Find the twins for this building
      let buildingTwins = [];
      let bTwinId = null;
      for (let ind = 0; ind < allTwins.length; ind++) {
        if (allTwins[ind].id === favoritesCopy[i].SourceId) {
          // Check all twins of this group and check if a building twin exists
          for (let i2 = 0; i2 < allTwins[ind].twins.length; i2++) {
            buildingTwins.push(allTwins[ind].twins[i2]);
            if (allTwins[ind].twins[i2].BlockType === "BUILDING") {
              bTwinId = allTwins[ind].twins[i2].id;
            }
          }
        }
      }
      let twinGroups = [];
      for (let i2 = 0; i2 < this.state.allTwinsGroup.length; i2++) {
        if (this.state.allTwinsGroup[i2][0] === favoritesCopy[i].SourceId) {
          twinGroups = this.state.allTwinsGroup[i2][1];
        }
      }
      // Find buildings energydata
      let energyData = {};
      let threeMonthData = [];
      this.state.allEnergy.forEach((e) => {
        if (e.id === favoritesCopy[i].SourceId) {
          energyData = e;
          threeMonthData = filter(energyData.EnergySavings, function (s) {
            return s.Period === "3month";
          });
        }
      });

      const alertData = this.mapFavoriteAlerts(favoritesCopy[i].SourceId);

      favoriteObject = {
        name: this.findBuildingName(favoritesCopy[i].SourceId),
        Id: favoritesCopy[i].Id,
        SourceId: favoritesCopy[i].SourceId,
        bTwinId: bTwinId,
        twins: buildingTwins,
        alertData: alertData,
        twinGroups: twinGroups,
        energy: energyData,
        threeMonthEnergy: threeMonthData,
        alerts: alertData.activeUnchecked,
      };
      unSortedFavorites.push(favoriteObject);
    }

    const sortedFavorites = unSortedFavorites.sort(
      sortArrayObjects(this.state.sortBy, this.state.sort)
    );
    return sortedFavorites;
  }

  mapFavoriteAlerts(id) {
    const buildingAlerts = this.props.alerts.filter(
      (alert) => alert.BuildingId === id
    );
    let count = alertCount(buildingAlerts);
    return count;
  }

  findBuildingName = (sourceId) => {
    let retVal = "Unknown Building";
    const building = this.props.buildings.find((b) => b.id === sourceId);
    if (building !== undefined) {
      retVal = building.ProjectName;
    }
    return retVal;
  };

  getTwinStatus(twins = []) {
    let alertCounts = twins.map((t) => {
      return this.getAlertCount(t);
    });

    return this.getStatusIcon(sum(alertCounts));
  }

  getAlertCount(twin) {
    let twinAlerts = null;
    if (this.props.alerts.length > 0) {
      twinAlerts = filter(this.props.alerts, function (a) {
        return (
          a.BuildingId === twin.BuildingId &&
          a.BlockId === twin.id &&
          !a.AlertEndTime &&
          !a.Checked
        );
      });
    }
    let alertCount = 0;
    if (twinAlerts) {
      alertCount = twinAlerts.length;
    }
    return alertCount;
  }

  getStatusIcon(alertCount) {
    if (isUndefined(alertCount)) {
      return null;
    }
    if (alertCount === 0) {
      // return <SentimentVerySatisfied style={{ color: "#24b132" }} />;
      return false;
    } else {
      // return <SentimentVeryDissatisfied style={{ color: "red" }} />;
      return true;
    }
  }

  getUniqueTwinCount(fav) {
    // Use Object.entries() to tun the object into an array, where the first index is the name and the second is the actual array
    const twinArray = Object.entries(fav.twinGroups);
    let curCount = 0;
    for (let i = 0; i < twinArray.length; i++) {
      if (twinArray[i][0] !== "BUILDING" && twinArray[i][1].length > 0) {
        curCount++;
      }
    }
    return curCount;
  }

  getEmptyCells(twinDiff, favorite) {
    let retVal = [];
    for (let i = 0; i < twinDiff; i++) {
      retVal.push(
        <TableCell
          key={"empty" + favorite.Id + i}
          className={this.props.classes.emptyCell}
        ></TableCell>
      );
    }
    return retVal;
  }

  // Return the cell for the required twin
  getTwinCell(favorite, twinName) {
    return some(favorite.twins, (t) =>
      this.props.isExternal
        ? twinForExternal(t) && t.BlockType === twinName
        : t.BlockType === twinName
    ) ? (
      <TwinButton
        style={{ margin: "0 8px 0 0" }}
        onClick={(e) =>
          this.rowClickedHandler(
            e,
            !this.props.isExternal
              ? '/blocks?filter=%7B"BlockType"%3A"' +
                  twinName +
                  '"%7D&order=DESC&page=1&perPage=10&sort=id'
              : "/dashboard",
            favorite.SourceId,
            favorite.bTwinId
          )
        }
        alert={
          !this.props.isExternal &&
          this.getTwinStatus(get(favorite.twinGroups, twinName, []))
        }
        twinType={twinName}
      />
    ) : null;
  }

  getOnlineStatus(favorite) {
    let twinAlerts = null;
    let retVal = <CloudDoneOutlinedIcon style={{ color: "#212121" }} />;
    if (this.props.alerts.length > 0) {
      twinAlerts = filter(this.props.alerts, function (a) {
        return (
          a.BuildingId === favorite.SourceId &&
          a.AlertName === "BUILDING_DATA_SOURCE_RAU" &&
          !a.AlertEndTime &&
          !a.Checked
        );
      });
      if (twinAlerts.length > 0) {
        retVal = <CloudOffIcon style={{ color: "red" }} />;
      }
    }
    return retVal;
  }

  getEnergyStatus(fav, type, bPath, sourceId, bTwinId) {
    let color = "#282331";
    let retVal = "";

    const realized = filter(fav.threeMonthEnergy, function (o) {
      return (
        o.Type === type &&
        o.Unit === "percentage" &&
        o.Description === "realized"
      );
    });
    const target = filter(fav.threeMonthEnergy, function (o) {
      return (
        o.Type === type && o.Unit === "percentage" && o.Description === "target"
      );
    });
    if (realized[0] && target[0]) {
      const real = realized[0];
      const tar = target[0];
      if (real.Value >= tar.Value) {
        color = "#282331";
      } else if (real.Value < tar.Value && real.Value !== null) {
        color = "#D62B1A";
      }
      const r =
        real && real.Value ? real.Value.toFixed(0) + " % / " : "-- % / ";
      const t = tar && tar.Value ? tar.Value.toFixed(0) + " %" : "-- %";
      retVal = r + t;
    } else retVal = "-- / -- %";

    const heatIcon = (
      <SvgIcon
        className={this.props.classes.heatIcon}
        style={{ fontSize: "0.75rem", fill: color }}
        viewBox="0 0 16.378 17"
      >
        <g
          id="Group_255"
          data-name="Group 255"
          transform="translate(-279.342 -401.6)"
        >
          <g
            id="Group_254"
            data-name="Group 254"
            transform="translate(279.342 401.6)"
          >
            <path
              id="Path_165"
              data-name="Path 165"
              d="M294.87,418.6a6.806,6.806,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574a6.805,6.805,0,0,1,2.752,4c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3Z"
              transform="translate(-286.51 -401.6)"
            />
            <path
              id="Path_166"
              data-name="Path 166"
              d="M310.036,405.6c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3l-1.5,1.574a6.805,6.805,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574A6.806,6.806,0,0,1,310.036,405.6Z"
              transform="translate(-293.735 -401.6)"
            />
            <path
              id="Path_167"
              data-name="Path 167"
              d="M284.536,405.6c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3l-1.5,1.574a6.805,6.805,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574A6.806,6.806,0,0,1,284.536,405.6Z"
              transform="translate(-279.342 -401.6)"
            />
          </g>
        </g>
      </SvgIcon>
    );

    const electricityIcon = (
      <SvgIcon
        className={this.props.classes.electricityIcon}
        style={{ fontSize: "0.75rem", fill: color }}
        viewBox="0 0 13.004 19.517"
      >
        <path
          id="icon_electricity"
          d="M34.147,15.205H29.294l.462-6.725a.693.693,0,0,0-1.271-.416L21.944,18.672a.693.693,0,0,0,.6,1.063h4.946l-.508,6.818a.693.693,0,0,0,.485.716h.208a.693.693,0,0,0,.6-.324l6.471-10.7a.693.693,0,0,0-.6-1.04Zm-5.57,8.551.347-4.669a.693.693,0,0,0-.693-.74H23.793l4.391-7.165-.324,4.669a.693.693,0,0,0,.693.74h4.368Z"
          transform="translate(-21.837 -7.753)"
        />
      </SvgIcon>
    );

    let startIcon = heatIcon;
    if (type === "heating") {
      startIcon = heatIcon;
    } else if (type === "electricity") {
      startIcon = electricityIcon;
    }
    return (
      <Button
        onClick={(e) => this.rowClickedHandler(e, bPath, sourceId, bTwinId)}
        variant="outlined"
        classes={{
          root: this.props.classes.energyButton,
          label: this.props.classes.energyLabel,
        }}
        startIcon={startIcon}
        title={type}
      >
        <span style={{ marginLeft: 8, color: color }}>{retVal}</span>
      </Button>
    );
  }

  onRemoveHandler = (id) => {
    const params = {
      id: id,
    };
    restClient("DELETE", "user/favorites", params).then(
      this.props.favoriteDeleted
    );
  };

  rowClickedHandler = (event, redirectPath, buildingId, bTwinId) => {
    if (event) {
      event.preventDefault();
    }
    // Search for the building from the buildings list
    let selectedBuilding = null;
    for (let i = 0; i < this.props.buildings.length; i++) {
      if (this.props.buildings[i].id === buildingId) {
        selectedBuilding = this.props.buildings[i];
      }
    }
    if (selectedBuilding) {
      this.props.changebuilding(selectedBuilding, redirectPath, bTwinId);
    }
  };

  render() {
    let retVal = (
      <CardWithTitle title="My Buildings" spacerColor="#2E2247">
        <Spinner style={{ height: "10rem" }} />
      </CardWithTitle>
    );
    const useMobile = this.props.useMobile;

    if (useMobile && this.state.loading) {
      retVal = <Spinner style={{ height: "10rem" }} />;
    }

    if (!this.state.loading) {
      let favoriteData = [];
      if (this.state.allTwins.length > 0) {
        favoriteData = this.mapFavoriteData(this.state.allTwins);
      }

      retVal = (
        <CardWithTitle title="My Buildings" spacerColor="#2E2247">
          {this.props.favorites && this.props.favorites.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={this.props.classes.buildingHeader}>
                    <TableSortLabel
                      active={this.state.sortBy === "name"}
                      direction={
                        this.state.sortBy === "name" ? this.state.sort : "asc"
                      }
                      onClick={() => {
                        this.setState((prevState) => {
                          return {
                            sortBy: "name",
                            sort: prevState.sort === "asc" ? "desc" : "asc",
                          };
                        });
                      }}
                    >
                      Building
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={this.props.classes.twinHeader}>
                    Digital twins
                  </TableCell>
                  {!this.props.isExternal && (
                    <TableCell className={this.props.classes.energyHeader}>
                      Reminders
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    className={this.props.classes.energyHeader}
                  >
                    Energy (3 months) <div>(Actual / Target)</div>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={this.props.classes.onlineHeader}
                  >
                    Online
                  </TableCell>
                  {!this.props.isExternal && (
                    <TableCell
                      align="center"
                      className={this.props.classes.alertHeader}
                    >
                      <TableSortLabel
                        active={this.state.sortBy === "alerts"}
                        direction={
                          this.state.sortBy === "alerts"
                            ? this.state.sort
                            : "asc"
                        }
                        onClick={() => {
                          this.setState((prevState) => {
                            return {
                              sortBy: "alerts",
                              sort: prevState.sort === "asc" ? "desc" : "asc",
                            };
                          });
                        }}
                      >
                        Alerts
                      </TableSortLabel>
                    </TableCell>
                  )}
                  {!this.props.isExternal && (
                    <TableCell
                      align="center"
                      className={this.props.classes.favHeader}
                    ></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {favoriteData.map((favorite) => {
                  // const bPath = favorite.bTwinId
                  //   ? "/blocks/" + favorite.bTwinId + "/show"
                  //   : "/buildings/" + favorite.SourceId + "/show";
                  const aPath = "/alerts";
                  const dPath = "/dashboard";
                  const rPath = "/reminders";
                  // Check if building has any twins (excluding building twins)
                  // If external user, only show specific twins
                  const filteredTwins = filter(favorite.twins, (t) => {
                    return this.props.isExternal
                      ? twinForExternal(t)
                      : t.BlockType !== "BUILDING";
                  });
                  const hasTwins = filteredTwins.length > 0;
                  return (
                    <TableRow key={favorite.Id}>
                      <TableCell className={this.props.classes.row}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {favorite.alertData.activeUnchecked > 0 &&
                            !this.props.isExternal && (
                              <Spacer
                                style={{ display: "inline-block" }}
                                height={"23px"}
                                color="#D62B1A"
                              />
                            )}
                          <Typography
                            className={this.props.classes.buildingName}
                          >
                            <MuiLink
                              href="#"
                              onClick={(e) => {
                                this.rowClickedHandler(
                                  e,
                                  dPath,
                                  favorite.SourceId,
                                  favorite.bTwinId
                                );
                              }}
                              color="inherit"
                            >
                              {favorite.name}
                            </MuiLink>
                          </Typography>
                        </div>
                      </TableCell>
                      {hasTwins ? (
                        <TableCell className={this.props.classes.twinCell}>
                          {this.getTwinCell(favorite, "AHU")}
                          {this.getTwinCell(favorite, "HP")}
                          {this.getTwinCell(favorite, "SOLAR")}
                          {this.getTwinCell(favorite, "ZONE")}
                          {!this.props.isExternal &&
                            this.getTwinCell(favorite, "HX")}
                          {this.getTwinCell(favorite, "Meter")}
                        </TableCell>
                      ) : (
                        <TableCell className={this.props.classes.noTwins}>
                          No digital twins
                        </TableCell>
                      )}
                      {!this.props.isExternal && (
                        <TableCell
                          align="center"
                          className={this.props.classes.favCell}
                        >
                          <MuiLink
                            ref="#"
                            color="inherit"
                            onClick={(e) =>
                              this.rowClickedHandler(
                                e,
                                rPath,
                                favorite.SourceId,
                                favorite.bTwinId
                              )
                            }
                          >
                            <FavoriteReminders id={favorite.SourceId} />
                          </MuiLink>
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        className={this.props.classes.energyCell}
                      >
                        <div>
                          {this.getEnergyStatus(
                            favorite,
                            "heating",
                            dPath,
                            favorite.SourceId,
                            favorite.bTwinId
                          )}
                        </div>
                        <div>
                          {this.getEnergyStatus(
                            favorite,
                            "electricity",
                            dPath,
                            favorite.SourceId,
                            favorite.bTwinId
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={this.props.classes.favCell}
                      >
                        <IconButton
                          onClick={(e) =>
                            this.rowClickedHandler(
                              e,
                              !this.props.isExternal ? aPath : dPath,
                              favorite.SourceId,
                              favorite.bTwinId
                            )
                          }
                        >
                          {this.getOnlineStatus(favorite)}
                        </IconButton>
                      </TableCell>
                      {!this.props.isExternal && (
                        <TableCell
                          align="center"
                          className={this.props.classes.alertCell}
                        >
                          <MuiLink
                            ref="#"
                            color="inherit"
                            onClick={(e) =>
                              this.rowClickedHandler(
                                e,
                                aPath,
                                favorite.SourceId,
                                favorite.bTwinId
                              )
                            }
                          >
                            {/* <AlertIndicator
                        noIcon
                        activeCount={favorite.alertData.active}
                        uncheckedCount={favorite.alertData.unchecked}
                      /> */}
                            <Typography
                              style={{
                                fontWeight: 600,
                                color:
                                  favorite.alertData.activeUnchecked > 0
                                    ? "#D62B1A"
                                    : "#282331",
                              }}
                            >
                              {favorite.alertData.activeUnchecked}
                            </Typography>
                            {/* <Typography>
                          active & <br /> unchecked
                        </Typography> */}
                          </MuiLink>
                        </TableCell>
                      )}
                      {!this.props.isExternal && (
                        <TableCell
                          align="center"
                          className={this.props.classes.favCell}
                        >
                          <IconButton
                            onClick={() => this.onRemoveHandler(favorite.Id)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <NoFavorites />
          )}
        </CardWithTitle>
      );

      // Mobile version
      if (useMobile) {
        const aPath = "/alerts";
        const dPath = "/dashboard";
        retVal =
          this.props.favorites && this.props.favorites.length > 0 ? (
            favoriteData.map((f) => {
              // Check if building has any twins (excluding building twins)
              // If external user, only show specific twins
              const filteredTwins = filter(f.twins, (t) => {
                return this.props.isExternal
                  ? twinForExternal(t)
                  : t.BlockType !== "BUILDING";
              });
              const hasTwins = filteredTwins.length > 0;
              return (
                <CardWithTitle
                  key={f.Id}
                  title={
                    <MuiLink
                      href="#"
                      onClick={(e) => {
                        this.rowClickedHandler(e, dPath, f.SourceId, f.bTwinId);
                      }}
                      color="inherit"
                    >
                      {f.name}
                    </MuiLink>
                  }
                  spacerColor={
                    f.alertData.activeUnchecked > 0 && !this.props.isExternal
                      ? "#D62B1A"
                      : "#282331"
                  }
                  action={
                    !this.props.isExternal && (
                      <IconButton onClick={() => this.onRemoveHandler(f.Id)}>
                        <ClearIcon />
                      </IconButton>
                    )
                  }
                  style={{ marginBottom: "3rem" }}
                >
                  <CardContent>
                    {hasTwins && (
                      <>
                        <Typography
                          variant="body1"
                          align="center"
                          className={this.props.classes.energyHeader}
                        >
                          Digital twins
                        </Typography>
                        <Grid container alignItems="center" justify="center">
                          {!this.props.isExternal && this.getTwinCell(f, "AHU")}
                          {!this.props.isExternal && this.getTwinCell(f, "HP")}
                          {this.getTwinCell(f, "SOLAR")}
                          {this.getTwinCell(f, "ZONE")}
                          {!this.props.isExternal && this.getTwinCell(f, "HX")}
                          {this.getTwinCell(f, "Meter")}
                        </Grid>
                      </>
                    )}
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 12,
                        marginLeft: -16,
                        width: "calc(100% + 32px)",
                        borderBottom: "1px solid rgba(164,175,183,0.5)",
                      }}
                    />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="column"
                    >
                      <Typography
                        variant="body1"
                        align="center"
                        className={this.props.classes.energyHeader}
                      >
                        Energy, 3 months (Actual / Target)
                      </Typography>
                      {this.getEnergyStatus(
                        f,
                        "heating",
                        dPath,
                        f.SourceId,
                        f.bTwinId
                      )}
                      {this.getEnergyStatus(
                        f,
                        "electricity",
                        dPath,
                        f.SourceId,
                        f.bTwinId
                      )}
                    </Grid>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 12,
                        marginLeft: -16,
                        width: "calc(100% + 32px)",
                        borderBottom: "1px solid rgba(164,175,183,0.5)",
                      }}
                    />
                  </CardContent>
                  <CardActions>
                    <Grid container wrap="nowrap" spacing={2}>
                      {!this.props.isExternal && (
                        <Grid
                          container
                          item
                          direction="column"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              variant="caption"
                              className={this.props.classes.energyHeader}
                            >
                              Reminders
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingTop: 12 }}>
                            <FavoriteReminders id={f.SourceId} />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            variant="caption"
                            className={this.props.classes.energyHeader}
                          >
                            Online
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={(e) =>
                              this.rowClickedHandler(
                                e,
                                !this.props.isExternal ? aPath : dPath,
                                f.SourceId,
                                f.bTwinId
                              )
                            }
                          >
                            {this.getOnlineStatus(f)}
                          </IconButton>
                        </Grid>
                      </Grid>
                      {!this.props.isExternal && (
                        <Grid
                          container
                          item
                          direction="column"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              variant="caption"
                              className={this.props.classes.energyHeader}
                            >
                              Alerts
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingTop: 12 }}>
                            <Typography
                              style={{
                                fontWeight: 600,
                                color:
                                  f.alertData.activeUnchecked > 0
                                    ? "#D62B1A"
                                    : "#282331",
                              }}
                            >
                              {f.alertData.activeUnchecked}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </CardActions>
                </CardWithTitle>
              );
            })
          ) : (
            <CardContent>
              <NoFavorites />
            </CardContent>
          );
      }
    }
    return retVal;
  }
}

FavoriteBuildings.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(mapper, withStyles(styles));

export default enhance(FavoriteBuildings);
