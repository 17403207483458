import React, { useState } from "react";
import { ImageInput, ImageField, useNotify } from "react-admin";
import { useFormState, useForm } from "react-final-form";
import { Button, makeStyles } from "@material-ui/core";
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles({
  buttonRoot: {
    backgroundColor: "#3f51b5",
    marginTop: 20,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#303f9f",
    },
  },
});

const ZoneUpload = () => {
  const { instance: msalInstance } = useMsal();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const formState = useFormState().values;
  const form = useForm();

  let id = null;
  if (formState && formState.id) {
    id = formState.id;
  }

  const handleReject = (newFiles, rejectedFiles, event) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      if (rejectedFiles[0].type !== "image/png") {
        notify("File has to be a PNG-file", "warning");
      } else if (rejectedFiles[0].size > 5000000) {
        notify("Maximum file size is 5MB", "warning");
      }
    }
  };

  async function handleSave() {
    setLoading(true);
    const token = await msalInstance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
    })
      .catch((error) => {
        msalInstance.loginRedirect();
      });
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token.accessToken);

    const formData = new FormData();
    formData.append(
      "formFile",
      formState.formFile.rawFile,
      formState.formFile.title
    );

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formData,
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/blocks/${id}/uploadImage`,
      requestOptions
    )
      .then(() => {
        form.change("formFile", null);
        setLoading(false);
        notify("Zone layout uploaded");
      })
      .catch((error) => {
        setLoading(false);
        notify(error.message, "warning");
      });
  }

  if (!id) {
    return <p>Unknown zone!</p>;
  }

  let noImage = true;
  if (formState && formState.formFile && formState.formFile.rawFile) {
    noImage = false;
  }

  return (
    <div>
      <ImageInput
        options={{
          onDrop: handleReject,
        }}
        source="formFile"
        label="Zone layout image"
        accept=".png"
        maxSize={5000000}
        labelSingle="Drop a picture to upload, or click to select it. Picture has to be in PNG format and less than 5MB in size."
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <Button
        variant="contained"
        classes={{ root: classes.buttonRoot }}
        onClick={handleSave}
        disabled={loading || noImage}
      >
        UPLOAD
      </Button>
    </div>
  );
};

export default ZoneUpload;
