import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllAlerts,
  setAllActiveAlertCount,
} from "../customReducers/actions";
import { alertCount } from "../home/BuildingsWithAlerts";

function useAllAlerts() {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [viewVersion, setViewVersion] = useState(null);
  const curViewVersion = useSelector((state) => state.admin.ui.viewVersion);

  // Fetch new data when refresh button clicked/new data is put)
  useEffect(() => {
    if (viewVersion !== curViewVersion && curViewVersion !== 1) {
      setViewVersion(curViewVersion);

      dataProvider
        .getList("alerts", {
          filter: { active: true, check: false },
          sort: { field: "AlertId", order: "DESC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.length > 0) {
              const aCount = alertCount(data);
              dispatch(setAllAlerts(data));
              dispatch(setAllActiveAlertCount(aCount.activeUnchecked));
            } else {
              dispatch(setAllAlerts([]));
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        });
    }
  }, [dispatch, notify, dataProvider, curViewVersion, viewVersion]);

  return null;
}

export default useAllAlerts;
