import { isNil } from "lodash";
const setVisibleYears = (_data) => {
  let data = [];
  data = [..._data];
  // Only set the last three years visible by default
  if (data && data.length > 0) {
    let newestYear = 0;
    data.forEach((d) => {
      if (!isNil(d)) {
        if (Number.isInteger(d.name)) {
          if (d.name >= newestYear) {
            newestYear = d.name;
          }
        } else if (Number.isInteger(d.stack)) {
          if (d.stack >= newestYear) {
            newestYear = d.stack;
          }
        }
      }
    });

    const oldestYear = newestYear - 2;

    return data.map((d) => {
      let showYear = false;
      if (Number.isInteger(d.name)) {
        if (d.name >= oldestYear) {
          showYear = true;
        }
      } else if (Number.isInteger(d.stack)) {
        if (d.stack >= oldestYear) {
          showYear = true;
        }
      } else {
        return d;
      }
      return { ...d, visible: showYear };
    });
  } else return data;
};

export default setVisibleYears;
