import React from "react";
import { makeStyles } from "@material-ui/core";

import CardWithTitle from "../components/layout/CardWithTitle";
import TwinInputStatus from "./TwinInputStatus";
import solarBg from "../assets/images/solarBG.png";
import { find } from "lodash";

const useStyles = makeStyles({
  root: {
    overflowX: "auto",
    textAlign: "left",
  },
  container: {
    position: "relative",
    display: "inline-block",
    marginLeft: 75,
  },
  image: {
    zIndex: 1,
    height: 400,
  },
  totalPower: {
    position: "absolute",
    textAlign: "center",
    top: 85,
    left: 400,
  },
  area: {
    position: "absolute",
    textAlign: "center",
    top: 190,
    left: 400,
  },
  efficiency: {
    position: "absolute",
    textAlign: "center",
    top: 285,
    left: 400,
  },
});

const SolarMap = (props) => {
  const classes = useStyles();

  const initData = {
    data: null,
    unit: "",
    timestamp: "",
  };

  const solarImage = (
    <img className={classes.image} alt="solar" src={solarBg}></img>
  );

  const totalPowerData = find(props.data, (d) => d.title === "totalPower")
    ? find(props.data, (d) => d.title === "totalPower")
    : initData;

  let totalPower = (
    <div className={classes.totalPower}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Solar power"}
        data={totalPowerData.data}
        unit={totalPowerData.unit}
        timestamp={totalPowerData.timestamp}
      />
    </div>
  );

  const areaData = find(props.data, (d) => d.title === "area")
    ? find(props.data, (d) => d.title === "area")
    : initData;

  let area = (
    <div className={classes.area}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Panel area"}
        data={areaData.data}
        unit={areaData.unit}
        timestamp={areaData.timestamp}
        noTooltip
      />
    </div>
  );

  const efficiencyData = find(props.data, (d) => d.title === "efficiency")
    ? find(props.data, (d) => d.title === "efficiency")
    : initData;

  let efficiency = (
    <div className={classes.efficiency}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Panel efficiency"}
        data={efficiencyData.data}
        unit={efficiencyData.unit}
        timestamp={efficiencyData.timestamp}
      />
    </div>
  );

  return (
    <CardWithTitle title={"Input status"} style={{ height: "100%" }}>
      <div className={classes.root}>
        <div className={classes.container}>
          {efficiency}
          {area}
          {totalPower}
          {solarImage}
        </div>
      </div>
    </CardWithTitle>
  );
};

export default SolarMap;
