import React, { useState, useEffect, FunctionComponent } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useSelector } from "react-redux";
import { get, first, last } from "lodash";
import SolarMap from "./SolarMap";
import { IRootState } from '../types/root-state';
import { BuildingBlock, ValueStorage } from '../types/leasegreen-core-api-exports';
import { toBuildingTime } from '../utils/twinUtils';

const SolarTwinInfo: FunctionComponent<BuildingBlock & { efficiencyData: any[] }> = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const buildingData = useSelector<IRootState>(
    (state) => state.buildingFilter.buildingData
  );
  const [totalPower, setTotalPower] = useState<ValueStorage>({});
  const [area, setArea] = useState<ValueStorage>({});
  const efficiency = last(props.efficiencyData);

  useEffect(() => {
    dataProvider
      .getList<ValueStorage & { id: number }>("valuestorage", {
        filter: {
          BuildingId: props.BuildingId,
          VariableNames: [props.BlockName + "_TOTAL_SOLAR_POWER"],
          DataType: "FUNCTION",
          ValueAge: 4,
        },
        sort: { field: "UpdateTime", order: "DESC" },
        pagination: {} as any
      })
      .then(({ data }) => {
        if (data && data.length > 0) {
          setTotalPower(first(data));
        }
      })
      .catch((error: Error) => {
        notify(error.message, "warning");
      });

    // Panel area
    if (props && props.Solar && props.Solar.PanelArea) {
      setArea({ AggregatedValue: props.Solar.PanelArea, UpdateTime: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, props.BuildingId, notify, props.BlockName]);

  const twinData = [];

  if (totalPower) {
    twinData.push({
      title: "totalPower",
      timestamp: toBuildingTime(
        totalPower.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data:
        totalPower.AggregatedValue || totalPower.AggregatedValue === 0
          ? totalPower.AggregatedValue.toFixed(1)
          : null,
      unit: "kW",
    });
  }
  if (area) {
    twinData.push({
      title: "area",
      timestamp: "",
      data: area.AggregatedValue || area.AggregatedValue === 0 ? area.AggregatedValue.toFixed(1) : null,
      unit: "m²",
    });
  }
  if (efficiency) {
    twinData.push({
      title: "efficiency",
      timestamp: toBuildingTime(
        efficiency.Date,
        get(buildingData, "timeZoneIANA", null)
      ),
      data:
        efficiency.Value || efficiency.Value === 0
          ? efficiency.Value.toFixed(2)
          : null,
      unit: "%",
    });
  }

  return <SolarMap data={twinData} />;
};

export default SolarTwinInfo;
