import React, { Component } from "react";
import { GET_LIST, Title, GET_ONE, Loading } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { stateMapper } from "../customReducers/connectFunctions";
import Card from "@material-ui/core/Card";
import { raConsumptionToHighchartsData } from "../utils/raDataToHighcharts";
import { setConsumptionUnitText } from "../utils/unitTextParsers";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import { buildingTempToChart } from "../utils/raDataToHighcharts";
import { has } from "lodash";
import graphColor from "../utils/graphColor";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

class ConsumptionGraph extends Component {
  constructor(props) {
    super(props);
    this.consRef = React.createRef();
    this.normRef = React.createRef();
    this.state = {
      data: [],
      BuildingId: this.props.BuildingId,
      projectname: this.props.projectname,
      buildingTwinId: this.props.buildingTwinId,
      monthlyTargets: [],
      temperatureSeries: [],
      targets: [],
      loading: true,
    };
  }

  async componentDidMount() {
    let allPromises = [];
    let targets = [];
    // Do not make a call if no building is selected
    if (this.state.buildingTwinId || this.props.buildingTwinId) {
      // Get monthly targets from the BUILDING digital twin
      const monthlyTargets = await restClient(GET_ONE, "blocks", {
        id: this.state.buildingTwinId,
      });
      this.setState({ monthlyTargets: monthlyTargets.data.MonthlyTargets });
    }

    const consumption = await restClient(GET_LIST, "consumptionlists", {
      filter: {
        BuildingId: this.state.BuildingId,
      },
      sort: { field: "Time", order: "ASC" },
      pagination: {},
    });
    const consumptionData = consumption.data;
    this.setState({ data: consumptionData });

    // Only get outdoortemp if graph type is heating
    if (
      this.props.location.state.type === "heating" &&
      consumptionData &&
      consumptionData.length > 0
    ) {
      // Get the HP twins for this building and get their production targets and subtract that from the monthly targets to get the target for the purchased heating
      const hp = await restClient(GET_LIST, "blocks", {
        filter: {
          BuildingId: this.state.BuildingId,
          BlockType: "HP",
        },
        sort: { field: null, order: "ASC" },
        pagination: { page: 1, perPage: 9999 },
      });

      // Get HP data for each HP twin
      if (hp && hp.data && hp.data.length > 0) {
        const hpPromises = hp.data.map(async (h) => {
          const target = await restClient(GET_ONE, "blocks", {
            id: h.id,
          });
          if (
            has(target, "data.Hp.MonthlyTargets") &&
            target.data.Hp.MonthlyTargets.length > 0
          ) {
            targets.push(target.data.Hp.MonthlyTargets);
          }
        });
        allPromises.push(...hpPromises);
      }

      // Get the times of the first consumption record and the last
      let dataBegin = consumptionData[0].Time;
      dataBegin = dataBegin.slice(0, 7);
      let dataEnd = consumptionData[consumptionData.length - 1].Time;
      dataEnd = dataEnd.slice(0, 7);

      const startDate = new Date(dataBegin);
      const endDate = new Date(dataEnd);

      const temperaturesfetch = await restClient(GET_LIST, "reportdata", {
        filter: {
          pointIds: this.state.BuildingId,
          buildingId: this.state.BuildingId,
          pointSource: "building_temp",
        },
        sort: {
          field: "valueTime",
          order: "ASC",
        },
        pagination: {},
      });

      const temperatureData = temperaturesfetch.data;

      // Get monthly averages of every year in the received record if temperature data exists
      let tempSeries = [];
      if (temperatureData && temperatureData.length > 0) {
        let temperatureArr = buildingTempToChart(
          temperatureData,
          startDate,
          endDate
        );

        tempSeries.push({
          name: "Outside Temperatures",
          data: null,
          type: "line",
          showInLegend: true,
          visible: false,
          id: "group",
          events: {
            legendItemClick: (event) => {
              event.preventDefault();
              this.temperatureGroupClickedHandler(event.target);
              return false;
            },
          },
        });

        for (let i = 0; i < temperatureArr.length; i++) {
          const dataArr = [
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ];
          temperatureArr[i].forEach((t) => {
            dataArr[parseInt(t.month) - 1] = t.val;
          });
          // Color (index) is offset by one to make the target series the first color and temperature series are the next ones
          tempSeries.push({
            name: "Temperature " + temperatureArr[i][0].year,
            data: dataArr,
            type: "line",
            zIndex: 9,
            yAxis: 1,
            visible: false,
            showInLegend: false,
            color: graphColor({
              index: i,
              seriesLength: temperatureArr.length,
              lineSeries: true,
              offset: 1,
            }),
          });
        }
      }
      Promise.all(allPromises).then((r) => {
        this.setState({
          temperatureSeries: tempSeries,
          loading: false,
          targets: targets,
        });
      });
    } else {
      // Get the SOLAR twins for this building and get their production targets and subtract that from the monthly targets to get the target for the purchased electricity
      const solar = await restClient(GET_LIST, "blocks", {
        filter: {
          BuildingId: this.state.BuildingId,
          BlockType: "SOLAR",
        },
        sort: { field: null, order: "ASC" },
        pagination: { page: 1, perPage: 9999 },
      });

      // Get HP data for each HP twin
      if (solar && solar.data && solar.data.length > 0) {
        const solarPromises = solar.data.map(async (s) => {
          const target = await restClient(GET_ONE, "blocks", {
            id: s.id,
          });
          if (
            has(target, "data.Solar.MonthlyTargets") &&
            target.data.Solar.MonthlyTargets.length > 0
          ) {
            targets.push(target.data.Solar.MonthlyTargets);
          }
        });
        allPromises.push(...solarPromises);
      }
      Promise.all(allPromises).then((r) => {
        this.setState({ loading: false, targets: targets });
      });
    }
  }

  temperatureGroupClickedHandler(series) {
    // When the group is clicked, only show temperatures that have a visible consumption year
    const allSeries = series.chart.series;
    if (series.visible) {
      series.hide();
      for (let i = 0; i < allSeries.length; i++) {
        let tempNameStart = allSeries[i].name.toString().slice(0, 12);
        if (tempNameStart === "Temperature ") {
          allSeries[i].hide();
        }
      }
    } else {
      series.show();
      for (let i = 0; i < allSeries.length; i++) {
        let tempNameStart = allSeries[i].name.toString().slice(0, 12);
        let tempNameEnd = allSeries[i].name.toString().slice(12, 17);
        if (tempNameStart === "Temperature ") {
          // Find the corresponding consumption series
          for (let ind = 0; ind < allSeries.length; ind++) {
            if (
              allSeries[ind].name.toString() === tempNameEnd &&
              allSeries[ind].visible
            ) {
              allSeries[i].show();
            }
          }
        }
      }
    }
  }

  legendItemClickedHandler = (series) => {
    const name = series.name;
    let groupSeriesVisible = null;
    // Find the temperature group
    for (let i = 0; i < series.chart.series.length; i++) {
      if (series.chart.series[i].name === "Outside Temperatures") {
        groupSeriesVisible = series.chart.series[i].visible;
      }
    }
    // If group series is hidden, do not hide/show the related temperature group
    if (series.visible) {
      series.hide();
      //Try to find a matching outside temperature
      for (let i = 0; i < series.chart.series.length; i++) {
        if (
          series.chart.series[i].name === "Temperature " + name &&
          groupSeriesVisible
        ) {
          series.chart.series[i].hide();
        }
      }
      return;
    } else {
      series.show();
      for (let i = 0; i < series.chart.series.length; i++) {
        if (
          series.chart.series[i].name === "Temperature " + name &&
          groupSeriesVisible
        ) {
          series.chart.series[i].show();
        }
      }
      return;
    }
  };

  targetMapping = (type, value) => {
    if (this.state.monthlyTargets.length > 0) {
      // Try to find a suitable target description
      let targetType = "";
      if (type === "heating" && value === "ConsNorm") {
        targetType = "BUILDING_HEAT_TARGET";
      } else {
        targetType = "BUILDING_" + type.toUpperCase();
      }

      // Factor for some types is different than 1
      let typeFactor = 1;
      let targetDivider = 1000;
      if (type === "electricity") {
        typeFactor = 1000;
        targetDivider = 1;
      }

      let targetIndex = null;
      // Check if target data exists
      let targetFound = false;
      for (let i = 0; i < this.state.monthlyTargets.length; i++) {
        if (this.state.monthlyTargets[i].Desc === targetType) {
          targetFound = true;
          targetIndex = i;
          break;
        }
      }

      if (!targetFound) {
        return null;
      } else {
        let targetData = [];

        for (let index = targetIndex; index < targetIndex + 12; index++) {
          targetData[this.state.monthlyTargets[index].Month - 1] =
            this.state.monthlyTargets[index].Target * typeFactor;
        }
        // Subtract HP production targets for heating graphs and SOLAR targets for electricity graphs
        // First calculate the HP/Solar targets for each twin and month
        let twinTargets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (this.state.targets && this.state.targets.length > 0) {
          this.state.targets.forEach((tar) => {
            tar.forEach((t) => {
              const index = t.Month - 1;
              if (t.ProducedTarget) {
                twinTargets[index] += t.ProducedTarget;
              }
              if (t.AdditionalTarget) {
                twinTargets[index] += t.AdditionalTarget;
              }
              // This is for solar, above are HP
              if (t.Target) {
                twinTargets[index] += t.Target;
              }
            });
          });
        }
        for (let i = 0; i < targetData.length; i++) {
          targetData[i] -= twinTargets[i] / targetDivider;
          targetData[i] = parseFloat(targetData[i].toFixed(2));
        }
        return {
          name: "Target",
          data: targetData,
          type: "line",
          zIndex: 10,
          color: graphColor({ index: 0, seriesLength: 1, lineSeries: true }),
        };
      }
    }
  };

  seriesMapping = (data, type, value) => {
    // Get unique years from consumption data.
    const years = [
      ...new Set(
        data.map((obj) => {
          const date = new Date(obj.Time);
          return date.getFullYear();
        })
      ),
    ];
    // create an array of series, one for each year.
    // Only set the last three years visible by default
    let newestYear = 0;
    years.forEach((y) => {
      if (y > newestYear) {
        newestYear = y;
      }
    });
    const oldestYear = newestYear - 2;
    const series = years.map((year, index) => {
      let showYear = false;
      if (year >= oldestYear) {
        showYear = true;
      }

      return {
        name: year,
        data: raConsumptionToHighchartsData(data, type, value, year),
        visible: showYear,
        color: graphColor({ index, seriesLength: years.length }),
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            this.legendItemClickedHandler(event.target);
            return false;
          },
        },
      };
    });
    return series;
  };

  graphOptions = (data, type, value) => {
    const graphdata = this.seriesMapping(data, type, value);
    const targetData = this.targetMapping(type, value);
    // Check if targetData exists
    let series = graphdata;
    if (targetData) {
      series.push(targetData);
    }
    // Check chart y-axis
    let yAxis = [
      {
        // Primary axis
        min: 0,
        title: {
          text: setConsumptionUnitText(type),
        },
      },
    ];
    // Check if temperatureData exists
    if (this.state.temperatureSeries.length > 0) {
      this.state.temperatureSeries.map((s) => series.push(s));
      //series.push(this.state.temperatureSeries);
      // Also create secondary y-axis for outside temperature
      yAxis.push({
        min: -30,
        max: 30,
        title: {
          text: "Temperature (°C)",
        },
        opposite: true,
      });
    }
    // Check chart title based on type
    // Custom titles for heating charts, others are type with 1st letter in uppercase
    let title = "";
    if (value === "ConsNorm") {
      title = "Heating - Weather Corrected";
    } else if (type === "heating") {
      title = "Heating - Absolute";
    } else {
      title = type[0].toUpperCase() + type.slice(1);
    }
    const finalSeries = series;
    return {
      chart: {
        type: "column",
        height: 600,
        width: 1200,
      },
      legend: {
        enabled: true,
      },
      series: finalSeries,
      title: {
        text: title,
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: true,
      },
      yAxis: yAxis,
    };
  };

  render() {
    // Check whether to render title or not, depends on the rendering location
    let titleText = " ";
    if (this.props.renderTitle) {
      titleText = this.state.projectname;
    }
    let render = <Loading />;
    if (!this.state.loading) {
      render = (
        <div>
          <Card>
            <Title title={titleText} />
            <HighchartsReact
              highcharts={Highcharts}
              ref={this.consRef}
              options={this.graphOptions(
                this.state.data,
                this.props.location.state.type,
                this.props.location.state.value
              )}
            />
          </Card>
          {this.props.location.state.type === "heating" ? (
            <Card>
              <HighchartsReact
                highcharts={Highcharts}
                ref={this.normRef}
                options={this.graphOptions(
                  this.state.data,
                  "heating",
                  "ConsNorm"
                )}
              />
            </Card>
          ) : null}
        </div>
      );
    }
    return render;
  }
}

export default stateMapper(ConsumptionGraph);
