import React, { useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DeleteButton,
  Edit,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";

import { FormDataConsumer } from "react-admin";
import { Card, CardContent, CardHeader, withStyles } from "@material-ui/core";
import { get } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { HpDrInputs } from "./HpDrInputs";
import { AhuInputs } from "./AhuInputs";
import { MeterInputs } from "./MeterInputs";
import { HxInputs } from "./HxInputs";
import { HpInputs } from "./HpInputs";
import { SolarInputs } from "./SolarInputs";
import { ZoneInputs } from "./ZoneInputs";
import { AhuOperations } from "./AhuOperations";
import { MeterOperations } from "./MeterOperations";
import { HpOperations } from "./HpOperations";
import { SolarOperations } from "./SolarOperations";
import { ZoneOperations } from "./ZoneOperations";
import { isIntValue, minValue, maxValue } from "../validationHelper";
import { blockTypeInputOptions } from "./blockTypeInputOptions";
import optionRenderer from "../utils/optionRenderer";

import CustomShowButton from "../components/CustomShowButton";
import ZoneUpload from "./ZoneUpload";
import HpUpload from "./HpUpload";
import { useLocation } from "react-router-dom";
import ZoneUsageTimesInput from "./ZoneUsageTimeInput";
import PageTitle from "../components/layout/PageTitle";

const blockStyles = {
  select_variable_field: { display: "inline-block", width: 400 },
  scaling_field: { display: "inline-block" },
  multiple_points: { chip: { flexWrap: "wrap" } },
};

function ResolveDefaultEfficiency(formData) {
  const type = get(formData, "Ahu.HeatRecoveryType", "");
  if (type === "RECIRCULATION_HR") {
    return 70;
  } else if (type === "DISC_HR") {
    return 70;
  } else if (type === "CUBE_HR") {
    return 50;
  } else if (type === "LIQUID_HR") {
    return 30;
  } else if (type === "NO_HR") {
    return 0;
  } else {
    return 0;
  }
}

const zoneOptionRenderer = (choice) => {
  if (choice) return `${choice.BlockName} - ${choice.BlockDescription}`;
};

const NameTooltipTitle =
  "This is more like prefix so something simple and short AHU1, TK311 etc...";

const validateHour = [isIntValue(), minValue(0), maxValue(24)];

const toolbarStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const BlockEditToolbar = withStyles(toolbarStyles)((props) => {
  // Check which tab is active to determine which save button to render
  const location = useLocation();
  const path = location.pathname;
  const pathArr = path.split("/");
  const selectedTab = pathArr[pathArr.length - 1];

  return (
    <Toolbar {...props}>
      {selectedTab === "5" && props.record.BlockType === "ZONE" ? null : (
        <SaveButton />
      )}
      {props.record.BlockType !== "BUILDING" && selectedTab !== "5" ? (
        <DeleteButton />
      ) : null}
    </Toolbar>
  );
});

const solarDefaults = [
  { Month: 1, Desc: "January", Target: 0 },
  { Month: 2, Desc: "February", Target: 0 },
  { Month: 3, Desc: "March", Target: 0 },
  { Month: 4, Desc: "April", Target: 0 },
  { Month: 5, Desc: "May", Target: 0 },
  { Month: 6, Desc: "June", Target: 0 },
  { Month: 7, Desc: "July", Target: 0 },
  { Month: 8, Desc: "August", Target: 0 },
  { Month: 9, Desc: "September", Target: 0 },
  { Month: 10, Desc: "October", Target: 0 },
  { Month: 11, Desc: "November", Target: 0 },
  { Month: 12, Desc: "December", Target: 0 },
];

const hpDefaultTargets = [
  {
    Month: 1,
    Desc: "January",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 2,
    Desc: "February",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 3,
    Desc: "March",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 4,
    Desc: "April",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 5,
    Desc: "May",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 6,
    Desc: "June",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 7,
    Desc: "July",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 8,
    Desc: "August",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 9,
    Desc: "September",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 10,
    Desc: "October",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 11,
    Desc: "November",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 12,
    Desc: "December",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
];

const hpChoices = [
  {
    id: "AIR",
    name: "Air source heat pump",
  },
  {
    id: "GROUND",
    name: "Ground source heat pump",
  },
];

const ahuChoices = [
  {
    id: "RECIRCULATION_HR",
    name: "Recirculation HR",
  },
  {
    id: "DISC_HR",
    name: "Disc HR",
  },
  {
    id: "CUBE_HR",
    name: "Cube HR",
  },
  {
    id: "LIQUID_HR",
    name: "Liquid HR",
  },
  {
    id: "NO_HR",
    name: "No HR",
  },
];

const meterDirChoices = [
  {
    id: "Consumption",
    name: "Consumption",
  },
  {
    id: "Production",
    name: "Production",
  },
];

const meterCatChoices = [
  {
    id: "Cooling",
    name: "Cooling",
  },
  {
    id: "Electricity",
    name: "Electricity",
  },
  {
    id: "Heating",
    name: "Heating",
  },
  {
    id: "Water",
    name: "Water",
  },
];

const zoneChoices = [
  {
    id: 0,
    name: "Custom",
  },
  {
    id: 1,
    name: "S1",
  },
  {
    id: 2,
    name: "S2",
  },
  {
    id: 3,
    name: "S3",
  },
];
const airFlowCalcMethodChoices = [
  {
    id: "MeasuredAirFlow",
    name: "Measured airflow",
  },
  {
    id: "CalculatedFromPressure",
    name: "Calculated from pressure",
  },
  {
    id: "CalculatedFromControlPercent",
    name: "Calculated from control percent",
  },
];

export const BlockEdit = withStyles(blockStyles)(({ classes, ...props }) => {
  const [type, setType] = useState(null);
  const BlockEditActions = ({ basePath, data, resource }) => {
    const bType = get(data, "BlockType", null);
    if (type !== bType) {
      setType(bType);
    }
    const id = get(data, "id", "");
    return (
      <TopToolbar>
        <CustomShowButton
          link={bType === "BUILDING" ? "/dashboard" : "/blocks/" + id + "/show"}
          basePath={basePath}
          record={data}
        />
      </TopToolbar>
    );
  };

  const ExtraDiv = (props) => {
    // Remove unused props to get rid of errors
    let divProps = Object.assign({}, props);
    delete divProps.basePath;
    return (
      <div style={{ display: "inline-flex", width: "100%" }}>
        <div style={{ display: "flex" }}>
          <TextInput
            multiline
            label="Block name"
            source="BlockName"
            validate={required()}
            style={{ width: "100%" }}
            options={{ autoComplete: "off" }}
          />
          <Tooltip title={NameTooltipTitle} placement="right-end">
            <HelpIcon style={{ margin: "auto", fontWeight: "20em" }} />
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageTitle title="Edit Digital Twin" />
      <Edit {...props} actions={<BlockEditActions />}>
        <TabbedForm submitOnEnter={false} toolbar={<BlockEditToolbar />}>
          <FormTab label="General info">
            <BooleanInput source="Active" default={false} />
            <ReferenceInput
              label="Building"
              source="BuildingId"
              reference="buildings"
              sort={{ field: "ProjectName", order: "ASC" }}
              filter={{ RegisterListsVisible: true, showBAUI: true }}
              fullWidth
            >
              <AutocompleteInput
                optionText="ProjectName"
                optionValue="id"
                options={{
                  fullWidth: true,
                }}
              />
            </ReferenceInput>
            <ExtraDiv />
            <TextInput
              multiline
              label="Block description"
              source="BlockDescription"
              validate={required()}
              style={{ width: "100%" }}
              options={{ autoComplete: "off" }}
            />
            <SelectInput source="BlockType" choices={blockTypeInputOptions} />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.BlockType === "BUILDING") {
                  return (
                    <ReferenceInput
                      fullWidth
                      label='RAU UNIX Timestamp point name'
                      source='BuildingVariables.RAUTimestampPointName'
                      reference="registerlists"
                      filter={{ buildingId: formData.BuildingId }}
                      allowEmpty={true}
                    >
                      <AutocompleteInput
                        optionText={optionRenderer}
                        optionValue="PointId"
                        fullWidth={true}
                      />
                    </ReferenceInput>
                  );
                }
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "AHU" && (
                  <div>
                    <SelectInput
                      label="HR type"
                      source="Ahu.HeatRecoveryType"
                      choices={ahuChoices}
                    />
                    {formData.Ahu && !formData.Ahu.HeatRecoveryType ? null : (
                      <NumberInput
                        label="Est. HR efficiency (%)"
                        source="AhuHeatRecoveryEfficiencyLevel"
                        defaultValue={ResolveDefaultEfficiency(
                          formData
                        )} /*<- FIXME defaultValue does not work in edit. How to fix? */
                        style={{
                          marginLeft: "20px",
                        }}
                      />
                    )}
                    <Card>
                      <CardHeader title="Air flow calculation method" />
                      <CardContent>
                        {" "}
                        <SelectInput
                          label="Supply air flow calculation method"
                          source="Ahu.SupplyAirFlowCalculationMethod"
                          allowEmpty
                          choices={airFlowCalcMethodChoices}
                          style={{ display: "block" }}
                          fullWidth
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.Ahu &&
                            formData.Ahu.SupplyAirFlowCalculationMethod ===
                            "CalculatedFromPressure" && [
                              <NumberInput
                                label="Measured supply air flow (0-50 m³/s)"
                                source="Ahu.MeasuredSupplyAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(50),
                                ]}
                                style={{ width: "45%" }}
                              />,
                              <NumberInput
                                label="Pressure at measured supply air flow (0-1000 Pa)"
                                source="Ahu.PressureAtMeasuredSupplyAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(1000),
                                ]}
                                style={{
                                  marginLeft: "20px",
                                  width: "45%",
                                }}
                              />,
                            ]
                          }
                        </FormDataConsumer>
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.Ahu &&
                            formData.Ahu.SupplyAirFlowCalculationMethod ===
                            "CalculatedFromControlPercent" && [
                              <NumberInput
                                label="Measured supply air flow (0-50 m³/s)"
                                source="Ahu.MeasuredSupplyAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(50),
                                ]}
                                style={{ width: "45%" }}
                              />,
                              <NumberInput
                                label="Control percentage at measured supply air flow (0-100 %)"
                                source="Ahu.ControlAtMeasuredSupplyAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(100),
                                ]}
                                style={{
                                  marginLeft: "20px",
                                  width: "45%",
                                }}
                              />,
                            ]
                          }
                        </FormDataConsumer>
                        <SelectInput
                          label="Return air flow calculation method"
                          source="Ahu.ReturnAirFlowCalculationMethod"
                          allowEmpty
                          choices={airFlowCalcMethodChoices}
                          style={{ display: "block" }}
                          fullWidth
                        />
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.Ahu &&
                            formData.Ahu.ReturnAirFlowCalculationMethod ===
                            "CalculatedFromPressure" && [
                              <NumberInput
                                label="Measured return air flow (0-50 m³/s)"
                                source="Ahu.MeasuredReturnAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(50),
                                ]}
                                style={{ width: "45%" }}
                              />,
                              <NumberInput
                                label="Pressure at measured return air flow (0-1000 Pa)"
                                source="Ahu.PressureAtMeasuredReturnAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(1000),
                                ]}
                                style={{
                                  marginLeft: "20px",
                                  width: "45%",
                                }}
                              />,
                            ]
                          }
                        </FormDataConsumer>
                        <FormDataConsumer>
                          {({ formData, ...rest }) =>
                            formData.Ahu &&
                            formData.Ahu.ReturnAirFlowCalculationMethod ===
                            "CalculatedFromControlPercent" && [
                              <NumberInput
                                label="Measured return air flow (0-50 m³/s)"
                                source="Ahu.MeasuredReturnAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(50),
                                ]}
                                style={{ width: "45%" }}
                              />,
                              <NumberInput
                                label="Control percentage at measured return air flow (0-100 %)"
                                source="Ahu.ControlAtMeasuredReturnAirFlow"
                                validate={[
                                  required(),
                                  minValue(0),
                                  maxValue(100),
                                ]}
                                style={{
                                  marginLeft: "20px",
                                  width: "45%",
                                }}
                              />,
                            ]
                          }
                        </FormDataConsumer>
                      </CardContent>
                    </Card>
                    <ReferenceArrayInput
                      label="Serving zone blocks"
                      source="Ahu.ZoneBlockIds"
                      reference="blocks"
                      perPage={500}
                      sort={{ field: "BlockName", order: "ASC" }}
                      filter={{
                        BuildingId: formData.BuildingId,
                        BlockType: "ZONE",
                      }}
                      fullWidth
                    >
                      <SelectArrayInput
                        optionText={zoneOptionRenderer}
                        optionValue="id"
                      />
                    </ReferenceArrayInput>

                    <ArrayInput source="Ahu.AirFlowTargets">
                      <SimpleFormIterator>
                        <Tooltip
                          title="Set only air flow targets when AHU is running."
                          placement="right-end"
                        >
                          <HelpIcon />
                        </Tooltip>
                        <NumberInput
                          label="Supply air flow (m³/s)"
                          source="AirFlow"
                        />
                        <NumberInput
                          label="Return air flow (m³/s)"
                          source="ReturnAirFlow"
                        />
                        <NumberInput
                          label="Monday start"
                          source="MonStart"
                          validate={validateHour}
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Monday end" source="MonEnd" />
                        <NumberInput
                          label="Tuesday start"
                          source="TueStart"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Tuesday end" source="TueEnd" />
                        <NumberInput
                          label="Wednesday start"
                          source="WedStart"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Wednesday end" source="WedEnd" />
                        <NumberInput
                          label="Thursday start"
                          source="ThuStart"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Thursday end" source="ThuEnd" />
                        <NumberInput
                          label="Friday start"
                          source="FriStart"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Friday end" source="FriEnd" />
                        <NumberInput
                          label="Saturday start"
                          source="SatStart"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Saturday end" source="SatEnd" />
                        <NumberInput
                          label="Sunday start"
                          source="SunStart"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput label="Sunday end" source="SunEnd" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </div>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "Meter" && (
                  <div>
                    <SelectInput
                      label="Meter direction"
                      source="Meter.Direction"
                      validate={required()}
                      choices={meterDirChoices}
                    />
                    <SelectInput
                      label="Meter category"
                      source="Meter.Category"
                      validate={required()}
                      choices={meterCatChoices}
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HP" && (
                  <div>
                    <SelectInput
                      label="Heat source"
                      source="Hp.HeatSource"
                      validate={required()}
                      choices={hpChoices}
                    />
                    <NumberInput
                      label="Rated electric power (kW)"
                      source="Hp.RatedElectricPower"
                      style={{ marginLeft: "25px" }}
                    />
                    <NumberInput
                      label="Rated thermal power (kW)"
                      source="Hp.RatedThermalPower"
                      style={{ marginLeft: "25px" }}
                    />
                  </div>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HP" &&
                formData.Hp && (
                  <SelectInput
                    source="BlockVersion"
                    style={{ display: "inline-block", float: "left" }}
                    choices={[
                      { id: "1", name: "1" },
                      { id: "2", name: "2" },
                    ]}
                  ></SelectInput>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HP" && (
                  <ArrayInput
                    source="Hp.MonthlyTargets"
                    defaultValue={hpDefaultTargets}
                  >
                    <SimpleFormIterator disableAdd disableRemove>
                      <TextInput
                        disabled
                        label="Month"
                        source="Month"
                        style={{ display: "inline-block", float: "left" }}
                      />
                      <NumberInput
                        label="Energy production (kWh)"
                        source="ProducedTarget"
                        style={{ display: "inline-block", float: "left" }}
                      />
                      <NumberInput
                        label="Energy consumption (kWh)"
                        source="ConsumedTarget"
                        style={{ display: "inline-block", float: "left" }}
                      />
                      <NumberInput
                        label="Additional energy consumption (kWh)"
                        source="AdditionalTarget"
                        style={{ display: "inline-block", float: "left" }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "SOLAR" && [
                  <NumberInput
                    source="Solar.PanelArea"
                    label="Panel area (m²)"
                  />,
                  <NumberInput
                    source="Solar.PanelPowerDensity"
                    label="Panel power density (W/m²)"
                  />,
                  <ArrayInput
                    source="Solar.MonthlyTargets"
                    defaultValue={solarDefaults}
                  >
                    <SimpleFormIterator disableAdd disableRemove>
                      <TextInput
                        disabled
                        label="Month"
                        source="Desc"
                        style={{ display: "inline-block", float: "left" }}
                      />
                      <NumberInput
                        label="Target (kW)"
                        source="Target"
                        style={{ display: "inline-block", float: "left" }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>,
                ]
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "ZONE" && (
                  <React.Fragment>
                    <SelectInput
                      label="Zone indoor climate class"
                      source="Zone.IndoorClimateClass"
                      allowEmpty
                      defaultValue={2}
                      choices={zoneChoices}
                    />
                    {formData.Zone && formData.Zone.IndoorClimateClass === 0 && (
                      <div>
                        <NumberInput
                          label="SMin"
                          source="Zone.ZoneOperativeMinTemp"
                        />
                        <NumberInput
                          label="SMax"
                          source="Zone.ZoneOperativeMaxTemp"
                        />
                      </div>
                    )}
                    <NumberInput label="Floor" source="Zone.Floor" />
                    <ZoneUsageTimesInput formData={formData} />

                    <NumberInput disabled source="Zone.BuildingZoneId" />
                  </React.Fragment>
                )
              }
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Inputs">
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType !== "SOLAR" &&
                formData.BlockType !== "Meter" && (
                  <BooleanInput
                    label="Use IL outdoor weather"
                    source="UseILWeather"
                    defaultValue={true}
                  />
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "AHU" &&
                !formData.UseILWeather && (
                  <div>
                    <ReferenceInput
                      label="Outdoor air TE"
                      source="Ahu.OutdoorTE.BusTagName"
                      reference="registerlists"
                      filter={{ buildingId: formData.BuildingId }}
                    >
                      <AutocompleteInput
                        optionText={optionRenderer}
                        optionValue="PointId"
                        options={{
                          fullWidth: true,
                        }}
                      />
                    </ReferenceInput>
                  </div>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "AHU" && <AhuInputs {...formData} />
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "Meter" && <MeterInputs {...formData} />
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HX" && <HxInputs {...formData} />
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HP" && <HpInputs {...formData} />
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "SOLAR" && <SolarInputs {...formData} />
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "ZONE" && <ZoneInputs {...formData} />
              }
            </FormDataConsumer>
          </FormTab>

          <FormTab label="Operations">
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                (!formData.BlockType || !formData.BuildingId) && (
                  <p>Select building and lock type first!</p>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "AHU" && <AhuOperations {...formData} />
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "Meter" && (
                  <MeterOperations {...formData} />
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HX" && (
                  <div>
                    <p>No operations yet for HX block!</p>
                    {/*<HxOperations {...formData} />*/}
                  </div>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "HP" && (
                  <div>
                    <HpOperations {...formData} />
                  </div>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "SOLAR" && (
                  <SolarOperations {...formData} />
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType === "ZONE" && (
                  <ZoneOperations {...formData} />
                )
              }
            </FormDataConsumer>
          </FormTab>

          <FormTab label="Alerts">
            <ArrayInput
              source="BlockAlerts"
              style={{ border: "1px solid red", display: "contents" }}
            >
              <SimpleFormIterator disableRemove disableAdd>
                <TextInput
                  label="AlertName"
                  source="AlertName"
                  disabled={true}
                  style={{ display: "inline-block", float: "left" }}
                />
                <NumberInput
                  label="Limit"
                  source="Limit"
                  style={{ display: "inline-block", float: "left" }}
                />
                <NumberInput
                  label="Days"
                  source="Days"
                  style={{ display: "inline-block", float: "left" }}
                />
                <BooleanInput
                  label="Active"
                  source="Active"
                  style={{
                    display: "inline-block",
                    float: "left",
                    //pointerEvents: "none",
                  }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>

          <FormTab label="Outputs">
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                (formData.BlockType === "HP" ||
                  formData.BlockType === "SOLAR") && <p>Nothing here yet!</p>
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                (formData.BlockType === "HX" ||
                  formData.BlockType === "AHU") && (
                  <ReferenceInput
                    label="Compensation output"
                    source="Compensation.BlockOutput.BusTagName"
                    reference="registerlists"
                    filter={{
                      buildingId: formData.BuildingId,
                      writable: true,
                    }}
                    perPage={10}
                    allowEmpty={true}
                  >
                    <AutocompleteInput
                      optionText={optionRenderer}
                      optionValue="PointId"
                      options={{
                        fullWidth: true,
                      }}
                      filter={{
                        buildingId: formData.BuildingId,
                        writable: true,
                      }}
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                formData.BlockType &&
                (formData.BlockType === "HX" ||
                  formData.BlockType === "AHU") && [
                  <NumberInput
                    key={1}
                    source="Compensation.TemperatureHours"
                    step={1}
                    validate={[minValue(1), maxValue(72)]}
                  />,
                  <ArrayInput
                    key={2}
                    source="Compensation.CompensationCoordinates"
                  >
                    <SimpleFormIterator>
                      <NumberInput
                        label="Input"
                        source="Input"
                        style={{ display: "inline", float: "left" }}
                      />
                      <NumberInput
                        label="Output"
                        source="Output"
                        style={{
                          display: "inline",
                          float: "left",
                          marginLeft: "20px",
                        }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>,
                ]
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BuildingId &&
                (formData.BlockType === "HX" ||
                  formData.BlockType === "AHU") && (
                  <BooleanInput
                    label="Use solar radiation compensation"
                    source="Compensation.UseRadiationCompensation"
                  />
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData &&
                (formData.BlockType === "HX" ||
                  formData.BlockType === "AHU") && [
                  <NumberInput
                    key={1}
                    source="Compensation.RadiationHours"
                    step={1}
                    validate={[minValue(1), maxValue(72)]}
                  />,
                  <ArrayInput
                    key={2}
                    label="solar radiation coords"
                    source="Compensation.RadiationCompensationCoordinates"
                  >
                    <SimpleFormIterator>
                      <NumberInput
                        label="Input"
                        source="Input"
                        style={{ display: "inline", float: "left" }}
                      />
                      <NumberInput
                        label="Output"
                        source="Output"
                        style={{
                          display: "inline",
                          float: "left",
                          marginLeft: "20px",
                        }}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>,
                ]
              }
            </FormDataConsumer>
          </FormTab>
          {type === "HP" || type === "ZONE" ? (
            <FormTab label="Upload">
              {type === "HP" ? <HpUpload /> : <ZoneUpload />}
            </FormTab>
          ) : null}
          {type === "HP" ? (
            <FormTab label="Demand Response">
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HP" && <HpDrInputs {...formData} />
                }
              </FormDataConsumer>
            </FormTab>
          ) : null}
        </TabbedForm>
      </Edit>
    </>
  );
});
