import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  primary: { background: "white", color: "black" }
};

const AddBlockButton = ({ record, classes }) => {
  return (
    <Button
      variant="contained"
      className={classes.primary}
      component={Link}
      to={{
        pathname: "/blocks/create",
        state: { record: { BuildingId: record.id } }
      }}
    >
      Add new block
    </Button>
  );
};

export default withStyles(styles)(AddBlockButton);
