import { Edit, EditProps, SimpleForm, TextInput } from "ra-ui-materialui";
import React from "react";
import PageTitle from "../components/layout/PageTitle";

export const BuildingPointsEdit = (props: EditProps) => {
  return (
    <>
      <PageTitle title="Edit Buildingpoint" />
      <Edit {...props}>
        <SimpleForm>
          <TextInput label="Description" source="Description" />
        </SimpleForm>
      </Edit>
    </>
  );
};