import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";

import SolarTwinInfo from "./SolarTwinInfo";
import { SolarMonthlyEnergyGraph } from "./SolarMonthlyEnergyGraph";
import { SolarDailyEnergyGraph } from "./SolarDailyEnergyGraph";
import CardWithTitle from "../components/layout/CardWithTitle";
import OperationLogs from "../dashboard/logs/OperationLogs";
import useUserRole from "../utils/hooks/useUserRole";

const SolarShow = ({ formData, solarEfficiency }) => {
  const [solarData, setSolarData] = useState(null);
  const [hiddenContainers, setHiddenContainers] = useState([
    "logs",
    "monthly",
    "daily",
  ]);
  const { isInternalAdmin } = useUserRole();

  useEffect(() => {
    if (formData.Solar && !solarData) {
      setSolarData(formData.Solar);
    }
  }, [formData.Solar, solarData]);

  const handleContainerHide = useCallback(
    (container) => {
      if (!hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if (hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers]
  );

  if (
    formData.BuildingId &&
    (formData.Solar || solarData) &&
    formData.BlockType === "SOLAR"
  ) {
    let solar = formData.Solar ? formData.Solar : solarData;
    let _formData = { ...formData, Hx: solar };
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <SolarTwinInfo efficiencyData={solarEfficiency} {..._formData} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          style={{
            display:
              hiddenContainers.includes("logs") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs
              buildingId={_formData.BuildingId}
              twinId={_formData.id}
              showContainer={handleContainerShow}
              hideContainer={handleContainerHide}
            />
          </CardWithTitle>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("monthly") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <SolarMonthlyEnergyGraph
            efficiencyData={solarEfficiency}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("daily") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <SolarDailyEnergyGraph
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default SolarShow;
