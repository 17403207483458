import React, { useState, useEffect, useRef } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import useIsMounted from "../utils/hooks/useIsMounted";
import { heatingColors, coolingColors } from "../utils/graphColor";
import { find, map, round, sortBy, cloneDeep } from "lodash";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (
  dataFromReactAdmin,
  monthlyData = false
) => {
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = null;
    if (!monthlyData) {
      date = new Date(v.date);
    } else {
      date = new Date(v.year, v.month - 1, 1);
    }
    return [date.getTime(), round(v.value / 3600, 2), date.getMonth()];
  });

  return highChartsData;
};

export const AhuMonthlyEnergyGraph = (props) => {
  const [resolution, setResolution] = useState("day");
  const [optionsMonth, setOptionsMonth] = useState({ title: { text: "" } });
  const [optionsDay, setOptionsDay] = useState({ title: { text: "" } });
  const [fetchDone, setFetchDone] = useState(false);
  const isMounted = useIsMounted();
  const chartRef = useRef(null);

  useEffect(() => {
    const getGraphSeries = (data, name, options, monthlyData = false) => {
      const graphData = valuesToHighchartsData(data, monthlyData);
      const sortedData = sortBy(graphData, 0);
      return {
        type: "column",
        name,
        data: sortedData,
        dataGrouping: {
          approximation: "high",
        },
        ...options
      };
    };

    const graphOptions = (series) => {

      // Calculate date offsets for the previous year button
      // Get the time of the latest value
      let newestData = 0;
      series.forEach((s) => {
        if (s && s.data && s.data.length > 0) {
          s.data.forEach((d) => {
            if (d[0] > newestData) {
              newestData = d[0];
            }
          });
        }
      });

      const curEnd = new Date(newestData);
      const curStart = new Date(curEnd.getUTCFullYear(), 0, 1);
      const prevStart = new Date(curEnd.getUTCFullYear() - 1, 0, 1);
      const prevEnd = new Date(curEnd.getUTCFullYear(), 0, 0);
      const diffStart = curStart.getTime() - prevStart.getTime();
      const diffEnd = curEnd.getTime() - prevEnd.getTime();

      return {
        chart: {},
        legend: {
          enabled: true,
        },
        xAxis: {
          minRange: 1,
        },
        rangeSelector: {
          // selected: 1,
          allButtonsEnabled: true,
          buttonTheme: {
            width: "auto",
          },
          buttons: [
            {
              type: "month",
              count: 2,
              text: "3m",
            },
            {
              type: "month",
              count: 12,
              text: "12m",
            },
            {
              type: "ytd",
              text: "Last year",
              offsetMin: -diffStart,
              offsetMax: -diffEnd,
            },
            {
              type: "ytd",
              text: "This year",
            },
            {
              type: "all",
              text: "All",
            },
          ],
        },
        yAxis: [
          {
            title: {
              text: "kWh",
            },
          },
        ],
        title: {
          text: "",
        },
        time: {
          timezone: props.timezone,
        },
        series: series,
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        }
      };
    };

    const getTrend = (trendId, resolution) => {
      return restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          buildingId: props.BuildingId,
          metaIds: [findMetaIds(props.Trends, trendId)],
          trend: trendId,
          resolution,
        },
        sort: { field: "UpdateTime", order: "ASC" },
        pagination: {},
      })
        .then((response) => response.data)
    }

    const heatingSeriesOptions = { color: heatingColors[1] };
    const coolingSeriesOptions = { color: coolingColors[1] };

    Promise.all([
      getTrend("MONTHLY_HEATING_POWER_INTEGRAL", "day"),
      getTrend("MONTHLY_COOLING_POWER_INTEGRAL", "day")
    ])
      .then(([heatingResponse, coolingResponse]) => {
        if (isMounted) {
          const heating = getGraphSeries(heatingResponse.values, "Heating power", heatingSeriesOptions);
          const cooling = getGraphSeries(coolingResponse.values, "Cooling power", coolingSeriesOptions);
          setOptionsDay(graphOptions([heating, cooling]));
          setFetchDone(true);
        }
      });

    Promise.all([
      getTrend("YEARLY_HEATING_POWER_INTEGRAL", "month"),
      getTrend("YEARLY_COOLING_POWER_INTEGRAL", "month")
    ])
      .then(([heatingResponse, coolingResponse]) => {
        if (isMounted) {
          const heating = getGraphSeries(heatingResponse.values, "Heating power", heatingSeriesOptions, true);
          const cooling = getGraphSeries(coolingResponse.values, "Cooling power", coolingSeriesOptions, true);
          setOptionsMonth(graphOptions([heating, cooling]));
          setFetchDone(true);
        }
      });

  }, [isMounted, props.BuildingId, props.Trends, props.timezone]);

  let options = null;
  if (resolution === "day") {
    options = optionsDay;
  } else if (resolution === "month") {
    options = optionsMonth;
  }

  let showData = true;
  if (
    !find(
      props.Trends,
      (y) => y.TrendId === "MONTHLY_HEATING_POWER_INTEGRAL" || y.TrendId === "MONTHLY_COOLING_POWER_INTEGRAL"
    ) ||
    !options ||
    !options.series ||
    options.series.length === 0
  ) {
    showData = false;
  }

  // This is mostly to remove the animation when redraw is happening if the resolution is changed
  // Note the clone deep, it is used because the chart may mutate the series that is passed
  useEffect(() => {
    if (resolution && optionsDay && optionsMonth) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        if (resolution === "day") {
          chartRef.current.chart.update(
            cloneDeep(optionsDay),
            true,
            true,
            false
          );
        } else if (resolution === "month") {
          chartRef.current.chart.update(
            cloneDeep(optionsMonth),
            true,
            true,
            false
          );
        }
      }
    }
  }, [resolution, optionsDay, optionsMonth]);

  const handleResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  const ResolutionInput = (
    <div
      key={"res"}
      style={{
        marginLeft: 15,
        width: 80,
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <InputLabel>Resolution</InputLabel>
      <Select value={resolution} onChange={handleResolutionChange}>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
      </Select>
    </div>
  );

  if (props.showContainer && props.hideContainer && fetchDone) {
    if (showData) {
      props.showContainer("energy");
    } else {
      props.hideContainer("energy");
    }
  }

  return (
    <CardWithTitle title="Energy consumption">
      {showData ? (
        [
          ResolutionInput,
          <HighchartsReact
            key={"chart"}
            ref={chartRef}
            highcharts={Highcharts}
            options={cloneDeep(options)}
            constructorType={"stockChart"}
          />,
        ]
      ) : (
        <div style={{ height: "20rem" }}>
          <EmptyData />
        </div>
      )}
    </CardWithTitle>
  );
};
