import React from "react";

import classes from "./Spinner.module.css";

const Spinner = props => {
  return (
    <div style={props.style} className={classes.spinWrapper}>
      <div className={classes.spinner}></div>
    </div>
  );
};

export default Spinner;
