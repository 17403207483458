import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDataProvider, useNotify } from "react-admin";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

import { Dialog, DialogTitle, Button } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";
import { round, groupBy, isNil } from "lodash";
import useBuildingTimezone from "../utils/hooks/useBuildingTimezone";
import { ValueStorage } from "../types/leasegreen-core-api-exports";

HighchartsMore(Highcharts);

const DataPrecicionIndicator = ({isPreciseData, showAreaRanges, setShowAreaRanges}: any) =>
  <div style={{
    display: "inline-flex",
    height: "3.5em",
    alignItems: "center",
    marginRight: "1em",
  }}>
    {(isPreciseData
      ? <div>Showing precise data</div>
      : <div>
          <span style={{marginRight: ".5em"}}>Showing average data</span>
          <Button variant="contained" onClick={() => setShowAreaRanges(!showAreaRanges)}>
            {showAreaRanges ? 'Hide' : 'Show'} ranges
          </Button>
        </div>)}
  </div>;

const ValueStorageListGraph = (props: any) => {
  const notify = useNotify();
  const tz = useBuildingTimezone() as any;
  const [isOpen, setIsOpen] = useState(false);
  // Now - 3 years is the default fetch
  const [fromDate, setFromDate] = useState(
    moment.utc().startOf("day").subtract(3, "years")
  );
  const [toDate, setToDate] = useState(moment.utc());
  const [chartMinMax, setChartMinMax] = useState({
    min: moment.utc().startOf("day").subtract(3, "years"),
    max: moment.utc(),
  });
  const [variables, setVariables] = useState<any>(null);
  const [buildingPointIds, setBuildingPointIds] = useState([]);
  const dataProvider = useDataProvider();
  const chartRef = useRef<any>();

  const defaultChartOptions: Highcharts.Options = {
    chart: {
      zoomType: "x",
      height: "40%",
    },
    time: {},
    legend: {
      enabled: true,
    },
    tooltip: {
      valueDecimals: 2,
      xDateFormat: "%A, %b %e %Y, %H:%M",
    },
    xAxis: {
      ordinal: false,
      events: {
        afterSetExtremes: (event: any) => {
          if (
            event.trigger &&
            event.min &&
            event.max &&
            !isNaN(event.min) &&
            !isNaN(event.max)
          ) {
            setFromDate(moment(event.min));
            setToDate(moment(event.max));
            setChartMinMax({
              min: moment(event.min),
              max: moment(event.max),
            });
          }
        },
      },
    },
    rangeSelector: {
      inputEnabled: false,
      selected: 5,
      buttons: [
        {
          type: "day",
          count: 1,
          text: "1d",
        },
        {
          type: "week",
          count: 1,
          text: "1w",
        },
        {
          type: "month",
          count: 1,
          text: "1m",
        },
        {
          type: "month",
          count: 3,
          text: "3m",
        },
        {
          type: "month",
          count: 6,
          text: "6m",
        },
        {
          type: "all",
          text: "All",
        },
      ],
    },
    title: {
      text: "",
    },
    series: [],
    scrollbar: {
      liveRedraw: false
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: true,
    },
  };

  const [options, setOptions] = useState(defaultChartOptions);

  const [showAreaRanges, setShowAreaRanges] = useState(false);

  const [isPreciseData, setIsPreciseData] = useState(false);

  const graphOptions = useCallback(
    (data, naviSeries) => {
      const valuesToHighchartsData = (dataFromReactAdmin: any) => {
        const highChartsData = dataFromReactAdmin.map((v: any) => {
          let date = new Date(v.UpdateTime);
          return [date.getTime(), round(v.AggregatedValue || v.PreciseValue, 2)];
        });
        return highChartsData;
      };

      const valuesToAreaChartData = (dataFromReactAdmin: any) => {
        const highChartsData = dataFromReactAdmin.map((v: any) => {
          let date = new Date(v.UpdateTime);
          return [date.getTime(), round(v.MinValue, 2), round(v.MaxValue, 2)];
        });
        return highChartsData;
      };

      const getAreachartData = (seriesId: any, colorToUse: any, data: any) => {
        return {
          name: 'Range',
          type: 'arearange',
          linkedTo: seriesId,
          data: valuesToAreaChartData(data),
          color: `${colorToUse}50`, // Add opacity
          dataGrouping: {
            enabled: false,
          }
        }
      };

      const checkIsPreciseData = (trend: any): boolean => {
        const firstTrendData = trend[0] && trend[0][1];
        return firstTrendData && !isNil(firstTrendData[0].PreciseValue);
      };

      const getAverageLineData = ([variableName, trend]: any, index: any) => {
        const defaultColors: any = Highcharts.getOptions().colors;
        const colorToUse = defaultColors[(index * 3) % defaultColors.length];
        return {
          id: variableName,
          name: `${trend[0].PointSource} ${trend[0].VariableName} ${trend[0].Description}`,
          data: valuesToHighchartsData(trend),
          marker: {
            lineColor: colorToUse
          },
          dataGrouping: {
            enabled: false,
          },
        };
      }

      // Create series based on selected data, one series for each variable/measurement
      const grouped = groupBy(data, "VariableName");
      const series = Array.from(Object.entries(grouped));
      const lineChartData = series.map(getAverageLineData);
      if (!!series) {
        setIsPreciseData(checkIsPreciseData(series));
      }

      return {
        time: {
          timezone: tz,
        },
        series: [
          ...lineChartData,
          ...(
            showAreaRanges && !checkIsPreciseData(series)
              ? series.map(([variableName, trend]: any, index: any) => {
                const defaultColors: any = Highcharts.getOptions().colors;
                const colorToUse = defaultColors[(index * 3) % defaultColors.length];
                return getAreachartData(variableName, colorToUse, trend);
              })
              : []
          )
        ],
        navigator: {
          adaptToUpdatedData: false,
          series: naviSeries || series,
          enabled: true,
        },
      };
    },
    [tz, showAreaRanges]
  );

  useEffect(() => {
    // Graph has been opened
    if (props.open && !isOpen) {
      setIsOpen(true);
      if (
        props.data &&
        ((props.selectedIds && props.selectedIds.length > 0) ||
          (props.singleId && props.singleId.length === 1))
      ) {
        let d: any = [];
        const ids = props.singleId || props.selectedIds;
        let buildingPoints = ids;
        if (buildingPoints && !Array.isArray(ids)) {
          buildingPoints = [buildingPoints];
        }

        for (const key in props.data) {
          if (props.data.hasOwnProperty(key)) {
            // Push unique ID's to the array
            if (
              (ids.includes(parseInt(key)) || ids.includes(key)) &&
              !d.includes(
                props.buildingPoints
                  ? props.data[key].PointId
                  : props.data[key].VariableName
              )
            ) {
              // Set the selected data to state
              d.push(
                props.buildingPoints
                  ? props.data[key].PointId
                  : props.data[key].VariableName
              );
            }
          }
        }
        // Fetch all selected id's, at default for 3 years
        if (d && d.length > 0) {
          setVariables(d);
        }
        if (buildingPoints && buildingPoints.length > 0) {
          setBuildingPointIds(buildingPoints);
        }
      }
    }
    if (!props.open && isOpen) {
      setIsOpen(false);
    }
  }, [
    props.open,
    props.data,
    props.selectedIds,
    isOpen,
    props.buildingPoints,
    props.singleId,
      showAreaRanges
  ]);

  // Separate useEffect for the new data fetching
  useEffect(() => {
    if (
      isOpen &&
      chartMinMax.max &&
      chartMinMax.min &&
      variables &&
      variables.length > 0
    ) {
      let from = moment(chartMinMax.min).tz(tz).startOf("day").toISOString();
      let to = moment(chartMinMax.max).tz(tz).add(1, "day").startOf("day").toISOString();
      // setLoading(true);
      if (chartRef && chartRef.current) {
        chartRef.current?.chart.showLoading("Loading data from server...");
      }
      dataProvider
        .getList<ValueStorage & { id: number }>("valuestorage", {
          filter: {
            BuildingId: props.buildingId,
            VariableNames: variables,
            ValuesFrom: from,
            ValuesTo: to,
            bpids: buildingPointIds,
          },
          sort: {} as any,
          pagination: {} as any
        })
        .then(({ data }) => {
          setOptions((prevOptions: any): any => {
            if (
              prevOptions &&
              prevOptions.navigator &&
              prevOptions.navigator.series
            ) {
              const opt = graphOptions(data, prevOptions.navigator.series);
              if (chartRef && chartRef.current) {
                chartRef.current.chart.update(opt, true, true, true);
              }
              return opt;
            }
            const optNoNavi = graphOptions(data, undefined);
            if (chartRef && chartRef.current) {
              chartRef.current.chart.update(optNoNavi, true, true, true);
            }
            return optNoNavi;
          });
        })
        .catch((error: any) => {
          notify(error.message, "warning");
        })
        .finally(() => {
          if (chartRef && chartRef.current) {
            options.series.length > 0
              ? chartRef.current.chart.hideLoading()
              : chartRef.current.chart.showLoading("No data available");
          }
        });
    } else if (variables && variables.length === 0) {
      if (chartRef && chartRef.current) {
        chartRef.current.chart.hideLoading();
      }
    }
  }, [
      isOpen,
      chartMinMax,
      dataProvider,
      graphOptions,
      variables,
      props.buildingId,
      notify,
      tz,
      buildingPointIds,
      options.series.length
  ]);

  const handleFromChange = (value: any) => {
    setFromDate(value);
    setChartMinMax({
      min: value,
      max: toDate,
    });
  };

  const handleToChange = (value: any) => {
    setToDate(value);
    setChartMinMax({
      min: fromDate,
      max: value,
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle>Trend</DialogTitle>
      {isOpen && (
        <>
          <>
            <div style={{ textAlign: "right" }}>
              { options.series.length > 0
                && <DataPrecicionIndicator
                    isPreciseData={isPreciseData}
                    showAreaRanges={showAreaRanges}
                    setShowAreaRanges={setShowAreaRanges}></DataPrecicionIndicator> }
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <span style={{ marginRight: 10 }}>
                  <DatePicker
                    label="From"
                    format="DD-MM-YYYY"
                    value={fromDate}
                    onChange={handleFromChange}
                    maxDate={toDate}
                    inputVariant="outlined"
                  />
                </span>
                <span style={{ marginRight: 10 }}>
                  <DatePicker
                    label="To"
                    format="DD-MM-YYYY"
                    value={toDate}
                    onChange={handleToChange}
                    disableFuture
                    minDate={fromDate}
                    inputVariant="outlined"
                  />
                </span>
              </MuiPickersUtilsProvider>
            </div>
            <HighchartsReact
              allowChartUpdate={false}
              highcharts={Highcharts}
              constructorType="stockChart"
              options={defaultChartOptions}
              ref={chartRef}
            />
          </>
          <Button variant="contained" onClick={props.onClose}>
            Close
          </Button>
        </>
      )}
    </Dialog>
  );
};

export default ValueStorageListGraph;
