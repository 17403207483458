import graphColor from "../../../utils/graphColor";
import handleLegendClick from "../purchased/handleLegendClick";

const getYearGroups = ({
  data,
  groupNames = ["Outside Temperatures"],
  seriesNames = ["Temperature "],
  type,
}) => {
  let series = [];
  if (data && data.length > 0) {
    series = [...data];
    // Get all years from the series
    let yearSet = new Set();
    series.forEach((s) => {
      if (Number.isInteger(s.stack)) {
        yearSet.add(s.stack);
      }
    });
    let yearGroupSeries = [];

    let ind = 0;

    // Sort the year set by creating an array of it
    let sortedSet = [];
    sortedSet = Array.from(yearSet).sort();

    sortedSet.forEach((y) => {
      yearGroupSeries.push({
        name: y,
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        visible: true,
        color: graphColor({ index: ind, seriesLength: sortedSet.length, type }),
        id: y.toString(),
        className: "group",
        stack: y,
        tooltip: {
          enabled: false,
        },
        events: {
          legendItemClick: (event) => {
            // This is done to prevent multiple events from being bound on re-rendering of the series
            if (event.target.hcEvents.legendItemClick.length > 1) {
              event.target.hcEvents.legendItemClick.length = 1;
            }
            event.preventDefault();
            handleLegendClick(event.target, groupNames, seriesNames);
            return false;
          },
        },
      });
      ind++;
    });
    let finalSeries = [];
    finalSeries = [...yearGroupSeries];
    return finalSeries;
  } else return null;
};

export default getYearGroups;
