import React from "react";
import { useEffect, useState } from "react";
import { useRefresh } from "ra-core";
import { Toolbar } from "react-admin";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box,
  InputLabel,
  Button,
  Card,
  Typography,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  makeStyles,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Save } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import PageTitle from "../components/layout/PageTitle";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  standardPadding: {
    padding: 20,
  },
  createBox: {
    padding: 20,
    margingBottom: 20,
  },
  topPadding: {
    paddingTop: 40,
  },
  standardTopPadding: {
    paddingTop: 20,
  },
  saveButton: {
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
}));

const SubmeterReadingCreate = ({ handleClose }) => {
  const classes = useStyles();
  const customFetch = useCustomFetch();
  const refresh = useRefresh();
  const BuildingId = useSelector((state) => state.buildingFilter.id);

  const [submeterChoices, setSubmeterChoices] = useState([]);
  const [submeter, setSubmeter] = useState("");
  const [measurementValue, setMeasurementValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    customFetch({
      url: `manualsubmeters?BuildingId=${BuildingId}`,
      method: "GET",
    }).then(async (response) => {
      const data = await response.json();
      if (!data) return;
      setSubmeterChoices(data);
    });
  }, [customFetch, BuildingId]);

  const handleSubmit = () => {
    customFetch({
      url: `submeterreadings`,
      method: "POST",
      headers: [
        { name: "Accept", value: "application/json" },
        { name: "Content-Type", value: "application/json" },
      ],
      body: JSON.stringify({
        id: 0,
        SubmeterId: submeter.id,
        MeasurementValue: measurementValue,
        Time: new Date(selectedDate),
      }),
    });
    //Reload submeter readings list
    refresh();
    handleClose();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box className={classes.standardPadding}>
          <PageTitle title="Create Submeter Reading" />
          <Card
            style={{
              padding: 10,
              marginBottom: 20,
              display: submeter ? "block" : "none",
            }}
          >
            <Typography variant="h6">Selected submeter</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Device name</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{submeter.DeviceName}</TableCell>
                  <TableCell>{submeter.MeasurementTarget}</TableCell>
                  <TableCell>{submeter.Unit}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Select Submeter</InputLabel>
            <Select
              id="select-submeter"
              value={submeter.DeviceName}
              onChange={({ target }) => setSubmeter(target.value)}
            >
              {submeterChoices?.map((submeter) => (
                <MenuItem key={submeter.id} value={submeter}>
                  {submeter.DeviceName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className={classes.topPadding}>
            <TextField
              type="number"
              variant="filled"
              label="Value"
              value={measurementValue}
              onChange={({ target }) => setMeasurementValue(target.value)}
            />
          </Box>
          <Box className={classes.standardTopPadding}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Create Date"
                format="yyyy-MM-dd"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Box>
        <Toolbar>
          <Button
            className={classes.saveButton}
            type="submit"
            variant="outlined"
            startIcon={<Save />}
          >
            Save
          </Button>
        </Toolbar>
      </form>
    </>
  );
};

export default SubmeterReadingCreate;
