import React from "react";

export const ZoneOperations = formData => (
  <div style={{ display: "grid" }}>
    <p>
      Background process will collect conditions data every 10min containing
      avg,min,max temperatures, humidities and CO^2 for each block. Values will
      be stored to datalake.
    </p>
  </div>
);
