import React, { useState, useEffect, useRef } from "react";
import { Grid, makeStyles, CardContent } from "@material-ui/core";
import { groupBy, get } from "lodash";
import TwinButton from "../../components/layout/TwinButton";
import DigitalTwinsContainer from "./DigitalTwinsContainer";
import { getTwinStatus } from "../../utils/twinAlerts";
import useCurrentWidth from "../../utils/hooks/useCurrentWidth";
import useUserRole from "../../utils/hooks/useUserRole";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem 1.5rem 2rem 1.5rem",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  container: {
    boxSizing: "border-box",
    paddingTop: "1.5rem",
    margin: 0,
    width: "100%",
    color: "#282331",
  },
  item: {},
}));

const DigitalTwins = (props) => {
  const classes = useStyles();
  const [selectedTwin, setSelectedTwin] = useState(null);
  const [twinsToRight, setTwinsToRight] = useState(false);
  const [twinLength, setTwinLength] = useState(null);
  const [allowChange, setAllowChange] = useState(true);
  const { isExternalUser } = useUserRole();
  const lastTwin = useRef(null);
  const firstTwin = useRef(null);
  // This is used to update the component when screen is resized
  let screenWidth = useCurrentWidth();

  // Change the component layout if twinbuttons are wrapped inside the flex
  if (lastTwin && firstTwin && lastTwin.current && firstTwin.current) {
    if (
      allowChange &&
      lastTwin.current.offsetTop > firstTwin.current.offsetTop &&
      !twinsToRight
    ) {
      setTwinsToRight(true);
      setTwinLength(screenWidth);
    }
    if (
      twinsToRight &&
      (lastTwin.current.offsetTop === firstTwin.current.offsetTop ||
        screenWidth > twinLength)
    ) {
      setTwinsToRight(false);
      setAllowChange(false);
    }
    if (
      !allowChange &&
      lastTwin.current.offsetTop === firstTwin.current.offsetTop
    ) {
      setAllowChange(true);
    }
  }

  let twinData = props.allTwins;

  // Group twins by type
  let groups = null;
  let twinGroups = null;
  let twinKeys = null;
  let selectedGroup = [];
  if (twinData && twinData.length > 0) {
    groups = groupBy(twinData, "BlockType");
    if (groups) {
      twinGroups = Object.entries(groups);
      twinKeys = Object.keys(groups);
      if (selectedTwin) {
        twinGroups.forEach((tg) => {
          if (
            tg &&
            tg[0] &&
            tg[1] &&
            tg[1].length > 0 &&
            tg[0] === selectedTwin
          ) {
            selectedGroup.push(...tg[1]);
          }
        });
      }
    }
  }

  // Check all twins for alerts on mount to open the group that has alerts
  useEffect(() => {
    if (twinKeys && groups && !selectedTwin) {
      twinKeys.forEach((k) => {
        const alert = getTwinStatus(get(groups, k, []), props.alerts);
        if (!isExternalUser && alert) {
          setSelectedTwin(k);
        }
      });
    }
  }, [twinKeys, groups, selectedTwin, props.alerts, isExternalUser]);

  const handleTwinClick = (twin) => {
    if (twin === selectedTwin) {
      setSelectedTwin("off");
    } else {
      setSelectedTwin(twin);
    }
  };

  // If external user, set the default state to off to avoid opening twins that have alerts
  useEffect(() => {
    if (isExternalUser) {
      setSelectedTwin("off");
    }
  }, [isExternalUser]);

  return (
    <div className={classes.root}>
      <CardContent>
        <Grid
          container
          spacing={twinsToRight ? 1 : 4}
          className={classes.container}
          wrap={twinsToRight ? "nowrap" : "wrap"}
        >
          <Grid
            container={twinsToRight ? undefined : true}
            item={twinsToRight ? true : undefined}
            spacing={twinsToRight ? undefined : 4}
            // xs={twinsToRight ? 5 : undefined}
            className={classes.buttons}
          >
            {twinKeys
              ? twinKeys.map((k, index) => {
                  const alert = getTwinStatus(get(groups, k, []), props.alerts);
                  let ref = null;
                  if (index === 0) {
                    ref = firstTwin;
                  } else if (index === twinKeys.length - 1) {
                    ref = lastTwin;
                  }
                  const buttonStyle = twinsToRight
                    ? {
                        display: "flex",
                        alignItems: "center",
                        margin: "1rem 0",
                        height: 32,
                      }
                    : {};
                  return (
                    <Grid
                      style={buttonStyle}
                      ref={ref}
                      key={k}
                      className={classes.item}
                      item
                    >
                      <TwinButton
                        onClick={() => handleTwinClick(k)}
                        style={{ width: "6.5rem", height: "2rem" }}
                        arrowBottom={selectedTwin === k && !twinsToRight}
                        arrowRight={selectedTwin === k && twinsToRight}
                        alert={!isExternalUser && alert}
                        twinType={k}
                      />
                    </Grid>
                  );
                })
              : "No digital twins"}
          </Grid>
          {selectedTwin && selectedTwin !== "off" ? (
            <div style={{ width: twinsToRight ? '70%' : '100%' }}>
              <DigitalTwinsContainer
                alert={getTwinStatus(get(groups, selectedTwin, []), props.alerts)}
                alerts={props.alerts}
                twins={selectedGroup}
                twinsToRight={twinsToRight}
              />
            </div>
          ) : null}
        </Grid>
      </CardContent>
    </div>
  );
};

export default DigitalTwins;
