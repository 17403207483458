import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const BugForm = ({ open, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <IconButton style={{ marginLeft: "auto" }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <iframe
        title="Feedback form"
        width="800px"
        height="850px"
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=L4Trhe05TkOXVxbWpkbVN6oks9hhtGdPr23nnnVBTKZUQlNUN1g3OVFWTDUwV0Y5VkFZRjVCQzVNWS4u&embed=true"
        frameBorder="0"
        marginWidth="0"
        marginHeight="0"
        style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}
        allowFullScreen
      ></iframe>
    </Dialog>
  );
};
export default BugForm;
