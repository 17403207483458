import React from "react";

import Arrow from "./Arrow";
import InputBox from "./InputBox";
import Tank from "./Tank";

const WaterTank = ({
  arrowHeight,
  arrowOffset,
  extraPumps,
  inputData,
  energyPoints,
  existing = [],
}) => {
  const warmWaterSupply =
    inputData && inputData.find((d) => d.name === "warmWaterSupply");

  const warmWaterReturn =
    inputData && inputData.find((d) => d.name === "warmWaterReturn");

  const warmWaterTank =
    inputData && inputData.find((d) => d.name === "warmWaterTank");

  const warmWaterNetworkSupply =
    inputData && inputData.find((d) => d.name === "warmWaterNetworkSupply");

  const warmWaterNetworkReturn =
    inputData && inputData.find((d) => d.name === "warmWaterNetworkReturn");

  const waterEnergy =
    energyPoints && energyPoints.find((d) => d.name === "waterEnergy");

  const waterResistor =
    energyPoints && energyPoints.find((d) => d.name === "waterResistor");

  const center =
    existing &&
    !existing.includes("heatingEnergy") &&
    !existing.includes("heatingResistor");

  return (
    <>
      <Tank
        height={arrowHeight * 4}
        data={warmWaterTank}
        text="Warm water tank"
        style={{
          left: `calc(100% + ${243}px)`,
          bottom: `${center ? 50 : 20 + extraPumps * 1}%`,
          transform: `translate(0,${50}%)`,
        }}
      >
        {existing && existing.includes("waterEnergy") && (
          <Arrow
            height={arrowHeight}
            width={243}
            top={`${38 + arrowOffset}%`}
            right="100%"
            translateX="0"
            translateY="-50%"
            text="Warm water supply"
            data={warmWaterSupply}
            textLeft={42}
          >
            <InputBox
              style={{
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
              height={arrowHeight + 6}
              backgroundColor="#E9941E"
              data={waterEnergy}
              divider={1000}
              unit="MWh"
            />
          </Arrow>
        )}
        {existing && existing.includes("waterResistor") && (
          <Arrow
            height={arrowHeight}
            width={224}
            top={`-${19 - arrowOffset * 2}%`}
            right="50%"
            translateX={`${arrowHeight / 2}px`}
            translateY="-50%"
            cornerRadius="20%"
            toBottom
            text="Boiler resistor"
            textLeft={42}
          >
            <InputBox
              style={{
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
              height={arrowHeight + 6}
              backgroundColor="#01BD6D"
              data={waterResistor}
              unit="kWh"
            />
          </Arrow>
        )}
        <Arrow
          height={arrowHeight}
          width={243}
          toLeft
          top={`${70 + arrowOffset / 4}%`}
          right="100%"
          translateX="0"
          translateY="-50%"
          text="Warm water return"
          data={warmWaterReturn}
        />
        <Arrow
          height={arrowHeight}
          width={243}
          top={`${38 + arrowOffset}%`}
          left="100%"
          translateX="0"
          translateY="-50%"
          text="Warm water network supply"
          data={warmWaterNetworkSupply}
        />
        <Arrow
          height={arrowHeight}
          width={243}
          toLeft
          top={`${70 + arrowOffset / 4}%`}
          left="100%"
          translateX="0"
          translateY="-50%"
          text="Warm water network return"
          data={warmWaterNetworkReturn}
        />
      </Tank>
    </>
  );
};

export default WaterTank;
