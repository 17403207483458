import graphColor from "../../../utils/graphColor";
import { filter, groupBy, find } from "lodash";
import raReportingDataToHighchartsData from "./raReportingDataToHighChartsData";

const trendMapping = (_trends, resource, type) => {
  let trends = [];
  trends = [..._trends];
  let series = [];
  let filteredTrends = [];

  if (resource === "heating") {
    // Check if trends/HP is version 2 or 1
    let version2 = false;

    trends.forEach((t) => {
      if (
        t.variableName === "BUILDING_HEAT_PUMP_TOTAL_HEATING_ENERGY" ||
        t.variableName === "BUILDING_HEAT_PUMP_TOTAL_COOLING_ENERGY" ||
        t.variableName === "BUILDING_HEAT_PUMP_TOTAL_WATER_ENERGY" ||
        t.variableName ===
          "BUILDING_HEAT_PUMP_TOTAL_WATER_BOILER_RESISTOR_ENERGY" ||
        t.variableName ===
          "BUILDING_HEAT_PUMP_TOTAL_HEATING_BOILER_RESISTOR_ENERGY"
      ) {
        version2 = true;
      }
    });

    if (!version2) {
      filteredTrends = filter(
        trends,
        (y) =>
          y.trendId === "BUILDING_HEAT_PUMP_ENERGY" &&
          (y.legend === "Building produced total energy" ||
            y.legend === "Building total additional energy")
      );
    } else {
      filteredTrends = filter(
        trends,
        (y) =>
          y.trendId === "BUILDING_HEAT_PUMP_ENERGY" &&
          (y.variableName === "BUILDING_HEAT_PUMP_TOTAL_HEATING_ENERGY" ||
            y.variableName === "BUILDING_HEAT_PUMP_TOTAL_WATER_ENERGY" ||
            y.variableName ===
              "BUILDING_HEAT_PUMP_TOTAL_HEATING_BOILER_RESISTOR_ENERGY" ||
            y.variableName ===
              "BUILDING_HEAT_PUMP_TOTAL_WATER_BOILER_RESISTOR_ENERGY")
      );
    }

    if (filteredTrends && filteredTrends.length > 0) {
      const grouped = groupBy(filteredTrends, "metaId");
      let keys = Object.keys(grouped);
      // Reverse order to get boiler resistors on top
      keys = keys.reverse();

      let tempSeries = [];

      keys.forEach((k) => {
        const groupedByYear = groupBy(grouped[k], "year");
        const years = Object.keys(groupedByYear);
        let ind = 0;
        years.forEach((y) => {
          let trendValue = find(filteredTrends, (v) => v.metaId === Number(k));
          // Custom series names
          let name = "";
          if (trendValue.legend === "Building produced total energy") {
            name = "Heat pumps";
          }
          if (trendValue.legend === "Building total additional energy") {
            name = "Boiler resistors";
          }
          if (
            trendValue.variableName ===
            "BUILDING_HEAT_PUMP_TOTAL_HEATING_BOILER_RESISTOR_ENERGY"
          ) {
            name = "Heating boiler resistors";
          }
          if (
            trendValue.variableName ===
            "BUILDING_HEAT_PUMP_TOTAL_WATER_BOILER_RESISTOR_ENERGY"
          ) {
            name = "Water boiler resistors";
          }
          if (
            trendValue.variableName ===
            "BUILDING_HEAT_PUMP_TOTAL_HEATING_ENERGY"
          ) {
            name = "Heat pumps - heating";
          }
          if (
            trendValue.variableName === "BUILDING_HEAT_PUMP_TOTAL_WATER_ENERGY"
          ) {
            name = "Heat pumps - water";
          }

          tempSeries.push({
            name: name + " " + y,
            data: raReportingDataToHighchartsData(groupedByYear[y]),
            stack: parseInt(y),
            visible: true,
            color: graphColor({ index: ind, seriesLength: years.length }),
            showInLegend: false,
            linkedTo: y.toString(),
          });
          ind++;
        });
      });

      // HP water data to end of the series so the stack is at bottom
      let otherSeries = [];

      const waterSeries = tempSeries.filter(
        (t) =>
          typeof t.name === "string" && t.name.includes("Heat pumps - water")
      );

      otherSeries = tempSeries.filter(
        (t) =>
          typeof t.name !== "string" || !t.name.includes("Heat pumps - water")
      );

      // Push the rest of the series
      series.push(...otherSeries);

      // Push the water series
      if (waterSeries && waterSeries.length > 0) {
        series.push(...waterSeries);
      }
    }
  } else if (resource === "electricity") {
    filteredTrends = filter(
      trends,
      (y) => y.trendId === "BUILDING_SOLAR_ENERGY"
    );
    if (filteredTrends && filteredTrends.length > 0) {
      const grouped = groupBy(filteredTrends, "year");
      const keys = Object.keys(grouped);
      keys.forEach((k) => {
        let index = 0;
        series.push({
          name: "Solar " + k,
          data: raReportingDataToHighchartsData(grouped[k], "solar"),
          stack: parseInt(k),
          showInLegend: false,
          linkedTo: k,
          color: graphColor({
            index: index,
            seriesLength: filteredTrends.length,
          }),
        });
        index++;
      });
    }
  } else if (resource === "cooling") {
    filteredTrends = filter(
      trends,
      (y) =>
        y.trendId === "BUILDING_HEAT_PUMP_ENERGY" &&
        y.legend === "Building heat pump total cooling energy"
    );

    if (filteredTrends && filteredTrends.length > 0) {
      const grouped = groupBy(filteredTrends, "year");
      const keys = Object.keys(grouped);
      keys.forEach((k) => {
        let index = 0;
        series.push({
          name: "Heat pumps " + k,
          data: raReportingDataToHighchartsData(grouped[k]),
          stack: parseInt(k),
          showInLegend: false,
          linkedTo: k,
          color: graphColor({
            index: index,
            seriesLength: filteredTrends.length,
          }),
        });
        index++;
      });
    }
  }
  return [series, filteredTrends];
};

export default trendMapping;
