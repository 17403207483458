export const blockTypeInputOptions = [
  {
    id: "AHU",
    name: "Air handling unit"
  },
  {
    id: "Meter",
    name: "Meter"
  },
  {
    id: "HX",
    name: "Heating exchanger"
  },
  {
    id: "HP",
    name: "Heat pump"
  },
  {
    id: "SOLAR",
    name: "Solar"
  },
  {
    id: "ZONE",
    name: "Zone"
  }
];
