import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import graphColor from "../utils/graphColor";
import moment from "moment";

import { get, find, groupBy, map, round, sortBy } from "lodash";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import Boost from "highcharts/modules/boost";
Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (
  dataFromReactAdmin,
  scalingFactor = 1
) => {
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = new Date(v.valueTime);
    return [date.getTime(), round(v.value * scalingFactor, 2)];
  });
  return highChartsData;
};

//TODO timezone
// const getTargetForCurrentTime = (currentTime, targets) => {
//   const dayOfWeek = currentTime.getDay();
//   const currentHour = currentTime.getHours();
//   const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

//   const startKey = weekDays[dayOfWeek] + "Start";
//   const endKey = weekDays[dayOfWeek] + "End";
//   const target = find(targets, t => {
//     const start = get(t, startKey);

//     const end = get(t, endKey);

//     return t.AirFlow > 0 && currentHour >= start && currentHour < end;
//   });

//   if (target) {
//     return target.AirFlow;
//   }

//   return 0;
// };

export default class AhuBlockAirFlowGraph extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);

    this.state = {
      options: this.defaultOptions,
      record: this.props,
      fetchDone: false,
    };
  }
  afterChartCreated(chart) {
    this.internalChart = chart;
  }

  graphOptions = (data) => {
    const grouped = groupBy(data, "metaId");
    let trend = find(
      this.state.record.Trends,
      (y) => y.TrendId === "AHU_AIR_FLOW"
    );

    const keys = Object.keys(grouped);

    const series = map(keys, (k) => {
      let trendValue = find(
        trend.Values,
        (v) => v.ReportingPointId === Number(k)
      );

      const getScalingFactor = (legend) => {
        if (legend.toLowerCase().includes('return air flow')) {
          return this.state.record.Ahu.ReturnAirFlowCalculationMethod === "MeasuredAirFlow"
            ? this.state.record.Ahu.ReturnAirFlow.ScalingFactor
            : 1
        } else if (legend.toLowerCase().includes('supply air flow')) {
          return this.state.record.Ahu.SupplyAirFlowCalculationMethod === "MeasuredAirFlow"
            ? this.state.record.Ahu.SupplyAirFlow.ScalingFactor
            : 1
        } else {
          return 1;
        }
      };

      return {
        name: trendValue.Legend,
        data: sortBy(
          valuesToHighchartsData(
            grouped[k],
            getScalingFactor(trendValue.Legend)
          ),
          0
        ),
        showInNavigator: false,
        dataGrouping: {
          enabled: false,
        },
        boostThreshold: 1,
      };
    });
    const getTargetForCurrentTime = (currentTime, targets) => {
      const dayOfWeek = currentTime.getDay();
      const currentHour = currentTime.getHours();
      const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const startKey = weekDays[dayOfWeek] + "Start";
      const endKey = weekDays[dayOfWeek] + "End";
      const target = find(targets, (t) => {
        const start = get(t, startKey);

        const end = get(t, endKey);

        return t.AirFlow > 0 && currentHour >= start && currentHour < end;
      });

      if (target) {
        return target.AirFlow;
      }

      return 0;
    };
    const flowTargetSeries = map(keys, (k) => {
      const targetData = map(grouped[k], (v) => {
        const currentDate = new Date(v.valueTime);

        return [
          currentDate.getTime(),
          getTargetForCurrentTime(
            currentDate,
            this.state.record.Ahu.AirFlowTargets
          ),
        ];
      });
      return {
        type: "line",
        name: "air flow target",
        data: sortBy(targetData, 0),
        dataGrouping: {
          enabled: false,
        },
        showInNavigator: false,
        boostThreshold: 1,
        color: graphColor({ index: 0, seriesLength: 1, lineSeries: true }),
      };
    });

    let allSeries = [];
    allSeries = series.concat(flowTargetSeries);
    return allSeries;
  };

  componentDidMount() {
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        naviSeries: findMetaIds(this.props.Trends, "AHU_AIR_FLOW"),
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props.Trends, "AHU_AIR_FLOW"),
        trend: "AHU_AIR_FLOW",
        dataBegin: moment.utc().subtract(7, "days").format(),
        dataEnd: moment.utc().format(),
      },
      sort: { field: "Date", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        this.setState({
          options: {
            series: this.graphOptions(response.values),
            navigator: {
              series: {
                data: valuesToHighchartsData(
                  response.naviSeries ? response.naviSeries.Values : []
                ),
              },
            },
          },
          fetchDone: true,
        });
      });
  }

  defaultOptions = {
    chart: {
      type: "column",
      zoomType: "x",
    },
    legend: {
      enabled: true,
    },
    title: {
      text: "",
    },
    scrollbar: {
      liveRedraw: false,
      adaptToUpdatedData: false,
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: true,
    },
    tooltip: {
      valueDecimals: 2,
      snap: 50,
    },
    xAxis: {
      events: {
        afterSetExtremes: this.afterSetExtremes.bind(this),
      },
      type: "datetime",
      minRange: 24 * 3600 * 1000, // one day,
    },
    time: {
      timezone: this.props.timezone,
    },
    yAxis: [
      {
        title: {
          text: "m³/s",
        },
        min: 0,
      },
    ],
    rangeSelector: {
      // can't make selected work here. no idea why.
      // selected: 1,
      buttons: [
        {
          type: "day",
          count: 1,
          text: "1d",
        },

        {
          type: "week",
          count: 1,
          text: "1 wk",
        },

        {
          type: "month",
          count: 1,
          text: "1m",
        },
      ],
    },
    series: [],
  };

  afterSetExtremes(e) {
    // const chart = this.internalChart;
    if (e.trigger && !isNaN(e.min) && !isNaN(e.max)) {
      const startISO = new Date(e.min).toISOString();
      const endISO = new Date(e.max).toISOString();

      restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          buildingId: this.props.BuildingId,
          metaIds: findMetaIds(this.props.Trends, "AHU_AIR_FLOW"),
          dataBegin: startISO,
          dataEnd: endISO,
          trend: "AHU_AIR_FLOW",
        },
        sort: { field: "Date", order: "ASC" },
        pagination: {},
      })
        .then((response) => response.data)
        .then((response) => {
          this.setState({
            options: {
              series: this.graphOptions(response.values),
            },
          });
        });
    }
  }

  render() {
    let showData = true;
    if (
      this.state.fetchDone &&
      (!this.state.options ||
        !this.state.options.series ||
        this.state.options.series.length === 0)
    ) {
      showData = false;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.fetchDone
    ) {
      if (showData) {
        this.props.showContainer("flow");
      } else {
        this.props.hideContainer("flow");
      }
    }

    return (
      <CardWithTitle title={"Air flow measurement"}>
        {showData ? (
          <HighchartsReact
            key={"afChart"}
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={this.state.options}
            callback={this.afterChartCreated}
          />
        ) : (
          <div style={{ height: "20rem" }}>
            <EmptyData />
          </div>
        )}
      </CardWithTitle>
    );
  }
}
