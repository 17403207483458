import React from "react";
import { Show } from "react-admin";
import { ValueStorageGraph } from "./ValueStorageGraph";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";

const ValueStorageShow = ({ BuildingId, projectname, ...props }) => {
  // Delete unnecessary props
  let showProps = Object.assign({}, props);
  delete showProps.buildingTwinId;
  delete showProps.buildingSelected;
  delete showProps.dispatch;
  return (
    <>
      <PageTitle title="Valuestorage Graph" />
      <Show {...showProps}>
        <ValueStorageGraph />
      </Show>
    </>
  );
};

export default stateMapper(ValueStorageShow);
