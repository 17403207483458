import React, { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useSelector } from "react-redux";
import { get, find, isNil, last } from "lodash";
import { AhuSvgWrapper } from './ahu/ahu-svg/AhuSvgWrapper';
import { AhuBlock, Building, BuildingBlock, HeatRecoveryType, ValueStorage, AirFlowCalculationMethod } from '../types/leasegreen-core-api-exports';
import { IRootState } from '../types/root-state';
import { toBuildingTime } from '../utils/twinUtils';
import AhuZones from './AhuZones';
import { UnderlinedInput } from './hp/Inputs/UnderlinedInput';

interface ConfigAhuFields {
  title: string;
  variableName: (keyof AhuBlock);
  busTagName?: string;
  unit: string;
  mapValueFn?: Function;
}

const mapToPrecision = (precision: number) => (value: number) => Number(value).toFixed(precision);
const mapOnOffValues = (value: number) => !!value ? 'ON' : 'OFF';

/**
 * In some situations airflow may be returned in different unit. This is a legacy way of checking it.
 */
const getAirFlowUnit = (scalingFactor: number, calculationMethod: AirFlowCalculationMethod) =>
  calculationMethod === 'MeasuredAirFlow' && scalingFactor === 0.001 ? 'l/s' : 'm³/s';

export interface AhuTwinPropertyValue {
  title: string;
  fieldKey: string;
  variableName: keyof AhuBlock | string;
  timestamp: string;
  data: number | string;
  unit: string;
}

const AhuTwinInfo = (props: BuildingBlock) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [ahuData, setAhuData] = useState<AhuTwinPropertyValue[]>([]);

  const [latestFunctionVariableValues, setLatestFunctionVariableValues] = useState<AhuTwinPropertyValue[]>([]);

  const buildingData = useSelector<IRootState, Building>(state => state.buildingFilter.buildingData);

  const allTwins = useSelector<IRootState, BuildingBlock[]>(state => state.buildingFilter.allTwins);

  const temperatureData = useSelector<IRootState, any>(state => state.buildingFilter.buildingTemp);



  useEffect(() => {

    // If airflow is calculated, get it from FUNCTION, else get from RAU
    const isMeasuredAirflow = props.Ahu.SupplyAirFlowCalculationMethod as unknown as string === 'MeasuredAirFlow'
      || isNil(props.Ahu.SupplyAirFlowCalculationMethod)

    const usedAhuFields: ConfigAhuFields[] = [
      { title: 'Temp. before HR', unit: '°C', variableName: 'BeforeHRTE', mapValueFn: mapToPrecision(1) },
      { title: 'Temp. after HR', unit: '°C', variableName: 'AfterHRTE', mapValueFn: mapToPrecision(1) },
      { title: 'Temp. after radiator', unit: '°C', variableName: 'TEBetweenHeatingAndCoolingCoilInput', mapValueFn: mapToPrecision(1) },
      { title: 'Supply air temp.', unit: '°C', variableName: 'SupplyAirTE', mapValueFn: mapToPrecision(1) },
      { title: 'Return air temp.', unit: '°C', variableName: 'ReturnAirTe', mapValueFn: mapToPrecision(1) },
      { title: 'Exhaust air temp.', unit: '°C', variableName: 'ExhaustAirTE', mapValueFn: mapToPrecision(1) },
      { title: '', unit: '', variableName: 'SupplyStatusInput', mapValueFn: mapOnOffValues },
      { title: '', unit: '', variableName: 'ReturnStatusInput', mapValueFn: mapOnOffValues },
      { title: 'Outdoor temp.', unit: '°C', variableName: 'OutdoorTE', mapValueFn: mapToPrecision(1) },
      { title: 'HR request', unit: '%', variableName: 'HeatRecoveryControlInput', mapValueFn: mapToPrecision(2) },
      { title: 'Supply air pressure', unit: 'Pa', variableName: 'SupplyAirPressureInput', mapValueFn: mapToPrecision(2) },
      { title: 'Supply air control', unit: '%', variableName: 'SupplyAirControlInput', mapValueFn: mapToPrecision(2) },
      { title: 'Return air pressure', unit: 'Pa', variableName: 'ReturnAirPressureInput', mapValueFn: mapToPrecision(2) },
      { title: 'Return air control', unit: '%', variableName: 'ReturnAirControlInput', mapValueFn: mapToPrecision(2) },
      ...(isMeasuredAirflow
        ? [
          { title: 'Air flow supply', unit: getAirFlowUnit(props.Ahu.SupplyAirFlow.ScalingFactor, props.Ahu.SupplyAirFlowCalculationMethod), variableName: 'SupplyAirFlow', mapValueFn: mapToPrecision(1) },
          { title: 'Air flow return', unit: getAirFlowUnit(props.Ahu.SupplyAirFlow.ScalingFactor, props.Ahu.SupplyAirFlowCalculationMethod), variableName: 'ReturnAirFlow', mapValueFn: mapToPrecision(1) }
        ] as ConfigAhuFields[]
        : [])
    ];

    const resolveVariables = (ahu: AhuBlock) => {
      const fields = usedAhuFields // Fix cast to any by narrowing typing
        .filter(field => !!(ahu[field.variableName] as any)?.BusTagName)
        .map(field => {
          const busTagName = (ahu[field.variableName] as any)?.BusTagName;
          const scalingFactor = (ahu[field.variableName] as any)?.ScalingFactor;
          return {
            ...field,
            busTagName,
            scalingFactor: !isNil(scalingFactor) ? scalingFactor : 1
          };
        });
      return fields;
    };

    const now = new Date();
    // Get RAU data
    if (!!props.Ahu) {
      const fields = resolveVariables(props.Ahu);
      const busTagNames = fields.map(f => f.busTagName);
      dataProvider
        .getList<ValueStorage[] & { id: string }>("valuestorage", {
          filter: {
            BuildingId: props.BuildingId,
            VariableNames: busTagNames,
            DataType: "RAU",
            ValuesFrom: new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              now.getHours() - 1,
              now.getMinutes(),
              now.getSeconds()
            ).toISOString(),
            ValuesTo: now.toISOString(),
          },
          sort: { field: "UpdateTime", order: "DESC" },
          pagination: {} as any,
        })
        .then(({ data }: { data: ValueStorage[] }) => {
          if (data.length > 0) {
            const latestValues: AhuTwinPropertyValue[] = fields
              .map(field => {
                const latestMeasurement = find(data, v => v.VariableName === field.busTagName);
                const value = latestMeasurement?.AggregatedValue;
                const transformedValue = field.mapValueFn && !isNil(value) ? field.mapValueFn(value) : value;
                return {
                  title: field.title,
                  fieldKey: field.variableName,
                  variableName: field.variableName,
                  timestamp: toBuildingTime(
                    latestMeasurement?.UpdateTime,
                    get(buildingData, "timeZoneIANA", null)
                  ),
                  data: transformedValue,
                  unit: field.unit,
                };
              });
            setAhuData(latestValues);
          }
        })
        .catch((error: any) => {
          notify(error.message, "warning");
        });
    }

    const functionTypeDataFields = [
      { title: 'Heating power', fieldKey: 'HEATING_POWER', unit: 'kW', variableName: props.BlockName + "_HEATING_POWER", mapValueFn: mapToPrecision(2) },
      { title: 'Cooling power', fieldKey: 'COOLING_POWER', unit: 'kW', variableName: props.BlockName + "_COOLING_POWER", mapValueFn: mapToPrecision(2) },
      { title: 'Total heating energy', fieldKey: 'TOTAL_HEATING_ENERGY', unit: 'kWh', variableName: props.BlockName + "_TOTAL_HEATING_ENERGY", mapValueFn: (value: number) => mapToPrecision(0)(value * (1 / 3600)) },
      { title: 'Total cooling energy', fieldKey: 'TOTAL_COOLING_ENERGY', unit: 'kWh', variableName: props.BlockName + "_TOTAL_COOLING_ENERGY", mapValueFn: (value: number) => mapToPrecision(0)(value * (1 / 3600)) },
      { title: 'HR efficiency', fieldKey: 'HR_EFFICIENCY', unit: '%', variableName: props.BlockName + "_HR_EFFICIENCY", mapValueFn: mapToPrecision(2) },
      ...(!isMeasuredAirflow
        ? [
          { title: 'Air flow supply', unit: 'm³/s', fieldKey: 'SupplyAirFlow', variableName: props.BlockName + "_SUPPLY_AIR_FLOW", mapValueFn: mapToPrecision(1) },
          { title: 'Air flow return', unit: 'm³/s', fieldKey: 'ReturnAirFlow', variableName: props.BlockName + "_RETURN_AIR_FLOW", mapValueFn: mapToPrecision(1) }
        ]
        : [])
    ];

    // Get heating power
    dataProvider
      .getList<ValueStorage & { id: number }>("valuestorage", {
        filter: {
          BuildingId: props.BuildingId,
          VariableNames: functionTypeDataFields.map(f => f.variableName),
          DataType: "FUNCTION",
          ValuesFrom: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours() - 2,
            now.getMinutes(),
            now.getSeconds()
          ).toISOString(),
          ValuesTo: now.toISOString(),
        },
        sort: { field: "UpdateTime", order: "DESC" },
        pagination: {} as any
      })
      .then(({ data }) => {
        if (data.length > 0) {
          const latestValues = functionTypeDataFields
            .map(field => {
              const latestValue = find(data, v => v.VariableName === field.variableName);
              const value = latestValue?.AggregatedValue;
              const transformedValue = field.mapValueFn && !isNil(value) ? field.mapValueFn(value) : value;
              return {
                title: field.title,
                fieldKey: field.fieldKey,
                variableName: field.variableName,
                timestamp: toBuildingTime(
                  latestValue?.UpdateTime,
                  get(buildingData, "timeZoneIANA", null)
                ),
                data: transformedValue || null,
                unit: field.unit,
              };
            })
            .filter(field => !isNil(field.data));
          setLatestFunctionVariableValues(latestValues);
        }
      })
      .catch((error: Error) => {
        console.error('Fetching AHU valuestorage threw an error', error);
        notify(error.message, "warning");
      });
  }, [
    dataProvider,
    props.Ahu,
    props.BuildingId,
    notify,
    props.BlockName,
    buildingData
  ]);

  const hrType: HeatRecoveryType = get(props, "Ahu.HeatRecoveryType", null);

  // Zone twins for ahu map
  let zoneTwins: BuildingBlock[] = [];

  if (allTwins && allTwins.length > 0 && get(props, "Ahu.ZoneBlockIds", null)) {
    const zones = get(props, "Ahu.ZoneBlockIds", null);
    if (zones && zones.length > 0) {
      zones.forEach((z: any) => {
        const twin = allTwins.find((t: any) => t.id === z);
        if (twin) {
          zoneTwins.push(twin);
        }
      });
    }
  }

  const outdoorTemp = ahuData.find(v => v.fieldKey === 'OutdoorTE');
  const tempFromTwin: any = temperatureData?.length > 0 ? last(temperatureData) : null;
  const outdoorData = tempFromTwin ? tempFromTwin.value : !isNil(outdoorTemp?.data) ? (outdoorTemp.data as number).toFixed(1) : null;

  return <div>
    {!isNil(outdoorData) && <UnderlinedInput value={outdoorData} unit={'°C'} description={'Outdoor temp.'} />}
    <div style={{
      display: 'flex', height: '700px', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'center'
    }}>
      <div style={{
        width: '12%', alignSelf: 'center', marginTop: '3.5%', height: '65%'
      }}>
        <AhuZones ahuMap zones={zoneTwins} />
      </div>
      <div style={{ width: '60%', marginTop: '1em', marginRight: hrType === 'CUBE_HR' ? '0' : '-0.6%' }}>
        <AhuSvgWrapper
          twinData={[
            ...ahuData,
            ...latestFunctionVariableValues,
          ]}
          heatRecoveryType={hrType}></AhuSvgWrapper>
      </div>
    </div>
  </div>
};

export default AhuTwinInfo;
