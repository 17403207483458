import { get, round, filter, groupBy } from "lodash";
import * as moment from "moment";
import feedbackIcons from "../utils/feedbackIcons";
import operationLogIcon from "../utils/operationLogIcon";
import { find } from "highcharts";

// Takes the data react-admin provides, along with the info of which fields of the object are to used as time and value. Returns a series of time-value pairs.

export const raDataToHighcharts = (dataFromReactAdmin, time, value) => {
  const highChartsData = dataFromReactAdmin.map((v) => {
    const date = new Date(get(v, time));
    return [Date.parse(date), get(v, value)];
  });
  return highChartsData;
};

// Requires the monthly consumption data array, type (heating, electricity, water), value (Consumption, ConsNorm) and year props.
// Edited due to changes in backend.
// Purchased consumption data can be determined if the source of the consumption is either metry or manual

export const raConsumptionToHighchartsData = (
  dataFromReactAdmin,
  type, //type could be electricity, cooling or heating...
  value, //value could be for example consNorm, consumption...
  year,
  allCons
) => {
  const highChartsData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const filteredData = filter(
    dataFromReactAdmin,
    (d) => new Date(d.Time).getFullYear() === year && d.Type === type
  );

  const groupedByMonth = groupBy(filteredData, (d) =>
    new Date(d.Time).getMonth()
  );

  filteredData.forEach((v) => {
    const time = new Date(v.Time);
    if (!allCons) {
      // Just get the purchased (manual or metry)
      return v.Source.toLowerCase() === "manual" ||
        v.Source.toLowerCase() === "metry"
        ? (highChartsData[time.getMonth()] = round(get(v, value), 2))
        : null;
    } else {
      // Get all consumption, (manual & hp / metry & hp OR just manual if no hp exists)
      // Check if HP data from that month exists
      let hpDataExists = false;
      if (
        groupedByMonth &&
        groupedByMonth[time.getMonth()] &&
        groupedByMonth[time.getMonth()].length > 0
      ) {
        groupedByMonth[time.getMonth()].forEach((d) => {
          if (d.Source.toLowerCase().includes("hp total")) {
            hpDataExists = true;
          }
        });
        // If HP data exists, add it to the consumption data,
        // else just add the manual/metry
        groupedByMonth[time.getMonth()].forEach((d) => {
          if (hpDataExists) {
            if (
              d.Source.toLowerCase() === "manual & hp total" ||
              d.Source.toLowerCase() === "metry & hp total" ||
              d.Source.toLowerCase() === "hp total"
            ) {
              const valueToWrite = round(get(d, value), 2);
              if (valueToWrite !== 0) {
                highChartsData[time.getMonth()] = round(get(d, value), 2);
              }
            }
          } else {
            if (
              d.Source.toLowerCase() === "manual" ||
              d.Source.toLowerCase() === "metry"
            ) {
              highChartsData[time.getMonth()] = round(get(d, value), 2);
            }
          }
        });
      }
    }
  });

  return highChartsData;
};

export const addTemperaturesToConsumptionData = (
  consumptions,
  temperatures
) => {
  // let t7 = performance.now();
  // Convert the temperature valueTimes into moment objects and set the time to be the start of the next hour
  // conversion takes 1,5-2,5sec for ~40k values, gotta figure out how to do it faster
  const convertedTemperatures = temperatures.map((temperature) => {
    const timeMoment = moment
      .utc(temperature.valueTime)
      .startOf("hour")
      .add(1, "hours");
    temperature.valueTime = timeMoment;
    return temperature;
  });
  // let t8 = performance.now();
  //console.log(`time taken to convert to moment is ${t8 - t7}`);

  var hourlyTemperatures = {};
  var newTemp = convertedTemperatures[0];
  var count = 1;

  // Create an object with temperature values that have the time string as a key and the average of temperatures for that hour as the value.
  // The list is assumed to be ordered by valueTime.
  const convertTemperaturesToHourlyAverages = (item) => {
    if (newTemp.valueTime.isSame(item.valueTime)) {
      newTemp.value = newTemp.value + item.value;
      count += 1;
    } else {
      newTemp.value = newTemp.value / count;
      hourlyTemperatures[item.valueTime.format("YYYY-MM-DDTHH:mm:ss")] =
        newTemp;
      newTemp = item;
      count = 1;
    }
  };

  // let t5 = performance.now();
  //forEach was slower and this lets skip the first item, which is nice. takes 100-200ms
  for (var i = 1, len = convertedTemperatures.length; i < len; i++) {
    var item = convertedTemperatures[i];
    convertTemperaturesToHourlyAverages(item);
  }
  // let t6 = performance.now();
  //console.log(`time taken to count the averages with for loop is ${t6 - t5}`);

  // Add the temperature value of the matching times to the consumptions array
  const consumptionsWithTemperatures = consumptions.map((consumption) => {
    const temperature = hourlyTemperatures[consumption.Time];
    temperature
      ? (consumption.Temperature = temperature.value)
      : (consumption.Temperature = null);
    return consumption;
  });

  return consumptionsWithTemperatures;
};

//needs the consumptions that are mapped with the above function to include temperatures
export const raConsumptionsAndTemperaturesForScatterplot = (
  consumptions,
  year
) => {
  const yearlyConsumptions = consumptions.filter((consumption) => {
    return consumption.Time.slice(0, 4) === year;
  });

  var highChartsData = [];
  // Added third element to the array, month of the data, used on the regression line calculations
  yearlyConsumptions.forEach((consumption) => {
    if (consumption.Temperature !== null) {
      highChartsData.push([
        consumption.Temperature,
        consumption.Power,
        new Date(consumption.Time).getMonth() + 1,
      ]);
    }
  });

  return highChartsData;
};

// Building (outside) temperatures are used to get monthly averages
export const buildingTempToChart = (temperatureData, startDate, endDate) => {
  // let t1 = performance.now();
  let allYears = [];
  let oneYear = [];
  let oneMonth = {
    sum: 0.0,
    count: 0,
    year: "",
  };

  let currentYear = temperatureData[0].valueTime.slice(0, 4);
  let currentMonth = temperatureData[0].valueTime.slice(5, 7);
  let prevYear = temperatureData[0].valueTime.slice(0, 4);
  let prevMonth = temperatureData[0].valueTime.slice(5, 7);
  // Cycle through the temperature data and create the arrays for avg calculations
  for (let i = 0; i < temperatureData.length; i++) {
    // Only use temperatures that are taken during the consumption dates
    if (
      new Date(temperatureData[i].valueTime) >= startDate &&
      new Date(temperatureData[i].valueTime) <= endDate
    ) {
      currentYear = temperatureData[i].valueTime.slice(0, 4);
      currentMonth = temperatureData[i].valueTime.slice(5, 7);
      if (currentMonth === prevMonth) {
        oneMonth = {
          sum: oneMonth.sum + temperatureData[i].value,
          count: oneMonth.count + 1,
          year: currentYear,
          month: currentMonth,
        };
        prevMonth = temperatureData[i].valueTime.slice(5, 7);
      } else if (
        parseInt(currentMonth) > parseInt(prevMonth) ||
        (currentMonth === "01" && prevMonth === "12")
      ) {
        oneYear.push(oneMonth);
        oneMonth = {
          sum: temperatureData[i].value,
          count: 1,
          year: currentYear,
          month: temperatureData[i].valueTime.slice(5, 7),
        };
        prevMonth = temperatureData[i].valueTime.slice(5, 7);
      }
      if (currentYear === prevYear) {
        prevYear = temperatureData[i].valueTime.slice(0, 4);
      } else if (parseInt(currentYear) > parseInt(prevYear)) {
        allYears.push(oneYear);
        oneYear = [];
        prevYear = temperatureData[i].valueTime.slice(0, 4);
      }
    }
  }
  // Finally push the last month and year
  oneYear.push(oneMonth);
  allYears.push(oneYear);

  // Then calculate monthly averages and push the values to a data array for the charts
  let dataSeries = [];
  let temperatureArr = [];
  for (let i = 0; i < allYears.length; i++) {
    for (let ind = 0; ind < allYears[i].length; ind++) {
      let avg = (allYears[i][ind].sum / allYears[i][ind].count).toFixed(1);
      let year = allYears[i][ind].year;
      let month = allYears[i][ind].month;
      dataSeries.push({ val: parseFloat(avg), year: year, month: month });
    }
    temperatureArr.push(dataSeries);
    dataSeries = [];
  }
  // let t2 = performance.now();
  // console.log(
  //   "Time taken to get monthly averages for temperature: " + (t2 - t1)
  // );
  return temperatureArr;
};

export const createFeedbackSeries = ({
  feedbackSeries,
  chart,
  allSeries,
  referenceSeriesName,
}) => {
  // Only add feedbacks if chart is not boosted
  if (chart && chart.isBoosting) {
    return null;
  }
  let fbData = [];
  // Only add entries that are between the dates of the reference series
  if (allSeries && allSeries.length > 0) {
    const refSeries = find(allSeries, (s) => s.name === referenceSeriesName);
    const startDate = refSeries.data[0][0];
    const endDate = refSeries.data[refSeries.data.length - 1][0];
    feedbackSeries.data.forEach((d) => {
      if (d.x > startDate && d.x < endDate) {
        fbData.push({
          x: d.x,
          y: 1,
          text: d.text,
          score: d.score,
        });
      }
    });
  }
  return fbData;
};

export const updateFeedbackIcons = (chart) => {
  let points = null;
  chart.series.forEach((s) => {
    if (s.name === "Feedbacks") {
      points = s.points;
    }
  });

  if (points && points.length > 0) {
    points.forEach((p) => {
      if (p) {
        if (p.score === "Good") {
          p.update({
            marker: {
              symbol: "url(" + feedbackIcons.good + ")",
            },
          });
        } else if (p.score === "Neutral") {
          p.update({
            marker: {
              symbol: "url(" + feedbackIcons.neutral + ")",
            },
          });
        } else if (p.score === "Bad") {
          p.update({
            marker: {
              symbol: "url(" + feedbackIcons.bad + ")",
            },
          });
        } else if (p.score === "None") {
          p.update({
            marker: {
              symbol: "url(" + feedbackIcons.none + ")",
            },
          });
        }
      }
    });
  }
};

export const createOperationSeries = ({
  operationSeries,
  chart,
  allSeries,
  referenceSeriesName,
}) => {
  // Only add operations if chart is not boosted
  if (chart && chart.isBoosting) {
    return null;
  }
  let opData = [];
  // Only add entries that are between the dates of the reference series
  if (allSeries && allSeries.length > 0) {
    const refSeries = find(allSeries, (s) => s.name === referenceSeriesName);
    const startDate = refSeries.data[0][0];
    const endDate = refSeries.data[refSeries.data.length - 1][0];
    operationSeries.data.forEach((d) => {
      if (d.x > startDate && d.x < endDate) {
        opData.push({
          x: d.x,
          y: 0.5,
          text: d.text,
        });
      }
    });
  }

  return opData;
};

export const updateOperationIcons = (chart) => {
  let points = null;
  chart.series.forEach((s) => {
    if (s.name === "Operations") {
      points = s.points;
    }
  });

  if (points && points.length > 0) {
    points.forEach((p) => {
      if (p) {
        p.update({
          marker: {
            symbol: "url(" + operationLogIcon + ")",
          },
        });
      }
    });
  }
};

export const tooltipFormatter = (current, Highcharts, tooltip) => {
  const dateText = tooltip.defaultFormatter.call(current, tooltip)[0];
  return [dateText].concat(
    current.points
      ? current.points.map(function (p) {
          return p.point.text
            ? `<span style="color:${p.color}">●</span> ${p.point.text}<br/>`
            : `<span style="color:${p.color}">●</span> ${
                p.series.name
              }: <b>${round(p.y, 2)}</b><br/>`;
        })
      : []
  );
};
