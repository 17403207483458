import {
  // find,
  groupBy,
  filter,
  sum,
  // get,
  // first,
} from "lodash";
import raReportingDataToHighchartsData from "../consumed/raReportingDataToHighChartsData";
import graphColor from "../../../utils/graphColor";

const graphOptionsCooling = (buildingTrends, hpData) => {
  let data = [];
  if (hpData) {
    let k = Object.keys(hpData);
    k.forEach((k) => {
      if (k !== "trendData") {
        data.push(hpData[k]);
      }
    });
  }

  const producedCoolingSeriesName = "BUILDING_HEAT_PUMP_TOTAL_COOLING_ENERGY";
  // Target for produced cooling?
  // const targetData = get(first(data), "Hp.MonthlyTargets", null);

  const filteredTrends = filter(
    buildingTrends,
    (t) =>
      t.trendId === "BUILDING_HEAT_PUMP_ENERGY" &&
      t.variableName === producedCoolingSeriesName
  );

  const grouped = groupBy(filteredTrends, "metaId");
  const keys = Object.keys(grouped);
  const series = [];

  let years = null;
  keys.forEach((k) => {
    const groupedByYear = groupBy(grouped[k], "year");
    years = Object.keys(groupedByYear);
    let index = 0;
    years.forEach((y) => {
      // let trendValue = find(filteredTrends, (v) => v.metaId === Number(k));
      const seriesData = raReportingDataToHighchartsData(groupedByYear[y]);
      if (sum(seriesData) > 0) {
        series.push({
          name: y,
          opacity: 1,
          stack: parseInt(y),
          data: seriesData,
          type: "column",
          color: graphColor({
            index: index,
            seriesLength: years.length,
            type: "cooling",
          }),
        });
        index += 1;
      }
    });
  });

  // TARGET FOR COOLING NOT SPECIFIED YET
  // Target data
  // if (sum(targets) > 0) {
  //   series.push({
  //     name: "Monthly target",
  //     data: targets,
  //     type: "line",
  //     color: graphColor({
  //       index: 0,
  //       seriesLength: 1,
  //       lineSeries: true,
  //       type: "electricity",
  //     }),
  //   });
  // }

  if (!series || series.length === 0) {
    return [];
  }

  return {
    chart: {
      type: "column",
    },
    legend: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: "Heat pumps cooling energy",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: series,
    yAxis: {
      min: 0,
      title: {
        text: "Cooling energy (MWh)",
      },
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
  };
};

export default graphOptionsCooling;
