const handleTemperatureClick = (series, seriesName = "Temperature ") => {
  // When the group is clicked, only show temperatures that have a visible consumption year
  const allSeries = series.chart.series;
  if (series.visible) {
    series.hide();
    for (let i = 0; i < allSeries.length; i++) {
      let tempNameStart = allSeries[i].name
        .toString()
        .slice(0, seriesName.length);
      if (tempNameStart === seriesName) {
        allSeries[i].hide();
      }
    }
  } else {
    series.show();
    for (let i = 0; i < allSeries.length; i++) {
      let tempNameStart = allSeries[i].name
        .toString()
        .slice(0, seriesName.length);
      let tempNameEnd = allSeries[i].name
        .toString()
        .slice(seriesName.length, seriesName.length + 5);
      if (tempNameStart === seriesName) {
        // Find the corresponding consumption series
        for (let ind = 0; ind < allSeries.length; ind++) {
          if (
            allSeries[ind].name.toString() === tempNameEnd &&
            allSeries[ind].visible
          ) {
            allSeries[i].show();
          }
        }
      }
    }
  }
};

export default handleTemperatureClick;
