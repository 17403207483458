import { groupBy, isNil, first, find, filter, round, sum } from "lodash";
import graphColor from "../../../utils/graphColor";
import raReportingDataToHighchartsData from "./raReportingDataToHighChartsData";

const mapElectricityByLoad = (_series, _hpData) => {
  let electricityLoadSeries = [];
  let totalSeries = [];
  let sumSeries = [];
  let otherSeries = [];
  let series = [];
  let hpData = [];
  let hpSeries = [];
  if (_hpData && _hpData.trendData && _hpData.trendData.length > 0) {
    hpData = [..._hpData.trendData];
  }
  if (_series && _series.length > 0) {
    series = [..._series];
  }
  // Calculate the sums of the previous graph data (stacks)
  let seriesGrouped = groupBy(series, "stack");
  let seriesKeys = Object.keys(seriesGrouped);
  seriesKeys.forEach((k) => {
    if (k !== "undefined") {
      let oneYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (seriesGrouped[k] && seriesGrouped[k].length > 0) {
        seriesGrouped[k].forEach((s) => {
          if (!isNil(s.data) && s.data.length > 0) {
            for (let i = 0; i < s.data.length; i++) {
              // Sum the data into one year
              if (!isNil(first(s.data[i]))) {
                oneYear[i] += first(s.data[i]);
              } else if (!isNil(s.data[i])) {
                oneYear[i] += s.data[i];
              }
            }
          }
        });
      }
      oneYear = oneYear.map((o) => round(o, 2));
      sumSeries.push({
        year: k,
        data: oneYear,
      });
    }
  });

  // Then subtract the hp consumptions from the sums to form the "other" data stack
  // First form the hpData for the graph
  if (hpData?.length > 0) {
    const trendData = [...hpData];
    let filteredTrends = filter(
      trendData,
      (y) => {
        return y.trendId === "ENERGY_MONTHLY" &&
          (y.legend.includes("total consumed energy") ||
            y.legend.includes("total heating boiler resistor energy") ||
            y.legend.includes("total water boiler resistor energy"))
      }
    );
    if (filteredTrends?.length > 0) {
      // First add type to data
      filteredTrends.forEach((t) => {
        if (t.legend.includes("total consumed energy")) {
          t.type = "cons";
        } else if (t.legend.includes("total heating boiler resistor energy")) {
          t.type = "heat";
        } else if (t.legend.includes("total water boiler resistor energy")) {
          t.type = "water";
        }
      });

      const grouped = groupBy(filteredTrends, "type");
      let keys = Object.keys(grouped);
      // Reverse order to get boiler resistors on top
      keys = keys.reverse();

      keys.forEach((k) => {
        const groupedByYear = groupBy(grouped[k], "year");
        const years = Object.keys(groupedByYear);
        let ind = 0;
        years.forEach((y) => {
          let trendValue = find(filteredTrends, (v) => v.type === k);
          // Custom series names
          let name = "";
          if (trendValue.legend.includes("total consumed energy")) {
            name = "HP consumption";
          } else if (trendValue.legend.includes("total heating boiler resistor energy")) {
            name = "Heating boiler resistors";
          } else if (trendValue.legend.includes("total water boiler resistor energy")) {
            name = "Water boiler resistors";
          }
          const seriesData = raReportingDataToHighchartsData(
            groupedByYear[y],
            "hp"
          );
          if (sum(seriesData) > 0) {
            hpSeries.push({
              name: name + " " + y,
              data: seriesData,
              stack: parseInt(y),
              visible: true,
              color: graphColor({ index: ind, seriesLength: years.length }),
              showInLegend: false,
              linkedTo: y.toString(),
            });
          }
          ind++;
        });
      });
    }

    // Form the "other" stack
    let ind = 0;
    sumSeries.forEach((s) => {
      let oneYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // Copy the s.data array to oneYear
      oneYear = [...s.data];
      // Find the corresponding hp series
      hpSeries.forEach((h) => {
        if (parseInt(h.stack) === parseInt(s.year)) {
          // Subtrach the hp data from the total (sum) data to form the other data
          for (let i = 0; i < h.data.length; i++) {
            oneYear[i] -= h.data[i];
            // If the value is <= 0 , set it to null
            if (oneYear[i] <= 0) {
              oneYear[i] = null;
            }
          }
        }
      });
      oneYear = oneYear.map((o) => (!o ? null : round(o, 2)));
      otherSeries.push({
        name: "Other " + s.year,
        data: oneYear,
        stack: parseInt(s.year),
        linkedTo: s.year,
        color: graphColor({ index: ind, seriesLength: sumSeries.length }),
        showInLegend: false,
      });
      ind++;
    });
    electricityLoadSeries.push(...hpSeries, ...otherSeries);
    // If hpData does not exist, just show the total energy instead of hp + other
  } else {
    let ind = 0;
    sumSeries.forEach((s) => {
      let oneYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // Copy the s.data array to oneYear
      oneYear = [...s.data];
      totalSeries.push({
        name: "Total " + s.year,
        data: oneYear,
        stack: parseInt(s.year),
        linkedTo: s.year,
        color: graphColor({ index: ind, seriesLength: sumSeries.length }),
        showInLegend: false,
      });
      ind++;
    });
    electricityLoadSeries.push(...totalSeries);
  }
  const finalSeries = electricityLoadSeries;
  return finalSeries;
};

export default mapElectricityByLoad;
