import React from "react";

import { Typography, makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    borderRadius: "50%",
    border: (props) => (props.noBorder ? undefined : "1px solid #282331"),
    backgroundColor: "#fff",
    height: (props) => (props.size ? props.size : 60),
    width: (props) => (props.size ? props.size : 60),
    zIndex: 10,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  data: {
    fontSize: "1rem",
    fontWeight: 600,
    marginRight: (props) => (props.noBorder ? 5 : undefined),
  },
  unit: {
    marginLeft: "0rem",
    fontSize: "0.7rem",
    fontWeight: 600,
  },
  noData: {
    opacity: 0.6,
  },
  tooltip: {
    margin: 0,
  },
  title: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 350,
    color: (props) => (props.titleColor ? props.titleColor : "#858585"),
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 350,
    color: "#858585",
    marginBottom: 7,
  },
});

const TwinInputStatus = (props) => {
  const classes = useStyles(props);

  return (
    <div>
      {props.title && (
        <Typography className={classes.title}>{props.title}</Typography>
      )}
      {props.subTitle && (
        <Typography className={classes.subTitle}>{props.subTitle}</Typography>
      )}
      <div className={classes.root}>
        {props.data || props.data === 0 ? (
          <span>
            {props.noTooltip ? (
              <span>
                <Typography
                  component={props.noBorder ? "span" : "p"}
                  className={classes.data}
                >
                  {props.data}
                </Typography>
                <Typography
                  component={props.noBorder ? "span" : "p"}
                  className={classes.unit}
                >
                  {props.unit}
                </Typography>
              </span>
            ) : (
              <span>
                <Tooltip
                  classes={{ tooltipPlacementBottom: classes.tooltip }}
                  title={props.timestamp + " - Building time"}
                >
                  <Typography
                    component={props.noBorder ? "span" : "p"}
                    className={classes.data}
                  >
                    {props.data}
                  </Typography>
                </Tooltip>
                <Typography
                  component={props.noBorder ? "span" : "p"}
                  className={classes.unit}
                >
                  {props.unit}
                </Typography>
              </span>
            )}
          </span>
        ) : (
          <Typography className={classes.data}>
            <span className={classes.noData}>--</span>
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TwinInputStatus;
