import moment from "moment-timezone";
import { get } from "lodash";
import { useSelector } from "react-redux";

// Convert UTC to building time with optional format
function useBuildingTime() {
  const buildingData = useSelector(
    (state) => state.buildingFilter.buildingData
  );
  const timezone = get(buildingData, "timeZoneIANA", null);

  const toBuildingTime = (time) => {
    const utc = moment.utc(new Date(time));
    const retVal = moment.tz(utc, timezone);
    return retVal;
  };

  return toBuildingTime;
}

export default useBuildingTime;
