import React, { useState, useEffect } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from "react-admin";
import { FormDataConsumer } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { get } from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { AhuInputs } from "./AhuInputs";
import { HxInputs } from "./HxInputs";
import { HpInputs } from "./HpInputs";
import { MeterInputs } from "./MeterInputs";
import { SolarInputs } from "./SolarInputs";
import { ZoneInputs } from "./ZoneInputs";
import { AhuOperations } from "./AhuOperations";
import { HpOperations } from "./HpOperations";
import { MeterOperations } from "./MeterOperations";
import { SolarOperations } from "./SolarOperations";
import { ZoneOperations } from "./ZoneOperations";
import { stateMapper } from "../customReducers/connectFunctions";
import {
  containsWhiteSpace,
  isValidBlockName,
  isIntValue,
  minValue,
  maxValue,
  startsWithNumber,
} from "../validationHelper";
import { blockTypeInputOptions } from "./blockTypeInputOptions";
import optionRenderer from "../utils/optionRenderer";
import ZoneUsageTimesInput from "./ZoneUsageTimeInput";
import PageTitle from "../components/layout/PageTitle";

const createDefaults = {
  Zone: { UsageTimeGroupId: null, UsageTimes: [] },
  Compensation: {
    RadiationCompensationCoordinates: [
      { Input: 1000, Output: -1 },
      { Input: 800, Output: -0.5 },
      { Input: 500, Output: -0.2 },
      { Input: 300, Output: 0 },
    ],
  },
};

const blockStyles = {
  select_variable_field: { display: "inline-block", width: 400 },
  scaling_field: { display: "inline-block" },
  multiple_points: { chip: { flexWrap: "wrap" } },
  main: { marginTop: 0 },
};

function DefaultCompensationGraph(formData) {
  if (formData.BlockType === "AHU") {
    return [
      { Input: 3, Output: -1 },
      { Input: -3, Output: 1 },
    ];
  } else if (formData.BlockType === "HX")
    return [
      { Input: 6, Output: -4 },
      { Input: -6, Output: 4 },
    ];
}

function ResolveDefaultEfficiency(formData) {
  const type = get(formData, "Ahu.HeatRecoveryType", "");
  if (type === "RECIRCULATION_HR") {
    return 70;
  } else if (type === "DISC_HR") {
    return 70;
  } else if (type === "CUBE_HR") {
    return 50;
  } else if (type === "LIQUID_HR") {
    return 30;
  } else if (type === "NO_HR") {
    return 0;
  } else {
    return 0;
  }
}

const zoneOptionRenderer = (choice) =>
  `${choice.BlockName} - ${choice.BlockDescription}`;

const NameTooltipTitle =
  "This is more like prefix so something simple and short AHU1, TK311 etc...";

const checkOutputSelected = (formData) => {
  const outputTag = get(formData, "Compensation.BlockOutput.BusTagName", "");
  return !(outputTag === null) && outputTag.length > 0;
};
const validateHour = [isIntValue(), minValue(0), maxValue(24)];

const alertDefaults = (formData) => {
  const alerts = [];

  switch (formData.BlockType) {
    case "AHU":
      alerts.push({
        AlertName: "FLOW_NOT_IN_TARGET",
        Limit: 30.0,
        Days: 1,
        Active: true,
      });
      break;
    case "HX":
      alerts.push({
        AlertName: "SECONDARY_SUPPLY_TE_NOT_IN_TARGET",
        Limit: 6.0,
        Days: 1,
        Active: true,
      });
      break;
    case "HP":
      alerts.push({
        AlertName: "HP_BLOCK_NOT_IN_TARGET",
        Limit: 20,
        Days: 7,
        Active: true,
      });
      alerts.push({
        AlertName: "HP_BLOCK_STATUS_ALERT",
        Active: true
      });
      break;
    case "Meter":
      break;
    case "SOLAR":
      alerts.push({
        AlertName: "SOLAR_BLOCK_NOT_IN_TARGET",
        Limit: 20,
        Days: 30,
        Active: true,
      });
      break;
    case "ZONE":
      alerts.push({
        AlertName: "ZONE_TEMPERATURE_NOT_IN_TARGET",
        Limit: 90,
        Days: 1,
        Active: true,
      });
      alerts.push({
        AlertName: "ZONE_CO2_NOT_IN_TARGET",
        Limit: 90,
        Days: 1,
        Active: true,
      });
      break;
    default:
      break;
  }
  alerts.push({ AlertName: "NO_BLOCK_DATA", Limit: 6, Days: 0, Active: true });
  formData.BlockAlerts = alerts;
  return alerts;
};

const solarDefaultMonthlyTargets = [
  { Month: 1, Desc: "January", Target: 0 },
  { Month: 2, Desc: "February", Target: 0 },
  { Month: 3, Desc: "March", Target: 0 },
  { Month: 4, Desc: "April", Target: 0 },
  { Month: 5, Desc: "May", Target: 0 },
  { Month: 6, Desc: "June", Target: 0 },
  { Month: 7, Desc: "July", Target: 0 },
  { Month: 8, Desc: "August", Target: 0 },
  { Month: 9, Desc: "September", Target: 0 },
  { Month: 10, Desc: "October", Target: 0 },
  { Month: 11, Desc: "November", Target: 0 },
  { Month: 12, Desc: "December", Target: 0 },
];

const hpDefaultMonthlyTargets = [
  {
    Month: 1,
    Desc: "January",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 2,
    Desc: "February",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 3,
    Desc: "March",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 4,
    Desc: "April",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 5,
    Desc: "May",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 6,
    Desc: "June",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 7,
    Desc: "July",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 8,
    Desc: "August",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 9,
    Desc: "September",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 10,
    Desc: "October",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 11,
    Desc: "November",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
  {
    Month: 12,
    Desc: "December",
    ConsumedTarget: 0,
    ProducedTarget: 0,
    AdditionalTarget: 0,
  },
];

const ahuChoices = [
  {
    id: "RECIRCULATION_HR",
    name: "Recirculation HR",
  },
  {
    id: "DISC_HR",
    name: "Disc HR",
  },
  {
    id: "CUBE_HR",
    name: "Cube HR",
  },
  {
    id: "LIQUID_HR",
    name: "Liquid HR",
  },
  {
    id: "NO_HR",
    name: "No HR",
  },
];

const meterDirChoices = [
  {
    id: "Consumption",
    name: "Consumption",
  },
  {
    id: "Production",
    name: "Production",
  },
];

const meterCatChoices = [
  {
    id: "Cooling",
    name: "Cooling",
  },
  {
    id: "Electricity",
    name: "Electricity",
  },
  {
    id: "Heating",
    name: "Heating",
  },
  {
    id: "Water",
    name: "Water",
  },
];

const hpChoices = [
  {
    id: "AIR",
    name: "Air source heat pump",
  },
  {
    id: "GROUND",
    name: "Ground source heat pump",
  },
];

const zoneChoices = [
  {
    id: 1,
    name: "S1",
  },
  {
    id: 2,
    name: "S2",
  },
  {
    id: 3,
    name: "S3",
  },
];

const airFlowCalcMethodChoices = [
  {
    id: "MeasuredAirFlow",
    name: "Measured airflow",
  },
  {
    id: "CalculatedFromPressure",
    name: "Calculated from pressure",
  },
  {
    id: "CalculatedFromControlPercent",
    name: "Calculated from control percent",
  },
];

const AlertArrayInput = (props) => {
  const [defaults, setDefaults] = useState([]);
  useEffect(() => {
    setDefaults(alertDefaults(props.formData));
  }, [props.formData.BlockType, props.formData]);

  return (
    <ArrayInput
      source="BlockAlerts"
      style={{ display: "contents" }}
      defaultValue={defaults}
    >
      <SimpleFormIterator disableRemove disableAdd>
        <TextInput
          label="AlertName"
          source="AlertName"
          disabled={true}
          style={{
            display: "inline-block",
            float: "left",
          }}
        />
        <NumberInput
          label="Limit"
          source="Limit"
          style={{ display: "inline-block", float: "left" }}
        />
        <NumberInput
          label="Days"
          source="Days"
          style={{ display: "inline-block", float: "left" }}
        />
        <BooleanInput
          label="Active"
          source="Active"
          style={{ display: "inline-block", float: "left" }}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const ExtraDiv = (props) => {
  const divProps = Object.assign({}, props);
  delete divProps.basePath;
  delete divProps.hasList;
  delete divProps.hasEdit;
  delete divProps.hasShow;
  delete divProps.hasCreate;

  return (
    <div {...divProps} style={{ display: "inline-flex", width: "100%" }}>
      <div style={{ display: "flex" }}>
        <TextInput
          label="Block name"
          source="BlockName"
          validate={validateName}
          style={{ width: "100%" }}
          options={{ autoComplete: "off" }}
        />
        <Tooltip title={NameTooltipTitle} placement="right-end">
          <HelpIcon style={{ margin: "auto", fontWeight: "20em" }} />
        </Tooltip>
      </div>
      <div style={{ display: "block" }} />
    </div>
  );
};

const BlockCreate = withStyles(blockStyles)(
  ({ BuildingId, classes, ...props }) => {
    // Remove unused props
    const createProps = Object.assign({}, props);
    delete createProps.dispatch;
    delete createProps.buildingTwinId;
    delete createProps.buildingSelected;

    return (
      <>
        <PageTitle title="Create Digital Twin" />
        <Create {...createProps} classes={{ main: classes.main }}>
          <TabbedForm initialValues={createDefaults} submitOnEnter={false}>
            <FormTab label="General info">
              <BooleanInput source="Active" default={false} />
              <ReferenceInput
                label="Building"
                source="BuildingId"
                reference="buildings"
                sort={{ field: "ProjectName", order: "ASC" }}
                filter={{ RegisterListsVisible: true, showBAUI: true }}
                fullWidth
                defaultValue={BuildingId}
              >
                <AutocompleteInput
                  optionText="ProjectName"
                  optionValue="id"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>

              <ExtraDiv {...createProps} />
              <TextInput
                multiline
                label="Block description"
                source="BlockDescription"
                validate={required()}
                style={{ width: "100%" }}
                options={{ autoComplete: "off" }}
              />

              <SelectInput
                source="BlockType"
                choices={blockTypeInputOptions}
                validate={required()}
              />

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "AHU" && (
                    <div>
                      <SelectInput
                        label="HR type"
                        source="Ahu.HeatRecoveryType"
                        choices={ahuChoices}
                      />
                      {formData.Ahu && !formData.Ahu.HeatRecoveryType ? null : (
                        <NumberInput
                          label="Est. HR efficiency (%)"
                          source="AhuHeatRecoveryEfficiencyLevel"
                          defaultValue={ResolveDefaultEfficiency(formData)}
                          style={{
                            marginLeft: "20px",
                          }}
                        />
                      )}
                      <Card>
                        <CardHeader title="Air flow calculation method" />
                        <CardContent>
                          {" "}
                          <SelectInput
                            label="Supply air flow calculation method"
                            source="Ahu.SupplyAirFlowCalculationMethod"
                            choices={airFlowCalcMethodChoices}
                            style={{ display: "block" }}
                            fullWidth
                            validate={required()}
                          />
                          <FormDataConsumer>
                            {({ formData, ...rest }) =>
                              formData.Ahu &&
                              formData.Ahu.SupplyAirFlowCalculationMethod ===
                              "CalculatedFromPressure" && [
                                <NumberInput
                                  label="Measured supply air flow (0-50 m³/s)"
                                  source="Ahu.MeasuredSupplyAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(50),
                                  ]}
                                  style={{ width: "45%" }}
                                />,
                                <NumberInput
                                  label="Pressure at measured supply air flow (0-1000 Pa)"
                                  source="Ahu.PressureAtMeasuredSupplyAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(1000),
                                  ]}
                                  style={{
                                    marginLeft: "20px",
                                    width: "45%",
                                  }}
                                />,
                              ]
                            }
                          </FormDataConsumer>
                          <FormDataConsumer>
                            {({ formData, ...rest }) =>
                              formData.Ahu &&
                              formData.Ahu.SupplyAirFlowCalculationMethod ===
                              "CalculatedFromControlPercent" && [
                                <NumberInput
                                  label="Measured supply air flow (0-50 m³/s)"
                                  source="Ahu.MeasuredSupplyAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(50),
                                  ]}
                                  style={{ width: "45%" }}
                                />,
                                <NumberInput
                                  label="Control percentage at measured supply air flow (0-100 %)"
                                  source="Ahu.ControlAtMeasuredSupplyAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(100),
                                  ]}
                                  style={{
                                    marginLeft: "20px",
                                    width: "45%",
                                  }}
                                />,
                              ]
                            }
                          </FormDataConsumer>
                          <SelectInput
                            label="Return air flow calculation method"
                            source="Ahu.ReturnAirFlowCalculationMethod"
                            choices={airFlowCalcMethodChoices}
                            style={{ display: "block" }}
                            fullWidth
                            validate={required()}
                          />
                          <FormDataConsumer>
                            {({ formData, ...rest }) =>
                              formData.Ahu &&
                              formData.Ahu.ReturnAirFlowCalculationMethod ===
                              "CalculatedFromPressure" && [
                                <NumberInput
                                  label="Measured return air flow (0-50 m³/s)"
                                  source="Ahu.MeasuredReturnAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(50),
                                  ]}
                                  style={{ width: "45%" }}
                                />,
                                <NumberInput
                                  label="Pressure at measured return air flow (0-1000 Pa)"
                                  source="Ahu.PressureAtMeasuredReturnAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(1000),
                                  ]}
                                  style={{
                                    marginLeft: "20px",
                                    width: "45%",
                                  }}
                                />,
                              ]
                            }
                          </FormDataConsumer>
                          <FormDataConsumer>
                            {({ formData, ...rest }) =>
                              formData.Ahu &&
                              formData.Ahu.ReturnAirFlowCalculationMethod ===
                              "CalculatedFromControlPercent" && [
                                <NumberInput
                                  label="Measured return air flow (0-50 m³/s)"
                                  source="Ahu.MeasuredReturnAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(50),
                                  ]}
                                  style={{ width: "45%" }}
                                />,
                                <NumberInput
                                  label="Control percentage at measured return air flow (0-100 %)"
                                  source="Ahu.ControlAtMeasuredReturnAirFlow"
                                  validate={[
                                    required(),
                                    minValue(0),
                                    maxValue(100),
                                  ]}
                                  style={{
                                    marginLeft: "20px",
                                    width: "45%",
                                  }}
                                />,
                              ]
                            }
                          </FormDataConsumer>
                        </CardContent>
                      </Card>
                      <ReferenceArrayInput
                        label="Serving zone blocks"
                        source="Ahu.ZoneBlockIds"
                        reference="blocks"
                        perPage={500}
                        sort={{ field: "BlockName", order: "ASC" }}
                        filter={{
                          BuildingId: formData.BuildingId,
                          BlockType: "ZONE",
                        }}
                        fullWidth
                      >
                        <SelectArrayInput
                          optionText={zoneOptionRenderer}
                          optionValue="id"
                        />
                      </ReferenceArrayInput>
                      <ArrayInput source="Ahu.AirFlowTargets">
                        <SimpleFormIterator>
                          <NumberInput
                            label="Supply air flow (m³/s)"
                            source="AirFlow"
                          />
                          <Tooltip
                            title="Set only air flow targets when AHU is running."
                            placement="right-end"
                          >
                            <HelpIcon />
                          </Tooltip>
                          <NumberInput
                            label="Return air flow (m³/s)"
                            source="ReturnAirFlow"
                          />
                          <NumberInput
                            label="Monday start"
                            source="MonStart"
                            validate={validateHour}
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Monday end" source="MonEnd" />
                          <NumberInput
                            label="Tuesday start"
                            source="TueStart"
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Tuesday end" source="TueEnd" />
                          <NumberInput
                            label="Wednesday start"
                            source="WedStart"
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Wednesday end" source="WedEnd" />
                          <NumberInput
                            label="Thursday start"
                            source="ThuStart"
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Thursday end" source="ThuEnd" />
                          <NumberInput
                            label="Friday start"
                            source="FriStart"
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Friday end" source="FriEnd" />
                          <NumberInput
                            label="Saturday start"
                            source="SatStart"
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Saturday end" source="SatEnd" />
                          <NumberInput
                            label="Sunday start"
                            source="SunStart"
                            style={{ display: "inline-block", float: "left" }}
                          />
                          <NumberInput label="Sunday end" source="SunEnd" />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </div>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "Meter" && (
                    <div>
                      <SelectInput
                        label="Meter direction"
                        source="Meter.Direction"
                        validate={required()}
                        choices={meterDirChoices}
                      />
                      <SelectInput
                        label="Meter category"
                        source="Meter.Category"
                        validate={required()}
                        choices={meterCatChoices}
                      />
                    </div>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HP" && (
                    <div>
                      <SelectInput
                        label="Heat source"
                        source="Hp.HeatSource"
                        validate={required()}
                        choices={hpChoices}
                      />
                      <NumberInput
                        label="Rated electric power (kW)"
                        source="Hp.RatedElectricPower"
                        style={{ marginLeft: "25px" }}
                      />
                      <NumberInput
                        label="Rated thermal power (kW)"
                        source="Hp.RatedThermalPower"
                        style={{ marginLeft: "25px" }}
                      />
                    </div>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HP" && (
                    <ArrayInput
                      source="Hp.MonthlyTargets"
                      defaultValue={hpDefaultMonthlyTargets}
                    >
                      <SimpleFormIterator disableAdd disableRemove>
                        <TextInput
                          disabled
                          source="Month"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput
                          label="Energy production (kWh)"
                          source="ProducedTarget"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput
                          label="Energy consumption (kWh)"
                          source="ConsumedTarget"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput
                          label="Additional energy consumption (kWh)"
                          source="AdditionalTarget"
                          style={{ display: "inline-block", float: "left" }}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "SOLAR" && [
                    <NumberInput
                      source="Solar.PanelArea"
                      label="Panel area (m²)"
                    />,
                    <NumberInput
                      source="Solar.PanelPowerDensity"
                      label="Panel power density (W/m²)"
                    />,
                    <ArrayInput
                      source="Solar.MonthlyTargets"
                      defaultValue={solarDefaultMonthlyTargets}
                    >
                      <SimpleFormIterator disableAdd disableRemove>
                        <TextInput
                          disabled
                          source="Desc"
                          style={{ display: "inline-block", float: "left" }}
                        />
                        <NumberInput
                          label="Target (kW)"
                          source="Target"
                          style={{ display: "inline-block", float: "left" }}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>,
                  ]
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "ZONE" && (
                    <React.Fragment>
                      <SelectInput
                        label="Zone indoor climate class"
                        source="Zone.IndoorClimateClass"
                        allowEmpty
                        choices={zoneChoices}
                      />
                      <NumberInput label="Floor" source="Zone.Floor" />

                      <ZoneUsageTimesInput formData={formData} />

                      {/* TODO first we just link relations from AHU blocks
                <ReferenceArrayInput
                  label="AHU's serving this zone"
                  source="Zone.ServedByBlocks"
                  reference="blocks"
                  sort={{ field: "BlockName", order: "ASC" }}
                  filter={{ BuildingId: formData.BuildingId, BlockType: "AHU" }}
                  fullWidth
                >
                  <SelectArrayInput optionText="BlockName" optionValue="id" />
                </ReferenceArrayInput>*/}
                    </React.Fragment>
                  )
                }
              </FormDataConsumer>
            </FormTab>
            <FormTab label="Inputs">
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType !== "SOLAR" && (
                    <BooleanInput
                      label="Use IL outdoor weather"
                      source="UseILWeather"
                      defaultValue={true}
                    />
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "AHU" &&
                  !formData.UseILWeather && (
                    <div>
                      <ReferenceInput
                        label="Outdoor air TE"
                        source="Ahu.OutdoorTE.BusTagName"
                        reference="registerlists"
                        filter={{ buildingId: formData.BuildingId }}
                      >
                        <AutocompleteInput
                          optionText={optionRenderer}
                          optionValue="PointId"
                          options={{
                            fullWidth: true,
                          }}
                        />
                      </ReferenceInput>
                    </div>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "AHU" && <AhuInputs {...formData} />
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HX" && <HxInputs {...formData} />
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HP" && <HpInputs {...formData} />
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "SOLAR" && (
                    <SolarInputs {...formData} />
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "ZONE" && <ZoneInputs {...formData} />
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "Meter" && (
                    <MeterInputs {...formData} />
                  )
                }
              </FormDataConsumer>
            </FormTab>

            <FormTab label="Operations">
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  (!formData.BlockType || !formData.BuildingId) && (
                    <p>Select building and block type first!</p>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "AHU" && (
                    <AhuOperations {...formData} />
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "SOLAR" && (
                    <SolarOperations {...formData} />
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "ZONE" && (
                    <ZoneOperations {...formData} />
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HX" && (
                    <div>
                      <p>No operations yet for HX block!</p>
                      {/*<HxOperations {...formData} />*/}
                    </div>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "HP" && (
                    <div>
                      <HpOperations {...formData} />
                    </div>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType === "Meter" && (
                    <div>
                      <MeterOperations {...formData} />
                    </div>
                  )
                }
              </FormDataConsumer>
            </FormTab>

            <FormTab label="Alerts">
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType && <AlertArrayInput formData={formData} />
                }
              </FormDataConsumer>
            </FormTab>

            <FormTab label="Outputs">
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  (formData.BlockType === "HP" ||
                    formData.BlockType === "SOLAR") && <p>Nothing here yet!</p>
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  (formData.BlockType === "HX" ||
                    formData.BlockType === "AHU") && [
                    <ReferenceInput
                      label="Compensation output"
                      source="Compensation.BlockOutput.BusTagName"
                      reference="registerlists"
                      filter={{
                        buildingId: formData.BuildingId,
                        writable: true,
                      }}
                      //  perPage={10}
                      allowEmpty={true}
                    >
                      <AutocompleteInput
                        optionText={optionRenderer}
                        optionValue="PointId"
                        options={{
                          fullWidth: true,
                        }}
                        filter={{
                          buildingId: formData.BuildingId,
                          writable: true,
                        }}
                      />
                    </ReferenceInput>,
                    <NumberInput
                      source="Compensation.TemperatureHours"
                      step={1}
                      defaultValue={24}
                      validate={[minValue(1), maxValue(72)]}
                    />,
                  ]
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.BlockType &&
                  checkOutputSelected(formData) && (
                    <ArrayInput
                      source="Compensation.CompensationCoordinates"
                      defaultValue={DefaultCompensationGraph(formData)}
                    >
                      <SimpleFormIterator>
                        <NumberInput
                          label="Input"
                          source="Input"
                          style={{ display: "inline", float: "left" }}
                        />
                        <NumberInput
                          label="Output"
                          source="Output"
                          style={{
                            display: "inline",
                            float: "left",
                            marginLeft: "20px",
                          }}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  (formData.BlockType === "HX" ||
                    formData.BlockType === "AHU") && (
                    <BooleanInput
                      label="Use solar radiation compensation"
                      source="Compensation.UseRadiationCompensation"
                    />
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.BuildingId &&
                  formData.Compensation &&
                  formData.Compensation.UseRadiationCompensation && [
                    <NumberInput
                      source="Compensation.RadiationHours"
                      step={1}
                      defaultValue={2}
                      validate={[minValue(1), maxValue(72)]}
                    />,
                    <ArrayInput source="Compensation.RadiationCompensationCoordinates">
                      <SimpleFormIterator>
                        <NumberInput
                          label="Input"
                          source="Input"
                          style={{ display: "inline", float: "left" }}
                        />
                        <NumberInput
                          label="Output"
                          source="Output"
                          style={{
                            display: "inline",
                            float: "left",
                            marginLeft: "20px",
                          }}
                        />
                      </SimpleFormIterator>
                    </ArrayInput>,
                  ]
                }
              </FormDataConsumer>
            </FormTab>
          </TabbedForm>
        </Create>
      </>
    );
  }
);

const validateName = [
  required(),
  startsWithNumber(),
  containsWhiteSpace(),
  isValidBlockName(),
];

export default stateMapper(BlockCreate);
