import React from "react";
import { Card, CardHeader, makeStyles } from "@material-ui/core";
import Spacer from "./Spacer";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 10px #2E224729",
    borderRadius: "2px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "5.125em",
    width: "100%",
    margin: "0 0 0 0",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
  },
  headerAction: {
    alignSelf: "center",
  },
  headerRoot: { paddingLeft: "2rem", paddingRight: "2rem", width: "100%" },
  header: {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
}));

const CardWithTitle = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} style={props.style}>
      <div className={classes.headerContainer}>
        <Spacer
          height="2rem"
          color={props.spacerColor ? props.spacerColor : "#282331"}
        />
        <CardHeader
          classes={{
            root: classes.headerRoot,
            title: classes.header,
            action: classes.headerAction,
          }}
          title={props.title}
          subheader={props.subheader}
          action={props.action}
        />
      </div>
      {props.children}
    </Card>
  );
};

export default CardWithTitle;
