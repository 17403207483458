import React from "react";
import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  NullableBooleanInput,
  NumberField,
  SelectInput,
  TextField,
  Pagination,
} from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";
import { makeStyles } from "@material-ui/core";
import RefreshRegisterlistsButton from "./RefreshRegistelistsButton";
import ListTitle from "../components/ListTitle";
import { ipToHttpLink } from "../utils/ipToHttpParser";
import { SourceBuilding } from "../alerts/AlertShow";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const BusAdapterFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput
        source="AdapterType"
        choices={[
          { id: "MODBUS", name: "MODBUS" },
          { id: "IOTHUB", name: "IOTHUB" },
        ]}
        className={classes.helperText}
      />
      <NullableBooleanInput
        label="Active"
        source="Active"
        defaultValue={true}
        className={classes.helperText}
      />
    </Filter>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const listname = "Bus adapters";

const BusAdapterList = ({ BuildingId, projectname, ...props }) => {
  const { isAdminOrUser } = useUserRole();
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;
  return (
    <>
      <PageTitle title="Bus Adapters" />
      <List
        {...listProps}
        pagination={<ValuesPagination />}
        title={ListTitle(projectname, BuildingId, listname)}
        filters={<BusAdapterFilter />}
        filterDefaultValues={{ BuildingId }}
        filter={{ BuildingId }}
        bulkActionButtons={false}
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid>
          <NumberField source="BusAdapterId" options={{ useGrouping: false }} />
          <SourceBuilding sortBy={"BuildingId"} label="Building" />
          <TextField source="AdapterType" />
          <FunctionField
            render={(record) => ipToHttpLink(record.IP)}
            label="IP"
          />
          <BooleanField source="Active" />
          {isAdminOrUser && (
            <EditButton style={{ padding: 0 }} />
          )}
          {isAdminOrUser && (
            <RefreshRegisterlistsButton />
          )}
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(BusAdapterList);
