import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberField,
  SelectInput,
  TextInput,
  Pagination,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import { stateMapper } from "../customReducers/connectFunctions";
import { setConsumptionUnitText } from "../utils/unitTextParsers";
import { ConsumptionActions } from "./ConsumptionActions";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";
import { isNil } from "lodash";

const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const FunctionFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" className={classes.helperText} />
      <SelectInput
        source="Type"
        choices={[
          { id: "heating", name: "Heating" },
          { id: "electricity", name: "Electricity" },
          { id: "water", name: "Water" },
          { id: "cooling", name: "Cooling" },
        ]}
        className={classes.helperText}
      />
    </Filter>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const ConsumptionListsList = ({ BuildingId, projectname, ...props }) => {
  const { isAdminOrUser } = useUserRole();
  // Prevent rendering component without BuildingId
  if (isNil(BuildingId)) {
    return null;
  }
  // Delete unused/unnecessary props
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  return (
    <>
      <PageTitle title="Consumptions" />
      <List
        {...listProps}
        perPage={25}
        pagination={<ValuesPagination />}
        filterDefaultValues={{ BuildingId }}
        filter={{ BuildingId, Source: ["Manual", "Metry"] }}
        filters={<FunctionFilter />}
        bulkActionButtons={false}
        actions={<ConsumptionActions />}
        sort={{ field: "Time", order: "DESC" }}
      >
        <Datagrid>
          <DateField
            source="Time"
            label="Date"
            options={{ year: "numeric", month: "numeric" }}
          />
          <NumberField source="Consumption" options={{ useGrouping: false }} />
          {/* <NumberField
          source="ConsNorm"
          label="Normalized Consumption"
          options={{ useGrouping: false }}
        /> */}
          <FunctionField
            label="Type"
            render={(record) => setConsumptionUnitText(record.Type)}
          />
          <FunctionField label="Source" render={(record) => record.Source} />
          <FunctionField
            render={(record) =>
              isAdminOrUser && (
                <EditButton
                  to={`manualconsumption?BuildingStatsId=${record.BuildingStatsId}&Type=${record.Type}&Time=${record.Time}`}
                />
              )
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(ConsumptionListsList);
