import React from "react";
import { NullableBooleanInput } from "react-admin";
import { get } from "lodash";

function isInputSelected(formData, inputId) {
  const selectedValue = get(formData.Ahu, inputId + ".BusTagName", undefined);
  return selectedValue ? true : false;
}

export const AhuOperations = formData => (
  <div style={{ display: "grid" }}>
    <NullableBooleanInput
      label="Heat recovery efficiency %"
      source="AhuOperations.HeatingRecoveryUnitEfficiency.Active"
      defaultValue={
        isInputSelected(formData, "AfterHRTE") &&
        isInputSelected(formData, "ReturnAirTe")
      }
      // TODO: remove commented props on all inputs
      // disabled={
      //   !(
      //     isInputSelected(formData, "AfterHRTE") &&
      //     isInputSelected(formData, "ReturnAirTe")
      //   )
      // }
      disabled={true}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Heating power"
      source="AhuOperations.HeatingPower.Active"
      defaultValue={
        isInputSelected(formData, "SupplyAirTE") &&
        isInputSelected(formData, "SupplyAirFlow") &&
        isInputSelected(formData, "AfterHRTE")
      }
      // disabled={
      //   !(
      //     isInputSelected(formData, "SupplyAirTE") &&
      //     isInputSelected(formData, "SupplyAirFlow") &&
      //     isInputSelected(formData, "AfterHRTE")
      //   )
      // }
      disabled={true}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Heating power integral"
      source="AhuOperations.HeatingPowerIntegral.Active"
      defaultValue={
        isInputSelected(formData, "SupplyAirTE") &&
        isInputSelected(formData, "SupplyAirFlow") &&
        isInputSelected(formData, "AfterHRTE")
      }
      // disabled={
      //   !(
      //     isInputSelected(formData, "SupplyAirTE") &&
      //     isInputSelected(formData, "SupplyAirFlow") &&
      //     isInputSelected(formData, "AfterHRTE")
      //   )
      // }
      disabled={true}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Heating recovery heating power"
      source="AhuOperations.HeatingRecoveryHeatingPower.Active"
      defaultValue={
        isInputSelected(formData, "SupplyAirFlow") &&
        isInputSelected(formData, "AfterHRTE")
      }
      // disabled={
      //   !(
      //     isInputSelected(formData, "SupplyAirFlow") &&
      //     isInputSelected(formData, "AfterHRTE")
      //   )
      // }
      disabled={true}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Heating recovery heating power integral"
      source="AhuOperations.HeatingRecoveryHeatingPowerIntegral.Active"
      defaultValue={
        isInputSelected(formData, "SupplyAirFlow") &&
        isInputSelected(formData, "AfterHRTE")
      }
      // disabled={
      //   !(
      //     isInputSelected(formData, "SupplyAirFlow") &&
      //     isInputSelected(formData, "AfterHRTE")
      //   )
      // }
      disabled={true}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Supply air flow integral"
      source="AhuOperations.SupplyAirFlowIntegral.Active"
      defaultValue={isInputSelected(formData, "SupplyAirFlow")}
      // disabled={!isInputSelected(formData, "SupplyAirFlow")}
      disabled={true}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Running status info "
      source="AhuOperations.RunningStatusInfo.Active"
      defaultValue={isInputSelected(formData, "SupplyAirFlow")}
      // disabled={!isInputSelected(formData, "SupplyAirFlow")}
      disabled={true}
      style={{ width: "300px" }}
    />
  </div>
);
