import React from "react";
import { LoadingIndicator } from "ra-ui-materialui";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  makeStyles,
  SvgIcon,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";

import CurrentPath from "./CurrentPath";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    minHeight: "3em",
    marginBottom: (props) => (props.noBottomMargin ? undefined : 24),
  },
  title: {
    fontSize: "1.875rem",
  },
  rightContainer: {
    // height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  refresh: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#282331",
    opacity: "60%",
    minWidth: "3em",
  },
  indicator: {
    minHeight: "3em",
    minWidth: "3em",
    maxHeight: "3em",
    maxWidth: "3em",
  },
  refreshText: {
    marginLeft: 0,
    fontSize: "1.1rem",
  },
  editContainer: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    marginLeft: "auto",
  },
  editText: {
    opacity: "60%",
    fontSize: "0.875rem",
  },
  editIcon: {
    height: "1.125rem",
    width: "1.125rem",
  },
  alerts: {
    marginLeft: "auto",
    marginRight: 10,
  },
  spacer: {
    borderRight: "2px solid #77747F",
    opacity: 0.6,
    marginLeft: 5,
    height: 30,
  },
}));

const PageTitle = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down("md"));

  const editIcon = (
    <SvgIcon
      classes={{ root: classes.editIcon }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="icon_edit"
        d="M5,3A1.993,1.993,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V12H19v7H5V5h7V3H5M17.78,4a.691.691,0,0,0-.48.2L16.08,5.41l2.5,2.5L19.8,6.7a.676.676,0,0,0,0-.95L18.25,4.2a.668.668,0,0,0-.47-.2M15.37,6.12,8,13.5V16h2.5l7.37-7.38Z"
        transform="translate(-3 -3)"
        fill="#282331"
      />
    </SvgIcon>
  );

  const EditTwin = (
    <div className={classes.editContainer}>
      {isMediumOrSmaller ? (
        <IconButton
          component={RouterLink}
          to={props.editPath ? props.editPath : "/"}
          className={classes.editText}
        >
          {editIcon}
        </IconButton>
      ) : (
        <Button
          component={RouterLink}
          to={props.editPath ? props.editPath : "/"}
          classes={{ label: classes.editText }}
          variant="text"
          startIcon={editIcon}
        >
          EDIT TWIN
        </Button>
      )}
      <div className={classes.spacer} />
    </div>
  );

  return (
    <>
      <Box className={classes.box}>
        <div>
          {props.showPath && <CurrentPath path={props.path} />}
          {props.twinTitle ? (
            props.twinTitle
          ) : (
            <Typography className={classes.title} variant="h4">
              {props.title}
            </Typography>
          )}
        </div>
        {props.editPath && EditTwin}
        <div
          className={classes.rightContainer}
          style={{ marginLeft: props.editPath ? undefined : "auto" }}
        >
          <div className={classes.refresh}>
            <div className={classes.indicator}>
              <LoadingIndicator />
            </div>
            {!isMediumOrSmaller && (
              <span className={classes.refreshText}>REFRESH</span>
            )}
          </div>
        </div>
      </Box>
    </>
  );
};

export default PageTitle;
