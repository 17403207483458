import React from "react";
import {
  BooleanInput,
  Create,
  TextInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { FormDataConsumer } from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";

import WritePointInput from "../components/WritePointInput";
import PageTitle from "../components/layout/PageTitle";

const CompensationDefaultValue = {
  created_at: new Date(),
  EndpointId: 7,
  Guid: undefined,
  functions: [],
};

const CompensationCreate = ({ BuildingId, ...props }) => {
  return (
    <>
      <PageTitle title="Create Compensation" />
      <Create {...props} redirect="edit">
        <SimpleForm initialValues={CompensationDefaultValue}>
          <ReferenceInput
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ RegisterListsVisible: true, showBAUI: true }}
            allowEmpty
            fullWidth
            defaultValue={BuildingId}
          >
            <SelectInput optionText="ProjectName" optionValue="id" />
          </ReferenceInput>

          <TextInput
            multiline
            label="Compensation description"
            source="Name"
            validate={required()}
          />

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.BuildingId && (
                <ReferenceInput
                  label="BusAdapter"
                  source="BusAdapterId"
                  reference="busadapters"
                  // sort={{ field: "name", order: "ASC" }}
                  filter={{ BuildingId: formData.BuildingId }}
                  allowEmpty
                  fullWidth
                  defaultValue={BuildingId}
                >
                  <SelectInput
                    optionValue="id"
                    optionText={(choice) =>
                      `${choice.AdapterType ? choice.AdapterType : ""}: 
          ${choice.IP ? choice.IP : ""} 
          ${choice.DeviceId ? choice.DeviceId : ""}`
                    }
                  />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.BusAdapterId && [
                <WritePointInput
                  dependsOnValue={formData.BusAdapterId}
                  label="Write points"
                  source="WritePointNames"
                  allowEmpty={true}
                  options={{ fullWidth: true, multiple: true }}
                />,
                <ReferenceArrayInput
                  label="Compensation functions"
                  source="CompensationFunctionsIds"
                  reference="compensationfunctions"
                  sort={{ field: "Name", order: "ASC" }}
                  filter={{ BuildingId: formData.BuildingId }}
                  allowEmpty
                  fullWidth
                >
                  <SelectArrayInput optionValue="id" optionText="Name" />
                </ReferenceArrayInput>,
              ]
            }
          </FormDataConsumer>

          <TextInput
            multiline
            label="Formula"
            source="Formula"
            validate={required()}
          />

          <BooleanInput source="Active" />

          <BooleanInput
            label="Log compensation result"
            source="IsResultLogging"
          />

          <BooleanInput label="Simulate output" source="IsSimulation" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(CompensationCreate);
