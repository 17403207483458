import React from "react";

import Arrow from "./Arrow";
import InputBox from "./InputBox";

const LeftSideArrows = ({
  arrowHeight,
  arrowOffset,
  heatSource,
  inputData,
  energyPoints,
  existing = [],
}) => {
  const sourceSupply =
    inputData && inputData.find((d) => d.name === "sourceSupply");

  const sourceReturn =
    inputData && inputData.find((d) => d.name === "sourceReturn");

  const inputEnergy =
    energyPoints && energyPoints.find((d) => d.name === "importEnergy");

  return (
    <>
      <Arrow
        height={arrowHeight}
        width={219}
        top={`${32 + arrowOffset}%`}
        left={0}
        translateX="-100%"
        translateY="-50%"
        text={`${heatSource} supply`}
        data={sourceSupply}
      />
      <Arrow
        height={arrowHeight}
        width={219}
        toLeft
        top={"50%"}
        left={0}
        translateX="-100%"
        translateY="-50%"
        text={`${heatSource} return`}
        data={sourceReturn}
      />
      {existing && existing.includes("inputEnergy") && (
        <Arrow
          height={arrowHeight}
          width={219}
          top={`${68 - arrowOffset}%`}
          left={0}
          translateX="-100%"
          translateY="-50%"
          text="HP Electricity"
          textLeft={42}
        >
          <InputBox
            style={{
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
            height={arrowHeight + 6}
            backgroundColor="#01BD6D"
            data={inputEnergy}
            unit="kWh"
          />
        </Arrow>
      )}
    </>
  );
};

export default LeftSideArrows;
