import React from "react";
import {
  AutocompleteInput,
  Create,
  DateInput,
  maxLength,
  ReferenceInput,
  regex,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";

const validateIP = regex(
  /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/,
  "Please enter a valid IP address"
);

const useStyles = makeStyles(() => ({
  formInputs: {
    width: "70%",
  },
}));

const RemoteConnectionCreate = ({ BuildingId, ...props }) => {
  const classes = useStyles();
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;
  return (
    <>
      <PageTitle title="Create Remote Connection" />
      <Create {...listProps}>
        <SimpleForm redirect="list">
          <ReferenceInput
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ showBAUI: true }}
            defaultValue={BuildingId}
            perPage={1000}
            validate={required()}
            className={classes.formInputs}
          >
            <AutocompleteInput optionText="ProjectName" />
          </ReferenceInput>
          <TextInput source="BuildingName" className={classes.formInputs} />
          <TextInput source="Interface" className={classes.formInputs} />
          <TextInput source="ConnectionType" className={classes.formInputs} />
          <TextInput
            source="SIMCardNumber"
            label="SIM card number"
            className={classes.formInputs}
          />
          <DateInput source="InstallationDate" className={classes.formInputs} />
          <DateInput source="ReturnedDate" className={classes.formInputs} />

          <TextInput
            source="PCUsername"
            label="PC username"
            className={classes.formInputs}
          />
          <TextInput
            source="PCPassword"
            label="PC password"
            className={classes.formInputs}
          />

          <TextInput
            source="BMSUsername"
            label="BMS username"
            className={classes.formInputs}
          />
          <TextInput
            source="BMSPassword"
            label="BMS password"
            className={classes.formInputs}
          />

          <TextInput
            source="TosiboxNATIP"
            label="Tosibox NAT IP"
            validate={[required(), validateIP]}
            className={classes.formInputs}
          />
          <TextInput
            source="TosiboxLANIP"
            label="Tosibox LAN IP"
            validate={validateIP}
            className={classes.formInputs}
          />

          <TextInput
            source="DHCP"
            label="DHCP"
            className={classes.formInputs}
          />
          <TextInput
            source="AdditionalInformation"
            validate={maxLength(255, "max length exceeded")}
            className={classes.formInputs}
          />
          <TextInput
            source="BMSAlterPolicy"
            label="BMS alter policy"
            validate={maxLength(255, "max length exceeded")}
            className={classes.formInputs}
          />
          <TextInput
            source="TosiboxLocation"
            validate={maxLength(255, "max length exceeded")}
            className={classes.formInputs}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(RemoteConnectionCreate);
