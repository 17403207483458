import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AnalyticsContent from "./AnalyticsContent";
import Spinner from "../components/layout/Spinner";

const Analytics = () => {
  const history = useHistory();
  const [buildingId, setBuildingId] = useState(null);
  const building = useSelector((state) => state.buildingFilter.Building);

  // On mount, set path to energy/analytics
  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === "/energy"
    ) {
      history.push("/energy/analytics");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (building && building.id) {
      setBuildingId(building.id);
    }
  }, [building]);

  if (building && building.id) {
    // If building has been changed, return spinner until state has been set to force remount of the content
    if (building.id !== buildingId) {
      return <Spinner />;
    }
    return <AnalyticsContent building={building} />;
  } else return <Spinner />;
};

export default Analytics;
