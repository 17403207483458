import React from "react";
import {
  DateField,
  EditButton,
  TextField,
  Show,
  SimpleShowLayout,
} from "react-admin";
import { SourceBuilding } from "../alerts/AlertShow";
import PageTitle from "../components/layout/PageTitle";

export const RemoteConnectionShow = (props) => (
  <>
    <PageTitle title="Remote Connection" />
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="BuildingName" />
        <SourceBuilding
          sortBy={"BuildingId"}
          addLabel={true}
          label="Project name"
        />
        <TextField source="Interface" />
        <TextField source="ConnectionType" />
        <TextField source="SIMCardNumber" label="SIM card number" />
        <DateField source="InstallationDate" />
        <DateField source="ReturnedDate" />

        <TextField source="PCUsername" label="PC username" />
        <TextField source="PCPassword" label="PC password" />

        <TextField source="BMSUsername" label="BMS username" />
        <TextField source="BMSPassword" label="BMS password" />

        <TextField source="TosiboxNATIP" label="Tosibox NAT IP" />
        <TextField source="TosiboxLANIP" label="Tosibox LAN IP" />

        <TextField source="DHCP" label="DHCP" />
        <TextField source="AdditionalInformation" />
        <TextField source="BMSAlterPolicy" label="BMS alter policy" />
        <TextField source="TosiboxLocation" />
        <EditButton />
      </SimpleShowLayout>
    </Show>
  </>
);
