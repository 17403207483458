import React, { useEffect, useState, useRef, FunctionComponent } from "react";
import Spinner from "../components/layout/Spinner";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import usePurchasedDataMapper from "../analytics/datamappers/purchased/usePurchasedDataMapper";
import useTargetDataMapper from "../analytics/datamappers/purchased/useTargetDataMapper";
import useTrendsDataMapper from "../analytics/datamappers/consumed/useTrendsDataMapper";
import { useSelector } from "react-redux";
import setLegendItemHover from "../analytics/datamappers/setLegendItemHover";
import savingsGraphOptions from "./savingsGraphOptions";
import { IRootState } from "../types/root-state";
import { AhuBlock, CumulativeEnergy, HpBlock } from "../types/leasegreen-core-api-exports";
import useCustomFetch from "../utils/hooks/useCustomFetch";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

interface IConsumption {
  name: string;
  stack: string;
  linkedTo: string;
  showInLegend: boolean;
  event: any;
}
export type Resource = "heating" | "water" | "electricity" | "cooling";
export type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface IBaseline {
  Desc: string;
  Month: MonthNumber;
  Target: number
}
interface ISavingsGraphProps {
  resource: Resource;
  selectedResource: Resource;
  norm: boolean;
  type: any;
  target: boolean;
  handover: Date;
  baselines: IBaseline[];
  color: string;
  hideResource: Function;
  showResource: Function;
  buildingId: number;
  setTotalEnergy: Function;
}

const SavingsGraph: FunctionComponent<ISavingsGraphProps> = ({
  resource,
  selectedResource,
  norm,
  type,
  target,
  handover,
  baselines,
  color,
  hideResource,
  showResource,
  buildingId,
  setTotalEnergy,
}) => {
  const chart = useRef(null);
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [consumptionData] = usePurchasedDataMapper(
    resource,
    resource === "heating" && norm ? "ConsNorm" : "Consumption",
    norm ? true : false
  );
  const targetData = useTargetDataMapper(resource, "Consumption");
  const ahuData = useSelector<IRootState, AhuBlock>((state) => state.buildingFilter.ahuData);
  const buildingTwinId = useSelector<IRootState, any>((state) => state.buildingFilter.buildingTwin.id);
  const hpData = useSelector<IRootState, HpBlock>((state) => state.buildingFilter.hpData);
  const [trendData] = useTrendsDataMapper(resource, type);

  const customFetch = useCustomFetch();
  const [cumulativeData, setCumulativeData] = useState<CumulativeEnergy[]>([]);
  useEffect(() => {
    customFetch({
      url: `cumulativeenergy/${buildingTwinId}`
    })
      .then(r => r.json() as unknown as CumulativeEnergy[])
      .then(setCumulativeData);
  }, [customFetch, buildingTwinId]);

  useEffect(() => {
    setLoading(true);
    let editedConsumption: IConsumption[] = [];
    if (consumptionData && consumptionData.length > 0) {
      if (consumptionData && consumptionData.length > 0) {
        consumptionData.forEach((c: IConsumption) => {
          editedConsumption.push({
            ...c,
            stack: c.name,
            linkedTo: c.name.toString(),
            showInLegend: false,
            event: null,
          });
        });
      }
    }
    const cumulativeConsNormData = cumulativeData
      .filter(a => a.Type.includes(selectedResource))
      .sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime())
      .map(a => {
        const d = new Date(a.Date);
        const time = new Date(Date.UTC(d.getFullYear(), d.getMonth()));
        return [time, a.ConsCumulative] as [Date, number]
      });
    let options = savingsGraphOptions({
      cumulativeConsNormData,
      targetData: target ? targetData : null,
      resource: resource,
      baselines: baselines,
      color: color,
    });
    setGraphData(options);
    const series = options?.series?.find((s) => s.name === "Actual savings");
    const dataContainsResource = cumulativeData?.filter(a => a.Type.includes(resource))?.length > 0;
    if (!dataContainsResource) {
      hideResource(resource);
    } else {
      showResource(resource);
      if (
        selectedResource === resource &&
        series?.data?.length > 0
      ) {
        const lastEntry = series.data[series.data.length - 1];
        if (lastEntry && lastEntry[1]) {
          setTotalEnergy(
            `${(lastEntry[1] as number).toFixed(2)} ${resource === "water" ? "m³" : "MWh"} `
          );
        }
      }
    }
  }, [
    consumptionData,
    targetData,
    ahuData,
    hpData,
    resource,
    target,
    trendData,
    norm,
    handover,
    baselines,
    color,
    hideResource,
    showResource,
    selectedResource,
    setTotalEnergy,
    buildingId,
    cumulativeData
  ]);

  // Additional useEffect to set loading to false when graphData changes
  useEffect(() => {
    if (graphData) {
      setLoading(false);
    }
  }, [graphData]);

  if (loading || loading) {
    return <Spinner style={{ height: "25rem" }} />;
  }

  // If not selected, return null
  if (selectedResource !== resource) {
    return null;
  }

  return (
    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={graphData}
      callback={setLegendItemHover}
      containerProps={{ style: { height: "auto" } }}
    />
  );
};

export default SavingsGraph;
