import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashBoardContent from "./DashBoardContent";
import { filter } from "lodash";
import { changebuilding } from "../customReducers/actions";
import { setBuilding } from "../layout/appbar/BuildingSelect";
import useQuery from "../utils/hooks/useQuery";
import useUserRole from "../utils/hooks/useUserRole";
import twinForExternal from "../utils/twinForExternal";

const DashBoard = (props) => {
  const building = useSelector((state) => state.buildingFilter.Building);
  const twinData = useSelector((state) => state.buildingFilter.buildingTwin);
  const alerts = useSelector((state) => state.buildingFilter.alerts);
  const buildingData = useSelector(
    (state) => state.buildingFilter.buildingData
  );
  const allTwins = useSelector((state) => state.buildingFilter.allTwins);
  const alertCount = useSelector(
    (state) => state.buildingFilter.activeAlertCount
  );
  const dispatch = useDispatch();

  const query = useQuery();

  const { isExternalUser } = useUserRole();

  // Mount
  useEffect(() => {
    const changeBuilding = (building, redirectTo, buildingTwinId) => {
      dispatch(changebuilding(building, redirectTo, buildingTwinId));
    };

    let id = query.get("id");
    let name = query.get("name");
    if (id && name) {
      setBuilding(
        { id: parseInt(id), ProjectName: name },
        true,
        changeBuilding
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter building twin from the twin data
  // If external user, filter all but zones
  const filteredTwins = filter(allTwins, (t) =>
    isExternalUser ? twinForExternal(t) : t.BlockType !== "BUILDING"
  );

  return (
    <DashBoardContent
      building={building}
      alerts={alerts}
      twinData={twinData}
      buildingData={buildingData}
      allTwins={filteredTwins}
      alertCount={alertCount}
    />
  );
};

export default DashBoard;
