import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  spacer: {
    left: 0,
    height: props => props.height,
    borderRadius: "0px 2px 2px 0px",
    width: 3,
    backgroundColor: props => props.color
  }
});

const Spacer = props => {
  const classes = useStyles(props);
  return <div style={props.style} className={classes.spacer}></div>;
};

export default Spacer;
