import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import moment from "moment-timezone";
import { raConsumptionsAndTemperaturesForScatterplot } from "../utils/raDataToHighcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import Boost from "highcharts/modules/boost";
import regression from "regression";
import { round, groupBy, sortBy, cloneDeep, filter } from "lodash";
import graphColor from "../utils/graphColor";
import handleLegendClick from "../analytics/datamappers/purchased/handleLegendClick";
import setVisibleYears from "../analytics/datamappers/purchased/setVisibleYears";
import handleTemperatureClick from "../analytics/datamappers/purchased/handleTemperatureClick";
import setLegendItemHover from "../analytics/datamappers/setLegendItemHover";
Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

window.moment = moment;

class PowerConsumptionScatterGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      projectname: this.props.projectname,
      consumptions: this.props.consumptions,
      open: false,
    };
  }

  medianTopPercentage = 10;
  regressionTopPercentage = 10;
  consumptionSeriesName = "Consumption";
  baseSeriesName = "Base load";
  peakSeriesName = "Peak load";

  componentDidMount() {
    const options = this.graphOptions(this.state.consumptions);
    this.setState({ options });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  // Get X highest values, for example from 1000 values return 100 values if percentage = 10;
  getPercentageValues(percentage, data) {
    let percCount = Math.floor(data.length * (percentage / 100));
    const srt = sortBy(data, (d) => d[1]);
    let retVal = [];
    let ind = srt.length - percCount - 1;
    if (ind < 0) {
      ind = 0;
    }
    for (let i = ind; i < srt.length; i++) {
      retVal.push(srt[i]);
    }
    return retVal;
  }

  median(numbers) {
    let median = null,
      numsLen = numbers.length;
    numbers = sortBy(numbers, (n) => n[1]);
    if (!numbers || numbers.length === 0) {
      return null;
    }
    if (
      numsLen % 2 ===
      0 // is even
    ) {
      // average of two middle numbers
      median = (numbers[numsLen / 2 - 1][1] + numbers[numsLen / 2][1]) / 2;
    } else {
      // is odd
      // middle number only
      median = numbers[(numsLen - 1) / 2][1];
    }
    return round(median, 2);
  }

  // Check if month is a summer month
  isSummerMonth(month) {
    let retVal = false;
    const summerMonths = [5, 6, 7, 8];
    summerMonths.forEach((m) => {
      if (month === m) {
        retVal = true;
      }
    });
    return retVal;
  }

  seriesMapping = (consumptions) => {
    let loadDataExists = false;
    // Get unique years from consumption data.
    const years = [
      ...new Set(
        consumptions.map((obj) => {
          const year = obj.Time.slice(0, 4);
          return year;
        })
      ),
    ];
    // create an array of series, one for each year.
    let series = [];
    let groupNames = [];
    let seriesNames = [];
    let yearIndex = 0;
    years.forEach((year) => {
      const graphdata = raConsumptionsAndTemperaturesForScatterplot(
        consumptions,
        year
      );

      // Only use "summer months"
      const filtered = filter(graphdata, (g) => this.isSummerMonth(g[2]));
      let maxPercVals = this.getPercentageValues(
        this.medianTopPercentage,
        filtered
      );
      const medianPower = this.median(maxPercVals);

      // Only use "winter months"
      const regressionGroup = filter(
        graphdata,
        (g) => g[1] >= medianPower && !this.isSummerMonth(g[2])
      );

      // Round all temperatures to 0 decimals then groupd them and use the highest X% consumption value for regression line
      let regData = null;
      let maxedData = [];
      if (regressionGroup.length > 0) {
        let roundedData = [];
        regressionGroup.forEach((g) => {
          let rounded = round(g[0], 0);
          if (rounded === -0) {
            rounded = 0;
          }
          roundedData.push([rounded, g[1]]);
        });
        let groupedData = groupBy(roundedData, (d) => d[0]);
        for (const k in groupedData) {
          if (groupedData.hasOwnProperty(k)) {
            const max = this.getPercentageValues(
              this.regressionTopPercentage,
              groupedData[k]
            );
            maxedData.push(...max);
          }
        }
        regData = regression.linear(maxedData);
      }

      // Remove the month from the graphdata (3rd element)
      graphdata.forEach((g) => (g.length = 2));
      if (
        graphdata.length > 0 &&
        !groupNames.includes(this.consumptionSeriesName)
      ) {
        groupNames.push(this.consumptionSeriesName);
      }
      if (!seriesNames.includes(this.consumptionSeriesName + " ")) {
        seriesNames.push(this.consumptionSeriesName + " ");
      }
      series.push({
        type: "scatter",
        name: this.consumptionSeriesName + " " + year,
        stack: parseInt(year),
        data: graphdata,
        marker: {
          symbol: "circle",
          radius: 2,
        },
        color: graphColor({
          index: yearIndex,
          seriesLength: years.length,
          type: "heating",
        }),
        visible: graphdata.length > 0 ? true : false,
        boostThreshold: 1,
        turboThreshold: 1,
        showInLegend: false,
      });
      if (regData && medianPower) {
        loadDataExists = true;
        regData.points.push(regData.predict(-30));
        // Filter out values where power < median and get the cutoff point
        let points = cloneDeep(regData.points);
        points = filter(points, (s) => s[1] > medianPower);
        points.push([
          round((medianPower - regData.equation[1]) / regData.equation[0], 2),
          medianPower,
        ]);
        // Sort the data once more
        const sortedData = sortBy(points, (p) => p[0]);
        if (!groupNames.includes(this.peakSeriesName)) {
          groupNames.push(this.peakSeriesName);
        }
        if (!seriesNames.includes(this.peakSeriesName + " ")) {
          seriesNames.push(this.peakSeriesName + " ");
        }
        series.push({
          name: this.peakSeriesName + " " + year,
          stack: parseInt(year),
          data: sortedData,
          type: "line",
          color: graphColor({
            index: yearIndex,
            seriesLength: years.length,
            lineSeries: true,
            offset: 0,
            type: "heating",
          }),
          marker: {
            enabled: false,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
          showInLegend: false,
          boostThreshold: 0,
          turboThreshold: 0,
        });

        if (!groupNames.includes(this.baseSeriesName)) {
          groupNames.push(this.baseSeriesName);
        }
        if (!seriesNames.includes(this.baseSeriesName + " ")) {
          seriesNames.push(this.baseSeriesName + " ");
        }
        series.push({
          name: this.baseSeriesName + " " + year,
          data: [
            [-30, medianPower],
            [30, medianPower],
          ],
          type: "line",
          dashStyle: "longdash",
          color: graphColor({
            index: yearIndex,
            seriesLength: years.length,
            lineSeries: true,
            offset: 0,
            type: "heating",
          }),
          states: {
            inactive: {
              opacity: 1,
            },
          },
          showInLegend: false,
          boostThreshold: 0,
          turboThreshold: 0,
        });
      }
      // Master group for each year for controlling visibility
      series.push({
        name: parseInt(year),
        data: [[null, null]],
        type: "scatter",
        color: graphColor({
          index: yearIndex,
          seriesLength: years.length,
          type: "heating",
        }),
        marker: {
          symbol: "circle",
        },
        showInLegend: graphdata.length > 0 ? true : false,
        boostThreshold: 0,
        turboThreshold: 0,
        events: {
          legendItemClick: (event) => {
            // This is done to prevent multiple events from being bound on re-rendering of the series
            if (event.target.hcEvents.legendItemClick.length > 1) {
              event.target.hcEvents.legendItemClick.length = 1;
            }
            event.preventDefault();
            handleLegendClick(event.target, groupNames, seriesNames);
            return false;
          },
        },
      });
      yearIndex++;
    });
    // Add legend buttons (dummy series) for controlling the visibility of different series
    if (loadDataExists) {
      series.push({
        name: this.consumptionSeriesName,
        data: null,
        // Area type has a circle as legend symbol but does not affect the size of other column series
        type: "area",
        showInLegend: true,
        visible: true,
        color: "#000",
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(
              event.target,
              this.consumptionSeriesName + " "
            );
            return false;
          },
        },
      });
      series.push({
        name: this.peakSeriesName,
        data: null,
        // Area type has a circle as legend symbol but does not affect the size of other column series
        type: "area",
        showInLegend: true,
        visible: true,
        color: "#000",
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(event.target, this.peakSeriesName + " ");
            return false;
          },
        },
      });
      series.push({
        name: this.baseSeriesName,
        data: null,
        // Area type has a circle as legend symbol but does not affect the size of other column series
        type: "area",
        showInLegend: true,
        visible: true,
        color: "#000",
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(event.target, this.baseSeriesName + " ");
            return false;
          },
        },
      });
    }
    // Only three newest years are visible by default
    series = setVisibleYears(series);
    return series;
  };

  graphOptions = (consumptions) => {
    return {
      chart: {
        zoomType: "xy",
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true,
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        scatter: {
          tooltip: {
            pointFormat:
              "x: <b>{point.x:.1f}</b><br/>y: <b>{point.y:.0f}</b><br/>",
          },
        },
      },
      series: this.seriesMapping(consumptions),
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: "Power consumption",
        },
        format: "{value} kWm",
        min: 0,
        gridLineWidth: 1,
      },
      xAxis: {
        format: "{value}°C",
        title: {
          text: "Outdoor temperature",
        },
        min: -35,
        max: 35,
        minPadding: 0,
        maxPadding: 0,
      },
    };
  };

  render() {
    return (
      <Card>
        {this.state.options && [
          <div key="help" style={{ textAlign: "right" }}>
            <Tooltip title="Data info">
              <IconButton onClick={this.handleOpen}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </div>,
          <Dialog
            key="dialog"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <DialogTitle>Data info</DialogTitle>
            <DialogContent>
              <h4>{this.baseSeriesName + ":"}</h4>
              <ul style={{ listStyle: "decimal" }}>
                <li>Extract data from summer months (June, July, August)</li>
                <li>
                  Get the highest {this.medianTopPercentage}% of the power
                  values
                </li>
                <li>Calculate the median of those power values</li>
              </ul>
              <h4>{this.peakSeriesName + ":"}</h4>
              <ul style={{ listStyle: "decimal" }}>
                <li>
                  Extract data from all months, excluding the summer months
                </li>
                <li>
                  Remove all values where the power is below the previously
                  calculated median power value
                </li>
                <li>
                  Round all temperature values to full degrees (0 decimals)
                </li>
                <li>
                  Get the highest {this.regressionTopPercentage}% of the power
                  values for each temperature value
                </li>
                <li>Draw a regression line for the calculated values</li>
              </ul>
            </DialogContent>
          </Dialog>,
          <HighchartsReact
            key="chart"
            highcharts={Highcharts}
            options={this.state.options}
            callback={setLegendItemHover}
          />,
        ]}
      </Card>
    );
  }
}

export default PowerConsumptionScatterGraph;
