import React from "react";
import { List, Datagrid, TextField, Filter, TextInput } from "react-admin";
import { useSelector } from "react-redux";

import BuildingsArrayField from "./BuildingsArrayField";
import PageTitle from "../components/layout/PageTitle";

const UserFilter = ({ BuildingId, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search name" source="q" alwaysOn />
  </Filter>
);

const GuestUserList = (props) => {
  const buildings = useSelector((state) => state.buildingFilter.buildings);
  return (
    <>
      <PageTitle title="Guest Users" />
      <List {...props} bulkActionButtons={false} filters={<UserFilter />} pagination={null}>
        <Datagrid rowClick="edit">
          <TextField source="id" sortable={false} />
          <TextField source="displayName" sortable={false} />
          <TextField source="userPrincipalName" sortable={false} />
          <TextField source="mail" sortable={false} />
          <BuildingsArrayField
            source="buildingIds"
            label="Buildings"
            sortable={false}
            addLabel
            buildings={buildings}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default GuestUserList;
