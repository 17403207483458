import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { sortBy } from "lodash";
import { ZoneBlockCo2Measurements } from "./ZoneBlockCo2Measurements";
import { ZoneBlockHumidityMeasurements } from "./ZoneBlockHumidityMeasurements";
import { ZoneBlockTemperatureMeasurements } from "./ZoneBlockTemperatureMeasurements";
import ZoneTwinInfo from "./ZoneTwinInfo";
import ZoneFeedback from "./ZoneFeedback";
import ZoneNPSGraph from "./ZoneNPSGraph";
import useBuildingTime from "../utils/hooks/useBuildingTime";
import useBuildingTimezone from "../utils/hooks/useBuildingTimezone";
import CardWithTitle from "../components/layout/CardWithTitle";
import OperationLogs from "../dashboard/logs/OperationLogs";
import useUserRole from "../utils/hooks/useUserRole";
import { ZoneBlockPressureMeasurements } from "./ZoneBlockPressureMeasurements";

const ZoneShow = ({ formData, zoneImage, zoneFeedback }) => {
  const [zoneData, setZoneData] = useState(null);
  const [avgTemp, setAvgTemp] = useState(null);
  const [minTemp, setMinTemp] = useState(null);
  const [maxTemp, setMaxTemp] = useState(null);
  const [avgCo2, setAvgCo2] = useState(null);
  const [minCo2, setMinCo2] = useState(null);
  const [maxCo2, setMaxCo2] = useState(null);
  const [avgHumidity, setAvgHumidity] = useState(null);
  const [minHumidity, setMinHumidity] = useState(null);
  const [maxHumidity, setMaxHumidity] = useState(null);
  const [opLogs, setOpLogs] = useState(null);
  const [hiddenContainers, setHiddenContainers] = useState([
    "feedback",
    "nps",
    "temperature",
    "co2",
    "humidity",
    "pressure",
  ]);
  const buildingTimezone = useBuildingTimezone();
  const toBuildingTime = useBuildingTime();
  const { isInternalAdmin } = useUserRole();

  // Feedbacks are used on all "condition" graphs
  let feedbackSeries = {
    name: "Feedbacks",
    type: "line",
    data: [],
    yAxis: 2,
    lineWidth: 0,
    states: {
      hover: {
        lineWidthPlus: 0,
      },
      inactive: {
        opacity: 1,
      },
    },
    marker: {
      enabled: true,
      symbol: "circle",
      radius: 10,
    },
    dataGrouping: {
      enabled: false,
    },
    boostThreshold: 0,
    turboThreshold: 0,
  };

  let operationSeries = {
    name: "Operations",
    type: "line",
    data: [],
    yAxis: 2,
    lineWidth: 0,
    states: {
      hover: {
        lineWidthPlus: 0,
      },
      inactive: {
        opacity: 1,
      },
    },
    marker: {
      enabled: true,
      symbol: "circle",
      radius: 10,
    },
    dataGrouping: {
      enabled: false,
    },
    boostThreshold: 0,
    turboThreshold: 0,
  };

  useEffect(() => {
    if (formData.Zone && !zoneData) {
      setZoneData(formData.Zone);
    }
  }, [formData.Zone, zoneData]);

  const handleContainerHide = useCallback(
    (container) => {
      if (!hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if (hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers]
  );

  if (
    formData.BuildingId &&
    (formData.Zone || zoneData) &&
    formData.BlockType === "ZONE"
  ) {
    let zone = formData.Zone ? formData.Zone : zoneData;
    let _formData = { ...formData, Zone: zone };

    const handleLatestTemp = (d) => {
      setAvgTemp({ Value: d.temps[1], UpdateTime: d.measured });
      setMinTemp({ Value: d.temps[2], UpdateTime: d.measured });
      setMaxTemp({ Value: d.temps[3], UpdateTime: d.measured });
    };

    const handleLatestHumidity = (d) => {
      setAvgHumidity({ Value: d.humis[0], UpdateTime: d.measured });
      setMinHumidity({ Value: d.humis[1], UpdateTime: d.measured });
      setMaxHumidity({ Value: d.humis[2], UpdateTime: d.measured });
    };

    const handleLatestCo2 = (d) => {
      setAvgCo2({ Value: d.co2s[0], UpdateTime: d.measured });
      setMinCo2({ Value: d.co2s[1], UpdateTime: d.measured });
      setMaxCo2({ Value: d.co2s[2], UpdateTime: d.measured });
    };

    const handleLogs = (logs) => {
      if (logs && logs.length > 0) {
        logs.forEach((l) => {
          const date = toBuildingTime(l.CreatedAt);
          let finalDate = date.valueOf();
          operationSeries.data.push({
            x: finalDate,
            y: 1,
            text: l.Title,
          });
        });
        operationSeries.data = sortBy(operationSeries.data, (f) => f.x);
        setOpLogs(operationSeries);
      }
    };

    if (
      zoneFeedback &&
      zoneFeedback.feedbacks &&
      zoneFeedback.feedbacks.length > 0
    ) {
      zoneFeedback.feedbacks.forEach((f) => {
        const date = toBuildingTime(f.feedbackDate);
        let finalDate = date.valueOf();
        feedbackSeries.data.push({
          x: finalDate,
          text: f.feedbackText,
          score: f.feedbackScore,
        });
      });
      feedbackSeries.data = sortBy(feedbackSeries.data, (f) => f.x);
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ZoneTwinInfo
            avgTemp={avgTemp}
            minTemp={minTemp}
            maxTemp={maxTemp}
            avgCo2={avgCo2}
            minCo2={minCo2}
            maxCo2={maxCo2}
            avgHumidity={avgHumidity}
            minHumidity={minHumidity}
            maxHumidity={maxHumidity}
            zoneImage={zoneImage}
            zoneFeedback={zoneFeedback}
            {..._formData}
          />
        </Grid>
        <Grid
          style={{
            display:
              hiddenContainers.includes("feedback") && (!isInternalAdmin)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
          lg={5}
        >
          <ZoneFeedback
            {...zoneFeedback}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          style={{
            display:
              hiddenContainers.includes("nps") && (!isInternalAdmin)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
          lg={7}
        >
          <ZoneNPSGraph
            {...zoneFeedback}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          style={{
            display:
              (!isInternalAdmin) && (!opLogs || opLogs.length === 0)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
          lg={5}
        >
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs
              buildingId={_formData.BuildingId}
              twinId={_formData.id}
              logsCb={handleLogs}
            />
          </CardWithTitle>
        </Grid>
        <Grid
          style={{
            display:
              hiddenContainers.includes("temperature") &&
                (!isInternalAdmin)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
        >
          <ZoneBlockTemperatureMeasurements
            timezone={buildingTimezone}
            latestTemp={handleLatestTemp}
            feedbackSeries={
              feedbackSeries.data.length > 0 ? feedbackSeries : null
            }
            operationSeries={opLogs && opLogs.data.length > 0 ? opLogs : null}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          style={{
            display:
              hiddenContainers.includes("co2") && (!isInternalAdmin)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
        >
          <ZoneBlockCo2Measurements
            timezone={buildingTimezone}
            latestCo2={handleLatestCo2}
            feedbackSeries={
              feedbackSeries.data.length > 0
                ? { ...feedbackSeries, yAxis: 1 }
                : null
            }
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          style={{
            display:
              hiddenContainers.includes("humidity") && (!isInternalAdmin)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
        >
          <ZoneBlockHumidityMeasurements
            timezone={buildingTimezone}
            latestHumidity={handleLatestHumidity}
            feedbackSeries={
              feedbackSeries.data.length > 0
                ? { ...feedbackSeries, yAxis: 1 }
                : null
            }
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          style={{
            display:
              hiddenContainers.includes("pressure") && (!isInternalAdmin)
                ? "none"
                : undefined,
          }}
          item
          xs={12}
        >
          <ZoneBlockPressureMeasurements
            timezone={buildingTimezone}
            feedbackSeries={
              feedbackSeries.data.length > 0
                ? { ...feedbackSeries, yAxis: 1 }
                : null
            }
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default ZoneShow;
