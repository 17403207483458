import React, { useState, useEffect, useCallback } from "react";
import Spinner from "../components/layout/Spinner";
import { get } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  makeStyles,
  Grid,
  IconButton,
  Button,
  MenuItem,
  Typography,
  Select,
  withStyles,
  Input,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import PageTitle from "../components/layout/PageTitle";
import CardWithTitle from "../components/layout/CardWithTitle";
import BuildingStats from "./buildingstats/BuildingStats";
import FavoriteButton from "./buildingstats/FavoriteButton";
import OperationLogs from "./logs/OperationLogs";
import TargetGraphs from "./targetgraphs/TargetGraphs";
import DigitalTwins from "./digitaltwins/DigitalTwins";
import editIcon from "../assets/icons/icon_edit.svg";
import sharepointIcon from "../assets/images/image_sharepoint.png";
import SavingsGraph from "./SavingsGraph";
import useUserRole from "../utils/hooks/useUserRole";

// Create custom input for Select component to allow changing of the underline color
const CustomInput = withStyles({
  underline: {
    "&:after": {
      borderBottomColor: "green",
    },
  },
})(Input);

const useStyles = makeStyles((theme) => ({
  container: {},
  spRoot: {
    height: "2.3125rem",
    marginRight: "1rem",
    border: "1px solid #D1D7DB",
    borderRadius: "2px",
  },
  spIcon: {
    width: "1.2rem",
    height: "1.2rem",
  },
  editIcon: {
    opacity: "60%",
    width: "1.2rem",
    height: "1.2rem",
  },
  spLabel: {
    textTransform: "none",
    color: "#7D7A83",
  },
  root: {
    marginTop: "1rem",
    flexGrow: 1,
    justifyContent: "center",
  },
  resourceRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  savingsContainer: {
    padding: "0 3rem",
  },
  select: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem",
  },
  underline: (props) => ({
    "&:after": {
      borderBottomColor: props.color,
    },
  }),
  savingsText: {
    fontSize: "0.875rem",
    fontWeight: 600,
    textAlign: "center",
  },
  savingsLabel: {
    fontSize: "0.75rem",
    fontWeight: 300,
    textAlign: "center",
    color: "#7D7A83",
  },
}));

const colors = {
  heating: "#FFA11F",
  water: "#1A67D6",
  electricity: "#00975A",
  cooling: "#1AC5D6",
};

const DashBoardContent = (props) => {
  const [selectedResource, setSelectedResource] = useState("heating");
  const classes = useStyles({ color: colors[selectedResource] });
  const [hiddenResources, setHiddenResources] = useState([]);
  const [totalSavedEnergy, setTotalSavedEnergy] = useState("");

  const alerts = props.alerts;
  const twinData = props.twinData;
  const buildingData = props.buildingData;
  const alertCount = props.alertCount;
  const { isExternalUser, isAdminOrUser } = useUserRole();

  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (twinData && buildingData) {
      setSelectedResource("heating");
      setHiddenResources([]);
    }
  }, [twinData, buildingData]);

  // Scroll to top on mount
  useEffect(() => {
    const top = document.getElementById("scroll-top");
    if (top) {
      top.scrollIntoView();
    }
  }, []);

  const handleResourceChange = (e) => {
    setSelectedResource(e.target.value);
  };

  const handleResourceHide = useCallback((res) => {
    setHiddenResources((prevHidden) => {
      if (!prevHidden.includes(res)) {
        return [...prevHidden, res];
      }
      return prevHidden;
    });
  }, []);

  const handleResourceShow = useCallback((res) => {
    setHiddenResources((prevHidden) => {
      if (prevHidden.includes(res)) {
        return prevHidden.filter((r) => r !== res);
      }
      return prevHidden;
    });
  }, []);

  // Each time hiddenResources changes, change the selected resource if necessary
  useEffect(() => {
    const possibleResources = ["heating", "water", "electricity", "cooling"];
    // Set current selection as the "top" one
    for (let i = 0; i < possibleResources.length; i++) {
      const p = possibleResources[i];
      if (!hiddenResources.includes(p)) {
        setSelectedResource(p);
        break;
      }
    }
  }, [hiddenResources]);

  let projectName = "No Building Selected";
  if (buildingData && buildingData.projectName) {
    projectName = buildingData.projectName;
  }

  // Form the subheader
  const address = get(buildingData, "address.StreetAddress", "Unknown street");
  const zip = get(buildingData, "address.ZipCode", "Unknown Zip Code");
  const city = get(buildingData, "address.City", "Unknown City");
  const country = get(buildingData, "address.Country", "Unknown Country");
  const type = get(buildingData, "buildingType", "");

  const firstPart = type && type !== "" ? type + " at " : "";

  const location =
    firstPart + address + ", " + zip + ", " + city + ", " + country;

  if (!twinData || !buildingData || !props.allTwins) {
    return (
      <div className={classes.container}>
        <PageTitle title="Dashboard" activeCount={alertCount} />
        <Spinner />
      </div>
    );
  }

  const editButton =
    twinData && twinData.id && isAdminOrUser ? (
      <Grid item>
        {isMediumOrSmaller ? (
          <IconButton
            className={classes.editIcon}
            component={RouterLink}
            to={"/buildings/" + buildingData.id}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        ) : (
          <Button
            component={RouterLink}
            to={"/buildings/" + buildingData.id}
            classes={{
              root: classes.spRoot,
              label: classes.spLabel,
              startIcon: classes.editIcon,
            }}
            variant="outlined"
            startIcon={<img src={editIcon} alt="edit" />}
          >
            Edit
          </Button>
        )}
      </Grid>
    ) : null;

  const cardActions = (
    <Grid
      container
      spacing={1}
      direction={isMediumOrSmaller ? "column-reverse" : "row"}
      alignItems="flex-end"
    >
      {!isExternalUser && (
        <Grid item>
          <FavoriteButton
            key="favbutton"
            buildingId={buildingData.id}
            isMobile={isMediumOrSmaller}
          />
        </Grid>
      )}
      {!isExternalUser && (
        <Grid item>
          {isMediumOrSmaller ? (
            <IconButton
              component="a"
              href={get(buildingData, "projectWorkspaceURL", "#")}
              rel="noopener"
              target="_blank"
            >
              <img
                className={classes.spIcon}
                src={sharepointIcon}
                alt="sharepoint"
              />
            </IconButton>
          ) : (
            <Button
              key="sp"
              href={get(buildingData, "projectWorkspaceURL", "#")}
              rel="noopener"
              target="_blank"
              classes={{ root: classes.spRoot, label: classes.spLabel }}
              variant="outlined"
              startIcon={
                <img
                  className={classes.spIcon}
                  src={sharepointIcon}
                  alt="sharepoint"
                />
              }
            >
              Open in Sharepoint
            </Button>
          )}
        </Grid>
      )}
      {editButton && editButton}
    </Grid>
  );

  // Handover date
  const handoverDate = get(twinData, "Energy.HandoverDate", null);

  // Resource select and saved values
  const resourceSelect = (
    <div className={classes.select}>
      <Select
        style={{ marginRight: "auto" }}
        value={selectedResource}
        onChange={handleResourceChange}
        input={<CustomInput classes={{ underline: classes.underline }} />}
      >
        {!hiddenResources.includes("heating") && (
          <MenuItem value={"heating"}>Heating</MenuItem>
        )}
        {!hiddenResources.includes("water") && (
          <MenuItem value={"water"}>Water</MenuItem>
        )}
        {!hiddenResources.includes("electricity") && (
          <MenuItem value={"electricity"}>Electricity</MenuItem>
        )}
        {!hiddenResources.includes("cooling") && (
          <MenuItem value={"cooling"}>Cooling</MenuItem>
        )}
      </Select>
      <div className={classes.savingsContainer} style={{ marginRight: "auto" }}>
        <Typography className={classes.savingsText}>
          {totalSavedEnergy}
        </Typography>
        <Typography className={classes.savingsLabel}>Total savings</Typography>
      </div>
      {/* <div className={classes.savingsContainer}>
        <Typography className={classes.savingsText}>
          {totalSavedCO2}
        </Typography>
        <Typography className={classes.savingsLabel}>CO2 savings</Typography>
      </div>
      <div className={classes.savingsContainer}>
        <Typography className={classes.savingsText}>
          {totalSavedEuros}
        </Typography>
        <Typography className={classes.savingsLabel}>Euro savings</Typography>
      </div> */}
    </div>
  );

  return (
    <div className={classes.container}>
      <PageTitle title="Dashboard" activeCount={alertCount} />
      <Grid container className={classes.root} spacing={4}>
        <Grid item xs={12} lg={8}>
          <CardWithTitle
            spacerColor="#2E2247"
            title={projectName}
            subheader={location}
            action={cardActions}
          >
            <BuildingStats buildingTwin={twinData} building={buildingData} />
          </CardWithTitle>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs buildingId={buildingData ? buildingData.id : null} />
          </CardWithTitle>
        </Grid>
        {buildingData && buildingData.id ? (
          <TargetGraphs
            building={buildingData}
            buildingTwin={twinData}
            xs={12}
            md={6}
            lg={3}
          />
        ) : null}
        {handoverDate && (
          <Grid
            style={{
              display: hiddenResources.length < 4 ? "block" : "none",
            }}
            item
            xs={12}
          >
            <CardWithTitle title="Cumulative savings since handover">
              {resourceSelect}
              <SavingsGraph
                buildingId={buildingData ? buildingData.id : null}
                resource="heating"
                selectedResource={selectedResource}
                target
                norm
                handover={new Date(handoverDate)}
                baselines={
                  twinData && twinData.MonthlyBaselines
                    ? twinData.MonthlyBaselines.filter((b) =>
                      b.Desc.includes("HEAT")
                    )
                    : []
                }
                color={colors.heating}
                hideResource={handleResourceHide}
                showResource={handleResourceShow}
                setTotalEnergy={setTotalSavedEnergy}
              />
              <SavingsGraph
                buildingId={buildingData ? buildingData.id : null}
                resource="water"
                selectedResource={selectedResource}
                target
                norm={false}
                handover={new Date(handoverDate)}
                baselines={
                  twinData && twinData.MonthlyBaselines
                    ? twinData.MonthlyBaselines.filter((b) =>
                      b.Desc.includes("WATER")
                    )
                    : []
                }
                color={colors.water}
                hideResource={handleResourceHide}
                showResource={handleResourceShow}
                setTotalEnergy={setTotalSavedEnergy}
              />
              <SavingsGraph
                buildingId={buildingData ? buildingData.id : null}
                resource="electricity"
                selectedResource={selectedResource}
                target
                norm={false}
                handover={new Date(handoverDate)}
                baselines={
                  twinData && twinData.MonthlyBaselines
                    ? twinData.MonthlyBaselines.filter((b) =>
                      b.Desc.includes("ELECTRICITY")
                    )
                    : []
                }
                color={colors.electricity}
                hideResource={handleResourceHide}
                showResource={handleResourceShow}
                setTotalEnergy={setTotalSavedEnergy}
              />
              <SavingsGraph
                buildingId={buildingData ? buildingData.id : null}
                resource="cooling"
                selectedResource={selectedResource}
                target
                norm={false}
                handover={new Date(handoverDate)}
                baselines={
                  twinData && twinData.MonthlyBaselines
                    ? twinData.MonthlyBaselines.filter((b) =>
                      b.Desc.includes("COOLING")
                    )
                    : []
                }
                color={colors.cooling}
                hideResource={handleResourceHide}
                showResource={handleResourceShow}
                setTotalEnergy={setTotalSavedEnergy}
              />
            </CardWithTitle>
          </Grid>
        )}
        <Grid item xs={12} lg={12}>
          <CardWithTitle spacerColor="#2E2247" title="Digital twins">
            <DigitalTwins
              building={buildingData}
              allTwins={props.allTwins}
              alerts={!isExternalUser && alerts}
            />
          </CardWithTitle>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoardContent;
