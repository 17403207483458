import React from "react";
import { useSelector } from "react-redux";
import {
  AutocompleteArrayInput,
  Edit,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
} from "react-admin";
import { FormDataConsumer } from "react-admin";
import { makeStyles } from "@material-ui/core";
import OperationTooltip from "./OperationTooltip";
import PageTitle from "../components/layout/PageTitle";
import { stateMapper } from "../customReducers/connectFunctions";
import twinForExternal from "../utils/twinForExternal";

const blockOptions = (choice) =>
  `${choice.BlockName} - ${choice.BlockDescription}`;

const useStyles = makeStyles(() => ({
  formInputs: {
    width: "70%",
  },
  hidden: {
    display: "none",
  },
}));

const ExternalEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const OperationsEditCustomer = ({ BuildingId, ...props }) => {
  const classes = useStyles();

  const building = useSelector((state) => state.buildingFilter.Building);

  // Remove unused props to get rid of errors
  let editProps = Object.assign({}, props);
  delete editProps.buildingTwinId;
  delete editProps.buildingSelected;
  delete editProps.dispatch;
  return (
    <>
      <PageTitle title="Edit Operation" />
      <Edit {...editProps}>
        <SimpleForm redirect="list" toolbar={<ExternalEditToolbar />}>
          {building && (
            <TextField
              record={building}
              source="ProjectName"
              label="Building"
            />
          )}

          <BooleanInput
            className={classes.hidden}
            label="Visible to customer"
            source="VisibleToExternal"
            defaultValue={true}
            disabled
          />

          <OperationTooltip field="Title">
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <TextInput
                  className={classes.formInputs}
                  source="Title"
                  validate={required("Title required")}
                  disabled={formData.OperationMeta !== 14}
                />
              )}
            </FormDataConsumer>
          </OperationTooltip>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <OperationTooltip field="OperationBlocks">
                <ReferenceArrayInput
                  label="Digital twins"
                  source="OperationBlocks"
                  reference="blocks"
                  resource="blocks"
                  perPage={1000}
                  sort={{ field: "BlockName", order: "ASC" }}
                  filter={{
                    BuildingId: formData.BuildingId,
                  }}
                  fullWidth
                  options={{
                    disabled: formData.OperationMeta !== 14,
                  }}
                >
                  <AutocompleteArrayInput
                    optionText={blockOptions}
                    optionValue="id"
                    shouldRenderSuggestions={() =>
                      formData.OperationMeta === 14
                    }
                    matchSuggestion={(_filter, choice) => {
                      return twinForExternal(choice);
                    }}
                  />
                </ReferenceArrayInput>
              </OperationTooltip>
            )}
          </FormDataConsumer>

          <TextInput
            multiline
            label="Comments"
            source="ExternalComment"
            className={classes.formInputs}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default stateMapper(OperationsEditCustomer);
