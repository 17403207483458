import React, { forwardRef, cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const NavLinkRef = React.forwardRef((props, ref) => {
  // Delete unused props
  const navProps = Object.assign({}, props);
  delete navProps.noBottomBorder;
  return <NavLink innerRef={ref} {...navProps} />;
});

const useStyles = makeStyles(
  (theme) => ({
    root: {
      // color: theme.palette.text.secondary
      color: "#9590a0",
      fontSize: "1em",
      paddingTop: 16,
      paddingBottom: 16,
      borderBottom: (props) =>
        props.noBottomBorder ? "none" : "1px solid #463d5c",
    },
    active: {
      // color: theme.palette.text.primary
      color: "#1ad689",
      borderLeft: "5px solid #1ad689",
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: "LGHomeButton" }
);

const HomeButton = forwardRef(
  (
    {
      classes: classesOverride,
      className,
      primaryText,
      leftIcon,
      onClick,
      sidebarIsOpen,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({
      classes: classesOverride,
      noBottomBorder: props.noBottomBorder,
    });

    const handleMenuTap = useCallback(
      (e) => {
        onClick && onClick(e);
      },
      [onClick]
    );

    const renderMenuItem = () => {
      return (
        <MenuItem
          className={classnames(classes.root, className)}
          activeClassName={classes.active}
          component={NavLinkRef}
          ref={ref}
          {...props}
          onClick={handleMenuTap}
        >
          {leftIcon && (
            <ListItemIcon className={classes.icon}>
              {cloneElement(leftIcon, {
                titleAccess: primaryText,
              })}
            </ListItemIcon>
          )}
          {primaryText}
        </MenuItem>
      );
    };

    if (sidebarIsOpen) {
      return renderMenuItem();
    }

    return (
      <Tooltip title={primaryText} placement="right">
        {renderMenuItem()}
      </Tooltip>
    );
  }
);

HomeButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  staticContext: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default HomeButton;
