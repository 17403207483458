import React, { Component } from "react";
import {
  NumberField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  TextInput,
  NullableBooleanInput,
  UrlField,
  GET_LIST,
  showNotification,
} from "react-admin";

import { getBuildings } from "./BuildingActions";

import { restClient } from "../App";

import { connect } from "react-redux";

import FavoriteBuildingField from "./FavoriteBuildingField";

import BuildingShowButton from "./BuildingShowButton";
import PageTitle from "../components/layout/PageTitle";

const BuildingFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <NullableBooleanInput label="Report" source="generateReport" />
    <NullableBooleanInput label="Compensate" source="useCompensation" />
    <NullableBooleanInput
      label="RAU-connection"
      source="RegisterListsVisible"
    />
    <NullableBooleanInput label="Favorite" source="FavoritedByCurrentUser" />
  </Filter>
);

class BuildingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: [],
      favoriteDeleted: false,
      favoriteAdded: false,
      loading: true,
    };
  }
  // Fetch favorites when component mounts
  componentDidMount() {
    this.getAllFavorites();
  }

  componentDidUpdate() {
    // Fetch all favorites again when favorites are added or deleted
    if (this.state.favoriteAdded || this.state.favoriteDeleted) {
      this.getAllFavorites();
    }
  }

  getAllFavorites() {
    // Callback of setState is used to refresh the list
    restClient(GET_LIST, "user/favorites", {
      sort: { field: "ProjectName", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    }).then((r) => {
      this.setState(
        {
          favorites: r.data,
          favoriteDeleted: false,
          favoriteAdded: false,
          loading: false,
        },
        () =>
          this.props.getBuildings(
            { page: 1, perPage: 5 },
            { field: "ProjectName", order: "ASC" }
          )
      );
    });
  }

  favoriteAddedHandler = (id) => {
    // Form the data for adding a favorite. Id is autogenerated by backend, SourceId = building id
    const payload = {
      Id: id,
      SourceId: id,
      FavoriteType: 1,
    };
    const params = {
      id: "favorites",
      data: payload,
    };
    restClient("UPDATE", "user", params)
      .then((response) => {
        //this.props.showNotification("Favorite Added");
        this.setState({ favoriteAdded: true, loading: true });
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        //this.props.showNotification("Error", "warning");
      });
  };

  favoriteDeletedHandler = (id) => {
    const fav = this.state.favorites.find((f) => f.SourceId === id);
    const favId = fav.Id;
    const params = {
      id: favId,
    };
    restClient("DELETE", "user/favorites", params).then((r) => {
      //this.props.showNotification("Favorite Removed");
      this.setState({ favoriteDeleted: true, loading: true });
    });
  };

  render() {
    // Remove unused props to get rid of errors
    let listProps = Object.assign({}, this.props);
    delete listProps.getBuildings;
    delete listProps.showNotification;

    return (
      <>
        <PageTitle title="Buildings" />
        <List
          filters={<BuildingFilter />}
          filterDefaultValues={{ ShowBAUI: true }}
          bulkActionButtons={false}
          sort={{ field: "ProjectName", order: "DESC" }}
          favorites={this.state.favorites}
          {...listProps}
        >
          <Datagrid favorites={this.state.favorites}>
            <NumberField source="id" options={{ useGrouping: false }} />
            <TextField source="ProjectName" />
            <UrlField
              source="ProjectWorkspaceURL"
              label="Project workspace URL"
              target="_blank"
              rel="noopener noreferrer"
            />
            <BooleanField source="UseCompensation" />
            {/* TODO: Delete, not used? */}
            {/* <BooleanField source="GenerateReport" /> */}
            <FavoriteBuildingField
              label={"Favorite"}
              addLabel={true}
              sortBy="FavoritedByCurrentUser"
              favorites={this.state.favorites}
              favoriteAdded={this.favoriteAddedHandler}
              favoriteDeleted={this.favoriteDeletedHandler}
              {...this.props}
            />
            <BuildingShowButton />
          </Datagrid>
        </List>
      </>
    );
  }
}

export default connect(null, {
  getBuildings,
  showNotification,
})(BuildingList);
