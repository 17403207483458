import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import createYearGroups from "../analytics/datamappers/consumed/createYearGroups";
import setStackColor from "../analytics/datamappers/consumed/setStackColor";
import setVisibleYears from "../analytics/datamappers/purchased/setVisibleYears";
import handleTemperatureClick from "../analytics/datamappers/purchased/handleTemperatureClick";
import graphColor, { electricityColors } from "../utils/graphColor";
import EmptyData from "../components/layout/EmptyData";
import { find, groupBy, map, round, sortBy, meanBy } from "lodash";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import setLegendItemHover from "../analytics/datamappers/setLegendItemHover";
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (record, id) => {
  let y = find(record.Trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

const raReportingDataToHighchartsData = (dataIn) => {
  const sortedByMonth = sortBy(dataIn, "Month");

  const data = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  for (let i = 0; i < 12; i++) {
    var current = sortedByMonth[i];
    if (current) {
      data[current.Month - 1] = round(current.Value, 2);
    }
  }

  return data;
};

export class SolarMonthlyEnergyGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        title: {
          text: "",
        },
      },
      record: this.props,
      loading: true,
      fetchDone: false,
    };
  }

  componentDidMount() {
    restClient(GET_LIST, "reportdata", {
      filter: {
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props, "SOLAR_ENERGY_MONTHLY"),
        resolution: "month",
        trend: "SOLAR_ENERGY_MONTHLY",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    }).then((response) => {
      this.setState({
        options: this.graphOptions(response.data, this.props.efficiencyData),
        fetchDone: true,
      });
    });
  }

  graphOptions = (data, efficiencyData = null) => {
    const grouped = groupBy(data, "Id");
    const keys = Object.keys(grouped);
    const groupedEff = groupBy(efficiencyData, "Id");
    const keysEff = Object.keys(groupedEff);
    let series = [];
    let producedExists = false;
    let efficiencyExists = efficiencyData && efficiencyData.length > 0;

    let trend = find(
      this.state.record.Trends,
      (y) => y.TrendId === "SOLAR_ENERGY_MONTHLY"
    );
    let effTrend = find(
      this.state.record.Trends,
      (y) => y.TrendId === "SOLAR_ENERGY_EFFICIENCY_DAILY"
    );

    if (!trend) {
      return null;
    }

    keys.forEach((k) => {
      const groupedByYear = groupBy(grouped[k], "Year");
      const years = Object.keys(groupedByYear);
      years.forEach((y) => {
        let trendValue = find(
          trend.Values,
          (v) => v.ReportingPointId === Number(k)
        );
        producedExists = true;
        series.push({
          name: trendValue.Legend + " " + y,
          stack: parseInt(y),
          showInLegend: false,
          data: raReportingDataToHighchartsData(groupedByYear[y]),
        });
      });
    });

    // Add the efficiency series
    if (efficiencyExists) {
      keysEff.forEach((k) => {
        const groupedByYear = groupBy(groupedEff[k], (d) =>
          new Date(d.Date).getFullYear()
        );
        const years = Object.keys(groupedByYear);
        years.forEach((y) => {
          let trendValue = find(
            effTrend.Values,
            (v) => v.ReportingPointId === Number(k)
          );
          // Calculate the monthly averages
          const groupedByMonth = groupBy(groupedByYear[y], (y) =>
            new Date(y.Date).getMonth()
          );
          const mKeys = Object.keys(groupedByMonth);
          const effData = [];
          mKeys.forEach((k) => {
            const avg = meanBy(groupedByMonth[k], "Value");
            effData.push({ Month: parseInt(k) + 1, Value: avg });
          });
          series.push({
            marker: {
              symbol: "square"
            },
            color: "#265059",
            name: trendValue.Legend + " " + y,
            type: "line",
            yAxis: 1,
            showInLegend: false,
            data: raReportingDataToHighchartsData(effData),
          });
        });
      });
    }

    // Create master groups for produced series and efficiency series
    if (producedExists) {
      series.push({
        name: "Produced energy",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        // Area type has a circle as legend symbol but does not affect the size of other column series
        type: "area",
        showInLegend: true,
        visible: true,
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(event.target, "Sum of produced energy ");
            return false;
          },
        },
      });
    }
    if (efficiencyExists) {
      series.push({
        name: "Efficiency",
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        marker: {
          symbol: "square"
        },
        color: "#265059",
        type: "line",
        showInLegend: true,
        visible: true,
        events: {
          legendItemClick: (event) => {
            event.preventDefault();
            handleTemperatureClick(
              event.target,
              "Solar panel energy production efficiency "
            );
            return false;
          },
        },
      });
    }
    // Create year groups for the energy columns 
    const yearGroups = createYearGroups({
      data: [...series],
      groupNames: ["Produced energy", "Efficiency"],
      seriesNames: [
        "Sum of produced energy ",
        "Solar panel energy production efficiency ",
      ],
      type: "electricity",
    });
    if (yearGroups && yearGroups.length > 0) {
      series.push(...yearGroups);
    }

    // Set the colors
    series = setStackColor(series);

    // Set visible years
    series = setVisibleYears([...series]);

    // Set the stack property for every series to undefined, also set opacity to 1
    if (series && series.length > 0) {
      series.forEach((s) => {
        if (s.stack && s.type === "line") {
          s.stack = undefined;
        }
        if (s.opacity) {
          s.opacity = 1.0;
        }
      });
    }

    const targets = map(
      this.state.record.Solar.MonthlyTargets,
      (t) => t.Target
    );
    series.push({
      name: "Monthly target",
      data: targets,
      type: "line",
      marker: {
        symbol: "triangle-down"
      },
      color: graphColor({
        index: 0,
        seriesLength: 1,
        lineSeries: true,
        type: "electricity",
      }),
    });

    const yAxis = [];
    yAxis.push({
      min: 0,
      title: {
        text: trend.Title + " (KWh)",
      },
    });
    if (efficiencyExists) {
      yAxis.push({
        min: 0,
        max: 35,
        title: {
          text: "Panel efficiency (%)",
        },
        opposite: true,
      });
    }

    return {
      chart: {
        type: "column",
      },
      colors: electricityColors,
      legend: {
        enabled: true,
      },
      rangeSelector: {
        enabled: false,
      },
      title: {
        text: "",
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      series: series,
      yAxis: yAxis,
      scrollbar: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
      },
      navigator: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    };
  };

  render() {
    let showData = false;

    if (
      this.state &&
      this.state.options &&
      this.state.options.series &&
      this.state.options.series.length > 0
    ) {
      showData = true;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.fetchDone
    ) {
      if (showData) {
        this.props.showContainer("monthly");
      } else {
        this.props.hideContainer("monthly");
      }
    }

    return (
      <CardWithTitle title="Year by year">
        {this.state &&
          this.state.options &&
          this.state.options.series &&
          this.state.options.series.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={this.state.options}
            callback={setLegendItemHover}
          />
        ) : (
          <div style={{ height: "20rem" }}>
            <EmptyData />
          </div>
        )}
      </CardWithTitle>
    );
  }
}
