import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDataProvider, useNotify } from "react-admin";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

import { round, groupBy } from "lodash";
import useBuildingTimezone from "../../utils/hooks/useBuildingTimezone";
import { defaultColors } from "../../utils/graphColor";
import EmptyData from "../../components/layout/EmptyData";

const findMetaIds = (trends = [], id, values = []) => {
  const trend = trends.find((t) => t.TrendId === id);
  if (trend && trend.Values && trend.Values.length > 0) {
    const vals = [];
    trend.Values.forEach((tv) => {
      values.forEach((v) => {
        if (tv.Legend && tv.Legend.includes(v) && !vals.includes(tv)) {
          vals.push(tv);
        }
      });
    });
    if (vals) {
      return vals;
    }
  }
  return [];
};

const AhuTrendsGraph = ({
  Trends,
  trendId,
  trendValues,
  name,
  resolution,
  valueDivider = 1,
  unit,
  buildingId,
  naviMetaId,
  showGraph,
  hideGraph,
  graphType = "column",
  graphColors = defaultColors,
  stack = false,
}) => {
  const [fetchDone, setFetchDone] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const notify = useNotify();
  const tz = useBuildingTimezone();
  const [metaIds, setMetaIds] = useState();
  const [options, setOptions] = useState();
  const [graphDates, setGraphDates] = useState({
    min: moment.utc().subtract(3, "months").format(),
    max: moment.utc().format(),
  });

  const dataProvider = useDataProvider();
  const chartRef = useRef();

  const defaultChartOptions = {
    chart: {
      zoomType: "x",
      type: graphType,
      ignoreHiddenSeries: false,
    },
    colors: graphColors,
    legend: {
      enabled: true,
    },
    tooltip: {
      valueDecimals: 2,
      xDateFormat: resolution !== "month" ? "%A, %b %e %Y, %H:%M" : "%B, %Y",
    },
    xAxis: {
      events: {
        afterSetExtremes: (event) => {
          if (
            event.trigger &&
            event.min &&
            event.max &&
            !isNaN(event.min) &&
            !isNaN(event.max)
          ) {
            setGraphDates({
              min: moment(event.min).format(),
              max: moment(event.max).format(),
            });
          }
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "%",
        },
        min: 0,
        max: 100,
      },
      {
        title: {
          text: "°C",
        },
        min: -30,
        max: 30,
        opposite: false,
      },
    ],
    rangeSelector: {
      inputEnabled: false,
      buttonTheme: {
        width: "auto",
      },
      buttons:
        resolution === "month"
          ? [
              {
                type: "month",
                count: 3,
                text: "3 months",
              },
              {
                type: "month",
                count: 12,
                text: "12 months",
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ]
          : [
              {
                type: "day",
                count: 1,
                text: "day",
              },
              {
                type: "month",
                count: 1,
                text: "month",
              },
              {
                type: "all",
                text: "All",
              },
            ],
    },
    title: {
      text: "",
    },
    series: [],
    scrollbar: {
      liveRedraw: false,
      adaptToUpdatedData: false,
    },
    navigator: {
      adaptToUpdatedData: false,
      enabled: true,
    },
  };

  const graphOptions = useCallback(
    ({ data, naviData, metaIds, temperatureData, resolution }) => {
      // Function to map values to highcharts format
      const valuesToHighchartsData = (dataFromReactAdmin) => {
        const highChartsData = dataFromReactAdmin.map((v) => {
          const date = v.valueTime
            ? new Date(v.valueTime)
            : new Date(Date.UTC(v.year, v.month - 1, 1));
          return [date.getTime(), round(v.value / valueDivider, 2)];
        });
        return highChartsData;
      };

      // Create series based on selected data, one series for each variable/measurement
      let series = [];

      const grouped = groupBy(data, "metaId");
      const keys = Object.keys(grouped);
      keys.forEach((k) => {
        if (grouped[k] && grouped[k][0] && metaIds && metaIds.length > 0) {
          // Find correct legends
          let trend = null;
          trend = metaIds.find(
            (m) => m.ReportingPointId === grouped[k][0].metaId
          );
          if (trend && trend.Legend) {
            series.push({
              id: trend.Legend,
              name: trend.Legend,
              data: valuesToHighchartsData(grouped[k]),
              dataGrouping: {
                enabled: resolution === "month",
                forced: resolution === "month",
                units: [["month", [1]]],
                approximation: "average",
              },
              tooltip: {
                valueSuffix: ` ${unit}`,
              },
              yAxis: 0,
              ...(stack && { stack: "stack" }),
            });
          }
        }
      });

      // Add temperature
      if (
        series &&
        series.length > 0 &&
        temperatureData &&
        temperatureData.length > 0
      ) {
        let firstDataTime = null;
        let lastDataTime = null;
        // Filter out the temperature data that is outside the measurement data
        series.forEach((s) => {
          if (
            s &&
            s.data &&
            s.data[0] &&
            (s.data[0][0] < firstDataTime || !firstDataTime)
          ) {
            firstDataTime = s.data[0][0];
          }
          if (
            s &&
            s.data &&
            s.data[s.data.length - 1] &&
            (s.data[s.data.length - 1][0] > lastDataTime || !lastDataTime)
          ) {
            lastDataTime = s.data[s.data.length - 1][0];
          }
        });
        const tempSeriesData = valuesToHighchartsData(temperatureData);
        const finalTempSeriesData = tempSeriesData.filter(
          (d) => d[0] >= firstDataTime && d[0] <= lastDataTime
        );

        if (finalTempSeriesData && finalTempSeriesData.length > 0) {
          // Only set the visibility to false if series is already hidden
          let tempSeriesVisible = false;
          if (
            chartRef &&
            chartRef.current &&
            chartRef.current.chart &&
            chartRef.current.chart.series &&
            chartRef.current.chart.series.length > 0
          ) {
            const tempSer = chartRef.current.chart.series.find(
              (s) => s.name === "Outside temperature"
            );
            if (tempSer && tempSer.visible) {
              tempSeriesVisible = true;
            }
          }
          series.push({
            id: "temperature",
            name: "Outside temperature",
            data: finalTempSeriesData,
            visible: tempSeriesVisible,
            dataGrouping: {
              enabled: resolution === "month",
              forced: resolution === "month",
              units: [["month", [1]]],
              approximation: "average",
            },
            tooltip: {
              valueSuffix: " °C",
            },
            yAxis: 1,
          });
        }
      }

      return {
        time: {
          timezone: tz,
        },
        series: series,
        navigator: {
          adaptToUpdatedData: false,
          enabled: true,
          ...(naviData &&
            naviData.length > 0 && {
              series: { data: valuesToHighchartsData(naviData) },
            }),
        },
      };
    },
    [tz, stack, valueDivider, unit]
  );

  useEffect(() => {
    // Get the correct metaId's
    if (Trends && Trends.length > 0) {
      setMetaIds(findMetaIds(Trends, trendId, trendValues));
    }
  }, [Trends, trendId, trendValues, name]);

  // useEffect for the new data fetching
  useEffect(() => {
    const getGraphData = async () => {
      const naviId =
        metaIds &&
        metaIds.find((m) => m.Legend && m.Legend.includes(naviMetaId))
          ? metaIds.find((m) => m.Legend.includes(naviMetaId))
          : null;
      if (
        metaIds &&
        metaIds.length > 0 &&
        naviId &&
        buildingId &&
        graphDates &&
        graphDates.min &&
        graphDates.max
      ) {
        if (chartRef && chartRef.current) {
          chartRef.current.chart.showLoading("Loading data from server...");
        }
        const fetchIds = metaIds.map((m) => m.ReportingPointId);
        const reportDataPromise = dataProvider.getList("reportdata", {
          filter: {
            v2: true,
            naviSeries: naviId.ReportingPointId,
            buildingId: buildingId,
            metaIds: fetchIds,
            trend: naviId.Legend,
            dataBegin: graphDates.min,
            dataEnd: graphDates.max,
          },
          sort: {},
          pagination: {},
        });

        const temperatureDataPromise = dataProvider.getList("reportdata", {
          filter: {
            v2: true,
            buildingId: buildingId,
            pointIds: buildingId,
            pointSource: "building_temp",
            trend: "temperature",
            resolution: resolution,
            dataBegin: graphDates.min,
            dataEnd: graphDates.max,
          },
          sort: {},
          pagination: {},
        });

        Promise.all([reportDataPromise, temperatureDataPromise])
          .then((res) => {
            if (
              chartRef &&
              chartRef.current &&
              res &&
              res[0] &&
              res[1] &&
              res[0].data &&
              res[1].data &&
              res[0].data.values &&
              res[0].data.values
            ) {
              const opt = graphOptions({
                data: res[0].data.values,
                naviData: res[0].data.naviSeries
                  ? res[0].data.naviSeries.Values
                  : [],
                metaIds,
                temperatureData: res[1].data.values,
                resolution,
              });
              setOptions(opt);
              chartRef.current.chart.update(opt, true, true, true);
            }
          })
          .catch((error) => {
            notify(error.message, "warning");
          })
          .finally(() => {
            setFetchDone(true);
            setFetchCount((curCount) => curCount + 1);
            if (chartRef && chartRef.current) {
              chartRef.current.chart.hideLoading();
            }
          });
      } else if (metaIds && metaIds.length === 0) {
        setFetchDone(true);
      }
    };

    getGraphData();
  }, [
    dataProvider,
    graphOptions,
    metaIds,
    naviMetaId,
    buildingId,
    graphDates,
    notify,
    resolution,
  ]);

  let showData = true;

  // The graph is only hidden if the initial fetch (fetchcount === 1) did not find data
  if (
    fetchDone &&
    fetchCount === 1 &&
    (!options || !options.series || options.series.length === 0)
  ) {
    showData = false;
  }

  if (showGraph && hideGraph && fetchDone) {
    if (showData) {
      showGraph(name);
    } else {
      hideGraph(name);
    }
  }

  return showData ? (
    <HighchartsReact
      allowChartUpdate={false}
      highcharts={Highcharts}
      constructorType="stockChart"
      options={defaultChartOptions}
      ref={chartRef}
    />
  ) : (
    <EmptyData style={{ height: "20rem" }} />
  );
};

export default AhuTrendsGraph;
