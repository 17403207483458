import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #D1D7DB",
    borderRadius: "2px",
    color: "#282331",
  },
  title: {
    fontWeight: 300,
    fontSize: "0.75em",
    opacity: "0.8",
  },
  text: {
    fontSize: "1em",
  },
  lineContainer: {
    display: "flex",
    alignItems: "center",
    height: "25%",
    margin: "0.3em",
    marginLeft: "0.75em",
  },
}));

const Info = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.lineContainer}>
        <Typography
          className={classes.title}
          title={props.title || "title"}
          noWrap
        >
          {props.title ? props.title : "Unknown title"}
        </Typography>
      </div>
      <div className={classes.lineContainer}>
        <Typography
          className={classes.text}
          title={props.text || "text"}
          noWrap={!props.desc}
        >
          {props.text || props.text === 0 ? props.text : "-"}
        </Typography>
      </div>
    </div>
  );
};

export default Info;
