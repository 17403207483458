import React from "react";
import {
  AutocompleteInput,
  ArrayInput,
  NumberInput,
  ReferenceInput,
  required,
  SimpleFormIterator
} from "react-admin";

import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import optionRenderer from "../utils/optionRenderer";

const HpScalingTitle = "Note! Check scaling. Wh=0.001 kWh=1, MWh=1000 etc...";

export const MeterInputs = formData => (
  <div>
    <div style={{ display: "inline-flex", width: "100%" }}>
      <Tooltip title={HpScalingTitle} placement="right-end">
        <HelpIcon style={{ margin: "auto" }} />
      </Tooltip>
      <ArrayInput source="Meter.InputPoints" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="input energy points"
            source="BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
            fullWidth
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling factor"
            source="ScalingFactor"
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>

    <div style={{ display: "inline-flex", width: "100%" }}>
      <Tooltip title={HpScalingTitle} placement="right-end">
        <HelpIcon style={{ margin: "auto" }} />
      </Tooltip>
      <ArrayInput source="Meter.InputPowerPoints" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="Input power points"
            source="BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
            fullWidth
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling factor"
            source="ScalingFactor"
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  </div>
);
