import { useCallback, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDataProvider, useNotify } from "react-admin";

import { changebuilding } from "../../customReducers/actions";

// Used to set a building as selected, for example in a case where a twin is opened through a link
function useSetBuilding() {
  const mounted = useRef(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const location = useLocation();
  const curPath = location.pathname;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Setter function that is passed to the fetchBuilding function
  const changeBuilding = useCallback(
    async (buildingId, redirectTo) => {
      try {
        const res = await dataProvider.getOne("buildings", { id: buildingId });
        const buildingData = res.data;
        const twinRes = await dataProvider.getList("blocks", {
          filter: {
            BuildingId: buildingId,
            BlockType: "BUILDING",
          },
          sort: {},
          pagination: {},
        });
        let twinData;
        if (twinRes && twinRes.data && twinRes.data.length > 0) {
          twinData = twinRes.data[0];
        }
        const buildingTwinId = twinData && twinData.id ? twinData.id : null;
        if (mounted.current) {
          dispatch(changebuilding(buildingData, redirectTo, buildingTwinId));
        }
      } catch (error) {
        notify(error.message, "warning");
      }
    },
    [dispatch, dataProvider, notify]
  );

  const setBuilding = useCallback(
    (id, redirect = false) => {
      if (id) {
        const redirPath = redirect ? "/dashboard" : curPath;
        changeBuilding(parseInt(id), redirPath);
      }
    },
    [changeBuilding, curPath]
  );
  return setBuilding;
}

export default useSetBuilding;
