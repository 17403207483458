import React, { useState, useEffect } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateField,
  Edit,
  FormDataConsumer,
  ReferenceField,
  ReferenceInput,
  regex,
  required,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
  FileInput,
  FileField,
  useNotify,
} from "react-admin";
import { get } from "lodash";
import {
  makeStyles,
  CardContent,
  Typography,
  Link,
  IconButton,
} from "@material-ui/core";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { stateMapper } from "../customReducers/connectFunctions";
import { DateInputNoTimezone } from "../components/TimeInputs";
import OperationTooltip from "./OperationTooltip";
import DateFieldTz from "../utils/DateFieldTz";
import useQuery from "../utils/hooks/useQuery";
import PageTitle from "../components/layout/PageTitle";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import Spinner from "../components/layout/Spinner";

const validateHttp = regex(/(http|https):\/\/.+/, "Please enter a valid url");
const blockOptions = (choice) =>
  !choice ? "" : `${choice.BlockName} - ${choice.BlockDescription}`;

const useStyles = makeStyles((theme) => ({
  formInputs: {
    width: "70%",
  },
  removeIcon: {
    color: theme.palette.error.main,
  },
}));

const CurrentAttachmentsField = (props) => {
  const classes = useStyles();
  return (
    <div label="Current Attachments">
      <CardContent>
        {props.attachmentsLoading ? (
          <Spinner style={{ height: 80 }} />
        ) : props.attachments && props.attachments.length > 0 ? (
          props.attachments.map((a) => {
            return (
              <div key={a.url}>
                <IconButton
                  className={classes.removeButton}
                  onClick={() => props.handleAttachmentRemove(a.name)}
                  aria-label="Remove"
                  title="Remove"
                >
                  <RemoveCircle className={classes.removeIcon} />
                </IconButton>
                <Link href={a.url} download={a.name}>
                  {a.name}
                </Link>
              </div>
            );
          })
        ) : (
          <Typography variant="body2">No attachments</Typography>
        )}
      </CardContent>
    </div>
  );
};

const OperationsEdit = ({ BuildingId, ...props }) => {
  const [tz, setTz] = useState(null);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachments, setAttachments] = useState();
  const classes = useStyles();
  // Remove unused props to get rid of errors
  let editProps = Object.assign({}, props);
  delete editProps.buildingTwinId;
  delete editProps.buildingSelected;
  delete editProps.dispatch;

  // Need and external component to extract the timezone
  const BuildingField = (props) => {
    setTz(get(props, "record.TimeZoneIANA", null));
    return (
      <TextField
        {...props}
        source="ProjectName"
        className={classes.formInputs}
      />
    );
  };
  const query = useQuery();
  const source = query.get("src");

  const customFetch = useCustomFetch();
  const notify = useNotify();
  const { id } = props;

  // On mount, fetch the attachments
  useEffect(() => {
    const getAttachments = async () => {
      setAttachmentsLoading(true);
      try {
        const res = await customFetch({ url: `operationlog/${id}/files` });
        const resData = await res.json();
        if (resData && resData.length > 0) {
          const blobPromises = resData.map(async (r) => {
            const attachRes = await customFetch({
              url: `operationlog/${id}/files/${r.fileName}`,
            });
            const blob = await attachRes.blob();
            const url = URL.createObjectURL(blob);
            const name = r.fileName;
            return { url, name };
          });
          const finalResponse = await Promise.all(blobPromises);
          setAttachments(finalResponse);
        }
      } catch (error) {
        notify("Error retrieving log attachments", "warning");
        setAttachmentsLoading(false);
      } finally {
        setAttachmentsLoading(false);
      }
    };

    getAttachments();
  }, [notify, customFetch, id]);

  const handleAttachmentRemove = (fileName) => {
    customFetch({
      url: `operationlog/${id}/files/${fileName}`,
      method: "DELETE",
    })
      .then(() => {
        setAttachments((curAttachments) =>
          curAttachments.filter((a) => a.name !== fileName)
        );
      })
      .catch(() => notify("Error deleting attachment", "warning"));
  };

  return (
    <>
      <PageTitle title="Edit Operation" />
      <Edit {...editProps}>
        <SimpleForm
          redirect={
            source === "reminders"
              ? () => {
                  return "/reminders";
                }
              : "list"
          }
        >
          <ReferenceField
            label="Project name"
            source="BuildingId"
            reference="buildings"
            filter={{ showBAUI: true }}
            link="show"
          >
            <BuildingField />
          </ReferenceField>
          <ReferenceField
            source="BuildingId"
            reference="buildings"
            label="Link to sharepoint"
            filter={{ showBAUI: true }}
            link={false}
          >
            <UrlField
              className={classes.formInputs}
              source="ProjectWorkspaceURL"
              target="_blank"
              rel="noopener noreferrer"
            />
          </ReferenceField>
          <OperationTooltip field="ProjectCharterLink">
            <TextInput
              className={classes.formInputs}
              multiline
              source="ProjectCharterLink"
              label="Link to project charter"
              validate={[validateHttp]}
            />
          </OperationTooltip>
          <ReferenceInput
            label="Subcategory"
            source="OperationMeta"
            reference="operationmeta"
            filter={{ Parent: false }}
            sort={{ field: "id", order: "ASC" }}
            validate={required()}
          >
            <SelectInput
              optionText="Name"
              allowEmpty
              className={classes.formInputs}
            />
          </ReferenceInput>
          <OperationTooltip field="Title">
            <TextInput
              source="Title"
              validate={required()}
              className={classes.formInputs}
            />
          </OperationTooltip>
          <DateField
            source="Date"
            label="Date created"
            locales="fi-FI"
            className={classes.formInputs}
          />

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <OperationTooltip field="OperationBlocks">
                <ReferenceArrayInput
                  label="Digital twins"
                  source="OperationBlocks"
                  reference="blocks"
                  resource="blocks"
                  perPage={10}
                  sort={{ field: "BlockName", order: "ASC" }}
                  filter={{
                    BuildingId: formData.BuildingId,
                  }}
                  fullWidth
                >
                  <AutocompleteArrayInput
                    optionText={blockOptions}
                    optionValue="id"
                  />
                </ReferenceArrayInput>
              </OperationTooltip>
            )}
          </FormDataConsumer>

          <OperationTooltip field="InternalComment">
            <TextInput
              multiline
              label="Internal comments"
              source="InternalComment"
              className={classes.formInputs}
            />
          </OperationTooltip>

          <OperationTooltip field="VisibleToExternal">
            <BooleanInput
              label="Visible to customer"
              source="VisibleToExternal"
            />
          </OperationTooltip>

          <OperationTooltip field="ExternalComment">
            <TextInput
              multiline
              label="Comments to customer"
              source="ExternalComment"
              className={classes.formInputs}
            />
          </OperationTooltip>

          <OperationTooltip field="State" tooltipMargin={50}>
            <SelectInput
              className={classes.formInputs}
              source="State"
              choices={[
                { id: "1. Active", name: "1. Active" },
                { id: "2. Delayed", name: "2. Delayed" },
                { id: "3. Completed", name: "3. Completed" },
              ]}
            />
          </OperationTooltip>

          <DateInputNoTimezone
            className={classes.formInputs}
            source="Deadline"
            label="Deadline"
          />

          <TextField source="CreatedBy" />
          <DateFieldTz
            source="CreatedAt"
            label={tz ? "Created at" : "Created at (UTC)"}
            showTime
            addLabel
            timeZone={tz}
          />
          <TextField source="LastModifiedBy" />
          <DateFieldTz
            source="LastModifiedAt"
            label={tz ? "Last modified at" : "Last modified at (UTC)"}
            showTime
            addLabel
            timeZone={tz}
          />
          <FileInput source="files" label="Add attachments" multiple>
            <FileField source="src" title="Attachments" />
          </FileInput>
          <CurrentAttachmentsField
            label="Current attachments"
            addLabel
            attachments={attachments}
            attachmentsLoading={attachmentsLoading}
            handleAttachmentRemove={handleAttachmentRemove}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default stateMapper(OperationsEdit);
