import React, { useState, useEffect, FunctionComponent } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useSelector } from "react-redux";
import { get, find, last } from "lodash";
import HxMap from "./HxMap";
import { BuildingBlock, BlockCompensation, HxBlock, ValueStorage } from '../types/leasegreen-core-api-exports';
import { IRootState } from '../types/root-state';
import { toBuildingTime } from '../utils/twinUtils';

const resolveVariables = (hx: HxBlock, compensation: BlockCompensation) => {
  if (hx) {
    let variables = [];
    if (get(hx, "PrimarySupplyTE.BusTagName", null)) {
      variables.push(hx.PrimarySupplyTE.BusTagName);
    }
    if (get(hx, "PrimaryReturnTE.BusTagName", null)) {
      variables.push(hx.PrimaryReturnTE.BusTagName);
    }
    if (get(hx, "SecondarySupplyTE.BusTagName", null)) {
      variables.push(hx.SecondarySupplyTE.BusTagName);
    }
    if (get(hx, "SecondaryReturnTE.BusTagName", null)) {
      variables.push(hx.SecondaryReturnTE.BusTagName);
    }
    if (get(hx, "ControlValvePercentage.BusTagName", null)) {
      variables.push(hx.ControlValvePercentage.BusTagName);
    }
    if (get(compensation, "BlockOutput.BusTagName", null)) {
      variables.push(compensation.BlockOutput.BusTagName);
    }
    return variables;
  }
  return [];
};

const HxTwinInfo: FunctionComponent<BuildingBlock> = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const buildingData = useSelector<IRootState>(
    (state) => state.buildingFilter.buildingData
  );
  const temperatureData = useSelector<IRootState, any[]>(
    (state) => state.buildingFilter.buildingTemp
  );
  const [primarySupply, setPrimarySupply] = useState<ValueStorage>({});
  const [primaryReturn, setPrimaryReturn] = useState<ValueStorage>({});
  const [secondarySupply, setSecondarySupply] = useState<ValueStorage>({});
  const [secondaryReturn, setSecondaryReturn] = useState<ValueStorage>({});
  const [controlValve, setControlValve] = useState<ValueStorage>({});
  const [compensation, setCompensation] = useState<ValueStorage>({});
  const [outdoor] = useState<Partial<ValueStorage & { valueTime: any; value: any }>>({});

  useEffect(() => {
    const now = new Date();

    // Get RAU data
    dataProvider
      .getList<ValueStorage & { id: number }>("valuestorage", {
        filter: {
          BuildingId: props.BuildingId,
          VariableNames: resolveVariables(props.Hx, props.Compensation),
          DataType: "RAU",
          ValuesFrom: new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours() - 1,
            now.getMinutes(),
            now.getSeconds()
          ).toISOString(),
          ValuesTo: now.toISOString(),
        },
        sort: { field: "UpdateTime", order: "DESC" },
        pagination: {} as any
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setPrimarySupply(
            find(
              data,
              (v) =>
                v.VariableName ===
                get(props.Hx, "PrimarySupplyTE.BusTagName", null)
            )
          );
          setPrimaryReturn(
            find(
              data,
              (v) =>
                v.VariableName ===
                get(props.Hx, "PrimaryReturnTE.BusTagName", null)
            )
          );
          setSecondarySupply(
            find(
              data,
              (v) =>
                v.VariableName ===
                get(props.Hx, "SecondarySupplyTE.BusTagName", null)
            )
          );
          setSecondaryReturn(
            find(
              data,
              (v) =>
                v.VariableName ===
                get(props.Hx, "SecondaryReturnTE.BusTagName", null)
            )
          );
          setControlValve(
            find(
              data,
              (v) =>
                v.VariableName ===
                get(props.Hx, "ControlValvePercentage.BusTagName", null)
            )
          );
          setCompensation(
            find(
              data,
              (v) =>
                v.VariableName ===
                get(props.Compensation, "BlockOutput.BusTagName", null)
            )
          );
        }
      })
      .catch((error: Error) => {
        notify(error.message, "warning");
      });
  }, [
    dataProvider,
    props.BuildingId,
    notify,
    props.BlockName,
    props.Hx,
    props.Compensation,
  ]);

  const twinData = [];

  if (primarySupply) {
    twinData.push({
      title: "primarySupply",
      timestamp: toBuildingTime(
        primarySupply.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: primarySupply.AggregatedValue ? primarySupply.AggregatedValue.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (primaryReturn) {
    twinData.push({
      title: "primaryReturn",
      timestamp: toBuildingTime(
        primaryReturn.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: primaryReturn.AggregatedValue ? primaryReturn.AggregatedValue.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (secondarySupply) {
    twinData.push({
      title: "secondarySupply",
      timestamp: toBuildingTime(
        secondarySupply.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: secondarySupply.AggregatedValue ? secondarySupply.AggregatedValue.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (secondaryReturn) {
    twinData.push({
      title: "secondaryReturn",
      timestamp: toBuildingTime(
        secondaryReturn.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: secondaryReturn.AggregatedValue ? secondaryReturn.AggregatedValue.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (controlValve) {
    twinData.push({
      title: "controlValve",
      timestamp: toBuildingTime(
        controlValve.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: controlValve.AggregatedValue ? controlValve.AggregatedValue.toFixed(1) : null,
      unit: "%",
    });
  }
  if (compensation) {
    twinData.push({
      title: "compensation",
      timestamp: toBuildingTime(
        compensation.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: compensation.AggregatedValue ? compensation.AggregatedValue.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (outdoor) {
    let temp = null;
    if (temperatureData && temperatureData.length > 0) {
      temp = last(temperatureData);
    }
    twinData.push({
      title: "outdoor",
      timestamp: toBuildingTime(
        temp ? temp.valueTime : outdoor.UpdateTime,
        get(buildingData, "timeZoneIANA", null)
      ),
      data: temp ? temp.value : outdoor.AggregatedValue ? outdoor.AggregatedValue.toFixed(1) : null,
      unit: "°C",
    });
  }

  return <HxMap data={twinData} />;
};

export default HxTwinInfo;
