import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Dialog } from "@material-ui/core";

import CardWithTitle from "../components/layout/CardWithTitle";
import TwinInputStatus from "./TwinInputStatus";
import ZoneAhus from "./ZoneAhus";
import { find } from "lodash";

const useStyles = makeStyles({
  root: {
    overflowX: "auto",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 1275,
  },
  inputContainer: {
    width: "100%",
    minWidth: 1275,
    marginBottom: 10,
  },
  image: {
    zIndex: 1,
    height: "auto",
    maxWidth: 1200,
    maxHeight: 800,
    marginTop: 20,
    marginBottom: 20,
    cursor: "zoom-in",
  },
  inputItem: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  temperatures: {
    display: "inline-flex",
    boxShadow: "0px 3px 10px #2E224729",
    padding: 10,
  },
  co2s: {
    display: "inline-flex",
    marginLeft: 20,
    boxShadow: "0px 3px 10px #2E224729",
    padding: 10,
  },
  humidities: {
    display: "inline-flex",
    marginLeft: 20,
    boxShadow: "0px 3px 10px #2E224729",
    padding: 10,
  },
  feedbackItem: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  feedbacks: {
    display: "inline-flex",
    marginBottom: 20,
    boxShadow: "0px 3px 10px #2E224729",
    padding: 10,
    flexDirection: "column",
    marginLeft: "auto",
  },
  ahus: {
    boxSizing: "border-box",
    width: 143,
    height: 408,
    display: "inline-flex",
    flexDirection: "column",
    marginBottom: 20,
    boxShadow: "0px 3px 10px #2E224729",
    padding: 10,
    marginRight: "auto",
  },
  ahuTitle: {
    fontSize: 14,
    fontWeight: 350,
    color: "#858585",
    marginBottom: 5,
  },
  noLayoutText: {
    color: "#282331",
    opacity: "30%",
    fontStyle: "italic",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
});

const getHeightAndWidthFromDataUrl = (dataURL) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });

const ZoneMap = (props) => {
  const [open, setOpen] = useState(false);
  const [imgSize, setImgSize] = useState();
  const [imgStyle, setImgStyle] = useState({});
  const classes = useStyles();

  const initData = {
    data: null,
    unit: "",
    timestamp: "",
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.zoneImage) {
      getHeightAndWidthFromDataUrl(props.zoneImage).then((res) => {
        if (res && res.width && res.height) {
          let widthScroll = false;
          let heightScroll = false;
          let widthFactor = 1;
          let heightFactor = 1;
          let style = {};
          const windowWidth = window.innerWidth * 0.95;
          const windowHeight = window.innerHeight * 0.9;

          if (res.width >= windowWidth) {
            widthScroll = true;
            widthFactor = windowWidth / res.width;
          }
          if (res.height >= windowHeight) {
            heightScroll = true;
            heightFactor = windowHeight / res.height;
          }
          if (heightScroll && !widthScroll) {
            style = { maxWidth: "95vw" };
          }
          if (widthScroll && !heightScroll) {
            style = { maxHeight: "90vh" };
          }
          if (widthScroll && heightScroll) {
            if (widthFactor >= heightFactor) {
              style = { width: res.width * widthFactor };
            } else {
              style = { height: res.height * heightFactor };
            }
          }
          setImgStyle({ ...style, objectFit: "contain" });
          setImgSize(res);
        }
      });
    }
  }, [props.zoneImage]);

  const zoneImage =
    props.zoneImage && imgSize && imgSize.height && imgSize.width ? (
      open ? (
        <Dialog
          maxWidth={false}
          onClose={handleClose}
          open={open}
          onClick={() => setOpen(false)}
          PaperProps={{
            id: "fullscreen-dialog",
            style: {
              display: "block",
              overflow: "auto",
              cursor: "zoom-out",
            },
          }}
        >
          <img
            alt="zone layout"
            id="fullscreen-image"
            src={props.zoneImage}
            style={imgStyle}
          />
        </Dialog>
      ) : (
        <img
          className={classes.image}
          alt="zone layout"
          src={props.zoneImage}
          onClick={() => setOpen(true)}
        />
      )
    ) : (
      <Typography className={classes.noLayoutText}>
        No Layout Available
      </Typography>
    );

  const avgTempData = find(props.data, (d) => d.title === "avgTemp")
    ? find(props.data, (d) => d.title === "avgTemp")
    : initData;

  let avgTemp = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Average temperature"}
        data={avgTempData.data}
        unit={avgTempData.unit}
        timestamp={avgTempData.timestamp}
      />
    </div>
  );
  const minTempData = find(props.data, (d) => d.title === "minTemp")
    ? find(props.data, (d) => d.title === "minTemp")
    : initData;

  let minTemp = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Minimum temperature"}
        data={minTempData.data}
        unit={minTempData.unit}
        timestamp={minTempData.timestamp}
      />
    </div>
  );

  const maxTempData = find(props.data, (d) => d.title === "maxTemp")
    ? find(props.data, (d) => d.title === "maxTemp")
    : initData;

  let maxTemp = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Maximum temperature"}
        data={maxTempData.data}
        unit={maxTempData.unit}
        timestamp={maxTempData.timestamp}
      />
    </div>
  );

  const avgCo2Data = find(props.data, (d) => d.title === "avgCo2")
    ? find(props.data, (d) => d.title === "avgCo2")
    : initData;

  let avgCo2 = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Average Co2"}
        data={avgCo2Data.data}
        unit={avgCo2Data.unit}
        timestamp={avgCo2Data.timestamp}
      />
    </div>
  );
  const minCo2Data = find(props.data, (d) => d.title === "minCo2")
    ? find(props.data, (d) => d.title === "minCo2")
    : initData;

  let minCo2 = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Minimum Co2"}
        data={minCo2Data.data}
        unit={minCo2Data.unit}
        timestamp={minCo2Data.timestamp}
      />
    </div>
  );

  const maxCo2Data = find(props.data, (d) => d.title === "maxCo2")
    ? find(props.data, (d) => d.title === "maxCo2")
    : initData;

  let maxCo2 = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Maximum Co2"}
        data={maxCo2Data.data}
        unit={maxCo2Data.unit}
        timestamp={maxCo2Data.timestamp}
      />
    </div>
  );

  const avgHumidityData = find(props.data, (d) => d.title === "avgHumidity")
    ? find(props.data, (d) => d.title === "avgHumidity")
    : initData;

  let avgHumidity = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Average Humidity"}
        data={avgHumidityData.data}
        unit={avgHumidityData.unit}
        timestamp={avgHumidityData.timestamp}
      />
    </div>
  );
  const minHumidityData = find(props.data, (d) => d.title === "minHumidity")
    ? find(props.data, (d) => d.title === "minHumidity")
    : initData;

  let minHumidity = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Minimum Humidity"}
        data={minHumidityData.data}
        unit={minHumidityData.unit}
        timestamp={minHumidityData.timestamp}
      />
    </div>
  );

  const maxHumidityData = find(props.data, (d) => d.title === "maxHumidity")
    ? find(props.data, (d) => d.title === "maxHumidity")
    : initData;

  let maxHumidity = (
    <div className={classes.inputItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Maximum Humidity"}
        data={maxHumidityData.data}
        unit={maxHumidityData.unit}
        timestamp={maxHumidityData.timestamp}
      />
    </div>
  );

  const npsData = find(props.data, (d) => d.title === "nps")
    ? find(props.data, (d) => d.title === "nps")
    : initData;

  let nps = (
    <div className={classes.feedbackItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"NPS"}
        data={npsData.data}
        unit={npsData.unit}
        timestamp={npsData.timestamp}
        noTooltip
      />
    </div>
  );

  const goodData = find(props.data, (d) => d.title === "good")
    ? find(props.data, (d) => d.title === "good")
    : initData;

  let good = (
    <div className={classes.feedbackItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Good feedback"}
        data={goodData.data}
        unit={goodData.unit}
        timestamp={goodData.timestamp}
        noTooltip
      />
    </div>
  );

  const neutralData = find(props.data, (d) => d.title === "neutral")
    ? find(props.data, (d) => d.title === "neutral")
    : initData;

  let neutral = (
    <div className={classes.feedbackItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Neutral feedback"}
        data={neutralData.data}
        unit={neutralData.unit}
        timestamp={neutralData.timestamp}
        noTooltip
      />
    </div>
  );

  const badData = find(props.data, (d) => d.title === "bad")
    ? find(props.data, (d) => d.title === "bad")
    : initData;

  let bad = (
    <div className={classes.feedbackItem}>
      <TwinInputStatus
        noBorder
        size={64}
        subTitle={"Bad feedback"}
        data={badData.data}
        unit={badData.unit}
        timestamp={badData.timestamp}
        noTooltip
      />
    </div>
  );

  return (
    <CardWithTitle title={"Input status"}>
      <div className={classes.root}>
        <div className={classes.inputContainer}>
          <span className={classes.temperatures}>
            {avgTemp}
            {minTemp}
            {maxTemp}
          </span>
          <span className={classes.co2s}>
            {avgCo2}
            {minCo2}
            {maxCo2}
          </span>
          <span className={classes.humidities}>
            {avgHumidity}
            {minHumidity}
            {maxHumidity}
          </span>
        </div>
        <div className={classes.container}>
          <span className={classes.ahus}>
            <span className={classes.ahuTitle}>Served by</span>
            <ZoneAhus zoneMap ahus={props.ahus} />
          </span>
          {zoneImage}
          <span className={classes.feedbacks}>
            {nps}
            {good}
            {neutral}
            {bad}
          </span>
        </div>
      </div>
    </CardWithTitle>
  );
};

export default ZoneMap;
