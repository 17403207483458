import React, { useState, useEffect, useRef } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import useIsMounted from "../utils/hooks/useIsMounted";

import { find, map, round, sortBy, groupBy, cloneDeep } from "lodash";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (dataFromReactAdmin) => {
  var current = new Date();
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = new Date(v.Date);
    return [
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getTime(),
      round(v.Value, 2),
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getMonth(),
    ];
  });

  return highChartsData;
};

export const HpTemperatureGraph = (props) => {
  const [resolution] = useState("day");
  const [optionsMonth, setOptionsMonth] = useState({ title: { text: "" } });
  const [optionsDay, setOptionsDay] = useState({ title: { text: "" } });
  const [fetchDone, setFetchDone] = useState(false);
  const isMounted = useIsMounted();
  const chartRef = useRef(null);

  useEffect(() => {
    const graphOptions = (data, monthlyData = false) => {
      let series = [];

      const grouped = groupBy(data, "Id");
      let trend = find(
        props.Trends,
        (y) => y.TrendId === "SOURCE_RETURN_TE_DAILY"
      );
      const keys = Object.keys(grouped);

      map(keys, (k) => {
        let trendValue = find(
          trend.Values,
          (v) => v.ReportingPointId === Number(k)
        );

        const sortedData = sortBy(
          valuesToHighchartsData(grouped[k], monthlyData),
          0
        );

        series.push({
          name: trendValue.Legend,
          type: "line",
          data: sortedData,
          dataGrouping: {
            approximation: "low",
          },
        });
      });

      // Calculate date offsets for the previous year button
      // Get the time of the latest value
      let newestData = 0;
      series.forEach((s) => {
        if (s && s.data && s.data.length > 0) {
          s.data.forEach((d) => {
            if (d[0] > newestData) {
              newestData = d[0];
            }
          });
        }
      });

      const curEnd = new Date(newestData);
      const curStart = new Date(curEnd.getUTCFullYear(), 0, 1);
      const prevStart = new Date(curEnd.getUTCFullYear() - 1, 0, 1);
      const prevEnd = new Date(curEnd.getUTCFullYear(), 0, 0);
      const diffStart = curStart.getTime() - prevStart.getTime();
      const diffEnd = curEnd.getTime() - prevEnd.getTime();

      return {
        legend: {
          enabled: true,
        },
        xAxis: {
          minRange: 1,
        },
        rangeSelector: {
          // selected: 1,
          allButtonsEnabled: true,
          buttonTheme: {
            width: "auto",
          },
          buttons: [
            {
              type: "month",
              count: 3,
              text: "3m",
              // Use to group the data
              dataGrouping: {
                forced: monthlyData,
                enabled: monthlyData,
                units: [["month", [1]]],
              },
            },
            {
              type: "month",
              count: 12,
              text: "12m",
              dataGrouping: {
                forced: monthlyData,
                enabled: monthlyData,
                units: [["month", [1]]],
              },
            },
            {
              type: "ytd",
              text: "Last year",
              offsetMin: -diffStart,
              offsetMax: -diffEnd,
              dataGrouping: {
                forced: monthlyData,
                enabled: monthlyData,
                units: [["month", [1]]],
              },
            },
            {
              type: "ytd",
              text: "This year",
              dataGrouping: {
                forced: monthlyData,
                enabled: monthlyData,
                units: [["month", [1]]],
              },
            },
            {
              type: "all",
              text: "All",
              dataGrouping: {
                forced: monthlyData,
                enabled: monthlyData,
                units: [["month", [1]]],
              },
            },
          ],
        },
        title: {
          text: "",
        },
        series: series,
      };
    };
    // Daily data
    const metaIds = findMetaIds(props.Trends, "SOURCE_RETURN_TE_DAILY");
    if (metaIds && metaIds.length > 0) {
      restClient(GET_LIST, "reportdata", {
        filter: {
          buildingId: props.BuildingId,
          metaIds: findMetaIds(props.Trends, "SOURCE_RETURN_TE_DAILY"),
          trend: "SOURCE_RETURN_TE_DAILY",
        },
        sort: { field: "UpdateTime", order: "ASC" },
        pagination: {},
      })
        .then((response) => response.data)
        .then(data => data.map(v => ({ Date: v.valueTime, Id: v.metaId, Value: v.value })))
        .then((response) => {
          if (isMounted) {
            setOptionsDay(graphOptions(response));
            setOptionsMonth(graphOptions(response, true));
            setFetchDone(true);
          }
        });
    }
  }, [props.Trends, props.BuildingId, isMounted, props.Hp.MonthlyTargets]);

  let options = null;
  if (resolution === "day") {
    options = cloneDeep(optionsDay);
  } else if (resolution === "month") {
    options = cloneDeep(optionsMonth);
  }

  let showData = true;
  if (!options || !options.series || options.series.length === 0) {
    showData = false;
  }

  // This is mostly to remove the animation when redraw is happening if the resolution is changed
  // Note the clone deep, it is used because the chart may mutate the series that is passed
  useEffect(() => {
    if (resolution && optionsDay && optionsMonth) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        if (resolution === "day") {
          chartRef.current.chart.update(
            cloneDeep(optionsDay),
            true,
            true,
            false
          );
        } else if (resolution === "month") {
          chartRef.current.chart.update(
            cloneDeep(optionsMonth),
            true,
            true,
            false
          );
        }
      }
    }
  }, [resolution, optionsMonth, optionsDay]);

  if (props.showContainer && props.hideContainer && fetchDone) {
    if (showData) {
      props.showContainer("temperature");
    } else {
      props.hideContainer("temperature");
    }
  }

  return (
    <CardWithTitle title="Source and return temperatures">
      {showData ? (
        [
          <HighchartsReact
            key={"chart"}
            ref={chartRef}
            highcharts={Highcharts}
            options={cloneDeep(options)}
            constructorType={"stockChart"}
          />,
        ]
      ) : (
        <div style={{ height: "20rem" }}>
          <EmptyData />
        </div>
      )}
    </CardWithTitle>
  );
};
