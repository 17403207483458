import React from "react";
import {
  // FileInput,
  // FileField,
  FormDataConsumer,
  TopToolbar,
  CheckboxGroupInput,
  NumberInput,
  NumberField,
  Datagrid,
  Link,
  ListButton,
  TextField,
  BooleanField,
  TabbedForm,
  FormTab,
  Edit,
  ReferenceManyField,
  RefreshButton,
  SelectInput,
  EditButton,
  BooleanInput,
  regex,
  TextInput,
} from "react-admin";

import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/HelpOutline";
import Button from "@material-ui/core/Button";
import BuildingShowButton from "./BuildingShowButton";
import UpdatePWAExportButton from "./UpdatePWAExportButton";
import PageTitle from "../components/layout/PageTitle";
import { RecalculateHistoryButton } from './RecalculateHistoryButton';
import { useSelector } from "react-redux";
/*
const validateYear = regex(
  /^(199\d|20[0-1]\d)$/,
  "Must be year between 1990-2019"
);
*/

const validatePercentage = regex(
  /^(\d|[1-9]\d|100)$/,
  "Must be percentage between 0-100%"
);

const BuildingTitle = ({ record }) => {
  return (
    <span>
      Building #{record ? `${record.id} - ${record.ProjectName}` : ""}
    </span>
  );
};

const AddBusAdapterButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/busadapters/create",
      state: { record: { BuildingId: record.id } },
    }}
  >
    Add new busadapter
  </Button>
);

const BuildingEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <BuildingShowButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

export const BuildingEdit = (props) => {
  const allTwins = useSelector(state => state?.buildingFilter?.allTwins);

  return (
    <>
      <PageTitle title="Edit Building" />
      <Edit
        {...props}
        title={<BuildingTitle />}
        actions={<BuildingEditActions />}
      >
        <TabbedForm redirect="show">
          <FormTab label="Building">
            <BooleanInput label="GenerateReport" source="GenerateReport" />
            <BooleanInput label="UseCompensation" source="UseCompensation" />
            <SelectInput
              source="TimeZone"
              choices={[
                {
                  id: "FLE Standard Time",
                  name:
                    "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
                },
                {
                  id: "W. Europe Standard Time",
                  name:
                    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
                },
              ]}
            />

            <NumberInput
              label="Weather station latitude"
              source="WeatherStationLocation.Latitude"
            />
            <NumberInput
              label="Weather station longitude"
              source="WeatherStationLocation.Longitude"
            />
          </FormTab>

          <FormTab label="Datasources">
            <CheckboxGroupInput
              source="DataSources"
              choices={[
                { id: 1, name: "ILForecast" },
                { id: 2, name: "ILWeather" },
                { id: 5, name: "Ovaport API" },
                { id: 9, name: "Ovaport API - Fetch missing values" },
                { id: 10, name: "RAU modbus" },
              ]}
            />

            <AddBusAdapterButton />
            <ReferenceManyField
              label="Bus adapters"
              target="BuildingId"
              reference="busadapters"
            >
              <Datagrid>
                <NumberField source="BusAdapterId" />
                <TextField source="AdapterType" />
                <BooleanField source="Active" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Consumption">
            <SelectInput
              label="Comparation place"
              source="BuildingStats.Comparator"
              choices={[
                { id: "Helsinki", name: "Helsinki" },
                { id: "Ivalo", name: "Ivalo" },
                { id: "Joensuu", name: "Joensuu" },
                { id: "Jyväskylä", name: "Jyväskylä" },
                { id: "Kajaani", name: "Kajaani" },
                { id: "Kuopio", name: "Kuopio" },
                { id: "Lahti", name: "Lahti" },
                { id: "Lappeenranta", name: "Lappeenranta" },
                { id: "Maarianhamina", name: "Maarianhamina" },
                { id: "Oulu", name: "Oulu" },
                { id: "Pori", name: "Pori" },
                { id: "Sodankylä", name: "Sodankylä" },
                { id: "Tampere", name: "Tampere" },
                { id: "Turku", name: "Turku" },
                { id: "Vaasa", name: "Vaasa" },
                { id: "Vantaa", name: "Vantaa" },
              ]}
            />
            <div style={{ display: "inline-flex" }}>
              <BooleanInput
                label="Use normalization"
                source="BuildingStats.Normalized"
              />

              <Tooltip
                title="Yes = LG calculates normalization, No = Given numbers already normalized"
                placement="right-end"
              >
                <HelpIcon style={{ margin: "auto", color: "737373" }} />
              </Tooltip>
            </div>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData &&
                formData.BuildingStats &&
                formData.BuildingStats.Normalized && (
                  <BooleanInput
                    label="Use warm water"
                    source="BuildingStats.UseWarmWater"
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData &&
                formData.BuildingStats &&
                formData.BuildingStats.Normalized &&
                formData.BuildingStats.UseWarmWater && (
                  <BooleanInput
                    label="Calculate water usage using default values"
                    source="BuildingStats.UseDefaultWaterCons"
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData &&
                formData.BuildingStats &&
                formData.BuildingStats.Normalized &&
                formData.BuildingStats.UseWarmWater &&
                !formData.BuildingStats.UseDefaultWaterCons && (
                  <TextInput
                    label="Warm water %"
                    source="BuildingStats.WarmWaterPerc"
                    validate={validatePercentage}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData &&
                formData.BuildingStats &&
                formData.BuildingStats.Normalized &&
                formData.BuildingStats.UseWarmWater &&
                formData.BuildingStats.UseDefaultWaterCons && (
                  <div props>
                    <SelectInput
                      label="Set default water consumption [dm^3/brm^2]"
                      source="BuildingStats.WaterConsDefaultVal"
                      fullWidth
                      defaultValue="99"
                      choices={[
                        { id: null, name: "Custom value" },
                        { id: "460", name: "Daycare: 460" },
                        { id: "180", name: "Educational building: 180" },
                        { id: "520", name: "Healthcare: 520" },
                        { id: "100", name: "Office building: 100" },
                        { id: "600", name: "Residential building: 600" },
                        { id: "65", name: "Store: 65" },
                        { id: "1800", name: "Swimming hall: 1800" },
                        { id: "120", name: "Theatre and library: 120" },
                      ]}
                    />
                    <NumberInput
                      label="Selected default value for water consumption"
                      source="BuildingStats.WaterConsDefaultVal"
                      fullWidth
                    />
                  </div>
                )
              }
            </FormDataConsumer>
            {/*
        <TextInput
                label="Set default water consumption [dm^3/brm^2]"
                source="BuildingStats.WaterConsDefaultVal"
              />
        */}

            {/* If water consumption is not custom, render this disabled NumberInput */}
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData &&
                formData.BuildingStats &&
                formData.BuildingStats.Normalized &&
                formData.BuildingStats.UseWarmWater &&
                formData.BuildingStats.UseDefaultWaterCons &&
                FormData.WaterConsumption &&
                11
              }
            </FormDataConsumer>
            {/* If water consumption is selected and is custom, render this NumberInput
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            FormData.WaterConsumption &&
            FormData.WaterConsumption == "9" && (
              <NumberInput
                label="Select a custom value for default water consumption"
                source="BuildingStats.WaterConsDefaultVal"
                defaultValue={100}
              />
            )
          }
        </FormDataConsumer>
        */}
          </FormTab>
          <FormTab label='Update'>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ padding: '1em' }}>
                <FormDataConsumer>
                  {props => <UpdatePWAExportButton record={props.formData} />}
                </FormDataConsumer>
              </div>
              <div style={{
                padding: '1em',
                display: allTwins?.some(twin => twin.BlockType === 'AHU') ? 'block' : 'none'
              }}>
                <RecalculateHistoryButton buildingId={props.id} blockType={'AHU'} />
              </div>
              <div style={{
                padding: '1em',
                display: allTwins?.some(twin => twin.BlockType === 'HP') ? 'block' : 'none'
              }}>
                <RecalculateHistoryButton buildingId={props.id} blockType={'HP'} />
              </div>
            </div>
          </FormTab>

          {/* <FormTab label="Add Consumption">
        <FileInput
          source="files"
          label="Upload consumptions from excel"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <FileField source="src" title="Upload from excel" />
        </FileInput>
      </FormTab> */}
        </TabbedForm>
      </Edit>
    </>
  );
};
