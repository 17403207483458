import React, { useState, useMemo, cloneElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  AutocompleteInput,
  Filter,
  SelectInput,
  Pagination,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  useListContext,
} from "react-admin";
import { round, isNil, cloneDeep } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton, Typography, Card } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import { SourceBuilding } from "../alerts/AlertShow";
import PageTitle from "../components/layout/PageTitle";

const useStyles = makeStyles({
  realizedError: { color: "red" },
});

const EnergySavingsFilters = (props) => {
  // Check through all data and get all unique customerNames for the filter
  const customerChoices = [];
  const customerNames = [];
  if (props && props.data) {
    for (const key in props.data) {
      if (props.data.hasOwnProperty(key)) {
        const c = props.data[key];
        if (!customerNames.includes(c.customerName)) {
          customerChoices.push({ customerName: c.customerName, id: key });
          customerNames.push(c.customerName);
        }
      }
    }
  }

  return (
    <Filter {...props}>
      <SelectInput
        alwaysOn
        allowEmpty={false}
        source="period"
        label="Time period"
        choices={[
          {
            id: "0",
            name: "3 Months",
            value: "3month",
          },
          {
            id: "1",
            name: "12 Months",
            value: "12month",
          },
          {
            id: "2",
            name: "This year",
            value: "this year",
          },
          {
            id: "3",
            name: "Last year",
            value: "last year",
          },
          {
            id: "4",
            name: "Since handover",
            value: "since handover",
          },
          {
            id: "5",
            name: "Since service agreement",
            value: "since service agreement",
          },
          {
            id: "6",
            name: "All",
            value: "all",
          },
        ]}
        optionValue="value"
      />
      <SelectInput
        alwaysOn
        allowEmpty={false}
        source="type"
        label="Type"
        choices={[
          {
            id: "0",
            name: "Heating",
            value: "heating",
          },
          {
            id: "1",
            name: "Electricity",
            value: "electricity",
          },
          {
            id: "2",
            name: "Water",
            value: "water",
          },
          {
            id: "3",
            name: "Cooling",
            value: "cooling",
          },
          {
            id: "4",
            name: "CO2",
            value: "co2",
          },
        ]}
        optionValue="value"
      />
      <AutocompleteInput
        label="Customer"
        alwaysOn
        source="customerName"
        optionText="customerName"
        optionValue="customerName"
        choices={customerChoices}
      />
      <SelectInput
        alwaysOn
        allowEmpty
        source="customerType"
        label="Customer type"
        choices={[
          {
            id: "0",
            name: "Construction",
            value: "Construction",
          },
          {
            id: "1",
            name: "Housing co-ops (Energiaremontti)",
            value: "Housing co-ops (Energiaremontti)",
          },
          {
            id: "2",
            name: "Industrial",
            value: "Industrial",
          },
          {
            id: "3",
            name: "Internal development",
            value: "Internal development",
          },
          {
            id: "4",
            name: "Investor",
            value: "Investor",
          },
          {
            id: "5",
            name: "PublicServices",
            value: "PublicServices",
          },
        ]}
        optionValue="value"
      />
    </Filter>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 1000]} {...props} />
);

const EnergySavingsListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  let customRest = Object.assign({}, rest);
  delete customRest.hasList;
  delete customRest.hasEdit;
  delete customRest.hasShow;
  const targetPercEl = document.querySelector('[data-sort="TargetPerc"]');
  const realizedPercEl = document.querySelector('[data-sort="RealizedPerc"]');
  const targetEnergyEl = document.querySelector('[data-sort="TargetEnergy"]');
  const realizedEnergyEl = document.querySelector(
    '[data-sort="RealizedEnergy"]'
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(customRest)}>
        {targetPercEl && (
          <Tooltip
            title="((Baseline - target consumption) / baseline) * 100"
            PopperProps={{ anchorEl: targetPercEl }}
            open={open}
            arrow
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <IconButton size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        )}
        {realizedPercEl && (
          <Tooltip
            title="((Baseline - actual consumption) / baseline) * 100"
            PopperProps={{ anchorEl: realizedPercEl }}
            open={open}
            arrow
            placement="top"
          >
            <div style={{ display: "none" }} />
          </Tooltip>
        )}
        {targetEnergyEl && (
          <Tooltip
            title="Baseline - target consumption"
            PopperProps={{ anchorEl: targetEnergyEl }}
            open={open}
            arrow
          >
            <div style={{ display: "none" }} />
          </Tooltip>
        )}
        {realizedEnergyEl && (
          <Tooltip
            title="Baseline - actual consumption"
            PopperProps={{ anchorEl: realizedEnergyEl }}
            open={open}
            arrow
            placement="top"
          >
            <div style={{ display: "none" }} />
          </Tooltip>
        )}
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            maxResults={maxResults}
          />
        )}
      </TopToolbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      resource,
      displayedFilters,
      filterValues,
      selectedIds,
      filters,
      total,
      targetPercEl,
      realizedPercEl,
      targetEnergyEl,
      realizedEnergyEl,
      open,
    ]
  );
};

const EnergyField = (props) => {
  const classes = useStyles();
  const targetVal = props.record[props.target];
  const curVal = props.record[props.realized];
  return (
    <TextField
      className={
        props.record.buildingId !== "sum" &&
          !isNil(curVal) &&
          targetVal &&
          curVal < targetVal
          ? classes.realizedError
          : ""
      }
      {...props}
      style={{ fontWeight: props.record.buildingId === "sum" ? 600 : null }}
      source={props.source}
    />
  );
};

const EnergyGrid = (props) => {
  const curType = props.filterValues.type;
  let unit = curType === "water" ? "(m³)" : "(MWh)";
  if (curType === "co2") {
    unit = "(t)";
  }
  const gridProps = cloneDeep(props);
  delete gridProps.hasList;
  delete gridProps.hasShow;
  delete gridProps.hasEdit;

  // Scale electricity values to MWh
  // Scale co2 from kg to tons
  if (curType === "electricity" || curType === "co2") {
    if (gridProps && gridProps.data) {
      for (const k in gridProps.data) {
        if (gridProps.data.hasOwnProperty(k)) {
          const d = gridProps.data[k];
          if (d && d.realizedEnergy) {
            d.realizedEnergy = round(d.realizedEnergy / 1000, 2);
          }
          if (d && d.targetEnergy) {
            d.targetEnergy = round(d.targetEnergy / 1000, 2);
          }
          if (d && d.baseline) {
            d.baseline = round(d.baseline / 1000, 2);
          }
          if (d && d.target) {
            d.target = round(d.target / 1000, 2);
          }
        }
      }
    }
  }

  if (
    gridProps &&
    gridProps.data &&
    gridProps.ids &&
    gridProps.ids.length > 0
  ) {
    let targetSum = 0;
    let targetExists = false;
    let realizedSum = 0;
    let realizedExists = false;

    let actTargetSum = 0;
    let actTargetExists = false;

    let baselineSum = 0;
    let baselineExists = false;

    gridProps.ids.forEach((k) => {
      if (gridProps.data[k]) {
        if (!isNil(gridProps.data[k].targetEnergy)) {
          targetExists = true;
          targetSum += gridProps.data[k].targetEnergy;
        }

        if (!isNil(gridProps.data[k].realizedEnergy)) {
          realizedExists = true;
          realizedSum += gridProps.data[k].realizedEnergy;
        }

        if (!isNil(gridProps.data[k].baseline)) {
          baselineExists = true;
          baselineSum += gridProps.data[k].baseline;
        }

        if (!isNil(gridProps.data[k].target)) {
          actTargetExists = true;
          actTargetSum += gridProps.data[k].target;
        }
      }
    });

    gridProps.data = {
      ...gridProps.data,
      sum: {
        buildingId: "sum",
        targetPerc:
          baselineExists && actTargetExists
            ? round(((baselineSum - actTargetSum) / baselineSum) * 100, 2)
            : null,
        realizedPerc:
          baselineExists && realizedExists
            ? round((realizedSum / baselineSum) * 100, 2)
            : null,
        targetEnergy: targetExists ? round(targetSum, 2) : null,
        realizedEnergy: realizedExists ? round(realizedSum, 2) : null,
        baseline: baselineExists ? round(baselineSum, 2) : null,
      },
    };
    if (!gridProps.ids.includes("sum")) {
      gridProps.ids.push("sum");
    }
  }

  return (
    <Datagrid {...gridProps}>
      <SourceBuilding
        sortBy="ProjectName"
        addLabel={true}
        label="Building"
        buildingIdAsBuildingId
      />
      <EnergyField
        source="baseline"
        sortBy="Baseline"
        label={"Baseline " + unit}
        realized="realizedPerc"
        target="targetPerc"
      />
      <EnergyField
        source="targetPerc"
        sortBy="TargetPerc"
        label="Target (%)"
        realized="realizedPerc"
        target="targetPerc"
      />
      <EnergyField
        source="realizedPerc"
        sortBy="RealizedPerc"
        label="Realized (%)"
        realized="realizedPerc"
        target="targetPerc"
      />
      <EnergyField
        source="targetEnergy"
        sortBy="TargetEnergy"
        label={"Target " + unit}
        realized="realizedPerc"
        target="targetPerc"
      />
      <EnergyField
        source="realizedEnergy"
        sortBy="RealizedEnergy"
        label={"Realized " + unit}
        realized="realizedPerc"
        target="targetPerc"
      />
      <TextField sortBy="CustomerName" source="customerName" />
      <TextField sortBy="CustomerType" source="customerType" />
    </Datagrid>
  );
};

const Aside = () => {
  const { data, ids } = useListContext();
  const totalCount = ids.map(id => data[id]).reduce((acc, cur) => acc + 1, 0);
  const realizedCount = ids.map(id => data[id]).reduce((acc, cur) => {
    const isRealized = !(cur.realizedPerc < cur.targetPerc);
    if (isRealized) {
      acc++;
    }
    return acc
  }, 0);
  const realizedPercentage = realizedCount / totalCount * 100;
  return (
    <Card style={{ width: 200, margin: '0 0 0 1em', padding: '1em', height: 'fit-content' }}>
      <Typography variant="h6">Summary</Typography>
      <Typography variant="body2">Total buildings: {totalCount}</Typography>
      <Typography variant="body2">Realized buildings: {realizedCount}</Typography>
      {!isNaN(realizedPercentage) && <Typography variant="body2">Realized percentage: {round(realizedPercentage, 2)}%</Typography>}
    </Card>
  );
};

const EnergySavingsList = (props) => {
  return (
    <>
      <PageTitle title="Savings Portfolio" />
      <List
        {...props}
        pagination={<ValuesPagination />}
        filterDefaultValues={{ period: "3month", type: "heating" }}
        filters={<EnergySavingsFilters />}
        bulkActionButtons={false}
        actions={<EnergySavingsListActions {...props} />}
        sort={{ field: "ProjectName", order: "ASC" }}
        perPage={1000}
        aside={<Aside />}
      >
        <EnergyGrid {...props} />
      </List>
    </>
  );
};

export default EnergySavingsList;
