import { addDays } from "date-fns";
import addMonths from "date-fns/addMonths";

const getCumulativeTarget = (startDate: Date, endDate: Date, data: number[], initialValue = 0): [number, number][] => {
    let curDate = addDays(startDate, 1);
    let targetSum = initialValue;
    let output = [];
    while (getDateAsMonth(curDate).getTime() < getDateAsMonth(endDate).getTime()) {
        const curMonthIdx = curDate.getUTCMonth();
        const curBaseline = data[curMonthIdx];
        targetSum = targetSum + curBaseline;
        const formattedDate = getDateAsMonth(curDate);
        output.push([formattedDate.getTime(), targetSum] as [number, number]);
        curDate = addMonths(curDate, 1);
    }
    return output;
}

function getDateAsMonth(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getUTCMonth(), 1));
}

export { getCumulativeTarget };
