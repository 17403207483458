import React from "react";
import {
  AutocompleteInput,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";
import DateTimeWithTimezoneInput from "../components/TimeInputs";
import { setPowerConsumptionUnitText } from "../utils/unitTextParsers";
import PageTitle from "../components/layout/PageTitle";

const PowerConsumptionListsCreate = ({ BuildingId, ...props }) => {
  // Delete unused/unnecessary props
  let createProps = Object.assign({}, props);
  delete createProps.buildingTwinId;
  delete createProps.buildingSelected;
  delete createProps.dispatch;
  return (
    <>
      <PageTitle title="Create Power Consumption" />
      <Create {...createProps}>
        <SimpleForm redirect="list">
          <ReferenceInput
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ showBAUI: true }}
            perPage={1000}
            allowEmpty
            fullWidth
            defaultValue={BuildingId}
          >
            <AutocompleteInput
              optionText="ProjectName"
              optionValue="id"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput
            source="Power"
            label={setPowerConsumptionUnitText("Power")}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.Cooling &&
              !formData.WIncoming &&
              !formData.WOutgoing && (
                <NumberInput
                  source="WaterAmount"
                  label={setPowerConsumptionUnitText("WaterAmount")}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.WaterAmount &&
              !formData.Cooling && (
                <NumberInput
                  source="WIncoming"
                  label={setPowerConsumptionUnitText("WIncoming")}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.WaterAmount &&
              !formData.Cooling && (
                <NumberInput
                  source="WOutgoing"
                  label={setPowerConsumptionUnitText("WOutgoing")}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.WaterAmount &&
              !formData.WIncoming &&
              !formData.WOutgoing && (
                <NumberInput
                  source="Cooling"
                  label={setPowerConsumptionUnitText("Cooling")}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <DateTimeWithTimezoneInput source="Time" label="Time" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(PowerConsumptionListsCreate);
