import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import temperatureMapping from "./temperatureMapping";

const useTemperatureDataMapper = ({ consumption, type }) => {
  const temperature = useSelector((state) => state.buildingFilter.buildingTemp);
  const [temperatureSeries, setTemperatureSeries] = useState(null);

  useEffect(() => {
    if (temperature && !temperatureSeries) {
      setTemperatureSeries(
        temperatureMapping({
          temperatureData: temperature,
          consumptionData: consumption,
          type,
        })
      );
    }
  }, [temperature, consumption, temperatureSeries, type]);
  return temperatureSeries;
};

export default useTemperatureDataMapper;
