import React from "react";
import { makeStyles } from "@material-ui/core";

import Pump from "./Pump";
import LeftSideArrows from "./LeftSideArrows";
import HeatingTank from "./HeatingTank";
import WaterTank from "./WaterTank";
import BottomArrows from "./BottomArrows";

const useStyles = makeStyles({
  root: {
    minHeight: 370,
    minWidth: 242,
    boxShadow: "0px 0px 10px #0000003D",
    border: "1px solid #282331",
    borderRadius: 10,
    background: "#F9F9F9 0% 0% no-repeat padding-box",
    marginLeft: 322,
    marginTop: 100,
    marginBottom: 180,
    padding: "82px 60px",
    position: "relative",
    boxSizing: "border-box",
    display: "inline-flex",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
});

const MainTank = ({
  pumps = [],
  heatSource,
  inputData,
  energyPoints,
  existing = [],
}) => {
  const classes = useStyles();

  const arrowOffset = pumps && pumps.length >= 5 ? pumps.length * 0.25 : 0;
  const arrowHeight = pumps && pumps.length >= 5 ? 41 + pumps.length * 0.5 : 41;
  const extraPumps = pumps && pumps.length >= 5 ? pumps.length - 4 : 0;

  return (
    <div className={classes.root}>
      <LeftSideArrows
        inputData={inputData}
        energyPoints={energyPoints}
        existing={existing}
        arrowHeight={arrowHeight}
        arrowOffset={arrowOffset}
        heatSource={heatSource}
      />
      <div className={classes.container}>
        {pumps &&
          pumps.length > 0 &&
          pumps.map((p) => <Pump key={p.pumpNumber} pump={p} />)}
      </div>
      {existing &&
        (existing.includes("heatingEnergy") ||
          existing.includes("heatingResistor")) && (
          <HeatingTank
            inputData={inputData}
            energyPoints={energyPoints}
            existing={existing}
            arrowHeight={arrowHeight}
            arrowOffset={arrowOffset}
            extraPumps={extraPumps}
          />
        )}
      {existing &&
        (existing.includes("waterEnergy") ||
          existing.includes("waterResistor")) && (
          <WaterTank
            inputData={inputData}
            energyPoints={energyPoints}
            existing={existing}
            arrowHeight={arrowHeight}
            arrowOffset={arrowOffset}
            extraPumps={extraPumps}
          />
        )}
      <BottomArrows
        inputData={inputData}
        energyPoints={energyPoints}
        existing={existing}
        arrowHeight={arrowHeight}
        arrowOffset={arrowOffset}
      />
    </div>
  );
};

export default MainTank;
