import React from "react";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
  TopToolbar,
  ExportButton,
} from "react-admin";
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";
import CreateSubmeterDeviceDialog from "./CreateSubmeterDeviceDialog";
import SubmeterReadingCreate from "./SubmeterReadingsCreate";
import { create, isNil } from "lodash";

const useStyles = makeStyles({
  createButtonStyle: {
    color: "#3f51b5",
    fontSize: "0.8125rem",
  },
  perDayStyle: {
    margin: 10,
    padding: 20,
    width: "30%",
    background: "#eee",
  },
  configureButton: { backgroundColor: "#3f51b5", color: "#fff" },
});

//test values for daily report in next release
const readings = [
  {
    id: 1,
    MeasurementValue: 10,
    Unit: "kWh",
    Time: "08/05/2023",
    DeviceName: "Elec 10",
    MeasuremenTarget: "electricity",
  },
  {
    id: 2,
    MeasurementValue: 20,
    Unit: "kWh",
    Time: "08/06/2023",
    DeviceName: "Elec 11",
    MeasuremenTarget: "electricity",
  },
  {
    id: 3,
    MeasurementValue: 30,
    Unit: "kWh",
    Time: "08/07/2023",
    DeviceName: "Elec 12",
    MeasuremenTarget: "electricity",
  },
  {
    id: 4,
    MeasurementValue: 40,
    Unit: "kWh",
    Time: "08/08/2023",
    DeviceName: "Elec 13",
    MeasuremenTarget: "electricity",
  },
  {
    id: 5,
    MeasurementValue: 50,
    Unit: "kWh",
    Time: "08/09/2023",
    DeviceName: "Elec 14",
    MeasuremenTarget: "electricity",
  },
];

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const SubmeterReadingsList = ({ BuildingId, projectname, ...props }) => {
  const classes = useStyles();
  const [submetersOpen, setSubmetersOpen] = useState(false);
  const [readingsOpen, setReadingsOpen] = useState(false);

  const handleClickOpen = (name) => {
    if (name === "readings") setReadingsOpen(true);
    if (name === "submeters") setSubmetersOpen(true);
  };

  const handleClose = () => {
    setReadingsOpen(false);
    setSubmetersOpen(false);
  };

  // Delete unused/unnecessary props
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  // Prevent rendering component without BuildingId
  if (isNil(BuildingId)) {
    return null;
  }

  const SubmeterReadingsPerDay = () => {
    return (
      <Paper className={classes.perDayStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {readings?.map((r) => (
              <TableRow>
                <TableCell>{r.Time}</TableCell>
                <TableCell>{r.MeasurementValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  const ListActions = () => (
    <TopToolbar>
      <Button
        className={classes.createButtonStyle}
        onClick={() => {
          handleClickOpen("readings");
        }}
        label="Create"
        startIcon={<Add style={{ width: "90%" }} />}
      >
        Create
      </Button>
      <ExportButton />
    </TopToolbar>
  );

  return (
    <>
      <PageTitle title="Submeters" />
      <Button
        className={classes.configureButton}
        variant="contained"
        onClick={() => handleClickOpen("submeters")}
      >
        Configure Submeter Devices
      </Button>
      <List
        actions={<ListActions />}
        {...listProps}
        perPage={25}
        pagination={<ValuesPagination />}
        filterDefaultValues={{ BuildingId }}
        bulkActionButtons={false}
        sort={{ field: "Time", order: "DESC" }}
        onError={(e) => console.warn("HELLO", e)}
      >
        <Datagrid expand={null}>
          <DateField
            source="Time"
            options={{
              year: "numeric",
              month: "numeric",
            }}
          />
          <TextField source="DeviceName" />
          <TextField label="Type" source="MeasurementTarget" />
          <TextField label="Value" source="MeasurementValue" />
          <TextField source="Unit" />
        </Datagrid>
      </List>
      <Dialog onClose={handleClose} open={submetersOpen}>
        <CreateSubmeterDeviceDialog handleClose={handleClose} />
      </Dialog>
      <Dialog onClose={handleClose} open={readingsOpen}>
        <SubmeterReadingCreate handleClose={handleClose} />
      </Dialog>
    </>
  );
};

export default stateMapper(SubmeterReadingsList);
