import React from "react";
import { NullableBooleanInput } from "react-admin";
import { get } from "lodash";

function isSolarInputSelected(formData, inputId) {
  const input = get(formData.Solar, inputId, undefined);

  if (!input) {
    return false;
  }

  if (Array.isArray(input) && input.length > 0) {
    return true;
  } else {
    const busTagName = get(input, inputId + ".BusTagName", undefined);

    if (busTagName) {
      return true;
    }
    return false;
  }
}

export const SolarOperations = formData => (
  <div style={{ display: "grid" }}>
    <NullableBooleanInput
      label="Calculate output energy running sum"
      source="Solar.Operations.CalculateEnergyRunningSum.Active"
      defaultValue={isSolarInputSelected(formData, "OutputEnergyPoints")}
      disabled={!isSolarInputSelected(formData, "OutputEnergyPoints")}
      style={{ width: "300px" }}
    />
    <NullableBooleanInput
      label="Calculate total output power"
      source="Solar.Operations.CalculateTotalOutputPower.Active"
      defaultValue={isSolarInputSelected(formData, "OutputPowerPoints")}
      disabled={!isSolarInputSelected(formData, "OutputPowerPoints")}
      style={{ width: "300px" }}
    />
  </div>
);
