import { useState, useEffect } from "react";
import { usePermissions } from "react-admin";

type UserRole = "admin" | "user" | "reader" | "extuser";

export const getUserRoles = (permissions: UserRole[]) => {
  const isAdmin = permissions.includes("admin");
  const isUser = permissions.includes("user");
  const isReader = permissions.includes("reader");
  const isExternal = permissions.includes("extuser");

  /**
   * User is external non-admin
   */
  const isExternalUser = isExternal && !isAdmin;
  const isInternalAdmin = !isExternal && isAdmin;
  const isAdminOrUser = isAdmin || isUser;
  const isUserOrReader = isUser || isReader;
  const isExternalOrReader = isReader || isExternal;

  return {
    isAdmin,
    isUser,
    isReader,
    isAdminOrUser,
    isUserOrReader,
    isExternalUser,
    isInternalAdmin,
    isExternalOrReader,
    permissions,
  };
};

function useUserRole() {
  const perm = usePermissions();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    setPermissions(perm?.permissions || []);
  }, [perm]);

  return getUserRoles(permissions);
}

export default useUserRole;
