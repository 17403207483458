import { isNil, toString } from 'lodash';
import React, { useEffect, useRef } from "react";
import { AhuTwinPropertyValue } from '../../AhuTwinInfo';

export interface SvgMapping {
  selector: string;
  onElementChange?: (element: SVGSVGElement, valueToWrite: string | number) => void;
}

export interface SvgElementConfig {
  getTitleEl: SvgMapping;
  getValueEl: SvgMapping;
  otherEls?: SvgMapping[];
}

export type SvgValueMappings = Map<AhuTwinPropertyValue['variableName'], SvgElementConfig>;


interface SvgDataMapperProps {
  twinData: AhuTwinPropertyValue[];
  svgElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  elementValueMappings: SvgValueMappings;
  elementsToHide?: SvgMapping[];
}

const renderValueToSvg = (valueToWrite: string | number, mapping: SvgMapping, svgElement: SVGSVGElement, renderValue: boolean = true) => {
  if (!!mapping.selector && mapping.selector.length > 0) {
    const elementToChange = svgElement.querySelector<SVGSVGElement>(mapping.selector);
    if (elementToChange && !isNil(valueToWrite) && renderValue) {
      elementToChange.textContent = toString(valueToWrite);
    }
    if (mapping.onElementChange) {
      mapping.onElementChange(elementToChange, valueToWrite);
    }
  }
};

const setElementVisibility = (selector: string, visible: boolean, svgElement: SVGSVGElement) => {
  if (!!selector && selector.length > 0) {
    const elementToChange = svgElement.querySelector<SVGSVGElement>(selector);
    if (elementToChange) {
      elementToChange.style.display = visible ? "block" : "none";
    }
  }
};

export const SvgElementDataMapper = (props: SvgDataMapperProps) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    props.elementValueMappings.forEach(mapping => {
      if (!!svgRef?.current) {
        // renderValueToSvg(value.title, mapping?.getTitleEl, svgRef.current);
        renderValueToSvg(`--`, mapping?.getValueEl, svgRef.current);
        setElementVisibility(mapping?.getValueEl.selector, false, svgRef?.current);
        setElementVisibility(mapping?.getTitleEl.selector, false, svgRef?.current);
        mapping?.otherEls?.forEach(mapping => setElementVisibility(mapping.selector, false, svgRef?.current));
      }
    });

    props.twinData.forEach(value => {
      if (!!svgRef?.current) {
        const selectors = props.elementValueMappings.get(value.fieldKey);
        if (!!selectors) {
          if (selectors.otherEls?.length > 0) {
            selectors.otherEls.forEach(mapping => {
              setElementVisibility(mapping.selector, true, svgRef?.current);
              renderValueToSvg(value.data, mapping, svgRef?.current, false);
            });
          }
          setElementVisibility(selectors?.getTitleEl.selector, true, svgRef?.current);
          renderValueToSvg(value.title, selectors?.getTitleEl, svgRef.current);
          setElementVisibility(selectors?.getValueEl.selector, true, svgRef?.current);
          renderValueToSvg(`${isNil(value.data) ? '--' : value.data} ${value.unit}`, selectors?.getValueEl, svgRef.current);
        }
      }
    });

    (props.elementsToHide || []).forEach(mapping => {
      if (!!svgRef?.current && mapping.selector.length > 0) {
        setElementVisibility(mapping.selector, false, svgRef?.current);
      }
    });
  }, [
    props.twinData,
    svgRef,
    props.elementValueMappings,
    props.elementsToHide
  ])
  return <props.svgElement ref={svgRef} style={{ width: '100%', height: '600px' }}></props.svgElement>;
}