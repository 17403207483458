import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(() => ({
  tooltip: {
    marginTop: (props) => props.tooltipMargin,
  },
}));

const OperationTooltip = ({
  children,
  field,
  placement = "bottom-start",
  tooltipMargin = null,
  tooltipPadding = 23,
}) => {
  const props = { tooltipMargin };
  const classes = useStyles(props);

  const tooltipTexts = {
    ProjectCharterLink:
      "Link to the PWA Project Charter. Project Charter can contain the necessary attachments, such as Excel-files, emails or meeting minutes.",
    Title: "General description of the log",
    HVAC_process: "HVAC process related to the log",
    OperationBlocks: "Digital twins related to the log",
    Error_or_deficiency: "Error/Deficiency caused by the contractor",
    Cause: "The causes for the issue",
    Action: "Actions taken to correct the issue",
    Discussion: "Discussion about the issue / other specifying information",
    Change_plan: "Change plan and actions agreed upon to fix the issue",
    Effect: "What does the issue affect",
    Effect_mwh: "Effect of the issue in MWh, for example per year",
    Needed_resourse:
      "Resources needed to handle the issue. For example in euros per contract and/or planning/mapping necessities.",
    State:
      "State of the log. Active and delayed logs require actions to complete.",
    State_change_date: "Date of the latest state change",
    To_customer_team:
      "Solving of the issue requires resources from sales/planning/projects. Meeting with the project team is recommended.",
    Contractor: "Contractor related to the log",
    LG_checkup:
      "Date for a checkup by a LG representative. Adding a checkup date will automatically pin the log and create a reminder. Remove the checkup date or manually unpin the log to remove the reminder.",
    InternalComment: "Comments not visible to customers",
    ExternalComment:
      "Comments visible to customers. Will be shown with the label 'Comments' in customer view",
    VisibleToExternal:
      "Log and the external comments will be visible to customers",
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {children}
      <Tooltip
        classes={{
          tooltipPlacementBottom: classes.tooltip,
        }}
        placement={placement}
        title={tooltipTexts[field]}
      >
        <HelpIcon style={{ paddingTop: tooltipPadding, marginLeft: 10 }} />
      </Tooltip>
    </div>
  );
};

export default OperationTooltip;
