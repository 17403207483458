import React from "react";

interface UnderlinedInputProps { value: number | string, unit: string, description?: string };

export const UnderlinedInput = ({ value, unit, description }: UnderlinedInputProps) =>
  <div style={{
    padding: '.3em 3em',
    margin: '1em',
    borderBottom: '2px #dadada solid',
    width: 'max-content',
    fontFamily: 'SegoeUI-Semibold, "Segoe UI"',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  }}>
    <div style={{ margin: '0 .2em', fontSize: "1.25em" }}>{value} {unit}</div>
    <div style={{ margin: '0 .2em', fontSize: "1em" }}>{description ? description : ''}</div>
  </div>;
