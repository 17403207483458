import { useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllTwins,
  setAhuData,
  setSolarData,
} from "../customReducers/actions";
import { useState, useEffect } from "react";

function useAllTwins(id) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [buildingId, setBuildingId] = useState(null);
  const [fetching, setFetching] = useState(false);
  const dataProvider = useDataProvider();
  const allTwins = useSelector((state) => state.buildingFilter.allTwins);

  // Set some datas to null when building is changed
  useEffect(() => {
    if (!fetching && (id !== buildingId || !allTwins)) {
      setFetching(true);
      setBuildingId(id);
      dispatch(setAllTwins(null));
      dispatch(setAhuData(null));
      dispatch(setSolarData(null));

      dataProvider
        .getList("blocks", {
          filter: { BuildingId: id },
          sort: {},
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.length > 0) {
              dispatch(setAllTwins(data));
            } else {
              dispatch(setAllTwins([]));
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
          dispatch(setAllTwins([]));
        })
        .finally(() => setFetching(false));
    }
  }, [id, buildingId, dispatch, notify, dataProvider, allTwins, fetching]);

  return null;
}

export default useAllTwins;
