import React from "react";
import {
  List,
  ChipField,
  Datagrid,
  TextField,
  BooleanInput,
  BooleanField,
  ReferenceInput,
  SelectInput,
  EditButton,
  Filter,
  TextInput,
  NumberField,
  ReferenceField,
  Pagination,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const FunctionFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Block"
        source="BlockId"
        reference="blocks"
        sort={{ field: "BlockName", order: "ASC" }}
        filter={{ BuildingId: props.buildingid }}
        alwaysOn
        className={classes.helperText}
        style={{ marginLeft: 10 }}
      >
        <SelectInput optionText="BlockName" optionValue="id" />
      </ReferenceInput>
      <TextInput label="Search" source="q" className={classes.helperText} />
      <BooleanInput label="Active" source="Active" alwaysOn />
    </Filter>
  );
};

const CompensationFunctionList = ({ BuildingId, projectname, ...props }) => {
  const { isAdminOrUser } = useUserRole();
  // Remove unused props
  const listProps = Object.assign({}, props);
  delete listProps.dispatch;
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;

  return (
    <>
      <PageTitle title="Functions" />
      <List
        perPage={25}
        pagination={<ValuesPagination />}
        bulkActionButtons={false}
        filter={{ BuildingId }}
        filters={<FunctionFilter buildingid={BuildingId} />}
        filterDefaultValues={{ Active: true }}
        {...listProps}
      >
        <Datagrid>
          <NumberField source="id" options={{ useGrouping: false }} />
          <ReferenceField
            label="Block"
            source="BlockId"
            reference="blocks"
            allowEmpty
          >
            <ChipField source="BlockName" />
          </ReferenceField>
          <TextField label="Type" source="FunctionType" />
          <TextField label="Name" source="Name" />
          <TextField label="Description" source="ValueStorageDescription" />
          <NumberField
            label="Latest value"
            source="LatestValueStorage.PreciseValue"
          />
          <BooleanField source="Active" />
          {isAdminOrUser && <EditButton />}
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(CompensationFunctionList);
