import React from "react";
import { makeStyles, Typography, Tooltip } from "@material-ui/core";
import { isNil, round } from "lodash";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    height: 28,
    width: 234,
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    top: 25,
    left: 25,
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: "#282331",
  },
});

const COP = ({ energyPoints }) => {
  const classes = useStyles();

  const cop = energyPoints && energyPoints.find((d) => d.name === "cop");

  const systemCop =
    energyPoints && energyPoints.find((d) => d.name === "systemCop");

  return (
    <div className={classes.container}>
      <Typography className={classes.text} noWrap>
        {!isNil(cop) && !isNil(cop.value) ? (
          <Tooltip title="Last 24h">
            <span>{`${round(cop.value, 1)} COP`}</span>
          </Tooltip>
        ) : (
          "-- COP"
        )}
      </Typography>
      <Typography className={classes.text} noWrap>
        {!isNil(systemCop) && !isNil(systemCop.value) ? (
          <Tooltip title="Last 24h">
            <span>{`${round(systemCop.value, 1)} System COP`}</span>
          </Tooltip>
        ) : (
          "-- System COP"
        )}
      </Typography>
    </div>
  );
};

export default COP;
