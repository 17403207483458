import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { showNotification } from "react-admin";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { GET_ONE } from "react-admin";
import { restClient } from "../App";

class RefreshRegisterlistsButton extends Component {
  handleClick = event => {
    event.preventDefault();
    const { push, record, showNotification } = this.props;
    showNotification("Updating registerlists");

    restClient(GET_ONE, "busadapters", {
      id: `${record.id}/UpdateRegisterlists`
    })
      .then(response => {
        showNotification("Updated registers");
        push("/busadapters");
      })
      .catch(e => {
        console.error(e);
        showNotification("Error", "warning");
      });
  };

  render() {
    const record = this.props;
    return record.record.AdapterType === "IOTHUB" ? (
      <Button onClick={this.handleClick}>Refresh registerlists</Button>
    ) : null;
  }
}

export default connect(null, {
  showNotification,
  push
})(RefreshRegisterlistsButton);
