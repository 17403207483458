import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from "@material-ui/core/Tooltip";
import useCustomFetch from '../utils/hooks/useCustomFetch';

export const RecalculateHistoryButton = (props: { buildingId: string; blockType: "AHU" | "HP" }) => {
  const { buildingId, blockType } = props;
  const customFetch = useCustomFetch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const url = `blocks/${buildingId}/${blockType}/orderRecalculation`;

  const handleRecalculateBtnClick = () => {
    setIsDialogOpen(true);
  }
  
  const handleClose = () => {
    setIsDialogOpen(false);
  }
  
  const handleConfirmCalculation = () => {
    setIsDialogOpen(false);
    customFetch({
      method: "PUT",
      url
    });
  }

  useEffect(() => {
    customFetch({
      method: "GET",
      url
    })
      .then(async response => {
        const { isCalculating }: { isCalculating: boolean } = await response.json();
        setIsCalculating(isCalculating);
      })
      .catch(err => {
        console.warn(err);
      });
  }, [url, customFetch]);

  return <div>
    <Tooltip title={`Recalculate history for ${blockType} blocks`} placement="top">
    {
      isCalculating
        ? <Button variant='contained' disabled>
            Recalculating {blockType}
          </Button>
        : <Button variant='contained' onClick={handleRecalculateBtnClick}>
          Recalculate {blockType}
        </Button>
      }
    </Tooltip>
    <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to start recalculation?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This starts aggregation of raw data in Azure Databricks tonight and should be complete by tomorrow morning.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmCalculation} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  </div>;
}