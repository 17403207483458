import React, { useState, useEffect } from "react";
import { GET_LIST, useShowController } from "react-admin";
import { restClient } from "../App";
import { makeStyles, Typography, Tooltip } from "@material-ui/core";
import { find, map } from "lodash";

import HpShow from "./HpShow";
import AhuShow from "./AhuShow";
import ZoneShow from "./ZoneShow";
import HxShow from "./HxShow";
import SolarShow from "./SolarShow";
import MeterShow from "./MeterShow";

import { stateMapper } from "../customReducers/connectFunctions";
import useQuery from "../utils/hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { changebuilding } from "../customReducers/actions";
import { setBuilding as fetchBuilding } from "../layout/appbar/BuildingSelect";
import { useLocation, useHistory } from "react-router-dom";
import useSetBuilding from "../utils/hooks/useSetBuilding";
import useUserRole from "../utils/hooks/useUserRole";
import PageTitle from "../components/layout/PageTitle";

import ahuIcon from "../assets/icons/ahu_icon.svg";
import hpIcon from "../assets/icons/hp_icon.svg";
import hxIcon from "../assets/icons/hx_icon.svg";
import meterIcon from "../assets/icons/meter_icon.svg";
import solarIcon from "../assets/icons/solar_icon.svg";
import zoneIcon from "../assets/icons/zone_icon.svg";
import twinForExternal from "../utils/twinForExternal";
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles({
  root: {
    minHeight: "110vh",
  },
  cardContent: {
    "& > div": {
      padding: "0 !important",
    },
  },
  twinTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  twinTitleIcon: {
    boxShadow: "0px 0px 6px #0000004D",
    borderRadius: "50%",
    marginRight: 17,
  },
  twinTitleName: {
    fontSize: "1.875rem",
    color: "#282331",
    marginRight: 20,
    fontWeight: 400,
  },
  twinTitleSpacer: {
    height: 30,
    marginLeft: 5,
    borderRight: "2px solid #77747F",
    opacity: 0.6,
    marginRight: 20,
  },
  twinTitleDescription: {
    fontSize: "1rem",
    color: "#282331",
  },
  tooltip: {
    backgroundColor: "#282331",
    borderRadius: "2px",
    boxShadow: "0px 3px 6px #00000029",
    fontSize: "0.5625rem",
  },
  tooltipArrow: {
    color: "#282331",
  },
});

const findMetaIds = (record, id) => {
  let y = find(record.Trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

// Custom component for title
const TwinTitle = ({ type, name, description }) => {
  const classes = useStyles();
  let twinIcon = null;
  const twinType = type && typeof type === "string" ? type.toLowerCase() : "";
  let tooltipText = "";

  switch (twinType) {
    case "ahu":
      twinIcon = ahuIcon;
      tooltipText = "Air handling unit";
      break;
    case "hp":
      twinIcon = hpIcon;
      tooltipText = "Heat pump";
      break;
    case "hx":
      twinIcon = hxIcon;
      tooltipText = "Heating exchanger";
      break;
    case "meter":
      twinIcon = meterIcon;
      tooltipText = "Meter";
      break;
    case "solar":
      twinIcon = solarIcon;
      tooltipText = "Solar";
      break;
    case "zone":
      twinIcon = zoneIcon;
      tooltipText = "Zone";
      break;
    default:
      break;
  }

  return (
    <div className={classes.twinTitleContainer}>
      <Tooltip
        arrow
        placement="top"
        title={tooltipText}
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      >
        <img src={twinIcon} alt={type} className={classes.twinTitleIcon} />
      </Tooltip>
      <Typography variant="h2" className={classes.twinTitleName}>
        {name}
      </Typography>
      <div className={classes.twinTitleSpacer} />
      <Typography variant="body1" className={classes.twinTitleDescription}>
        {description}
      </Typography>
    </div>
  );
};

const BlockShow = ({ BuildingId, projectname, ...props }) => {
  const { instance: msalInstance } = useMsal();
  const classes = useStyles();
  const buildingSelected = useSelector(
    (state) => state.buildingFilter.buildingSelected
  );
  const selectedBuilding = useSelector(
    (state) => state.buildingFilter.Building
  );
  const selectedBuildingId = selectedBuilding ? selectedBuilding.id : null;

  const [recordState, setRecord] = useState(null);
  const [solarEfficiency, setSolarEfficiency] = useState(null);
  const [solarLoading, setSolarLoading] = useState(false);
  const [zoneImage, setZoneImage] = useState(null);
  const [zoneImageFetched, setZoneImageFetched] = useState(false);
  const [zoneFeedback, setZoneFeedback] = useState(null);
  const [zoneFeedbackFetched, setZoneFeedbackFetched] = useState(false);
  const { isAdminOrUser } = useUserRole();

  const setBuilding = useSetBuilding();

  const dispatch = useDispatch();

  const query = useQuery();
  const location = useLocation();
  const history = useHistory();

  const { isExternalUser } = useUserRole();

  const { record } = useShowController(props);

  const buildingId = record && record.BuildingId ? record.BuildingId : null;

  // Reset block specific data when record changes
  useEffect(() => {
    if (record && record.id) {
      setZoneFeedback(null);
      setZoneImage(null);
      setZoneFeedbackFetched(false);
      setZoneImageFetched(false);
      setSolarEfficiency(null);
    }
  }, [record]);

  useEffect(() => {
    if (record) {
      setRecord(record);
    }
  }, [record, recordState]);

  // Fetch some solar/etc data here to avoid multiple calls in lower components
  useEffect(() => {
    async function fetchZoneImage(id) {
      const token = await msalInstance.acquireTokenSilent({
        scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
      })
        .catch((error) => {
          msalInstance.loginRedirect();
        });
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/blocks/${id}/image`,
        requestOptions
      )
        .then((r) => r.blob())
        .then((blob) => {
          if (blob.type === "image/png") {
            setZoneImage(URL.createObjectURL(blob));
          } else setZoneImage(null);
        })
        .catch(() => {
          setZoneImage(null);
        });
    }
    async function fetchZoneFeedback(id) {
      const token = await msalInstance.acquireTokenSilent({
        scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
      })
        .catch((error) => {
          msalInstance.loginRedirect();
        });
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/blocks/${id}/feedback`,
        requestOptions
      )
        .then((r) => r.json())
        .then((d) => {
          setZoneFeedback(d);
        })
        .catch(() => {
          setZoneFeedback(null);
        });
    }

    if (
      recordState &&
      recordState.Trends &&
      recordState.BlockType.toLowerCase() === "solar" &&
      (!solarEfficiency || solarEfficiency === [])
    ) {
      const effMeta = findMetaIds(recordState, "SOLAR_ENERGY_EFFICIENCY_DAILY");
      if (effMeta.length > 0) {
        setSolarLoading(true);
        restClient(GET_LIST, "reportdata", {
          filter: {
            buildingId: recordState.BuildingId,
            metaIds: effMeta,
            resolution: "day",
            trend: "SOLAR_ENERGY_EFFICIENCY_DAILY",
          },
          sort: { field: "UpdateTime", order: "ASC" },
          pagination: {},
        })
          .then((res) => {
            setSolarLoading(false);
            if (res && res.data && res.data.length > 0) {
              // Change to percentage
              res.data.forEach((e) => {
                e.Value = e.Value * 100;
              });
              setSolarEfficiency(res.data);
            }
          })
          .catch(() => {
            setSolarLoading(false);
            setSolarEfficiency([]);
          });
      }
    } else if (
      recordState &&
      recordState.BlockType.toLowerCase() === "zone" &&
      recordState.id
    ) {
      if (!zoneImageFetched) {
        setZoneImageFetched(true);
        // Get the zone image
        fetchZoneImage(recordState.id);
      }
      if (!zoneFeedbackFetched) {
        setZoneFeedbackFetched(true);
        fetchZoneFeedback(recordState.id);
      }
    }
  }, [
      recordState,
      solarEfficiency,
      zoneImageFetched,
      zoneFeedback,
      zoneFeedbackFetched,
      msalInstance
  ]);

  // Mount
  useEffect(() => {
    const changeBuilding = (building, redirectTo, buildingTwinId) => {
      dispatch(changebuilding(building, redirectTo, buildingTwinId));
    };
    let id = query.get("id");
    let name = query.get("name");
    if (id && name) {
      fetchBuilding(
        { id: parseInt(id), ProjectName: name },
        false,
        changeBuilding,
        location.pathname
      );
    }
    // Scroll to top
    const top = document.getElementById("scroll-top");
    if (top) {
      top.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If no building is selected,try to get the bId from the twin, set that building as the selected building, or if a building is selected, but it's a different building than the one the twin belongs to
  useEffect(() => {
    const idFromLs = sessionStorage.getItem("BuildingId");
    if (
      (!buildingSelected && buildingId && +idFromLs !== buildingId) ||
      (buildingId && selectedBuildingId && selectedBuildingId !== buildingId)
    ) {
      setBuilding(buildingId);
    }
  }, [buildingSelected, selectedBuildingId, buildingId, setBuilding]);

  // REDIRECT FOR EXTERNAL USERS
  // Currently external users have access only to Zone twins
  useEffect(() => {
    if (isExternalUser && recordState && !twinForExternal(recordState)) {
      history.push("/dashboard");
    }
  }, [isExternalUser, recordState, history]);

  // Create a copy of the props and delete the unused ones
  const showProps = Object.assign({}, props);
  delete showProps.buildingTwinId;
  delete showProps.dispatch;
  delete showProps.buildingSelected;
  return (
    <>
      <PageTitle
        twinTitle={
          recordState ? (
            <TwinTitle
              type={recordState.BlockType}
              name={recordState.BlockName}
              description={recordState.BlockDescription}
            />
          ) : null
        }
        editPath={
          isAdminOrUser &&
          `/blocks/${recordState ? recordState.id : "unknowntwin"}`
        }
      />
      {buildingSelected && recordState && (
        <div className={classes.root}>
          <HpShow formData={recordState} />
          <AhuShow formData={recordState} />
          <ZoneShow
            formData={recordState}
            zoneImage={zoneImage}
            zoneFeedback={zoneFeedback}
          />
          <HxShow formData={recordState} />
          {!solarLoading && (
            <SolarShow
              formData={recordState}
              solarEfficiency={solarEfficiency}
            />
          )}
          <MeterShow formData={recordState} />
        </div>
      )}
    </>
  );
};

export default stateMapper(BlockShow);
