import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { sortBy } from "lodash";
import EmptyData from "../components/layout/EmptyData";
import CardWithTitle from "../components/layout/CardWithTitle";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import useBuildingTime from "../utils/hooks/useBuildingTime";

const useStyles = makeStyles({
  root: {
    height: 450,
    overflowY: "auto",
  },
  fbRoot: {
    display: "flex",
    alignItems: "center",
    height: "auto",
    width: "100%",
    boxSizing: "border-box",
    padding: "1rem 0.5rem 1rem 2.1875rem",
    color: "#282331",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
  },
  fbText: {
    display: "inline",
    fontSize: "1rem",
    fontWeight: 400,
  },
  countText: {
    marginLeft: 10,
    fontSize: "0.85rem",
  },
  dateContainer: {
    whiteSpace: "nowrap",
  },
  fbDate: {
    marginRight: 10,
  },
});

const ZoneFeedback = (props) => {
  const classes = useStyles();
  const toBuildingTime = useBuildingTime();

  if (!props.feedbacks || !props.feedbacks.length > 0 || props.nps === null) {
    if (props.hideContainer) {
      props.hideContainer("feedback");
    }
  } else if (props.showContainer) {
    props.showContainer("feedback");
  }

  const sortedFeedbacks =
    props.feedbacks && props.feedbacks.length > 0
      ? sortBy(props.feedbacks, (f) => new Date(f.feedbackDate)).reverse()
      : [];

  return (
    <CardWithTitle title="Zone feedback">
      <div className={classes.root}>
        {sortedFeedbacks && sortedFeedbacks.length > 0 ? (
          sortedFeedbacks.map((f, id) => {
            return (
              <Box key={id} className={classes.fbRoot}>
                {f.feedbackScore === "Good" && (
                  <SentimentSatisfiedAltIcon
                    style={{
                      color: "#1DA95F",
                      marginRight: 15,
                      fontSize: "2rem",
                    }}
                  />
                )}
                {f.feedbackScore === "Neutral" && (
                  <SentimentSatisfiedIcon
                    style={{
                      color: "#F9B64C",
                      marginRight: 15,
                      fontSize: "2rem",
                    }}
                  />
                )}
                {f.feedbackScore === "Bad" && (
                  <SentimentVeryDissatisfiedIcon
                    style={{
                      color: "#F26F65",
                      marginRight: 15,
                      fontSize: "2rem",
                    }}
                  />
                )}
                {f.feedbackScore === "None" && (
                  <NotInterestedIcon
                    style={{
                      opacity: "38%",
                      marginRight: 15,
                      fontSize: "2rem",
                    }}
                  />
                )}
                {toBuildingTime && (
                  <div className={classes.dateContainer}>
                    <Typography className={classes.fbDate}>
                      {toBuildingTime(f.feedbackDate).format(
                        "DD.MM.YYYY HH:mm"
                      )}
                    </Typography>
                  </div>
                )}
                <Typography className={classes.fbText}>
                  {f.feedbackText}
                </Typography>
              </Box>
            );
          })
        ) : (
          <EmptyData />
        )}
      </div>
    </CardWithTitle>
  );
};

export default ZoneFeedback;
