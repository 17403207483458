import { get, round, isNil } from "lodash";
import moment from "moment";
import { HpBlock, ValueStorage } from '../../../types/leasegreen-core-api-exports';

const hpVariableMappings = [
  // Source temperatures
  ["SourceSupplyTE.BusTagName", "sourceSupply"],
  ["SourceReturnTE.BusTagName", "sourceReturn"],
  ["SourceReturnTE.BusTagName", "sourceReturn"],
  // Heating tank data
  ["HeatSupplyTE.BusTagName", "heatingSupply"],
  ["HeatReturnTE.BusTagName", "heatingReturn"],
  ["HeatingNetworkSupplyTE.BusTagName", "heatingNetworkSupply"],
  ["HeatingNetworkReturnTE.BusTagName", "heatingNetworkReturn"],
  ["HeatingTankTE.BusTagName", "heatingTank"],
  // Warm water tank data
  ["WarmWaterSupplyTE.BusTagName", "warmWaterSupply"],
  ["WarmWaterReturnTE.BusTagName", "warmWaterReturn"],
  ["WarmWaterNetworkSupplyTE.BusTagName", "warmWaterNetworkSupply"],
  ["WarmWaterNetworkReturnTE.BusTagName", "warmWaterNetworkReturn"],
  ["WarmWaterTankTE.BusTagName", "warmWaterTank"],
];

const getHpTwinEnergyPointMappings = (twinName: string) => [
  // Energypoints
  [`${twinName}_TOTAL_CONSUMED_ENERGY`, "inputEnergy"],
  [`${twinName}_TOTAL_IMPORT_ENERGY`, "importEnergy"],
  [`${twinName}_TOTAL_HEATING_ENERGY`, "heatingEnergy"],
  [`${twinName}_TOTAL_HEATING_BOILER_RESISTOR_ENERGY`, "heatingResistor"],
  [`${twinName}_TOTAL_WATER_ENERGY`, "waterEnergy"],
  [`${twinName}_TOTAL_WATER_BOILER_RESISTOR_ENERGY`, "waterResistor"],
  [`${twinName}_TOTAL_REGENERATION_ENERGY`, "regenerationEnergy"],
  [`${twinName}_TOTAL_COOLING_ENERGY`, "coolingEnergy"],
  // COP
  [`${twinName}_COP`, "cop"],
  [`${twinName}_SYSTEM_COP`, "systemCop"],
];

/**
 * Takes HP twin and list of valuestorage data and returns latest data for each variable configured for HP.
 * This should be generalized to work with any twin type (AHU, HP, HX, etc.)
 */
export const ResolveHpData = (hp: HpBlock, rawData: ValueStorage[], tz: string, twinName: string): { value: number; name: string; time: string }[] | null => {
  if (!hp || !rawData || !rawData.length) {
    return null;
  }

  const findAndGet = (variable: string, name: string, energyPoint = false) => {
    const d = rawData.find((v) => {
      const normalizedVariableName =
        v && typeof v.VariableName === "string"
          ? v.VariableName.toUpperCase()
          : "";
      if (energyPoint) {
        return normalizedVariableName === variable;
      } else {
        const normalizedPropertyKey = get(hp, variable, null)?.toUpperCase();
        return normalizedVariableName === normalizedPropertyKey;
      }
    });
    if (d && d.UpdateTime && !isNil(d.AggregatedValue)) {
      const utc = moment.utc(new Date(d.UpdateTime));
      const time = moment.tz(utc, tz).format("DD.MM.YYYY HH:mm");
      return { value: round(d.AggregatedValue, 2), name, time };
    }
  };

  /**
   * Some data is resolved by BusTagName found from the Twin and some data by forming an id from twinId and wanted variable
   */
  const dataByVariables = hpVariableMappings.map(([objectPath, variable]) => findAndGet(objectPath, variable));
  const dataByTwinName = getHpTwinEnergyPointMappings(twinName).map(([objectPath, variable]) => findAndGet(objectPath, variable, true))

  return [...dataByVariables, ...dataByTwinName].filter(v => !isNil(v));
};

export const ResolveHpPumpData = (hp: HpBlock, rawData: ValueStorage[], tz: string): (ValueStorage & { pumpNumber: number })[] => {
  return (hp?.Pumps || [])
    .map(p => {
      const pumpNumber = p?.PumpNumber;
      const alert = rawData.find(v => v.VariableName === p?.AlertStatus?.BusTagName);
      const running = rawData.find(v => v.VariableName === p?.RunningStatus?.BusTagName);
      if (
        pumpNumber &&
        ((alert && !isNil(alert?.AggregatedValue) && !isNil(alert?.UpdateTime)) ||
          (running && !isNil(running?.AggregatedValue) && !isNil(running?.UpdateTime)))
      ) {
        const alertUTC = moment.utc(new Date(alert?.UpdateTime));
        const alertTime = moment.tz(alertUTC, tz).format("DD.MM.YYYY HH:mm");

        const runningUTC = moment.utc(new Date(running?.UpdateTime));
        const runningTime = moment
          .tz(runningUTC, tz)
          .format("DD.MM.YYYY HH:mm");

        return {
          ...(alert &&
            alertTime && { alert: { value: alert?.AggregatedValue, time: alertTime } }),
          ...(running &&
            runningTime && {
            running: { value: running?.AggregatedValue, time: runningTime },
          }),
          pumpNumber,
        };
      } else {
        return null;
      }
    }).filter(v => !isNil(v));
}
