import React, { useState, useCallback, useMemo } from "react";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import HpTrendsGraph from "./HpTrendsGraph";
import CardWithTitle from "../../components/layout/CardWithTitle";
import EmptyData from "../../components/layout/EmptyData";
import {
  electricityColors,
  electricityLineColors,
} from "../../utils/graphColor";

const HpConsumptionGraph = ({
  showContainer,
  hideContainer,
  Trends,
  BuildingId,
  Hp,
}) => {
  const [hiddenGraphs, setHiddenGraphs] = useState(["month", "day"]);
  const [resolution, setResolution] = useState("month");
  const [trendValues] = useState([
    "TOTAL_CONSUMED_ENERGY",
    "TOTAL_HEATING_BOILER_RESISTOR_ENERGY",
    "TOTAL_WATER_BOILER_RESISTOR_ENERGY",
  ]);

  const { MonthlyTargets } = Hp;

  const monthlyTargets = useMemo(
    () =>
      MonthlyTargets.map((m) => ({
        month: m.Month,
        value: +m.ConsumedTarget + +m.AdditionalTarget,
      })),
    [MonthlyTargets]
  );

  const handleGraphHide = useCallback((graph) => {
    setHiddenGraphs((prevHidden) => {
      if (!prevHidden.includes(graph)) {
        return [...prevHidden, graph];
      }
      return prevHidden;
    });
  }, []);

  const handleGraphShow = useCallback((graph) => {
    setHiddenGraphs((prevHidden) => {
      if (prevHidden.includes(graph)) {
        return prevHidden.filter((g) => g !== graph);
      }
      return prevHidden;
    });
  }, []);

  const handleResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  const ResolutionInput = (
    <div
      key={"res"}
      style={{
        marginLeft: 15,
        width: 80,
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <InputLabel>Resolution</InputLabel>
      <Select value={resolution} onChange={handleResolutionChange}>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
      </Select>
    </div>
  );

  if (showContainer && hideContainer) {
    if (hiddenGraphs.length < 2) {
      showContainer("consumption");
    } else {
      hideContainer("consumption");
    }
  }

  return (
    <CardWithTitle title="Consumption">
      <div style={{ display: hiddenGraphs.length === 2 ? "none" : undefined }}>
        {ResolutionInput}
        <div style={{ display: resolution !== "day" ? "none" : undefined }}>
          <HpTrendsGraph
            Trends={Trends || []}
            trendId="ENERGY_DAILY"
            trendValues={trendValues}
            name="day"
            resolution="day"
            unit="KWh"
            buildingId={BuildingId || null}
            naviMetaId={trendValues[0]}
            graphType="column"
            stack
            graphColors={electricityColors}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
        </div>
        <div style={{ display: resolution !== "month" ? "none" : undefined }}>
          <HpTrendsGraph
            Trends={Trends || []}
            trendId="ENERGY_MONTHLY"
            trendValues={trendValues}
            name="month"
            resolution="month"
            targets={monthlyTargets}
            unit="KWh"
            buildingId={BuildingId || null}
            naviMetaId={trendValues[0]}
            graphType="column"
            stack
            graphColors={electricityColors}
            targetColor={electricityLineColors[0]}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
        </div>
      </div>
      <div
        style={{
          height: "20rem",
          display: hiddenGraphs.length < 2 ? "none" : undefined,
        }}
      >
        <EmptyData />
      </div>
    </CardWithTitle>
  );
};

export default HpConsumptionGraph;
