import React, { useEffect } from "react";
import {
  ArrayInput,
  NullableBooleanInput,
  NumberInput,
  SimpleFormIterator,
} from "react-admin";
import { some, get } from "lodash";
import { useForm } from "react-final-form";

function isAnyHpInputSelected(formData, inputIds) {
  var inputs = inputIds;
  if (inputIds && !Array.isArray(inputIds)) {
    inputs = [inputIds];
  }

  if (inputs) {
    var hasAnyTagsSelected = some(inputs, (i) => {
      const input = get(formData.Hp, i, undefined);
      if (input) {
        if (Array.isArray(input) && input.length > 0) {
          var hasTagValues = some(input, (tag) => {
            if (tag && tag.BusTagName) {
              return true;
            }
            return false;
          });
          return hasTagValues;
        } else {
          const busTagName = get(input, i + ".BusTagName", undefined);
          if (busTagName) {
            return true;
          }
        }
      }
      return false;
    });

    return hasAnyTagsSelected;
  }
  return false;
}

function DefaultCopCoordinates(formData) {
  const defaults = [
    { Input: 20, Output: 3 },
    { Input: 0, Output: 2 },
    { Input: -15, Output: 1.3 },
  ];
  return defaults;
}

const CustomArrayInput = () => {
  const form = useForm();
  const formState = form.getState();
  const hpType = get(formState, "values.Hp.HeatSource", null);
  const currentCoordinates = get(
    formState,
    "values.HpOperations.CalculateEnergyCop.OperationAlert.Coordinates",
    null
  );
  const { change } = form;

  useEffect(() => {
    // When HpType changes, set the default values, if values do not exist
    if (hpType === "AIR" && !currentCoordinates) {
      change(
        "HpOperations.CalculateEnergyCop.OperationAlert.Coordinates",
        DefaultCopCoordinates()
      );
    }
  }, [hpType, change, currentCoordinates]);

  return (
    <ArrayInput source="HpOperations.CalculateEnergyCop.OperationAlert.Coordinates">
      <SimpleFormIterator>
        <NumberInput
          label="Outdoor TE"
          source="Input"
          style={{ display: "inline", float: "left" }}
        />
        <NumberInput
          label="COP limit"
          source="Output"
          style={{ display: "inline", float: "left", marginLeft: "20px" }}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const CalculateCop = (props) => {
  const form = useForm();
  const formState = form.getState();
  const currentValue = get(
    formState,
    "values.HpOperations.CalculateEnergyCop.Active",
    null
  );
  const { change } = form;

  const defaultVal =
    isAnyHpInputSelected(props.formData, "InputEnergyPoints") &&
    isAnyHpInputSelected(props.formData, [
      "OutputEnergyPoints",
      "HeatingEnergyPoints",
      "CoolingEnergyPoints",
      "WaterEnergyPoints",
    ]);

  const disabled =
    !isAnyHpInputSelected(props.formData, "InputEnergyPoints") &&
    !isAnyHpInputSelected(props.formData, [
      "OutputEnergyPoints",
      "HeatingEnergyPoints",
      "CoolingEnergyPoints",
      "WaterEnergyPoints",
    ]);

  useEffect(() => {
    // When Input or output energypoints change, set the default values, if values is different
    if (currentValue !== defaultVal) {
      change("HpOperations.CalculateEnergyCop.Active", defaultVal);
    }
  }, [currentValue, change, defaultVal]);

  return (
    <NullableBooleanInput
      label="Calculate COP"
      source="HpOperations.CalculateEnergyCop.Active"
      disabled={disabled}
      style={{ width: "300px" }}
    />
  );
};

const IncludeCop = (props) => {
  const form = useForm();
  const formState = form.getState();
  const currentValue = get(
    formState,
    "values.HpOperations.IncludeCopInTotalBuildingCopCalculation.Active",
    null
  );
  const { change } = form;

  const defaultVal =
    isAnyHpInputSelected(props.formData, ["InputEnergyPoints"]) &&
    isAnyHpInputSelected(props.formData, [
      "OutputEnergyPoints",
      "HeatingEnergyPoints",
      "CoolingEnergyPoints",
      "WaterEnergyPoints",
    ]);

  useEffect(() => {
    // When Input energypoints change, set the default values, if values is different
    if (currentValue !== defaultVal) {
      change(
        "HpOperations.IncludeCopInTotalBuildingCopCalculation.Active",
        defaultVal
      );
    }
  }, [currentValue, change, defaultVal]);

  return (
    <NullableBooleanInput
      label="Include COP in total building COP"
      source="HpOperations.IncludeCopInTotalBuildingCopCalculation.Active"
      defaultValue={isAnyHpInputSelected(props.formData, "InputEnergyPoints")}
      disabled={!isAnyHpInputSelected(props.formData, "InputEnergyPoints")}
      style={{ width: "300px" }}
    />
  );
};

export const HpOperations = (formData) => (
  <div style={{ display: "grid" }}>
    <p>Calculate COP and system COP</p>
    <CalculateCop formData={formData} />
    {formData.Hp && formData.Hp.HeatSource === "AIR" ? (
      <CustomArrayInput formData={formData} />
    ) : (
      <p>Cop alert low limit 2.0</p>
    )}
    <IncludeCop formData={formData} />
  </div>
);
