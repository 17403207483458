// Get the color for the series

export const defaultColors = [
  "#2E2247",
  "#1D3E70",
  "#005D93",
  "#007DA9",
  "#009DAE",
  "#1AD689",
];

export const defaultLineColors = ["#1AD689", "#1D3E70", "#009DAE", "#2E2247"];

export const heatingColors = [
  "#FFA11F",
  "#D7801D",
  "#B16019",
  "#8C4215",
  "#692410",
  "#470404",
];

export const heatingLineColors = ["#470404", "#D7801D", "#8C4215", "#FFA11F"];

export const waterColors = [
  "#1A67D6",
  "#3C84CE",
  "#47A2C4",
  "#48C1BA",
  "#3BE0AE",
  "#00FFA1",
];

export const waterLineColors = ["#00FFA1", "#3C84CE", "#48C1BA", "#1A67D6"];

export const electricityColors = [
  "#00BA6B",
  "#239566",
  "#297160",
  "#265059",
  "#1A2F51",
  "#001149",
];

export const electricityLineColors = [
  "#001149",
  "#239566",
  "#265059",
  "#00BA6B",
];

export const coolingColors = [
  "#1AC5D6",
  "#439DAC",
  "#4D7684",
  "#4C515E",
  "#432D3B",
  "#35071B",
];

export const coolingLineColors = ["#35071B", "#439DAC", "#4C515E", "#1AC5D6"];

const graphColor = ({
  index,
  seriesLength,
  lineSeries = false,
  offset = 0,
  type = "",
}) => {
  // Use different color scheme based on selection
  let lineColors = defaultLineColors;
  let colors = defaultColors;
  switch (type.toLowerCase()) {
    case "heating":
      lineColors = heatingLineColors;
      colors = heatingColors;
      break;

    case "water":
      lineColors = waterLineColors;
      colors = waterColors;
      break;

    case "electricity":
      lineColors = electricityLineColors;
      colors = electricityColors;
      break;

    case "cooling":
      lineColors = coolingLineColors;
      colors = coolingColors;
      break;

    default:
      lineColors = defaultLineColors;
      colors = defaultColors;
      break;
  }

  const colorArrLastIndex = lineSeries
    ? lineColors.length - 1
    : colors.length - 1;

  // Newest year gets the first color
  let colorIndex = undefined;
  let seriesIndex = seriesLength - 1 - index; // Last series is index 0
  seriesIndex = seriesIndex + offset;
  let factor = 0;
  if (seriesIndex > colorArrLastIndex) {
    factor = Math.floor(seriesIndex / colorArrLastIndex);
    colorIndex = seriesIndex - factor * colorArrLastIndex - 1;
  } else {
    colorIndex = seriesIndex;
  }

  if (lineSeries) {
    return lineColors[colorIndex];
  } else {
    return colors[colorIndex];
  }
};
export default graphColor;
