import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import { map, cloneDeep, last, find } from "lodash";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import Boost from "highcharts/modules/boost";
import {
  createFeedbackSeries,
  updateFeedbackIcons,
  createOperationSeries,
  updateOperationIcons,
  tooltipFormatter,
} from "../utils/raDataToHighcharts";
Boost(Highcharts);

const resolveSMin = (block) => {
  if (block != null && block.Zone) {
    const indoorClimateClass = block.Zone.IndoorClimateClass;
    switch (indoorClimateClass) {
      case 1:
        return 20;
      case 2:
        return 20;
      case 3:
        return 18;
      default:
        return 0;
    }
  }
  return 0;
};

const boostLimit = 5000;

export class ZoneBlockTemperatureMeasurements extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);

    this.state = {
      record: this.props,
      optionsDay: this.graphOptions(),
      optionsMonth: this.graphOptions(true),
      resolution: "day",
      fetchDone: false,
    };
  }

  graphOptions(monthlyData = false) {
    return {
      chart: {
        height: "800px",
        //renderTo: "container",
        alignTicks: true,
        zoomType: "x",
        events: {
          load: function () {
            let chart = this;
            // Update point icons
            updateFeedbackIcons(chart);
            updateOperationIcons(chart);
          },
        },
      },
      legend: {
        enabled: true,
      },
      time: {
        timezone: this.props.timezone,
      },
      rangeSelector: {
        inputEnabled: false,
        // selected: monthlyData ? 2 : 1,
        allButtonsEnabled: true,
        buttonTheme: {
          width: "auto",
        },
        buttons: monthlyData
          ? [
              {
                type: "month",
                count: 3,
                text: "3m",
              },
              {
                type: "month",
                count: 12,
                text: "12m",
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ]
          : [
              {
                type: "day",
                count: 1,
                text: "day",
              },
              {
                type: "month",
                count: 1,
                text: "month",
              },
              {
                type: "all",
                text: "All",
              },
            ],
      },
      tooltip: {
        valueDecimals: 2,
        formatter: function (tooltip) {
          return tooltipFormatter(this, Highcharts, tooltip);
        },
      },
      title: {
        text: "",
      },
      yAxis: [
        {
          title: {
            text: "Temperature",
          },
          height: "60%",
          lineWidth: 2,
        },
        {
          title: {
            text: "Outdoor temperature",
          },
          top: "80%",
          height: "20%",
          offset: 0,
          lineWidth: 2,
        },
        {
          title: {
            text: "Events",
          },
          top: "65%",
          height: "10%",
          offset: 0,
          lineWidth: 2,
          min: 0,
          max: 1.5,
          labels: {
            enabled: false,
          },
        },
      ],
      scrollbar: {
        liveRedraw: false,
        adaptToUpdatedData: false,
      },
      navigator: {
        adaptToUpdatedData: monthlyData,
        enabled: true,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
      xAxis: {
        events: monthlyData
          ? undefined
          : {
              afterSetExtremes: this.afterSetExtremes.bind(this),
            },
        minRange: monthlyData ? 1 : 24 * 3600 * 1000, // one day,
        type: "datetime",
      },
      series: [],
    };
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
  }

  componentDidMount() {
    if (this.internalChart) {
      this.internalChart.showLoading("Loading data from server...");

      const getMeasurements = restClient(GET_LIST, "zonecondition", {
        filter: {
          blockId: this.state.record.id,
          dataBegin: null,
          dataEnd: null,
          conditionType: "TEMP",
        },
        sort: { field: "measured", order: "ASC" },
        pagination: {},
      });

      const getOutTemp = restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          buildingId: this.state.record.BuildingId,
          pointIds: [this.state.record.BuildingId],
          pointSource: "building_temp",
          dataBegin: null,
          dataEnd: null,
        },
        sort: { field: "DateTime", order: "ASC" },
        pagination: {},
      });

      Promise.all([getMeasurements, getOutTemp]).then(
        ([responseMeas, responseTemp]) => {
          if (
            responseMeas &&
            responseMeas.data &&
            responseMeas.data.length > 0
          ) {
            // Callback to pass the latest value to parent
            this.props.latestTemp(last(responseMeas.data));
          }
          const measSeriesDay = this.constructSeries(responseMeas.data);
          const tempSerieDay = this.dataToOutdoorSeries(
            responseTemp.data.values,
            1,
            measSeriesDay
          );
          const allSeriesDay = measSeriesDay.concat(tempSerieDay);

          const measSeriesMonth = this.constructSeries(
            responseMeas.data,
            0,
            true
          );
          const tempSerieMonth = this.dataToOutdoorSeries(
            responseTemp.data.values,
            1,
            measSeriesMonth,
            true
          );
          const allSeriesMonth = measSeriesMonth.concat(tempSerieMonth);

          this.setState((prevState) => ({
            optionsDay: {
              ...prevState.optionsDay,
              series: cloneDeep(allSeriesDay),
            },
            fetchDone: true,
          }));
          this.setState((prevState) => ({
            optionsMonth: {
              ...prevState.optionsMonth,
              series: cloneDeep(allSeriesMonth),
            },
          }));
          this.internalChart.hideLoading();
        }
      );
    }
  }

  afterSetExtremes(e) {
    // Check for e.trigger to only fetch new data after user action
    if (
      e.trigger &&
      this.state.resolution === "day" &&
      !isNaN(e.min) &&
      !isNaN(e.max)
    ) {
      const chart = this.internalChart;
      const startISO = new Date(e.min).toISOString();
      const endISO = new Date(e.max).toISOString();

      chart.showLoading("Loading data from server...");

      const getMeasurements = restClient(GET_LIST, "zonecondition", {
        filter: {
          blockId: this.state.record.id,
          dataBegin: startISO,
          dataEnd: endISO,
          conditionType: "TEMP",
        },
        sort: { field: "UpdateTime", order: "ASC" },
        pagination: {},
      });

      const getOutTemp = restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          buildingId: this.state.record.BuildingId,
          pointIds: [this.state.record.BuildingId],
          pointSource: "building_temp",
          dataBegin: startISO,
          dataEnd: endISO,
        },
        sort: { field: "DateTime", order: "ASC" },
        pagination: {},
      });

      Promise.all([getMeasurements, getOutTemp]).then(
        ([responseMeas, responseTemp]) => {
          const measSeries = this.constructSeries(responseMeas.data);
          const tempSerie = this.dataToOutdoorSeries(
            responseTemp.data.values,
            1,
            measSeries
          );

          let allSeries = measSeries.concat(tempSerie);
          // also update the feedback series and the operation logs
          let feedbackSeries = null;
          let operationSeries = null;
          if (this.props.feedbackSeries) {
            let fbData = createFeedbackSeries({
              feedbackSeries: this.props.feedbackSeries,
              chart: this.internalChart,
              allSeries: allSeries,
              referenceSeriesName: "Temperature avg",
            });
            if (fbData && fbData.length > 0) {
              feedbackSeries = { ...this.props.feedbackSeries, data: fbData };
            }
          }
          if (this.props.operationSeries) {
            let opData = createOperationSeries({
              operationSeries: this.props.operationSeries,
              chart: this.internalChart,
              allSeries: allSeries,
              referenceSeriesName: "Temperature avg",
            });
            if (opData && opData.length > 0) {
              operationSeries = { ...this.props.operationSeries, data: opData };
            }
          }

          // Series data is updated, instead of setting the state again to avoid using some not-wanted initial settins, such as min/max on xAxis and rangeselector selections
          if (feedbackSeries) {
            allSeries = allSeries.concat(feedbackSeries);
          }
          if (operationSeries) {
            allSeries = allSeries.concat(operationSeries);
          }
          try {
            this.internalChart.update({ series: allSeries }, true, true, false);
            updateFeedbackIcons(this.internalChart);
            updateOperationIcons(this.internalChart);
          } catch (error) {}
          if (!this.state.fetchDone) {
            this.setState({ fetchDone: true });
          }
          this.internalChart.hideLoading();
        }
      );
    }
  }

  dataToOutdoorSeries = (data, y, measSeries, monthlyData = false) => {
    const series = [];
    let sData = [];

    // Only insert values that have a corresponding measurement
    // sData = map(data, (d) => [new Date(d.valueTime).getTime(), d.value]);
    if (measSeries && measSeries.length > 0) {
      const lastData = measSeries[0].data.length - 1;
      const firstMeas = measSeries[0].data[0][0];
      const lastMeas = measSeries[0].data[lastData][0];
      data.forEach((d) => {
        const date = new Date(d.valueTime).getTime();
        if (date >= firstMeas && date <= lastMeas) {
          sData.push([date, d.value]);
        }
      });
      if (sData && sData.length > 0) {
        series.push({
          yAxis: y,
          type: "line",
          name: "Outdoor temperature",
          data: sData,
          dataGrouping: {
            enabled: monthlyData,
            forced: monthlyData,
            units: [["month", [1]]],
            approximation: "average",
          },
          boostThreshold: monthlyData ? 0 : boostLimit,
        });
      }
    }

    return series;
  };

  constructSeries = (data, y, monthlyData = false) => {
    const series = [];

    const indoorClimateClass = this.state.record.Zone.IndoorClimateClass;
    let sData = null;
    sData = map(data, (d) => [
      d.measured * 1000,
      indoorClimateClass !== 0
        ? d.temps[0]
        : this.state.record.Zone.ZoneOperativeMaxTemp,
    ]);

    if (sData && sData.length > 0) {
      series.push({
        type: "line",
        name: "S" + indoorClimateClass + " Max",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "high",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    const sMin =
      indoorClimateClass !== 0
        ? resolveSMin(this.state.record)
        : this.state.record.Zone.ZoneOperativeMinTemp;
    sData = map(data, (d) => [d.measured * 1000, sMin]);

    if (sData && sData.length > 0) {
      series.push({
        type: "line",
        name: "S" + indoorClimateClass + " Min",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "low",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    sData = map(data, (d) => [d.measured * 1000, d.temps[1]]);
    if (sData && sData.length > 0) {
      series.unshift({
        type: "line",
        name: "Temperature avg",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "average",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    sData = map(data, (d) => [d.measured * 1000, d.temps[2]]);
    if (sData && sData.length > 0) {
      series.push({
        type: "line",
        name: "Temperature min",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "low",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    sData = map(data, (d) => [d.measured * 1000, d.temps[3]]);
    if (sData && sData.length > 0) {
      series.push({
        type: "line",
        name: "Temperature max",
        data: sData,
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "high",
        },
        boostThreshold: monthlyData ? 0 : boostLimit,
        turboThreshold: monthlyData ? 0 : boostLimit,
      });
    }

    return series;
  };

  render() {
    let options = null;
    if (this.state.resolution === "day") {
      options = cloneDeep(this.state.optionsDay);
      // Try to find the feedback series, only add if not found
      let fbSer = find(options.series, (s) => s.name === "Feedbacks");
      if (
        !fbSer &&
        this.props.feedbackSeries &&
        Array.isArray(options.series)
      ) {
        let fbData = createFeedbackSeries({
          feedbackSeries: this.props.feedbackSeries,
          chart: this.internalChart,
          allSeries: options.series,
          referenceSeriesName: "Temperature avg",
        });
        if (fbData && fbData.length > 0) {
          options.series.push({ ...this.props.feedbackSeries, data: fbData });
        }
      }
      // Try to find the operation series, only add if not found
      let opSer = find(options.series, (s) => s.name === "Operations");
      if (
        !opSer &&
        this.props.operationSeries &&
        Array.isArray(options.series)
      ) {
        let opData = createOperationSeries({
          operationSeries: this.props.operationSeries,
          chart: this.internalChart,
          allSeries: options.series,
          referenceSeriesName: "Temperature avg",
        });
        if (opData && opData.length > 0) {
          options.series.push({ ...this.props.operationSeries, data: opData });
        }
      }
    } else if (this.state.resolution === "month") {
      options = cloneDeep(this.state.optionsMonth);
    }

    const handleResolutionChange = (e) => {
      this.setState({ resolution: e.target.value });
    };

    const ResolutionInput = (
      <div
        key={"res"}
        style={{
          marginLeft: 15,
          width: 80,
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        <InputLabel>Resolution</InputLabel>
        <Select value={this.state.resolution} onChange={handleResolutionChange}>
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"month"}>Month</MenuItem>
        </Select>
      </div>
    );

    let showGraph = false;
    if (this.state.record.Zone.Temperatures.length > 0) {
      showGraph = true;
    }

    if (
      !this.state.record.Zone.Temperatures.length > 0 ||
      (this.state.fetchDone &&
        (!options || !options.series || !options.series.length > 0))
    ) {
      showGraph = false;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.fetchDone
    ) {
      if (showGraph) {
        this.props.showContainer("temperature");
      } else {
        this.props.hideContainer("temperature");
      }
    }

    return (
      <CardWithTitle title={"Temperature"}>
        {this.state.record.Zone.Temperatures.length > 0 ? (
          [
            ResolutionInput,
            <HighchartsReact
              key={"chrt"}
              immutable={true}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={cloneDeep(options)}
              callback={this.afterChartCreated}
            />,
          ]
        ) : (
          <div style={{ height: "20rem" }}>
            <EmptyData />
          </div>
        )}
      </CardWithTitle>
    );
  }
}
