import React, { useEffect, useState } from "react";
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SingleFieldList,
  SimpleShowLayout,
  TextInput,
  UrlField,
  Pagination,
  useNotify,
} from "react-admin";
import {
  makeStyles,
  Paper,
  CardContent,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@material-ui/core";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { mapper } from "../customReducers/connectFunctions";
import { useExpanded } from "ra-core";
import useQuery from "../utils/hooks/useQuery";
import OperationLogPinnedField from "./OperationLogPinnedField";
import useUserRole from "../utils/hooks/useUserRole";
import PageTitle from "../components/layout/PageTitle";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import Spinner from "../components/layout/Spinner";

const useStyles = makeStyles((theme) => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
  removeButton: {},
  removeIcon: {
    color: theme.palette.error.main,
  },
  preWrap: {
    whiteSpace: "pre-wrap",
  },
}));

const OperationsFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput
        source="q"
        label="All fields free text"
        className={classes.helperText}
      />
      <ReferenceInput
        label="Subcategory"
        source="OperationMeta"
        reference="operationmeta"
        filter={{ Parent: false }}
        sort={{ field: "id", order: "ASC" }}
        className={classes.helperText}
      >
        <SelectInput optionText="Name" allowEmpty />
      </ReferenceInput>
      <SelectInput
        source="Effect"
        choices={[
          {
            id: "1",
            name: "1. Energy consumption",
          },
          { id: "2", name: "2. Conditions" },
          { id: "3", name: "3. Energy consumption and conditions" },
        ]}
        className={classes.helperText}
      />
      <SelectInput
        source="State"
        choices={[
          { id: "1. Active", name: "1. Active" },
          { id: "2. Delayed", name: "2. Delayed" },
          { id: "3. Completed", name: "3. Completed" },
        ]}
        className={classes.helperText}
      />
    </Filter>
  );
};

export const OperationsExpand = (props) => {
  const classes = useStyles();
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachments, setAttachments] = useState();
  const { isExternalUser, isAdminOrUser } = useUserRole();
  const customFetch = useCustomFetch();
  const notify = useNotify();
  const { id } = props;

  // On mount, fetch the attachments
  useEffect(() => {
    const getAttachments = async () => {
      setAttachmentsLoading(true);
      try {
        const res = await customFetch({ url: `operationlog/${id}/files` });
        const resData = await res.json();
        if (resData && resData.length > 0) {
          const blobPromises = resData.map(async (r) => {
            const attachRes = await customFetch({
              url: `operationlog/${id}/files/${r.fileName}`,
            });
            const blob = await attachRes.blob();
            const url = URL.createObjectURL(blob);
            const name = r.fileName;
            return { url, name };
          });
          const finalResponse = await Promise.all(blobPromises);
          setAttachments(finalResponse);
        }
      } catch (error) {
        notify("Error retrieving log attachments", "warning");
        setAttachmentsLoading(false);
      } finally {
        setAttachmentsLoading(false);
      }
    };

    getAttachments();
  }, [notify, customFetch, id]);

  const handleAttachmentRemove = (fileName) => {
    customFetch({
      url: `operationlog/${id}/files/${fileName}`,
      method: "DELETE",
    })
      .then(() => {
        setAttachments((curAttachments) =>
          curAttachments.filter((a) => a.name !== fileName)
        );
      })
      .catch(() => notify("Error deleting attachment", "warning"));
  };

  // Remove unused props to get rid of errors
  let divProps = Object.assign({}, props);
  delete divProps.basePath;
  return (
    <Grid container spacing={3} {...divProps}>
      <Grid item xs={4}>
        <Show {...props} title=" ">
          <SimpleShowLayout>
            {!isExternalUser && (
              <ReferenceField
                source="BuildingId"
                reference="buildings"
                label="Link to sharepoint"
                filter={{ showBAUI: true }}
                link={false}
              >
                <UrlField
                  source="ProjectWorkspaceURL"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </ReferenceField>
            )}
            {!isExternalUser && (
              <FunctionField
                render={(record) => {
                  return record.ProjectCharterLink ? (
                    <a
                      href={record.ProjectCharterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open Project Charter
                    </a>
                  ) : (
                    <p>No Project Charter link provided</p>
                  );
                }}
                label="Project Charter"
              />
            )}
            {!isExternalUser && (
              <TextField source="meta.Parent_name" label="Category" />
            )}
            {!isExternalUser && (
              <ReferenceArrayField
                label="Digital twins"
                source="OperationBlocks"
                reference="blocks"
              >
                <SingleFieldList>
                  <ChipField source="BlockName" />
                </SingleFieldList>
              </ReferenceArrayField>
            )}
            {!isExternalUser && (
              <TextField
                className={classes.preWrap}
                source="InternalComment"
                component="pre"
              />
            )}
            {!isExternalUser && (
              <BooleanField
                source="VisibleToExternal"
                label="Visible to customer"
              />
            )}
            {!isExternalUser && <TextField source="State" />}
            <TextField
              className={classes.preWrap}
              source="ExternalComment"
              label={!isExternalUser ? "Comments to customer" : "Comments"}
              component="pre"
            />
          </SimpleShowLayout>
        </Show>
      </Grid>

      {isAdminOrUser && (
        <Grid item xs={4}>
          <Paper style={{ marginTop: "1em" }}>
            <CardContent>
              <Typography variant="body1">Attachments</Typography>
              {attachmentsLoading ? (
                <Spinner style={{ height: 80 }} />
              ) : attachments && attachments.length > 0 ? (
                attachments.map((a) => {
                  return (
                    <div key={a.url}>
                      <IconButton
                        className={classes.removeButton}
                        onClick={() => handleAttachmentRemove(a.name)}
                        aria-label="Remove"
                        title="Remove"
                      >
                        <RemoveCircle className={classes.removeIcon} />
                      </IconButton>
                      <Link href={a.url} download={a.name}>
                        {a.name}
                      </Link>
                    </div>
                  );
                })
              ) : (
                <Typography variant="body2">No attachments</Typography>
              )}
            </CardContent>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 1000]} {...props} />
);

const OperationsList = ({ BuildingId, ...props }) => {
  const { isExternalUser: isExternal, isAdminOrUser } = useUserRole();
  const [queryRowId, setQueryRowId] = useState(null);
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.showNotification;
  delete listProps.setBuildings;
  delete listProps.changebuilding;

  const isPermissions = isAdminOrUser;

  // Find the selected element to scroll it into view
  useEffect(() => {
    if (queryRowId) {
      const qstr = `[aria-controls="${queryRowId}-expand"]`;
      const el = document.querySelector(qstr);
      if (el) {
        // Some offset is needed for the header
        const contentWindow = document.getElementById("main-content");
        const offset = 90;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = el.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        contentWindow.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [queryRowId]);

  const ExpandHandler = (props) => {
    const [expanded, toggleExpanded] = useExpanded(
      "operationlog",
      props.record.id
    );
    const id = useQuery().get("id");
    // If the row has the same id as the query params, open the row, otherwise close it
    useEffect(() => {
      if (id && +id === props.record.id && !expanded) {
        toggleExpanded();
        setQueryRowId(props.record.id);
      } else if (id && +id !== props.record.id && expanded) {
        toggleExpanded();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return null;
  };

  return (
    <>
      <PageTitle title="Operations" />
      <List
        {...listProps}
        perPage={1000}
        pagination={<ValuesPagination />}
        bulkActionButtons={false}
        filters={!isExternal ? <OperationsFilter /> : null}
        filter={{ BuildingId }}
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid expand={<OperationsExpand />}>
          <ExpandHandler />
          {!isExternal && (
            <ReferenceField
              label="Project name"
              source="BuildingId"
              reference="buildings"
              filter={{ showBAUI: true }}
              link={() => "/dashboard"}
            >
              <TextField source="ProjectName" />
            </ReferenceField>
          )}
          {!isExternal && <TextField source="meta.Name" label="Subcategory" />}
          <TextField source="Title" />
          <TextField source="State" />
          <DateField source="Date" label="Date created" locales="fi-FI" />
          {!isExternal && <TextField source="CreatedBy" />}
          {!isExternal && <DateField source="Deadline" locales="fi-FI" />}
          {!isExternal && (
            <OperationLogPinnedField
              label="Pinned"
              isPermissions={isPermissions}
            />
          )}

          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

export default mapper(OperationsList);
