import { useState, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch, useSelector } from "react-redux";

import { setBlockFeedbackCount } from "../customReducers/actions";

function useBlockFeedbacks(id = null) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [viewVersion, setViewVersion] = useState(null);
  const curViewVersion = useSelector((state) => state.admin.ui.viewVersion);

  // Fetch new data when refresh button clicked/new data is put)
  useEffect(() => {
    if (viewVersion !== curViewVersion && curViewVersion !== 1) {
      setViewVersion(curViewVersion);
      dataProvider
        .getList("blockfeedbacks", {
          filter: { BuildingId: id },
          sort: { field: "id", order: "ASC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            if (data && data.length > 0) {
              const feedbacks = [];
              data.forEach((d) => {
                if (d && !d.checked) {
                  feedbacks.push(d);
                }
              });
              dispatch(setBlockFeedbackCount(feedbacks.length));
            }
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        });
    }
  }, [dispatch, notify, curViewVersion, viewVersion, dataProvider, id]);

  return null;
}

export default useBlockFeedbacks;
