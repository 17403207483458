import React, { useState, useCallback } from "react";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import HpTrendsGraph from "./HpTrendsGraph";
import CardWithTitle from "../../components/layout/CardWithTitle";
import EmptyData from "../../components/layout/EmptyData";
import { heatingColors } from "../../utils/graphColor";

const HpRegenerationGraph = ({
  showContainer,
  hideContainer,
  Trends,
  BuildingId,
}) => {
  const [hiddenGraphs, setHiddenGraphs] = useState(["month", "day"]);
  const [resolution, setResolution] = useState("month");
  const [trendValues] = useState(["TOTAL_REGENERATION_ENERGY"]);

  const handleGraphHide = useCallback((graph) => {
    setHiddenGraphs((prevHidden) => {
      if (!prevHidden.includes(graph)) {
        return [...prevHidden, graph];
      }
      return prevHidden;
    });
  }, []);

  const handleGraphShow = useCallback((graph) => {
    setHiddenGraphs((prevHidden) => {
      if (prevHidden.includes(graph)) {
        return prevHidden.filter((g) => g !== graph);
      }
      return prevHidden;
    });
  }, []);

  const handleResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  const ResolutionInput = (
    <div
      key={"res"}
      style={{
        marginLeft: 15,
        width: 80,
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <InputLabel>Resolution</InputLabel>
      <Select value={resolution} onChange={handleResolutionChange}>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
      </Select>
    </div>
  );

  if (showContainer && hideContainer) {
    if (hiddenGraphs.length < 2) {
      showContainer("regeneration");
    } else {
      hideContainer("regeneration");
    }
  }

  return (
    <CardWithTitle title="Regeneration">
      <div style={{ display: hiddenGraphs.length === 2 ? "none" : undefined }}>
        {ResolutionInput}
        <div style={{ display: resolution !== "day" ? "none" : undefined }}>
          <HpTrendsGraph
            Trends={Trends || []}
            trendId="ENERGY_DAILY"
            trendValues={trendValues}
            name="day"
            resolution="day"
            valueDivider={1000}
            unit="MWh"
            buildingId={BuildingId || null}
            naviMetaId={trendValues[0]}
            graphType="column"
            graphColors={heatingColors}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
        </div>
        <div style={{ display: resolution !== "month" ? "none" : undefined }}>
          <HpTrendsGraph
            Trends={Trends || []}
            trendId="ENERGY_MONTHLY"
            trendValues={trendValues}
            name="month"
            resolution="month"
            valueDivider={1000}
            unit="MWh"
            buildingId={BuildingId || null}
            naviMetaId={trendValues[0]}
            graphType="column"
            graphColors={heatingColors}
            showGraph={handleGraphShow}
            hideGraph={handleGraphHide}
          />
        </div>
      </div>
      <div
        style={{
          height: "20rem",
          display: hiddenGraphs.length < 2 ? "none" : undefined,
        }}
      >
        <EmptyData />
      </div>
    </CardWithTitle>
  );
};

export default HpRegenerationGraph;
