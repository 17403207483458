const optionRenderer = choice => {
  if (!choice) return "";
  else if (
    choice &&
    choice.PointId === null &&
    choice.Description === undefined
  )
    return "(Empty)";
  else return `${choice.PointId} - ${choice.Description} (${choice.Unit})`;
};

export default optionRenderer;
