import React, { useState, useEffect, useRef } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import useIsMounted from "../utils/hooks/useIsMounted";

import { find, groupBy, map, round, sortBy, some, cloneDeep } from "lodash";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import Boost from "highcharts/modules/boost";
Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (
  dataFromReactAdmin,
  monthlyData = false
) => {
  var current = new Date();
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = null;
    if (!monthlyData) {
      date = new Date(v.date);
    } else {
      date = new Date(v.year, v.month - 1, 1);
    }
    return [
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getTime(),
      round(v.value, 2),
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getMonth(),
    ];
    //return [Date.parse(v.Date), round(v.Value, 2)];
  });
  return highChartsData;
};

export const BlockDailyEnergyGraph = (props) => {
  const [resolution, setResolution] = useState("day");
  const [optionsMonth, setOptionsMonth] = useState({ title: { text: "" } });
  const [optionsDay, setOptionsDay] = useState({ title: { text: "" } });
  const [fetchDone, setFetchDone] = useState(false);
  const isMounted = useIsMounted();
  const chartRef = useRef(null);

  useEffect(() => {
    const graphOptions = (data, monthlyData = false) => {
      const grouped = groupBy(data, "metaId");
      let trend = monthlyData
        ? find(props.Trends, (y) => y.TrendId === "ENERGY_MONTHLY")
        : find(props.Trends, (y) => y.TrendId === "ENERGY_DAILY");
      const keys = Object.keys(grouped);

      let series = [];

      const conTargets = map(props.Hp.MonthlyTargets, (t) => t.ConsumedTarget);

      const prodTargets = map(props.Hp.MonthlyTargets, (t) => t.ProducedTarget);

      const addTargets = map(
        props.Hp.MonthlyTargets,
        (t) => t.AdditionalTarget
      );

      const seriesData = [];

      map(keys, (k) => {
        let trendValue = find(
          trend.Values,
          (v) => v.ReportingPointId === Number(k)
        );

        const sortedData = sortBy(
          valuesToHighchartsData(grouped[k], monthlyData),
          0
        );
        seriesData.push(sortedData);

        series.push({
          name: trendValue.Legend,
          type: "column",
          data: sortedData,
        });
      });

      let conData = [];
      let prodData = [];
      let addData = [];

      // Loop through the data and create target series according to that
      if (seriesData[0] && seriesData[0].length > 0) {
        seriesData[0].forEach((d) => {
          const y = new Date(d[0]).getFullYear();
          const m = new Date(d[0]).getMonth();
          const amountOfDays = monthlyData
            ? 1
            : parseInt(new Date(y, m + 1, 0).getDate());

          conData.push([d[0], round(conTargets[d[2]] / amountOfDays, 0)]);
          prodData.push([d[0], round(prodTargets[d[2]] / amountOfDays, 0)]);
          addData.push([d[0], round(addTargets[d[2]] / amountOfDays, 0)]);
        });
      }

      if (some(conData, (t) => !isNaN(t[1]))) {
        series.push({
          type: "line",
          name: "Energy consumption target",
          data: conData,
          dataGrouping: {
            approximation: "average",
          },
        });
      }

      if (some(prodData, (t) => !isNaN(t[1]))) {
        series.push({
          type: "line",
          name: "Energy production target",
          data: prodData,
          dataGrouping: {
            approximation: "average",
          },
        });
      }

      if (some(addData, (t) => !isNaN(t[1]))) {
        series.push({
          type: "line",
          name: "Additional energy consumption target",
          data: addData,
          dataGrouping: {
            approximation: "average",
          },
        });
      }
      // Calculate date offsets for the previous year button
      // Get the time of the latest value
      let newestData = 0;
      series.forEach((s) => {
        if (s && s.data && s.data.length > 0) {
          s.data.forEach((d) => {
            if (d[0] > newestData) {
              newestData = d[0];
            }
          });
        }
      });

      const curEnd = new Date(newestData);
      const curStart = new Date(curEnd.getUTCFullYear(), 0, 1);
      const prevStart = new Date(curEnd.getUTCFullYear() - 1, 0, 1);
      const prevEnd = new Date(curEnd.getUTCFullYear(), 0, 0);
      const diffStart = curStart.getTime() - prevStart.getTime();
      const diffEnd = curEnd.getTime() - prevEnd.getTime();

      return {
        legend: {
          enabled: true,
        },
        xAxis: {
          minRange: 1,
        },
        rangeSelector: {
          // selected: 1,
          allButtonsEnabled: true,
          buttonTheme: {
            width: "auto",
          },
          buttons: [
            {
              type: "month",
              count: 2,
              text: "3m",
            },
            {
              type: "month",
              count: 12,
              text: "12m",
            },
            {
              type: "ytd",
              text: "Last year",
              offsetMin: -diffStart,
              offsetMax: -diffEnd,
            },
            {
              type: "ytd",
              text: "This year",
            },
            {
              type: "all",
              text: "All",
            },
          ],
        },
        title: {
          text: "",
        },
        series: series,
      };
    };

    // Daily data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: props.BuildingId,
        metaIds: findMetaIds(props.Trends, "ENERGY_DAILY"),
        resolution: "day",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        if (isMounted) {
          setOptionsDay(graphOptions(response.values));
          setFetchDone(true);
        }
      });
    // Monthly data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: props.BuildingId,
        metaIds: findMetaIds(props.Trends, "ENERGY_MONTHLY"),
        resolution: "month",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        if (isMounted) {
          setOptionsMonth(graphOptions(response.values, true));
          setFetchDone(true);
        }
      });
  }, [isMounted, props.BuildingId, props.Trends, props.Hp.MonthlyTargets]);

  let options = null;
  if (resolution === "day") {
    options = optionsDay;
  } else if (resolution === "month") {
    options = optionsMonth;
  }

  let showData = true;
  if (!options || !options.series || options.series.length === 0) {
    showData = false;
  }

  // This is mostly to remove the animation when redraw is happening if the resolution is changed
  // Note the clone deep, it is used because the chart may mutate the series that is passed
  useEffect(() => {
    if (resolution && optionsDay && optionsMonth) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        if (resolution === "day") {
          chartRef.current.chart.update(
            cloneDeep(optionsDay),
            true,
            true,
            false
          );
        } else if (resolution === "month") {
          chartRef.current.chart.update(
            cloneDeep(optionsMonth),
            true,
            true,
            false
          );
        }
      }
    }
  }, [resolution, optionsDay, optionsMonth]);

  const handleResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  if (props.showContainer && props.hideContainer && fetchDone) {
    if (showData) {
      props.showContainer("energy");
    } else {
      props.hideContainer("energy");
    }
  }

  const ResolutionInput = (
    <div
      key={"res"}
      style={{
        marginLeft: 15,
        width: 80,
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <InputLabel>Resolution</InputLabel>
      <Select value={resolution} onChange={handleResolutionChange}>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
      </Select>
    </div>
  );

  return (
    <CardWithTitle title="Energy">
      {showData ? (
        [
          ResolutionInput,
          <HighchartsReact
            key="eChrt"
            ref={chartRef}
            highcharts={Highcharts}
            options={cloneDeep(options)}
            constructorType={"stockChart"}
          />,
        ]
      ) : (
        <div style={{ height: "20rem" }}>
          <EmptyData />
        </div>
      )}
    </CardWithTitle>
  );
};
