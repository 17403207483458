import React from "react";
import {
  SelectInput,
  BooleanInput,
  NumberField,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditButton,
  required,
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceArrayField,
  SelectArrayInput,
  TextInput,
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";

import { FormDataConsumer } from "react-admin";
import WritePointInput from "../components/WritePointInput";
import PageTitle from "../components/layout/PageTitle";

const CompensationTitle = ({ record }) => {
  return (
    <span>
      Compensation #{record ? `${record.id} - ${record.ProjectName}` : ""}
    </span>
  );
};

const editStyles = {
  buildingInfo: { display: "inline-block" },
};

export const CompensationEdit = withStyles(editStyles)(
  ({ classes, ...props }) => {
    return (
      <>
        <PageTitle title="Edit Compensation" />
        <Edit {...props} title={<CompensationTitle />}>
          <SimpleForm>
            <TextInput
              disabled
              source="ProjectName"
              formClassName={classes.buildingInfo}
            />
            <TextInput
              source="BuildingId"
              formClassName={classes.buildingInfo}
            />

            <TextInput
              disabled
              source="ProjectGuid"
              options={{ fullWidth: true }}
              formClassName={classes.buildingInfo}
            />

            <TextInput
              multiline
              label="Compensation description"
              source="Name"
              validate={required()}
            />

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.ProjectGuid && (
                  <ReferenceInput
                    label="BusAdapter"
                    source="BusAdapterId"
                    reference="busadapters"
                    allowEmpty={true}
                    filter={{ BuildingId: formData.BuildingId }}
                    {...rest}
                  >
                    <SelectInput
                      optionText={(choice) =>
                        `${choice.AdapterType ? choice.AdapterType : ""}: 
                    ${choice.IP ? choice.IP : ""} ${
                          choice.DeviceId ? choice.DeviceId : ""
                        }`
                      }
                    />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.BusAdapterId && [
                  <WritePointInput
                    key="wpinput"
                    dependsOnValue={formData.BusAdapterId}
                    label="Write points"
                    source="WritePointNames"
                    allowEmpty={true}
                    options={{ fullWidth: true, multiple: true }}
                  />,
                  <ReferenceArrayInput
                    key="rainput"
                    label="Compensation functions"
                    source="CompensationFunctionsIds"
                    reference="compensationfunctions"
                    sort={{ field: "Name", order: "ASC" }}
                    filter={{ BuildingId: formData.BuildingId }}
                    allowEmpty
                    fullWidth
                  >
                    <SelectArrayInput optionValue="id" optionText="Name" />
                  </ReferenceArrayInput>,
                ]
              }
            </FormDataConsumer>

            <TextInput
              multiline
              label="Formula"
              source="Formula"
              validate={required()}
            />

            <BooleanInput source="Active" />
            <BooleanInput
              label="Log compensation result"
              source="IsResultLogging"
            />

            <BooleanInput label="Simulate output" source="IsSimulation" />

            <ReferenceArrayField
              label="Functions in this compensation"
              source="CompensationFunctionsIds"
              reference="compensationfunctions"
            >
              <Datagrid>
                <NumberField label="FunctionId" source="FunctionId" />
                <TextField source="Name" />
                <TextField source="ValueStorageDescription" />
                <EditButton />
              </Datagrid>
            </ReferenceArrayField>
          </SimpleForm>
        </Edit>
      </>
    );
  }
);
