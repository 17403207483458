import React from "react";
import { useSelector } from "react-redux";
import ZoneMap from "./ZoneMap";
import { get, filter, isNil } from "lodash";
import { toBuildingTime } from '../utils/twinUtils';

const ZoneTwinInfo = (props) => {
  const buildingData = useSelector(
    (state) => state.buildingFilter.buildingData
  );
  const allTwins = useSelector((state) => state.buildingFilter.allTwins);

  const twinData = [];

  if (props.avgTemp) {
    twinData.push({
      title: "avgTemp",
      timestamp: toBuildingTime(
        props.avgTemp.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.avgTemp.Value) ? props.avgTemp.Value.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (props.minTemp) {
    twinData.push({
      title: "minTemp",
      timestamp: toBuildingTime(
        props.minTemp.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.minTemp.Value) ? props.minTemp.Value.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (props.maxTemp) {
    twinData.push({
      title: "maxTemp",
      timestamp: toBuildingTime(
        props.maxTemp.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.maxTemp.Value) ? props.maxTemp.Value.toFixed(1) : null,
      unit: "°C",
    });
  }
  if (props.avgCo2) {
    twinData.push({
      title: "avgCo2",
      timestamp: toBuildingTime(
        props.avgCo2.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.avgCo2.Value) ? props.avgCo2.Value.toFixed(1) : null,
      unit: "ppm",
    });
  }
  if (props.minCo2) {
    twinData.push({
      title: "minCo2",
      timestamp: toBuildingTime(
        props.minCo2.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.minCo2.Value) ? props.minCo2.Value.toFixed(1) : null,
      unit: "ppm",
    });
  }
  if (props.maxCo2) {
    twinData.push({
      title: "maxCo2",
      timestamp: toBuildingTime(
        props.maxCo2.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.maxCo2.Value) ? props.maxCo2.Value.toFixed(1) : null,
      unit: "ppm",
    });
  }
  if (props.avgHumidity) {
    twinData.push({
      title: "avgHumidity",
      timestamp: toBuildingTime(
        props.avgHumidity.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.avgHumidity.Value)
        ? props.avgHumidity.Value.toFixed(1)
        : null,
      unit: "%",
    });
  }
  if (props.minHumidity) {
    twinData.push({
      title: "minHumidity",
      timestamp: toBuildingTime(
        props.minHumidity.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.minHumidity.Value)
        ? props.minHumidity.Value.toFixed(1)
        : null,
      unit: "%",
    });
  }
  if (props.maxHumidity) {
    twinData.push({
      title: "maxHumidity",
      timestamp: toBuildingTime(
        props.maxHumidity.UpdateTime,
        get(buildingData, "timeZoneIANA", null),
        true
      ),
      data: !isNil(props.maxHumidity.Value)
        ? props.maxHumidity.Value.toFixed(1)
        : null,
      unit: "%",
    });
  }
  // Zone feedback
  if (
    props.zoneFeedback &&
    props.zoneFeedback.nps !== null &&
    props.zoneFeedback.feedbacks &&
    props.zoneFeedback.feedbacks.length > 0
  ) {
    let goodCount = null;
    let neutralCount = null;
    let badCount = null;

    goodCount = filter(
      props.zoneFeedback.feedbacks,
      (f) => f.feedbackScore === "Good"
    ).length;
    neutralCount = filter(
      props.zoneFeedback.feedbacks,
      (f) => f.feedbackScore === "Neutral"
    ).length;
    badCount = filter(
      props.zoneFeedback.feedbacks,
      (f) => f.feedbackScore === "Bad"
    ).length;

    twinData.push({
      title: "nps",
      timestamp: "",
      data: props.zoneFeedback.nps,
      unit: "",
    });
    twinData.push({
      title: "good",
      timestamp: "",
      data: goodCount,
      unit: "",
    });
    twinData.push({
      title: "neutral",
      timestamp: "",
      data: neutralCount,
      unit: "",
    });
    twinData.push({
      title: "bad",
      timestamp: "",
      data: badCount,
      unit: "",
    });
  }

  // Ahu twins for zone map
  let ahuTwins = [];
  if (
    allTwins &&
    allTwins.length > 0 &&
    get(props, "Zone.ServedByBlocks", null)
  ) {
    const ahus = get(props, "Zone.ServedByBlocks", null);
    if (ahus && ahus.length > 0) {
      ahus.forEach((a) => {
        const twin = allTwins.find((t) => t.id === a);
        if (twin) {
          ahuTwins.push(twin);
        }
      });
    }
  }

  return (
    <ZoneMap zoneImage={props.zoneImage} data={twinData} ahus={ahuTwins} />
  );
};

export default ZoneTwinInfo;
