import React from "react";
import {
  List,
  Datagrid,
  EditButton,
  FunctionField,
  NumberField,
  Pagination,
} from "react-admin";

import { stateMapper } from "../customReducers/connectFunctions";
import { get } from "lodash";
import { setPowerConsumptionUnitText } from "../utils/unitTextParsers";
import { PowerConsumptionActions } from "./PowerConsumptionActions";
import DateFieldTz from "../utils/DateFieldTz";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const PowerConsumptionListsList = ({
  BuildingId,
  projectname,
  ...props
}) => {
  const { isAdminOrUser } = useUserRole();
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;
  const tz = get(listProps, "building.TimeZoneIANA", null);
  return (
    <>
      <PageTitle title="Power Consumptions" />
      <List
        {...listProps}
        sort={{ field: "Time", order: "DESC" }}
        perPage={25}
        pagination={<ValuesPagination />}
        filterDefaultValues={{ BuildingId }}
        filter={{ BuildingId }}
        bulkActionButtons={false}
        actions={<PowerConsumptionActions />}
      >
        <Datagrid>
          <DateFieldTz
            source="Time"
            label={tz ? "Date" : "Date (UTC)"}
            showTime
            timeZone={tz}
          />
          <NumberField
            source="Power"
            label={setPowerConsumptionUnitText("Power")}
            options={{ useGrouping: false }}
          />
          <NumberField
            source="WaterAmount"
            label={setPowerConsumptionUnitText("WaterAmount")}
            options={{ useGrouping: false }}
          />
          <NumberField
            source="WIncoming"
            label={setPowerConsumptionUnitText("WIncoming")}
            options={{ useGrouping: false }}
          />
          <NumberField
            source="WOutgoing"
            label={setPowerConsumptionUnitText("WOutgoing")}
            options={{ useGrouping: false }}
          />
          <NumberField
            source="Cooling"
            label={setPowerConsumptionUnitText("Cooling")}
            options={{ useGrouping: false }}
          />
          <FunctionField label="Source" render={(record) => record.Source} />
          <FunctionField
            render={(record) =>
              record.Source === "Metry"
                ? null
                : isAdminOrUser && <EditButton to={`${props.basePath}/${record.id}`} />
            }
          />
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(PowerConsumptionListsList);
