import React, { useState, Fragment, useEffect } from "react";
import {
  AutocompleteInput,
  BulkDeleteButton,
  Datagrid,
  Filter,
  List,
  NullableBooleanInput,
  ReferenceInput,
  TextField,
  TextInput,
  useListController,
  Pagination,
  FunctionField,
  SelectInput
} from "react-admin";
import { get } from "lodash";
import { stateMapper } from "../customReducers/connectFunctions";
import CheckButton from "../alerts/CheckButton";
import CheckAlerts from "../alerts/CheckAlerts";
import { SourceTwin, SourceBuilding } from "./AlertShow";
import CheckManyAlertsButton from "./CheckManyAlertsButton";
import DateFieldTz from "../utils/DateFieldTz";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

const AlertFilter = ({ BuildingId, ...props }) => {
  return (
    <Filter {...props}>
      <NullableBooleanInput label="Checked?" source="Check" alwaysOn />
      <NullableBooleanInput label="Active?" source="Active" alwaysOn />
      <SelectInput
        alwaysOn
        allowEmpty={true}
        label="Priority"
        source="Priority"
        choices={[
          {
            id: 0,
            name: "Critical",
            value: 0
          },
          {
            id: 1,
            name: "Warning",
            value: 1
          },
          {
            id: 2,
            name: "Informative",
            value: 2
          },
          {
            id: 3,
            name: "Uncategorized",
            value: 3
          },
        ]}
      />
      <ReferenceInput
        label="Building"
        source="BuildingId"
        reference="buildings"
        sort={{ field: "ProjectName", order: "ASC" }}
        filter={{ showBAUI: true }}
        alwaysOn
      >
        <AutocompleteInput optionText="ProjectName" optionValue="id" />
      </ReferenceInput>

      <TextInput label="Search name/description" source="q" alwaysOn />
    </Filter>
  );
};

const AlertBulkActionButtons = ({ ...props }) => {
  const { isAdmin, isAdminOrUser } = useUserRole();
  const [, setDialogState] = useState(false);
  const handleDialogChange = (val) => {
    setDialogState(val);
  };
  return (
    <Fragment>
      {isAdminOrUser &&
        props.filterValues.Check === false && (
          <CheckAlerts dialogCb={handleDialogChange} {...props} />
        )}
      {isAdminOrUser &&
        props.filterValues.Check === true && (
          <CheckManyAlertsButton {...props} />
        )}

      {isAdmin && <BulkDeleteButton {...props} />}
    </Fragment>
  );
};

const AlertButtons = (props) => {
  if (props.record.Check) {
    return <CheckButton {...props} />;
  } else {
    return <CheckAlerts dialogCb={props.handleDialogChange} {...props} />;
  }
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const getPriorityLabel = (priorityNumber) => {
  const labelStyle = {
    color: "white",
    padding: "1px 5px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
  };
  const priorityMap = {
    0: <div style={{ ...labelStyle, backgroundColor: "#ff4d4d" }}>Critical</div>,
    1: <div style={{ ...labelStyle, backgroundColor: "#ffa700" }}>Warning</div>,
    2: <div style={{ ...labelStyle, backgroundColor: "#689BE8" }}>Informative</div>,
    3: <div style={{ ...labelStyle, backgroundColor: "grey" }}>Uncategorized</div>
  };
  return priorityMap[priorityNumber];
}
const AlertList = ({ BuildingId, projectname, ...props }) => {
  const { isAdminOrUser } = useUserRole();
  const [dialogState, setDialogState] = useState(false);
  const handleDialogChange = (val) => {
    setDialogState(val);
  };
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;
  let gridProps = Object.assign({}, listProps);
  delete gridProps.hasList;
  delete gridProps.hasEdit;
  delete gridProps.hasShow;
  const tz = get(listProps, "building.TimeZoneIANA", null);

  // List controller is used to get the current filter values to add buildingId to them
  const listController = useListController(props);
  let filterValues = null;
  let displayedFilters = null;
  let setFilters = null;
  if (listController) {
    ({ setFilters, filterValues, displayedFilters } = listController);
  }

  // If a building is selected, add the selected building to the filters
  useEffect(() => {
    if (
      BuildingId &&
      setFilters &&
      filterValues &&
      filterValues.BuildingId !== BuildingId
    ) {
      setFilters(
        { ...filterValues, BuildingId, Check: null, Active: true },
        displayedFilters
      );
    }
  }, [BuildingId, filterValues, displayedFilters, setFilters]);

  return (
    <>
      <PageTitle title="Alerts" />
      <List
        {...listProps}
        title={"Alerts"}
        pagination={<ValuesPagination />}
        bulkActionButtons={<AlertBulkActionButtons />}
        sort={{ field: "Priority", order: "DESC" }}
        filters={<AlertFilter />}
        filterDefaultValues={{
          Check: null,
          Active: true,
          BuildingId: BuildingId,
        }}
        perPage={25}
      >
        <Datagrid
          rowClick={(id) => {
            // If dialog is open return null to cancel redirect
            if (dialogState) {
              return null;
            }
            const path = `/alerts/${id}/show`;
            return path;
          }}
          {...gridProps}
        >
          <SourceBuilding
            sortBy={"BuildingId"}
            addLabel={true}
            label="Building"
          />
          <TextField source="AlertName" />
          <TextField source="Description" />
          <SourceTwin label="Source twin" />
          <DateFieldTz
            source="AlertTime"
            label={tz ? "Alert time" : "Alert time (UTC)"}
            showTime
            timeZone={tz}
          />
          <DateFieldTz
            source="AlertEndTime"
            label={tz ? "Alert end time" : "Alert end time (UTC)"}
            showTime
            allowEmpty
            timeZone={tz}
          />
          <DateFieldTz
            source="Checked"
            label={tz ? "Checked" : "Checked (UTC)"}
            showTime
            allowEmpty
            timeZone={tz}
          />
          <TextField source="CheckedBy" />
          <FunctionField
            sortBy="Priority"
            label="Priority"
            sortable={true}
            render={({ Priority }) => getPriorityLabel(Priority)}
          />
          {isAdminOrUser && (
            <AlertButtons dialogCb={handleDialogChange} {...props} />
          )}
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(AlertList);
