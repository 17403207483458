import React, { useState } from "react";
import { Button, makeStyles, SvgIcon, Tooltip, Fade } from "@material-ui/core";

import alertIcon from "../../assets/icons/icon_check.svg";
import ahuIcon from "../../assets/icons/ahu_icon.svg";
import hpIcon from "../../assets/icons/hp_icon.svg";
import hxIcon from "../../assets/icons/hx_icon.svg";
import meterIcon from "../../assets/icons/meter_icon.svg";
import solarIcon from "../../assets/icons/solar_icon.svg";
import zoneIcon from "../../assets/icons/zone_icon.svg";

const useStyles = makeStyles({
  container: {
    display: "inline-flex",
    height: "2.125rem",
    width: "6.5rem",
    position: "relative",
    alignItems: "center",
  },
  twinIcon: {
    zIndex: 99,
    display: "inline-block",
    position: "absolute",
    boxShadow: "0px 0px 6px #0000004D",
    borderRadius: "50%",
  },
  buttonRoot: {
    width: "5.5rem",
    height: "1.875rem",
    padding: 0,
    border: (props) =>
      props.alert ? "1px solid #D62B1A" : "1px solid #282331",
    borderRadius: "20px",
    boxShadow: (props) =>
      props.alert ? "0px 3px 10px #D62B1A33" : "0px 3px 10px #28233133",
    textAlign: "center",
    marginLeft: 16,
  },
  text: {
    fontWeight: 600,
    fontSize: "0.75rem",
    color: (props) => (props.alert ? "#D62B1A" : "#282331"),
  },
  alertIcon: {
    color: "#D62B1A",
    height: "100%",
  },
  alertIconContainer: {
    backgroundColor: "transparent",
    height: "1rem",
    width: "1rem",
    zIndex: 10,
    position: "absolute",
    right: 0,
    top: "-5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    fontSize: "2.5rem",
  },
  arrowBottom: {
    textAlign: "center",
    position: "relative",
    top: "-8px",
    height: 33,
  },
  arrowRight: {
    transform: "rotate(270deg)",
    width: 32,
  },
  tooltip: {
    backgroundColor: "#282331",
    borderRadius: "2px",
    boxShadow: "0px 3px 6px #00000029",
    fontSize: "0.5625rem",
  },
  tooltipArrow: {
    color: "#282331",
  },
});

const TwinButton = (props) => {
  const classes = useStyles(props);

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const alertIndicator = (
    <div className={classes.alertIconContainer}>
      <img className={classes.alertIcon} src={alertIcon} alt="alert" />
    </div>
  );

  const arrowIcon = (
    <SvgIcon
      style={{ width: props.arrowRight ? 32 : "auto" }}
      className={classes.arrowIcon}
      viewBox="0 0 14 40.189"
    >
      <g id="arrow" transform="translate(-104 -143.5)">
        <circle
          id="Ellipse_51"
          data-name="Ellipse 51"
          cx="7"
          cy="7"
          r="7"
          transform="translate(104 143.5)"
          fill="#f5f5f5"
        />
        <circle
          id="Ellipse_52"
          data-name="Ellipse 52"
          cx="4"
          cy="4"
          r="4"
          transform="translate(107 146.5)"
          fill={props.alert ? "#D62B1A" : "#282331"}
        />
        <rect
          id="Rectangle_243"
          data-name="Rectangle 243"
          width="2"
          height="30"
          transform="translate(110 152)"
          fill={props.alert ? "#D62B1A" : "#282331"}
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M1168.71,352.866l5,5,5-5"
          transform="translate(-1062.71 -175.592)"
          fill="none"
          stroke={props.alert ? "#D62B1A" : "#282331"}
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );

  let twinType =
    props.twinType && typeof props.twinType === "string"
      ? props.twinType.toLowerCase()
      : null;
  let twinIcon = null;
  let tooltipText = "";

  switch (twinType) {
    case "ahu":
      twinIcon = ahuIcon;
      tooltipText = "Air handling unit";
      break;

    case "hp":
      twinIcon = hpIcon;
      tooltipText = "Heat pump";
      break;

    case "hx":
      twinIcon = hxIcon;
      tooltipText = "Heating exchanger";
      break;

    case "meter":
      twinIcon = meterIcon;
      tooltipText = "Meter";
      break;

    case "solar":
      twinIcon = solarIcon;
      tooltipText = "Solar";
      break;

    case "zone":
      twinIcon = zoneIcon;
      tooltipText = "Zone";
      break;

    default:
      break;
  }

  // Back to uppercase for showing the text on the button
  twinType = twinType && twinType.toUpperCase();

  const arrowBottom = <div className={classes.arrowBottom}>{arrowIcon}</div>;
  const arrowRight = <div className={classes.arrowRight}>{arrowIcon}</div>;
  return (
    <>
      <div
        className={classes.container}
        style={props.style}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
      >
        <img src={twinIcon} alt={tooltipText} className={classes.twinIcon} />
        <Tooltip
          open={open}
          arrow
          placement="top"
          title={tooltipText}
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 0 }}
        >
          <Button
            onClick={props.onClick}
            disabled={props.disabled}
            classes={{ root: classes.buttonRoot, label: classes.text }}
          >
            {props.alert && alertIndicator}
            {twinType}
          </Button>
        </Tooltip>
      </div>
      {props.arrowBottom && arrowBottom}
      {props.arrowRight && arrowRight}
    </>
  );
};

export default TwinButton;
