import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Card from "@material-ui/core/Card";
import { raDataToHighcharts } from "../utils/raDataToHighcharts";
import moment from "moment-timezone";
import { restClient } from "../App";
import { GET_ONE, GET_LIST } from "ra-core";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import Boost from "highcharts/modules/boost";
import Spinner from "../components/layout/Spinner";
import { sortBy } from "lodash";
import { heatingColors } from "../utils/graphColor";
Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

window.moment = moment;

const findReportingIds = (blocks) => {
  let reportingIds = [];

  if (blocks.length > 0) {
    blocks.forEach((block) => {
      const id = findReportingId(block);
      id && reportingIds.push(id);
    });
  }
  return reportingIds;
};

const findReportingId = (block) => {
  let reportingId;
  if (block.Trends.length > 0) {
    let trend = block.Trends.find((t) =>
      t.Values.find((v) => v.Legend.includes("setpoint"))
    );
    if (trend) {
      const value = trend.Values.find((v) => v.Legend.includes("setpoint"));
      let idName = "";
      if (block.BlockDescription != null) {
        idName = block.BlockDescription;
      } else idName = block.BlockName;
      reportingId = {
        description: idName,
        pointId: value.ReportingPointId,
        data: [],
      };
    }
  }
  return reportingId;
};

const fetchDataForSeries = async (pointId, buildingId) => {
  const series = await restClient(GET_LIST, "reportdata", {
    filter: {
      metaIds: [pointId],
      buildingId: buildingId,
      //dataBegin: resolveDataBegin(),
      //dataEnd: resolveDataEnd()
    },
    sort: { field: "Date", order: "ASC" },
    pagination: {},
  });
  return await series;
};

const fetchBlocksWithTrends = async (blocks) => {
  let blocklist = [];
  if (blocks) {
    blocklist = await Promise.all(
      blocks.map(async (b) => {
        const block = await restClient(GET_ONE, "blocks", { id: b.id });
        return await block.data;
      })
    );
  }
  return blocklist;
};

class PowerConsumptionGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      projectname: this.props.projectname,
      buildingId: this.props.BuildingId,
      // consumptions: this.props.consumptions,
      // temperatures: this.props.temperatures,
      // Only consumptions with a matching temperature are shown
      consumptions: props.consTemps.map((c) => ({
        Power: c.Power,
        Time: c.Time,
      })),
      temperatures: props.consTemps.map((c) => ({
        value: c.Temperature,
        valueTime: c.Time,
      })),
      blocks: this.props.blocks,
      compensationIds: [],
    };
  }

  createSetpointSeries = (id, buildingId) => {
    return {
      id: id.description,
      name: id.description,
      type: "line",
      dataGrouping: {
        approximation: "high",
      },
      events: {
        legendItemClick: async function () {
          const seri = this.chart.get(id.description);
          const seriesData = seri.getValidPoints();
          if (seriesData.length === 0) {
            const data = await fetchDataForSeries(id.pointId, buildingId);
            const fetchedData = await data.data;
            await seri.setData(
              raDataToHighcharts(fetchedData, "valueTime", "value")
            );
          }
        },
      },
      visible: false,
      showInNavigator: false,
      data: [],
      yAxis: 3,
      boostThreshold: 1,
      turboThreshold: 1,
    };
  };

  createSeries = (consumptions, temperatures, compensationData) => {
    let series = [
      {
        name: "Power consumption",
        type: "column",
        dataGrouping: {
          approximation: "high",
        },
        showInNavigator: true,
        data: raDataToHighcharts(consumptions, "Time", "Power"),
        tooltip: {
          valueSuffix: "kW",
          valueDecimals: 2,
        },
        yAxis: 2,
        zIndex: 0,
        boostThreshold: 1,
        turboThreshold: 1,
      },
      {
        name: "Outdoor temperature",
        type: "line",
        dataGrouping: {
          approximation: "average",
        },
        data: raDataToHighcharts(temperatures, "valueTime", "value"),
        tooltip: {
          valueSuffix: "°C",
          valueDecimals: 2,
        },
        zIndex: 1,
        boostThreshold: 1,
        turboThreshold: 1,
      },
    ];
    compensationData.forEach((id) =>
      series.push(this.createSetpointSeries(id, this.props.BuildingId))
    );
    // Sort data
    series.forEach((ser) => {
      if (ser && ser.data && ser.data.length > 0) {
        ser.data = sortBy(ser.data, (d) => d[0]);
      }
    });
    return series;
  };

  graphOptions = (consumptions, temperatures, compensationData) => {
    return {
      chart: {},
      legend: {
        enabled: true,
      },
      colors: heatingColors,
      rangeSelector: {
        allButtonsEnabled: true,
        buttons: [
          {
            type: "day",
            count: 1,
            text: "day",
          },
          {
            type: "week",
            count: 1,
            text: "week",
          },
          {
            type: "month",
            count: 1,
            text: "month",
          },
          {
            type: "month",
            count: 3,
            text: "3 months",
          },
          {
            type: "month",
            count: 6,
            text: "6 months",
          },
          {
            type: "year",
            count: 1,
            text: "year",
          },
          {
            type: "ytd",
            text: "This year",
          },
          {
            type: "all",
            text: "All",
          },
        ],
        buttonTheme: {
          width: "auto",
        },
      },
      series: this.createSeries(consumptions, temperatures, compensationData),
      time: {
        timezone: this.props.timezone,
      },
      title: {
        // text: this.state.projectname,
        text: "",
      },
      tooltip: {
        split: true,
        valueDecimals: 2,
      },
      yAxis: [
        {
          format: "{value}°C",
          labels: {
            align: "right",
          },
          title: {
            text: "Temperature",
          },
          min: -35,
          max: 35,
          opposite: false,
          height: "65%",
          top: "30%",
        },
        {},
        {
          title: {
            text: "Power consumption",
          },
          format: "{value} kWm",
          opposite: true,
          min: 0,
          startOnTick: false,
          endOnTick: false,
          height: "65%",
          top: "30%",
        },
        {
          format: "{value}°C",
          labels: {
            align: "right",
          },
          title: {
            text: "Compensation",
          },
          min: -5,
          max: 5,
          opposite: false,
          height: "25%",
        },
      ],
    };
  };

  async componentDidMount() {
    let blocksWithTrends = [];
    if (!this.props.allTwinsData || this.props.allTwinsData.length === 0) {
      this.setState({ loading: true });
      blocksWithTrends = await fetchBlocksWithTrends(this.state.blocks);
      this.setState({ loading: false });
    } else {
      blocksWithTrends = this.props.allTwinsData;
    }
    const compensationIds = await findReportingIds(blocksWithTrends);
    const compensationData = await compensationIds.map((id) => {
      return {
        description: id.description,
        pointId: id.pointId,
        data: [],
      };
    });
    const options = await this.graphOptions(
      this.state.consumptions,
      this.state.temperatures,
      compensationData
    );
    await this.setState({ options });
  }

  render() {
    return (
      <div>
        <Card>
          {!this.state.loading ? (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={this.state.options}
            />
          ) : (
            <Spinner style={{ height: "20rem" }} />
          )}
        </Card>
      </div>
    );
  }
}

export default PowerConsumptionGraph;
