import React from "react";
import { DateField } from "react-admin";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";

const DateFieldTz = (props) => {
  let newProps = cloneDeep(props);
  // If a timezone is given, convert the time to building time
  if (newProps && newProps.timeZone) {
    if (newProps.record[newProps.source]) {
      newProps.record[newProps.source] = moment
        .utc(newProps.record[newProps.source])
        .tz(newProps.timeZone)
        .format();
    }
    // If no timezone specified, show UTC time
  } else if (newProps.record[newProps.source]) {
    newProps.options = { timeZone: "UTC" };
  }
  delete newProps.timeZone;
  return <DateField {...newProps} />;
};

export default DateFieldTz;
