import React, { useState, useCallback, useEffect } from "react";
import { makeStyles, Tab, Tabs, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";

import PurchasedGraph from "./PurchasedGraph";
import CardWithTitle from "../../components/layout/CardWithTitle";
import useUserRole from "../../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "28rem",
  },
  indicator: {
    backgroundColor: "#1AD689",
  },
  label: {
    textTransform: "none",
    fontSize: "1.125rem",
    color: "#282331",
    fontWeight: 400,
    height: "3.25rem",
    width: "12.1875rem",
    lineHeight: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, className, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={className} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const PurchasedGraphs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState("heating");
  const [hiddenGraphs, setHiddenGraphs] = useState([]);
  const { isExternalUser, isReader } = useUserRole();
  const { hideContainer, showContainer } = props;

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const handleGraphHide = useCallback(
    (graph) => {
      if ((isExternalUser || isReader) && !hiddenGraphs.includes(graph)) {
        setHiddenGraphs([...hiddenGraphs, graph]);
      }
    },
    [hiddenGraphs, isExternalUser, isReader]
  );

  const handleGraphShow = useCallback(
    (graph) => {
      if ((isExternalUser || isReader) && hiddenGraphs.includes(graph)) {
        setHiddenGraphs(hiddenGraphs.filter((r) => r !== graph));
      }
    },
    [hiddenGraphs, isExternalUser, isReader]
  );

  // Each time hiddenResources changes, change the selected resource if necessary
  useEffect(() => {
    const possibleGraphIds = ["heating", "water", "electricity", "cooling"];
    // Set current selection as the "top" one
    for (let i = 0; i < possibleGraphIds.length; i++) {
      const p = possibleGraphIds[i];
      if (!hiddenGraphs.includes(p)) {
        setValue(p);
        break;
      }
    }
  }, [hiddenGraphs]);

  useEffect(() => {
    if (hiddenGraphs.length >= 4 && hideContainer) {
      hideContainer("purchased");
    }
    if (hiddenGraphs.length < 4 && showContainer) {
      showContainer("purchased");
    }
  }, [hiddenGraphs, hideContainer, showContainer]);

  return (
    <CardWithTitle title={"Purchased"} spacerColor="#2E2247">
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        classes={{ indicator: classes.indicator }}
        value={value}
        onChange={handleChange}
      >
        {!hiddenGraphs.includes("heating") && (
          <Tab
            classes={{ root: classes.label }}
            label="Heating"
            value="heating"
          />
        )}
        {!hiddenGraphs.includes("water") && (
          <Tab classes={{ root: classes.label }} label="Water" value="water" />
        )}
        {!hiddenGraphs.includes("electricity") && (
          <Tab
            classes={{ root: classes.label }}
            label="Electricity"
            value="electricity"
          />
        )}
        {!hiddenGraphs.includes("cooling") && (
          <Tab
            classes={{ root: classes.label }}
            label="Cooling"
            value="cooling"
          />
        )}
      </Tabs>
      {/* Just a dummy, used to check if the graphs have any potential data */}
      <div style={{ display: "none" }}>
        <PurchasedGraph
          type="heating"
          value="Consumption"
          temperature
          showGraph={handleGraphShow}
          hideGraph={handleGraphHide}
        />
        <PurchasedGraph
          type="water"
          value="Consumption"
          target
          showGraph={handleGraphShow}
          hideGraph={handleGraphHide}
        />
        <PurchasedGraph
          type="electricity"
          value="Consumption"
          target
          showGraph={handleGraphShow}
          hideGraph={handleGraphHide}
        />
        <PurchasedGraph
          type="cooling"
          value="Consumption"
          target
          showGraph={handleGraphShow}
          hideGraph={handleGraphHide}
        />
      </div>
      <TabPanel className={classes.container} value={value} index={"heating"}>
        <PurchasedGraph type="heating" value="Consumption" temperature />
      </TabPanel>
      <TabPanel className={classes.container} value={value} index={"water"}>
        <PurchasedGraph type="water" value="Consumption" target />
      </TabPanel>
      <TabPanel
        className={classes.container}
        value={value}
        index={"electricity"}
      >
        <PurchasedGraph type="electricity" value="Consumption" target />
      </TabPanel>
      <TabPanel className={classes.container} value={value} index={"cooling"}>
        <PurchasedGraph type="cooling" value="Consumption" target />
      </TabPanel>
    </CardWithTitle>
  );
};

export default PurchasedGraphs;
