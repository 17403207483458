import React from "react";
import { Link } from "react-router-dom";

const ListTitle = (projectname, BuildingId, listname) => {
  var title = "";
  projectname === "none"
    ? (title = " No building selected")
    : (title = (
        <Link
          to={`/buildings/${BuildingId}/show`}
          style={{ textDecoration: "none", color: "white" }}
        >
          {listname} for {projectname}
        </Link>
      ));
  return title;
};

export default ListTitle;
