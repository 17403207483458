import React from "react";
import { DateInput, DateTimeInput } from "react-admin-date-inputs";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import { mapper } from "../customReducers/connectFunctions";
import useBuildingTimezone from "../utils/hooks/useBuildingTimezone";
import { get } from "lodash";

// This will save the date selected with the timestamp 00:00 UTC of the first day of the selected month.
export const MonthInput = ({ source, label }) => (
  <DateInput
    source="Time"
    label="Date"
    options={{
      format: "MM/YYYY",
      // keyboard: true,
      autoOk: true,
      clearable: true,
    }}
    providerOptions={{
      utils: MomentUtils,
    }}
    initialValue={createdefaultdate}
    parse={(v) => v && moment(v).utc(v).startOf("month").format()}
  />
);

// This will save the date selected with the timestamp 00:00 UTC of the first day of the selected month. This is used on create for month input.
const createdefaultdate = () => {
  var now = moment();
  now = now.subtract(1, "months").startOf("month").utc(now).format();
  return now;
};

export const DateInputNoTimezone = ({
  source,
  label,
  defaultDate,
  onChange = null,
  className,
  disabled = false,
}) => {
  return (
    <DateInput
      className={className}
      source={source}
      label={label}
      options={{
        format: "D.M.YYYY",
        autoOk: true,
        clearable: true,
        showTodayButton: true,
        locale: "fiLocale",
        disabled: disabled,
      }}
      providerOptions={{
        utils: MomentUtils,
      }}
      parse={(v) => {
        return v && moment(v).utc(v).format();
      }}
      initialValue={defaultDate}
      onChange={onChange && onChange}
    />
  );
};

/*
defaultValue={moment()
      .utc()
      .startOf("day")
      .format()}
*/

const DateTimeWithTimezoneInput = ({ building, label, source, ...props }) => {
  const tz = useBuildingTimezone();
  return (
    <DateTimeInput
      source={source}
      label={label}
      options={{
        format: "DD/MM/YYYY, HH:mm",
        autoOk: true,
        clearable: true,
        ampm: false,
      }}
      providerOptions={{
        utils: MomentUtils,
      }}
      parse={(v) =>
        v &&
        moment
          .utc(v)
          .tz(get(building, "TimeZoneIANA", null) || tz)
          .format()
      }
      format={(v) =>
        v &&
        moment
          .utc(v)
          .tz(get(building, "TimeZoneIANA", null) || tz)
          .format()
      }
    />
  );
};

export default mapper(DateTimeWithTimezoneInput);
