import React, { useState } from "react";
import { useRefresh, useNotify } from "react-admin";
import { makeStyles, IconButton, SvgIcon } from "@material-ui/core";
import { restClient } from "../App";

const useStyles = makeStyles(() => ({
  buttonRoot: {
    alignSelf: "end",
    marginLeft: "auto",
    "&:hover svg": {
      strokeOpacity: 1,
    },
  },
  buttonIcon: {
    width: 20,
    fill: "#000",
    stroke: "#000",
    fillOpacity: (props) => (props.pinned ? 1 : 0),
    strokeOpacity: 0.5,
    transform: (props) => (props.pinned ? "rotate(0deg)" : "rotate(45deg)"),
    transition: "transform .25s, fill-opacity .25s",
  },
}));

const OperationPinButton = (props) => {
  const classes = useStyles(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const PinIcon = (
    <SvgIcon classes={{ root: classes.buttonIcon }}>
      <g
        transform="matrix(0.85245401,0,0,1.1250178,1.7705519,-1.6072136)"
        id="g839"
      >
        <path
          id="path837"
          fillRule="evenodd"
          d="M 16,9 V 4 h 1 c 0.55,0 1,-0.45 1,-1 V 3 C 18,2.45 17.55,2 17,2 H 7 C 6.45,2 6,2.45 6,3 v 0 c 0,0.55 0.45,1 1,1 h 1 v 5 c 0,1.66 -1.34,3 -3,3 v 0 2 h 5.97 v 7 l 1,1 1,-1 V 14 H 19 v -2 0 c -1.66,0 -3,-1.34 -3,-3 z"
        />
      </g>
    </SvgIcon>
  );

  const handlePinUnpin = () => {
    setLoading(true);
    const endpoint = props.pinned ? "/unpin" : "/pin";
    const params = {
      id: props.id + endpoint,
      data: {},
    };
    restClient("UPDATE", "operationlog", params)
      .then(() => {
        refresh();
        setLoading(false);
      })
      .catch((e) => {
        notify(e.message, "warning");
        setLoading(false);
      });
  };

  return props.pinned || props.isPermissions ? (
    <IconButton
      classes={{ root: classes.buttonRoot }}
      disabled={loading || !props.isPermissions}
      onClick={handlePinUnpin}
    >
      {PinIcon}
    </IconButton>
  ) : null;
};

export default OperationPinButton;
