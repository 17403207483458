import React from "react";
import { makeStyles, Typography, SvgIcon, Tooltip } from "@material-ui/core";
import { isNil } from "lodash";

const useStyles = makeStyles({
  tank: {
    minHeight: 160,
    height: (props) => props.height,
    width: (props) => props.height * 0.75,
    minWidth: 160,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #282331",
    borderRadius: "34% 38% 38% 38% / 14% 14% 14% 14%",
    position: "absolute",
    justifyContent: "center",
    boxShadow: "0px 0px 10px #0000003D",
  },
  icon: {
    fill: "#fff",
    transform: "scale(0.5)",
  },
  iconContainer: {
    boxShadow: "0px 0px 6px #0000004D",
    background: "#282331 0% 0% no-repeat padding-box",
    borderRadius: "50%",
    width: 25,
    height: 25,
    margin: "0 auto",
    textAlign: "center",
  },
  data: {
    fontSize: 24,
    fontWeight: 600,
    color: "#000",
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000",
  },
});

const Tank = (props) => {
  const classes = useStyles(props);
  const { data, text, heating } = props;

  const heatIcon = (
    <SvgIcon className={classes.icon} viewBox="0 0 16.378 17">
      <g
        id="Group_255"
        data-name="Group 255"
        transform="translate(-279.342 -401.6)"
      >
        <g
          id="Group_254"
          data-name="Group 254"
          transform="translate(279.342 401.6)"
        >
          <path
            id="Path_165"
            data-name="Path 165"
            d="M294.87,418.6a6.806,6.806,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574a6.805,6.805,0,0,1,2.752,4c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3Z"
            transform="translate(-286.51 -401.6)"
          />
          <path
            id="Path_166"
            data-name="Path 166"
            d="M310.036,405.6c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3l-1.5,1.574a6.805,6.805,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574A6.806,6.806,0,0,1,310.036,405.6Z"
            transform="translate(-293.735 -401.6)"
          />
          <path
            id="Path_167"
            data-name="Path 167"
            d="M284.536,405.6c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3l-1.5,1.574a6.805,6.805,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574A6.806,6.806,0,0,1,284.536,405.6Z"
            transform="translate(-279.342 -401.6)"
          />
        </g>
      </g>
    </SvgIcon>
  );

  const waterIcon = (
    <SvgIcon className={classes.icon} viewBox="0 0 12 16.46">
      <path
        id="icon_water"
        d="M12,3.77l-.75.84A38.7,38.7,0,0,0,8.68,7.94C7.39,9.82,6,12.07,6,14.23a6,6,0,0,0,12,0c0-2.16-1.39-4.41-2.68-6.29a38.7,38.7,0,0,0-2.57-3.33L12,3.77M12,6.9a21.327,21.327,0,0,1,1.68,2.17c1.21,1.76,2.32,4,2.32,5.16a4,4,0,1,1-8,0c0-1.16,1.11-3.4,2.32-5.16A21.327,21.327,0,0,1,12,6.9Z"
        transform="translate(-6 -3.77)"
      />
    </SvgIcon>
  );

  return (
    <div className={classes.tank} style={props.style}>
      <div className={classes.iconContainer}>
        {heating ? heatIcon : waterIcon}
      </div>
      <Typography align="center" className={classes.data}>
        {!isNil(data) && !isNil(data.value) ? (
          <Tooltip title={!isNil(data.time) ? data.time : ""}>
            <span>{`${data.value} °C`}</span>
          </Tooltip>
        ) : null}
      </Typography>
      <Typography align="center" className={classes.text}>
        {text}
      </Typography>
      {props.children}
    </div>
  );
};

export default Tank;
