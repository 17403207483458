import React from "react";
import { Edit, FunctionField, NumberInput, SimpleForm } from "react-admin";
import { setConsumptionUnitText } from "../utils/unitTextParsers";
import { MonthInput } from "../components/TimeInputs";
import PageTitle from "../components/layout/PageTitle";

export const ConsumptionListsEdit = ({ Building, ...props }) => {
  let editProps = Object.assign({}, props);
  delete editProps.buildingTwinId;
  delete editProps.buildingSelected;
  delete editProps.dispatch;
  return (
    <>
      <PageTitle title="Edit Consumption" />
      <Edit {...editProps}>
        <SimpleForm submitOnEnter={false}>
          <FunctionField
            label="Type"
            render={(record) => setConsumptionUnitText(record.Type)}
          />
          <NumberInput source="Consumption" />
          <MonthInput source="Time" label="Date" />
        </SimpleForm>
      </Edit>
    </>
  );
};
