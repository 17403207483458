import React from "react";

import DigitalTwinsContainer from "../dashboard/digitaltwins/DigitalTwinsContainer";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: '100%'
  },
  container: {
    height: '100%'
  },
  item: {},
});

const AhuZones = (props) => {
  const classes = useStyles();

  return props.zones ? (
    <DigitalTwinsContainer
      className={classes.container}
      ahuMap={props.ahuMap}
      twinsToRight
      twins={props.zones}
    />
  ) : null;
};

export default AhuZones;
