import React, { useState, Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { toggleSidebar } from "ra-core";

import { UserMenu } from "ra-ui-materialui";
import { HideOnScroll } from "ra-ui-materialui";
import ProfileMenu from "./ProfileMenu";
import FeedbackForm from "../FeedbackForm";
import useUserRole from "../../utils/hooks/useUserRole";
import BugForm from "../BugForm";
import { useAuthProvider } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      boxSizing: "border-box",
      paddingRight: 24,
      height: 60,
      backgroundColor: "#fff",
    },
    menuButton: {
      marginLeft: "0.2em",
      marginRight: "0.2em",
      color: "#000",
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(0deg)",
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(180deg)",
    },
    title: {
      display: "none",
      color: "#000",
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }),
  { name: "LGAppBar" }
);

const CustomAppBar = ({
  children,
  classes: classesOverride,
  className,
  logo,
  logout,
  open,
  title,
  userMenu,
  ...rest
}) => {
  const classes = useStyles({ classes: classesOverride });
  const [fbDialogOpen, setFbDialogOpen] = useState(false);
  const [bugDialogOpen, setBugDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { isExternalUser } = useUserRole();

  const [userName, setUserName] = useState('');
  const authProvider = useAuthProvider();
  authProvider.getIdentity().then(user => {
    setUserName(user.fullName);
  });

  const handleFbClickOpen = () => {
    setFbDialogOpen(true);
  };

  const handleFbClose = () => {
    setFbDialogOpen(false);
  };

  const handleBugClickOpen = () => {
    setBugDialogOpen(true);
  };

  const handleBugClose = () => {
    setBugDialogOpen(false);
  };

  return (
    <>
      <HideOnScroll>
        <MuiAppBar className={className} color="secondary" {...rest}>
          <Toolbar
            disableGutters
            variant={isXSmall ? "regular" : "dense"}
            className={classes.toolbar}
          >
            {isMedium ? (
              <IconButton
                aria-label="open drawer"
                onClick={() => dispatch(toggleSidebar())}
                className={classNames(classes.menuButton)}
              >
                <MenuIcon
                  classes={{
                    root: open
                      ? classes.menuButtonIconOpen
                      : classes.menuButtonIconClosed,
                  }}
                />
              </IconButton>
            ) : null}
            {cloneElement(userMenu)}
            {Children.count(children) === 0 ? (
              <Typography
                variant="h6"
                className={classes.title}
                id="react-admin-title"
              />
            ) : (
              children
            )}
            {/* <LoadingIndicator /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginLeft: "auto",
              }}
            >
              {!isMedium && !isExternalUser ? (
                <Button
                  variant="contained"
                  onClick={handleBugClickOpen}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Report a bug
                </Button>
              ) : null}
              {isMedium ? null : (
                <Button
                  variant="contained"
                  style={{ marginLeft: "15px", whiteSpace: "nowrap" }}
                  onClick={handleFbClickOpen}
                >
                  Send Feedback
                </Button>
              )}
            </div>
            {isXSmall ? null : (
              <ProfileMenu userName={userName} />
            )}
          </Toolbar>
        </MuiAppBar>
      </HideOnScroll>
      <FeedbackForm open={fbDialogOpen} onClose={handleFbClose} />
      {!isExternalUser && <BugForm open={bugDialogOpen} onClose={handleBugClose} />}
    </>
  );
};

CustomAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

CustomAppBar.defaultProps = {
  userMenu: <UserMenu />,
};

export default CustomAppBar;
