// Check graph data to see if data series actually exists
const checkGraphData = ({ data, ignoreTargets = true }) => {
  if (data && data.series && data.series.length > 0) {
    // Check if only target series is ok
    if (ignoreTargets) {
      let dataSeriesExists = false;
      data.series.forEach((s) => {
        if (s && s.name && typeof s.name === "string") {
          if (!s.name.toLowerCase().includes("target")) {
            dataSeriesExists = true;
          }
        } else {
          dataSeriesExists = true;
        }
      });
      if (dataSeriesExists) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};

export default checkGraphData;
