import React, { useEffect, useState } from "react";
import Spinner from "../../components/layout/Spinner";
import { makeStyles } from "@material-ui/core";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import EmptyData from "../../components/layout/EmptyData";
import usePurchasedDataMapper from "../datamappers/purchased/usePurchasedDataMapper";
import useTargetDataMapper from "../datamappers/purchased/useTargetDataMapper";
import graphOptions from "../datamappers/purchased/graphOptions";
import useTemperatureDataMapper from "../datamappers/purchased/useTemperatureDataMapper";
import checkGraphData from "../../utils/checkGraphData";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    height: "28rem",
    display: "flex",
    alignItems: "center",
  },
}));

const PurchasedGraph = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [consumptionData, consumptionRaw] = usePurchasedDataMapper(
    props.type,
    props.value
  );
  const targetData = useTargetDataMapper(props.type, props.value, props.target);
  const temperatureData = useTemperatureDataMapper({
    consumption: consumptionRaw,
    type: props.type,
  });

  useEffect(() => {
    let graphOpt = graphOptions(
      consumptionData,
      props.target ? targetData : null,
      props.temperature ? temperatureData : null,
      props.type,
      props.value,
      true
    );
    setGraphData(graphOpt);
    setLoading(false);
  }, [
    consumptionData,
    targetData,
    temperatureData,
    props.type,
    props.value,
    props.target,
    props.temperature,
  ]);

  if (loading) {
    return <Spinner style={{ height: "25rem" }} />;
  }

  let retVal = (
    <div className={classes.emptyContainer}>
      <EmptyData />
    </div>
  );

  if (checkGraphData({ data: graphData })) {
    retVal = <HighchartsReact highcharts={Highcharts} options={graphData} />;
    if (props.showGraph) {
      props.showGraph(props.type);
    }
  } else {
    if (props.hideGraph) {
      props.hideGraph(props.type);
    }
  }

  return retVal;
};

export default PurchasedGraph;
