import { useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useDispatch } from "react-redux";
import { setBuildingTrends } from "../customReducers/actions";

function useBuildingTrends(bTwin, bId) {
  const dispatch = useDispatch();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    let metaIds = [];
    let metaLegends = [];
    if (bId && bTwin && bTwin.Trends && bTwin.Trends.length > 0) {
      bTwin.Trends.forEach((t) => {
        if (t && t.Values && t.Values.length > 0) {
          t.Values.forEach((v) => {
            if (v.ReportingPointId) {
              metaIds.push(v.ReportingPointId);
              metaLegends.push({
                id: v.ReportingPointId,
                legend: v.Legend,
                variableName: v.VariableName,
                trendId: t.TrendId,
              });
            }
          });
        }
      });

      dataProvider
        .getList("reportdata", {
          filter: {
            buildingId: bId,
            metaIds: metaIds,
            resolution: "month",
            v2: true,
          },
          sort: { field: "UpdateTime", order: "ASC" },
          pagination: {},
        })
        .then((res) => {
          if (res) {
            const { data } = res;
            let trendData = [];
            if (
              data &&
              data.values &&
              data.values.length > 0 &&
              metaLegends &&
              metaLegends.length > 0
            ) {
              data.values.forEach((d) => {
                metaLegends.forEach((m) => {
                  if (d.metaId === m.id) {
                    trendData.push({
                      ...d,
                      legend: m.legend,
                      variableName: m.variableName,
                      trendId: m.trendId,
                    });
                  }
                });
              });
            }
            dispatch(setBuildingTrends(trendData));
          }
        })
        .catch((error) => {
          notify(error.message, "warning");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bTwin, bId, notify, dispatch]);

  return null;
}

export default useBuildingTrends;
