import { useMsal } from '@azure/msal-react';
import { useCallback } from "react";

interface ICustomFetchParams {
  url: string;
  method?: "GET" | "POST" | "UPDATE" | "PUT" | "DELETE";
  headers?: { name: string, value: any }[];
  body?: any;
}

export default function useCustomFetch() {
  const { instance: msalInstance } = useMsal();
  const customFetch = useCallback(
    async ({ url, method = "GET", body = null, headers = [] }: ICustomFetchParams) => {
      let token;
      try {
        token = await msalInstance.acquireTokenSilent({
          scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
        });
      } catch (err) {
        msalInstance.loginRedirect();
        return;
      }

      const myHeaders = new Headers({
        "Authorization": "Bearer " + token.accessToken
      });
      headers.forEach((h) => myHeaders.append(h.name, h.value));

      const requestOptions = {
        method: method,
        headers: myHeaders,
        body: body,
      };
      return fetch(process.env.REACT_APP_API_URL + "/" + url, requestOptions);
    },
    [msalInstance]
  );

  return customFetch;
}