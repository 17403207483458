import { find, groupBy, filter, sum, round } from "lodash";
import raReportingDataToHighchartsData from "../consumed/raReportingDataToHighChartsData";
import graphColor from "../../../utils/graphColor";

const graphOptionsElec = (buildingTrends, solarData) => {
  let data = [];
  let solarTrends = [];
  if (solarData) {
    let k = Object.keys(solarData);
    k.forEach((k) => {
      if (k !== "trendData") {
        data.push(solarData[k]);
      } else {
        solarTrends.push(solarData[k]);
      }
    });
  }
  let targets = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  // Sum all solar monthly targets
  if (data && data.length > 0) {
    data.forEach((s) => {
      if (s && s.Solar && s.Solar.MonthlyTargets) {
        s.Solar.MonthlyTargets.forEach((m) => {
          targets[m.Month - 1] += round(m.Target, 2);
        });
      }
    });
  }

  let filteredTrends = [];
  filteredTrends = filter(
    buildingTrends,
    (t) => t.trendId === "BUILDING_SOLAR_ENERGY"
  );

  const grouped = groupBy(filteredTrends, "metaId");
  const keys = Object.keys(grouped);
  let series = [];

  let years = null;
  keys.forEach((k) => {
    const groupedByYear = groupBy(grouped[k], "year");
    years = Object.keys(groupedByYear);
    let index = 0;
    years.forEach((y) => {
      let trendValue = find(filteredTrends, (v) => v.metaId === Number(k));
      if (trendValue && trendValue.legend) {
        series.push({
          name: y,
          showInLegend: true,
          data: raReportingDataToHighchartsData(groupedByYear[y], "solar"),
          color: graphColor({
            index: index,
            seriesLength: years.length,
            type: "electricity",
          }),
        });
      }
      index++;
    });
  });

  // Target data
  if (sum(targets) > 0) {
    series.push({
      name: "Monthly target",
      data: targets,
      type: "line",
      color: graphColor({
        index: 0,
        seriesLength: 1,
        lineSeries: true,
        type: "electricity",
      }),
    });
  }

  if (!series || series.length === 0) {
    return [];
  }

  return {
    chart: {
      type: "column",
    },
    legend: {
      enabled: true,
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: "Building solar energy",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: series,
    yAxis: {
      min: 0,
      title: {
        text: "Building solar energy (KWh)",
      },
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
  };
};

export default graphOptionsElec;
