import React, { useState, useEffect } from "react";
import { useMutation, useDataProvider } from "react-admin";
import { makeStyles, Button, IconButton } from "@material-ui/core";
import { find } from "lodash";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "2.3125rem",
    marginRight: "1rem",
    border: "1px solid #D1D7DB",
    borderRadius: "2px",
  },
  label: {
    textTransform: "none",
    color: "#7D7A83",
  },
}));

const FavoriteButton = (props) => {
  const dataProvider = useDataProvider();
  const [favData, setFavData] = useState();
  const [favoriteAdded, setFavoriteAdded] = useState();
  const [favoriteRemoved, setFavoriteRemoved] = useState();
  const classes = useStyles();

  // Mount
  useEffect(() => {
    dataProvider
      .getList("user/favorites", {
        sort: { field: "ProjectName", order: "ASC" },
        pagination: {},
      })
      .then(({ data }) => {
        setFavData(data);
      });
  }, [dataProvider]);

  // Update
  useEffect(() => {
    if (favoriteAdded || favoriteRemoved) {
      dataProvider
        .getList("user/favorites", {
          sort: { field: "ProjectName", order: "ASC" },
          pagination: {},
        })
        .then(({ data }) => {
          setFavData(data);
          setFavoriteAdded(false);
          setFavoriteRemoved(false);
        });
    }
  });

  // Check if this building is favorited by user
  const favorite = find(favData, (f) => {
    return f.SourceId === props.buildingId;
  });
  const favorited = favorite ? true : false;

  let [addFavorite] = useMutation(
    {
      type: "update",
      resource: "user",
      payload: {
        id: "favorites",
        data: {
          Id: props.buildingId,
          SourceId: props.buildingId,
          FavoriteType: 1,
        },
      },
    },
    {
      onSuccess: () => {
        setFavoriteAdded(true);
      },
    }
  );

  let removeFavorite = null;

  [removeFavorite] = useMutation(
    {
      type: "delete",
      resource: "user/favorites",
      payload: {
        id: favorite && favorite.Id ? favorite.Id : null,
      },
    },
    {
      onSuccess: () => {
        setFavoriteRemoved(true);
      },
    }
  );

  return props.isMobile ? (
    <IconButton>{favorited ? <StarIcon /> : <StarBorderIcon />}</IconButton>
  ) : (
    <Button
      onClick={favorited ? removeFavorite : addFavorite}
      classes={{ root: classes.root, label: classes.label }}
      variant="outlined"
      startIcon={favorited ? <StarIcon /> : <StarBorderIcon />}
    >
      {favorited ? "Remove" : "Add"}
    </Button>
  );
};

export default FavoriteButton;
