import React, { useEffect } from "react";

import { AutocompleteInput, NumberInput, ReferenceInput } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import optionRenderer from "../utils/optionRenderer";

import { useForm } from "react-final-form";

const TooltipTitle = "Check Scaling! m³/s=1, l/s=0.001, m³/h => 2.78e-4 etc...";

const hasAirFlow = (ahuState) => {
  return !!(
    ahuState.SupplyAirFlow?.BuildingPointId ||
    ahuState.SupplyAirControlInput?.BuildingPointId ||
    ahuState.SupplyAirPressureInput?.BuildingPointId
  );
};

const buildingPointsOptionRenderer = (choice) => {
  if (!choice) return "";
  else if (
    choice &&
    choice.PointId === null &&
    choice.Description === undefined
  )
    return "(Empty)";
  let PointId = "";
  if (choice.PointId) {
    PointId = choice.PointId;
  }
  return `${choice.Description || ""} ${PointId || ""} (${choice.PointSource || ""})`;
};

export const AhuInputs = (formData) => {
  const form = useForm();

  const formState = form.getState();
  let ahuState = null;
  if (formState && formState.values && formState.values.Ahu) {
    ahuState = formState.values.Ahu;
  }

  // Each time the form is changed, adjust the ahu operations field values depending on the value of some of the inputs
  useEffect(() => {
    const handleChange = (key) => {
      let value = true;
      switch (key) {
        case "AhuOperations.HeatingRecoveryUnitEfficiency.Active":
          if (!ahuState.AfterHRTE || !ahuState.AfterHRTE.BuildingPointId) {
            value = false;
          }
          if (!ahuState.ReturnAirTe || !ahuState.ReturnAirTe.BuildingPointId) {
            value = false;
          }
          form.change(
            "AhuOperations.HeatingRecoveryUnitEfficiency.Active",
            value
          );
          break;

        case "AhuOperations.HeatingPower.Active":
          if (!ahuState.AfterHRTE || !ahuState.AfterHRTE.BuildingPointId) {
            value = false;
          }
          if (!ahuState.SupplyAirTE || !ahuState.SupplyAirTE.BuildingPointId) {
            value = false;
          }
          if (!hasAirFlow(ahuState)) {
            value = false;
          }
          form.change("AhuOperations.HeatingPower.Active", value);
          break;

        case "AhuOperations.HeatingPowerIntegral.Active":
          if (!ahuState.AfterHRTE || !ahuState.AfterHRTE.BuildingPointId) {
            value = false;
          }
          if (!ahuState.SupplyAirTE || !ahuState.SupplyAirTE.BuildingPointId) {
            value = false;
          }
          if (!hasAirFlow(ahuState)) {
            value = false;
          }
          form.change("AhuOperations.HeatingPowerIntegral.Active", value);
          break;

        case "AhuOperations.HeatingRecoveryHeatingPower.Active":
          if (!ahuState.AfterHRTE || !ahuState.AfterHRTE.BuildingPointId) {
            value = false;
          }
          if (!hasAirFlow(ahuState)) {
            value = false;
          }
          form.change(
            "AhuOperations.HeatingRecoveryHeatingPower.Active",
            value
          );
          break;

        case "AhuOperations.HeatingRecoveryHeatingPowerIntegral.Active":
          if (!ahuState.AfterHRTE || !ahuState.AfterHRTE.BuildingPointId) {
            value = false;
          }
          if (!hasAirFlow(ahuState)) {
            value = false;
          }
          form.change(
            "AhuOperations.HeatingRecoveryHeatingPowerIntegral.Active",
            value
          );
          break;

        case "AhuOperations.SupplyAirFlowIntegral.Active":
          if (!hasAirFlow(ahuState)) {
            value = false;
          }
          form.change("AhuOperations.SupplyAirFlowIntegral.Active", value);
          break;

        case "AhuOperations.RunningStatusInfo.Active":
          if (!hasAirFlow(ahuState)) {
            value = false;
          }
          form.change("AhuOperations.RunningStatusInfo.Active", value);
          break;

        default:
          break;
      }
    };

    if (ahuState) {
      const operations = [
        "AhuOperations.HeatingRecoveryUnitEfficiency.Active",
        "AhuOperations.HeatingPower.Active",
        "AhuOperations.HeatingPowerIntegral.Active",
        "AhuOperations.HeatingRecoveryHeatingPower.Active",
        "AhuOperations.HeatingRecoveryHeatingPowerIntegral.Active",
        "AhuOperations.SupplyAirFlowIntegral.Active",
        "AhuOperations.RunningStatusInfo.Active",
      ];
      operations.forEach((o) => {
        handleChange(o);
      });
    }
  }, [ahuState, form]);

  return (
    <div style={{ display: "block", width: "100%" }}>
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="Fresh air TE (if left empty IL Weather will be used)"
          source="Ahu.FreshAirTE.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          //    perPage={10}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>
      <div style={{ display: "block" }} />
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="Before HR TE (if left empty Fresh air TE or IL Weather will be used)"
          source="Ahu.BeforeHRTE.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          //   perPage={10}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="Heat recovery control %"
          source="Ahu.HeatRecoveryControlInput.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>

      <div style={{ display: "block" }} />
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="After HR TE"
          source="Ahu.AfterHRTE.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          //perPage={10}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>

      <ReferenceInput
        label="TE between heating and cooling coils"
        source="Ahu.TEBetweenHeatingAndCoolingCoilInput.BuildingPointId"
        reference="buildingpoints"
        filter={{
          BuildingId: formData.BuildingId,
          ShowPoint: "BlockInputsOnly",
          PointSources: ["RAU", "LTS", "FUNCTION"],
        }}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={buildingPointsOptionRenderer}
          optionValue="uid"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Supply fan running status"
        source="Ahu.SupplyStatusInput.BuildingPointId"
        reference="buildingpoints"
        filter={{
          BuildingId: formData.BuildingId,
          ShowPoint: "BlockInputsOnly",
          PointSources: ["RAU", "LTS", "FUNCTION"],
        }}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={buildingPointsOptionRenderer}
          optionValue="uid"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <div style={{ display: "block" }} />
      {formData.Ahu &&
        formData.Ahu.SupplyAirFlowCalculationMethod === "MeasuredAirFlow" ? (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <ReferenceInput
            label="Supply air flow"
            source="Ahu.SupplyAirFlow.BuildingPointId"
            reference="buildingpoints"
            filter={{
              BuildingId: formData.BuildingId,
              ShowPoint: "BlockInputsOnly",
              PointSources: ["RAU", "LTS", "FUNCTION"],
            }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={buildingPointsOptionRenderer}
              optionValue="uid"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling"
            source="Ahu.SupplyAirFlow.ScalingFactor"
            defaultValue={1.0}
            style={{
              marginLeft: "20px",
            }}
          />
          <Tooltip title={TooltipTitle} placement="right-end">
            <HelpIcon style={{ margin: "auto" }} />
          </Tooltip>
        </div>
      ) : null}
      {formData.Ahu &&
        formData.Ahu.SupplyAirFlowCalculationMethod ===
        "CalculatedFromControlPercent" ? (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <ReferenceInput
            label="Supply control %"
            source="Ahu.SupplyAirControlInput.BuildingPointId"
            reference="buildingpoints"
            filter={{
              BuildingId: formData.BuildingId,
              ShowPoint: "BlockInputsOnly",
              PointSources: ["RAU", "LTS", "FUNCTION"],
            }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={buildingPointsOptionRenderer}
              optionValue="uid"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
        </div>
      ) : null}

      {formData.Ahu &&
        formData.Ahu.SupplyAirFlowCalculationMethod ===
        "CalculatedFromPressure" ? (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <ReferenceInput
            label="Supply air pressure"
            source="Ahu.SupplyAirPressureInput.BuildingPointId"
            reference="buildingpoints"
            filter={{
              BuildingId: formData.BuildingId,
              ShowPoint: "BlockInputsOnly",
              PointSources: ["RAU", "LTS", "FUNCTION"],
            }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={buildingPointsOptionRenderer}
              optionValue="uid"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
        </div>
      ) : null}

      <div style={{ display: "block" }} />
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="Supply air TE"
          source="Ahu.SupplyAirTE.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>
      <div style={{ display: "block" }} />
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="Return air TE"
          source="Ahu.ReturnAirTe.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>
      <ReferenceInput
        label="Return fan running status"
        source="Ahu.ReturnStatusInput.BuildingPointId"
        reference="buildingpoints"
        filter={{
          BuildingId: formData.BuildingId,
          ShowPoint: "BlockInputsOnly",
          PointSources: ["RAU", "LTS", "FUNCTION"],
        }}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={buildingPointsOptionRenderer}
          optionValue="uid"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <div style={{ display: "block" }} />
      {formData.Ahu &&
        formData.Ahu.ReturnAirFlowCalculationMethod === "MeasuredAirFlow" ? (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <ReferenceInput
            label="Return air flow"
            source="Ahu.ReturnAirFlow.BuildingPointId"
            reference="buildingpoints"
            filter={{
              BuildingId: formData.BuildingId,
              ShowPoint: "BlockInputsOnly",
              PointSources: ["RAU", "LTS", "FUNCTION"],
            }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={buildingPointsOptionRenderer}
              optionValue="uid"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling"
            source="Ahu.ReturnAirFlow.ScalingFactor"
            defaultValue={1.0}
            style={{
              marginLeft: "20px",
            }}
          />
          <Tooltip title={TooltipTitle} placement="right-end">
            <HelpIcon style={{ margin: "auto" }} />
          </Tooltip>
        </div>
      ) : null}
      {formData.Ahu &&
        formData.Ahu.ReturnAirFlowCalculationMethod ===
        "CalculatedFromControlPercent" ? (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <ReferenceInput
            label="Return control %"
            source="Ahu.ReturnAirControlInput.BuildingPointId"
            reference="buildingpoints"
            filter={{
              BuildingId: formData.BuildingId,
              ShowPoint: "BlockInputsOnly",
              PointSources: ["RAU", "LTS", "FUNCTION"],
            }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={buildingPointsOptionRenderer}
              optionValue="uid"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
        </div>
      ) : null}

      {formData.Ahu &&
        formData.Ahu.ReturnAirFlowCalculationMethod ===
        "CalculatedFromPressure" ? (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <ReferenceInput
            label="Return air pressure"
            source="Ahu.ReturnAirPressureInput.BuildingPointId"
            reference="buildingpoints"
            filter={{
              BuildingId: formData.BuildingId,
              ShowPoint: "BlockInputsOnly",
              PointSources: ["RAU", "LTS", "FUNCTION"],
            }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={buildingPointsOptionRenderer}
              optionValue="uid"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
        </div>
      ) : null}
      <div style={{ display: "block" }} />
      <div style={{ display: "inline-flex", width: "100%" }}>
        <ReferenceInput
          label="Exhaust air TE"
          source="Ahu.ExhaustAirTE.BuildingPointId"
          reference="buildingpoints"
          filter={{
            BuildingId: formData.BuildingId,
            ShowPoint: "BlockInputsOnly",
            PointSources: ["RAU", "LTS", "FUNCTION"],
          }}
          allowEmpty={true}
        >
          <AutocompleteInput
            optionText={buildingPointsOptionRenderer}
            optionValue="uid"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </div>
    </div>
  );
};
