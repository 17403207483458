import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import memberIcon from "../../assets/icons/icon_person.svg";
import phoneIcon from "../../assets/icons/icon_phone.svg";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    border: "1px solid #D1D7DB",
    borderRadius: "2px",
    color: "#282331",
    padding: "0 0.5em",
  },
  title: {
    fontWeight: 300,
    fontSize: "0.75em",
    opacity: "0.8",
  },
  icon: {
    width: "1em",
    height: "1em",
    opacity: "0.8",
    marginRight: "0.75em",
    fontSize: "1em",
  },
  text: {
    fontSize: "1em",
  },
  lineContainer: {
    display: "flex",
    alignItems: "center",
    height: "25%",
    margin: "0.3em",
  },
}));

const TeamMember = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.lineContainer}>
        <Typography className={classes.title}>
          {props.title ? props.title : "Unknown role"}
        </Typography>
      </div>
      <div className={classes.lineContainer}>
        <img className={classes.icon} src={memberIcon} alt="member" />
        <Typography className={classes.text}>
          {props.name ? props.name : "-"}
        </Typography>
      </div>
      {!props.isExternal && (
        <div className={classes.lineContainer}>
          <img className={classes.icon} src={phoneIcon} alt="phone" />
          <Typography className={classes.text}>
            {props.number ? props.number : "-"}
          </Typography>
        </div>
      )}
      <div className={classes.lineContainer}>
        <MailOutlineIcon
          className={classes.icon}
          classes={{ root: classes.icon }}
        />
        <Typography
          className={classes.text}
          style={{ overflow: "hidden" }}
          title={props.email}
        >
          {props.email ? (
            <a href={`mailto:${props.email}`}>{props.email}</a>
          ) : (
            "-"
          )}
        </Typography>
      </div>
    </div>
  );
};

export default TeamMember;
