import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import optionRenderer from "../utils/optionRenderer";

export const HxInputs = formData => (
  <div style={{ display: "block", width: "100%" }}>
    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Primary supply TE"
        source="Hx.PrimarySupplyTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />

    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Primary Return TE"
        source="Hx.PrimaryReturnTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />

    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Control valve %"
        source="Hx.ControlValvePercentage.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />

    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Secondary supply TE"
        source="Hx.SecondarySupplyTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />

    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Secondary Return TE"
        source="Hx.SecondaryReturnTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />
  </div>
);
