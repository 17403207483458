import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxHeight: "3.5rem",
    borderRadius: "15px",
    border: "1px solid",
    borderColor: (props) => (props.alert ? "#D62B1A" : "#282331"),
    boxShadow: (props) =>
      props.alert ? "0px 3px 10px #D62B1A33" : "0px 3px 10px #28233133",
    backgroundColor: "#FFF",
    justifyContent: (props) => props.ahuMap && "center",
    "&:hover": {
      backgroundColor: (props) => (props.disableLink ? undefined : "#DDD"),
    },
  },
  status: {
    maxHeight: "3.5rem",
    minWidth: "4rem",
    background: (props) =>
      props.alert
        ? "#D62B1A 0% 0% no-repeat padding-box"
        : "transparent radial-gradient(closest-side at 50% 50%, #282331 0%, #282331 100%) 0% 0% no-repeat padding-box",
    borderRadius: "15px 0 0 15px",
    color: "#FFFFFF",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    maxHeight: "3.5rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  text: {
    color: (props) => (props.alert ? "#D62B1A" : "#282331"),
    fontSize: (props) => (props.ahuMap ? "0.75rem" : "0.875rem"),
    fontWeight: 600,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

const ButtonWrapper = ({ children, disableLink, id }) =>
  disableLink ? (
    <div>{children}</div>
  ) : (
    <RouterLink to={`/blocks/${id}/show`} style={{ textDecoration: "none" }}>
      {children}
    </RouterLink>
  );

const DigitalTwin = (props) => {
  const classes = useStyles(props);

  return (
    <ButtonWrapper disableLink={props.disableLink} id={props.id}>
      <Grid container wrap="nowrap" spacing={2} className={classes.root}>
        {!props.ahuMap && (
          <Grid item className={classes.status}>
            {props.status ? props.status : null}
          </Grid>
        )}
        <Grid item className={classes.textContainer}>
          <Typography className={classes.text}>
            {props.name ? props.name : "Unknown twin"}
          </Typography>
          {!props.ahuMap && (
            <Typography className={classes.text} style={{ color: "#7E7B84" }}>
              {props.desc ? props.desc : ""}
            </Typography>
          )}
        </Grid>
      </Grid>
    </ButtonWrapper>
  );
};

export default DigitalTwin;
