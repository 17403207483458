import React, { useState, cloneElement, useMemo, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  SelectInput,
  Filter,
  TextInput,
  Pagination,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  useNotify,
} from "react-admin";
import { makeStyles, Button } from "@material-ui/core";
import { stateMapper } from "../customReducers/connectFunctions";
import classnames from "classnames";

import ValueStorageListGraph from "../valuestorage/ValueStorageListGraph";
import PageTitle from "../components/layout/PageTitle";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import { EditButton, FunctionField } from "ra-ui-materialui";
import { useDataProvider } from "ra-core";
import { isNil } from "lodash";
import moment from "moment";
import useUserRole from "../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  toolBar: {
    "& .MuiToolbar-root form": {
      transition: "margin-bottom .5s ease",
    },
  },
  toolBarMargin: {
    "& .MuiToolbar-root form": {
      marginBottom: 60,
    },
  },
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const FunctionFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" className={classes.helperText} />
      <SelectInput
        label="Source"
        source="PointSource"
        choices={[
          { id: "LTS", name: "LTS" },
          { id: "OVA", name: "OVA" },
          { id: "RAU", name: "RAU" },
          { id: "SIGFOX", name: "SIGFOX" },
          { id: "FUNCTION", name: "FUNCTION" },
        ]}
        className={classes.helperText}
        alwaysOn
      />
    </Filter>
  );
};

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const BuildingPointListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const [ovaLoading, setOvaLoading] = useState(false);
  const customFetch = useCustomFetch();
  const notify = useNotify();
  let customRest = Object.assign({}, rest);
  delete customRest.hasList;
  delete customRest.hasEdit;
  delete customRest.hasShow;

  const handleOvaUpdate = async () => {
    try {
      setOvaLoading(true);
      await customFetch({ url: "buildingpoints/updateovaport" });
      notify("Updated");
    } catch (error) {
      notify(error, "warning");
    } finally {
      setOvaLoading(false);
    }
  };

  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(customRest)}>
        <Button
          size="small"
          color="primary"
          disabled={ovaLoading}
          onClick={handleOvaUpdate}
        >
          Update OVA
        </Button>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            maxResults={maxResults}
          />
        )}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const BuildingPointList = ({ BuildingId, projectname, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  const [open, setOpen] = useState(false);
  // Bulk active is used to change margin on the form
  const [bulkActive, setBulkActive] = useState(false);
  const [singleId, setSingleId] = useState();

  let selectedIds = [];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSingleId(null);
  };

  const ValueStorageBulkActions = (props) => {
    selectedIds = props.selectedIds;

    // Toggle bulk active depending on selected id's
    if (selectedIds && selectedIds.length > 0) {
      if (!bulkActive) {
        setBulkActive(true);
      }
    } else if (bulkActive) {
      setBulkActive(false);
    }

    return (
      <Button variant="contained" onClick={handleClickOpen}>
        Open graph
      </Button>
    );
  };

  const ListGrid = (props) => {
    const { isAdmin } = useUserRole();
    const [valueStorageData, setValueStorageData] = useState([]);

    useEffect(() => {
      const pagePointIds = new Set(Object.values(props.data).map(v => v.PointId));
      const pagePointIdList = Array.from(pagePointIds);
      if (!!pagePointIdList && pagePointIdList.length > 0) {
        dataProvider
          .getList("valuestorage/latest", {
            filter: {
              BuildingId,
              VariableNames: pagePointIdList
            },
            sort: {},
            pagination: {},
          })
          .then((res) => {
            if (!!res) {
              const { data } = res;
              setValueStorageData(prev => [...data, ...prev]);
            }
          });
      }
    }, [props.data]);
    return (
      <>
        <Datagrid
          {...{ ...props }}
          rowClick={(id, _basePath, record) => {
            setOpen(true);
            setSingleId([id]);
          }}
          isRowSelectable={(record) =>
            record.PointSource === "RAU" ||
            record.PointSource === "OVA" ||
            record.PointSource === "SIGFOX" ||
            record.PointSource === "LTS"
          }
        >
          <TextField source="id" />
          <TextField source="PointId" />
          <TextField source="Description" />
          <TextField source="PointSource" />
          <FunctionField label="Updated" render={record => {
            const latestPointMeasurement = valueStorageData
              .sort((a, b) => new Date(b.UpdateTime).getTime() - new Date(a.UpdateTime).getTime())
              .find(vs => vs.VariableName === record.PointId);
            if (!isNil(latestPointMeasurement)) {
              const updateTime = new Date(latestPointMeasurement.UpdateTime);
              const now = new Date();
              const formattedTime = updateTime.getDate() === now.getDate()
                ? `Today ${moment(updateTime).format("HH:mm")}`
                : moment(updateTime).format("DD.MM.YYYY HH:mm")
              return formattedTime;
            } else {
              return `-`;
            }
          }} />
          <FunctionField label="Latest value" render={record => {
            const latestPointMeasurement = valueStorageData
              .sort((a, b) => new Date(b.UpdateTime).getTime() - new Date(a.UpdateTime).getTime())
              .find(vs => vs.VariableName === record.PointId);
            if (!isNil(latestPointMeasurement?.AggregatedValue)) {
              return latestPointMeasurement?.AggregatedValue?.toFixed(2)
            } else if (!isNil(latestPointMeasurement?.PreciseValue)) {
              return latestPointMeasurement?.PreciseValue?.toFixed(2)
            } else {
              return `-`;
            }
          }} />
          <TextField source="Unit" />
          {isAdmin && <FunctionField render={record => {
            return record.PointSource === 'SIGFOX'
              ? <EditButton basePath="/buildingpoints" record={record} />
              : <div style={{ height: '2.1em' }}></div>
          }} />}
        </Datagrid>
        <ValueStorageListGraph
          buildingPoints
          selectedIds={selectedIds}
          singleId={singleId}
          data={props.data}
          open={open}
          onClose={handleClose}
          buildingId={BuildingId}
        />
      </>
    );
  };

  return (
    <>
      <PageTitle title="Buildingpoints" />
      <List
        {...listProps}
        className={classnames(
          classes.toolBar,
          bulkActive && classes.toolBarMargin
        )}
        perPage={25}
        pagination={<ValuesPagination />}
        filter={{ BuildingId }}
        filters={<FunctionFilter />}
        bulkActionButtons={<ValueStorageBulkActions />}
        actions={<BuildingPointListActions {...listProps} maxResults={10000} />}
      >
        <ListGrid />
      </List>
    </>
  );
};

export default stateMapper(BuildingPointList);
