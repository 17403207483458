import React from "react";

import CardWithTitle from "../components/layout/CardWithTitle";
import TwinInputStatus from "./TwinInputStatus";

import { makeStyles } from "@material-ui/core";
import { find } from "lodash";
import hxBg from "../assets/images/hxBG.png";

const useStyles = makeStyles({
  root: {
    overflowX: "auto",
    textAlign: "center",
  },
  container: {
    position: "relative",
    display: "inline-block",
  },
  image: {
    zIndex: 1,
  },
  primarySupply: {
    position: "absolute",
    top: 145,
    left: 158,
  },
  primaryReturn: {
    position: "absolute",
    top: 381,
    left: 175,
  },
  secondarySupply: {
    position: "absolute",
    top: 151,
    left: 848,
  },
  secondaryReturn: {
    position: "absolute",
    top: 380,
    left: 816,
  },
  controlValve: {
    position: "absolute",
    top: 243,
    left: 201,
  },
  compensation: {
    position: "absolute",
    top: 89,
    left: 571,
  },
  outdoor: {
    position: "absolute",
    top: 0,
    left: 576,
  },
});

const HxMap = (props) => {
  const classes = useStyles();

  const initData = {
    data: null,
    unit: "",
    timestamp: "",
  };

  const hxImage = (
    <img className={classes.image} alt="heating exchanger" src={hxBg}></img>
  );

  const primarySupplyData = find(props.data, (d) => d.title === "primarySupply")
    ? find(props.data, (d) => d.title === "primarySupply")
    : initData;

  let primarySupply = (
    <div className={classes.primarySupply}>
      <TwinInputStatus
        size={64}
        data={primarySupplyData.data}
        unit={primarySupplyData.unit}
        timestamp={primarySupplyData.timestamp}
      />
    </div>
  );

  const primaryReturnData = find(props.data, (d) => d.title === "primaryReturn")
    ? find(props.data, (d) => d.title === "primaryReturn")
    : initData;
  let primaryReturn = (
    <div className={classes.primaryReturn}>
      <TwinInputStatus
        size={64}
        data={primaryReturnData.data}
        unit={primaryReturnData.unit}
        timestamp={primaryReturnData.timestamp}
      />
    </div>
  );

  const secondarySupplyData = find(
    props.data,
    (d) => d.title === "secondarySupply"
  )
    ? find(props.data, (d) => d.title === "secondarySupply")
    : initData;
  let secondarySupply = (
    <div className={classes.secondarySupply}>
      <TwinInputStatus
        size={64}
        data={secondarySupplyData.data}
        unit={secondarySupplyData.unit}
        timestamp={secondarySupplyData.timestamp}
      />
    </div>
  );

  const secondaryReturnData = find(
    props.data,
    (d) => d.title === "secondaryReturn"
  )
    ? find(props.data, (d) => d.title === "secondaryReturn")
    : initData;
  let secondaryReturn = (
    <div className={classes.secondaryReturn}>
      <TwinInputStatus
        size={64}
        data={secondaryReturnData.data}
        unit={secondaryReturnData.unit}
        timestamp={secondaryReturnData.timestamp}
      />
    </div>
  );

  const controlValveData = find(props.data, (d) => d.title === "controlValve")
    ? find(props.data, (d) => d.title === "controlValve")
    : initData;
  let controlValve = (
    <div className={classes.controlValve}>
      <TwinInputStatus
        size={64}
        title="Control valve"
        titleColor="#000"
        data={controlValveData.data}
        unit={controlValveData.unit}
        timestamp={controlValveData.timestamp}
      />
    </div>
  );

  const compensationData = find(props.data, (d) => d.title === "compensation")
    ? find(props.data, (d) => d.title === "compensation")
    : initData;
  let compensation = (
    <div className={classes.compensation}>
      <TwinInputStatus
        size={69}
        data={compensationData.data}
        unit={compensationData.unit}
        timestamp={compensationData.timestamp}
      />
    </div>
  );

  const outdoorData = find(props.data, (d) => d.title === "outdoor")
    ? find(props.data, (d) => d.title === "outdoor")
    : initData;
  let outdoor = (
    <div className={classes.outdoor}>
      <TwinInputStatus
        size={69}
        data={outdoorData.data}
        unit={outdoorData.unit}
        timestamp={outdoorData.timestamp}
      />
    </div>
  );

  return (
    <CardWithTitle title={"Input status"}>
      <div className={classes.root}>
        <div className={classes.container}>
          {primarySupply}
          {primaryReturn}
          {secondarySupply}
          {secondaryReturn}
          {controlValve}
          {compensation}
          {outdoor}
          {hxImage}
        </div>
      </div>
    </CardWithTitle>
  );
};

export default HxMap;
