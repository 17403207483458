import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import TeamMember from "./TeamMember";
import useUserRole from "../../utils/hooks/useUserRole";

const useStyles = makeStyles(() => ({
  root: {
    display: "table",
  },
}));

const TeamMembers = (props) => {
  const classes = useStyles();
  const { isExternalUser } = useUserRole();

  if (props && props.members && props.members.length > 0) {
    const filteredMembers = props.members.filter((m) =>
      isExternalUser ? m.role === "Remote Operator" : true
    );
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          {filteredMembers.map((m, i) => {
            return (
              <Grid
                key={m.role + m.name + i}
                item
                xs={12}
                md={isExternalUser ? 12 : 6}
                style={{ minWidth: "13.5em" }}
              >
                <TeamMember
                  title={m.role}
                  name={m.name}
                  number={m.phone}
                  email={m.email}
                  isExternal={isExternalUser}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};

export default TeamMembers;
