const number =
  (message = "Must be a number") =>
  (value) =>
    value && isNaN(Number(value)) ? message : undefined;
const minValue =
  (min, message = "Too small") =>
  (value) =>
    value && value < min ? message : undefined;
const maxValue =
  (max, message = "Too large") =>
  (value) =>
    value && value > max ? message : undefined;
const isIntValue =
  (message = "Not an integer") =>
  (value) => {
    return value && !Number.isInteger(value) ? message : undefined;
  };
const startsWithNumber =
  (message = "string start with number") =>
  (value) => {
    if (/^\d/.test(value)) {
      return message;
    }
    return undefined;
  };
const containsWhiteSpace =
  (message = "string contains white spaces") =>
  (value) => {
    return /\s/.test(value) ? message : undefined;
  };
const isValidBlockName =
  (message = "block name contains invalid characters a-zA-Z0-9_") =>
  (value) => {
    var allowed = /^[a-zA-Z0-9_]+$/;
    if (allowed.test(value)) {
      return undefined;
    }
    return message;
  };
export {
  isValidBlockName,
  containsWhiteSpace,
  isIntValue,
  number,
  minValue,
  maxValue,
  startsWithNumber,
};
