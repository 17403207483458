import { Button, FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useRedirect, useRefresh } from "ra-core";
import React, { useEffect, useState } from "react";
import { EditProps } from "react-admin";
import PageTitle from "../components/layout/PageTitle";
import { ConsumptionMeasurement } from "../types/leasegreen-core-api-exports";
import useCustomFetch from "../utils/hooks/useCustomFetch";
import MomentUtils from "@date-io/moment";
import { isNil } from "lodash";
import { useSelector } from "react-redux";
import { IRootState } from "../types/root-state";

const consumptionTypeOptions = [
  {
    key: "electricity",
    title: "Electricity (KWh)"
  },
  {
    key: "heating",
    title: "Heating (MWh)"
  },
  {
    key: "cooling",
    title: "Cooling (MWh)"
  },
  {
    key: "water",
    title: "Water (m³)"
  }
];

const formatDate = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const SetManualConsumption = (props: EditProps) => {
  const customFetch = useCustomFetch();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const buildingId = useSelector<IRootState, string>((state) => state.buildingFilter.id);

  const goToListingPage = () => {
    redirect("/consumptionlists");
    refresh();
  }

  if (isNil(buildingId)) {
    goToListingPage();
  }

  const params = new URLSearchParams(props.location.search);
  const paramBuildingStatsId = Number.parseInt(params.get("BuildingStatsId"));
  const paramType = params.get("Type");
  const paramTime = params.get("Time");

  const [consumptionMeasurement, setConsumptionMeasurement] = useState<ConsumptionMeasurement>();
  const [preferred, setPreferred] = useState<boolean>(false);
  const [type, setType] = useState<string>(paramType);
  const [consumption, setConsumption] = useState<number>(null);
  const [time, setTime] = useState<Date>(new Date(paramTime));
  const [isGetRequestReady, setIsGetRequestReady] = useState<boolean>(false);

  const url = `consumptionlists?BuildingId=${buildingId}&BuildingStatsId=${paramBuildingStatsId}&Type=${paramType}&Time=${paramTime}&Source=Manual`;
  useEffect(() => {
    setIsGetRequestReady(false);
    customFetch({
      url,
      method: 'GET'
    })
      .then(async (response: Response) => {
        setIsGetRequestReady(true);
        const data: ConsumptionMeasurement[] = await response.json();
        if (data.length > 0) {
          const receivedConsumption = data[0];
          setConsumptionMeasurement(receivedConsumption);
          setType(receivedConsumption.Type);
          setConsumption(receivedConsumption.Consumption);
          setTime(new Date(receivedConsumption.Time));
          setPreferred(receivedConsumption.Preferred);
        }
      });
  }, [customFetch, url]);

  const handleSubmit = (e: any) => {
    if (consumptionMeasurement) { // UPDATE
      customFetch({
        url: `consumptionlists/${consumptionMeasurement.id}`,
        method: 'PUT',
        body: JSON.stringify({
          ...consumptionMeasurement,
          BuildingStatsId: paramBuildingStatsId,
          Time: formatDate(time),
          Consumption: consumption,
          Type: type,
          Preferred: preferred,
        }),
        headers: [{
          name: "Content-Type",
          value: "application/json"
        }]
      }).then(() => goToListingPage());
    } else { // INSERT NEW
      customFetch({
        url: 'consumptionlists',
        method: 'POST',
        body: JSON.stringify({
          BuildingStatsId: paramBuildingStatsId,
          Time: formatDate(time),
          Consumption: consumption,
          Type: type,
          Preferred: preferred,
        }),
        headers: [{
          name: "Content-Type",
          value: "application/json"
        }]
      }).then(() => goToListingPage());
    }
  };

  return (
    <>
      <PageTitle title="Set manual consumption" />
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          xs={8}
        >
          <TextField
            select
            required={true}
            label="Type"
            variant="filled"
            value={type}
            SelectProps={{
              native: true
            }}
            onChange={e => setType(e.target.value)}>
            {consumptionTypeOptions
              .map((option) => (
                <option key={option.key} value={option.key}>
                  {option.title}
                </option>
              ))}
          </TextField>
          <TextField
            required={true}
            label="Consumption"
            variant="filled"
            type="number"
            value={consumption}
            onChange={e => setConsumption(Number.parseFloat(e.target.value))} />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              required={true}
              variant="dialog"
              openTo="year"
              format="MM/yyyy"
              views={["year", "month"]}
              label="Year and Month"
              value={time}
              onChange={e => setTime(e.toDate())} />
          </MuiPickersUtilsProvider>
          <FormControlLabel
            control={
              <Switch
                title="Preferred"
                color="primary"
                checked={preferred}
                onChange={e => setPreferred(e.target.checked)} />
            }
            label="Prefer manual value"
          />
          <Button
            disabled={!isGetRequestReady}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
        </Button>
        </Grid>
      </form>
    </>
  );
}