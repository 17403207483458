import React from 'react';
import { HeatRecoveryType } from '../../../types/leasegreen-core-api-exports';
import { AhuTwinPropertyValue } from '../../AhuTwinInfo';
import { SvgElementDataMapper, SvgValueMappings, SvgElementConfig } from './SvgDataMapper';
import { ReactComponent as ConvectionAhuSvg } from '../../../assets/images/ahu-svg/convection_ahu.svg';
import { ReactComponent as CubeAhuSvg } from '../../../assets/images/ahu-svg/cube_ahu.svg';
import { ReactComponent as DiscAhuSvg } from '../../../assets/images/ahu-svg/disc_ahu.svg';
import { ReactComponent as LiquidAhuSvg } from '../../../assets/images/ahu-svg/liquid_ahu.svg';
import { ReactComponent as NoAhuSvg } from '../../../assets/images/ahu-svg/no_ahu.svg';

interface AhuSvgWrapperProps {
  heatRecoveryType: HeatRecoveryType;
  twinData: AhuTwinPropertyValue[];
}

const elementValueMappings: SvgValueMappings = new Map<AhuTwinPropertyValue['variableName'], SvgElementConfig>([
  ["BeforeHRTE", { getValueEl: { selector: '#temp_before_HR_value > tspan' }, getTitleEl: { selector: '#temp_before_HR > tspan' } }],
  ["SupplyAirTE", { getValueEl: { selector: '#supply_air_temp_value > tspan' }, getTitleEl: { selector: '#supply_air_temp > tspan' } }],
  ["ReturnAirTe", { getValueEl: { selector: '#return_air_temp_value > tspan' }, getTitleEl: { selector: '#return_air_temp > tspan' } }],
  ["SupplyAirFlow", { getValueEl: { selector: '#air_flow_supply_intake_value > tspan' }, getTitleEl: { selector: '#air_flow_supply_intake > tspan' } }],
  ["ReturnAirFlow", { getValueEl: { selector: '#air_flow_supply_exhaust_value > tspan' }, getTitleEl: { selector: '#air_flow_supply_exhaust > tspan' } }],
  ["AfterHRTE", { getValueEl: { selector: '#temp_after_HR_value > tspan' }, getTitleEl: { selector: '#temp_after_HR > tspan' } }],
  ["ExhaustAirTE", { getValueEl: { selector: '#exhaust_air_temp_value > tspan' }, getTitleEl: { selector: '#exhaust_air_temp > tspan' } }],
  ["TEBetweenHeatingAndCoolingCoilInput", { getValueEl: { selector: '#temp_after_radiator_value > tspan' }, getTitleEl: { selector: '#temp_after_radiator > tspan' } }],
  ["HeatRecoveryControlInput", { getValueEl: { selector: '#HR_request_value > tspan' }, getTitleEl: { selector: '#HR_request > tspan' } }],
  ["SupplyStatusInput", { getValueEl: { selector: '#air_flow_on_off_intake' }, getTitleEl: { selector: '' }, otherEls: [{ selector: '#air_flow_on_off_bg_intake-2', onElementChange: (el, val) => { el.style.fill = val === 'ON' ? 'green' : 'gray'; } }] }],
  ["ReturnStatusInput", { getValueEl: { selector: '#air_flow_on_off_exhaust' }, getTitleEl: { selector: '' }, otherEls: [{ selector: '#air_flow_on_off_bg_exhaust-2', onElementChange: (el, val) => { el.style.fill = val === 'ON' ? 'green' : 'gray'; } }] }],
  ["HEATING_POWER", { getValueEl: { selector: '#heating_power_value > tspan' }, getTitleEl: { selector: '#heating_power > tspan' } }],
  ["COOLING_POWER", { getValueEl: { selector: '#cooling_power_value > tspan' }, getTitleEl: { selector: '#cooling_power > tspan' } }],
  ["TOTAL_HEATING_ENERGY", { getValueEl: { selector: '#heating_power_value_mwh > tspan' }, getTitleEl: { selector: '' }, otherEls: [{ selector: '#Group_320' }] }],
  ["TOTAL_COOLING_ENERGY", { getValueEl: { selector: '#cooling_power_value_mwh' }, getTitleEl: { selector: '' }, otherEls: [{ selector: '#Group_321' }] }],
  ["HR_EFFICIENCY", { getValueEl: { selector: '#HR_efficiency_value > tspan' }, getTitleEl: { selector: '#HR_efficiency > tspan' } }],
  ["SupplyAirPressureInput", { getValueEl: { selector: '#supply_air_pressure_value > tspan' }, getTitleEl: { selector: '#supply_air_pressure > tspan' } }],
  ["ReturnAirPressureInput", { getValueEl: { selector: '#return_air_pressure_value > tspan' }, getTitleEl: { selector: '#return_air_pressure > tspan' } }],
  ["SupplyAirControlInput", { getValueEl: { selector: '#air_flow_control_intake_value > tspan' }, getTitleEl: { selector: '#air_flow_control_intake > tspan' } }],
  ["ReturnAirControlInput", { getValueEl: { selector: '#air_flow_control_exhaust_value > tspan' }, getTitleEl: { selector: '#air_flow_control_exhaust > tspan' } }],
  // ["HeatRecoveryEfficiencyLevel", { getValueEl: { selector: '#HR_efficiency_value > tspan' }, getTitleEl: { selector: '#HR_efficiency > tspan' } }],
  // ["OutdoorTE", { getValueEl: '', getTitleEl: ''}],
]);

const svgFiles = new Map([
  ['LIQUID_HR', LiquidAhuSvg],
  ['CUBE_HR', CubeAhuSvg],
  ['DISC_HR', DiscAhuSvg],
  ['NO_HR', NoAhuSvg],
  ['RECIRCULATION_HR', ConvectionAhuSvg]
]);

export const AhuSvgWrapper = (props: AhuSvgWrapperProps) => {
  const hiddenElements = [
    ...(props.heatRecoveryType === 'NO_HR'
      ? [
        elementValueMappings.get("HR_EFFICIENCY").getTitleEl,
        elementValueMappings.get("HR_EFFICIENCY").getValueEl
      ]
      : []
    )
  ];

  return <SvgElementDataMapper
    twinData={props.twinData}
    elementValueMappings={elementValueMappings}
    elementsToHide={hiddenElements}
    svgElement={svgFiles.get(props.heatRecoveryType)} />;
}