import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { find, groupBy, map, round, sortBy } from "lodash";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import CardWithTitle from "../../components/layout/CardWithTitle";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (record, id) => {
  let y = find(record.Trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

const raReportingDataToHighchartsData = (dataIn, divider) => {
  const sortedByMonth = sortBy(dataIn, "Month");

  const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < 12; i++) {
    var current = sortedByMonth[i];
    if (current) {
      data[current.Month - 1] = round(current.Value / divider, 2);
    }
  }

  return data;
};

export class MeterMonthlySumGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: { title: { text: "" } },
      dataFetched: false,
      regFetched: false,
      data: null,
      reg: null,
      chartDrawn: false,
      record: this.props,
      title: "",
    };
  }

  componentDidMount() {
    restClient(GET_LIST, "reportdata", {
      filter: {
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props, "MONTHLY_SUM"),
        resolution: "month",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    }).then((response) => {
      let unit = "";
      if (this.props.Meter && this.props.Meter.Category) {
        switch (this.props.Meter.Category) {
          case "Heating":
            unit = "MWh";
            break;
          case "Cooling":
            unit = "MWh";
            break;
          case "Electricity":
            unit = "KWh";
            break;
          case "Water":
            unit = "m³";
            break;
          default:
            break;
        }
      }
      this.setState({
        dataFetched: true,
        data: response.data,
        reg: { Unit: unit },
        regFetched: true,
      });
    });
  }

  componentDidUpdate() {
    if (
      !this.state.chartDrawn &&
      this.state.dataFetched &&
      this.state.data &&
      this.state.regFetched &&
      this.state.reg
    ) {
      this.setState({
        chartDrawn: true,
        options: this.graphOptions(this.state.data, this.state.reg),
      });
    }
  }

  graphOptions = (data, register) => {
    const grouped = groupBy(data, "Id");
    const keys = Object.keys(grouped);
    let series = [];

    let trend = find(
      this.state.record.Trends,
      (y) => y.TrendId === "MONTHLY_SUM"
    );

    keys.forEach((k) => {
      const groupedByYear = groupBy(grouped[k], "Year");
      const years = Object.keys(groupedByYear);
      years.forEach((y) => {
        let trendValue = find(
          trend.Values,
          (v) => v.ReportingPointId === Number(k)
        );
        series.push({
          name: trendValue.Legend + " " + y,
          data: raReportingDataToHighchartsData(
            groupedByYear[y],
            register.Unit === "MWh" ? 1000 : 1
          ),
        });
      });
    });

    if (this.state.title === "") {
      this.setState({
        title: trend.Title,
      });
    }
    return {
      chart: {
        type: "column",
      },
      colors: this.props.graphColors,
      legend: {
        enabled: true,
      },
      rangeSelector: {
        enabled: false,
      },
      title: {
        text: "",
      },
      series: series,
      yAxis: {
        min: 0,
        title: {
          text: trend.Title + ` (${register.Unit})`,
        },
      },
      scrollbar: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    };
  };

  render() {
    let showData = false;

    if (this.state && this.state.options && this.state.options.series) {
      showData = true;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.dataFetched
    ) {
      if (showData) {
        this.props.showContainer("monthly");
      } else {
        this.props.hideContainer("monthly");
      }
    }

    return this.state && this.state.options && this.state.options.series ? (
      <CardWithTitle title="Year by year">
        <HighchartsReact
          immutable
          highcharts={Highcharts}
          options={this.state.options}
        />
      </CardWithTitle>
    ) : null;
  }
}
