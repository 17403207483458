import React from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import FavoriteBuildings from "./FavoriteBuildings";
import useUserRole from "../utils/hooks/useUserRole";

const FavoriteContainer = ({
  buildings,
  favorites,
  alerts,
  favoriteDeleted,
}) => {
  const { isExternalUser } = useUserRole();
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <FavoriteBuildings
      buildings={buildings}
      favorites={favorites}
      alerts={alerts}
      favoriteDeleted={favoriteDeleted}
      isExternal={isExternalUser}
      useMobile={isMediumOrSmaller}
    />
  );
};

export default FavoriteContainer;
