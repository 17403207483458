import { GET_LIST } from "react-admin";

export const GET_BUILDINGS = "GET_BUILDINGS";

// Get buildings is just used to refresh the page
export const getBuildings = (pagination, sort, filter) => ({
  type: GET_BUILDINGS,
  payload: { pagination: pagination, sort: sort, filter: filter },
  meta: {
    resource: "buildings",
    fetch: GET_LIST,
    refresh: true
  }
});
