import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import { find, groupBy, map, round, sortBy, cloneDeep } from "lodash";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";
import Boost from "highcharts/modules/boost";
import { electricityColors } from "../utils/graphColor";
Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (dataFromReactAdmin) => {
  var current = new Date();
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = null;
    if (v.date) {
      date = new Date(v.date);
    } else if (v.year && v.month) {
      date = new Date(v.year, v.month - 1, 2);
    }
    return [
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getTime(),
      round(v.value, 2),
    ];
  });
  return highChartsData;
};

export class SolarDailyEnergyGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsDay: { title: { text: "" } },
      optionsMonth: { title: { text: "" } },
      resolution: "day",
      record: this.props,
      fetchDone: false,
    };
  }

  componentDidMount() {
    // Daily data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props.Trends, "SOLAR_ENERGY_DAILY"),
        resolution: "day",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) =>
        this.setState({
          optionsDay: this.graphOptions(response.values),
          fetchDone: true,
        })
      );

    // Monthly data
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: this.props.BuildingId,
        metaIds: findMetaIds(this.props.Trends, "SOLAR_ENERGY_MONTHLY"),
        resolution: "month",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        this.setState({
          optionsMonth: this.graphOptions(response.values, true),
          fetchDone: true,
        });
      });
  }

  graphOptions = (data, monthlyData = false) => {
    const grouped = groupBy(data, "metaId");
    let trend = find(
      this.state.record.Trends,
      (y) => y.TrendId === "SOLAR_ENERGY_DAILY"
    );
    const keys = Object.keys(grouped);

    const series = map(keys, (k) => {
      let trendValue = find(
        trend.Values,
        (v) => v.ReportingPointId === Number(k)
      );

      return {
        name: trendValue.Legend,
        data: sortBy(valuesToHighchartsData(grouped[k]), 0),
        dataGrouping: {
          enabled: false,
        },
      };
    });

    return {
      chart: {
        zoomType: "x",
        type: "column",
      },
      colors: electricityColors,
      legend: {
        enabled: true,
      },
      rangeSelector: {
        selected: 2,
        // allButtonsEnabled: true,
        buttonTheme: {
          width: "auto",
        },
        buttons: monthlyData
          ? [
              {
                type: "month",
                count: 3,
                text: "3m",
              },
              {
                type: "month",
                count: 12,
                text: "12m",
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ]
          : [
              {
                type: "month",
                count: 1,
                text: "1m",
              },
              {
                type: "month",
                count: 3,
                text: "3m",
              },
              {
                type: "month",
                count: 6,
                text: "6m",
              },
              {
                type: "ytd",
                text: "YTD",
              },
              {
                type: "year",
                count: 1,
                text: "1y",
              },
              {
                type: "all",
                text: "All",
              },
            ],
      },
      tooltip: {
        valueDecimals: 2,
        valueSuffix: " KWh",
        xDateFormat: !monthlyData ? "%A, %b %e %Y, %H:%M" : "%B, %Y",
      },
      title: {
        text: "",
      },
      series: series,
    };
  };

  render() {
    let options = null;
    if (this.state.resolution === "day") {
      options = cloneDeep(this.state.optionsDay);
    } else if (this.state.resolution === "month") {
      options = cloneDeep(this.state.optionsMonth);
    }

    const handleResolutionChange = (e) => {
      this.setState({ resolution: e.target.value });
    };

    const ResolutionInput = (
      <div
        key={"resInput"}
        style={{
          marginLeft: 15,
          width: 80,
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        <InputLabel>Resolution</InputLabel>
        <Select value={this.state.resolution} onChange={handleResolutionChange}>
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"month"}>Month</MenuItem>
        </Select>
      </div>
    );

    let showData = true;
    if (!options || !options.series || options.series.length === 0) {
      showData = false;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.fetchDone
    ) {
      if (showData) {
        this.props.showContainer("daily");
      } else {
        this.props.hideContainer("daily");
      }
    }

    return (
      <CardWithTitle title="History">
        {showData ? (
          [
            ResolutionInput,
            <HighchartsReact
              key={"chrtE"}
              immutable={true}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={cloneDeep(options)}
              callback={this.afterChartCreated}
            />,
          ]
        ) : (
          <div style={{ height: "20rem" }}>
            <EmptyData />
          </div>
        )}
      </CardWithTitle>
    );
  }
}
