import React from "react";
import {
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { stateMapper } from "../customReducers/connectFunctions";
import { setConsumptionUnitText } from "../utils/unitTextParsers";
import { MonthInput } from "../components/TimeInputs";
import PageTitle from "../components/layout/PageTitle";

const useStyles = makeStyles(() => ({
  main: {
    marginTop: 0,
  },
}));

const ConsumptionListsCreate = ({ BuildingId, ...props }) => {
  const classes = useStyles();
  // Delete unused/unnecessary props
  let createProps = Object.assign({}, props);
  delete createProps.buildingTwinId;
  delete createProps.buildingSelected;
  delete createProps.dispatch;
  return (
    <>
      <PageTitle title="Create Consumption" />
      <Create {...createProps} classes={{ main: classes.main }}>
        <SimpleForm redirect="list">
          <ReferenceInput
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ showBAUI: true }}
            perPage={1000}
            allowEmpty
            fullWidth
            defaultValue={BuildingId}
          >
            <AutocompleteInput
              optionText="ProjectName"
              optionValue="id"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput source="Consumption" />
          <MonthInput source="Time" label="Date" />
          <SelectInput
            source="Type"
            choices={[
              {
                id: "electricity",
                name: setConsumptionUnitText("electricity"),
              },
              { id: "heating", name: setConsumptionUnitText("heating") },
              { id: "cooling", name: setConsumptionUnitText("cooling") },
              { id: "water", name: setConsumptionUnitText("water") },
            ]}
            validate={required("You must choose consumption type.")}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(ConsumptionListsCreate);
