import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  Toolbar,
  SaveButton,
} from "react-admin";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  Typography,
  Link,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import Wrapper from "../hoc/Wrapper";
import { useSelector } from "react-redux";

import consumptionTemplate from "../assets/kulutukset_malli.xlsx";

const useStyles = makeStyles({
  root: {
    color: "#3F51B5",
  },
});

const ImportConsumptions = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const buildingId = useSelector((state) => state.buildingFilter.id);

  const ConsumptionImportToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton onClick={handleSave} />
    </Toolbar>
  );

  const ConsumptionDialog = (props) => {
    if (!buildingId) {
      return null;
    }
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Import consumptions</DialogTitle>
        <Edit
          {...props}
          basePath="/consumptionlists"
          id={buildingId}
          resource="buildings"
          title={null}
          actions={null}
          onFailure={() => setOpen(false)}
        >
          <SimpleForm
            toolbar={<ConsumptionImportToolbar />}
            submitOnEnter={false}
            redirect={false}
            resource="buildings"
            style={{ textAlign: "center" }}
          >
            <Typography variant="body1">
              Consumption template file
              <div style={{ marginBottom: 20 }}>
                <Button
                  title="Download template file for importing consumptions"
                  component={Link}
                  variant="contained"
                  href={consumptionTemplate}
                  download
                >
                  Template
                </Button>
              </div>
            </Typography>
            <FileInput
              source="files"
              label="Upload consumptions from excel"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <FileField source="src" title="Upload from excel" />
            </FileInput>
          </SimpleForm>
        </Edit>
      </Dialog>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  return (
    <Wrapper>
      <Button
        size="small"
        classes={{ root: classes.root }}
        variant="text"
        startIcon={<PublishIcon />}
        onClick={handleClickOpen}
      >
        IMPORT
      </Button>
      <ConsumptionDialog open={open} onClose={handleClose} />
    </Wrapper>
  );
};

export default ImportConsumptions;
