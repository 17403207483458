import React, { forwardRef, cloneElement, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { shallowEqual, useSelector } from "react-redux";
import { getResources } from "ra-core";

const NavLinkRef = React.forwardRef((props, ref) => {
  // Delete unused props
  const navProps = Object.assign({}, props);
  delete navProps.alertCount;
  delete navProps.checkForList;
  return <NavLink innerRef={ref} {...navProps} />;
});

const useStyles = makeStyles(
  (theme) => ({
    root: {
      // color: theme.palette.text.secondary
      color: "#9590a0",
      fontSize: "1em",
      paddingTop: 14,
      paddingBottom: 14,
    },
    active: {
      // color: theme.palette.text.primary
      color: "#1ad689",
      borderLeft: "5px solid #1ad689",
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: "LGMenuItemLink" }
);

const CustomMenuLink = forwardRef(
  (
    {
      classes: classesOverride,
      className,
      primaryText,
      leftIcon,
      onClick,
      sidebarIsOpen,
      subMenu,
      ...props
    },
    ref
  ) => {
    const classes = useStyles({ classes: classesOverride, subMenu });
    const resources = useSelector(getResources, shallowEqual);

    const handleMenuTap = useCallback(
      (e) => {
        onClick && onClick(e);
      },
      [onClick]
    );

    const renderMenuItem = () => {
      return (
        <MenuItem
          className={classnames(classes.root, className)}
          activeClassName={classes.active}
          component={NavLinkRef}
          ref={ref}
          {...props}
          onClick={handleMenuTap}
        >
          {leftIcon && (
            <ListItemIcon className={classes.icon}>
              {cloneElement(leftIcon, {
                titleAccess: primaryText,
              })}
            </ListItemIcon>
          )}
          {primaryText}
          {props.alertCount && props.alertCount > 0 ? (
            <div style={{ width: "100%", textAlign: "right" }}>
              <div
                style={{
                  backgroundColor: "#D62B1A",
                  borderRadius: "50%",
                  marginRight: 16,
                  display: "inline-flex",
                  height: 24,
                  width: 24,
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "0.6875rem",
                  fontWeight: 300,
                  color: "#FFFFFF",
                }}
              >
                <span>{props.alertCount}</span>
              </div>
            </div>
          ) : null}
        </MenuItem>
      );
    };

    // If the resource does not have a list, return null
    // This is mainly for permission checking
    if (
      props.checkForList &&
      (!resources.find((r) => r.name === props.to.substring(1)) ||
        !resources.find((r) => r.name === props.to.substring(1)).hasList)
    ) {
      return null;
    }

    if (sidebarIsOpen) {
      return renderMenuItem();
    }

    return (
      <Tooltip title={primaryText} placement="right">
        {renderMenuItem()}
      </Tooltip>
    );
  }
);

CustomMenuLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  staticContext: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default CustomMenuLink;
