import React from "react";
import { makeStyles, Chip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  chip: {
    margin: 4,
    cursor: "inherit",
  },
}));

const BuildingsArrayField = ({ record, source, buildings }) => {
  const classes = useStyles();
  if (
    buildings &&
    record &&
    source &&
    record[source] &&
    record[source].length > 0
  ) {
    const buildingArray = [];
    for (let i = 0; i < record[source].length; i++) {
      if (i >= 11) {
        break;
      }

      if (i === 10) {
        buildingArray.push(
          <Chip
            key="MORE BUILDINGS"
            className={classes.chip}
            label="MORE BUILDINGS"
            style={{ width: "100%", backgroundColor: "#1ad689" }}
          />
        );
        break;
      }

      const building = buildings.find((b) => b.id === record[source][i]);
      const projectName =
        (building && building.ProjectName) || "Unknown building";
      buildingArray.push(
        <Chip
          key={record[source][i]}
          className={classes.chip}
          label={projectName}
        />
      );
    }
    return buildingArray;
  }
  return null;
};

export default BuildingsArrayField;
