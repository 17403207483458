import React from "react";
import { Admin, Resource } from "react-admin";
import { useMsal } from "@azure/msal-react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FunctionsIcon from "@material-ui/icons/Functions";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import WifiIcon from "@material-ui/icons/Wifi";
import ExposureOutlinedIcon from "@material-ui/icons/ExposureOutlined";
import { Route } from "react-router-dom";
import Home from "./home/Home";
import DashBoard from "./dashboard/DashBoard";
import { AlertShow } from "./alerts/AlertShow";
import AlertList from "./alerts/AlertList";
import BlockCreate from "./blocks/BlockCreate";
import { BlockEdit } from "./blocks/BlockEdit";
import BlockList from "./blocks/BlockList";
import BlockShowWrapper from "./blocks/BlockShowWrapper";
import { BuildingEdit } from "./buildings/BuildingEdit";
import BuildingList from "./buildings/BuildingList";
import { BuildingShow } from "./buildings/BuildingShow";
import BuildingPointList from "./buildingpoints/BuildingPointList";
import BusAdapterList from "./busadapters/BusAdapterList";
import BusAdapterCreate from "./busadapters/BusAdapterCreate";
import { BusAdapterEdit } from "./busadapters/BusAdapterEdit";
import { CompensationEdit } from "./compensations/CompensationEdit";
import CompensationCreate from "./compensations/CompensationCreate";
import CompensationList from "./compensations/CompensationList";
import CompensationFunctionCreate from "./compensationfunctions/CompensationFunctionCreate";
import CompensationFunctionEdit from "./compensationfunctions/CompensationFunctionEdit";
import CompensationFunctionList from "./compensationfunctions/CompensationFunctionList";
import ValueStorageList from "./valuestorage/ValueStorageList";
import ValueStorageCreate from "./valuestorage/ValueStorageCreate";
import ValueStorageShow from "./valuestorage/ValueStorageShow";
import RegisterListsList from "./registerlists/RegisterListsList";
import { RegisterListsEdit } from "./registerlists/RegisterListsEdit";
import ConsumptionListsList from "./consumptionlists/ConsumptionListsList";
import EnergyTypeList from "./energytypelist/EnergyTypeList";
import { ConsumptionListsEdit } from "./consumptionlists/ConsumptionListsEdit";
import ConsumptionListsCreate from "./consumptionlists/ConsumptionListsCreate";
import PowerConsumptionListsList from "./powerconsumptionlists/PowerConsumptionListsList";
import { PowerConsumptionListsEdit } from "./powerconsumptionlists/PowerConsumptionListsEdit";
import PowerConsumptionListsCreate from "./powerconsumptionlists/PowerConsumptionListsCreate";
import customLayout from "./layout/CustomLayoutWrapper";
import buildingFilter from "./customReducers/buildingFilterReducer";
import OperationsList from "./operations/OperationsList";
import OperationsCreate from "./operations/OperationsCreate";
import OperationsEdit from "./operations/OperationsEdit";
import { RemoteConnectionList } from "./remoteconnections/RemoteConnectionList";
import { RemoteConnectionShow } from "./remoteconnections/RemoteConnectionShow";
import RemoteConnectionEdit from "./remoteconnections/RemoteConnectionEdit";
import RemoteConnectionCreate from "./remoteconnections/RemoteConnectionCreate";
import PowerConsumptionGraph from "./powerconsumptionlists/PowerConsumptionGraph";
import PowerConsumptionGraphs from "./powerconsumptionlists/PowerConsumptionGraphs";
import ConsumptionGraph from "./consumptionlists/ConsumptionGraph";
import RisksIssuesList from "./issues/RisksIssuesList";
import myTheme from "./layout/layoutTheme";
import { SvgIcon } from "@material-ui/core";
import Analytics from "./analytics/Analytics";
import Reminders from "./reminders/Reminders";
import SubmeterReadingsList from "./submeterlist/SubmeterReadingsList";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Highcharts from "highcharts/highstock";
import EnergySavingsList from "./energysavings/EnergySavingsList";
import GuestUserList from "./admin/GuestUserList";
import GuestUserEdit from "./admin/GuestUserEdit";
import { defaultColors } from "./utils/graphColor";
import OperationsCreateCustomer from "./operations/OperationsCreateCustomer";
import OperationsEditCustomer from "./operations/OperationsEditCustomer";
import BlockFeedbackList from "./blockfeedbacks/BlockFeedbackList";
import { uploadCapableDataProvider } from "./httpClient";
import { getUserRoles } from "./utils/hooks/useUserRole";
import { BuildingPointsEdit } from "./buildingpoints/BuildingPointsEdit";
import { SetManualConsumption } from "./consumptionlists/SetManualConsumption";

const AlertIcon = (
  <SvgIcon>
    <path d="M21.54,10.8l-8.3-8.3a1.63,1.63,0,0,0-2.4,0l-8.3,8.3a1.63,1.63,0,0,0,0,2.4l8.3,8.3a1.63,1.63,0,0,0,2.4,0l8.3-8.3a1.83,1.83,0,0,0,0-2.4M20.34,12,12,20.3,3.74,12,12,3.7M13,17H11V15h2m0-2H11V7h2Z" />
  </SvgIcon>
);

const BuildingsIcon = (
  <SvgIcon>
    <g id="icon_buildings" transform="translate(-67.987 -190.586)">
      <g
        id="Rectangle_7"
        data-name="Rectangle 7"
        transform="translate(67.987 199.641)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <rect width="10" height="13" stroke="none" />
        <rect x="0.75" y="0.75" width="8.5" height="11.5" fill="none" />
      </g>
      <path
        id="Path_30"
        data-name="Path 30"
        d="M94.91,196.988v-5.3l9.508,3.725v16.481H96.347"
        transform="translate(-18.657 0)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);

const DashboardIcon = (
  <SvgIcon>
    <path
      id="icon_dashboard"
      d="M11.264,3V7.959h6.611V3M11.264,17.876h6.611V9.611H11.264M3,17.876H9.611V12.917H3m0-1.653H9.611V3H3Z"
      transform="translate(-3 -3)"
      fill="currentColor"
    />
  </SvgIcon>
);

const TwinsIcon = (
  <SvgIcon>
    <g id="icon_digital_twin" transform="translate(11.177 -43.823)">
      <rect
        id="Rectangle_179"
        data-name="Rectangle 179"
        width="9"
        height="9"
        rx="1"
        transform="translate(-11.177 43.823)"
        fill="currentColor"
      />
      <g
        id="Rectangle_180"
        data-name="Rectangle 180"
        transform="translate(-2.177 52.823)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      >
        <rect width="9" height="9" rx="1" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="7.5"
          height="7.5"
          rx="0.25"
          fill="none"
        />
      </g>
    </g>
  </SvgIcon>
);

const a = {
  msalProvider: null,
};

export const getProvider = new Promise((resolve, reject) => {
  setInterval(() => {
    if (!!a.msalProvider) {
      resolve(a.msalProvider);
    }
  }, 1000);
});

const getAuthProvider = (instance) => {
  const authProvider = {
    login: (params) => instance.loginRedirect(),
    logout: (params) => instance.logout(),
    checkAuth: (params) => Promise.resolve(),
    checkError: (error) => Promise.resolve(),
    getPermissions: (params) => {
      const activeAccount = instance.getActiveAccount();
      if (!!activeAccount) {
        const permissions = activeAccount.idTokenClaims["roles"];
        return Promise.resolve(permissions || []);
      }
      return Promise.resolve([]);
    },
    getIdentity: () => {
      const currentAccount = instance.getActiveAccount();
      const id = currentAccount?.localAccountId;
      const fullName = currentAccount?.name;
      if (!!id && !!fullName) {
        return Promise.resolve({
          id,
          fullName,
        });
      } else {
        return Promise.reject();
      }
    },
    getAccessToken: (params) =>
      instance.acquireTokenSilent({ scopes: params.scopes }).catch((error) => {
        instance.loginRedirect();
      }),
  };
  return authProvider;
};

export function App() {
  const { instance, accounts, inProgress } = useMsal();

  const activeAccount =
    instance.getActiveAccount() || instance.getAllAccounts()[0];
  instance.setActiveAccount(activeAccount);

  a.msalProvider = getAuthProvider(instance);

  Highcharts.setOptions({
    colors: defaultColors,
    tooltip: {
      valueDecimals: 2,
    },
    credits: {
      enabled: false,
    },
  });

  if (accounts.length > 0) {
    const { isExternalUser } = getUserRoles(
      instance.getActiveAccount()?.idTokenClaims.roles
    );

    const customRoutes = [
      <Route exact path="/dashboard" component={DashBoard} />,
      <Route path="/analytics" component={Analytics} />,
      <Route path="/manualconsumption" component={SetManualConsumption} />,
    ];

    if (!isExternalUser) {
      customRoutes.push(
        <Route
          exact
          path="/powerconsumptiongraph"
          component={PowerConsumptionGraph}
        />,
        <Route
          exact
          path="/powerconsumptiongraphs"
          component={PowerConsumptionGraphs}
        />,
        <Route
          exact
          path="/consumptiongraph"
          render={(props) => <ConsumptionGraph {...props} />}
        />
      );
    }

    return (
      <Admin
        layout={customLayout}
        customRoutes={customRoutes}
        theme={myTheme}
        dashboard={Home}
        dataProvider={uploadCapableDataProvider}
        authProvider={a.msalProvider}
        customReducers={{ buildingFilter }}
      >
        {(permissions) => {
          const { isAdmin, isExternalUser, isAdminOrUser } =
            getUserRoles(permissions);
          return [
            <Resource
              name="blocks"
              options={{ label: "Digital Twins" }}
              icon={TwinsIcon}
              create={isAdminOrUser ? BlockCreate : null}
              edit={isAdminOrUser ? BlockEdit : null}
              list={!isExternalUser && BlockList}
              show={BlockShowWrapper}
            />,
            <Resource
              name="alerts"
              list={!isExternalUser && AlertList}
              show={AlertShow}
              icon={AlertIcon}
            />,
            isAdminOrUser && (
              <Resource
                name="reminders"
                list={!isExternalUser && Reminders}
                icon={<CalendarTodayIcon />}
              />
            ),
            isAdminOrUser && (
              <Resource
                options={{ label: "Savings Portfolio" }}
                name="energysavings"
                list={!isExternalUser && EnergySavingsList}
                icon={
                  <ExposureOutlinedIcon
                    style={{ transform: "rotate(180deg)" }}
                  />
                }
              />
            ),
            <Resource
              name="buildingpoints"
              list={isAdminOrUser ? BuildingPointList : null}
              edit={isAdmin ? BuildingPointsEdit : null}
            />,
            <Resource
              options={{ label: "Functions" }}
              name="compensationfunctions"
              create={isAdminOrUser ? CompensationFunctionCreate : null}
              edit={isAdminOrUser ? CompensationFunctionEdit : null}
              list={isAdminOrUser ? CompensationFunctionList : null}
              icon={<FunctionsIcon />}
            />,
            <Resource
              name="compensations"
              list={isAdminOrUser ? CompensationList : null}
              edit={isAdminOrUser ? CompensationEdit : null}
              create={isAdminOrUser ? CompensationCreate : null}
            />,
            <Resource name="datasources" />,
            <Resource name="dashboard" icon={DashboardIcon} />,
            <Resource
              name="busadapters"
              options={{ label: "Bus Adapters" }}
              icon={<SettingsInputComponentIcon />}
              list={isAdminOrUser ? BusAdapterList : null}
              create={isAdminOrUser ? BusAdapterCreate : null}
              edit={isAdminOrUser ? BusAdapterEdit : null}
            />,
            <Resource name="busadapterpoints" />,
            <Resource
              name="registerlists"
              list={isAdminOrUser ? RegisterListsList : null}
              edit={isAdminOrUser ? RegisterListsEdit : null}
            />,
            <Resource
              options={{ label: "Consumptions" }}
              name="consumptionlists"
              list={!isExternalUser && ConsumptionListsList}
              create={isAdminOrUser ? ConsumptionListsCreate : null}
              edit={isAdminOrUser ? ConsumptionListsEdit : null}
            />,
            <Resource
              options={{ label: "Energy Types" }}
              name="energytypelist"
              list={!isExternalUser && EnergyTypeList}
            />,
            <Resource
              options={{ label: "Submeters" }}
              name="submeterreadings"
              list={SubmeterReadingsList}
            />,
            <Resource
              options={{ label: "Power Analytics" }}
              name="powerconsumptionlists"
              list={!isExternalUser && PowerConsumptionListsList}
              create={isAdminOrUser ? PowerConsumptionListsCreate : null}
              edit={isAdminOrUser ? PowerConsumptionListsEdit : null}
            />,
            <Resource
              name="valuestorage"
              options={{ label: "Valuestorage" }}
              list={!isExternalUser && ValueStorageList}
              create={isAdminOrUser ? ValueStorageCreate : null}
              show={ValueStorageShow}
            />,
            <Resource
              options={{ label: "Risks and Issues" }}
              name="buildingsharepoint"
              list={!isExternalUser && RisksIssuesList}
            />,
            <Resource
              options={{ label: "Logs" }}
              name="operationlog"
              list={OperationsList}
              create={
                isAdminOrUser ? OperationsCreate : OperationsCreateCustomer
              }
              edit={isAdminOrUser ? OperationsEdit : OperationsEditCustomer}
              icon={<BookOutlinedIcon />}
            />,
            <Resource name="operationmeta" />,
            <Resource name="zoneusagetime" />,
            <Resource
              name="buildings"
              icon={BuildingsIcon}
              list={!isExternalUser && BuildingList}
              edit={isAdminOrUser ? BuildingEdit : null}
              show={BuildingShow}
            />,
            isAdminOrUser ? (
              <Resource
                name="remoteconnection"
                options={{ label: "Remote Connections" }}
                icon={<WifiIcon />}
                list={RemoteConnectionList}
                show={RemoteConnectionShow}
                edit={isAdminOrUser ? RemoteConnectionEdit : null}
                create={isAdminOrUser ? RemoteConnectionCreate : null}
              />
            ) : null,
            <Resource name="user/favorites" />,
            isAdminOrUser && (
              <Resource
                name="blockfeedbacks"
                list={BlockFeedbackList}
                icon={<FeedbackIcon />}
              />
            ),
            isAdmin && (
              <Resource
                name="guestusers"
                list={GuestUserList}
                edit={GuestUserEdit}
              />
            ),

            isAdmin && <Resource name="guestUserEdit" />,
            <Resource name="2.0/buildings" />,
            <Resource name="2.0/buildingstats" />,
          ];
        }}
      </Admin>
    );
  } else if (inProgress === "login") {
    return <span>Logging in</span>;
  } else if (inProgress === "none") {
    instance.loginRedirect();
    return <div></div>;
  } else {
    return <div></div>;
  }
}
export default App;
export { uploadCapableDataProvider as restClient };
