import React from "react";
import {
  TopToolbar,
  Edit,
  ListButton,
  maxLength,
  RefreshButton,
  required,
  SimpleForm,
  TextInput,
  NullableBooleanInput,
} from "react-admin";
import PageTitle from "../components/layout/PageTitle";

const RegistersEditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

const checkForLineBreaks = (value) => {
  if (value.indexOf("\n") > -1) {
    return "Cannot contain line breaks";
  }
};

const validateDescription = [required(), checkForLineBreaks];
const validateUnit = [required(), maxLength(10), checkForLineBreaks];

export const RegisterListsEdit = (props) => {
  return (
    <>
      <PageTitle title="Edit Register" />
      <Edit actions={<RegistersEditActions />} submitOnEnter={false} {...props}>
        <SimpleForm>
          <TextInput disabled source="RegisterNumber" />
          <TextInput disabled source="PointId" style={{ width: "100%" }} />
          <TextInput
            source="Description"
            style={{ width: "100%" }}
            validate={validateDescription}
          />
          <TextInput source="Unit" validate={validateUnit} />
          <NullableBooleanInput source="Writable" />
        </SimpleForm>
      </Edit>
    </>
  );
};
