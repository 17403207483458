import { groupBy, round } from "lodash";

const raReportingDataToHighchartsData = (_dataIn, type) => {
  let dataIn = [];

  if (_dataIn && _dataIn.length > 0) {
    dataIn = [..._dataIn];
  }
  const groupedByMonth = groupBy(dataIn, "month");

  const data = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  for (let i = 0; i < 12; i++) {
    var current = groupedByMonth[i + 1];
    if (current) {
      // If type is ahu, divide value by 3600 to get kWh instead of kWs
      let divider = 1000;
      let typeDivider = 1;
      if (type === "ahu") {
        typeDivider = 3600;
      }
      if (type === "solar" || type === "hp" || type === "cop") {
        divider = 1;
      }
      for (const k in current) {
        if (current.hasOwnProperty(k)) {
          data[current[k].month - 1] += round(
            current[k].value / divider / typeDivider,
            2
          );
        }
      }
    }
  }
  return data;
};

export default raReportingDataToHighchartsData;
