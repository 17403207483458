import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { isNil } from "lodash";

const useStyles = makeStyles({
  rectangle: {
    position: "absolute",
    width: (props) => props.width,
    height: (props) => props.height,
    background: (props) =>
      props.toLeft
        ? "transparent linear-gradient(90deg, #585858 0%, #474747 100%) 0% 0% no-repeat padding-box"
        : "transparent linear-gradient(270deg, #6D6D6D 0%, #5B5B5B 100%) 0% 0% no-repeat padding-box;",
    top: (props) => props.top,
    left: (props) => props.left,
    right: (props) => props.right,
    bottom: (props) => props.bottom,
    transform: (props) => `translate(${props.translateX},${props.translateY})`,
    borderRadius: (props) => {
      if (props.toBottom) {
        return "0% 10% 0% 0% / 0% 41% 0% 0% ";
      }
      if (props.bottomArrow) {
        return "0% 0% 0% 2% / 0% 0% 0% 41% ";
      }
    },
    "&::after": {
      content: "''",
      position: "absolute",
      left: (props) => (props.toLeft ? undefined : "100%"),
      right: (props) => (props.toLeft ? "100%" : undefined),
      top: (props) => (props.toBottom ? "calc(100% + 10px)" : 0),
      width: 0,
      height: 0,
      borderWidth: (props) => props.height / 2,
      borderStyle: "solid",
      borderColor: "transparent",
      borderLeftColor: (props) =>
        props.toLeft || props.toBottom ? "transparent" : "#6D6D6D",
      borderRightColor: (props) => (props.toLeft ? "#585858" : "transparent"),
      borderTopColor: (props) => (props.toBottom ? "#6D6D6D" : "transparent"),
      transform: (props) => props.toBottom && "translate(-100%,0)",
    },
  },
  bottomBlock: {
    position: "absolute",
    width: (props) => props.height,
    height: 12,
    right: 0,
    bottom: 1,
    transform: "translate(0,100%)",
    backgroundColor: (props) => (props.toLeft ? "#585858" : "#6D6D6D"),
  },
  text: {
    fontSize: 11,
    fontWeight: 500,
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: (props) => props.textLeft || 20,
    transform: "translate(0,-50%)",
  },
  data: {
    fontSize: 14,
    fontWeight: 500,
    color: "#fff",
    position: "absolute",
    top: "50%",
    right: (props) => props.dataRight || 17,
    transform: "translate(0,-50%)",
  },
});

const Arrow = (props) => {
  const classes = useStyles(props);
  const { data, text, unit, textStyle, dataStyle } = props;

  return (
    <div className={classes.rectangle}>
      <span style={{ textStyle }} className={classes.text}>
        {text}
      </span>
      <span style={{ dataStyle }} className={classes.data}>
        {!isNil(data) && !isNil(data.value) ? (
          <Tooltip title={data.time ? data.time : ""}>
            <span>{data.value + " " + (unit || "°C")}</span>
          </Tooltip>
        ) : null}
      </span>
      {props.toBottom && <div className={classes.bottomBlock}></div>}
      {props.children}
    </div>
  );
};

export default Arrow;
