import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";

import { HxBlockMeasurementsGraph } from "./HxBlockMeasurementsGraph";
import HxTwinInfo from "./HxTwinInfo";
import CardWithTitle from "../components/layout/CardWithTitle";
import OperationLogs from "../dashboard/logs/OperationLogs";
import useBuildingTimezone from "../utils/hooks/useBuildingTimezone";
import useUserRole from "../utils/hooks/useUserRole";

const HxShow = ({ formData }) => {
  const [hxData, setHxData] = useState(null);
  const [hiddenContainers, setHiddenContainers] = useState([
    "logs",
    "measurements",
  ]);
  const { isInternalAdmin } = useUserRole();
  const buildingTimezone = useBuildingTimezone();

  useEffect(() => {
    if (formData.Hx && !hxData) {
      setHxData(formData.Hx);
    }
  }, [formData.Hx, hxData]);

  const handleContainerHide = useCallback(
    (container) => {
      if (!hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if (hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers]
  );

  if (
    formData.BuildingId &&
    (formData.Hx || hxData) &&
    formData.BlockType === "HX"
  ) {
    let hx = formData.Hx ? formData.Hx : hxData;
    let _formData = { ...formData, Hx: hx };
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HxTwinInfo {..._formData} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          style={{
            display:
              hiddenContainers.includes("logs") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs
              buildingId={_formData.BuildingId}
              twinId={_formData.id}
              showContainer={handleContainerShow}
              hideContainer={handleContainerHide}
            />
          </CardWithTitle>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("measurements") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <HxBlockMeasurementsGraph
            timezone={buildingTimezone}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default HxShow;
