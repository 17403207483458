import React from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  TextInput,
  NumberInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
} from "react-admin";
import { FormDataConsumer } from "react-admin";

import SelectValueStorageVariablesInput from "../components/SelectValueStorageVariablesInput";
import { minValue, maxValue } from "../validationHelper";
import { stateMapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";
import { validateOutputVariable } from "./CompensationFunctionCreate";

const CompensationFunctionEdit = ({ BuildingId, ...props }) => {
  // Remove unused props to get rid of errors
  let editProps = Object.assign({}, props);
  delete editProps.buildingTwinId;
  delete editProps.buildingSelected;
  delete editProps.dispatch;
  return (
    <>
      <PageTitle title="Edit Function" />
      <Edit {...editProps} redirect="edit">
        <SimpleForm>
          <TextInput
            disabled
            label="Function version"
            source="FunctionVersion"
          />
          <BooleanInput label="Active" source="Active" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionVersion && (
                <SelectInput
                  source="FunctionType"
                  choices={[
                    {
                      id: "ALERT",
                      name: "ALERT",
                    },
                    {
                      id: "AVG",
                      name: "AVERAGE",
                    },
                    {
                      id: "CONSUMPTION",
                      name: "CONSUMPTION",
                    },
                    {
                      id: "COP",
                      name: "COP",
                    },
                    {
                      id: "FORMULA",
                      name: "FORMULA",
                    },
                    {
                      id: "INTEGRAL",
                      name: "INTEGRAL",
                    },
                    {
                      id: "LINEAR",
                      name: "LINEAR",
                    },
                    {
                      id: "PASSTHROUGH",
                      name: "PASSTHROUGH",
                    },
                    {
                      id: "PID",
                      name: "PID",
                    },
                    {
                      id: "SPOT",
                      name: "SPOT",
                    },
                  ]}
                  options={{ multiple: false }}
                  allowEmpty
                  required
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "PID" && (
                <div>
                  <NumberInput label="f_pid_gain" source="PidParams.PidGain" />
                  <NumberInput label="f_pid_min" source="PidParams.PidMin" />
                  <NumberInput label="f_pid_max" source="PidParams.PidMax" />
                  <NumberInput
                    label="f_pid_ti (h)"
                    source="PidParams.PidTi"
                    format={(v) => Math.round(v / 60 / 60)}
                    parse={(v) => Math.round(v * 60 * 60)}
                  />
                  <NumberInput
                    label="f_pid_target"
                    source="PidParams.PidTarget"
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "LINEAR" && (
                <TextInput
                  disabled
                  label="graphid"
                  source="LinearParams.Graph.GraphId"
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "LINEAR" && (
                <TextInput
                  multiline
                  label="Graph name"
                  source="LinearParams.Graph.Name"
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData.FunctionType &&
              formData.FunctionType === "LINEAR" && (
                <ArrayInput source="LinearParams.Graph.Coordinates">
                  <SimpleFormIterator>
                    <TextInput label="input" source="X" />
                    <TextInput label="output" source="Y" />
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "AVG" && (
                <React.Fragment>
                  <NumberInput
                    label="Avg interval"
                    source="TimeInterval"
                    step={1}
                    validate={validateInterval}
                  />
                  <span>
                    Interval withing 1-24 hours (1=1h etc... from last
                    measurement)
                  </span>
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType !== "SPOT" && (
                <SelectValueStorageVariablesInput
                  buildingId={BuildingId}
                  source="ValueStorageVariables"
                  allowEmpty
                  options={{ fullWidth: true }}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "FORMULA" && (
                <React.Fragment>
                  <TextInput multiline source="Formula" />
                  <SelectInput
                    source="FormulaEvalType"
                    choices={[
                      {
                        id: "BOOL",
                        name: "BOOL",
                      },
                      {
                        id: "DOUBLE",
                        name: "DOUBLE",
                      },
                    ]}
                  />
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "CONSUMPTION" && (
                <TextInput source="RunInterval" defaultValue="1h" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "COP" && (
                <div>
                  <SelectArrayInput
                    label="COP inputs"
                    // optionText="VariableName"
                    // optionValue="VariableName"
                    source="CopParams.Inputs"
                    choices={GetCopValues(formData)}
                    style={{ display: "block" }}
                    options={{ fullWidth: true }}
                  />
                  <SelectArrayInput
                    label="COP outputs"
                    // optionText="VariableName"
                    // optionValue="VariableName"
                    source="CopParams.Outputs"
                    choices={GetCopValues(formData)}
                    style={{ display: "block" }}
                    options={{ fullWidth: true }}
                  />
                  <SelectArrayInput
                    label="COP additional inputs"
                    // optionText="VariableName"
                    // optionValue="VariableName"
                    source="CopParams.AdditionalInputs"
                    choices={GetCopValues(formData)}
                    style={{ display: "block" }}
                    options={{ fullWidth: true }}
                  />
                  <TextInput
                    label="variable prefix"
                    source="OutputVariablePrefix"
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "SPOT" && (
                <NumberInput source="SpotWindowStartHour" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "SPOT" && (
                <NumberInput source="SpotWindowEndHour" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "SPOT" && (
                <NumberInput source="SpotWindowHours" />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "ALERT" && (
                <React.Fragment>
                  <ArrayInput source="AlertFormulas">
                    <SimpleFormIterator>
                      <TextInput
                        multiline
                        label="Alert formula"
                        source="Formula"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <ArrayInput source="AlertExcludeFormulas">
                    <SimpleFormIterator>
                      <TextInput
                        multiline
                        label="Alert exclude formula"
                        source="Formula"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <NumberInput
                    label="Alert delay minutes"
                    source="AlertDelayMinutes"
                  />
                  <SelectInput
                    label="Alert mode"
                    source="AlertMode"
                    choices={[
                      {
                        id: "MIN",
                        name: "MIN",
                      },
                      {
                        id: "MAX",
                        name: "MAX",
                      },
                      {
                        id: "AVG",
                        name: "AVG",
                      },
                    ]}
                  />
                </React.Fragment>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType !== "CONSUMPTION" && (
                <TextInput
                  multiline
                  label="Output variable name"
                  source="Name"
                  validate={[required(), validateOutputVariable]}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "CONSUMPTION" && (
                <TextInput
                  multiline
                  label="Output variable name"
                  source="Name"
                  defaultValue="CONSUMPTION_"
                  disabled={true}
                />
              )
            }
          </FormDataConsumer>
          <TextInput
            multiline
            source="ValueStorageDescription"
            validate={required()}
          />
          <TextInput label="Output SI unit" source="OutputUnit" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.FunctionType &&
              formData.FunctionType === "FORMULA" && (
                <BooleanInput
                  label="Output is usable as block input"
                  source="OutputIsUsableAsBlockInput"
                />
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};

function GetCopValues(formData) {
  return formData.ValueStorageVariables.map((v) => {
    return { id: v, name: v };
  });
}

const validateInterval = [required(), minValue(1), maxValue(24)];

export default stateMapper(CompensationFunctionEdit);
