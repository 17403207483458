import React from "react";
import { useSelector } from "react-redux";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  TextInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";
import { FormDataConsumer } from "react-admin";
import { DateInputNoTimezone } from "../components/TimeInputs";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import OperationTooltip from "./OperationTooltip";
import PageTitle from "../components/layout/PageTitle";
import { stateMapper } from "../customReducers/connectFunctions";
import twinForExternal from "../utils/twinForExternal";

const dateToday = () => moment().utc().startOf("day").format();

const blockOptions = (choice) =>
  `${choice.BlockName} - ${choice.BlockDescription}`;

const useStyles = makeStyles(() => ({
  formInputs: {
    width: "70%",
  },
  hidden: {
    display: "none",
  },
}));

const Category = (props) => {
  const customerNoteId =
    props &&
    props.choices &&
    props.choices.find((c) => c.Name === "Customer note")
      ? props.choices.find((c) => c.Name === "Customer note").id
      : null;
  return customerNoteId ? (
    <SelectInput
      optionText="Name"
      initialValue={customerNoteId}
      {...props}
      InputProps={{ disabled: true }}
    />
  ) : null;
};

const OperationsCreateCustomer = ({ BuildingId, ...props }) => {
  const classes = useStyles();

  const building = useSelector((state) => state.buildingFilter.Building);

  // Remove unused props to get rid of errors
  let createProps = Object.assign({}, props);
  delete createProps.buildingTwinId;
  delete createProps.buildingSelected;
  delete createProps.dispatch;
  return (
    <>
      <PageTitle title="Create Operation" />
      <Create {...createProps}>
        <SimpleForm redirect="list">
          <ReferenceInput
            className={classes.hidden}
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ showBAUI: true }}
            perPage={1000}
            fullWidth
            defaultValue={BuildingId}
            validate={required("Please choose a building")}
          >
            <AutocompleteInput
              optionText="ProjectName"
              optionValue="id"
              options={{
                fullWidth: true,
                InputProps: {
                  disabled: true,
                },
              }}
            />
          </ReferenceInput>

          <ReferenceInput
            className={classes.hidden}
            label="Category"
            source="OperationMeta"
            reference="operationmeta"
            filter={{ Parent_id: 0 }}
            sort={{ field: "id", order: "ASC" }}
          >
            <Category />
          </ReferenceInput>

          {building && (
            <TextField
              record={building}
              source="ProjectName"
              label="Building"
            />
          )}

          <DateInputNoTimezone
            className={classes.hidden}
            disabled
            source="Date"
            label="Date created *"
            validate={required()}
            defaultDate={dateToday()}
          />

          <OperationTooltip field="Title">
            <TextInput
              className={classes.formInputs}
              source="Title"
              validate={required("Title required")}
            />
          </OperationTooltip>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <OperationTooltip field="OperationBlocks">
                <ReferenceArrayInput
                  label="Digital twins"
                  source="OperationBlocks"
                  reference="blocks"
                  resource="blocks"
                  perPage={1000}
                  sort={{ field: "BlockName", order: "ASC" }}
                  filter={{
                    BuildingId: formData.BuildingId,
                  }}
                  fullWidth
                >
                  <AutocompleteArrayInput
                    optionText={blockOptions}
                    optionValue="id"
                    matchSuggestion={(_filter, choice) => {
                      return twinForExternal(choice);
                    }}
                  />
                </ReferenceArrayInput>
              </OperationTooltip>
            )}
          </FormDataConsumer>

          <BooleanInput
            className={classes.hidden}
            label="Visible to customer"
            source="VisibleToExternal"
            defaultValue={true}
            disabled
          />

          <TextInput
            multiline
            label="Comments"
            source="ExternalComment"
            className={classes.formInputs}
          />

          <SelectInput
            className={classes.hidden}
            source="State"
            defaultValue="1. Active"
            choices={[
              { id: "1. Active", name: "1. Active" },
              { id: "2. Delayed", name: "2. Delayed" },
              { id: "3. Completed", name: "3. Completed" },
            ]}
            options={{ disabled: true }}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(OperationsCreateCustomer);
