import React, { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core";

import CardWithTitle from "../../../components/layout/CardWithTitle";
import MainTank from "./MainTank";
import ResolveHpVariables from "./ResolveHpVariables";

import useBuildingTimezone from "../../../utils/hooks/useBuildingTimezone";
import { ResolveHpData, ResolveHpPumpData } from "./ResolveHpData";

import COP from "./COP";

const useStyles = makeStyles({
  root: {
    minHeight: 640,
    width: "100%",
    position: "relative",
    overflowX: "auto",
  },
});

const HpInputImage = ({
  Hp: hp,
  BuildingId: buildingId,
  BlockName: twinName,
  HpOperations: operations,
}) => {
  const classes = useStyles();
  const [inputData, setInputData] = useState();
  const [energyPoints, setEnergyPoints] = useState();
  const [pumpData, setPumpData] = useState();
  const [existing, setExisting] = useState([]);
  const tz = useBuildingTimezone();

  const dataProvider = useDataProvider();

  // Get HP data
  useEffect(() => {
    const name = typeof twinName === "string" ? twinName.toUpperCase() : "";
    const [variables, exs, points] = ResolveHpVariables(hp, name, operations);
    setExisting(exs);

    const now = new Date();

    // Get the last 3h data, but only show latest
    const from = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours() - 3,
      now.getMinutes(),
      now.getSeconds()
    ).toISOString();

    const to = now.toISOString();

    // Get RAU data
    dataProvider
      .getList("valuestorage", {
        filter: {
          BuildingId: buildingId,
          VariableNames: variables,
          DataType: "RAU",
          ValuesFrom: from,
          ValuesTo: to,
        },
        sort: { field: "UpdateTime", order: "DESC" },
        pagination: {},
      })
      .then((res) => {
        if (res && res.data) {
          const { data } = res;
          const d = ResolveHpData(hp, data, tz, name);
          setInputData(d);
          const pumpD = ResolveHpPumpData(hp, data, tz);
          setPumpData(pumpD);
        }
      });

    // Get energy points / function data
    // Get RAU data
    dataProvider
      .getList("valuestorage", {
        filter: {
          BuildingId: buildingId,
          VariableNames: points,
          DataType: "FUNCTION",
          ValuesFrom: from,
          ValuesTo: to,
        },
        sort: { field: "UpdateTime", order: "DESC" },
        pagination: {},
      })
      .then((res) => {
        if (res && res.data) {
          const { data } = res;
          const energyPoints = ResolveHpData(hp, data, tz, name);
          setEnergyPoints(energyPoints);
        }
      });
  }, [hp, buildingId, dataProvider, tz, twinName, operations]);

  let heatSource = get(hp, "HeatSource", " ").toLowerCase();
  heatSource =
    heatSource && typeof heatSource === "string"
      ? heatSource[0].toUpperCase() + heatSource.slice(1)
      : null;

  return (
    <>
      <CardWithTitle
        title={heatSource ? `Heating - ${heatSource} sourced` : "Heating"}
      >
        <div className={classes.root}>
          {existing &&
            existing.includes("cop") &&
            existing.includes("systemCop") && (
              <COP energyPoints={energyPoints} />
            )}
          <MainTank
            pumps={pumpData}
            heatSource={heatSource}
            inputData={inputData}
            energyPoints={energyPoints}
            existing={existing}
          />
        </div>
      </CardWithTitle>
    </>
  );
};

export default HpInputImage;
