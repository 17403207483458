import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import PageTitle from "../components/layout/PageTitle";

const validateIP = [
  required(),
  regex(
    /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/,
    "Please enter a valid IP address"
  ),
];

const validateSlaveAddress = (adapterType) => {
  if (adapterType.toUpperCase() === "IOTHUB") {
    return [];
  }
  return required();
};

const useStyles = makeStyles(() => ({
  formInputs: {
    width: "70%",
  },
}));

export const BusAdapterEdit = (props) => {
  const classes = useStyles();
  return (
    <>
      <PageTitle title="Edit Bus Adapter" />
      <Edit {...props}>
        <SimpleForm>
          <ReferenceInput
            source="BuildingId"
            reference="buildings"
            filter={{ showBAUI: true }}
            className={classes.formInputs}
          >
            <AutocompleteInput optionText="ProjectName" validate={required()} />
          </ReferenceInput>
          <SelectInput
            source="AdapterType"
            choices={[
              { id: "MODBUS", name: "MODBUS" },
              { id: "IOTHUB", name: "IOTHUB" },
            ]}
            validate={required("You must choose Adapter type")}
            className={classes.formInputs}
          />
          <TextInput
            source="IP"
            validate={validateIP}
            className={classes.formInputs}
          />
          <NumberInput
            source="port"
            validate={required()}
            className={classes.formInputs}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.AdapterType && (
                <NumberInput
                  source="SlaveAddress"
                  validate={validateSlaveAddress(formData.AdapterType)}
                  className={classes.formInputs}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.AdapterType === "IOTHUB" && (
                <TextInput
                  label="Device id"
                  source="DeviceId"
                  validate={required()}
                  className={classes.formInputs}
                />
              )
            }
          </FormDataConsumer>
          <SelectInput
            source="DataSource"
            validate={required()}
            choices={[
              { id: "RAU", name: "RAU" },
              { id: "OTHER", name: "OTHER" },
            ]}
            className={classes.formInputs}
          />
          <BooleanInput source="Active" />
        </SimpleForm>
      </Edit>
    </>
  );
};
