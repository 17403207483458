import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@material-ui/core";

import AhuTwinInfo from "./AhuTwinInfo";
import { AhuBlockMeasurementsGraph } from "./AhuBlockMeasurementsGraph";
import AhuBlockAirFlowGraph from "./AhuBlockAirFlowGraph";
import { AhuMonthlyEnergyGraph } from "./AhuMonthlyEnergyGraph";
import { AhuWeeklyFlowIntegralGraph } from "./AhuWeeklyFlowIntegralGraph";
import CardWithTitle from "../components/layout/CardWithTitle";
import OperationLogs from "../dashboard/logs/OperationLogs";
import useBuildingTimezone from "../utils/hooks/useBuildingTimezone";
import useUserRole from "../utils/hooks/useUserRole";
import AhuHrEfficiencyGraph from "./ahu/AhuHREfficiencyGraph";

const AhuShow = ({ formData }) => {
  const [ahuData, setAhuData] = useState(null);
  const [trends, setTrends] = useState(null);
  const [hiddenContainers, setHiddenContainers] = useState([
    "logs",
    "integral",
    "flow",
    "energy",
    "measurements",
    "hr",
  ]);
  const { isInternalAdmin } = useUserRole();
  const buildingTimezone = useBuildingTimezone();

  useEffect(() => {
    if (formData.Ahu && !ahuData) {
      setAhuData(formData.Ahu);
    }
    if (formData.Trends && formData.Trends.length > 0 && !trends) {
      setTrends(formData.Trends);
    }
  }, [formData.Ahu, ahuData, formData.Trends, trends]);

  const handleContainerHide = useCallback(
    (container) => {
      if (!hiddenContainers.includes(container)) {
        setHiddenContainers([...hiddenContainers, container]);
      }
    },
    [hiddenContainers]
  );

  const handleContainerShow = useCallback(
    (container) => {
      if (hiddenContainers.includes(container)) {
        setHiddenContainers(hiddenContainers.filter((r) => r !== container));
      }
    },
    [hiddenContainers]
  );

  if (
    formData.BuildingId &&
    (formData.Ahu || ahuData) &&
    ((formData.Trends && formData.Trends.length > 0) || trends) &&
    formData.BlockType === "AHU"
  ) {
    let ahu = formData.Ahu ? formData.Ahu : ahuData;
    let tr =
      formData.Trends && formData.Trends.length > 0 ? formData.Trends : trends;
    let _formData = { ...formData, Ahu: ahu, Trends: tr };
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithTitle spacerColor="#2E2247" title="Input status">
            <AhuTwinInfo {..._formData} />
          </CardWithTitle>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          style={{
            display:
              hiddenContainers.includes("logs") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <CardWithTitle spacerColor="#2E2247" title="Operation log">
            <OperationLogs
              buildingId={_formData.BuildingId}
              twinId={_formData.id}
              showContainer={handleContainerShow}
              hideContainer={handleContainerHide}
            />
          </CardWithTitle>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("integral") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <AhuWeeklyFlowIntegralGraph
            timezone={buildingTimezone}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("flow") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <AhuBlockAirFlowGraph
            timezone={buildingTimezone}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("energy") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <AhuMonthlyEnergyGraph
            timezone={buildingTimezone}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("measurements") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <AhuBlockMeasurementsGraph
            timezone={buildingTimezone}
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              hiddenContainers.includes("hr") && !isInternalAdmin
                ? "none"
                : undefined,
          }}
        >
          <AhuHrEfficiencyGraph
            {..._formData}
            showContainer={handleContainerShow}
            hideContainer={handleContainerHide}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default AhuShow;
