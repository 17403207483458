import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  Create,
  TextInput,
  ReferenceArrayInput,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  FileInput,
  FileField,
} from "react-admin";
import { FormDataConsumer } from "react-admin";
import { stateMapper } from "../customReducers/connectFunctions";
import { DateInputNoTimezone } from "../components/TimeInputs";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import OperationTooltip from "./OperationTooltip";
import PageTitle from "../components/layout/PageTitle";

const dateToday = () => moment().utc().startOf("day").format();

const blockOptions = (choice) =>
  `${choice.BlockName} - ${choice.BlockDescription}`;

const validateHttp = regex(/(http|https):\/\/.+/, "Please enter a valid url");

const useStyles = makeStyles(() => ({
  formInputs: {
    width: "70%",
  },
  hidden: {
    display: "none",
  },
}));

const OperationsCreate = ({ BuildingId, ...props }) => {
  const classes = useStyles();
  // Remove unused props to get rid of errors
  let createProps = Object.assign({}, props);
  delete createProps.buildingTwinId;
  delete createProps.buildingSelected;
  delete createProps.dispatch;

  return (
    <>
      <PageTitle title="Create Operation" />
      <Create {...createProps}>
        <SimpleForm redirect="list">
          <ReferenceInput
            className={classes.formInputs}
            label="Building"
            source="BuildingId"
            reference="buildings"
            sort={{ field: "ProjectName", order: "ASC" }}
            filter={{ showBAUI: true }}
            perPage={1000}
            allowEmpty
            fullWidth
            defaultValue={BuildingId}
            validate={required("Please choose a building")}
          >
            <AutocompleteInput
              optionText="ProjectName"
              optionValue="id"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>

          <OperationTooltip field="ProjectCharterLink">
            <TextInput
              className={classes.formInputs}
              multiline
              source="ProjectCharterLink"
              label="Link to project charter"
              validate={[validateHttp]}
            />
          </OperationTooltip>

          <ReferenceInput
            className={classes.formInputs}
            label="Category"
            source="MetaParent"
            reference="operationmeta"
            filter={{ Parent_id: 0 }}
            sort={{ field: "id", order: "ASC" }}
            validate={required("Please choose a category")}
          >
            <SelectInput className={classes.formInputs} optionText="Name" />
          </ReferenceInput>

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.MetaParent && formData.MetaParent !== 14) {
                return (
                  <ReferenceInput
                    className={classes.formInputs}
                    label="Subcategory"
                    source="OperationMeta"
                    reference="operationmeta"
                    filter={{ Parent_id: formData.MetaParent }}
                    validate={required("Please choose a subcategory")}
                  >
                    <SelectInput
                      className={classes.formInputs}
                      optionText="Name"
                      allowEmpty
                    />
                  </ReferenceInput>
                );
              }
              if (formData.MetaParent === 14) {
                return (
                  <NumberInput
                    source="OperationMeta"
                    className={classes.hidden}
                    initialValue={14}
                  />
                );
              }
            }}
          </FormDataConsumer>

          <DateInputNoTimezone
            source="Date"
            label="Date created *"
            validate={required()}
            defaultDate={dateToday()}
          />
          <OperationTooltip field="Title">
            <TextInput
              className={classes.formInputs}
              source="Title"
              validate={required("Title required")}
            />
          </OperationTooltip>

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <OperationTooltip field="OperationBlocks">
                <ReferenceArrayInput
                  label="Digital twins"
                  source="OperationBlocks"
                  reference="blocks"
                  perPage={10}
                  sort={{ field: "BlockName", order: "ASC" }}
                  filter={{
                    BuildingId: formData.BuildingId,
                  }}
                  fullWidth
                >
                  <AutocompleteArrayInput
                    optionText={blockOptions}
                    optionValue="id"
                  />
                </ReferenceArrayInput>
              </OperationTooltip>
            )}
          </FormDataConsumer>

          <OperationTooltip field="InternalComment">
            <TextInput
              multiline
              label="Internal comments"
              source="InternalComment"
              className={classes.formInputs}
            />
          </OperationTooltip>

          <OperationTooltip field="VisibleToExternal">
            <BooleanInput
              label="Visible to customer"
              source="VisibleToExternal"
            />
          </OperationTooltip>

          <OperationTooltip field="ExternalComment">
            <TextInput
              multiline
              label="Comments to customer"
              source="ExternalComment"
              className={classes.formInputs}
            />
          </OperationTooltip>
          <OperationTooltip field="State" tooltipMargin={50}>
            <SelectInput
              className={classes.formInputs}
              source="State"
              choices={[
                { id: "1. Active", name: "1. Active" },
                { id: "2. Delayed", name: "2. Delayed" },
                { id: "3. Completed", name: "3. Completed" },
              ]}
            />
          </OperationTooltip>
          <DateInputNoTimezone source="Deadline" label="Deadline" />
          <FileInput source="files" label="Attachments" multiple>
            <FileField source="src" title="Attachments" />
          </FileInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default stateMapper(OperationsCreate);
