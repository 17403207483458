import { sum, filter, isUndefined } from "lodash";
import { BuildingBlock } from "../types/leasegreen-core-api-exports";

export const getTwinStatus = (twins: any[] | any = [], alerts: any[] = []) => {
  // Check if twins is an array
  if (Array.isArray(twins)) {
    let alertCounts = twins.map((t) => {
      return getAlertCount(t, alerts);
    });
    return getStatusIcon(sum(alertCounts));
  } else {
    return getStatusIcon(getAlertCount(twins, alerts));
  }
};

export const getAlertCount = (twin: BuildingBlock, alerts: any[] = []) => {
  let twinAlerts = null;
  if (alerts && alerts.length > 0) {
    twinAlerts = filter(alerts, function(a) {
      return (
        a.BuildingId === twin.BuildingId &&
        a.BlockId === twin.id &&
        !a.AlertEndTime &&
        !a.Checked
      );
    });
  }
  let alertCount = 0;
  if (twinAlerts) {
    alertCount = twinAlerts.length;
  }
  return alertCount;
};

export const getStatusIcon = (alertCount: number) => {
  if (isUndefined(alertCount)) {
    return null;
  }
  if (alertCount === 0) {
    return false;
  } else {
    return true;
  }
};
