import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import DigitalTwin from "./DigitalTwin";
import { getTwinStatus } from "../../utils/twinAlerts";
import useUserRole from "../../utils/hooks/useUserRole";
import twinForExternal from "../../utils/twinForExternal";
import { BuildingBlock } from "../../types/leasegreen-core-api-exports";

const useStyles = makeStyles<any,IDigitalTwinsContainerProps>({
  root: {
    padding: "0 3rem",
    boxSizing: "border-box",
    minWidth: "100%",
  },
  container: {
    height: '100%',
    boxShadow: "0px 3px 10px #00000033",
    border: "1px dashed",
    borderColor: (props) => (props.alert ? "#D62B1A" : "#282331"),
    borderRadius: "20px",
    margin: 0,
    width: "100%",
    overflowY: (props) => props.ahuMap ? "auto" : null,
  },
  item: {
    margin: (props) => (props.twinsToRight ? "1em auto" : ""),
  },
});

interface IDigitalTwinsContainerProps {
  twinsToRight?: boolean;
  twins: BuildingBlock[];
  ahuMap: unknown;
  alert: boolean;
  alerts: unknown[];
}

const DigitalTwinsContainer = (props: IDigitalTwinsContainerProps) => {
  const classes = useStyles(props);
  const { isExternalUser } = useUserRole();
  return (
    <Grid
      container={props.twinsToRight ? undefined : true}
      item={props.twinsToRight ? true : undefined}
      spacing={props.twinsToRight ? undefined : 6}
      xs={12}
      className={classes.container}
    >
      {props && props.twins
        ? props.twins.map((t) => {
            const alert = getTwinStatus(t, props.alerts);
            return (
              <Grid
                xs={props.ahuMap ? 9 : 10}
                md={props.ahuMap ? 9 : props.twinsToRight ? 11 : 6}
                lg={props.ahuMap ? 9 : 4}
                key={t.id}
                className={classes.item}
                item
              >
                <DigitalTwin
                  ahuMap={props.ahuMap}
                  alert={!isExternalUser && alert}
                  name={t.BlockName}
                  desc={t.BlockDescription}
                  id={t.id}
                  disableLink={isExternalUser ? !twinForExternal(t) : false}
                />
              </Grid>
            );
          })
        : "No digital twins of this type"}
    </Grid>
  );
};

export default DigitalTwinsContainer;
