import { get } from "lodash";

const hasBusTag = (points = []) => {
  let tagExists = false;
  if (!points || !points.length) {
    return false;
  }
  points.forEach((p) => {
    if (p.BusTagName) {
      tagExists = true;
    }
  });
  return tagExists;
};

const ResolveHpVariables = (hp, twinName, operations) => {
  if (!hp) {
    return [null, null];
  }
  const variables = [];
  const exists = [];
  const points = [];

  const checkAndGet = (variable) => {
    if (get(hp, variable, null)) {
      variables.push(get(hp, variable, null));
    }
  };

  // Pumps
  if (hp.Pumps && hp.Pumps.length > 0) {
    hp.Pumps.forEach((p) => {
      if (p && p.AlertStatus && p.AlertStatus.BusTagName) {
        variables.push(p.AlertStatus.BusTagName);
      }
      if (p && p.RunningStatus && p.RunningStatus.BusTagName) {
        variables.push(p.RunningStatus.BusTagName);
      }
    });
  }

  // Source temperatures
  checkAndGet("SourceSupplyTE.BusTagName");
  checkAndGet("SourceReturnTE.BusTagName");

  // Heating tank data
  checkAndGet("HeatSupplyTE.BusTagName");
  checkAndGet("HeatReturnTE.BusTagName");
  checkAndGet("HeatingNetworkSupplyTE.BusTagName");
  checkAndGet("HeatingNetworkReturnTE.BusTagName");
  checkAndGet("HeatingTankTE.BusTagName");

  // Warm water tank data
  checkAndGet("WarmWaterSupplyTE.BusTagName");
  checkAndGet("WarmWaterReturnTE.BusTagName");
  checkAndGet("WarmWaterNetworkSupplyTE.BusTagName");
  checkAndGet("WarmWaterNetworkReturnTE.BusTagName");
  checkAndGet("WarmWaterTankTE.BusTagName");

  // Energypoints
  // Check what data to display and what to hide
  if (hp && hasBusTag(hp.InputEnergyPoints)) {
    exists.push("inputEnergy");
    points.push(`${twinName}_TOTAL_CONSUMED_ENERGY`);
    exists.push("importEnergy");
    points.push(`${twinName}_TOTAL_IMPORT_ENERGY`);
  }
  if (hp && hasBusTag(hp.HeatingEnergyPoints)) {
    exists.push("heatingEnergy");
    points.push(`${twinName}_TOTAL_HEATING_ENERGY`);
  }
  if (hp && hasBusTag(hp.HeatingBoilerResistorEnergyPoints)) {
    exists.push("heatingResistor");
    points.push(`${twinName}_TOTAL_HEATING_BOILER_RESISTOR_ENERGY`);
  }
  if (hp && hasBusTag(hp.WaterEnergyPoints)) {
    exists.push("waterEnergy");
    points.push(`${twinName}_TOTAL_WATER_ENERGY`);
  }
  if (hp && hasBusTag(hp.WaterBoilerResistorEnergyPoints)) {
    exists.push("waterResistor");
    points.push(`${twinName}_TOTAL_WATER_BOILER_RESISTOR_ENERGY`);
  }
  if (hp && hasBusTag(hp.RegenerationEnergyPoints)) {
    exists.push("regenerationEnergy");
    points.push(`${twinName}_TOTAL_REGENERATION_ENERGY`);
  }
  if (hp && hasBusTag(hp.CoolingEnergyPoints)) {
    exists.push("coolingEnergy");
    points.push(`${twinName}_TOTAL_COOLING_ENERGY`);
  }
  if (hp && hasBusTag(hp.CoolingEnergyPoints)) {
    exists.push("coolingEnergy");
    points.push(`${twinName}_TOTAL_COOLING_ENERGY`);
  }

  // COP and System COP
  if (
    operations &&
    operations.CalculateEnergyCop &&
    operations.CalculateEnergyCop.Active
  ) {
    exists.push("cop");
    exists.push("systemCop");
    points.push(`${twinName}_COP`);
    points.push(`${twinName}_SYSTEM_COP`);
  }
  return [variables, exists, points];
};

export default ResolveHpVariables;
