import { getProvider } from './App';
import { fetchUtils } from 'react-admin';
import jsonServerRestClient from "ra-data-json-server";
import addUploadConsFeature from "./dataprovider/uploadConsFeature";

const httpClient = async (url, _options) => {
  const options = { ..._options, headers: new Headers({ Accept: "application/json" }) };

  const msalProvider = await getProvider;
  try {
    const token = await msalProvider.getAccessToken({
      scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
    });
    options.headers.set("Authorization", "Bearer " + token.accessToken);
  } catch (e) {
    msalProvider.loginRedirect();
    return;
  }

  // Custom case for GET_MANY of registerlists, manually add buildingId
  if (
    url &&
    typeof url === "string" &&
    url.includes("registerlists") &&
    url.includes("id") &&
    !url.includes("_end") &&
    (!url.includes("buildingId") || !url.includes("BuildingId"))
  ) {
    url = url + "&BuildingId=" + sessionStorage.getItem("BuildingId");
  }

  return fetchUtils.fetchJson(url, options);
};

const restClient = jsonServerRestClient(
  process.env.REACT_APP_API_URL,
  httpClient
);

export const uploadCapableDataProvider = addUploadConsFeature(restClient);