import React from "react";
import {
  AutocompleteInput,
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
} from "react-admin";

import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import optionRenderer from "../utils/optionRenderer";

const HpScalingTitle = "Note! Check scaling. kWh=1, MWh=1000 etc...";

export const HpInputs = (formData) => (
  <div>
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.BuildingId &&
        formData.Hp &&
        (formData.Hp.HeatSource === "GROUND" ||
          formData.Hp.HeatSource === "AIR") && (
          <ArrayInput source="Hp.Pumps" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Pump running status"
                source="RunningStatus.BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <ReferenceInput
                label="Pump alert status"
                source="AlertStatus.BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        )
      }
    </FormDataConsumer>

    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Source supply TE"
        source="Hp.SourceSupplyTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />
    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Source return TE"
        source="Hp.SourceReturnTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
    </div>
    <div style={{ display: "block" }} />
    <div style={{ display: "inline-flex", width: "100%" }}>
      <Tooltip title={HpScalingTitle} placement="right-end">
        <HelpIcon style={{ margin: "auto" }} />
      </Tooltip>
      <ArrayInput source="Hp.InputEnergyPoints" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="Input energy points"
            source="BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            //perPage={10}
            allowEmpty={true}
            fullWidth
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling factor"
            source="ScalingFactor"
            validate={required()}
            //defaultValue={1}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>

    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Heating supply TE"
        source="Hp.HeatSupplyTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
    </div>

    <div style={{ display: "block" }} />
    <div style={{ display: "inline-flex", width: "100%" }}>
      <ReferenceInput
        label="Heating return TE"
        source="Hp.HeatReturnTE.BusTagName"
        reference="registerlists"
        filter={{ buildingId: formData.BuildingId }}
        //perPage={10}
        allowEmpty={true}
      >
        <AutocompleteInput
          optionText={optionRenderer}
          optionValue="PointId"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
    </div>

    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.BuildingId &&
        formData.Hp &&
        (formData.Hp.HeatSource === "GROUND" ||
          formData.Hp.HeatSource === "AIR") && [
          <ReferenceInput
            label="Warm water supply temperature"
            source="Hp.WarmWaterSupplyTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
          <ReferenceInput
            label="Warm water return temperature"
            source="Hp.WarmWaterReturnTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
        ]
      }
    </FormDataConsumer>

    <div style={{ display: "block" }} />
    <div style={{ display: "inline-flex", width: "100%" }}>
      <Tooltip title={HpScalingTitle} placement="right-end">
        <HelpIcon style={{ margin: "auto" }} />
      </Tooltip>
      <ArrayInput source="Hp.OutputEnergyPoints">
        <SimpleFormIterator>
          <ReferenceInput
            label="Output energy points"
            source="BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            //perPage={10}
            allowEmpty={true}
            fullWidth
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling factor"
            source="ScalingFactor"
            validate={required()}
            //defaultValue={1}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
    <div style={{ display: "block" }} />
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.BuildingId &&
        formData.Hp &&
        (formData.Hp.HeatSource === "GROUND" ||
          formData.Hp.HeatSource === "AIR") && [
          <ArrayInput source="Hp.HeatingEnergyPoints" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Heating energy points"
                source="BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <NumberInput
                label="Scaling factor"
                source="ScalingFactor"
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>,
          <ArrayInput source="Hp.WaterEnergyPoints" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Water energy points"
                source="BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <NumberInput
                label="Scaling factor"
                source="ScalingFactor"
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>,
          <ArrayInput source="Hp.CoolingEnergyPoints" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Cooling energy points"
                source="BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <NumberInput
                label="Scaling factor"
                source="ScalingFactor"
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>,
          <ArrayInput source="Hp.RegenerationEnergyPoints" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Regeneration energy points"
                source="BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <NumberInput
                label="Scaling factor"
                source="ScalingFactor"
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>,
        ]
      }
    </FormDataConsumer>
    <div style={{ display: "inline-flex", width: "100%" }}>
      <Tooltip title={HpScalingTitle} placement="right-end">
        <HelpIcon style={{ margin: "auto" }} />
      </Tooltip>
      <ArrayInput source="Hp.AdditionalHeatingUnitEnergyPoints" fullWidth>
        <SimpleFormIterator>
          <ReferenceInput
            label="Additional heating unit energy points"
            source="BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            //perPage={10}
            allowEmpty={true}
            fullWidth
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>
          <NumberInput
            label="Scaling factor"
            source="ScalingFactor"
            validate={required()}
            //defaultValue={1000}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </div>

    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.BuildingId &&
        formData.Hp &&
        (formData.Hp.HeatSource === "GROUND" ||
          formData.Hp.HeatSource === "AIR") && [
          <ArrayInput source="Hp.HeatingBoilerResistorEnergyPoints" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Heating boiler resistor energy points"
                source="BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <NumberInput
                label="Scaling factor"
                source="ScalingFactor"
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>,
          <ReferenceInput
            label="Heating tank temperature"
            source="Hp.HeatingTankTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
          <ReferenceInput
            label="Heating network supply temperature"
            source="Hp.HeatingNetworkSupplyTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
          <ReferenceInput
            label="Heating network return temperature"
            source="Hp.HeatingNetworkReturnTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
          <ArrayInput source="Hp.WaterBoilerResistorEnergyPoints" fullWidth>
            <SimpleFormIterator>
              <ReferenceInput
                label="Water boiler resistor energy points"
                source="BusTagName"
                reference="registerlists"
                filter={{ buildingId: formData.BuildingId }}
                allowEmpty={true}
                fullWidth
              >
                <AutocompleteInput
                  optionText={optionRenderer}
                  optionValue="PointId"
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
              <NumberInput
                label="Scaling factor"
                source="ScalingFactor"
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>,
          <ReferenceInput
            label="Warm water tank temperature"
            source="Hp.WarmWaterTankTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
          <ReferenceInput
            label="Warm water network supply temperature"
            source="Hp.WarmWaterNetworkSupplyTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
          <ReferenceInput
            label="Warm water network return temperature"
            source="Hp.WarmWaterNetworkReturnTE.BusTagName"
            reference="registerlists"
            filter={{ buildingId: formData.BuildingId }}
            allowEmpty={true}
          >
            <AutocompleteInput
              optionText={optionRenderer}
              optionValue="PointId"
              options={{
                fullWidth: true,
              }}
            />
          </ReferenceInput>,
        ]
      }
    </FormDataConsumer>
  </div>
);
