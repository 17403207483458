import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import OperationPinButton from "../../operations/OperationPinButton";

const useStyles = makeStyles(() => ({
  root: {
    height: "auto",
    width: "100%",
    boxSizing: "border-box",
    padding: "0.3em 0.5em 0.3em 1.5em",
    color: "#282331",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(164, 175, 183, 0.5)",
    display: "flex",
  },
  link: {
    transition: "margin .25s ease",
    "&:hover": {
      marginLeft: 5,
    },
  },
  subCategory: {
    fontSize: "0.75rem",
    fontWeight: 300,
  },
  title: {
    fontSize: "1rem",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  info: { fontSize: "0.75rem", fontWeight: 300 },
}));

const OperationLog = (props) => {
  const classes = useStyles();

  let dateStr = "#282331";
  // Form date
  if (props && props.date) {
    const d = new Date(props.date);
    dateStr = d.toLocaleDateString();
  }
  const titleWeight = props.active ? 600 : 400;

  return (
    <Box className={classes.root}>
      <RouterLink
        to={`/operationlog?id=${props.id}`}
        className={classes.link}
        style={{ textDecoration: "none", color: "#282331", maxWidth: "85%" }}
      >
        <Typography className={classes.subCategory}>
          {props && props.subCategory ? props.subCategory : null}
        </Typography>
        <Typography
          style={{ fontWeight: titleWeight }}
          className={classes.title}
        >
          {props && props.title ? props.title : "Unknown log"}
        </Typography>
        <Typography className={classes.info}>
          {dateStr ? dateStr : null}
          <span style={{ margin: "1rem" }}>|</span>
          {props && props.user ? props.user : null}
        </Typography>
      </RouterLink>
      <OperationPinButton
        id={props.id}
        pinned={props.pinned}
        isPermissions={props.isPermissions}
      />
    </Box>
  );
};

export default OperationLog;
