import { UPDATE } from "react-admin";
import { UPDATE_MANY } from "react-admin";

export const CHECK_ALERT = "CHECK_ALERT";

export const checkAlert = (id, data, basePath) => ({
  type: CHECK_ALERT,
  payload: { id, data: { ...data, Check: true } },
  meta: {
    resource: "alerts",
    fetch: UPDATE,
    refresh: true
  }
});

export const CHECK_MANY_ALERTS = "CHECK_MANY_ALERTS";

export const checkManyAlerts = (ids, data, basePath) => ({
  type: CHECK_MANY_ALERTS,
  payload: { ids, data: { Check: true } },
  meta: {
    resource: "alerts",
    fetch: UPDATE_MANY,
    refresh: true
  }
});

export const UNCHECK_ALERT = "UNCHECK_ALERT";

export const unCheckAlert = (id, data, basePath) => ({
  type: UNCHECK_ALERT,
  payload: { id, data: { ...data, Check: false } },
  meta: {
    resource: "alerts",
    fetch: UPDATE,
    refresh: true
  }
});

export const UNCHECK_MANY_ALERTS = "UNCHECK_MANY_ALERTS";

export const unCheckManyAlerts = (ids, data, basePath) => ({
  type: UNCHECK_MANY_ALERTS,
  payload: { ids, data: { Check: false } },
  meta: {
    resource: "alerts",
    fetch: UPDATE_MANY,
    refresh: true
  }
});
