import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Boost from "highcharts/modules/boost";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import moment from "moment";

import { find, groupBy, map, round, sortBy, cloneDeep } from "lodash";
import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";

Boost(Highcharts);
HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id, sources) => {
  if (!Array.isArray(sources)) {
    sources = [sources];
  }

  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => {
      if (sources.includes(v.PointSource)) return v.ReportingPointId;
    });
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (dataFromReactAdmin) => {
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = new Date(v.valueTime);
    return [date.getTime(), round(v.value, 2)];
  });

  return highChartsData;
};

export class AhuBlockMeasurementsGraph extends Component {
  constructor(props) {
    super(props);
    this.afterChartCreated = this.afterChartCreated.bind(this);

    this.state = {
      record: this.props,
      optionsDay: this.graphOptions(),
      optionsMonth: this.graphOptions(true),
      resolution: "day",
      fetchDone: false,
    };
  }

  graphOptions(monthlyData = false) {
    return {
      chart: {
        height: "800px",
        //renderTo: "container",
        alignTicks: true,
        zoomType: "x",
      },
      legend: {
        enabled: true,
      },
      time: {
        timezone: this.props.timezone,
      },
      rangeSelector: {
        inputEnabled: false,
        allButtonsEnabled: true,
        // selected: 1,
        buttonTheme: {
          width: "auto",
        },
        buttons: monthlyData
          ? [
              {
                type: "month",
                count: 3,
                text: "3m",
              },
              {
                type: "month",
                count: 12,
                text: "12m",
              },
              {
                type: "ytd",
                text: "This year",
              },
              {
                type: "all",
                text: "All",
              },
            ]
          : [
              {
                type: "day",
                count: 1,
                text: "day",
              },
              {
                type: "month",
                count: 1,
                text: "month",
              },
              {
                type: "all",
                text: "All",
              },
            ],
      },
      title: {
        text: "",
      },
      yAxis: [
        {
          title: {
            text: "Temperature",
          },
          height: "60%",
          lineWidth: 2,
        },
        {
          title: {
            text: "Outdoor temperature",
          },
          top: "65%",
          height: "35%",
          offset: 0,
          lineWidth: 2,
        },
      ],
      scrollbar: {
        liveRedraw: false,
        adaptToUpdatedData: false,
      },
      navigator: {
        adaptToUpdatedData: monthlyData,
        enabled: true,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
      tooltip: {
        snap: 50,
        valueDecimals: 2,
      },
      xAxis: {
        events: monthlyData
          ? undefined
          : {
              afterSetExtremes: this.afterSetExtremes.bind(this),
            },
        minRange: monthlyData ? undefined : 24 * 3600 * 1000, // one day
      },
      series: [],
    };
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
  }

  componentDidMount() {
    if (this.internalChart) {
      this.internalChart.showLoading("Loading data from server...");

      const getMeasurements = restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          naviSeries: findMetaIds(this.props.Trends, "AHU_MEASUREMENTS", [
            "reglist",
            "lts",
          ])[0],
          buildingId: this.state.record.BuildingId,
          metaIds: findMetaIds(this.props.Trends, "AHU_MEASUREMENTS", [
            "reglist",
            "lts",
          ]),
          dataBegin: moment.utc().subtract(3, "months").format(),
          dataEnd: moment.utc().format(),
        },
        sort: { field: "UpdateTime", order: "ASC" },
        pagination: {},
      });

      const getTemp = restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          BuildingId: this.state.record.BuildingId,
          metaIds: findMetaIds(
            this.props.Trends,
            "AHU_MEASUREMENTS",
            "building_temp"
          ),
        },
        sort: { field: "DateTime", order: "ASC" },
        pagination: {},
      });

      Promise.all([getMeasurements, getTemp]).then(
        ([responseMeas, responseTemp]) => {
          // Filter out values from temperatures which are out of the bounds of the measurement values
          let filteredTemp = [];
          let firstDate = null;
          let lastDate = null;
          if (
            responseMeas &&
            responseMeas.data &&
            responseMeas.data.values &&
            responseMeas.data.values.length > 0
          ) {
            firstDate = new Date(responseMeas.data.values[0].valueTime);
            lastDate = new Date(
              responseMeas.data.values[
                responseMeas.data.values.length - 1
              ].valueTime
            );
          }
          new Date(responseMeas.data.values[0]);
          if (responseTemp && responseTemp.data && responseTemp.data.values) {
            filteredTemp = responseTemp.data.values.filter(
              (t) =>
                new Date(t.valueTime) >= firstDate &&
                new Date(t.valueTime) <= lastDate
            );
          }
          const measSeriesDay = this.constructSeries(responseMeas.data.values);
          const tempSeriesDay = this.constructSeries(filteredTemp, 1);

          const measSeriesMonth = this.constructSeries(
            responseMeas.data.values,
            0,
            true
          );
          const tempSeriesMonth = this.constructSeries(filteredTemp, 1, true);

          this.setState((prevState) => ({
            optionsDay: {
              ...prevState.optionsDay,
              series: cloneDeep(measSeriesDay.concat(tempSeriesDay)),
              navigator: {
                series: {
                  data: valuesToHighchartsData(
                    responseMeas.data.naviSeries
                      ? responseMeas.data.naviSeries.Values
                      : []
                  ),
                },
              },
            },
            fetchDone: true,
          }));

          this.setState((prevState) => ({
            optionsMonth: {
              ...prevState.optionsMonth,
              series: cloneDeep(measSeriesMonth.concat(tempSeriesMonth)),
            },
          }));

          this.internalChart.hideLoading();
        }
      );
    }
  }

  afterSetExtremes(e) {
    if (
      e.trigger &&
      !isNaN(e.max) &&
      !isNaN(e.min) &&
      this.state.resolution === "day"
    ) {
      const chart = this.internalChart;

      const startISO = new Date(e.min).toISOString();
      const endISO = new Date(e.max).toISOString();

      chart.showLoading("Loading data from server...");

      const getMeasurements = restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          buildingId: this.state.record.BuildingId,
          metaIds: findMetaIds(this.props.Trends, "AHU_MEASUREMENTS", [
            "reglist",
            "lts",
          ]),
          dataBegin: startISO,
          dataEnd: endISO,
        },
        sort: { field: "DateTime", order: "ASC" },
        pagination: {},
      });

      const getTemp = restClient(GET_LIST, "reportdata", {
        filter: {
          v2: true,
          buildingId: this.state.record.BuildingId,
          metaIds: findMetaIds(
            this.props.Trends,
            "AHU_MEASUREMENTS",
            "building_temp"
          ),
          dataBegin: startISO,
          dataEnd: endISO,
        },
        sort: { field: "DateTime", order: "ASC" },
        pagination: {},
      });

      Promise.all([getMeasurements, getTemp]).then(
        ([responseMeas, responseTemp]) => {
          const measSeries = this.constructSeries(responseMeas.data.values);
          const tempSeries = this.constructSeries(responseTemp.data.values, 1);

          // Series data is updated, instead of setting the state again to avoid using some not-wanted initial settings, such as min/max on xAxis and rangeselector selections
          try {
            this.internalChart.update(
              {
                series: cloneDeep(measSeries.concat(tempSeries)),
              },
              true,
              true,
              false
            );
          } catch (error) {}

          this.internalChart.hideLoading();
        }
      );
    }
  }

  constructSeries = (data, y, monthlyData = false) => {
    const groupedById = groupBy(data, (v) => v.metaId);
    const trend = find(
      this.props.Trends,
      (t) => t.TrendId === "AHU_MEASUREMENTS"
    );
    if (!trend) {
      return [];
    }
    const series = Object.keys(groupedById).map((key) => {
      const value = find(
        trend.Values,
        (v) => v.ReportingPointId === Number(key)
      );
      return {
        yAxis: y,
        type: "line",
        name: value.Legend,
        data: sortBy(valuesToHighchartsData(groupedById[key])),
        dataGrouping: {
          enabled: monthlyData,
          forced: monthlyData,
          units: [["month", [1]]],
          approximation: "average",
        },
        showInNavigator: false,
        boostThreshold: monthlyData ? 0 : 1,
      };
    });
    return series;
  };

  render() {
    let options = null;
    if (this.state.resolution === "day") {
      options = cloneDeep(this.state.optionsDay);
    } else if (this.state.resolution === "month") {
      options = cloneDeep(this.state.optionsMonth);
    }

    const handleResolutionChange = (e) => {
      this.setState({ resolution: e.target.value });
    };

    const ResolutionInput = (
      <div
        key={"res"}
        style={{
          marginLeft: 15,
          width: 80,
          display: "inline-flex",
          flexDirection: "column",
        }}
      >
        <InputLabel>Resolution</InputLabel>
        <Select value={this.state.resolution} onChange={handleResolutionChange}>
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"month"}>Month</MenuItem>
        </Select>
      </div>
    );

    let showData = true;
    if (
      this.state.fetchDone &&
      (!options || !options.series || options.series.length === 0)
    ) {
      showData = false;
    }

    if (
      this.props.showContainer &&
      this.props.hideContainer &&
      this.state.fetchDone
    ) {
      if (showData) {
        this.props.showContainer("measurements");
      } else {
        this.props.hideContainer("measurements");
      }
    }

    return (
      <CardWithTitle title={"Measurements"}>
        {showData ? (
          [
            ResolutionInput,
            <HighchartsReact
              key={"measChart"}
              immutable={true}
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={cloneDeep(options)}
              callback={this.afterChartCreated}
            />,
          ]
        ) : (
          <div style={{ height: "20rem" }}>
            <EmptyData />
          </div>
        )}
      </CardWithTitle>
    );
  }
}
