import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles, SvgIcon } from "@material-ui/core";
import TargetGraph from "./TargetGraph";

import CardWithTitle from "../../components/layout/CardWithTitle";
import Wrapper from "../../hoc/Wrapper";

const useStyles = makeStyles((theme) => ({
  root: {},
  heatIcon: {
    fill: "#FFA11F",
    marginTop: 18,
  },
  waterIcon: {
    fill: "#1A67D6",
    marginTop: 18,
  },
  electricityIcon: {
    fill: "#00975A",
    marginTop: 18,
  },
  coolingIcon: {
    fill: "#1AC5D6",
    marginTop: 18,
  },
  notMeasuredContainer: {
    position: "absolute",
    backgroundColor: "rgba(255,255,255,0.5)",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
}));

const TargetGraphs = (props) => {
  const classes = useStyles();
  const [hiddenResources, setHiddenResources] = useState([]);
  const consumptionData = useSelector(
    (state) => state.buildingFilter.consumption
  );

  useEffect(() => {
    if (props.building && props.buildingTwin) {
      setHiddenResources([]);
    }
  }, [props.building, props.buildingTwin]);

  const handleResourceHide = useCallback(
    (res) => {
      if (!hiddenResources.includes(res)) {
        setHiddenResources([...hiddenResources, res]);
      }
    },
    [hiddenResources]
  );

  const handleResourceShow = useCallback(
    (res) => {
      if (hiddenResources.includes(res)) {
        setHiddenResources(hiddenResources.filter((r) => r !== res));
      }
    },
    [hiddenResources]
  );

  const heatIcon = (
    <SvgIcon className={classes.heatIcon}>
      <g
        id="Group_255"
        data-name="Group 255"
        transform="translate(-279.342 -401.6)"
      >
        <g
          id="Group_254"
          data-name="Group 254"
          transform="translate(279.342 401.6)"
        >
          <path
            id="Path_165"
            data-name="Path 165"
            d="M294.87,418.6a6.806,6.806,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574a6.805,6.805,0,0,1,2.752,4c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3Z"
            transform="translate(-286.51 -401.6)"
          />
          <path
            id="Path_166"
            data-name="Path 166"
            d="M310.036,405.6c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3l-1.5,1.574a6.805,6.805,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574A6.806,6.806,0,0,1,310.036,405.6Z"
            transform="translate(-293.735 -401.6)"
          />
          <path
            id="Path_167"
            data-name="Path 167"
            d="M284.536,405.6c.29,1.619-.241,3.283-1.642,5.127-2.221,2.878-1.352,4.542.773,6.3l-1.5,1.574a6.805,6.805,0,0,1-2.752-4c-.29-1.619.241-3.283,1.642-5.127,2.221-2.878,1.352-4.542-.773-6.3l1.5-1.574A6.806,6.806,0,0,1,284.536,405.6Z"
            transform="translate(-279.342 -401.6)"
          />
        </g>
      </g>
    </SvgIcon>
  );

  const waterIcon = (
    <SvgIcon className={classes.waterIcon} viewBox="0 0 12 16.46">
      <path
        id="icon_water"
        d="M12,3.77l-.75.84A38.7,38.7,0,0,0,8.68,7.94C7.39,9.82,6,12.07,6,14.23a6,6,0,0,0,12,0c0-2.16-1.39-4.41-2.68-6.29a38.7,38.7,0,0,0-2.57-3.33L12,3.77M12,6.9a21.327,21.327,0,0,1,1.68,2.17c1.21,1.76,2.32,4,2.32,5.16a4,4,0,1,1-8,0c0-1.16,1.11-3.4,2.32-5.16A21.327,21.327,0,0,1,12,6.9Z"
        transform="translate(-6 -3.77)"
      />
    </SvgIcon>
  );

  const electricityIcon = (
    <SvgIcon className={classes.electricityIcon} viewBox="0 0 13.004 19.517">
      <path
        id="icon_electricity"
        d="M34.147,15.205H29.294l.462-6.725a.693.693,0,0,0-1.271-.416L21.944,18.672a.693.693,0,0,0,.6,1.063h4.946l-.508,6.818a.693.693,0,0,0,.485.716h.208a.693.693,0,0,0,.6-.324l6.471-10.7a.693.693,0,0,0-.6-1.04Zm-5.57,8.551.347-4.669a.693.693,0,0,0-.693-.74H23.793l4.391-7.165-.324,4.669a.693.693,0,0,0,.693.74h4.368Z"
        transform="translate(-21.837 -7.753)"
      />
    </SvgIcon>
  );

  const coolingIcon = (
    <SvgIcon className={classes.coolingIcon} viewBox="0 0 17 18.27">
      <path
        id="icon_cooling"
        d="M19.225,12.916l-2.128.566L15.27,12.45V9.819L17.1,8.787l2.128.566L19.7,7.591l-1.617-.429.42-1.617L16.74,5.069,16.174,7.2,14.347,8.23,12.109,6.915V4.85l1.562-1.562L12.374,2,11.2,3.178,10.017,2,8.72,3.288,10.282,4.85V6.915L8,8.23,6.171,7.2l-.53-2.128-1.754.475.429,1.617L2.7,7.591l.475,1.763L5.3,8.787,7.13,9.819v2.64L5.3,13.492l-2.128-.566L2.7,14.688l1.617.429-.429,1.608,1.763.475.566-2.128L8.044,14.04l2.238,1.315V17.42L8.72,18.982l1.3,1.288L11.2,19.091l1.178,1.178,1.288-1.288L12.109,17.42V15.355l2.284-1.325,1.827,1.032.566,2.128,1.717-.466-.429-1.617,1.617-.429-.466-1.763M8.912,9.819,11.2,8.495l2.284,1.325V12.45L11.2,13.775,8.912,12.45Z"
        transform="translate(-2.7 -2)"
      />
    </SvgIcon>
  );

  return (
    <Wrapper>
      <Grid item xs={props.xs} md={props.md} lg={props.lg}>
        <CardWithTitle
          spacerColor="#FFA11F"
          title="Heating"
          action={heatIcon}
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {hiddenResources.includes("heating") && (
            <div className={classes.notMeasuredContainer}></div>
          )}
          <TargetGraph
            building={props.building}
            buildingTwin={props.buildingTwin}
            type="heating"
            color="#FFA11F"
            consumptionData={consumptionData}
            hideResource={handleResourceHide}
            showResource={handleResourceShow}
          />
        </CardWithTitle>
      </Grid>
      <Grid item xs={props.xs} md={props.md} lg={props.lg}>
        <CardWithTitle
          spacerColor="#1A67D6"
          title="Water"
          action={waterIcon}
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {hiddenResources.includes("water") && (
            <div className={classes.notMeasuredContainer}></div>
          )}
          <TargetGraph
            building={props.building}
            buildingTwin={props.buildingTwin}
            type="water"
            color="#1A67D6"
            consumptionData={consumptionData}
            hideResource={handleResourceHide}
            showResource={handleResourceShow}
          />
        </CardWithTitle>
      </Grid>
      <Grid item xs={props.xs} md={props.md} lg={props.lg}>
        <CardWithTitle
          spacerColor="#00975A"
          title="Electricity"
          action={electricityIcon}
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {hiddenResources.includes("electricity") && (
            <div className={classes.notMeasuredContainer}></div>
          )}
          <TargetGraph
            building={props.building}
            buildingTwin={props.buildingTwin}
            type="electricity"
            color="#00975A"
            consumptionData={consumptionData}
            hideResource={handleResourceHide}
            showResource={handleResourceShow}
          />
        </CardWithTitle>
      </Grid>
      <Grid item xs={props.xs} md={props.md} lg={props.lg}>
        <CardWithTitle
          spacerColor="#1AC5D6"
          title="Cooling"
          action={coolingIcon}
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {hiddenResources.includes("cooling") && (
            <div className={classes.notMeasuredContainer}></div>
          )}
          <TargetGraph
            building={props.building}
            buildingTwin={props.buildingTwin}
            type="cooling"
            color="#1AC5D6"
            consumptionData={consumptionData}
            hideResource={handleResourceHide}
            showResource={handleResourceShow}
          />
        </CardWithTitle>
      </Grid>
    </Wrapper>
  );
};

export default TargetGraphs;
