import React from "react";
import { FileInput, FileField } from "react-admin";

const HpUpload = () => {
  return (
    <FileInput
      source="files"
      label="Upload simulation data from text file / csv"
      accept="text/plain"
    >
      <FileField source="src" title="Upload from text file" />
    </FileInput>
  );
};

export default HpUpload;
