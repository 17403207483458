import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { mapper } from "../customReducers/connectFunctions";
import PageTitle from "../components/layout/PageTitle";

const useStyles = makeStyles(() => ({
  helperText: {
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
  },
}));

const RisksIssuesFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SelectInput
        label="Risk/Issue filter"
        source="Type"
        choices={[
          { id: 1, name: "Issue" },
          { id: 2, name: "Risk" },
        ]}
        alwaysOn
        className={classes.helperText}
      />
    </Filter>
  );
};

const RisksIssuesList = ({ BuildingId, projectname, ...props }) => {
  // Remove unused props to get rid of errors
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.showNotification;
  delete listProps.setBuildings;
  delete listProps.changebuilding;
  return (
    <>
      <PageTitle title="Risks & Issues" />
      <List
        {...listProps}
        perPage={1000}
        bulkActionButtons={false}
        filter={{ BuildingId }}
        filters={<RisksIssuesFilter />}
      >
        <Datagrid>
          <TextField source="Title" />
          <TextField source="Name" />
          <TextField source="Status" />
          <TextField source="Priority" />
          <TextField source="Category" />
          <DateField source="Duedate" locales="fi-FI" />
        </Datagrid>
      </List>
    </>
  );
};

export default mapper(RisksIssuesList);
