import { groupBy, isNil, first, map, filter, round } from "lodash";
import graphColor from "../../../utils/graphColor";
import raReportingDataToHighchartsData from "./raReportingDataToHighChartsData";

const mapHeatingByLoad = (_series, _ahuData) => {
  let heatingLoadSeries = [];
  let totalSeries = [];
  let sumSeries = [];
  let otherSeries = [];
  let series = [];
  let ahuData = [];
  if (_ahuData && _ahuData.trendData && _ahuData.trendData.length > 0) {
    ahuData = [..._ahuData.trendData];
  }
  if (_series && _series.length > 0) {
    series = [..._series];
  }
  // Calculate the sums of the previous graph data (stacks)
  let seriesGrouped = groupBy(series, "stack");
  let seriesKeys = Object.keys(seriesGrouped);
  seriesKeys.forEach((k) => {
    if (k !== "undefined") {
      let oneYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      if (seriesGrouped[k] && seriesGrouped[k].length > 0) {
        seriesGrouped[k].forEach((s) => {
          if (!isNil(s.data) && s.data.length > 0) {
            for (let i = 0; i < s.data.length; i++) {
              // Sum the data into one year
              if (!isNil(first(s.data[i]))) {
                oneYear[i] += first(s.data[i]);
              } else if (!isNil(s.data[i])) {
                oneYear[i] += s.data[i];
              }
            }
          }
        });
      }
      oneYear = oneYear.map((o) => {
        return round(o, 2);
      });
      sumSeries.push({
        year: k,
        data: oneYear,
      });
    }
  });

  // Then subtract the ahu consumptions from the sums to form the "other" data stack
  // First form the ahuData for the graph
  if (ahuData && ahuData.length > 0) {
    const trendData = [...ahuData];
    const filteredData = filter(
      trendData,
      (t) => t.trendId === "YEARLY_HEATING_POWER_INTEGRAL"
    );
    let ahuGrouped = groupBy(filteredData, "year");
    let ahuKeys = Object.keys(ahuGrouped);
    let index = 0;
    let ahuSeries = map(ahuKeys, (k) => {
      index++;
      return {
        name: "AHU " + k,
        data: raReportingDataToHighchartsData(ahuGrouped[k], "ahu"),
        stack: parseInt(k),
        linkedTo: k,
        color: graphColor({ index: index - 1, seriesLength: ahuKeys.length }),
        showInLegend: false,
      };
    });

    // Form the "other" stack
    let ind = 0;
    sumSeries.forEach((s) => {
      let oneYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // Copy the s.data array to oneYear
      oneYear = [...s.data];
      // Find the corresponding ahu series
      ahuSeries.forEach((a) => {
        if (parseInt(a.stack) === parseInt(s.year)) {
          // Subtrach the AHU data from the total (sum) data to form the other data
          for (let i = 0; i < a.data.length; i++) {
            oneYear[i] -= a.data[i];
            // If the value goes < 0 , set it to null
            if (oneYear[i] < 0) {
              oneYear[i] = null;
            }
          }
        }
      });
      otherSeries.push({
        name: "Other " + s.year,
        data: oneYear,
        stack: parseInt(s.year),
        linkedTo: s.year,
        color: graphColor({ index: ind, seriesLength: sumSeries.length }),
        showInLegend: false,
      });
      ind++;
    });
    heatingLoadSeries.push(...ahuSeries, ...otherSeries);
    // If ahuData does not exist, just show the total energy instead of ahu + other
  } else {
    let ind = 0;
    sumSeries.forEach((s) => {
      let oneYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      // Copy the s.data array to oneYear
      oneYear = [...s.data];
      totalSeries.push({
        name: "Total " + s.year,
        data: oneYear,
        stack: parseInt(s.year),
        linkedTo: s.year,
        color: graphColor({ index: ind, seriesLength: sumSeries.length }),
        showInLegend: false,
      });
      ind++;
    });
    heatingLoadSeries.push(...totalSeries);
  }
  const finalSeries = heatingLoadSeries;
  return finalSeries;
};

export default mapHeatingByLoad;
