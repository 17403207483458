import React, {
  Component,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import {
  createMuiTheme,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import compose from "recompose/compose";

import { AppBar } from "ra-ui-materialui";
import { Sidebar } from "ra-ui-materialui";
import { Menu } from "ra-ui-materialui";
import { Notification } from "ra-ui-materialui";
import { Error } from "ra-ui-materialui";
import { defaultTheme } from "ra-ui-materialui";
import { ComponentPropType } from "ra-core";

let drawerWidth = 240;

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
      boxSizing: "border-box",
      position: "fixed",
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      boxShadow: "0px 3px 10px #0000001A",
      backgroundColor: "#fff",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginLeft: "0",
      },
    },
    appBarSpacer: {
      height: 60,
      marginBottom: 32,
    },
    contentWithSidebar: {
      display: "flex",
      flexGrow: 1,
    },
    content: {
      boxSizing: "border-box",
      flexGrow: 1,
      // Uncomment these if only the content should be scrollable
      height: "100vh",
      overflow: "auto",
      // ^ These two
      background: "#F0F0F7",
      color: "#282331",
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      [theme.breakpoints.up("xs")]: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 0,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 0,
      },
    },
  });

const sanitizeRestProps = ({
  staticContext,
  history,
  location,
  match,
  ...props
}) => props;

class Layout extends Component {
  state = {
    hasError: false,
    errorMessage: null,
    errorInfo: null,
  };

  constructor(props) {
    super(props);
    this.sideBarRef = React.createRef();
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      ...props
    } = this.props;
    const { hasError, errorMessage, errorInfo } = this.state;
    return (
      <div
        className={classnames("layout", classes.root, className)}
        {...sanitizeRestProps(props)}
      >
        {createElement(appBar, {
          title,
          open,
          logout,
          className: classes.appBar,
        })}
        {createElement(sidebar, {
          children: createElement(menu, {
            logout,
            hasDashboard: !!dashboard,
          }),
        })}
        <main className={classes.content} id="main-content">
          <div className={classes.appBarSpacer} id="scroll-top" />
          {hasError
            ? createElement(error, {
                error: errorMessage,
                errorInfo,
                title,
              })
            : children}
        </main>
        {createElement(notification)}
      </div>
    );
  }
}

Layout.propTypes = {
  appBar: ComponentPropType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: ComponentPropType,
  error: ComponentPropType,
  history: PropTypes.object.isRequired,
  logout: PropTypes.element,
  menu: ComponentPropType,
  notification: ComponentPropType,
  open: PropTypes.bool,
  sidebar: ComponentPropType,
  title: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  appBar: AppBar,
  error: Error,
  menu: Menu,
  notification: Notification,
  sidebar: Sidebar,
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles)
)(Layout);

const LayoutWithTheme = ({ theme: themeOverride, ...props }) => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
};

LayoutWithTheme.defaultProps = {
  theme: defaultTheme,
};

export default LayoutWithTheme;
