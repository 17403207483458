import React from "react";
import { makeStyles } from "@material-ui/core";

import Arrow from "./Arrow";
import InputBox from "./InputBox";

const useStyles = makeStyles({
  bottomBlock: {
    position: "absolute",
    left: 0,
    bottom: "97%",
    backgroundColor: "#5B5B5B",
  },
});

const BottomArrows = ({ arrowHeight, energyPoints, existing = [] }) => {
  const classes = useStyles();

  const regenerationEnergy =
    energyPoints && energyPoints.find((d) => d.name === "regenerationEnergy");

  const coolingEnergy =
    energyPoints && energyPoints.find((d) => d.name === "coolingEnergy");

  return (
    <>
      {existing && existing.includes("regenerationEnergy") && (
        <Arrow
          height={arrowHeight}
          width={838}
          bottom={-130}
          left="20%"
          translateX="0"
          translateY="50%"
          bottomArrow
          text="Regeneration energy"
          textLeft={55}
        >
          <InputBox
            style={{
              top: -1 * 130 + arrowHeight,
              transform: "translate(-19%,-96%)",
              zIndex: 10,
            }}
            height={arrowHeight + 6}
            backgroundColor="#E9941E"
            data={regenerationEnergy}
            divider={1000}
            unit="MWh"
          />
          <div
            className={classes.bottomBlock}
            style={{ width: arrowHeight, height: 131 - arrowHeight }}
          />
        </Arrow>
      )}
      {existing && existing.includes("coolingEnergy") && (
        <Arrow
          height={arrowHeight}
          width={735}
          bottom={-65}
          left="63%"
          translateX="0"
          translateY="50%"
          bottomArrow
          text="Cooling energy"
          textLeft={55}
        >
          <div
            className={classes.bottomBlock}
            style={{ width: arrowHeight, height: 66 - arrowHeight }}
          />
          <InputBox
            style={{
              top: -1 * 65 + arrowHeight,
              transform: "translate(-19%,-96%)",
              zIndex: 10,
            }}
            height={arrowHeight + 6}
            backgroundColor="#19B8C8"
            data={coolingEnergy}
            divider={1000}
            unit="MWh"
          />
        </Arrow>
      )}
    </>
  );
};

export default BottomArrows;
