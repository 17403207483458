import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../customReducers/actions";
import { Card, CardContent } from "@material-ui/core";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import { alertCount } from "./BuildingsWithAlerts";
import { isEqual } from "lodash";
import { Link } from "react-router-dom";

import PageTitle from "../components/layout/PageTitle";
import FavoriteContainer from "./FavoriteContainer";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    //    this.setBuildings = props.setBuildings.bind(this);
    this.state = {
      alerts: [],
      alertedBuildings: [],
      dashboard: {},
      value: 0,
      favorites: null,
      favoriteDeleted: false,
      favoriteAdded: false,
      activeCount: 0,
      uncheckedCount: 0,
      activeUnchecked: 0,
      viewVersion: 0,
    };
  }

  componentDidMount() {
    sessionStorage.setItem("BuildingId", null);
    sessionStorage.setItem("BuildingTwinId", null);
    this.props.changebuilding();

    restClient(GET_LIST, "user/favorites", {
      sort: { field: "ProjectName", order: "DESC" },
      pagination: { page: 1, perPage: 10000 },
    }).then((r) =>
      this.setState({
        favorites: r.data,
        favoriteDeleted: false,
        favoriteAdded: false,
      })
    );
  }

  componentDidUpdate() {
    // Fetch all favorites again when favorites are added or deleted
    if (this.state.favoriteAdded || this.state.favoriteDeleted) {
      restClient(GET_LIST, "user/favorites", {
        sort: { field: "ProjectName", order: "DESC" },
        pagination: { page: 1, perPage: 10000 },
      }).then((r) =>
        this.setState({
          favorites: r.data,
          favoriteDeleted: false,
          favoriteAdded: false,
        })
      );
    }

    //Alert handling
    let alertCounts = {
      active: 0,
      unchecked: 0,
      activeUnchecked: 0,
    };
    // Only update if alert data changes
    if (
      this.props.alerts &&
      this.props.alerts.length > 0 &&
      !isEqual(this.state.alerts, this.props.alerts)
    ) {
      let alertedBuildingsIds = [];
      alertCounts = alertCount(this.props.alerts);
      this.props.alerts.forEach((alert) => {
        if (!alertedBuildingsIds.includes(alert.BuildingId)) {
          alertedBuildingsIds.push(alert.BuildingId);
        }
      });
      this.setState({
        viewVersion: this.props.curViewVersion,
        alerts: this.props.alerts,
        activeCount: alertCounts.active,
        uncheckedCount: alertCounts.unchecked,
        activeUnchecked: alertCounts.activeUnchecked,
      });
      let alertedBuildings = this.props.buildings.filter((building) =>
        alertedBuildingsIds.includes(building.id)
      );
      this.setState({ alertedBuildings: alertedBuildings });
    }
  }

  handleChange = (event, value) => {
    this.setState({
      value: value,
    });
  };

  favoriteDeletedHandler = () => {
    this.setState({ favoriteDeleted: true });
  };

  SelectedBuilding = () => {
    var title = "";
    this.props.projectname === "none"
      ? (title = " Please select a building from the top bar")
      : (title = (
          <Link
            to={`/buildings/${this.props.BuildingId}/show`}
            style={{ textDecoration: "none", color: "white" }}
          >
            Selected building: {this.props.projectname}
          </Link>
        ));

    return title;
  };

  DashBoardCard = () => {
    return (
      <Card>
        <CardContent>
          BMS Integrations: {this.state.dashboard.BmsIntegrations}
        </CardContent>
        <CardContent>
          BMS Compensations: {this.state.dashboard.BmsCompensations}
        </CardContent>
      </Card>
    );
  };

  // Card for user favorites
  BuildingsCard = () => {
    let favorites = null;
    if (this.state.favorites) {
      favorites = (
        <FavoriteContainer
          buildings={this.props.buildings}
          favorites={this.state.favorites}
          alerts={this.state.alerts}
          favoriteDeleted={this.favoriteDeletedHandler}
        />
      );
    }
    return favorites;
  };

  render() {
    return (
      <div>
        <PageTitle title="Home" all activeCount={this.state.activeUnchecked} />
        <this.BuildingsCard />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    buildings: state.buildingFilter.buildings,
    BuildingId: state.buildingFilter.id,
    projectname: state.buildingFilter.ProjectName,
    alerts: state.buildingFilter.allAlerts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changebuilding: () => dispatch(actions.changebuilding()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
