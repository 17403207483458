/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () =>
      resolve({ name: file.rawFile.name, result: reader.result });
    reader.onerror = reject;
  });

const requestType = {
  GET_LIST: "getList",
  CREATE: "create",
  UPDATE: "update",
  GET_ONE: "getOne",
  GET_MANY: "getMany",
  GET_MANY_REFERENCE: "getManyReference",
  UPDATE_MANY: "updateMany",
  DELETE: "delete",
  DELETE_MANY: "deleteMany",
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadConsFeature = (requestHandler) => (type, resource, params) => {

  // Route reminder requests to operationlog endpoint
  if (resource === "reminders") {
    resource = "operationlog";
  }
  // Route guestusers requests to user/listGuestUsers
  if (resource === "guestusers") {
    resource = "user/listGuestUsers";
  }
  // Reportdata request go to api 2.0 at the moment if filter v2 == true
  if (resource === "reportdata" && params.filter && params.filter.v2) {
    resource = "2.0/reportdata";
    delete params.filter.v2;
  }
  // Custom request for editing guest users
  if (resource === "guestUserEdit" && params && params.id) {
    resource = "user";
    params.id = params.id + "/buildings";
  }

  if (
    (type === "UPDATE" &&
      (resource === "buildings" ||
        resource === "blocks" ||
        resource === "operationlog")) ||
    (type === "CREATE" && resource === "operationlog")
  ) {
    if (params.data.files) {
      let newFiles = new Array(params.data.files);
      // In case the files contains arrays of files, flatten it
      newFiles = newFiles.flat();
      return Promise.all(newFiles.map(convertFileToBase64))
        .then((base64Files) =>
          base64Files.map((file64) => {
            return {
              src: file64.result,
              title: params.data.title || file64.name,
            };
          })
        )
        .then((transformedNewFiles) => {
          return requestHandler[requestType[type]](resource, {
            ...params,
            data: {
              ...params.data,
              newfiles: [...transformedNewFiles],
            },
          })
        });
    }
  }

  // for other request types and resources, fall back to the default request handler
  const methodName = requestType[type];
  if (methodName) {
    try {
      return requestHandler[methodName](resource, params);
    } catch (e) {
      console.warn('Error in', type, methodName, e);
      return Promise.reject(e);
    }
  } else {
    console.warn(type, methodName);
    return Promise.reject();
  }
};

export default addUploadConsFeature;
