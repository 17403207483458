import React, { useState } from "react";
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  SelectInput,
  required,
  useRefresh,
  useNotify,
  useUnselectAll,
  fetchStart,
  fetchEnd,
  FormDataConsumer,
} from "react-admin";
import {
  Button,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Wrapper from "../hoc/Wrapper";
import { useDispatch } from "react-redux";
import { DateInputNoTimezone } from "../components/TimeInputs";
import { useMsal } from '@azure/msal-react';

const useStyles = makeStyles({
  root: {
    color: "#3F51B5",
  },
});

const CheckAlerts = (props) => {
  const { instance: msalInstance } = useMsal();
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const dispatch = useDispatch();
  const unselectAll = useUnselectAll();
  const [open, setOpen] = useState(false);
  const [, setData] = useState({});
  const [showOperationInputs, setShowOperationInputs] = useState(true);
  const selectedAlertIds = props.record ? [props.record.id] : props.selectedIds;

  const [, setLoading] = useState(false);

  async function handleClick(data) {
    const token = await msalInstance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
    })
      .catch((error) => {
        msalInstance.loginRedirect();
      });
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token.accessToken);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    setLoading(true);
    dispatch(fetchStart());
    const updatedRecord = {
      operation: data.CheckAlertsWithOperation ? data : null,
      alertIds: selectedAlertIds,
    };
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(updatedRecord),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/alerts/check-multiple`,
      requestOptions
    )
      .then(() => {
        unselectAll("alerts");
        refresh("alerts");
        notify("Alerts checked");
      })
      .catch((e) => {
        notify("Error: alerts not checked", "warning");
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  }

  const AlertsToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="Check" onClick={handleSave} />
    </Toolbar>
  );

  const AlertDialog = (props) => {
    if (selectedAlertIds == null) {
      return null;
    }
    if (!open) {
      return null;
    }

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Check alert(s) with operation log entry</DialogTitle>
        <DialogContent>
          <SimpleForm
            undoable={false}
            save={handleSubmit}
            redirect={"show"}
            resource="alerts"
            submitOnEnter={false}
            toolbar={<AlertsToolbar />}
          >
            <BooleanInput
              source="CheckAlertsWithOperation"
              defaultValue={showOperationInputs}
              onChange={handleShowOperationInputs}
            />
            {showOperationInputs && [
              <TextInput key="title" source="Title" validate={required()} />,
              <TextInput
                key="internalComment"
                multiline
                source="InternalComment"
              />,
              <SelectInput
                key="state"
                source="state"
                choices={[
                  { id: "1. Active", name: "1. Active" },
                  { id: "2. Delayed", name: "2. Delayed" },
                  { id: "3. Completed", name: "3. Completed" },
                ]}
              />,
              <DateInputNoTimezone
                key="deadline"
                source="Deadline"
                label="Deadline"
              />,
              <BooleanInput
                key="visibleToCustomer"
                source="VisibleToExternal"
                label="Visible to customer"
              />,
              <FormDataConsumer key="formData">
                {({ formData }) =>
                  formData.VisibleToExternal && (
                    <TextInput
                      multiline
                      label="Comments to customer"
                      source="ExternalComment"
                    />
                  )
                }
              </FormDataConsumer>,
            ]}
          </SimpleForm>
        </DialogContent>
      </Dialog>
    );
  };

  const handleShowOperationInputs = (value) => {
    setShowOperationInputs(value);
  };

  const handleSubmit = (data, path, e) => {
    setData(data);
    handleClick(data);
    setOpen(false);
  };

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    props.dialogCb(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
    props.dialogCb(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    setOpen(false);
    props.dialogCb(false);
  };

  return (
    <Wrapper>
      <Button
        size="small"
        classes={{ root: classes.root }}
        variant="contained"
        onClick={handleClickOpen}
      >
        CHECK ALERTS
      </Button>
      <AlertDialog open={open} onClose={handleClose} />
    </Wrapper>
  );
};

export default CheckAlerts;
