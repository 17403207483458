import React from "react";
import {
  TopToolbar,
  DateField,
  EditButton,
  ListButton,
  NumberField,
  RefreshButton,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  UrlField,
  ReferenceManyField,
  Datagrid,
  BooleanField
} from "react-admin/lib";
import AddBlockButton from "./AddBlockButton";
import UpdatePWAExportButton from "./UpdatePWAExportButton";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/HelpOutline";
import useUserRole from "../utils/hooks/useUserRole";
//import ExcelPowToSqlButton from "./ExcelPowToSqlButton";

const BuildingShowActions = ({ basePath, data, resource }) => {
  const { isAdminOrUser } = useUserRole();
  return (
    <TopToolbar>
      {isAdminOrUser && (
        <EditButton basePath={basePath} record={data} />
      )}
      {isAdminOrUser && <ListButton basePath={basePath} />}
      <RefreshButton />
    </TopToolbar>
  );
}

const BuildingTitle = ({ record }) => {
  return (
    <span>
      Building #{record ? `${record.id} - ${record.ProjectName}` : ""}
    </span>
  );
};

export const BuildingShow = ({ ...props }) => {
  const { isAdminOrUser } = useUserRole();
  return (<Show
    actions={<BuildingShowActions />}
    title={<BuildingTitle />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="Basic info">
        <TextField source="ProjectGuid" />
        <UrlField
          source="ProjectWorkspaceURL"
          target="_blank"
          rel="noopener noreferrer"
        />
        <TextField source="ProjectName" />
        <TextField source="Address.StreetAddress" />
        <TextField source="Address.ZipCode" />
        <TextField source="Address.City" />

        <TextField label="Latitude" source="BuildingLocation.Latitude" />
        <TextField label="Longitude" source="BuildingLocation.Longitude" />
        <UrlField
          source="GoogleMapLink"
          target="_blank"
          rel="noopener noreferrer"
        />

        <TextField
          label="Weather station latitude"
          source="WeatherStationLocation.Latitude"
        />
        <TextField
          label="Weather station longitude"
          source="WeatherStationLocation.Longitude"
        />
        <DateField label="UpdateTime" source="UpdateTime" showTime />
      </Tab>
      <Tab label="Consumption">
        <TextField label="Comparator" source="BuildingStats.Comparator" />
        <BooleanField
          label="Use normalization"
          source="BuildingStats.Normalized"
        />
        <BooleanField
          label="Use warm water"
          source="BuildingStats.UseWarmWater"
        />
        <BooleanField
          label="Use default water consumption"
          source="BuildingStats.UseDefaultWaterCons"
        />
        <TextField label="Warm water %" source="BuildingStats.WarmWaterPerc" />

        <DateField
          label="Update last run"
          source="BuildingStats.BAUpdated"
          showTime
        />
        <div style={{ display: "inline-flex" }}>
          <Tooltip
            title="Indicates time when values are read from Project Online and consumptions updated. Runs regardless if PO values have changed."
            placement="right-end"
          >
            <HelpIcon style={{ margin: "auto", color: "737373" }} />
          </Tooltip>
        </div>
        <DateField
          label="Project Online updated."
          source="BuildingStats.ProjOnlineUpdated"
          showTime
        />
        <div style={{ display: "inline-flex" }}>
          <Tooltip
            title="Time when Project Online was last changed."
            placement="right-end"
          >
            <HelpIcon style={{ margin: "auto", color: "737373" }} />
          </Tooltip>
        </div>
        {isAdminOrUser && [
          <UpdatePWAExportButton key="expbtn" {...props} />,
          <div key="ttdiv" style={{ display: "inline-flex" }}>
            <Tooltip
              title="Gets data from Project Online and updates consumptions."
              placement="right-end"
            >
              <HelpIcon style={{ margin: "auto", color: "737373" }} />
            </Tooltip>
          </div>
        ]}
      </Tab>

      <Tab label="Blocks" path="blocks">
        {isAdminOrUser && <AddBlockButton />}

        <ReferenceManyField
          label="Building blocks"
          reference="blocks"
          target="BuildingId"
        >
          <Datagrid>
            <NumberField source="id" />
            <TextField source="BlockName" />
            <TextField source="BlockDescription" />
            <TextField source="BlockType" />
            <DateField source="Created" />
            {isAdminOrUser && <EditButton />}
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
  );
}