import React from "react";
import {
  DeleteButton,
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { find } from "lodash";
import DateFieldTz from "../utils/DateFieldTz";
import useBuildingTimezone from "../utils/hooks/useBuildingTimezone";
import PageTitle from "../components/layout/PageTitle";
import useUserRole from "../utils/hooks/useUserRole";

export const SourceTwin = (props) => {
  const source = props.source || "BlockId";
  const buildingSource = props.buildingSource || "BuildingId";
  const buildingSelected = useSelector(
    (state) => state.buildingFilter.buildingSelected
  );
  const buildings = useSelector((state) => state.buildingFilter.buildings);

  // Delete some props to remove errors
  const fixedProps = Object.assign({}, props);
  delete fixedProps.buildingSource;

  if (!props.record[source]) {
    return null;
  }

  // If a building is already selected, the links should just be the basic ones without query parameters
  let link =
    props.record.SourceType &&
      props.record.SourceType.toLowerCase() === "building_block"
      ? "/dashboard"
      : "/blocks/" + props.record[source] + "/show";

  let linkEnd = "";

  if (!buildingSelected) {
    let building = find(
      buildings,
      (b) => b.id === props.record[buildingSource]
    );
    if (building && building.id && building.ProjectName) {
      linkEnd = "?id=" + building.id + "&name=" + building.ProjectName;
    }
  }

  return (
    <ReferenceField
      {...fixedProps}
      label="Source twin"
      source={source}
      reference="blocks"
      link={() => link + linkEnd}
    >
      <TextField source="BlockName" />
    </ReferenceField>
  );
};

export const SourceBuilding = (props) => {
  const buildingSelected = useSelector(
    (state) => state.buildingFilter.buildingSelected
  );
  const buildings = useSelector((state) => state.buildingFilter.buildings);

  // SPECIAL CASE FOR ENERGYSAVINGS
  if (props.record.buildingId === "sum") {
    return <span style={{ fontWeight: 600 }}>Total</span>;
  }

  // If a building is already selected, the links should just be the basic ones without query parameters
  let link = "/dashboard";

  // On some cases the props is buildingId instead of Buildingid
  let compareId = props.buildingIdAsBuildingId
    ? props.record.buildingId
    : props.record.BuildingId;

  if (!buildingSelected) {
    let building = find(buildings, (b) => b.id === compareId);
    if (building && building.id && building.ProjectName) {
      link = "/dashboard?id=" + building.id + "&name=" + building.ProjectName;
    }
  }
  const refProps = Object.assign({}, props);
  delete refProps.buildingIdAsBuildingId;
  return (
    <ReferenceField
      {...refProps}
      reference="buildings"
      source={props.buildingIdAsBuildingId ? "buildingId" : "BuildingId"}
      link={() => link}
      filter={{ showBAUI: true }}
    >
      <TextField record={props.record} source="ProjectName" />
    </ReferenceField>
  );
};

const TwinName = (props) => {
  if (props.record.BlockId) {
    return <SourceTwin {...props} />;
  } else return "";
};

const TwinDesc = (props) => {
  if (props.record.BlockId) {
    return (
      <ReferenceField
        {...props}
        label="Twin description"
        source="BlockId"
        reference="blocks"
        link={() => null}
      >
        <TextField record={props.record} source="BlockDescription" />
      </ReferenceField>
    );
  } else return "";
};

const Buttons = (props) => {
  return (
    <>
      <Button to="/remoteconnection" component={Link}>
        Remote connections
      </Button>
      <DeleteButton {...props} />
    </>
  );
};

export const AlertShow = (props) => {
  const { isAdminOrUser } = useUserRole();
  const tz = useBuildingTimezone();
  return (
    <>
      <PageTitle title="Alert" />
      <Show {...props}>
        <SimpleShowLayout>
          {props.SourceType === "Function" ? (
            <ReferenceField
              reference="compensationfunctions"
              source="SourceId"
              target="FunctionId"
              label="Triggering function"
            >
              <TextField source="Name" />
            </ReferenceField>
          ) : (
            ""
          )}

          {props.SourceType === "Function" ? (
            <ReferenceField
              reference="compensationfunctions"
              source="SourceId"
              target="FunctionId"
              label="Formula of the triggering function"
            >
              <TextField source="AlertFormulas" />
            </ReferenceField>
          ) : (
            ""
          )}
          <TextField
            source="VariableValues"
            style={{ wordBreak: "break-word" }}
          />
          <SourceBuilding addLabel={true} label="Building" />
          <TextField source="Description" />
          <TextField source="SourceType" />
          <TwinName {...props} addLabel={true} label="Source twin" />
          <TwinDesc {...props} addLabel={true} label="Twin description" />
          <DateFieldTz
            source="AlertTime"
            label={tz ? "Alert time" : "Alert time (UTC)"}
            showTime
            addLabel
            timeZone={tz}
          />
          <DateFieldTz
            source="AlertEndTime"
            label={tz ? "Alert end time" : "Alert end time (UTC)"}
            showTime
            addLabel
            allowEmpty
            timeZone={tz}
          />
          <DateFieldTz
            source="Checked"
            label={tz ? "Checked" : "Checked (UTC)"}
            showTime
            addLabel
            allowEmpty
            timeZone={tz}
          />
          <TextField source="CheckedBy" />
          {isAdminOrUser && <Buttons />}
        </SimpleShowLayout>
      </Show>
    </>
  );
};
