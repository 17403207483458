import React, { useState, useEffect } from "react";
import {
  ArrayInput,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useNotify,
} from "react-admin";
import { isIntValue, minValue, maxValue } from "../validationHelper";
import { useForm } from "react-final-form";
import { isEmpty } from "lodash";
import { Divider, Typography } from "@material-ui/core";
import { useMsal } from '@azure/msal-react';

const validateHour = [isIntValue(), minValue(0), maxValue(24)];

const usageTimeDayChoices = [
  {
    id: 1,
    name: "Monday",
  },
  {
    id: 2,
    name: "Tuesday",
  },
  {
    id: 3,
    name: "Wednesday",
  },
  {
    id: 4,
    name: "Thursday",
  },
  {
    id: 5,
    name: "Friday",
  },
  {
    id: 6,
    name: "Saturday",
  },
  {
    id: 0,
    name: "Sunday",
  },
];

const ZoneUsageTimesInput = (props) => {
  const onChangeGroupSelect = (event) => {
    const newSelectedGroupId = event.target.value;
    if (newSelectedGroupId === 0) {
      form.change("Zone.UsageTimeGroup.Description", "");
    } else {
      const newSelectedGroup = usageTimeChoices.find(
        (g) => g.Id === newSelectedGroupId
      );
      if (newSelectedGroup) {
        form.change(
          "Zone.UsageTimeGroup.Description",
          newSelectedGroup.Description
        );
        form.change(
          "Zone.UsageTimeGroup.UsageTimes",
          newSelectedGroup.UsageTimes
        );
        setExtraInputs(true);
      }
    }
  };

  const { instance: msalInstance } = useMsal();
  const form = useForm();
  const notify = useNotify();
  const [extraInputs, setExtraInputs] = useState(false);
  const [allowModify, setAllowModify] = useState(false);
  const [usageTimeChoices, setUsageTimeChoices] = useState([]);
  const [originalUsageTimes, setOriginalUsageTimes] = useState(null);
  const [originalUsageTimeGroupId, setOriginalUsageTimeGroupId] = useState(
    null
  );

  useEffect(() => {
    const resolveAllowEdit = (formData) => {
      if (formData.Zone && formData.Zone.UsageTimeGroupId) {
        const currentGroup = usageTimeChoices.find(
          (g) => g.Id === formData.Zone.UsageTimeGroupId
        );
        if (currentGroup && currentGroup.BuildingId) {
          return false;
        } else {
          return true;
        }
      }
    };
    async function fetchUsageTimeGroups(buildingId) {
      const newCustomGroupChoice = { Id: 0, Description: "new custom group" };
      const token = await msalInstance.acquireTokenSilent({
        scopes: [process.env.REACT_APP_SCOPE_URL + "/user_impersonation"],
      })
        .catch((error) => {
          msalInstance.loginRedirect();
        });
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token.accessToken);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      await fetch(
        `${process.env.REACT_APP_API_URL}/zoneusagetime?BuildingId=${buildingId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.push(newCustomGroupChoice);
          setUsageTimeChoices(result);
        })
        .catch((error) => {
          notify("failed to fetch usagetime groups", "error");
        });
    }

    if (isEmpty(usageTimeChoices)) {
      fetchUsageTimeGroups(props.formData.BuildingId);
    }
    if (props.formData && props.formData.Zone && !originalUsageTimes) {
      setOriginalUsageTimes(props.formData.Zone.UsageTimes);
    }
    if (props.formData && props.formData.Zone && !originalUsageTimeGroupId) {
      setOriginalUsageTimeGroupId(props.formData.Zone.UsageTimeGroupId);
    }
    if (
      props.formData &&
      props.formData.Zone &&
      props.formData.Zone.UsageTimeGroupId
    ) {
      setExtraInputs(true);
    }
    setAllowModify(resolveAllowEdit(props.formData));
  }, [
      props.formData.BlockType,
      props.formData,
      notify,
      originalUsageTimes,
      originalUsageTimeGroupId,
      usageTimeChoices,
      msalInstance
  ]);
  return [
    <Divider key="divider" />,
    <Typography key="title" variant="h6">
      Zone usage times
    </Typography>,
    <SelectInput
      key="selectTimeGroup"
      source="Zone.UsageTimeGroupId"
      label="Select usage time templates or create a new group below"
      choices={usageTimeChoices}
      optionText="Description"
      optionValue="Id"
      onChange={onChangeGroupSelect}
      style={{ width: "100%" }}
      validate={[required()]}
    />,
    extraInputs ? (
      <TextInput
        key="groupDesc"
        label="group description"
        source="Zone.UsageTimeGroup.Description"
        disabled={allowModify}
        style={{ width: "100%" }}
        validate={[required()]}
      />
    ) : null,
    extraInputs ? (
      <ArrayInput
        key="usageTimes"
        label="Zone usage times"
        source="Zone.UsageTimeGroup.UsageTimes"
      >
        <SimpleFormIterator
          disableAdd={allowModify}
          disableRemove={allowModify}
        >
          <SelectInput
            label="WeekStartDay"
            source="WeekStartDay"
            style={{ display: "inline", float: "left" }}
            choices={usageTimeDayChoices}
            disabled={allowModify}
          />
          <SelectInput
            label="WeekEndDay"
            source="WeekEndDay"
            style={{ display: "inline", float: "left" }}
            choices={usageTimeDayChoices}
            disabled={allowModify}
          />

          <NumberInput
            label="Start hour (0-24)"
            source="StartHour"
            validate={validateHour}
            style={{ display: "inline", float: "left" }}
            disabled={allowModify}
          />
          <NumberInput
            label="End hour (0-24)"
            source="EndHour"
            validate={validateHour}
            style={{ display: "inline", float: "left" }}
            disabled={allowModify}
          />
        </SimpleFormIterator>
      </ArrayInput>
    ) : null,
  ];
};

export default ZoneUsageTimesInput;
