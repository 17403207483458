import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { checkManyAlerts, unCheckManyAlerts } from "./AlertActions";
import compose from "recompose/compose";

const styles = {
  primary: { background: "red", color: "black", width: 150 },
  secondary: { background: "white", color: "black", width: 150 }
};

class CheckManyAlertsButton extends Component {
  handleCheckMany = event => {
    event.preventDefault();
    const { checkManyAlerts, selectedIds } = this.props;
    checkManyAlerts(selectedIds);
  };

  handleUnCheckMany = event => {
    event.preventDefault();
    const { unCheckManyAlerts, selectedIds } = this.props;
    unCheckManyAlerts(selectedIds);
  };

  render() {
    const { classes, filterValues } = this.props;
    return (
      <div>
        {filterValues.Check === false ? (
          <Button
            variant="contained"
            className={classes.secondary}
            onClick={this.handleCheckMany}
          >
            Check selected
          </Button>
        ) : null}
        {filterValues.Check === true ? (
          <Button
            variant="contained"
            className={classes.secondary}
            onClick={this.handleUnCheckMany}
          >
            Uncheck selected
          </Button>
        ) : null}
      </div>
    );
  }
}

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    {
      checkManyAlerts,
      unCheckManyAlerts
    }
  )
);

export default enhance(CheckManyAlertsButton);
