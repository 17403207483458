import React, { Component } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Card from "@material-ui/core/Card";
import { raDataToHighcharts } from "../utils/raDataToHighcharts";
import Boost from "highcharts/modules/boost";
Boost(Highcharts);

const defaultAge = (type) => {
  if (["RAU", "OVAPORT", "ILWEATHER", "ILFORECAST"].indexOf(type) > -1) {
    return 384;
  } else if (["FUNCTION", "CUSTOM"].indexOf(type) > -1) {
    return 2184;
  }
};

export class ValueStorageGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      record: this.props,
    };
  }

  componentDidMount() {
    restClient(GET_LIST, "valuestorage", {
      filter: {
        BuildingId: this.props.record.BuildingId,
        VariableNames: [this.props.record.VariableName],
        DataType: this.props.record.DataType,
        ValueAge: defaultAge(this.props.record.DataType),
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) =>
        this.setState({ options: this.graphOptions(response) })
      );
  }

  graphOptions = (data) => {
    const graphdata = raDataToHighcharts(data, "UpdateTime", "Value");
    return {
      // chart: {
      //   height: 600,
      //   width: 1200,
      // },
      legend: {
        enabled: true,
      },
      rangeSelector: {
        enabled: true,
        buttonTheme: {
          width: "auto",
        },
        buttons: [
          {
            type: "day",
            count: 1,
            text: "day",
          },
          {
            type: "week",
            count: 1,
            text: "week",
          },
          {
            type: "month",
            count: 1,
            text: "month",
          },
          {
            type: "month",
            count: 3,
            text: "3 months",
          },
          {
            type: "month",
            count: 6,
            text: "6 months",
          },
          {
            type: "year",
            count: 1,
            text: "year",
          },
          {
            type: "ytd",
            text: "This year",
          },
          {
            type: "all",
            text: "All",
          },
        ],
      },
      title: {
        text: data[0] ? data[0].Description : "",
      },
      series: [
        {
          name: data[0] ? data[0].Description : "",
          data: graphdata,
          boostThreshold: 1,
        },
      ],
    };
  };

  render() {
    return (
      <div>
        <Card>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={this.state.options}
          />
        </Card>
      </div>
    );
  }
}
