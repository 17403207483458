import React, { useState, useEffect, useRef } from "react";
import { GET_LIST } from "react-admin";
import { restClient } from "../App";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CardWithTitle from "../components/layout/CardWithTitle";
import EmptyData from "../components/layout/EmptyData";
import useIsMounted from "../utils/hooks/useIsMounted";
import graphColor from "../utils/graphColor";

import {
  find,
  map,
  round,
  sortBy,
  some,
  cloneDeep,
  filter,
  first,
  last,
} from "lodash";
import { InputLabel, Select, MenuItem } from "@material-ui/core";

import HC_exporting from "highcharts/modules/exporting";
import HC_export_data from "highcharts/modules/export-data";

HC_exporting(Highcharts);
HC_export_data(Highcharts);

const findMetaIds = (trends, id) => {
  let y = find(trends, (y) => y.TrendId === id);
  if (y) {
    let vals = map(y.Values, (v) => v.ReportingPointId);
    if (vals) {
      return vals;
    }
  }
  return [];
};

export const valuesToHighchartsData = (
  dataFromReactAdmin,
  monthlyData = false
) => {
  var current = new Date();
  const highChartsData = dataFromReactAdmin.map((v) => {
    let date = null;
    if (!monthlyData) {
      date = new Date(v.date);
    } else {
      date = new Date(v.year, v.month - 1, 1);
    }

    return [
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getTime(),
      round(v.value, 2),
      new Date(date.getTime() - current.getTimezoneOffset() * 60000).getMonth(),
    ];
    //return [Date.parse(v.Date), round(v.Value, 2)];
  });

  return highChartsData;
};

export const BlockDailyCopGraph = (props) => {
  const [resolution, setResolution] = useState("month");
  const [optionsMonth, setOptionsMonth] = useState({ title: { text: "" } });
  const [optionsDay, setOptionsDay] = useState({ title: { text: "" } });
  const [fetchDone, setFetchDone] = useState(false);
  const isMounted = useIsMounted();
  const chartRef = useRef(null);

  const { handleLatestCop } = props;

  useEffect(() => {
    const graphOptions = (data, monthlyData = false, copId, sysId) => {
      let series = [];

      const graphdata = valuesToHighchartsData(
        filter(data, (d) => d.metaId === copId),
        monthlyData
      );
      const sortedData = sortBy(graphdata, 0);

      const graphdataS = valuesToHighchartsData(
        filter(data, (d) => d.metaId === sysId),
        monthlyData
      );
      const sortedDataS = sortBy(graphdataS, 0);

      series.push({
        type: "column",
        name: "COP",
        data: sortedData,
        dataGrouping: {
          approximation: "average",
        },
      });

      series.push({
        type: "column",
        name: "System COP",
        data: sortedDataS,
        dataGrouping: {
          approximation: "average",
        },
      });

      let copData = [];
      const copTargets = map(props.Hp.MonthlyTargets, (t) =>
        round(t.ProducedTarget / t.ConsumedTarget, 2)
      );

      // Loop through the data and create target series according to that
      if (sortedData && sortedData.length > 0) {
        sortedData.forEach((d) => {
          copData.push([d[0], copTargets[d[2]]]);
        });
      }

      if (some(copData, (t) => !isNaN(t[1]))) {
        series.push({
          type: "line",
          name: "COP target",
          data: copData,
          color: graphColor({ index: 0, seriesLength: 1, lineSeries: true }),
          dataGrouping: {
            approximation: "average",
          },
        });
      }
      // Calculate date offsets for the previous year button
      // Get the time of the latest value
      let newestData = 0;
      series.forEach((s) => {
        if (s && s.data && s.data.length > 0) {
          s.data.forEach((d) => {
            if (d[0] > newestData) {
              newestData = d[0];
            }
          });
        }
      });

      const curEnd = new Date(newestData);
      const curStart = new Date(curEnd.getUTCFullYear(), 0, 1);
      const prevStart = new Date(curEnd.getUTCFullYear() - 1, 0, 1);
      const prevEnd = new Date(curEnd.getUTCFullYear(), 0, 0);
      const diffStart = curStart.getTime() - prevStart.getTime();
      const diffEnd = curEnd.getTime() - prevEnd.getTime();

      return {
        legend: {
          enabled: true,
        },
        xAxis: {
          minRange: 1,
        },
        rangeSelector: {
          // selected: 1,
          allButtonsEnabled: true,
          buttonTheme: {
            width: "auto",
          },
          buttons: [
            {
              type: "month",
              count: 2,
              text: "3m",
            },
            {
              type: "month",
              count: 12,
              text: "12m",
            },
            {
              type: "ytd",
              text: "Last year",
              offsetMin: -diffStart,
              offsetMax: -diffEnd,
            },
            {
              type: "ytd",
              text: "This year",
            },
            {
              type: "all",
              text: "All",
            },
          ],
        },
        title: {
          text: "",
        },
        series: series,
      };
    };
    // Daily data
    const dId = findMetaIds(props.Trends, "COP_DAILY");
    const dIdS = findMetaIds(props.Trends, "SYSTEM_COP_DAILY");
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: props.BuildingId,
        metaIds: [dId, dIdS],
        trend: "COP_DAILY",
        resolution: "day",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        if (isMounted) {
          if (handleLatestCop) {
            handleLatestCop(
              last(filter(response.values, (r) => r.metaId === first(dId)))
            );
          }
          setFetchDone(true);
          setOptionsDay(graphOptions(response.values, false, ...dId, ...dIdS));
        }
      });

    // Monthly data
    const mId = findMetaIds(props.Trends, "COP_MONTHLY");
    const mIdS = findMetaIds(props.Trends, "SYSTEM_COP_MONTHLY");
    restClient(GET_LIST, "reportdata", {
      filter: {
        v2: true,
        buildingId: props.BuildingId,
        metaIds: [mId, mIdS],
        trend: "COP_MONTHLY",
        resolution: "month",
      },
      sort: { field: "UpdateTime", order: "ASC" },
      pagination: {},
    })
      .then((response) => response.data)
      .then((response) => {
        if (isMounted) {
          setOptionsMonth(graphOptions(response.values, true, ...mId, ...mIdS));
        }
        setFetchDone(true);
      });
  }, [
    props.Trends,
    props.BuildingId,
    isMounted,
    props.Hp.MonthlyTargets,
    handleLatestCop,
  ]);

  let options = null;
  if (resolution === "day") {
    options = optionsDay;
  } else if (resolution === "month") {
    options = optionsMonth;
  }

  let showData = true;
  if (!options || !options.series || options.series.length === 0) {
    showData = false;
  }

  // This is mostly to remove the animation when redraw is happening if the resolution is changed
  // Note the clone deep, it is used because the chart may mutate the series that is passed
  useEffect(() => {
    if (resolution && optionsDay && optionsMonth) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        if (resolution === "day") {
          chartRef.current.chart.update(
            cloneDeep(optionsDay),
            true,
            true,
            false
          );
        } else if (resolution === "month") {
          chartRef.current.chart.update(
            cloneDeep(optionsMonth),
            true,
            true,
            false
          );
        }
      }
    }
  }, [resolution, optionsDay, optionsMonth]);

  const handleResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  if (props.showContainer && props.hideContainer && fetchDone) {
    if (showData) {
      props.showContainer("cop");
    } else {
      props.hideContainer("cop");
    }
  }

  const ResolutionInput = (
    <div
      key={"res"}
      style={{
        marginLeft: 15,
        width: 80,
        display: "inline-flex",
        flexDirection: "column",
      }}
    >
      <InputLabel>Resolution</InputLabel>
      <Select value={resolution} onChange={handleResolutionChange}>
        <MenuItem value={"day"}>Day</MenuItem>
        <MenuItem value={"month"}>Month</MenuItem>
      </Select>
    </div>
  );

  return (
    <CardWithTitle title="COP">
      {showData ? (
        [
          ResolutionInput,
          <HighchartsReact
            key={"chart"}
            ref={chartRef}
            highcharts={Highcharts}
            options={cloneDeep(options)}
            constructorType={"stockChart"}
          />,
        ]
      ) : (
        <div style={{ height: "20rem" }}>
          <EmptyData />
        </div>
      )}
    </CardWithTitle>
  );
};
