import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Pagination,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Typography } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { setReminderCount } from "../customReducers/actions";
import { SourceBuilding } from "../alerts/AlertShow";
import PageTitle from "../components/layout/PageTitle";

const useStyles = makeStyles({
  reminderWarning: { color: "red" },
});

const RemindersFilter = (props) => (
  <Filter {...props}>
    <TextInput source="q" label="Title" />
    <TextInput source="User" label="Creator/Editor" alwaysOn />
    <ReferenceInput
      label="Building"
      source="BuildingId"
      reference="buildings"
      sort={{ field: "ProjectName", order: "ASC" }}
      filter={{ showBAUI: true }}
      alwaysOn
    >
      <AutocompleteInput optionText="ProjectName" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const ReminderTextField = (props) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      className={isWarningReminder(props.record) ? classes.reminderWarning : ""}
      source={props.source}
    />
  );
};

const ReminderDateField = (props) => {
  const classes = useStyles();
  return (
    <DateField
      {...props}
      className={isWarningReminder(props.record) ? classes.reminderWarning : ""}
      source={props.source}
      locales={props.locales}
    />
  );
};

const Reminders = (props) => {
  const dispatch = useDispatch();
  const reminderCount = useSelector(
    (state) => state.buildingFilter.reminderCount
  );
  const buildingId = useSelector((state) => state.buildingFilter.id);

  const CustomGrid = (props) => {
    let newData = {};
    let newIds = [];
    // Filter out unnecessary rows
    let warningCount = 0;
    Object.keys(props.data).forEach((k) => {
      const row = props.data[k];
      if (props.ids.includes(row.id) && isValidReminder(row)) {
        newData = {
          ...newData,
          [k]: props.data[k],
        };
        newIds.push(props.data[k].id);
      }
      if (
        isWarningReminder(row) &&
        isValidReminder(row) &&
        (!buildingId || (buildingId && row.BuildingId === buildingId))
      ) {
        warningCount += 1;
      }
    });
    let newProps = Object.assign({}, props);
    newProps.data = newData;
    newProps.ids = newIds;
    // Update warning count on the sidedrawer
    if (warningCount !== reminderCount) {
      dispatch(setReminderCount(warningCount));
    }

    if (
      newProps &&
      newProps.loaded &&
      (!newProps.data || !Object.keys(newProps.data).length)
    ) {
      return (
        <CardContent>
          <Typography variant="body2">No results found</Typography>
        </CardContent>
      );
    }
    return (
      <Datagrid
        {...newProps}
        rowClick={(id) => "/operationlog/" + id + "?src=reminders"}
      >
        <TextField source="id" />
        <SourceBuilding
          sortBy={"BuildingId"}
          addLabel={true}
          label="Building"
        />
        <ReminderTextField source="Title" />
        <ReminderTextField source="State" />
        <ReminderDateField source="Date" label="Date created" locales="fi-FI" />
        <ReminderDateField source="Deadline" locales="fi-FI" />
        <ReminderTextField source="CreatedBy" />
        <ReminderTextField source="LastModifiedBy" />
      </Datagrid>
    );
  };

  const ValuesPagination = (props) => {
    return (
      <Pagination
        style={{ display: "none" }}
        rowsPerPageOptions={[25, 50, 1000]}
        {...props}
      />
    );
  };

  return (
    <>
      <PageTitle title="Reminders" />
      <List
        {...props}
        perPage={1000}
        pagination={<ValuesPagination />}
        bulkActionButtons={false}
        filters={<RemindersFilter />}
        filterDefaultValues={{
          BuildingId: buildingId,
        }}
      >
        <CustomGrid />
      </List>
    </>
  );
};

export default Reminders;

export const isValidReminder = (r) => {
  return (
    r.State &&
    ((r.State !== "3. Completed" && r.Deadline) ||
      (r.State !== "3. Completed" && r.LG_checkup))
  );
};

export const isWarningReminder = (r) => {
  return (
    (r.State !== "3. Completed" &&
      new Date(r.Deadline).getTime() < new Date().getTime()) ||
    (r.State !== "3. Completed" &&
      new Date(r.Deadline).getTime() < new Date().getTime())
  );
};
