import { addYears } from "date-fns";
import { setConsumptionUnitText } from "../utils/unitTextParsers";
import { IBaseline, Resource } from "./SavingsGraph";
import { getCumulativeTarget } from "./savingsGraphUtils";

const compareByMonth = (d1: Date, d2: Date) => d1.getFullYear() === d2.getFullYear() && d1.getUTCMonth() === d2.getUTCMonth();

const savingsGraphOptions = ({
  cumulativeConsNormData,
  targetData,
  resource,
  baselines = [],
  color,
}: { cumulativeConsNormData: [Date, number][]; targetData: any; resource: Resource; baselines: IBaseline[]; color: string; }) => {
  // CUSTOM DIVIDER FOR SOME CONSUMPTION TO GET CORRECT UNIT
  // Electricity is changed to MWh from KWh
  let divider = 1;
  if (resource === "electricity") {
    divider = 1000;
  }

  const cumulativeConsumed = cumulativeConsNormData.map(([date, value]) => [date, value] as [Date, number]);

  if (!(cumulativeConsumed.length > 0 && targetData)) {
    return {};
  }

  const firstCumulativeConsumedDate = new Date(cumulativeConsumed[0][0]);
  const lastCumulativeConsumedDate = new Date(cumulativeConsumed[cumulativeConsumed.length - 1][0]);

  const cumulativeBaseline = cumulativeConsumed.length > 0 && baselines.length > 0
    ? getCumulativeTarget(
      firstCumulativeConsumedDate,
      addYears(lastCumulativeConsumedDate, 1),
      baselines.map(baseline => baseline.Target)
    )
    : [];

  const cumulativeTarget = cumulativeConsumed.length > 0 && targetData
    ? getCumulativeTarget(
      firstCumulativeConsumedDate,
      addYears(lastCumulativeConsumedDate, 1),
      targetData.data
    )
    : [];

  if (!(cumulativeBaseline.length > 0)) {
    return {};
  }

  const cumulativeTargetSavings = cumulativeTarget.map((target, idx) => [target[0], cumulativeBaseline[idx][1] - target[1]] as [number, number]);
  const cumulativeTargetHistory = cumulativeTargetSavings.filter(savings => savings[0] <= cumulativeConsumed[cumulativeConsumed.length - 1][0].getTime());
  const cumulativeTargetForecast = cumulativeTargetSavings.filter(savings => savings[0] >= cumulativeConsumed[cumulativeConsumed.length - 1][0].getTime());

  // Calculate the actual consumption data
  // Actual consumption is baseline - consumption
  const actualConsumption: [number, number][] = [];
  if (cumulativeBaseline && cumulativeConsumed) {
    cumulativeBaseline.forEach((b) => {
      const cons = cumulativeConsumed.find(t => compareByMonth(t[0], new Date(b[0])));
      if (cons && cons[1] !== null) {
        actualConsumption.push([b[0], b[1] - cons[1]]);
      }
    });
  }

  // Change series colors gradient depending on resource
  let g1 = "#ffeed6";
  let g2 = "#fffdfa";
  if (resource === "heating") {
    g1 = "#ffeed6";
    g2 = "#fffdfa";
  } else if (resource === "water") {
    g1 = "#d4e2f7";
    g2 = "#edf3fc";
  } else if (resource === "electricity") {
    g1 = "#cfebe0";
    g2 = "#f5fbf9";
  } else if (resource === "cooling") {
    g1 = "#d3f4f7";
    g2 = "#ecfafb";
  }

  const finalSeries = [];

  const consumptionSeries = {
    name: "Actual savings",
    pointPlacement: "on",
    fillColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [
        [0, g1],
        [1, g2],
      ],
    },
    color: color,
    data: actualConsumption.map(([date, value]) => [date, value / divider]),
    showInLegend: false,
  };

  const targetSeries = {
    name: "Target savings",
    pointPlacement: "on",
    data: cumulativeTargetHistory.map(([date, value]) => [date, value / divider]),
    fillOpacity: 0.25,
    color: "#2E2247",
    type: "line",
    showInLegend: false,
  };

  const forecastSeries = {
    name: "Forecast savings",
    pointPlacement: "on",
    data: cumulativeTargetForecast.map(([date, value]) => [date, value / divider]),
    fillOpacity: 0.25,
    dashStyle: "dash",
    color: "#2E2247",
    type: "line",
    showInLegend: false,
  };

  if (
    consumptionSeries &&
    consumptionSeries.data &&
    consumptionSeries.data.length > 0
  ) {
    finalSeries.push(consumptionSeries);
  }

  if (targetSeries && targetSeries.data && targetSeries.data.length > 0) {
    finalSeries.push(targetSeries);
  }

  if (forecastSeries && forecastSeries.data && forecastSeries.data.length > 0) {
    finalSeries.push(forecastSeries);
  }

  let valueSuffix = " MWh";
  if (resource === "water") {
    valueSuffix = " m³";
  }

  return {
    chart: {
      type: "area",
      margin: [0, -15, 0, -15],
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: "",
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "downloadPNG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
    series: finalSeries,
    yAxis: {
      visible: false,
      title: {
        text: setConsumptionUnitText(resource),
      },
      gridLineWidth: 0,
    },
    xAxis: {
      visible: false,
      tickmarkPlacement: null,
      type: "datetime",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      valueSuffix: valueSuffix,
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

export default savingsGraphOptions;
