import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  Pagination,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin";
import { Grid, Divider, Card, Typography, Box } from "@material-ui/core";

import { stateMapper } from "../customReducers/connectFunctions";
import { convertEnergyTypeToLabel } from "../utils/unitTextParsers";
import PageTitle from "../components/layout/PageTitle";
import { isNil } from "lodash";

const ValuesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

const EnergyTypeList = ({ BuildingId, projectname, ...props }) => {
  // Prevent rendering component without BuildingId
  if (isNil(BuildingId)) {
    return null;
  }
  // Delete unused/unnecessary props
  let listProps = Object.assign({}, props);
  delete listProps.buildingTwinId;
  delete listProps.buildingSelected;
  delete listProps.dispatch;

  const HideableTextField = (props) => {
    const record = useRecordContext();
    if (record[props.source] === 0 || record[props.source] === null)
      return null;
    return (
      <Box sx={{ pb: 1 }}>
        <Typography
          style={{
            color: "#0000008a",
            fontWeight: 400,
            fontSize: "0.75rem",
          }}
        >
          {convertEnergyTypeToLabel(props.source)}
        </Typography>
        <Typography
          style={{
            fontWeight: 400,
            fontSize: "0.875rem",
          }}
        >
          {record[props.source].toFixed(1)}
        </Typography>
      </Box>
    );
  };

  const setConsumptionUnitText = (record) => {
    return (
      (
        record.Oil +
        record.DistrictHeating +
        record.Steam +
        record.NaturalGas +
        record.LiquidPetroleum +
        record.CondensateHeat
      ).toFixed(1) +
      " " +
      "MWh"
    );
  };

  const ConsumptionListExpand = (props) => {
    return (
      <SimpleShowLayout
        {...props}
        actions={null}
        title="Categorized Heating Consumptions"
      >
        <Card style={{ marginBottom: 20 }}>
          <Typography
            style={{ paddingLeft: 20, paddingTop: 20, paddingBottom: 10 }}
            variant="subtitle1"
            gutterBottom
          >
            Composition
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <HideableTextField source="DistrictHeating" />
                <HideableTextField source="Oil" />
                <HideableTextField source="NaturalGas" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <SimpleShowLayout>
                <HideableTextField source="LiquidPetroleum" />
                <HideableTextField source="Steam" />
                <HideableTextField source="CondensateHeat" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <SimpleShowLayout>
                <FunctionField
                  style={{ fontWeight: 600 }}
                  label="Total "
                  render={(record) => setConsumptionUnitText(record)}
                />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Card>
      </SimpleShowLayout>
    );
  };

  return (
    <>
      <PageTitle title="Consumptions by Energy Type" />
      <List
        {...listProps}
        perPage={25}
        pagination={<ValuesPagination />}
        filterDefaultValues={{ BuildingId }}
        bulkActionButtons={false}
        onError={(e) => console.warn("HELLO", e)}
      >
        <Datagrid expand={<ConsumptionListExpand />}>
          <DateField
            source="Time"
            label="Date"
            options={{ year: "numeric", month: "numeric" }}
          />
          {/* <NumberField
          source="ConsNorm"
          label="Normalized Consumption"
          options={{ useGrouping: false }}
        /> */}
          <FunctionField
            label="Total Consumption"
            render={(record) => setConsumptionUnitText(record)}
          />
          <FunctionField label="Source" render={(record) => record.Source} />
          <FunctionField
            render={(record) => (
              <EditButton
                to={`manualconsumption?BuildingStatsId=${record.BuildingStatsId}&Type=${record.Type}&Time=${record.Time}`}
              />
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default stateMapper(EnergyTypeList);
